import { PrivateDataState } from "store/importPrivateData/types";
import { RawAssetAndLoansState, RawLoanState, RawMember } from "../../../store/import/types";
import { isWebView } from "../../../store/import/utils";

export function getUrlHostName(url: string): string {
   const regex = /(ht{2}ps?:\/\/)?([\w\-.:]+)([/?].*)?/;
   const results = regex.exec(url);

   return results ? results[2] : "";
}

export function downloadData(
   env: string,
   acceptedUrlPatterns: Array<string>
): Promise<any> {
   return new Promise((resolve, reject) => {
      (window as any).gfmWebScrappingApi.getData(
         {
            env,
            acceptedUrlPatterns
         },
         (data: any) => {
            if (data !== undefined) {
               resolve(data);
            } else {
               reject("no data");
            }
         }
      );
   });
}

export function parseCookie(data: any): string {
   return data.cookies.ciblage;
}

export function parseMembers(host: string, data: any): RawMember | undefined {
   try {
      let memberDateOfBirth: Partial<RawMember> = {};
      let member: Partial<RawMember> = {};
      let firstNameRetrieved;
      let lastNameRetrieved;
      let idStat;
      let dcr;
      let icd;
      let dateOfBirthData;
      let titleData;
      let gotData = false;
      let protocol: string;

      protocol = getProtocol(host);
      if (
         (dcr = data[`${protocol}://${host}/dcr-web/dcr/dcr-elixyr.json`]) &&
         (dateOfBirthData = dcr.donnees) &&
         !!dateOfBirthData.dateOfBirth
      ) {
         memberDateOfBirth = {
            dateOfBirth:
            data[`${protocol}://${host}/dcr-web/dcr/dcr-elixyr.json`].donnees
               .dateOfBirth
         };
         gotData = true;
      }
      let title: string = "";
      if (
         (icd =
            data[
               `${protocol}://${host}/icd/rvd_rva/data/rdv_client/rdv-recuperer-donnees-tiers-authsec.json`
               ]) &&
         (titleData = icd.donnees) &&
         !!titleData.civilite
      ) {
         title = titleData.civilite;
      }

      const el = document.createElement("html");
      if (data[`${protocol}://${host}/dcr-web/dcr/dcr-donnees-personnelles.html`]) {
         el.innerHTML = data[`${protocol}://${host}/dcr-web/dcr/dcr-donnees-personnelles.html`];
         const cells = el.querySelectorAll("td.dcr_mp_greybg div");
         member = {};

         firstNameRetrieved = "";
         lastNameRetrieved = "";
         if (NodeList.prototype.isPrototypeOf(cells) && cells.length >= 3) {
            member = {
               maritalStatus: cells[0].textContent,
               numberChild: cells[1].textContent,
               employmentStatus: cells[2].textContent,
               title: title
            };
            gotData = true;
         }
         if (!isWebView()) {
            const jsons = el.querySelectorAll('script[type="application/json"]');
            const jsonLd: any[] = [];
            jsons.forEach((e) => jsonLd.push(JSON.parse(e.innerHTML)));
            firstNameRetrieved = jsonLd[0]["user-info-firstname"];
            lastNameRetrieved = jsonLd[0]["user-info-lastname"].toUpperCase();
            idStat = jsonLd[0]["IdStat"];
         } else {
            const cell = el.querySelectorAll("div.dcr_mp_h1");
            if (NodeList.prototype.isPrototypeOf(cell) && cells.length >= 1) {
               const strNames = cell[0].textContent!.slice(15);
               firstNameRetrieved = strNames.split(' ')[0];
               lastNameRetrieved = strNames.slice(firstNameRetrieved.length).replace(/\s*$/,'').toUpperCase();
            }
         }
      }

      if (gotData) {
         return {
            ...memberDateOfBirth,
            ...member,
            firstName: firstNameRetrieved,
            lastName: lastNameRetrieved,
            idStat,
         };
      }
      throw new Error("no member data");
   } catch (e) {
      console.log((e as Error).message); // non blocking error
   }
}

export function parsePrivateData(host: string, data: any): PrivateDataState {
  try {
    let protocol: string;

    protocol = getProtocol(host);

    const el = document.createElement("html");
    if (data[`${protocol}://${host}/dcr-web/dcr/dcr-donnees-personnelles.html`]) {
      el.innerHTML = data[`${protocol}://${host}/dcr-web/dcr/dcr-donnees-personnelles.html`];

      if (!isWebView()) {
          const jsons = el.querySelectorAll('script[type="application/json"]');
          const jsonLd: any[] = [];
          jsons.forEach((e) => jsonLd.push(JSON.parse(e.innerHTML)));

          const idStat = jsonLd[0]["IdStat"] || "";
          return {
              idStat,
              hasFetched: true,
              hasFetchedError: idStat === "",
          };
      }
      // IdStat récupéré autrement en WebView
    } else {
        throw new Error("no member data");
    }
  } catch (e) {
    console.log((e as Error).message); // non blocking error
  }

  return {
    idStat: "",
    hasFetched: true,
    hasFetchedError: true,
  };
}

export function parseAssetsAndLoans(host: string, data: any): RawAssetAndLoansState {
   const assetsAndLoans: RawAssetAndLoansState = {
      assets: [],
      loans: [],
      pretConsos: []
   };
   let protocol: string;

   protocol = getProtocol(host);
   try {
      // assets
      assetsAndLoans.assets = [];
      let groups = [];
      let prestations;
      let donnees;
      if (
         (prestations =
            data[
               `${protocol}://${host}/icd/cbo/data/liste-prestations-authsec.json?n10_avecMontant=0`
               ]) &&
         (donnees = prestations.donnees) &&
         !!donnees.syntheseParGroupeProduit
      ) {
         groups = donnees.syntheseParGroupeProduit;
      }
      groups.forEach((group: any) => {
         if (
            group.groupeProduit === "COMPTE_A_VUE" ||
            group.groupeProduit === "COMPTE_EPARGNE" ||
            group.groupeProduit === "EPARGNE_FINANCIERE" ||
            group.groupeProduit === "EPARGNE_A_TERME"
         ) {
            const {prestations} = group;
            prestations.forEach((prestation: any) => {
               if (
                  prestation.groupeRoleDTO === "TITULAIRE" &&
                  prestation.soldes.devise === "EUR"
               ) {
                  assetsAndLoans.assets.push({
                     assetName: prestation.labelToDisplay,
                     externalId: prestation.idTechnique,
                     produit: prestation.produit,
                     totalValuation: prestation.soldes.soldeActuel
                  });
               }
            });
         } else if (group.groupeProduit !== "PRET") {
            // on n'importe pas celui là car on l'a par ailleurs via les endpoints dédiés aux prêts
            // TODO  this.errorService.signal(`unknown groupeProduit in import: ${group.groupeProduit}`);
         }
      });
   } catch (e) {
      console.log('Error during load of assets: ' + e);
   }

   try {
      // loans pretConso
      let t_restit;
      let t_donnees;
      let prestations: Array<any> = [];
      if (
         (t_restit =
            data[
               `${protocol}://${host}/abm/restit/listeRestitutionPretsNET.json?a100_isPretConso=true`
               ]) &&
         (t_donnees = t_restit.donnees) &&
         !!t_donnees.tabPrestations
      ) {
         prestations = t_donnees.tabPrestations;
      }
      assetsAndLoans.pretConsos = prestations.map((prestation) =>
         createLoanFromPrestationLine(prestation, true)
      );
   } catch (e) {
      console.log('Error during load of loans with pretConso: ' + e);
   }

   try {
      // loans not pretConso
      let f_restit;
      let f_donnees;
      let prestations: Array<any> = [];
      if (
         (f_restit =
            data[
               `${protocol}://${host}/abm/restit/listeRestitutionPretsNET.json?a100_isPretConso=false`
               ]) &&
         (f_donnees = f_restit.donnees) &&
         !!f_donnees.tabPrestations
      ) {
         prestations = f_donnees.tabPrestations;
      }
      assetsAndLoans.loans = prestations.map((prestation) =>
         createLoanFromPrestationLine(prestation, false)
      );
   } catch (e) {
      console.log('Error during load of loans with loans: ' + e);
   }
   sessionStorage.setItem("assetsAndLoans", JSON.stringify(assetsAndLoans));
   return assetsAndLoans;
}

const createLoanFromPrestationLine = (
   prestation: any,
   isPretConso: boolean
): Partial<RawLoanState> => {
   const loan: Partial<RawLoanState> = {};
   loan.externalId = prestation.idPrestatech;
   loan.name = prestation.description;
   if (!!prestation.montantPret && !!prestation.montantPret.valeur) {
      loan.montantPret = prestation.montantPret.valeur;
   }
   if (!!prestation.montantEcheance && !!prestation.montantEcheance.valeur) {
      loan.monthlyAmount = prestation.montantEcheance.valeur;
   }
   loan.durationInMonth = prestation.dureeNbMois;
   loan.endDate = prestation.dateFin;
   loan.isPretConso = isPretConso;
   if (!isPretConso) {
      if (!!prestation.capitalRestantDu && prestation.capitalRestantDu.valeur) {
         loan.capitalRestantDu = prestation.capitalRestantDu.valeur;
      }
      loan.dateSituation = prestation.dateSituation;
   }

   return loan;
};

export function parseBankAdvisor(host: string, data: any): any {
   try {
      let root;
      let odt;
      let protocol: string;

      protocol = getProtocol(host);
      if (
         !!(root = data[`${protocol}://${host}/icd/pon/data/get-contacts.xml`]) &&
         !!(odt = root.odtListeContacts) &&
         !!odt.listeContacts
      ) {
         const contacts: Array<any> = odt.listeContacts;
         if (
            contacts[0] &&
            contacts[0].listeConseillers &&
            contacts[0].listeConseillers[0]
         ) {
            const contact: any = contacts[0].listeConseillers[0];
            return {
               firstName: contact.PrenomConseiller[0],
               lastName: contact.NomConseiller[0],
               phoneNumber: contact.NumeroTelephone[0],
               email: contact.Email[0],
               matricule: contact.matriculeAgent[0]
            };
         }
      }
      throw new Error("no advisor to import");
   } catch (e) {
      // TODO this.errorService.signal('Error during load of advisor Data: ' + e);
      throw e;
   }
}

const getProtocol = (host: string): string => {
   if (host.includes("localhost")) {
      return "http";
   } else {
      return "https";
   }
};
const isEmpty = (rawAssetAndLoansState: RawAssetAndLoansState): boolean => {
   if (
      rawAssetAndLoansState.assets != null &&
      rawAssetAndLoansState.assets.length > 0
   ) {
      return false;
   }
   if (
      rawAssetAndLoansState.pretConsos != null &&
      rawAssetAndLoansState.pretConsos.length > 0
   ) {
      return false;
   }

   return !(
      rawAssetAndLoansState.loans != null &&
      rawAssetAndLoansState.loans.length > 0
   );
};
