import {createSlice} from '@reduxjs/toolkit';
import {SignUpState} from "./types";

const initialState: SignUpState = {
   signup: {
      firstname: "",
      login: "",
      email: "",
      password: ""
   },
   isSignedUp: false,
   isLoginAlreadyUsed: false,
   isEmailInvalid: false
};

export const signupSlice = createSlice({
   name: "signup",
   initialState,
   reducers: {
      signupSuccess: (currentState) => ({
         ...currentState,
         isSignedUp: true
      }),
      signupErrorLoginAlreadyUsed: (currentState) => ({
         ...currentState,
         isLoginAlreadyUsed: true,
         isEmailInvalid: false
      }),
      signupErrorEmailInvalid: (currentState) => ({
         ...currentState,
         isEmailInvalid: true,
         isLoginAlreadyUsed: false
      })
   }
});

export const {signupSuccess, signupErrorLoginAlreadyUsed, signupErrorEmailInvalid} = signupSlice.actions;

export default signupSlice.reducer;
