import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { AccountState } from "store/account/types";
import { createAppointment } from "store/appointment/actions";
import { Appointment } from "store/appointment/types";
import { State } from "store/store";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { dateFRToSQL } from "website/utils/date/DateUtils";

const SGAgendaPage: FunctionComponent = () => {
    const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dateRdv = atob(queryParams.get(`${window.env.REACT_APP_AGENDA_PREFIX_OUT}dateRdv`) || "");
    const heureRdv = atob(queryParams.get(`${window.env.REACT_APP_AGENDA_PREFIX_OUT}heureRdv`) || "");
    const mailRdv = atob(queryParams.get(`${window.env.REACT_APP_AGENDA_PREFIX_OUT}mailCC`) || "");
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();

    useEffect(() => {
        const appointment: Partial<Appointment> = {
            date: dateFRToSQL(dateRdv),
            time: heureRdv,
            financialAdvisorEmail: mailRdv,
            appointmentJustCreated: true,
        };
        dispatch(createAppointment(appointment));
        saveEvent(trackingEvent.SGAGENDA_RETOUR);
    }, []);

    // @ts-ignore
    return <Redirect to="/dashboard" />;
};

export { SGAgendaPage };
