import { http } from "../website/utils/axios";
import { AxiosRequestConfig } from "axios";

export const apiGet = (url: string, baseURL?: string) => {
   const defaultsBaseURL = http.defaults.baseURL;
   if (baseURL) {
      http.defaults.baseURL = baseURL;
   }
   const response = http.get(url).then((response) => response);
   http.defaults.baseURL = defaultsBaseURL;

   return response;
};

export const apiPost = <T>(
   url: string,
   payload?: T,
   config?: AxiosRequestConfig,
   baseURL?: string
) => {
   const defaultsBaseURL = http.defaults.baseURL;
   if (baseURL) {
      http.defaults.baseURL = baseURL;
   }
   const response = http.post(url, payload, config).then((response) => response);
   http.defaults.baseURL = defaultsBaseURL;

   return response;
};

export const apiPostFile = (
   url: string,
   formData: FormData,
   baseURL?: string
) => {
   const defaultsBaseURL = http.defaults.baseURL;
   if (baseURL) {
      http.defaults.baseURL = baseURL;
   }
   const response = http
      .post(url, formData, {
         headers: {
            "Content-Type": "multipart/form-data;boundary=--abc--",
            "Access-Control-Allow-Origin": "*",
         },
      })
      .then((response) => response);
   http.defaults.baseURL = defaultsBaseURL;

   return response;
};

export const apiPut = <T>(url: string, payload?: T) =>
   http.put(url, payload).then((response) => response);

export const apiDelete = (url: string) =>
   http.delete(url).then((response) => response);
