import { FunctionComponent, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGText } from "sg-typo";
import { RetirementCategory } from "store/members/types";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";

interface CategorieActiveInputProps {
  retirementType?: RetirementCategory;
  categorieActive: CategorieActiveChoix;
  setCategorieActive: (value: CategorieActiveChoix) => void;
}

export const CategorieActiveInput: FunctionComponent<
  CategorieActiveInputProps
> = (props: CategorieActiveInputProps) => {
  const { retirementType, categorieActive, setCategorieActive } = props;

  const { control, setValue, formState: { errors, submitCount } } = useFormContext();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const isVisible = useMediaQuery({ maxwidth: "xl" });

  const optionCategorieActiveChoix: CategorieActiveChoix[] =
    Object.values(CategorieActiveChoix);

  useEffect(() => {
    setValue("categorieActive", categorieActive, {
      shouldValidate: submitCount > 0,
    });
  }, [categorieActive, retirementType]);

  return (
    <>
      {retirementType === RetirementCategory.FONCTIONNAIRE && (
        <Controller
          key="categorieActive"
          control={control}
          name="categorieActive"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SGSelect
              placeholder="Sélectionnez"
              value={categorieActive}
              size={!isVisible ? "xl" : "l"}
              onChange={(value: CategorieActiveChoix) => {
                field.onChange(value);
                setCategorieActive(value);
              }}
              label={
                <SGText>
                  <SGTextIntl
                    intlId="simulateurAvantApres.situation.question.categorieActive"
                    size="l"
                  />
                  <InfoTooltipAssu
                    text="simulateurAvantApres.situation.question.categorieActive.tooltip"
                    placement={isPhone ? "bottomRight" : "right"}
                    transformations={{
                      b: (word: string) => (
                        <SGText weight="bold">{word}</SGText>
                      ),
                      linebreak: <br />,
                    }}
                  />
                </SGText>
              }
              status={errors?.categorieActive && "error"}
              validate={<FormattedMessage id="tunnel.error.required" />}
            >
              {optionCategorieActiveChoix.map((value: CategorieActiveChoix) => (
                <SGSelectOption key={value} value={value}>
                  {value}
                </SGSelectOption>
              ))}
            </SGSelect>
          )}
        />
      )}
    </>
  );
};
