import {ProjectCategory, ProjectType} from "../projects/types";

export interface NewProjectState {
   newProjects: NewProject[];
   cannotFetchNewProjects: boolean;
   hasFetched: boolean;
}

export interface NewProject {
   id: number;
   clientId: number;
   projectType: ProjectType;
   category: ProjectCategory;
   clickable: boolean;
   notClickableReason: string;
   title: string;
   description: string;
   url: string;
}

// Actions
export const FETCH_NEW_PROJECTS = "FETCH_NEW_PROJECTS";
export const NEW_PROJECTS_FETCHED = "NEW_PROJECTS_FETCHED";
export const FETCH_NEW_PROJECTS_ERROR = "FETCH_NEW_PROJECTS_ERROR";
