import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiPost } from "../apiCaller";
import { safe } from "../error/utils";
import { onApiCallError } from "../parcoursRIS/utils";
import {
  FETCH_PARCOURS_RIS_PROJECTION,
  ParcoursRisProjection,
  ParcoursRisResultsRequest,
  PARCOURS_RIS_PROJECTION_FETCHED
} from "./types";

export default function* watcherSaga() {
  yield takeLeading(
    FETCH_PARCOURS_RIS_PROJECTION,
    safe(onApiCallError, handlePostParcoursRisProjection)
  );
}

function* handlePostParcoursRisProjection(action: PayloadAction<ParcoursRisResultsRequest>) {
  const payload: AxiosResponse<ParcoursRisProjection> = yield call(
    apiPost,
    `/backpfr/api/parcours-ris/results`,
    action.payload,
    undefined,
    window.env.REACT_APP_API_URL
  );
  yield put({ type: PARCOURS_RIS_PROJECTION_FETCHED, payload: payload.data });
}
