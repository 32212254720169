import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { DashboardAgeType, getIdTracking } from "store/dashboard/types";
import { previousStepAction } from "store/tunnel/actions";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { CarteAgeContenu } from "../CarteAgeContenu/CarteAgeContenu";

interface PensionSituationProps {
  changeSelectedAgeRetirement: (type: DashboardAgeType) => void;
  accessible?: boolean;
  showReturn?: boolean;
}

export const PensionSituation: FunctionComponent<PensionSituationProps> = ({
  changeSelectedAgeRetirement,
  accessible = true,
  showReturn = true,
}) => {
  const { agesTries } = useSelectedRetirementAge();
  const { trackClick } = useTracking();
  const dispatch = useDispatch();

  return (
    <SGSpace direction="vertical" disableautomargin size="xl">
      <SGTitle key="titre" level={2} textalign="center">
        <FormattedMessage id="tunnel.pension.title" />
      </SGTitle>

      <SGGridRow key="gridrow" justify="center" gutter={[0, 24]}>
        {agesTries?.map((age) => (
          <SGGridCol key={age.type} span={12} md={8}>
            <TuileCliquable
              disableautomargin
              iconeTaille="s"
              onClick={() => {
                trackClick(
                  `clic-sur-card-${getIdTracking(
                    age.type
                  )}`
                );
                changeSelectedAgeRetirement(age.type);
              }}
              ariaLabel={`Sélection ${age.typesDepartTitre}`}
              accessible={accessible}
            >
              <CarteAgeContenu age={age} />
            </TuileCliquable>
          </SGGridCol>
        ))}
        <SGGridCol />
      </SGGridRow>

      {showReturn && (
        <SGButtonGroup key="buttons" align="center">
          <SGButton
            type="secondary"
            onClick={() => {
              trackClick(`clic-sur-retour`);
              dispatch(previousStepAction());
            }}
            size="md"
          >
            <SGText>
              <FormattedMessage id="tunnel.buttons.previous" />
            </SGText>
          </SGButton>
        </SGButtonGroup>
      )}
    </SGSpace>
  );
};
