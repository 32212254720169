import { AxiosResponse } from "axios";
import { Reducer } from "react";
import {
  PARCOURS_RIS_UPLOAD_FETCHED,
  RESET_PARCOURS_RIS_UPLOAD_ERROR,
  UPDATE_PARCOURS_RIS_UPLOAD,
  ParcoursRisUploadState,
  PARCOURS_RIS_UPLOAD_ACCESSIBLE_FETCHED,
  RESET_PARCOURS_RIS_UPLOAD_ACCESSIBLE
} from "./types";
import { API_ERROR_400, API_ERROR_403, API_ERROR_422, API_ERROR_OTHER } from "../parcoursRIS/types";

const initialState: ParcoursRisUploadState = {} as ParcoursRisUploadState;

export const ParcoursRisUploadReducer: Reducer<ParcoursRisUploadState, any> = (
  currentState = initialState,
  action
): ParcoursRisUploadState => {
  switch (action.type) {
    case PARCOURS_RIS_UPLOAD_FETCHED:
      return {
        ...currentState,
        parcoursRisUpload: action.payload,
      };
    case PARCOURS_RIS_UPLOAD_ACCESSIBLE_FETCHED:
      return {
        ...currentState,
        accessible: true,
      };
    case RESET_PARCOURS_RIS_UPLOAD_ACCESSIBLE:
      return {
        ...currentState,
        accessible: undefined,
      };
    case API_ERROR_400: {
      const { response }: { response: AxiosResponse } = action.payload;

      return {
        ...currentState,
        parcoursRisUploadErrorCode: 400,
        parcoursRisUploadErrorMessage: response.data?.errorType,
      };
    }
    case API_ERROR_403:
      return {
        ...currentState,
        accessible: false,
      };
    case API_ERROR_422: {
      const { response }: { response: AxiosResponse } = action.payload;

      return {
        ...currentState,
        parcoursRisUploadErrorCode: 422,
        parcoursRisUploadErrorMessage: response.data?.message,
      };
    }
    case API_ERROR_OTHER:
      return {
        ...currentState,
        parcoursRisUploadErrorCode: 500,
        parcoursRisUploadErrorMessage: "Erreur générique"
      };
    case RESET_PARCOURS_RIS_UPLOAD_ERROR:
      return { ...currentState, parcoursRisUploadErrorCode: undefined, parcoursRisUploadErrorMessage: undefined };
    case UPDATE_PARCOURS_RIS_UPLOAD:
      return {
        ...currentState,
        parcoursRisUpload: action.payload,
        parcoursRisUploadErrorCode: undefined,
        parcoursRisUploadErrorMessage: undefined,
      };
    default:
      return currentState;
  }
};
