import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { PARCOURS_RIS } from "website/utils/privateRoutes";
import { InfoAssuModal } from "../InfoAssuModal/InfoAssuModal";

interface DepartAnticipeModalProps {
  visible: boolean;
  setVisible: (showParcoursRISModal: boolean) => void;
}

const DepartAnticipeModal: FunctionComponent<DepartAnticipeModalProps> = (
  props: DepartAnticipeModalProps
) => {
  const { visible, setVisible } = props;
  const history = useHistory();

  return (
    <InfoAssuModal
      onHide={() => setVisible(false)}
      onClick={() => {
        setVisible(false);
        history.push(PARCOURS_RIS);
      }}
      showInfoModal={visible}
      titleLevel={3}
      text="dashboard.modal.departAnticipe.text"
      title="dashboard.modal.departAnticipe.title"
      buttonPrimary="dashboard.modal.buttonPrimary.departAnticipe"
      buttonSecondary="dashboard.modal.buttonSecondary.departAnticipe"
      trackPageCategory3="tableau-de-bord"
      trackKey="popin-ris-actualiser-mon-ris"
      trackKeyFormField1="mise-a-jour-donnees-depart-anticipe"
      trackKeyOnClick="popin-ris-actualiser-mon-ris::clic-sur-j-analyse-mon-ris"
      trackKeyOnHide="popin-ris-actualiser-mon-ris::clic-sur-fermer"
      trackKeyButtonSecondaryOnHide="popin-ris-actualiser-mon-ris::clic-sur-annuler"
    />
  );
};

export { DepartAnticipeModal };
