export interface FeaturesState {
   hasFetched: boolean;
   features: Feature[];
}

export const hasFetchedFeatures = (featuresState: FeaturesState): boolean => featuresState.hasFetched && featuresState.features.length > 0;

/**
 * Objet récupéré du backpfr
 */
export interface Feature {
   id: string; // nom
   active: boolean;
}

export interface FeatureList {
   disponibiliteMCR?: boolean;
   disponibiliteSimulateurFlash?: boolean;
   parcoursSEIActif?: boolean;
   disponibiliteWebCallback?: boolean;
   disponibiliteAppeler?: boolean;
   simulateurAvantApres?: boolean;
   disponibiliteSGAgenda?: boolean;
   disponibiliteParcoursRevenusComplementaires?: boolean;
   disponibiliteOffrePER?: boolean;
   disponibiliteParcoursRIS?: boolean;
   disponibiliteHorsSgPatrimoineSupervision?: boolean;
   disponibiliteSupervision?: boolean;
   hasFetchedFeatureFlipping: boolean;
   visibiliteQuestionnaireDashboard?: boolean;
}

// actions
export const FETCH_FEATURES = "FETCH_FEATURES";
export const FEATURES_FETCHED = "FEATURES_FETCHED";
