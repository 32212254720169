import { Project } from "../projects/types";
import { Income } from "../incomes/types";

export interface FamilyState {
   family: Family,
   hasFetched: boolean;
   hasLocalChanges: boolean; // Indique si des modifications sur la famille ont eu lieu dans le store
   shouldRedirectToIncomes: boolean; // Indique si l'utilisateur doit être redirigé vers le tunnel incomes lorsqu'il sauvegarde sa famille
}

export interface Member {
   id?: number;
   name: string;
   status: FamilyStatus;
   maritalStatus?: MaritalStatus;
   birthday?: Date;
   deletable: boolean;
   employmentStatus?: EmploymentStatus;
   retirementType?: RetirementCategory;
   custodyType?: CustodyType;
   projects: Project[];
   incomes: Income[];
   matrimonialRegime?: MatrimonialRegime;
}

export type Me = Member & Required<Pick<Member, "birthday" | "maritalStatus">>;
export type Partner = Member & Required<Pick<Member, "maritalStatus">>;
export type MemberWithId = Member & Required<Pick<Member, "id">>;

export interface Family {
   me: Me;
   partner?: Partner;
   children: Array<Member>;
   relatives: Array<Member>;
}

// enums
export const EmploymentStatus = {
   WORKER: "WORKER",
   RETIRED: "RETIRED"
};

export type EmploymentStatus = typeof EmploymentStatus[keyof typeof EmploymentStatus];

// On le garde en enum pour le matching avec RetirementCategorySimulation
export enum RetirementCategory {
   SALARIE_PRIVE_CADRE = "SALARIE_PRIVE_CADRE",
   ARTISAN = "ARTISAN",
   COMMERCANT = "COMMERCANT",
   LIBERAL = "LIBERAL",
   FONCTIONNAIRE = "FONCTIONNAIRE",
   ASSISTANT_MEDICAL = "ASSISTANT_MEDICAL",
   EXPLOITANT_AGRICOLE = "EXPLOITANT_AGRICOLE"
}

export const RetirementCategorySimulation = {
   SALARIE_PRIVE_CADRE: "1",
   ARTISAN: "2",
   COMMERCANT: "3",
   LIBERAL: "4",
   FONCTIONNAIRE: "5",
   ASSISTANT_MEDICAL: "6",
   EXPLOITANT_AGRICOLE: "7",
};

export type RetirementCategorySimulation = typeof RetirementCategorySimulation[keyof typeof RetirementCategorySimulation];

export const CustodyType = {
   FULL_CUSTODY: "FULL_CUSTODY",
   FULL_CUSTODY_ME: "FULL_CUSTODY_ME",
   FULL_CUSTODY_PARTNER: "FULL_CUSTODY_PARTNER",
   SHARED_CUSTODY: "SHARED_CUSTODY",
   SHARED_CUSTODY_ME: "SHARED_CUSTODY_ME",
   SHARED_CUSTODY_PARTNER: "SHARED_CUSTODY_PARTNER",
   NO_CUSTODY: "NO_CUSTODY"
};

export type CustodyType = typeof CustodyType[keyof typeof CustodyType];

export const FamilyStatus = {
   ME: "ME",
   PARTNER: "PARTNER",
   CHILD: "CHILD",
   PARENT: "PARENT",
   GRANDCHILD: "GRANDCHILD",
   GRANDPARENT: "GRANDPARENT",
   GREATGRANDPARENT: "GREATGRANDPARENT",
   GREATGRANDCHILD: "GREATGRANDCHILD",
   BROTHER_SISTER: "BROTHER_SISTER",
   NEPHEW_NIECE: "NEPHEW_NIECE",
   FIRST_COUSIN: "FIRST_COUSIN",
   OTHER_IN_CHARGE: "OTHER_IN_CHARGE"
};

export type FamilyStatus = typeof FamilyStatus[keyof typeof FamilyStatus];

export const MaritalStatus = {
   MARRIED: "MARRIED",
   SINGLE: "SINGLE",
   BOUND_BY_PACS: "BOUND_BY_PACS",
   DIVORCED_OR_SEPARATED: "DIVORCED_OR_SEPARATED",
   WIDOWER: "WIDOWER",
   FREE_UNION: "FREE_UNION"
};

export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];

export const MatrimonialRegime = {
   COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS: "COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS",
   COMMUNAUTE_UNIVERSELLE: "COMMUNAUTE_UNIVERSELLE",
   SEPARATION_DE_BIENS: "SEPARATION_DE_BIENS",
   PARTICIPATION_AUX_ACQUETS: "PARTICIPATION_AUX_ACQUETS"
};

export type MatrimonialRegime = typeof MatrimonialRegime[keyof typeof MatrimonialRegime];

// actions
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const UPDATE_FAMILY = "UPDATE_FAMILY";
