import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { globalErrorUnavailable } from "store/error/errorsSlice";
import { apiGet, apiPost } from "../apiCaller";
import { getAssetsWithoutLoans } from "../assets/actions";
import { onDefaultError, safe } from "../error/utils";
import { getIncomesAction } from "../incomes/actions";
import { getProjectsAction } from "../projects/actions";
import { getRealizedDonationsAction } from "../realizedDonations/actions";
import { resetTaxState } from "../tax/taxSlice";
import { UpdateFamilyAction } from "./actions";
import { membersFetched } from "./membersSlice";
import { FETCH_MEMBERS, Family, UPDATE_FAMILY } from "./types";
import { logError } from "website/utils/logging";

export function* watcherSaga() {
   yield takeLeading(FETCH_MEMBERS, safe(onWealthError, handleGetMembers));
   yield takeLeading(UPDATE_FAMILY, safe(onDefaultError, handleSaveFamily));
}

function* onWealthError(err: AxiosError) {
  yield put(globalErrorUnavailable());
}

function* handleGetMembers() {
  const payload: AxiosResponse<Family> = yield call(
    apiGet,
    `wealth/api/members`
  );

  const payloadStringified = JSON.stringify(payload);
  if (payloadStringified.startsWith('{"')) {
    yield put(membersFetched(payload.data));
  } else {
    logError("Problème de connexion à wealth");
    yield put(globalErrorUnavailable());
  }
}

function* handleSaveFamily(action: PayloadAction<UpdateFamilyAction>) {
   const payload: AxiosResponse<Family> = yield call(apiPost, `wealth/api/members?override=${action.payload.override}`, action.payload.family);
   yield put(membersFetched(payload.data));
   if (action.payload.reloadStores) {
      yield put(getProjectsAction());
      yield put(resetTaxState());
      yield put(getRealizedDonationsAction());
      yield put(getIncomesAction());
      yield put(getAssetsWithoutLoans());
   }
}
