import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { Appointment, AppointmentState, APPOINTMENT_FETCHED, APPOINTMENT_UPDATED, UPDATE_APPOINTMENT } from "./types";

const initialState: AppointmentState = {appointment: {}};

export const AppointmentReducer: Reducer<AppointmentState, PayloadAction<Appointment>> = (
   currentState = initialState,
   action
): AppointmentState => {
   switch (action.type) {
      case APPOINTMENT_FETCHED:
         return {
            ...currentState,
            // TODO: Les dates arrivent au format string alors que c'est censé être de la Date
            // Ne devrait-on pas faire le parsing ici ?
            appointment: action.payload,
         };
      case APPOINTMENT_UPDATED:
         return {
            ...currentState,
            appointment: {...action.payload},
         };
      case UPDATE_APPOINTMENT:
         return {
            ...currentState,
            appointment: action.payload,
         };
      default:
      return currentState;
   }
};
