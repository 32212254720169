const LOG_PREFIX = "[PFR] ";

/**
 * Fonction de log de messages de debug. Non actif en production.
 *
 * @param businessMessage Message à loguer
 */
export const logDebug = (businessMessage: string): void => {
  if (window.env.REACT_APP_COMMANDERS_ENV !== "prod") {
    /* eslint-disable-next-line no-console */
    console.debug(LOG_PREFIX + businessMessage);
  }
};

/**
 * Fonction de log de messages d'information. Non actif en production.
 *
 * @param businessMessage Message à loguer
 */
export const logInfo = (businessMessage: string): void => {
  if (window.env.REACT_APP_COMMANDERS_ENV !== "prod") {
    /* eslint-disable-next-line no-console */
    console.info(LOG_PREFIX + businessMessage);
  }
};

/**
 * Fonction de log de messages d'avertissement.
 *
 * @param businessMessage Message à loguer
 */
export const logWarn = (businessMessage: string): void => {
  console.warn(LOG_PREFIX + businessMessage);
};

/**
 * Fonction de log de messages d'erreur.
 *
 * @param businessMessage Message à loguer
 */
export const logError = (businessMessage: string, err?: Error): void => {
  console.error(LOG_PREFIX + businessMessage, err);
};
