import {Member} from "../members/types";

export interface TaxState {
   taxSituation: TaxSituation;
   hasFetchedTaxSituation: boolean;
   taxCredits: TaxCredit[];
   hasFetchedTaxCredits: boolean;
   myTaxSystem: TaxSystem;
   partnerTaxSystem: TaxSystem;
   hasFetchedTaxSystems: boolean;
}

export interface TaxSituation {
   coupleIncomeTaxList: Tax[];
   ifiTaxAmount: number;
}

export interface Tax {
   netIncomes: number;
   incomeTaxAmount: number;
   numberOfParts: number;
   marginalTaxRate: number;
   percentageOfTheGlobalIncome: number;
   taxDeduction: number;
   childCareAmountDeduction: number;
   employeeAtHomeAmountDeduction: number;
   donationAmountDeduction: number;
   grossTaxAmount: number;
   socialTaxOnRentalIncomes: number;
}

export const TaxSystem = {
   FLAT_TAX: "FLAT_TAX",
   INCOME_TAX: "INCOME_TAX"
};

export type TaxSystem = typeof TaxSystem[keyof typeof TaxSystem];

export interface TaxCredit {
   id: number;
   name: string;
   taxCreditType: TaxCreditType;
   amount?: number;
   beneficiary: Member;
}

export enum TaxCreditType {
   CHILDCARE_DEDUCTIBLE_EXPENSE = "CHILDCARE_DEDUCTIBLE_EXPENSE",
   EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE = "EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE",
   DONATION_DEDUCTIBLE_EXPENSE = "DONATION_DEDUCTIBLE_EXPENSE",
}

// actions
export const TAX_SITUATION_FETCHED = "TAX_SITUATION_FETCHED";
export const MY_TAX_SYSTEM_FETCHED = "MY_TAX_SYSTEM_FETCHED";
export const PARTNER_TAX_SYSTEM_FETCHED = "PARTNER_TAX_SYSTEM_FETCHED";
export const MY_TAX_SYSTEM_UPDATED = "MY_TAX_SYSTEM_UPDATED";
export const UPDATE_PARTNER_TAX_SYSTEM = "UPDATE_PARTNER_TAX_SYSTEM";
export const PARTNER_TAX_SYSTEM_UPDATED = "PARTNER_TAX_SYSTEM_UPDATED";
export const FETCH_TAX_CREDITS = "FETCH_TAX_CREDITS";
export const TAX_CREDITS_FETCHED = "TAX_CREDITS_FETCHED";
export const CREATE_TAX_CREDIT = "CREATE_TAX_CREDIT";
export const TAX_CREDIT_CREATED = "TAX_CREDIT_CREATED";
export const UPDATE_TAX_CREDIT = "UPDATE_TAX_CREDIT";
export const TAX_CREDIT_UPDATED = "TAX_CREDIT_UPDATED";
export const DELETE_TAX_CREDIT = "DELETE_TAX_CREDIT";
export const RESET_TAX_STATE = "RESET_TAX_STATE";
