import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { hasFetchedDashboard } from "store/dashboard/types";
import { ErrorState, ErrorType } from "store/error/types";
import { LOGIN } from "store/login/types";
import { Family } from "store/members/types";
import { State } from "store/store";
import { setSupervision } from "store/supervision/supervisionSlice";
import { SupervisionState } from "store/supervision/types";
import { setTrackingInitStatus } from "store/tracking/slice";
import { TrackingState } from "store/tracking/types";
import { MAINTENANCE } from "website/utils/publicRoutes";
import { useDashboardState } from "./dashboard/useDashboardState";
import {
  LoadingStatus,
  PAGE_NAME_LOADER_CHARGEMENT,
  PAGE_NAME_LOADER_CONNEXION,
} from "./tracking/types";
import { useTracking } from "./tracking/useTracking";
import { useAccount } from "./useAccount";
import { usePrivateData } from "./usePrivateData";
import { getTokenFromParams } from "website/utils/auth/Authentication";

export const useAppInit = () => {
  const [loadingScrapping, setLoadingScrapping] = useState(true);
  const [loadingState, setLoadingState] = useState(true);

  const { idStat } = usePrivateData();

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const account = useAccount();
  const hasFetchedAccount = useSelector<State, boolean>(
    (state) => state.account.hasFetched
  );

  const dashboardState = useDashboardState();
  const { trackPage } = useTracking();

  const { trackingInitStatus, trackingReloading, pageName } = useSelector<
    State,
    TrackingState
  >((state) => state.tracking);

  const members: Family = useSelector<State, Family>(
    (state) => state.family.family
  );
  const hasFetchedMembers = useSelector<State, boolean>(
    (state) => state.family.hasFetched
  );

  const errorState: ErrorState = useSelector<State, ErrorState>(
    (state) => state.error
  );
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const variablesPretesPourTracking =
    idStat !== undefined && supervisionState.hasFetched;

  useEffect(() => {
    if (hasFetchedAccount) {
      dispatch(setSupervision(account.supervision ?? false));
    }
  }, [hasFetchedAccount, dispatch, account.supervision]);

  // On passe les variables en paramètre pour ne pas régénérer la fonction (et donc redéclencher l'init)
  const loadTrackingScript = useCallback(
    (
      statutTracking: LoadingStatus,
      userId = "",
      userIsSupervisor: boolean | undefined = undefined
    ) => {
      const token = sessionStorage.getItem("token") || getTokenFromParams();

      window.tc_vars = {
        env_work: window.env.REACT_APP_COMMANDERS_ENV,
        env_channel: "website",
        env_market: "particuliers",
        env_is_private: true,
        user_id: userId,
        user_is_logged: !!token,
        user_is_identified: true,
        user_is_supervisor: userIsSupervisor,
        page_noload: true,
      };

      const besoinRechargement =
        userId === undefined || userIsSupervisor === undefined;
      try {
        let bddfTmsScript: HTMLScriptElement | null = document.querySelector(
          `script[src="${window.env.REACT_APP_COMMANDERS_TAG_URL}"]`
        );

        if (!bddfTmsScript) {
          bddfTmsScript = document.createElement("script");
          bddfTmsScript.src = window.env.REACT_APP_COMMANDERS_TAG_URL;
          bddfTmsScript.async = true;
          bddfTmsScript.onload = () => {
            dispatch(
              setTrackingInitStatus({
                trackingInitStatus: LoadingStatus.DONE,
                trackingReloading: besoinRechargement,
              })
            );
          };
          bddfTmsScript.onerror = () => {
            dispatch(
              setTrackingInitStatus({
                trackingInitStatus: LoadingStatus.ERROR,
                trackingReloading: besoinRechargement,
              })
            );
          };
          document.body.appendChild(bddfTmsScript);
        } else if (statutTracking !== LoadingStatus.LOADING) {
          // Déjà initialisé, on est OK
          dispatch(
            setTrackingInitStatus({
              trackingInitStatus: statutTracking,
              trackingReloading: besoinRechargement,
            })
          );
        }
      } catch (e) {
        dispatch(
          setTrackingInitStatus({
            trackingInitStatus: LoadingStatus.ERROR,
            trackingReloading: besoinRechargement,
          })
        );
      }
    },
    [dispatch]
  );

  // Charge le tracking dès que possible une et une fois
  useEffect(() => {
    loadTrackingScript(LoadingStatus.LOADING);
  }, [loadTrackingScript]);

  useEffect(() => {
    if (
      variablesPretesPourTracking &&
      trackingInitStatus !== LoadingStatus.LOADING
    ) {
      loadTrackingScript(
        trackingInitStatus,
        idStat,
        supervisionState.estActive
      );
    }
  }, [
    variablesPretesPourTracking,
    loadTrackingScript,
    trackingInitStatus,
    idStat,
    supervisionState.estActive,
  ]);

  useEffect(() => {
    // Tracking chargé, on peut tracker
    if (
      (trackingInitStatus !== LoadingStatus.LOADING ||
        errorState.type === ErrorType.UNAVAILABLE_ERROR) &&
      loadingScrapping
    ) {
      const token = sessionStorage.getItem("token") || getTokenFromParams();

      if (
        pageName !== PAGE_NAME_LOADER_CONNEXION &&
        pageName !== PAGE_NAME_LOADER_CHARGEMENT
      ) {
        const pageField1 = token ? "authentifie" : "non_authentifie";
        trackPage("", PAGE_NAME_LOADER_CONNEXION, "autre", "", "", { page_field_1: pageField1 });
        // Si les variables sont bien alimentées et qu'on a fini de charger le tracking, on considère le scrapping comme chargé
      } else if (
        variablesPretesPourTracking &&
        pageName !== PAGE_NAME_LOADER_CHARGEMENT
      ) {
        trackPage("", PAGE_NAME_LOADER_CHARGEMENT, "autre");
        setLoadingScrapping(false);
      }
    }
  }, [
    trackingReloading,
    trackingInitStatus,
    loadingScrapping,
    errorState,
    variablesPretesPourTracking,
    trackPage,
    pageName,
  ]);

  // On désactive le loader si on est en erreur fatale ou sur la page de maintenance ou login
  // Ou que les données principales sont récupérées
  useEffect(() => {
    if (
      loadingState &&
      (errorState.type === ErrorType.UNAVAILABLE_ERROR ||
        pathname === MAINTENANCE ||
        pathname === LOGIN ||
        ((account.assetsImported || (hasFetchedMembers && members.me.id)) &&
          (hasFetchedDashboard(dashboardState) ||
            errorState.type === ErrorType.FATAL_ERROR)))
    ) {
      setLoadingState(false);
    }
  }, [
    loadingState,
    pathname,
    dashboardState,
    account.assetsImported,
    hasFetchedMembers,
    members?.me,
    errorState.type,
  ]);

  return { loadingScrapping, loadingState };
};
