import React, { FunctionComponent, useEffect, useState } from "react";
import { AssetWithValuation } from "store/assets/types";
import { ElementCard } from "../../atoms/ElementCard/ElementCard";
import { useFeatures } from "website/components/hooks/useFeatures";

interface ActifPatrimoineProps {
  actifPatrimoine: AssetWithValuation;
  onChecked: (checked: boolean, assetId: number) => void;
}

const ActifPatrimoineCard: FunctionComponent<ActifPatrimoineProps> = (
  props: React.PropsWithChildren<ActifPatrimoineProps>
) => {
  const { actifPatrimoine, onChecked } = props;
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleChecked = () => {
    if (actifPatrimoine.id) {
      onChecked(!checked, actifPatrimoine.id);
    }
    setChecked(!checked);
  };

  const { disponibiliteHorsSgPatrimoineSupervision } = useFeatures();

  useEffect(() => {
    setChecked(actifPatrimoine.percentageUsedForRetirement > 0);
    setDisabled(actifPatrimoine.assetType.indexOf("PER") !== -1);
  }, []);

  return (
    <>
      {(actifPatrimoine.externalId !== null ||
        disponibiliteHorsSgPatrimoineSupervision) && (
        <ElementCard
          element={actifPatrimoine}
          checked={checked}
          disabled={disabled}
          onChange={handleChecked}
        />
      )}
    </>
  );
};

export { ActifPatrimoineCard };
