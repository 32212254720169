import React from "react";
import {SGContent} from "sg-layout";
import {PasswordResetInit} from "../components/organisms/Utils/PasswordReset/PasswordResetInit";

const PasswordResetInitPage = () => (
   <SGContent span={8}>
      <PasswordResetInit/>
   </SGContent>
);

export {PasswordResetInitPage};
