import React, { FunctionComponent, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { SGCheckBox } from "sg-checkbox";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedModification } from "sg-icon-pack-base";
import { SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { AssetWithValuation } from "store/assets/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { AssetEditModal } from "website/components/molecules/AssetEditModal/AssetEditModal";
import { EditorContext } from "website/components/providers/EditorProvider";
import { getAssetCategory } from "../../../../store/assets/utils";
import { BoutonSupervision } from "../BoutonSupervision/BoutonSupervision";

interface ElementProps {
  element: AssetWithValuation;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

const ElementCard: FunctionComponent<ElementProps> = (
  props: React.PropsWithChildren<ElementProps>
) => {
  const { checked, disabled, onChange, element } = props;
  const intl = useIntl();
  const [showAssetEditModal, setShowAssetEditModal] = useState<boolean>(false);
  const { percentages, setPercentages } = useContext(EditorContext);
  const assetCategory = getAssetCategory(element.assetType);
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const handleOnSubmitPercentage = (newPercentage: number) => {
    if (element.id) {
      setPercentages({ ...percentages, [element.id]: newPercentage });
    }
    // L'asset est décochée si le pourcentage défini par l'utilisateur est 0
    if (newPercentage === 0 && onChange) {
      onChange();
    }
  };

  return (
    <SGCard
      disableautomargin
      bordered={false}
      borderleft={`4px solid ${assetCategory?.color?.hexCode} !important`}
      coversize="md"
      hoverable
    >
      <SGGridRow align="middle">
        <SGGridCol flex="initial" textalign="center" align="center">
          <SGCheckBox
            key={element.id}
            checked={checked}
            onChange={onChange}
            className="element-card__checkbox"
            disabled={!possedeDroitsActionUtilisateur(supervisionState)}
          />
        </SGGridCol>
        <SGGridCol flex="1 0 0%">
          <SGGridRow>
            <SGGridCol span={12}>
              <SGText>{element.assetName}</SGText>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGText weight="600">
                {intl.formatMessage(
                  { id: "parcoursRevenusComplementaires.estimate.currency" },
                  {
                    salary:
                      element.assetValuation.totalValuation?.toLocaleString(
                        "fr-FR"
                      ),
                    label: (word: string) => <label>{word}</label>,
                  }
                )}
              </SGText>
            </SGGridCol>
          </SGGridRow>
        </SGGridCol>
        {checked &&
          !disabled &&
          percentages &&
          element.id &&
          percentages[element.id] !== undefined && (
            <SGGridCol flex="intial">
              <SGSpace direction="vertical" size="xs" disableautomargin>
                <SGText key="text" weight="600" style={{ color: "#010035" }}>
                  {intl.formatMessage(
                    {
                      id: "parcoursRevenusComplementaires.estimate.asset.percentage",
                    },
                    {
                      percentage:
                        percentages[element.id].toLocaleString("fr-FR"),
                    }
                  )}
                </SGText>
                <BoutonSupervision
                  key="bouton"
                  type="tertiary"
                  icon={<SGIcon component={<SGAvenirStrokedModification />} />}
                  iconposition="right"
                  onClick={() => setShowAssetEditModal(true)}
                >
                  <SGText style={{ color: "#010035" }}>
                    <FormattedMessage id="parcoursRevenusComplementaires.estimate.modifier" />
                  </SGText>
                </BoutonSupervision>
              </SGSpace>
              {showAssetEditModal &&
                element.assetValuation.totalValuation !== undefined && (
                  <AssetEditModal
                    setVisible={setShowAssetEditModal}
                    visible={showAssetEditModal}
                    assetValuation={element.assetValuation.totalValuation}
                    initPercentage={percentages[element.id]}
                    onSubmitPercentage={handleOnSubmitPercentage}
                  />
                )}
            </SGGridCol>
          )}
      </SGGridRow>
    </SGCard>
  );
};

export { ElementCard };
