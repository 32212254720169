import React, { FunctionComponent } from "react";
import { Redirect } from "react-router-dom";
import { SGButtonGroup } from "sg-button";
import { getURLParams, isMobileEnvironment } from "../../../../store/import/utils";
import { SGButtonIntl } from "../../atoms/SGButtonIntl/SGButtonIntl";

interface loginSSOProps {
   redirect: boolean;
}

export const LoginSSO: FunctionComponent<loginSSOProps> = (props: loginSSOProps) => {
   const {redirect} = props;

   const loginSSO = () => {
      window.location.href = `${window.env.REACT_APP_API_URL}${!isMobileEnvironment() ? window.env.REACT_APP_SSO_URL : window.env.REACT_APP_SSO_URL_MOBILE}${encodeURIComponent(getURLParams())}`;
   };

   return (
      <SGButtonGroup align="center">
         <SGButtonIntl type="primary" size="md" cypressName="connexion" onClick={loginSSO} intlId="login.sso"/>
         {redirect &&
         /* @ts-ignore */
         <Redirect to={{pathname: "/auth"}}/>}
      </SGButtonGroup>
   );
};
