import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGText } from "sg-typo";
import { Incomes } from "store/assetIncomes/types";

interface AgeDepartPatrimoineProps {
  ageDepartPatrimoine: Incomes;
}

const AgeDepartPatrimoineCard: FunctionComponent<AgeDepartPatrimoineProps> = (
  props: React.PropsWithChildren<AgeDepartPatrimoineProps>
) => {
  const { ageDepartPatrimoine, children } = props;
  const intl = useIntl();

  return (
    <SGGridRow justify="space-between">
      <SGGridCol flex="inital">
          <SGText size="xl" weight="700">
            {intl.formatMessage(
              {
                id: "parcoursRevenusComplementaires.estimate.ageDepart",
              },
              {
                age: ageDepartPatrimoine.age,
                p: (word) => <SGText size="m" weight="400" whitespace="pre-line">{word}</SGText>,
                month: ageDepartPatrimoine.months ? (
                  ` et ${ageDepartPatrimoine.months} mois`
                ) : (
                  ""
                ),
                date: ageDepartPatrimoine.date
              },
            )}
          </SGText>
      </SGGridCol>
      <SGGridCol flex="1 0 0%" textalign="right">
        <SGText weight="600" color="success" style={{ fontSize: "2rem" }}>
          {intl.formatMessage(
            {
              id: "parcoursRevenusComplementaires.estimate.salary",
            },
            {
              salary:
                ageDepartPatrimoine.checkedAssets !== 0
                  ? ageDepartPatrimoine.income?.toLocaleString("fr-FR")
                  : "--",
              label: (word: string) => (
                <SGText size="l" weight="600">
                  {word}
                </SGText>
              ),
            }
          )}
        </SGText>
      </SGGridCol>
      {children}
    </SGGridRow>
  );
};

export { AgeDepartPatrimoineCard };
