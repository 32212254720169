// State
export interface AccountState {
  account: AccountData;
  hasFetched: boolean;
}

export const isLoggedIn = (accountState: AccountState): boolean =>
  accountState.account.id !== undefined && accountState.account.id !== -1;

export interface AccountData {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  activated: boolean;
  langKey: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  authorities: Array<string>;
  loggedAs: string;
  tunnelCompleted: boolean;
  disclaimerRead: boolean;
  assetsImported: boolean;
  cookieProvider: string;
  ssouser: boolean;
  title: string;
  visitedTaxSimulator: boolean;
  visitedSuccessionSimulator: boolean;
  completedTutorial: boolean;
  potentielSousSegmentNSM: string;
  codeSegmentMarcheNSM?: string;
  // Informe que l'utilisateur est de type supervision
  supervision: boolean;
}

// Actions
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
