import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { fetchWorkIncomesHistory } from "store/workIncomesHistory/action";
import { hasFetchedWorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";

const useWorkIncomesHistory = () => {
   const dispatch = useDispatch();
   const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);

   useEffect(() => {
      if (!hasFetchedWorkIncomesHistory(workIncomesHistoryState)) {
         dispatch(fetchWorkIncomesHistory());
      }
   }, []);

   return workIncomesHistoryState.workIncomesHistory;
};

export { useWorkIncomesHistory };
