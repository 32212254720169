import { FC, useMemo } from "react";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGProgress } from "sg-progress";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGTile } from "sg-tile";
import { SGPrice, SGPriceCommercial, SGText } from "sg-typo";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useParcoursEpargne } from "website/components/hooks/useParcoursEpargne";
import { useParcoursPatrimoine } from "website/components/hooks/useParcoursPatrimoine";
import { PARCOURS_REVENUS_COMPLEMENTAIRES } from "website/utils/privateRoutes";
import { SectionTemplate } from "../SectionTemplate/SectionTemplate";

interface RevenusElementListe {
  tag: string;
  couleur: string;
  montant: number;
  onClick?: () => void;
  showMontant: boolean;
  pageField3Tracking?: string;
  showSkeleton: boolean;
}

interface RevenusProps {
  setShowDrawer: (open: boolean) => void;
}

export const Revenus: FC<RevenusProps> = (props: RevenusProps) => {
  const { setShowDrawer } = props;

  const { selectedRetirementAge } = useSelectedRetirementAge();
  const { isAssetsChecked, isParcoursPatrimoineDone } = useParcoursPatrimoine();
  const { hasObjectif } = useParcoursEpargne();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const { trackClick } = useTracking();
  const history = useHistory();
  const {
    montantRevenusComplementaires,
    montantRevenusAFinancer,
    montantPension,
    montantTotal,
    hasFetchedDashboardState,
    hasFetchedAssetIncomesState,
  } = useMonPlanMontants(selectedRetirementAge);

  const dernierSalaire = selectedRetirementAge?.lastMonthlyIncome;

  const pourcentage = useMemo<number>(
    () => Math.round(((montantTotal ?? 0) * 100) / (dernierSalaire ?? 0)),
    [dernierSalaire, montantTotal]
  );

  const showSkeleton = !(
    hasFetchedAssetIncomesState && hasFetchedDashboardState
  );

  const dataSource: RevenusElementListe[] = useMemo(
    () => [
      {
        tag: "ma pension",
        couleur: "26",
        montant: montantPension || 0,
        showMontant: true,
        showSkeleton: !hasFetchedDashboardState,
      },
      {
        tag: "mon patrimoine",
        couleur: "36",
        montant: montantRevenusComplementaires || 0,
        onClick: () => {
          history.push(PARCOURS_REVENUS_COMPLEMENTAIRES);
        },
        showMontant: isParcoursPatrimoineDone && isAssetsChecked,
        pageField3Tracking: "mon-patrimoine",
        showSkeleton: !hasFetchedAssetIncomesState,
      },
      {
        tag: "mon épargne future",
        couleur: "11",
        montant: montantRevenusAFinancer || 0,
        onClick: () => {
          setShowDrawer(true);
        },
        showMontant: hasObjectif,
        pageField3Tracking: "mon-epargne",
        showSkeleton: !(
          hasFetchedDashboardState && hasFetchedAssetIncomesState
        ),
      },
    ],
    [
      hasFetchedDashboardState,
      hasFetchedAssetIncomesState,
      montantPension,
      montantRevenusComplementaires,
      montantRevenusAFinancer,
      isParcoursPatrimoineDone,
      isAssetsChecked,
      hasObjectif,
    ]
  );

  return (
    <SectionTemplate sectionType={SectionDashBoardType.REVENUS}>
      <SGTile key="tile" clickable>
        <SGSpace direction="vertical" disableautomargin size="md">
          <SGProgress
            arialabel={`Barre de progression représentant le montant total de mes revenus à retraite ${montantTotal} € par rapport à l’estimation de mes revenus en fin de carrière ${dernierSalaire} €.`}
            key="progress"
            context="money"
            showInfo={false}
            size="lg"
            percent={pourcentage}
            status="warning"
            valueMin="0"
            valueMax={`${dernierSalaire}`}
            header={
              <SGGridRow>
                <SGGridCol span={8}>
                  <SGText
                    weight="600"
                    size={isPhone ? "m" : "s"}
                    whitespace="pre-line"
                  >
                    Mes revenus à la retraite{"\n"}
                    <SGText size={isPhone ? "s" : "xs"} weight="400">
                      net/mois
                    </SGText>
                  </SGText>
                </SGGridCol>
                <SGGridCol span={4} textalign="right">
                  <SGPrice
                    size={isPhone ? "l" : "m"}
                    value={`${montantTotal?.toLocaleString("fr-FR") || 0}`}
                  />
                </SGGridCol>
              </SGGridRow>
            }
          />
          {pourcentage < 100 && (
            <SGTile background="Sec_Sg2" key="tile">
              {showSkeleton ? (
                <SGSkeleton title={false} paragraph={{ rows: 3 }} />
              ) : (
                <SGGridRow align="middle">
                  <SGGridCol flex="1 0 0%">
                    <SGText size="xs" weight="600">
                      Écart estimé entre mes revenus à la retraite et
                      l'estimation de mes revenus professionnels en fin de
                      carrière
                      {` ${
                        dernierSalaire?.toLocaleString("fr-FR") || 0
                      }${" "}€`}
                    </SGText>
                  </SGGridCol>
                  <SGGridCol flex="initial">
                    <SGPriceCommercial
                      size="l"
                      unit="%"
                      value={`${pourcentage - 100}`}
                    />
                  </SGGridCol>
                </SGGridRow>
              )}
            </SGTile>
          )}
          <SGText key="text" titlerender size={isPhone ? "l" : "m"}>
            Détail de mes revenus à la retraite
          </SGText>

          <SGSpace key="space" direction="vertical">
            {dataSource.map((item: RevenusElementListe) => (
              <SGGridRow key={`item${item.tag}`} align="middle" gutter={[0, 0]}>
                <SGGridCol flex="0 0 0%">
                  <SGTag tagcolor={item.couleur}>
                    <SGText whitespace="nowrap">{item.tag}</SGText>
                  </SGTag>
                </SGGridCol>
                <SGGridCol flex="0 0 0%">
                  <SGText size="xs">net/mois</SGText>
                </SGGridCol>
                <SGGridCol flex="1 0 0%" textalign="right">
                  {item.showSkeleton ? (
                    <SGBox span={1} inline>
                      <SGSkeleton title={false} paragraph={{ rows: 1 }} />
                    </SGBox>
                  ) : (
                    <>
                      {item.showMontant ? (
                        <SGPrice
                          size={isPhone ? "l" : "m"}
                          value={item.montant.toLocaleString("fr-FR")}
                        />
                      ) : (
                        <SGButton
                          type="link"
                          onClick={() => {
                            if (item.pageField3Tracking && item.onClick) {
                              trackClick(
                                `clic-sur-a-completer-detail-de-mes-revenus-a-la-retraite`,
                                { page_field_3: item.pageField3Tracking }
                              );
                              item.onClick();
                            }
                          }}
                        >
                          <SGText size={isPhone ? "l" : "m"}>
                            à compléter
                          </SGText>
                        </SGButton>
                      )}
                    </>
                  )}
                </SGGridCol>
              </SGGridRow>
            ))}
          </SGSpace>
        </SGSpace>
      </SGTile>
    </SectionTemplate>
  );
};
