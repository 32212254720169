import {call, put, takeLeading} from "redux-saga/effects";
import {Feedback, FETCH_HAS_SENT_FEEDBACK, SEND_FEEDBACK} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiGet, apiPost} from "../apiCaller";
import {PayloadAction} from "@reduxjs/toolkit";
import {hasSentFeedbackFetched} from "./feedbackSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeLeading(SEND_FEEDBACK, safe(onDefaultError, handleSendFeedback));
   yield takeLeading(FETCH_HAS_SENT_FEEDBACK, safe(onDefaultError, handleHasSentFeedback));
}

function* handleSendFeedback(action: PayloadAction<Feedback>) {
   yield call(apiPost, `wealth/api/feedback`, action.payload);
   yield put(hasSentFeedbackFetched(true));
}

function* handleHasSentFeedback() {
   const payload: AxiosResponse<boolean> = yield call(apiGet, `wealth/api/feedback`);
   yield put(hasSentFeedbackFetched(payload.data));
}
