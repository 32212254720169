import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGTitle } from "sg-typo";
import "./Loader.scss";

interface LoaderProps {
    title?: string;
}

const Loader: FunctionComponent<LoaderProps> = (props: LoaderProps) => {
    const { title } = props;
    const intl = useIntl();

    return (
        <div className="loader__container">
            <SGGridRow justify="center">
                <SGGridCol>
                    <div className="loader" />
                </SGGridCol>
            </SGGridRow>
            {title && (
                <SGGridRow justify="center">
                    <SGGridCol>
                        <SGTitle level={4} visuallevel={5}>
                            {intl.formatMessage({ id: title })}
                        </SGTitle>
                    </SGGridCol>
                </SGGridRow>
            )}
        </div>
    );
};

export { Loader };
