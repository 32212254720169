import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet, apiPost } from "store/apiCaller";
import { onApiCallErrorNoError, safe } from "store/error/utils";
import { FETCH_PERSONAL_INFORMATIONS, PersonalInformations, PERSONAL_INFORMATIONS_FETCHED, PERSONAL_INFORMATIONS_SAVED, SAVE_PERSONAL_INFORMATIONS } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_PERSONAL_INFORMATIONS, safe(onApiCallErrorNoError, handleFetchPersonalInformations));
   yield takeLeading(SAVE_PERSONAL_INFORMATIONS, safe(onApiCallErrorNoError, handleSavePersonalInformations));
}

/**
 * Récupère les informations personnelles RIS d'un utilisateur
 * @param action Action contenant l'id de l'utilisateur
 */
function* handleFetchPersonalInformations() {
   const payload: AxiosResponse<PersonalInformations> = yield call(apiGet, `/backpfr/api/user-personal-informations`);
   yield put({ type: PERSONAL_INFORMATIONS_FETCHED, payload: payload.data });
}

/**
 * Mets à jour les informations personelles RIS d'un utilisateur
 * @param action Action contenant PersonalInformations
 */
function* handleSavePersonalInformations(action: PayloadAction<PersonalInformations>) {
   yield call(apiPost, `/backpfr/api/user-personal-informations`, action.payload);
   yield put({ type: PERSONAL_INFORMATIONS_SAVED, payload: action.payload });
}
