import React, {FunctionComponent} from "react";
import "./CGU.scss";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";

const CGU: FunctionComponent = () => (
        <>
           <div className="blockCGU">
              <div className="blockCGU__content">
                 <SGTitleIntl intlId="cgu.title" cypressName="cgu.page.title"/>
                 <p>Ce site est publié par Société Générale.</p>
                 <p>Société anonyme au capital de 1 046 405 540 euros au 1er février 2022.</p>
                 <p>Immatriculée au RCS de Paris sous le numéro unique d’identification 552 120 222<br/>
                    Numéro APE : 651C<br/>
                    Siège social : 29 boulevard Haussmann 75009 Paris<br/>
                    No TVA : FR 27 552 120 222<br/>
                    Numéro de téléphone : + 33 01 42 14 20 00</p>
                 <p>Directeur de la publication : M. Frédéric OUDEA, Directeur Général</p>
                 <p>Responsable de la rédaction : Arnaud MIOSSEC<br/>
                    Contact : arnaud.miossec@socgen.com</p>
                 <p>Ce site est hébergé par les serveurs de la Société Générale</p>
                 <h2>Conditions générales d’utilisation</h2>
                 <p>Dernière date de mise à jour : 14/12/2021</p>
                 <p><strong>Réglementation professionnelle</strong><br/>
                    Société Générale est un établissement de crédit de droit français agréé par l’Autorité de Contrôle Prudentiel et de Résolution (« ACPR
                    », 4
                    place de Budapest CS 92459 75436 Paris Cedex 09) contrôlé par l’Autorité des Marchés Financiers (« AMF ») et sous la supervision
                    prudentielle
                    de la Banque Centrale Européenne (« BCE »). Société Générale, en qualité d’établissement de crédit agréé pour la fourniture de services
                    d’investissement, est ainsi habilitée, à effectuer toutes opérations de banque et à fournir tous les services d’investissement à
                    l’exception
                        du service d’investissement d’exploitation d’un système multilatéral de négociation ou d’un système organisé de négociation.</p>
                    <h3>ARTICLE 1 : CHAMP D’APPLICATION</h3>
                    <p>Les présentes conditions générales d’utilisation (les «&nbsp;CGU&nbsp;») ont pour objet de définir les
                        conditions
                        dans lesquelles vous pouvez consulter le site internet https://elyxir.coach/ (le «&nbsp;Site&nbsp;»). En naviguant sur le Site, vous
                        reconnaissez avoir pris connaissance et accepté les CGU.</p>
                    <p>Le Site s’adresse à des professionnels ou institutionnels, et a pour objet de présenter la Solution « Elyxir »,
                        une
                        solution technique innovante et unique pouvant être mise à disposition de vos clients, et qui leur permet d’accéder à un outil d’aide à
                        l’analyse de leur situation patrimoniale sur la base de simulations digitalisées et personnalisées au regard des informations
                        renseignées.</p>
                    <h3>ARTICLE 2 : CONTENU DU SITE</h3>
                    <p>Société Générale s’efforce d’assurer l’exactitude et la mise à jour des informations diffusées sur ce Site,
                        dont elle
                        se réserve le droit de corriger, à tout moment et sans préavis, le contenu. Elle ne peut cependant en garantir l’exhaustivité ou
                        l’absence de
                        modification par un tiers (intrusion, virus). En outre, Société Générale décline toute responsabilité en cas de difficulté ou
                        d’impossibilité
                        d’accéder au Site due à un problème de connexion internet.</p>
                    <p>La responsabilité de Société Générale ne saurait être retenue en cas de dommages indirects tels que, sans que
                        cette
                        liste soit exhaustive, les pertes découlant des transactions effectuées sur la base des informations figurant sur le site, les pertes de
                        profit, pertes d’affaires et pertes découlant d’une interruption du service due à un problème de connexion internet.</p>
                    <p>De même, Société Générale ne saurait être tenue responsable des éléments en dehors de son contrôle et des
                        dommages
                        qui pourraient éventuellement être causés par l’environnement technique des utilisateurs du présent Site, notamment les ordinateurs,
                        logiciels, équipements réseaux (modems, téléphones…) et tout matériel utilisé pour accéder à ou utiliser le service et/ou les
                        informations.</p>
                    <h3>ARTICLE 3 : PROPRIETE INTELLECTUELLE</h3>
                    <p>L’ensemble de ce Site et chacun de ses éléments pris séparément relèvent de la législation française et
                        internationale sur le droit d’auteur, le droit des marques et des bases de données et, de façon générale, sur la propriété
                        intellectuelle,
                        aussi bien en ce qui concerne sa forme (choix, plan, disposition des matières, moyens d’accès aux données, organisation des données…),
                        qu’en
                        ce qui concerne chacun des éléments de son contenu (textes, images, vidéos, etc…). Le Site et les éléments sont la propriété exclusive
                        de
                        Société Générale ou la propriété de tiers ayant accordé une licence celle-ci. Toute reproduction, représentation, diffusion ou
                        rediffusion, en
                        tout ou partie, du contenu de ce site sur quelque support ou par tout procédé que ce soit (notamment par voie de caching, framing) de
                        même que
                        toute vente, revente, retransmission ou mise à disposition de tiers de quelque manière que ce soit sont interdites sauf autorisation
                        expresse
                        et préalable du directeur de la publication. Le non-respect de cette interdiction constitue une contrefaçon susceptible d’engager la
                        responsabilité civile et pénale du contrefacteur.<br/>
                        Seule la reproduction papier est autorisée sous conditions d’une utilisation à des fins strictement personnelles, du respect de
                        l’intégrité des documents reproduits, ou en courte citation avec mention claire et lisible de la source, par exemple sous la forme
                        suivante «&nbsp;Extrait du site Elyxir. Tous droits réservés Groupe Société Générale.&nbsp;»<br/>
                        Les marques citées sur le Site sont protégées : leur reproduction ou utilisation, de quelque sorte, est interdite.</p>
                    <p>Les liens hypertextes établis en direction d’autres sites ne sauraient engager la responsabilité de Société
                        Générale,
                        notamment s’agissant du contenu de ces sites.</p>
                    <p>Société Générale n’est pas responsable des liens hypertextes pointant vers le présent Site.</p>
                    <h3>ARTICLE 4 : DONNEES PERSONNELLES</h3>
                    <p>A l’occasion de la consultation du Site, Société Générale peut être conduite à collecter des données
                        personnelles
                        vous concernant, en qualité de responsable de traitement, aux fins de répondre à vos demandes de contact. Le cas échéant, en vous
                        référant aux
                        informations contenues dans l’onglet «&nbsp;Nous contacter&nbsp;», vous êtes invité à contacter Société Générale par email..</p>
                    <p>Les données collectées dans ce cadre (i.e. votre adresse email et toute information personnelle que vous
                        pourriez
                        nous transmettre dans le cadre de cet email) font l’objet de traitements. Ces traitements relèvent de l’intérêt légitime de Société
                        Générale à
                        traiter et répondre à vos demandes d’information</p>
                    <p>Par ailleurs, si des informations non strictement nécessaires à l’exécution des finalités ci-dessus mentionnées
                        sont
                        collectées, elles le seront avec votre consentement préalable. Vous pourrez, à tout moment, retirer votre consentement.</p>
                    <p>Sauf dispositions législatives ou réglementaires contraires, les données personnelles sont conservées pour une
                        durée
                        de trois (3) ans après votre dernier contact avec la Société Générale en rapport avec les produits et services Elyxir.</p>
                    <p>Pour l’accomplissement desdites finalités, Société Générale peut être amenée à transférer les informations
                        recueillies à ses entités, prestataires ainsi qu’à ses partenaires établis en dehors de l’Espace Économique Européen. Ces transferts de
                        données interviennent dans des conditions et sous des garanties propres à assurer la protection de vos données personnelles
                        (autorisation par
                        l’autorité de protection des données, engagement contractuel avec les prestataires concernés et/ou Binding Corporate Rules du groupe
                        Société
                        Générale).</p>
                    <p>Vous disposez d’un droit d’accès à vos données personnelles, de rectification et d’effacement, de limitation de
                        traitement, d’opposition ainsi que d’un droit à la portabilité dans les conditions prévues par la réglementation applicable, à l’adresse
                        suivante : <a href="http://protectiondesdonnées@societegenerale.fr">protectiondesdonnées@societegenerale.fr</a></p>
                    <p>Vous avez aussi la possibilité de vous adresser au Délégué à la Protection des Données de Société Générale à
                        l’adresse <a href="http://protectiondesdonnées@societegenerale.fr">protectiondesdonnées@societegenerale.fr</a> et le droit d’introduire
                        une
                        réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), autorité de contrôle en charge du respect des
                        obligations en matière de données à caractère personnel.</p>
                    <h3>ARTICLE 5 : COOKIES</h3>
                    <p>Nous n’envoyons pas de cookie depuis ce site.</p>
                    <p><strong>Qu’est-ce qu’un cookie ?</strong><br/>
                        Un cookie est un fichier texte susceptible d’être enregistré dans un espace dédié du disque dur de votre terminal* à l’occasion de la
                        consultation d’un service en ligne grâce à votre logiciel de navigation. Un fichier cookie permet à son émetteur d’identifier le
                        terminal*
                        dans lequel il est enregistré, pendant la durée de validité ou d’enregistrement dudit cookie.</p>
                    <p>Le terme « cookie » désigne l’ensemble des technologies permettant de tracer la navigation d’un
                        utilisateur.</p>
                    <p>* le terminal désigne l’équipement matériel (ordinateur, tablette, Smartphone…) que vous utilisez pour
                        consulter ou
                        voir s’afficher un site, une application, un contenu publicitaire etc.</p>
                    <p><strong>Pourquoi nous utilisons des cookies ?</strong><br/>
                        Société Générale n’utilise sur le Site que des cookies :</p>
                    <ul>
                        <li>indispensables à la navigation et qui vous permettent d’utiliser les principales fonctionnalités du Site, de permettre et
                            d’optimiser son
                            fonctionnement. Ils vous permettent également d’adapter la présentation de notre Site aux préférences d’affichage de votre terminal.
                            Ces
                            cookies vous permettent ainsi d’avoir une navigation fluide et sur mesure ;
                        </li>
                        <li>&nbsp;statistiques, permettant d’effectuer des mesures d’audience anonymes du contenu visualisé. Les données recueillies dans ce
                            cadre ne
                            sont jamais recoupées avec d’autres traitements ni transmises à des tiers. Ces cookies ne permettent pas non plus de suivre votre
                            navigation.<br/>
                            Vous avez la possibilité de vous opposer à ce que vos données de navigation alimentent ces traitements de données statistiques
                            réalisés dans l’intérêt légitime de Société Générale.
                        </li>
                    </ul>
                    <p>Veuillez noter que le blocage complet de vos cookies via l’intermédiaire de votre navigateur ou d’ad-blockers
                        (y
                        compris des cookies liés au fonctionnement du Site), peut entraîner des dysfonctionnements comme l’impossible accès à certaines pages du
                        Site
                        ou à votre espace client sécurisé.</p>
                    <h3>ARTICLE 6 : MODIFICATION DES CONDITIONS D’UTILISATION</h3>
                    <p>Société Générale se réserve la possibilité d’adapter ou de modifier à tout moment les termes des présentes
                        CGU.</p>
                    <h3>ARTICLE 7 : FIN DU SERVICE</h3>
                    <p>Société Générale se réserve la possibilité de fermer le Site à tout moment et pour quelque raison que ce soit
                        sans
                        avoir à le justifier.</p>
                    <h3>ARTICLE 8 : LOI APPLICABLE</h3>
                    <p>Les présentes CGU sont soumises au droit français.</p>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </>
);

export {CGU};
