import { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGScroll } from "sg-scroll";
import { SGText } from "sg-typo";
import { Dashboard } from "store/dashboard/types";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useWorkIncomes } from "website/components/hooks/useWorkIncomes";
import { MaxRisErrorModal } from "website/components/molecules/MaxRisErrorModal/MaxRisErrorModal";
import { toddMMyyyyStringWithSlash } from "website/utils/date/DateUtils";
import { PARCOURS_RIS } from "website/utils/privateRoutes";
import "./MaPension.scss";
import { SGBox } from "sg-space";
import { SGLink } from "sg-link";

interface MaPensionProps {
  dashboard?: Partial<Dashboard>;
  isParcoursRISDone: boolean;
}

const MaPension: FunctionComponent<MaPensionProps> = (
  props: MaPensionProps
) => {
  const intl = useIntl();
  const history = useHistory();
  const { dashboard, isParcoursRISDone } = props;

  const workIncomes = useWorkIncomes();
  const [accessibiliteRisClicked, setAccessibiliteRisClicked] =
    useState<boolean>(false);
  const { disponibiliteParcoursRIS } = useFeatures();
  const { trackClick } = useTracking();

  const redirectToParcoursRIS = () => {
    history.push(PARCOURS_RIS);
  };

  const onClickRedirectToParcoursRIS = () => {
    trackClick(
      `ma-pension::clic-sur-${
        isParcoursRISDone ? "je-modifie-mon-releve" : "j-analyse-mon-releve"
      }`
    );
    setAccessibiliteRisClicked(true);
  };

  return (
    <SGCard
      coversize="md"
      hoverable
      borderleft="7px solid #9F2D62"
      title={
        <SGText
          style={{
            color: "#9F2D62",
          }}
          size="xl"
          weight="600"
        >
          {intl.formatMessage({ id: "monPlan.pension.title" })}
        </SGText>
      }
      className="ma-pension__card"
    >
      <SGScroll disableautomargin>
        {isParcoursRISDone && workIncomes?.length ? (
          workIncomes.map((income) => (
            <SGGridRow key={income.profession}>
              <SGGridCol>
                <SGText>
                  <SGTextIntl
                    intlId="monPlan.pension.ris-done"
                    size="m"
                    transformations={{
                      profession: income.profession,
                      revenus: income.income.toLocaleString("fr-FR"),
                      statut: income.statut,
                      b: (word: string) => <strong>{word}</strong>,
                    }}
                  />
                </SGText>
              </SGGridCol>
            </SGGridRow>
          ))
        ) : (
          <SGGridRow>
            {workIncomes !== undefined && (
              <SGGridCol>
                <SGText>
                  <SGTextIntl
                    intlId="monPlan.pension.no-ris"
                    size="m"
                    transformations={{
                      secteurPro:
                        workIncomes &&
                        workIncomes?.length &&
                        workIncomes[0].profession,
                      statut:
                        workIncomes &&
                        workIncomes?.length &&
                        workIncomes[0].statut,
                      revenus:
                        workIncomes &&
                        workIncomes?.length &&
                        workIncomes[0].income.toLocaleString("fr-FR"),
                      b: (word: string) => <strong>{word}</strong>,
                    }}
                  />
                </SGText>
              </SGGridCol>
            )}
          </SGGridRow>
        )}
        {isParcoursRISDone && (
          <SGGridRow>
            <SGGridCol>
              <SGText>
                <SGTextIntl
                  intlId="monPlan.pension.trimestres"
                  size="m"
                  transformations={{
                    risDate:
                      dashboard &&
                      dashboard.risDate &&
                      toddMMyyyyStringWithSlash(new Date(dashboard.risDate)),
                    nbTrimestres: dashboard?.acquiredQuarters,
                    nbTrimestresTotal:
                      dashboard?.acquiredQuarters &&
                      dashboard?.remainingQuarters &&
                      dashboard.acquiredQuarters + dashboard.remainingQuarters,
                    b: (word: string) => <strong>{word}</strong>,
                  }}
                />
              </SGText>
            </SGGridCol>
            <SGGridCol>
              <SGText>
                <SGTextIntl
                  intlId="monPlan.pension.ris-update"
                  size="m"
                  transformations={{
                    risUpdateDate:
                      dashboard &&
                      dashboard.firstRISPathDate &&
                      toddMMyyyyStringWithSlash(
                        new Date(dashboard.firstRISPathDate)
                      ),
                    b: (word: string) => <strong>{word}</strong>,
                  }}
                />
              </SGText>
            </SGGridCol>
          </SGGridRow>
        )}
      </SGScroll>
      <SGMediaQuery minwidth={0} maxwidth="sm">
        <SGBox margin={{ top: "sm" }} textalign="right">
          <SGLink
            href="#/ma-checklist/pension"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconside="right"
            type="tertiary"
            onClick={() => trackClick("ma-pension::clic-sur-ma-checklist")}
          >
            {intl.formatMessage({ id: "monPlan.checklist" })}
          </SGLink>
        </SGBox>
      </SGMediaQuery>
      {disponibiliteParcoursRIS && (
        <SGBox margin={{ top: "sm" }} textalign="right">
          <SGButton
            onClick={onClickRedirectToParcoursRIS}
            type="tertiary"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconposition="right"
          >
            {intl.formatMessage({
              id: isParcoursRISDone
                ? "monPlan.pension.modify"
                : "monPlan.pension.create",
            })}
          </SGButton>
        </SGBox>
      )}
      <MaxRisErrorModal
        accessibiliteRisClicked={accessibiliteRisClicked}
        setAccessibiliteRisClicked={setAccessibiliteRisClicked}
        redirectToParcoursRIS={redirectToParcoursRIS}
      />
    </SGCard>
  );
};

export { MaPension };
