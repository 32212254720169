import { Fragment, FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGText } from "sg-typo";
import { estAgeDepartAnticipeSapiendo } from "store/parcoursRIS/utils";
import {
  RetirementAge,
  agesDepartAnticipe,
} from "store/parcoursRisProjection/types";
import { RISProgressBar } from "website/components/atoms/RISProgressBar/RISProgressBar";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { formatDate } from "website/utils/date/DateUtils";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";
import "./RetirementPensionEstimation.scss";

interface RetirementPensionEstimationProps {
  dateDepart: RetirementAge;
}

export const RetirementPensionEstimation: FunctionComponent<
  RetirementPensionEstimationProps
> = (props: RetirementPensionEstimationProps) => {
  const { dateDepart } = props;
  const intl = useIntl();
  const perte = Math.round(
    ((dateDepart.dernier_revenu_estime_mensuel - dateDepart.pension_mensuelle) /
      dateDepart.dernier_revenu_estime_mensuel) *
      100
  );
  const dernierRevenuMensuel = roundDown10(
    dateDepart.dernier_revenu_estime_mensuel
  );
  const pensionMensuelle = roundDown10(dateDepart.pension_mensuelle);
  const dateDepartFormatee = formatDate(
    dateDepart.date,
    "-"
  ).toLocaleDateString("fr", {
    year: "numeric",
    month: "long",
  });

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const getProgressPerte = () => (
    <SGBox key="box-perte">
      <SGGridRow align="middle">
        <SGGridCol flex="0 0 0%">
          <SGTag disableautomargin tagcolor="45">
            {perte < 0 ? 0 : -perte}%
          </SGTag>
        </SGGridCol>
        <SGGridCol flex="1 0 0%">
          <SGTextIntl
            style={{ color: "#747374" }}
            intlId="parcoursRIS.resultat.progress.perte"
          />
        </SGGridCol>
      </SGGridRow>
    </SGBox>
  );

  return (
    <SGSpace direction="vertical" size="sm">
      <SGBox key="box-entete">
        <SGGridRow>
          <SGGridCol flex="auto">
            <SGText titlerender weight="600" size="xl">
              {`${dateDepart.age.years} ans ${
                dateDepart.age.months > 0
                  ? `et ${dateDepart.age.months} mois`
                  : ""
              }`}
            </SGText>
            <SGText style={{ color: "#747374" }}>
              {` en ${dateDepartFormatee}`}
            </SGText>
          </SGGridCol>
          {estAgeDepartAnticipeSapiendo(dateDepart) && (
            <SGGridCol flex="initial">
              {/* Afficher le tag si une des valeurs de types_depart est parmi typesDepartAnticipe */}
              <SGTag
                disableautomargin
                border
                tagcolor="0"
                style={{ border: "1px solid rgb(0, 0, 0)" }}
              >
                <SGTextIntl intlId="parcoursRIS.resultat.tag.departAnticipe" />
              </SGTag>
            </SGGridCol>
          )}
        </SGGridRow>
      </SGBox>

      <SGLayout key="layout" component="div">
        <SGText style={{ color: "#747374" }} size="l">
          {dateDepart.types_depart.map((typeDepart: number, index: number) => (
            <Fragment key={`type-${typeDepart}`}>
              {index > 0 && " = "}
              <SGText>
                {intl.formatMessage({
                  id: `parcoursRIS.resultat.subtitle${typeDepart}`,
                })}
              </SGText>
              {agesDepartAnticipe.includes(typeDepart) && (
                <InfoTooltip
                  text={`parcoursRIS.resultat.subtitle${typeDepart}.tooltip`}
                  isModal={isPhone}
                />
              )}
            </Fragment>
          ))}
        </SGText>
      </SGLayout>

      {isPhone && (
        <RISProgressBar
          key="progess-1"
          type="pension"
          value={pensionMensuelle}
        />
      )}
      {isPhone && (
        <RISProgressBar
          key="progess-2"
          type="salaire"
          value={dernierRevenuMensuel}
        />
      )}
      {isPhone && getProgressPerte()}
      {!isPhone && (
        <SGBox key="box-pension-salaire">
          <SGGridRow>
            <SGGridCol span={6}>{getProgressPerte()}</SGGridCol>
            <SGGridCol flex="auto" />
            <SGGridCol flex="initial">
              <RISProgressBar type="pension" value={pensionMensuelle} />
              <RISProgressBar type="salaire" value={dernierRevenuMensuel} />
            </SGGridCol>
          </SGGridRow>
        </SGBox>
      )}
    </SGSpace>
  );
};
