import React, {FunctionComponent} from "react";
import NumberFormat, {NumberFormatProps} from "react-number-format";
import {useDemoMode} from "../../components/hooks/useDemoMode";

const CustomNumberFormat: FunctionComponent<NumberFormatProps> = (props: NumberFormatProps) => {
   const demoMode = useDemoMode();

   // @ts-ignore
   return <NumberFormat className={demoMode ? "blur-3" : ""} {...props}/>
};

export {CustomNumberFormat};
