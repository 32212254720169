import {call, put, takeLeading} from "redux-saga/effects";
import {AxiosError, AxiosResponse} from "axios";
import {setAuthToken} from "../../website/utils/axios";
import {onDefaultError, safe} from "../error/utils";
import {apiPost} from "../apiCaller";
import {getAccountAction} from "../account/actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {SIGNUP, SignUp} from "./types";
import {signupErrorEmailInvalid, signupErrorLoginAlreadyUsed, signupSuccess} from "./signupSlice";
import {loginSuccess} from "../login/loginSlice";
import {isElyxirAxiosError, isStringAxiosError} from "store/error/typeguards";

export function* watcherSaga() {
   yield takeLeading(SIGNUP, safe(onSignupError, handleSignUp));
}

function* handleSignUp(action: PayloadAction<SignUp>) {
   /* eslint-disable camelcase */
   const payload: AxiosResponse<{ id_token: string }> = yield call(apiPost, "api/account/signup", action.payload);
   sessionStorage.setItem("token", payload.data.id_token);
   setAuthToken(payload.data.id_token);
   yield put(getAccountAction());
   yield put(loginSuccess());
   yield put(signupSuccess());
}

function* onSignupError(err: AxiosError) {
   const isEmailAlreadyExists = err.response?.status === 400 && isStringAxiosError(err) && err.response?.data === "email address already in use";
   const isEmailInvalid = err.response?.status === 400 && isElyxirAxiosError(err) && err.response?.data?.message === "error.validation";

   if (isEmailAlreadyExists) {
      yield put(signupErrorLoginAlreadyUsed());
   } else if (isEmailInvalid) {
      yield put(signupErrorEmailInvalid());
   } else {
      onDefaultError(err);
   }
}
