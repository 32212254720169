import { Reducer } from "react";
import { RETIREMENT_SIMULATION_FETCHED, RetirementSimulationParametersState, UPDATE_RETIREMENT_SIMULATION, FETCH_RETIREMENT_SIMULATION_ERROR, NEXT_STEP_SIMULATION, PREVIOUS_STEP_SIMULATION, RESET_PARCOURS_SIMULATION } from "./types";

export const RetirementSimulationParametersReducer: Reducer<RetirementSimulationParametersState, any> = (currentState = {} as RetirementSimulationParametersState, action): RetirementSimulationParametersState => {
   switch (action.type) {
      case RESET_PARCOURS_SIMULATION:
         return {
            ...currentState,
            retirementSimulationParameters: {
               ...currentState.retirementSimulationParameters,
               currentStep: 0,
               isParcoursDone: false,
            }
         };
      case NEXT_STEP_SIMULATION:
         return {
            ...currentState,
            retirementSimulationParameters: {
               ...currentState.retirementSimulationParameters,
               currentStep: currentState.retirementSimulationParameters.currentStep ? currentState.retirementSimulationParameters.currentStep + 1 : 0,
            }
         };
      case PREVIOUS_STEP_SIMULATION:
         return {
            ...currentState,
            retirementSimulationParameters: {
               ...currentState.retirementSimulationParameters,
               currentStep: currentState.retirementSimulationParameters.currentStep ? currentState.retirementSimulationParameters.currentStep - 1 : 0,
            }
         };
      case RETIREMENT_SIMULATION_FETCHED:
         return { retirementSimulationParameters: action.payload };
      case FETCH_RETIREMENT_SIMULATION_ERROR:
         return {
            ...currentState,
            retirementSimulationParameters: {
               ...currentState.retirementSimulationParameters,
               simulationErrorCode: action.payload.code,
               simulationErrorMessage: action.payload.message,
            }
         };
      case UPDATE_RETIREMENT_SIMULATION:
         return {
            ...currentState,
            retirementSimulationParameters: action.payload,
         };
      default:
         return currentState;
   }
};
