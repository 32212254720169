import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { State } from "store/store";
import { possedeDroitsActionUtilisateur } from "store/supervision/types";

let store: ToolkitStore<State>;

/**
 * Injection du store pour accès
 * @param pStore Store redux
 */
export const injectStore = (pStore: ToolkitStore) => {
  store = pStore;
};

/**
 * Requêtes non GET autorisées.
 * A voir si l'url seule suffit
 */
const requeteNonGetAutorisees = [
  "wealth/api/sg-import/assets-and-loans?createIfNotExist=true",
  "wealth/api/sg-import/assets-and-loans?createIfNotExist=false",
  "wealth/api/sg-import/init/assets?createIfNotExist=true",
  "wealth/api/sg-import/init/assets?createIfNotExist=false",
  "wealth/api/sg-import/init/members",
  "api/account",
  "/backpfr/api/userRetirementPath",
  "/backpfr/api/simulation-access/simulateur-fiscal-economie-impot",
  "/backpfr/api/simulation-access/simulateur-plafond-economie-impot",
  "/backpfr/api/simulation-access/simulateur-fiscal-plafond-disponible-tns",
];

const http = axios.create({
  baseURL: window?.env?.REACT_APP_API_URL || "http://localhost:8080",
});

http.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const configuration = config;

    const token = sessionStorage.getItem("token");

    if (
      configuration.headers &&
      !configuration.headers.Authorization &&
      token
    ) {
      configuration.headers.Authorization = `Bearer ${token}`;
    }

    const controller = new AbortController();

    if (besoinAnnulerRequete(configuration)) {
      controller.abort();
    }

    return {
      ...configuration,
      signal: controller.signal,
    };
  },
  (err) => {
    console.warn("error in getting ", err);
  }
);

export const setAuthToken = (token: string) => {
  if (token) {
    // applying token
    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // deleting the token from header
    delete http.defaults.headers.common.Authorization;
  }
};

/**
 * Détermine si une requête doit être annulée ou non.
 * Si la déconnexion est demandée, on bloque.
 * Si l'utilsateur a les droits de modification ou que la requête est GET, pas de blocage.
 * Sinon, certaines requêtes POST/PUT/etc sont autorisées.
 *
 * @param config Informations de la requête
 * @returns Vrai s'il faut annuler la requête
 */
const besoinAnnulerRequete = (config: AxiosRequestConfig): boolean => {
  const state = store.getState();

  return (
    state.login.forceLogOut ||
    // Si on n'a pas les droits de modifier
    (!possedeDroitsActionUtilisateur(state.supervision) &&
      // Que nous ne sommes pas en GET
      config.method !== "get" &&
      // ET que l'url ne fait pas partie des requêtes autorisées (si POST/PUT/...)
      !requeteNonGetAutorisees.includes(config.url ?? ""))
  );
};

export { http };
