import sapiendoImg from "assets/images/sapiendo-baseline.svg";
import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGText, SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { User, hasFetchedDashboard } from "store/dashboard/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import "./DisclaimerSeiModal.scss";

const DisclaimerSeiModal: FunctionComponent = () => {
    const [visible, setVisible] = useState(false);
    const intl = useIntl();
    const dashboardState = useDashboardState();
    const dispatch = useDispatch();
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'sm' });
    const { trackPopin, resetTracking } = useTracking();

    const acceptDisclaimerSei = () => {
        trackPopin(true, "parcours-sei", "popin-information-intro::clic-sur-j-ai-compris", "simulation");

        resetTracking();
        const req: User = {
            disclaimerSeiAcceptanceDate: new Date(),
        };
        dispatch(updatePrefs(req));
    };

    useEffect(() => {
        if (hasFetchedDashboard(dashboardState)) {
            setVisible(dashboardState.dashboard.disclaimerSeiAcceptanceDate === undefined)
        }
    }, [dashboardState]);

    useEffect(() => {
        if (visible) {
            trackPopin(false, "parcours-sei", "popin-information-intro", "simulation");
        }
    }, [visible]);

    return (
        <SGModal className="disclaimer-sei-modal" visible={visible} closable={false}>
            <SGGridRow gutter={[0, 10]}>
                <SGGridCol span={12} textalign="left">
                    <SGGridRow>
                        <SGGridCol>
                            <SGTitle caps visuallevel={2}>
                                {intl.formatMessage({ id: `disclaimer.sei.modal.title` })}
                            </SGTitle>
                        </SGGridCol>
                        <SGGridCol span={3} offset={6} sm={{ offset: 5 }} align="start" />
                    </SGGridRow>
                </SGGridCol>
                <SGGridCol span={12} textalign="left">
                    <SGScroll disableautomargin>
                        <SGGridRow gutter={[0, 20]}>
                            <SGGridCol span={12}>
                                <SGGridRow gutter={[30, 0]}>
                                    <SGMediaQuery minwidth="sm">
                                        <SGGridCol span={3} align="start">
                                            <img className="disclaimer-sei-modal__img" src={sapiendoImg} alt="logo-sapiendo" />
                                        </SGGridCol>
                                    </SGMediaQuery>
                                    <SGGridCol span={12} sm={9} textalign="left">
                                        <SGText size="m">
                                            {intl.formatMessage({ id: "disclaimer.sei.modal.paragraph1", })}
                                        </SGText>
                                    </SGGridCol>
                                </SGGridRow>
                            </SGGridCol>

                            <SGGridCol align="start" span={12}>
                                <SGText size="m">
                                    {intl.formatMessage(
                                        {
                                            id: "disclaimer.sei.modal.paragraph2",
                                        },
                                        {
                                            linebreak: <br />,
                                            b: (word: string) => <strong>{word}</strong>,
                                        }
                                    )}
                                </SGText>
                            </SGGridCol>

                            <SGMediaQuery minwidth={0} maxwidth="xs">
                                <SGGridCol span={12} textalign="center">
                                    <img className="disclaimer-sei-modal__img" src={sapiendoImg} alt="logo-sapiendo" />
                                </SGGridCol>
                            </SGMediaQuery>
                        </SGGridRow>
                    </SGScroll>
                </SGGridCol>
                <SGGridCol span={12} textalign="right">
                    <SGButtonGroup align={isPhone ? "center" : "right"} disableautomargin>
                        <SGButton size="sm" onClick={() => acceptDisclaimerSei()}>
                            {intl.formatMessage({ id: `disclaimer.sei.modal.button` })}
                        </SGButton>
                    </SGButtonGroup>
                </SGGridCol>
            </SGGridRow>
        </SGModal>
    );
};

export { DisclaimerSeiModal };
