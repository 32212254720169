import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGCarousel } from "sg-carousel";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGTitle } from "sg-typo";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { CarrouselVideoCarte } from "./CarrouselVideoCarte/CarrouselVideoCarte";
import "./CarrouselVideo.scss";
import { CategorieVideo, useVideos } from "website/components/hooks/useVideos";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface CarrouselVideoProps {
    showLoader?: boolean;
    categorie: CategorieVideo;
    videoPathCourante?: string;
}

// Composant carrousel du parcours epargner
export const CarrouselVideo: FunctionComponent<CarrouselVideoProps> = (props: React.PropsWithChildren<CarrouselVideoProps>) => {
    const { showLoader, categorie,videoPathCourante} = props;
    const intl = useIntl();
    const { trackClick } = useTracking();

    const openSGAgenda = useSGAgenda();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const [pauseIndex, setPauseIndex] = useState<number>();

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const handleOnClickRdv = (disponibiliteSGAgenda: boolean) => {
        trackClick(`clic-sur-prendre-rendez-vous`, {
            form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
        });

        if (disponibiliteSGAgenda) {
            openSGAgenda();
        } else {
            // SGAgenda désactivé, on affiche un message d'erreur
            setShowErrorModal(true);
        }
    };
    const videos = useVideos(categorie, handleOnClickRdv);

    const onSlideChange = (previousIndex: number, nextIndex: number) => {
        setPauseIndex(previousIndex);
    };

    return (
        <>
            <SGGridRow gutter={[0, 0]} className={isPhone ? "carrousel-video" : undefined}>
                <SGGridCol span={12} textalign="center">
                    <SGTitle level={2}>
                        {intl.formatMessage({
                            id: "commentPasserAction.title",
                        })}
                    </SGTitle>
                </SGGridCol>
                <SGGridCol span={12}>
                    <SGCarousel
                        beforeChange={onSlideChange}
                        settings={{
                            infinite: true,
                            slidesToShow: 1,
                        }}
                    >
                        {videos.filter((video) => video.path !== videoPathCourante).map((video, index) => (
                            <CarrouselVideoCarte key={video.id} video={video} currentIndex={index} pauseIndex={pauseIndex} showLoader={isPhone ? undefined : showLoader}/>
                        ))}
                    </SGCarousel>
                </SGGridCol>
            </SGGridRow>
            {showErrorModal && <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} description="modal.error.message.indisponible" />}
        </>
    );
};
