import { OPTIN_COOKIE_TEST, optinCookie } from "store/import/utils";

export interface ParcoursRISState {
  currentStep: number;
  stopParcours: boolean;
}

export const RESET_PARCOURS = "RESET_PARCOURS";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const GO_TO_STEP_4_PARCOURS_RIS = "GO_TO_STEP_4_PARCOURS_RIS";

export const STOP_PARCOURS = "STOP_PARCOURS";
export const CONTINUE_PARCOURS = "CONTINUE_PARCOURS";
export const API_ERROR_400 = "API_ERROR_400";
export const API_ERROR_403 = "API_ERROR_403";
export const API_ERROR_422 = "API_ERROR_422";
export const API_ERROR_OTHER = "API_ERROR_OTHER";
