import { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { User, hasFetchedDashboard } from "store/dashboard/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useMonPlanPreferences } from "website/components/hooks/monPlan/useMonPlanPreferences";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { BreadCrumbAssu } from "website/components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { LegalNotice } from "website/components/molecules/LegalNotice/LegalNotice";
import "./MonPlan.scss";
import { MonPlanTakeActionCard } from "./MonPlanTakeActionCard/MonPlanTakeActionCard";
import {
  PreparationCard,
  PreparationComponent,
} from "./PreparationCard/PreparationCard";
import { Synthese } from "./Synthese/Synthese";

const MonPlan: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const saveEvent = useTrackingEvent();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const { planPreferences, componentsPreparation, defaultChevrons, chevrons } =
    useMonPlanPreferences();

  const dashboardState = useDashboardState();
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage("mon-plan", "accueil", PAGE_TYPE_GESTION);
    saveEvent(trackingEvent.PLAN);
  }, []);

  useEffect(() => {
    // save first my plan date
    if (
      hasFetchedDashboard(dashboardState) &&
      dashboardState.dashboard.firstMyPlanDate === undefined
    ) {
      const req: User = {
        firstMyPlanDate: new Date(),
      };
      dispatch(updatePrefs(req));
    }
  }, [dashboardState]);

  return (
    <div className="mon-plan">
      <SGLayout tagName="div">
        <SGContent disableautomargin>
          <SGMediaQuery minwidth="sm">
            <SGGridRow>
              <SGGridCol span={12}>
                <BreadCrumbAssu step="monPlan.header" />
              </SGGridCol>
            </SGGridRow>
          </SGMediaQuery>
          <SGGridRow>
            <SGGridCol span={12}>
              <SGBox
                margin={
                  isPhone ? { top: "lg", bottom: "lg" } : { bottom: "lg" }
                }
              >
                <SGTitle caps level={2}>
                  {intl.formatMessage({ id: "monPlan.header" })}
                </SGTitle>
              </SGBox>
            </SGGridCol>
          </SGGridRow>
        </SGContent>
        <SGLayout background="light">
          <SGHeader disableautomargin />
          <SGContent disableautomargin>
            <SGMediaQuery minwidth={0} maxwidth="xs">
              <Synthese />
            </SGMediaQuery>
            <SGMediaQuery minwidth="sm">
              <Synthese />
              <SGBox margin={{ top: "xl", bottom: "xxl" }}>
                <>
                  {componentsPreparation.map(
                    (component: PreparationComponent, index: number) =>
                      defaultChevrons[component.type] && (
                        <div
                          className={index > 0 ? "mon-plan__insecable" : ""}
                          key={component.type}
                        >
                          <PreparationCard
                            component={component}
                            key={component.type}
                            planPreferences={planPreferences}
                            defaultChevrons={defaultChevrons[component.type]}
                            allchevrons={chevrons[component.type]}
                          />
                        </div>
                      )
                  )}
                </>
              </SGBox>
              <div className="mon-plan__print-display">
                <SGSpace direction="vertical">
                  <MonPlanTakeActionCard key="take-action" />
                  <LegalNotice key="legal-notices" />
                </SGSpace>
              </div>
            </SGMediaQuery>
          </SGContent>
        </SGLayout>
      </SGLayout>
    </div>
  );
};
export { MonPlan };
