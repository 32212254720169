export interface CallToActionState {
   simulationPDF?: BlobPart;
   meetingAppointmentState?: MeetingAppointmentState;
}

export enum MeetingAppointmentState {
   ALREADY_SENT_FOR_THIS_USER = "ALREADY_SENT_FOR_THIS_USER",
   NO_SENDER_AVAILABLE = "NO_SENDER_AVAILABLE",
   CAN_BE_SENT = "CAN_BE_SENT",
   SENT = "SENT",
   ERROR_WHILE_SENDING = "ERROR_WHILE_SENDING",
   NOT_TARGETED = "NOT_TARGETED",
}

// Actions
export const FETCH_SIMULATION_PDF = "FETCH_BILAN_PDF";
export const SIMULATION_PDF_FETCHED = "BILAN_PDF_FETCHED";
export const FETCH_CAN_MEETING_BE_SENT = "FETCH_CAN_MEETING_BE_SENT";
export const MEETING_CAN_BE_SENT_FETCHED = "MEETING_CAN_BE_SENT_FETCHED";
export const SCHEDULE_MEETING = "SCHEDULE_MEETING";
export const MEETING_SCHEDULED = "MEETING_SCHEDULED";
