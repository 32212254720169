import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { PlanPreference, PlanPreferenceState, PLAN_PREFERENCE_FETCHED, PLAN_PREFERENCE_SAVED } from "./types";

const initialState: PlanPreferenceState = {};

export const PlanPreferenceReducer: Reducer<PlanPreferenceState, PayloadAction<PlanPreference[]>> = (
   currentState = initialState,
   action
): PlanPreferenceState => {
   switch (action.type) {
      case PLAN_PREFERENCE_FETCHED:
         // Si non trouvé, on récupère quand même une 200 sans body...
         if (action.payload) {
            return { planPreference: action.payload };
         }

         return currentState;
      case PLAN_PREFERENCE_SAVED:
         return { planPreference: mergeplanPreferences(currentState.planPreference || [], action.payload[0]) };
      default:
         return currentState;
   }
};

function mergeplanPreferences(planPreferences: PlanPreference[], newPlanPreference: PlanPreference): PlanPreference[] {
   return planPreferences.filter((planPreference) => planPreference.preference !== newPlanPreference.preference).concat(newPlanPreference);
}
