import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ParametresPlafondEconomieImpot, PlafondsSEI, SimulateurPlafondEconomieImpotState } from "./types";

const initialState: SimulateurPlafondEconomieImpotState = {
   parameters: {},
   plafondsSEI: { availableCeiling: 0, personalCeiling: 0, partnerCeiling: 0 },
   hasFetched: false,
   plafondsSEIError: undefined,
};

export const simulateurPlafondEconomieImpotSlice = createSlice({
   name: "simulateurPlafondEconomieImpot",
   initialState,
   reducers: {
      plafondEconomieImpotFetched: (currentState, action: PayloadAction<{plafondsSEI : PlafondsSEI, parameters: ParametresPlafondEconomieImpot }>): SimulateurPlafondEconomieImpotState => ({
         ...currentState,
         hasFetched: true,
         plafondsSEI: action.payload.plafondsSEI,
         parameters: action.payload.parameters
      }),
      plafondEconomieImpotSimulationReset: (currentState): SimulateurPlafondEconomieImpotState => ({
         ...initialState
      }),
      plafondEconomieImpotSimulationError: (currentState, action: PayloadAction<AxiosResponse>): SimulateurPlafondEconomieImpotState => ({
         ...currentState,
         plafondsSEIError: action.payload.data?.message !== undefined ? action.payload.data?.message : action.payload.data
      }),
   },
});

export const { plafondEconomieImpotFetched, plafondEconomieImpotSimulationError, plafondEconomieImpotSimulationReset } = simulateurPlafondEconomieImpotSlice.actions;

export default simulateurPlafondEconomieImpotSlice.reducer;
