import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { getAccountAction } from "../../../../../store/account/actions";
import { AccountState, isLoggedIn } from "../../../../../store/account/types";
import { LoginState } from "../../../../../store/login/types";
import { State } from "../../../../../store/store";
import { getTokenFromParams } from "../../../../utils/auth/Authentication";
import { setAuthToken } from "../../../../utils/axios";
import { SGTitleIntl } from "../../../atoms/SGTitleIntl/SGTitleIntl";
import { LoginForm } from "../../../molecules/LoginForm/LoginForm";
import { LoginSSO } from "../../../molecules/LoginSSO/LoginSSO";

const Login: FunctionComponent = () => {
  const dispatch = useDispatch();
  const saveEvent = useTrackingEvent();

   const loginState = useSelector<State, LoginState>((state) => state.login);
   const accountState = useSelector<State, AccountState>((state) => state.account);
   const hasFetchedAccount = useSelector<State, boolean>((state) => state.account.hasFetched);

   const [redirect, setRedirect] = useState(false);

   useEffect(() => {
      if (window.env.REACT_APP_USE_SSO === "true" && !loginState.forceLogOut) {
         let token = sessionStorage.getItem("token");
         if (!token) {
            token = getTokenFromParams();
         }
         if (token && !hasFetchedAccount) {
            setAuthToken(token);
            dispatch(getAccountAction());
         }
         if (isLoggedIn(accountState)) {
            saveEvent(trackingEvent.CONNEXION_MCR);
            setRedirect(true);
         }
      }
   }, [hasFetchedAccount, loginState.forceLogOut]);

   return (
      <SGCard textalign="center">
         <SGTitleIntl intlId="login.title" cypressName="login-title"/>
         {window.env.REACT_APP_USE_SSO === "true" && (
            <LoginSSO redirect={redirect}/>
         )}
         <LoginForm/>
      </SGCard>
   );
};

export { Login };
