import { SituationsHandicapEnum } from "store/simulationAssu/types";

export interface ParcoursRisInformationsComplementairesState {
  parcoursRisInformationsComplementaires: ParcoursRisInformationsComplementaires;
  parcoursRisInformationsComplementairesError: string; // Erreurs API renvoyées par le back lors de la récupération des données
}

export const hasFetchedParcoursRisInformationsComplementaires = (
  parcoursRisInformationsComplementairesState: ParcoursRisInformationsComplementairesState
): boolean =>
  parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires !==
  undefined;

// TODO Retrait statut car présent dans acquis - refacto à faire
export interface ParcoursRisInformationsComplementaires {
  success: boolean;
  message: string;
  statut: number[];
  acquis: Acquis;
}

export interface ParcoursRisInformationsComplementairesRequest {
  birthdate: string;
  birthday: number;
  children: number;
  current_family_situation: number;
  disabled_children?: number;
  foreign_countries: string[];
  gender: string;
  graduate_education: number;
  identifier: string;
  parcours_key: string;
  traitement_indiciaire?: number;
  situationsHandicap?: SituationsHandicapEnum[];
}

export interface Age {
  years: number;
  months: number;
}

export interface Acquis {
  age_depart: Age;
  date_depart: string;
  trimestres_acquis: number;
  trimestres_total: number;
  trimestres_restants: number;
  date_trimestre_restants: string;
  duree_trimestre_restants?: Age;
  types_depart: number[];
  statuts: number[];
}

// actions
export const FETCH_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES =
  "FETCH_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES";
export const PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_FETCHED =
  "PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_FETCHED";
export const RESET_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_ERROR =
  "RESET_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_ERROR";
export const UPDATE_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES =
  "UPDATE_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES";
