import { ReactNodeLike } from "prop-types";
import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedReseauxSociauxFacebook,
  SGAvenirStrokedReseauxSociauxInstagram,
  SGAvenirStrokedReseauxSociauxTwitter,
} from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGLogo } from "sg-logo";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { AccountState } from "store/account/types";
import { State } from "store/store";
import { Image } from "website/components/atoms/Image/Image";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { externalURL } from "website/utils/externalUrl";
import { ACCESSIBILITE } from "website/utils/publicRoutes";
import { ReactComponent as Separator } from "../../../../../assets/images/line.svg";
import "./Footer.scss";

const sites = [
  {
    id: "banque",
    link: externalURL.EXTERNAL_URL_BANQUE,
  },
  {
    id: "professionnels",
    link: externalURL.EXTERNAL_URL_PROFESSIONNELS,
  },
  {
    id: "entreprises",
    link: externalURL.EXTERNAL_URL_ENTREPRISES,
  },
  {
    id: "associations",
    link: externalURL.EXTERNAL_URL_ASSOCIATIONS,
  },
  {
    id: "economie",
    link: externalURL.EXTERNAL_URL_ECONOMIE,
  },
  {
    id: "groupe",
    link: externalURL.EXTERNAL_URL_GROUPE,
  },
];

const Footer: FunctionComponent = () => {
  const accountState = useSelector<State, AccountState>(
    (state) => state.account
  );
  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const getSeparator = () => (
    <SGBox margin={{ right: "xs", left: "xs" }} inline>
      <Separator />
    </SGBox>
  );

  const getLink = (
    key: string,
    ariaLabel: string,
    icone: ReactNodeLike,
    href: string
  ) => (
    <SGLink
      key={key}
      type="cta"
      ariaLabel={ariaLabel}
      icon={<SGIcon currentcolor component={icone} />}
      href={href}
    />
  );

  return (
    <>
      {accountState.account.id !== -1 && (
        <footer className="footerAssu">
          <div className="footerAssu__row__black">
            <div className="footerAssu__wrapper">
              <SGBox padding={{ top: "md", bottom: "md" }}>
                <SGGridRow gutter={[8, 0]} justify="center" align="middle">
                  <SGGridCol flex="1 0 0%">
                    <SGGridRow gutter={[8, 0]} align="middle" justify="start">
                      <SGGridCol>
                        <SGLink
                          underline={false}
                          className="footerAssu__blackrow-text"
                          href={externalURL.EXTERNAL_URL_AGENCES}
                        >
                          <SGGridRow gutter={[10, 0]} align="middle">
                            <SGGridCol>
                              <Image
                                src="images/logo/footer-localisation.svg"
                                className="footerAssu__logo"
                              />
                            </SGGridCol>
                            <SGGridCol>
                              <SGTextIntl
                                intlId="footer.blackrow.agence"
                                size="l"
                              />
                            </SGGridCol>
                          </SGGridRow>
                        </SGLink>
                      </SGGridCol>
                      <SGGridCol span={4}>
                        <SGSelect
                          disableautomargin
                          aria-label="Ouvrir la liste des autres sites Société Générale"
                          aria-labelledby=""
                          placeholder="Autres sites Société Générale"
                          className="footerAssu__blackrow-text footerAssu__select"
                          value=""
                        >
                          <SGSelectOption value="" style={{ display: "none" }}>
                            Autres sites Société Générale
                          </SGSelectOption>
                          {sites.map((site) => (
                            <SGSelectOption value={site.id} key={site.id}>
                              <SGLink
                                className="footerAssu__link"
                                underline={false}
                                href={site.link}
                              >
                                {intl.formatMessage({
                                  id: `footer.site.id.${site.id}`,
                                })}
                              </SGLink>
                            </SGSelectOption>
                          ))}
                        </SGSelect>
                      </SGGridCol>
                    </SGGridRow>
                  </SGGridCol>
                  <SGGridCol flex="0 0 0%">
                    <SGSpace
                      disableautomargin
                      direction={isPhone ? "vertical" : "horizontal"}
                      size={isPhone ? "sm" : "md"}
                    >
                      {getLink(
                        "link1",
                        "Logo Facebook",
                        <SGAvenirStrokedReseauxSociauxFacebook />,
                        externalURL.EXTERNAL_URL_FACEBOOK
                      )}
                      {getLink(
                        "link2",
                        "Logo X",
                        <SGAvenirStrokedReseauxSociauxTwitter />,
                        externalURL.EXTERNAL_URL_TWITTER
                      )}
                      {getLink(
                        "link3",
                        "Logo Instragram",
                        <SGAvenirStrokedReseauxSociauxInstagram />,
                        externalURL.EXTERNAL_URL_INSTAGRAM
                      )}
                    </SGSpace>
                  </SGGridCol>
                </SGGridRow>
              </SGBox>
            </div>
          </div>
          <div className="footerAssu__wrapper">
            <SGBox margin={{ bottom: "md" }}>
              <SGGridRow align="middle" span={12} justify="space-between">
                <SGGridCol align="center" flex="initial">
                  <SGLogo />
                </SGGridCol>
                <SGGridCol flex="initial">
                  <>
                    <SGLink
                      href={
                        window.env.REACT_APP_BDDF_URL +
                        intl.formatMessage({ id: "link.securite" })
                      }
                    >
                      <SGTextIntl intlId="footer.title.security" size="xs" />
                    </SGLink>
                    {getSeparator()}
                    <SGLink
                      href={
                        window.env.REACT_APP_BDDF_URL +
                        intl.formatMessage({ id: "link.engagements" })
                      }
                    >
                      <SGTextIntl intlId="footer.title.engagement" size="xs" />
                    </SGLink>
                    {getSeparator()}
                    <SGLink
                      href={
                        window.env.REACT_APP_BDDF_URL +
                        intl.formatMessage({ id: "link.cookies" })
                      }
                    >
                      <SGTextIntl intlId="footer.title.cookie" size="xs" />
                    </SGLink>
                    {getSeparator()}
                    <SGLink
                      href={
                        window.env.REACT_APP_BDDF_URL +
                        intl.formatMessage({ id: "link.rgpd" })
                      }
                    >
                      <SGTextIntl
                        intlId="footer.title.personal.data"
                        size="xs"
                      />
                    </SGLink>
                    {getSeparator()}
                    <SGLink
                      href={
                        window.env.REACT_APP_BDDF_URL +
                        intl.formatMessage({ id: "link.tarifs" })
                      }
                    >
                      <SGTextIntl intlId="footer.title.tarif" size="xs" />
                    </SGLink>
                    {getSeparator()}
                    <SGLink
                      href={
                        window.env.REACT_APP_BDDF_URL +
                        intl.formatMessage({ id: "link.mentions-legales" })
                      }
                    >
                      <SGTextIntl intlId="footer.title.infolegal" size="xs" />
                    </SGLink>
                    {getSeparator()}
                    <SGLink href={`#${ACCESSIBILITE}`}>
                      <SGTextIntl intlId="footer.title.accesnum" size="xs" />
                    </SGLink>
                  </>
                </SGGridCol>
              </SGGridRow>
            </SGBox>
          </div>
        </footer>
      )}
    </>
  );
};

export { Footer };
