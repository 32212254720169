import React, {PropsWithChildren, useState} from "react";
import { AssetPercentages } from "store/assetPercentages/types";

/**
 * Ce provider a pour but de propager l'information que l'on est en train d'éditer un élément pour bloquer toute autre action possible
 *
 * Ce provider doit être appelé comme suit dans un composant dans lequel on veut partager le state:
 * <EditorContextProvider>
 *    <Component1/>
 *    <Component2/>
 * </EditiorContextProvider>
 *
 * A l'intérieur de Component1 et Component2 on peut alors récupérer le booléen et le setter en récupérant les valeurs du contexte et en les utilisant :
 * const {isEditing, setEditing} = useContext(EditorContext);
 */

export type EditorContextType = {
   isEditing: boolean;
   setEditing: (isEditing: boolean) => void;
   percentages: AssetPercentages;
   setPercentages: (percentages: AssetPercentages) => void;
};

export const EditorContext = React.createContext<EditorContextType>({
   isEditing: false, setEditing: (() => null),
   percentages: {}, setPercentages: (() => null)
});

export const EditorContextProvider = ({children}: PropsWithChildren<Record<never, never>>) => {
   const [isEditing, setEditing] = useState(false);
   const [percentages, setPercentages] = useState<AssetPercentages>({});

   return (
      <EditorContext.Provider value={{isEditing, setEditing, percentages, setPercentages}}>
         {children}
      </EditorContext.Provider>
   );
}
