import {
   DELETE_EXPENSE,
   Expense,
   FETCH_EXPENSES,
   FETCH_SPENDING_PROFILE,
   SpendingProfile,
   UPDATE_EXPENSES,
   UPDATE_EXPENSE_IN_STORE,
   UPDATE_SPENDING_PROFILE
} from "./types";

export const updateSpendingProfileAction = (spendingProfile: SpendingProfile) => ({
   type: UPDATE_SPENDING_PROFILE,
   payload: spendingProfile
});

export const getExpensesAction = () => ({
   type: FETCH_EXPENSES
});

export const getSpendingProfileAction = () => ({
   type: FETCH_SPENDING_PROFILE
});

export const updateExpenseInStoreAction = (expense: Expense) => ({
   type: UPDATE_EXPENSE_IN_STORE,
   payload: expense
});

export const deleteExpenseAction = (expense: Expense) => ({
   type: DELETE_EXPENSE,
   payload: expense
});

export const updateExpensesAction = (
   rent: Partial<Expense> | undefined,
   plannedSavings: Partial<Expense> | undefined,
   childSupportExpense: Partial<Expense> | undefined
) => ({
   type: UPDATE_EXPENSES,
   payload: {rent, plannedSavings, childSupportExpense}
});
