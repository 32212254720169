import {call, put, takeLeading} from "redux-saga/effects";
import {AccountData, FETCH_ACCOUNT, UPDATE_ACCOUNT} from "./types";
import {apiGet, apiPut} from "../apiCaller";
import {onDefaultError, safe} from "../error/utils";
import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {accountFetched, accountUpdated} from "./accountSlice";
import {globalError401} from "../error/errorsSlice";

export function* watcherSaga() {
   yield takeLeading(FETCH_ACCOUNT, handleGetAccount);
   yield takeLeading(UPDATE_ACCOUNT, safe(onDefaultError, handleUpdateAccount));
}

function* handleGetAccount() {
   try {
      const payload: AxiosResponse<AccountData> = yield call(apiGet, `api/account`);
      yield put(accountFetched(payload.data));
      localStorage.setItem("clientId", String(payload.data.id));
      localStorage.setItem("login", payload.data.login);
   } catch (e) {
      // En cas de non récupération de l'account et peu importe le code HTTP renvoyé par le serveur
      // on envoie un "faux" code erreur 401 pour notifier l'application web de déconnecter l'utilisateur
      yield put(globalError401());
   }
}

export function* handleUpdateAccount(action: PayloadAction<AccountData>) {
   const payload: AxiosResponse<AccountData> = yield call(apiPut, `api/account`, action.payload);
   // L'API ne renvoie pas encore le codeSegmentMarcheNSM
   yield put(accountUpdated({...payload.data, codeSegmentMarcheNSM: action.payload.codeSegmentMarcheNSM}));
}
