import React, { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { resetParcours } from "store/parcoursRIS/action";
import { updateParcoursRisUpload } from "store/parcoursRisUpload/actions";
import { ParcoursRisUpload } from "store/parcoursRisUpload/types";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { MaxRisErrorModal } from "website/components/molecules/MaxRisErrorModal/MaxRisErrorModal";
import { PARCOURS_RIS } from "website/utils/privateRoutes";
import { useFeatures } from "../hooks/useFeatures";

type BoutonAccesRISProps = {
  trackingClic: string;
  cleLabelBouton: string;
};

/**
 * BoutonSupervision couplé à la popin MaxRisErrorModal
 */
export const BoutonAccesRIS: FunctionComponent<BoutonAccesRISProps> = (
  props: React.PropsWithChildren<BoutonAccesRISProps>
) => {
  const { trackingClic, cleLabelBouton } = props;

  const history = useHistory();
  const { pathname, search } = useLocation();
  const { trackClick } = useTracking();
  const dispatch = useDispatch();
  const { disponibiliteParcoursRIS } = useFeatures();

  const [accessibiliteRisClicked, setAccessibiliteRisClicked] =
    useState<boolean>(false);

  const parcoursRisUpload: ParcoursRisUpload = useSelector<
    State,
    ParcoursRisUpload
  >((state) => state.parcoursRisUpload.parcoursRisUpload);

  const redirectToParcoursRIS = () => {
    if (pathname === PARCOURS_RIS && search === "") {
      dispatch(
        updateParcoursRisUpload({
          ...parcoursRisUpload,
          processed: false,
        })
      );
      dispatch(resetParcours());
    } else {
      history.push(PARCOURS_RIS);
    }
  };

  const handleOnClickModifier = () => {
    trackClick(trackingClic);
    setAccessibiliteRisClicked(true);
  };

  return (
    <>
      {disponibiliteParcoursRIS && (
        <BoutonSupervision type="secondary" onClick={handleOnClickModifier}>
          <FormattedMessage id={cleLabelBouton} />
        </BoutonSupervision>
      )}
      <MaxRisErrorModal
        accessibiliteRisClicked={accessibiliteRisClicked}
        setAccessibiliteRisClicked={setAccessibiliteRisClicked}
        redirectToParcoursRIS={redirectToParcoursRIS}
      />
    </>
  );
};
