import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Loan, LoanState} from "../../../store/loans/types";
import {getLoansAction} from "../../../store/loans/actions";
import {AssetState} from "../../../store/assets/types";
import {getAssetsWithoutLoans} from "../../../store/assets/actions";

const useLoansNotAssociatedToAssets = () => {
   const dispatch = useDispatch();
   const [loansNotAssociatedToAssets, setLoansNotAssociatedToAssets] = useState<Loan[]>([]);
   const loanState: LoanState = useSelector<State, LoanState>((state) => state.loans);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);

   useEffect(() => {
      if (!loanState.hasFetched) {
         dispatch(getLoansAction());
      }
   }, []);

   useEffect(() => {
      if (!assetState.hasFetched) {
         dispatch(getAssetsWithoutLoans());
      }
   }, []);

   useEffect(() => {
      if (loanState.hasFetched && assetState.hasFetched) {
         setLoansNotAssociatedToAssets(loanState.loans.filter((loan) => !assetState.assets.flatMap((asset) => asset.loans).some((associatedLoanId) => associatedLoanId === loan.id)));
      }
   }, [loanState, assetState]);

   return loansNotAssociatedToAssets;
};

export {useLoansNotAssociatedToAssets};
