import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { API_ERROR_OTHER } from "store/parcoursRIS/types";
import { taxEconomySimulationError } from "./slice";
import { TnsStatusEnum } from "./types";

export function* onApiCallError(err: any) {
   const { response }: { response: AxiosResponse } = err;
   if (response?.status !== undefined) {
      yield put(taxEconomySimulationError(response));
   } else {
      yield put({ type: API_ERROR_OTHER, payload: err });
   }
}

export const getTrackProfile = (value?: TnsStatusEnum) => {
   switch (value) {
       case TnsStatusEnum.BIC_BNC:
           return "bic-bnc";
       case TnsStatusEnum.GERANT:
           return "gerant-majoritaire";
       case TnsStatusEnum.EXPLOITANT_AGRICOLE:
           return "exploitant-agricole";
       default: return "";
   }
}
