import React, { FunctionComponent } from "react";
import { RouteProps } from "react-router-dom";

export interface SimulateurAvantApresProps extends RouteProps {
    currentStep: number;
    steps: FunctionComponent[];
}
export const SimulateurAvantApres: FunctionComponent<SimulateurAvantApresProps> = (props: SimulateurAvantApresProps) => {
    const { currentStep, steps } = props;

    return (
        <>{steps.map((step: FunctionComponent, index: number) => {
            const Component = step;

            return currentStep === index && <Component key={currentStep} {...props} />;
        })}</>
    );
};
