import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { Video } from "website/components/hooks/useVideos";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import "./CarteVideo.scss";
import { SGContent } from "sg-layout";

export interface PlayerRef {
  play: () => void;
  pause: () => void;
  actions: any;
}

interface CarteVideoProps {
  showLoader?: boolean;
  video: Video;
  getPlayer?: (player: PlayerRef) => void;
  onClickPlayer?: () => void;
}

// Composant carte violette contenant titre/texte/bouton et vidéo avec version mobile verticale
export const CarteVideo: FunctionComponent<CarteVideoProps> = (
  props: React.PropsWithChildren<CarteVideoProps>
) => {
  const { video, getPlayer, onClickPlayer, showLoader = false } = props;
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  // Le SGLayout avec background est le seul moyen d'avoir un padding auto sur le SGContent
  return (
    <SGContent tagName="div" className="carte-video__container">
      <SGCard disableautomargin className="carte-video__card">
        <SGBox
          padding={
            isPhone
              ? { top: "sm", bottom: "sm" }
              : { left: "md", right: "md", top: "sm", bottom: "sm" }
          }
        >
          <SGSpace
            align="stretch"
            direction={isPhone ? "vertical" : "horizontal"}
            size="xl"
          >
            <SGSpace key="space" align="stretch" direction="vertical">
              <SGBlock key="block" disableautomargin>
                <SGTitle level={3} visuallevel={isPhone ? 3 : 4} colorlight>
                  <FormattedMessage id={`video.${video.id}.title`} />
                </SGTitle>
                <SGBox margin={{ top: "md", bottom: "md" }}>
                  <SGText color="lightest" size="s" weight="300">
                    <FormattedMessage
                      id={`video.${video.id}.text`}
                      values={{
                        bloc: (word: string) => <SGBlock disableautomargin>{word}</SGBlock>,
                      }}
                    />
                  </SGText>
                </SGBox>
              </SGBlock>
              <SGBox key="box" alignchildren={["left", "bottom"]}>
                {video.onClick && (
                  <SGBox span={3} align={isPhone ? "center" : "left"}>
                    <>
                      {video.afficherAide && (
                        <SGBox margin={{ bottom: "sm" }} textalign="center">
                          <SGText color="lightest">
                            <FormattedMessage id="video.aide" />
                          </SGText>
                        </SGBox>
                      )}
                      <SGButtonGroup stretch disableautomargin>
                        {/* FIXME Le size ici permet d'avoir un des boutons sur une seule ligne. Mais ce n'est pas propre. Texte à revoir */}
                        <SGButton
                          onClick={video.onClick}
                          disabled={showLoader || video.disableOnClick}
                          size={isPhone ? undefined : ["lg", "md"]}
                        >
                          <FormattedMessage
                            id={`video.${video.id}.link.${video.linkLabel}`}
                          />
                        </SGButton>
                      </SGButtonGroup>
                    </>
                  </SGBox>
                )}
              </SGBox>
            </SGSpace>
            <VideoPlayer
              key="video"
              cover={video.cover}
              time={video.time}
              urlVideo={video.video}
              getPlayer={getPlayer}
              onClickPlayer={onClickPlayer}
              type={video.type}
            />
          </SGSpace>
        </SGBox>
      </SGCard>
    </SGContent>
  );
};
