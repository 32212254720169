import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery } from "sg-media-query";
import { SGScroll } from "sg-scroll";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGEqualHeight, SGEqualHeightItem, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { Incomes } from "store/assetIncomes/types";
import { AssetWithValuation } from "store/assets/types";
import { ActifPatrimoineCard } from "../ActifPatrimoineCard/ActifPatrimoineCard";
import { AgeDepartPatrimoineCard } from "../AgeDepartPatrimoineCard/AgeDepartPatrimoineCard";
import "./AssetCard.scss";

interface AssetCardProps {
  assets: AssetWithValuation[];
  incomes: Incomes[];
  addOrRemoveAsset: (checked: boolean, assetId: number) => void;
  initAgeIncomes: boolean;
}

const AssetCard: FunctionComponent<AssetCardProps> = (
  props: AssetCardProps
) => {
  const intl = useIntl();
  const { assets, incomes, addOrRemoveAsset, initAgeIncomes } = props;
  const [newAssets, setNewAssets] = useState<AssetWithValuation[]>();
  const [firstSort, setFirstSort] = useState<boolean>(true);

  function assetsSort(
    assetA: AssetWithValuation,
    assetB: AssetWithValuation
  ): number {
    if (
      assetA.assetValuation.totalValuation !== undefined &&
      assetB.assetValuation.totalValuation !== undefined
    ) {
      let checked = 0;

      // tri des assets cochées au chargement de l'étape, au moins 1 asset doit avoir un pourcentage à 0
      if (
        assetA.percentageUsedForRetirement !==
          assetB.percentageUsedForRetirement &&
        (assetA.percentageUsedForRetirement === 0 ||
          assetB.percentageUsedForRetirement === 0)
      ) {
        checked = assetA.percentageUsedForRetirement > 0 ? -2 : 2;
      }

      // tri décroissant des assets au chargement de l'étape
      return (
        checked +
        (assetA.assetValuation.totalValuation >
        assetB.assetValuation.totalValuation
          ? -1
          : 1)
      );
    }

    return 0;
  }

  useEffect(() => {
    if (firstSort && assets.length !== 0) {
      setFirstSort(false);
      assets.sort((a, b) => assetsSort(a, b));
      setNewAssets(assets);
    }
  }, [assets]);

  return (
    <div className="asset-card">
      <SGMediaQuery minwidth="md">
        <SGEqualHeight>
          <SGGridRow align="stretch">
            <SGGridCol span={6}>
              <SGCard hoverable>
                <SGEqualHeightItem tagName="span">
                  <SGText size="m" titlerender>
                    {intl.formatMessage({
                      id: "parcoursRevenusComplementaires.estimer.title1.actif.patrimoine",
                    })}
                  </SGText>
                </SGEqualHeightItem>
                <SGBox margin={{ top: "xs" }}>
                  <SGDivider disableautomargin />
                </SGBox>
                <SGBox margin={{ top: "lg" }}>
                  {newAssets && (
                    <SGSpace direction="vertical">
                      {newAssets.map((actif) =>
                        initAgeIncomes ? (
                          <SGSkeleton key={actif?.id} paragraph={false} />
                        ) : (
                          <ActifPatrimoineCard
                            actifPatrimoine={actif}
                            onChecked={addOrRemoveAsset}
                            key={actif?.id}
                          />
                        )
                      )}
                    </SGSpace>
                  )}
                </SGBox>
                <SGBox margin={{ top: "lg", bottom: "lg" }}>
                  <SGText size="s" weight="600" style={{ color: "#636C6E" }}>
                    {intl.formatMessage({
                      id: "parcoursRevenusComplementaires.estimer.actif.mention",
                    })}
                  </SGText>
                </SGBox>
              </SGCard>
            </SGGridCol>
            <SGGridCol span={6}>
              <SGCard hoverable>
                <SGEqualHeightItem tagName="span">
                  <SGText size="m" titlerender>
                    {intl.formatMessage({
                      id: "parcoursRevenusComplementaires.estimer.title2.revenus.retraite",
                    })}
                  </SGText>
                </SGEqualHeightItem>
                <SGBox margin={{ top: "xs" }}>
                  <SGDivider disableautomargin />
                </SGBox>
                <SGBox margin={{ top: "lg", left: "lg", right: "lg" }}>
                  <SGSpace direction="vertical">
                    {incomes.map((income) => (
                      <AgeDepartPatrimoineCard
                        ageDepartPatrimoine={income}
                        key={`${income.age}_${income.months}`}
                      >
                        <SGDivider />
                      </AgeDepartPatrimoineCard>
                    ))}
                  </SGSpace>
                </SGBox>
                <SGBox margin={{ top: "sm", left: "lg", right: "lg" }}>
                  <SGText size="s" weight="600" style={{ color: "#636C6E" }}>
                    {intl.formatMessage({
                      id: "parcoursRevenusComplementaires.estimer.revenus.mention",
                    })}
                  </SGText>
                </SGBox>
              </SGCard>
            </SGGridCol>
          </SGGridRow>
        </SGEqualHeight>
      </SGMediaQuery>

      <SGMediaQuery minwidth={0} maxwidth="sm">
        <SGGridRow style={{ backgroundColor: "#F4F5F6" }}>
          <SGGridCol>
            <div style={{ padding: 10 }}>
              <SGText
                size="xl"
                weight="600"
                style={{
                  color: "#2c0d6d",
                }}
              >
                {intl.formatMessage({
                  id: "parcoursRevenusComplementaires.estimer.title1.actif.patrimoine",
                })}
              </SGText>
            </div>
            <SGScroll disableautomargin>
              {newAssets && (
                <SGSpace direction="vertical">
                  {newAssets.map((actif) =>
                    initAgeIncomes ? (
                      <SGSkeleton key={actif?.id} paragraph={false} />
                    ) : (
                      <ActifPatrimoineCard
                        actifPatrimoine={actif}
                        onChecked={addOrRemoveAsset}
                        key={actif?.id}
                      />
                    )
                  )}
                </SGSpace>
              )}
              <SGBox margin={{ top: "md" }}>
                <SGText size="s" weight="400" style={{ color: "#636C6E" }}>
                  {intl.formatMessage({
                    id: "parcoursRevenusComplementaires.estimer.actif.mention",
                  })}
                </SGText>
              </SGBox>
            </SGScroll>
          </SGGridCol>
          <SGGridCol>
            <div style={{ padding: 10 }}>
              <SGText
                size="xl"
                weight="600"
                style={{
                  color: "#2c0d6d",
                }}
              >
                {intl.formatMessage({
                  id: "parcoursRevenusComplementaires.estimer.title2.revenus.retraite",
                })}
              </SGText>
            </div>
            <SGCard disableautomargin>
              <SGSpace direction="vertical">
                {incomes.map((income) => (
                  <AgeDepartPatrimoineCard
                    ageDepartPatrimoine={income}
                    key={`${income.age}_${income.months}`}
                  />
                ))}
              </SGSpace>
              <SGDivider />
              <SGBox margin={{ top: "md" }}>
                <SGText weight="600" style={{ color: "#636C6E" }}>
                  {intl.formatMessage({
                    id: "parcoursRevenusComplementaires.estimer.revenus.mention",
                  })}
                </SGText>
              </SGBox>
            </SGCard>
          </SGGridCol>
        </SGGridRow>
      </SGMediaQuery>
    </div>
  );
};
export { AssetCard };
