import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAgendaToken } from "store/appointment/types";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardAge, DashboardAgeType, hasFetchedDashboard, User } from "store/dashboard/types";
import { isWebView } from "store/import/utils";
import { getEffortsState } from "store/savingPath/actions";
import { convertAgeTypeToWealthProfile, DataComparer, EffortState, hasFetchedEffort, Result, SavingEffortsRequest } from "store/savingPath/types";
import { State } from "store/store";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { logInfo } from "website/utils/logging";
import { useDashboardState } from "./dashboard/useDashboardState";
import { useSelectedRetirementAge } from "./dashboard/useSelectedRetirementAge";
import { trackingEvent, useTrackingEvent } from "./useTrackingEvent";
import { useLocation } from "react-router";

/**
 * Hook fournissant la méthode à appeler pour lancer le parcours SGAgenda
 */
const useSGAgenda = () => {
   const dispatch = useDispatch();
   const saveEvent = useTrackingEvent();
   const dashboardState = useDashboardState();
   const { agesTries, selectedRetirementAge } = useSelectedRetirementAge();
   const intl = useIntl();
   const [ancienObjectif, setAncienObjectif] = useState<number>();
   const [sendMail, setSendMail] = useState<boolean>(false);
   const [isRedirect, setIsRedirect] = useState<boolean>(false);
   const [dataComparer, setDataComparer] = useState<DataComparer[]>([]);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
   const assetIncomesState: AssetIncomesState = useSelector<State, AssetIncomesState>((state) => state.assetIncomes);
   const effortState: EffortState = useSelector<State, EffortState>((state) => state.effort);
   const firstDiaryConnectionDate = useSelector<State, string | undefined>((state) => state.dashboard.dashboard?.firstDiaryConnectionDate);
   const { pathname } = useLocation();

   const {assets} = assetState;
   const {assetIncomes} = assetIncomesState;

   useEffect(() => {
       if (!assetState.hasFetched) {
           dispatch(getAssetsWithoutLoans());
       }
       if (!hasFetchedAssets(assetIncomesState)) {
           dispatch(getAssetsIncomes());
       }
   }, []);

   // Calcule le montant d'épargne en fonction du type de l'âge et de l'objectif
   useEffect(() => {
      // Pour calculer les montants épargnes, on a besoin de l'objectif
      if (
         hasFetchedDashboard(dashboardState) &&
         agesTries &&
         dashboardState.dashboard.monthlyIncomeGoal !== undefined &&
         ancienObjectif !== dashboardState.dashboard.monthlyIncomeGoal
      ) {
         const wps: string[] = agesTries.map((age: DashboardAge) => convertAgeTypeToWealthProfile(age.type));
         const reqs: SavingEffortsRequest = {
            profiles: wps,
            monthlyIncomeObjective: dashboardState.dashboard.monthlyIncomeGoal,
         };
         dispatch(getEffortsState(reqs));
      }
   }, [dashboardState, agesTries]);

   useEffect(() => {
      // Pour valider le retour de l'effort
      if (hasFetchedEffort(effortState)) {
         setAncienObjectif(dashboardState.dashboard.monthlyIncomeGoal);
      }
   }, [effortState]);

   // Récupération des âges dans le dashboard
   useEffect(() => {
      if (
         hasFetchedDashboard(dashboardState) &&
         agesTries &&
         assetState.hasFetched &&
         hasFetchedAssets(assetIncomesState) &&
         (hasFetchedEffort(effortState) || !dashboardState.dashboard.monthlyIncomeGoal)
      ) {
         // Construction des AgesDeparts à partir de dashboardInformations
         // Initialisation des données à partir des âges récupérés
         const data: DataComparer[] = agesTries.map((age: DashboardAge) => {
            // Calcule le montant patrimoine après la récupération des assets
            const additionalIncome = computeAdditionalIncomes(assets, assetIncomes, age);

            // Calcule revenus complémentaire
            const deltaObjectif = getRevenusComplementaires(age, dashboardState.dashboard.monthlyIncomeGoal);

            // Calcule le montant d'épargne après l'appel au moteur
            const ageWealth = convertAgeTypeToWealthProfile(age.type);
            const epargne = dashboardState.dashboard.monthlyIncomeGoal
               ? roundDown10(effortState.results?.find((result: Result) => result.age === ageWealth)?.result || 0)
               : undefined;

            return {
               age,
               montantEpargne: epargne,
               montantPatrimoine: roundDown10(additionalIncome),
               deltaObjectif,
               objectif: dashboardState.dashboard.monthlyIncomeGoal,
            };
         });

         setDataComparer(data);
      }
   }, [dashboardState, agesTries, assetState, assetIncomesState, effortState]);

   /**
    * Calcule les revenus complémentaires en fonction de l'âge, l'objectif et du montant patrimoine
    * @param age age sélectionné
    * @param objectif objectif de revenus à la retraite
    * @returns le total des revenus complémentaires
    */
   function getRevenusComplementaires(age: DashboardAge, objectif?: number) {
      if (objectif) {
         let total = 0;
         total += objectif;
         if (age.retirementMonthlyIncome >= 0) {
            total -= roundDown10(age.retirementMonthlyIncome);
         }
         const additionalIncome = roundDown10(computeAdditionalIncomes(assets, assetIncomes, age));
         total -= additionalIncome;

         return total;
      }
   }

   // Formats en texte brut
   const formats = {
      b: (word: string) => `<strong>${word}</strong>`,
      ul: (word: string) => `<ul>${word}</ul>`,
      li: (word: string) => `<li>${word}</li>`,
      th: (word: string) => `<th ${styleBorder}>${word}</th>`,
      td: (word: string) => `<td ${styleBorder}>${word}</td>`,
      tdCenter: (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
   };

   const styleBorder = 'style="border: 1px solid;"';

   const firstColumn = (word: string) => `<td ${styleBorder} width=25%>${word}</td>`;

   const isSelectedAge = (tableAgeType: DashboardAgeType, selectedAgeType?: DashboardAgeType) => selectedAgeType === tableAgeType;

   function isObjectifSuperior(data: DataComparer, objectif?: number) {
      return objectif && data.montantPatrimoine && objectif > data.age.retirementMonthlyIncome + data.montantPatrimoine;
   }

   function getDataTable(): string {
      const dataTable = `<table style="border-collapse: collapse; width: 80%;">
      <tr>
    <th ${styleBorder} width=25%></th>
    ${agesTries?.map((age) =>
       intl.formatMessage(
          { id: "agenda.message.ageCle" },
          {
             ...formats,
             tdCenter: isSelectedAge(age.type, selectedRetirementAge?.type)
                ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
             typeAge: intl.formatMessage({ id: `agesCles.${age?.type}` }),
          }
       )
    )}
    </tr>

    <tr>
    ${firstColumn(intl.formatMessage({ id: "agenda.message.table.ageDepart" }))}

    ${agesTries?.map((age) =>
       age.retirementAgeMonth !== 0
          ? intl.formatMessage(
               { id: "agenda.message.table.ageMonth" },
               {
                  ...formats,
                  tdCenter: isSelectedAge(age.type, selectedRetirementAge?.type)
                     ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                     : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
                  annee: age.retirementAgeYear,
                  mois: age.retirementAgeMonth,
               }
            )
          : intl.formatMessage(
               { id: "agenda.message.table.age" },
               {
                  ...formats,
                  tdCenter: isSelectedAge(age.type, selectedRetirementAge?.type)
                     ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                     : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
                  annee: age.retirementAgeYear,
               }
            )
    )}
     </tr>

     <tr>
     ${firstColumn(intl.formatMessage({ id: "agenda.message.table.pensionRetraite" }))}
 
     ${dataComparer.map((data) =>
        intl.formatMessage(
           { id: "agenda.message.table.montant" },
           {
              ...formats,
              tdCenter: isSelectedAge(data.age.type, selectedRetirementAge?.type)
                 ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                 : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
              montant: data.age.retirementMonthlyIncome.toLocaleString("fr-FR"),
           }
        )
     )}
      </tr>
      
      ${
         assets.length > 0 &&
         `
      <tr>
      ${firstColumn(intl.formatMessage({ id: "agenda.message.table.revenusPatrimoine" }))}

      ${dataComparer.map(
         (data) =>
            data.montantPatrimoine &&
            intl.formatMessage(
               { id: "agenda.message.table.montant" },
               {
                  ...formats,
                  tdCenter: isSelectedAge(data.age.type, selectedRetirementAge?.type)
                     ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                     : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
                  montant: data.montantPatrimoine.toLocaleString("fr-FR"),
               }
            )
      )}
       </tr>`
      }

       ${
          dashboardState.dashboard.monthlyIncomeGoal
             ? `
       <tr>
       ${firstColumn(intl.formatMessage({ id: "agenda.message.table.revenusCompleter" }))}
   
       ${dataComparer.map((data) =>
          isObjectifSuperior(data, dashboardState.dashboard.monthlyIncomeGoal)
             ? intl.formatMessage(
                  { id: "agenda.message.table.montantComplementaire" },
                  {
                     ...formats,
                     tdCenter: isSelectedAge(data.age.type, selectedRetirementAge?.type)
                        ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                        : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
                     complement: data.deltaObjectif?.toLocaleString("fr-FR"),
                     objectif: dashboardState.dashboard.monthlyIncomeGoal?.toLocaleString("fr-FR"),
                     pension: data.age.retirementMonthlyIncome.toLocaleString("fr-FR"),
                     patrimoine: data.montantPatrimoine?.toLocaleString("fr-FR"),
                  }
               )
             : intl.formatMessage(
                  { id: "agenda.message.table.montantComplementaire.aucun" },
                  {
                     ...formats,
                     tdCenter: isSelectedAge(data.age.type, selectedRetirementAge?.type)
                        ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                        : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
                     objectif: dashboardState.dashboard.monthlyIncomeGoal?.toLocaleString("fr-FR"),
                  }
               )
       )}
        </tr>

        <tr>
        ${firstColumn(intl.formatMessage({ id: "agenda.message.table.effortEpargne" }))}
    
        ${dataComparer.map((data) =>
           intl.formatMessage(
              { id: "agenda.message.table.montant" },
              {
                 ...formats,
                 tdCenter: isSelectedAge(data.age.type, selectedRetirementAge?.type)
                    ? (word: string) => `<td style="border: 1px solid; text-align: center; background-color: #F2DBDB;"><b>${word}</b></td>`
                    : (word: string) => `<td style="border: 1px solid; text-align: center;">${word}</td>`,
                 montant: data.montantEpargne?.toLocaleString("fr-FR"),
              }
           )
        )}
         </tr>`
             : ""
       }
    </table>`;

      return dataTable;
   }

   function getMessage(): string {
      const firstLine = `${
         dashboardState.dashboard.monthlyIncomeGoal
            ? intl.formatMessage({ id: "agenda.message.objectif" }, { ...formats, objectif: dashboardState.dashboard.monthlyIncomeGoal })
            : intl.formatMessage({ id: "agenda.message.objectif.sans" })
      }
        ${
           selectedRetirementAge?.retirementAgeMonth
              ? intl.formatMessage(
                   { id: "agenda.message.age.mois" },
                   {
                      ...formats,
                      annee: selectedRetirementAge?.retirementAgeYear,
                      mois: selectedRetirementAge?.retirementAgeMonth,
                      typeAge: intl.formatMessage({ id: `agesCles.${selectedRetirementAge?.type}` }),
                   }
                )
              : intl.formatMessage(
                   { id: "agenda.message.age.annee" },
                   {
                      ...formats,
                      annee: selectedRetirementAge?.retirementAgeYear,
                      typeAge: intl.formatMessage({ id: `agesCles.${selectedRetirementAge?.type}` }),
                   }
                )
        }`;

      const estimationLine = () => intl.formatMessage({ id: "agenda.message.estimation" });

      const message = `${firstLine}<br /><br />${getDataTable().replace(/,<td/g, "<td").replace(/,<th/g, "<th")}<br />${estimationLine()}`;

      logInfo(`MAIL SGAGENDA: ${message}`);

      return message;
   }

   useEffect(() => {
      if (hasFetchedDashboard(dashboardState) && assetState.hasFetched && sendMail) {
         if (ancienObjectif === dashboardState.dashboard?.monthlyIncomeGoal) {
            // save first diary connection date
            if (!isRedirect && firstDiaryConnectionDate === undefined) {
               const req: User = {
                  firstDiaryConnectionDate: new Date(),
               };
               dispatch(updatePrefs(req));
            }
            setIsRedirect(true);
         }
      }
   }, [sendMail, ancienObjectif]);

   useEffect(() => {
      if (isRedirect && firstDiaryConnectionDate !== undefined) {
            setSendMail(false);
            const encodedMessage = btoa(encodeURIComponent(getMessage()));
            const token: SGAgendaToken = {
               source: "AFW",
               showQualificationQuestions: "N",
               complements: encodedMessage,
               callbackURL: `${window.env.REACT_APP_SGAGENDA_URL}?`,
               callbackURLPrecedent: `${window.env.REACT_APP_URL}/#${pathname}`,
            };
            const encodedToken = btoa(JSON.stringify(token));
            const url = `${
               isWebView() ? window.env.REACT_APP_BDDF_URL_MOBILE : window.env.REACT_APP_BDDF_URL
            }/icd/rvd_rva/rdv_client/index-authsec.html#/home?${window.env.REACT_APP_AGENDA_PREFIX_IN}token=${encodedToken}`;

            logInfo(`URL SGAGENDA: ${url}`);

            window.location.href = url;
            setIsRedirect(false);
      }
   }, [isRedirect, firstDiaryConnectionDate]);

   function openSGAgenda(): void {
      saveEvent(trackingEvent.SGAGENDA_DEBRANCHEMENT);
      setSendMail(true);
   }

   return openSGAgenda;
};

export { useSGAgenda };
