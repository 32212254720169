import React, { PropsWithChildren, useState } from "react";

export type DetailTabContextType = {
   showDetailFonctionnaire: boolean;
   setShowDetailFonctionnaire: (showDetailFonctionnaire: boolean) => void;
};

export const DetailTabContext = React.createContext<DetailTabContextType>({
   showDetailFonctionnaire: false, setShowDetailFonctionnaire: (() => null),
});

export const DetailTabProvider = ({children}: PropsWithChildren<Record<never, never>>) => {
   const [showDetailFonctionnaire, setShowDetailFonctionnaire] = useState<boolean>(false);

   return (
      <DetailTabContext.Provider value={{showDetailFonctionnaire, setShowDetailFonctionnaire}}>
         {children}
      </DetailTabContext.Provider>
   );
}
