import {put, takeLeading} from "redux-saga/effects";
import {RESET_ERRORS} from "./types";
import {onDefaultError, safe} from "./utils";
import {resetErrors} from "./errorsSlice";

export function* watcherSaga() {
   yield takeLeading(RESET_ERRORS, safe(onDefaultError, handleResetErrors));
}

function* handleResetErrors() {
   yield put(resetErrors());
}
