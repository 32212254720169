export interface ParcoursRevenusComplementairesState {
    currentStep: number
    stopParcours: boolean
}

export const RESET_PARCOURS_REVENUS_COMPLEMENTAIRES = 'RESET_PARCOURS_REVENUS_COMPLEMENTAIRES';
export const NEXT_STEP_REVENUS_COMPLEMENTAIRES = 'NEXT_STEP_REVENUS_COMPLEMENTAIRES';
export const PREVIOUS_STEP_REVENUS_COMPLEMENTAIRES = 'PREVIOUS_STEP_REVENUS_COMPLEMENTAIRES';
export const STOP_PARCOURS_REVENUS_COMPLEMENTAIRES = 'STOP_PARCOURS_REVENUS_COMPLEMENTAIRES';
export const CONTINUE_PARCOURS_REVENUS_COMPLEMENTAIRES = 'CONTINUE_PARCOURS_REVENUS_COMPLEMENTAIRES';
export const GO_TO_STEP_PARCOURS_REVENUS_COMPLEMENTAIRES = 'GO_TO_STEP_PARCOURS_REVENUS_COMPLEMENTAIRES';
