import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPersonalInformations } from "store/personalInformations/action";
import { PersonalInformationsState } from "store/personalInformations/types";
import { State } from "store/store";

export const usePersonalInformations = () => {
   const dispatch = useDispatch();
   const personalInformationsState: PersonalInformationsState = useSelector<State, PersonalInformationsState>((state) => state.personalInformations);

   useEffect(() => {
      if (!personalInformationsState.hasFetched) {
         dispatch(fetchPersonalInformations());
      }
   }, []);

   return { personalInformationsState };
}
