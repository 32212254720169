import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RetirementCategory,
  RetirementCategorySimulation,
} from "store/members/types";
import {
  callRetirementSimulation,
  updateRetirementSimulation,
} from "store/simulationAssu/actions";
import {
  RetirementSimulationParameters,
  RetirementSimulationParametersState,
} from "store/simulationAssu/types";
import { State } from "store/store";
import {
  WorkIncomeRaisesType,
  WorkIncomeRaisesTypeSimulation,
  WorkIncomesHistoryState,
} from "store/workIncomesHistory/types";
import {
  PAGE_CATEGORY_3_FLASH,
  PAGE_NAME_MA_SITUATION_PROFESSIONNELLE,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useUpdateSituation } from "website/components/hooks/useUpdateSituation";
import { WorkIncomes } from "../../WorkIncomes/WorkIncomes";

import { resetAssetsIncomes } from "store/assetIncomes/actions";
import { callDashboard, setHasFetchedAges } from "store/dashboard/actions";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { nextStepAction } from "store/tunnel/actions";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { MiseAJourFlashModal } from "website/components/molecules/MiseAJourFlashModal/MiseAJourFlashModal";
import { getDate } from "website/utils/date/DateUtils";

export const SimulateurFlashWorkIncomes: FunctionComponent = () => {
  const dispatch = useDispatch();
  const saveEvent = useTrackingEvent();
  const { trackClick, trackPage } = useTracking();
  const {
    updateSituation,
    updateCategorieActive,
    updateWorkIncomes,
    updateWorkIncomesHistory,
  } = useUpdateSituation();
  const { personalInformationsState } = usePersonalInformations();
  const dashboardState = useDashboardState();

  const isParcoursRISDone = dashboardState?.dashboard?.risDate !== undefined;

  const [pastForecast, setPastForecast] = useState<WorkIncomeRaisesType>();
  const [futureForecast, setFutureForecast] = useState<WorkIncomeRaisesType>();
  const [retirementType, setRetirementType] = useState<RetirementCategory>();
  const [categorieActive, setCategorieActive] = useState<CategorieActiveChoix>(
    CategorieActiveChoix.NON
  );
  const [carrierStartYears, setCarrierStartYears] = useState<number>();
  const [carrierStartMonth, setCarrierStartMonth] = useState<number>();
  const [secteurPro, setSecteurPro] = useState<string>();
  const [montantSalaireAnnuel, setMontantSalaireAnnuel] = useState<number>();
  const [memberId, setMemberId] = useState<number>();
  const [showPopin, setShowPopin] = useState(false);
  const [hasSeenInfo, setHasSeenInfo] = useState(false);
  const [hasSubmitParcours, setHasSubmitParcours] = useState<boolean>(false);
  const [callTimer, setCallTimer] = useState<number>(0);
  const [errorHappened, setErrorHappened] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showMiseAJourFlashModal, setShowMiseAJourFlashModal] =
    useState<boolean>(false);

  const simulationAssuState: RetirementSimulationParametersState = useSelector<
    State,
    RetirementSimulationParametersState
  >((state) => state.retirementSimulationParameters);
  const workIncomesHistoryData: WorkIncomesHistoryState = useSelector<
    State,
    WorkIncomesHistoryState
  >((state) => state.workIncomesHistory);

  const onHideModal = () => {
    setShowPopin(false);
    setHasSeenInfo(true);
  };

  const handleSetVisible = (visible: boolean) => {
    setErrorHappened(visible);
    setShowErrorModal(visible);
  };

  useEffect(() => {
    trackPage(
      PAGE_CATEGORY_3_FLASH,
      PAGE_NAME_MA_SITUATION_PROFESSIONNELLE,
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "2"
    );
  }, []);

  useEffect(() => {
    if (personalInformationsState.hasFetched) {
      setCategorieActive(
        personalInformationsState.personalInformations.categorieActive
          ? CategorieActiveChoix.OUI
          : CategorieActiveChoix.NON
      );
    }
  }, [personalInformationsState.hasFetched]);

  useEffect(() => {
    if (
      dashboardState.hasFetchedAges &&
      simulationAssuState?.retirementSimulationParameters?.idSimulSimple &&
      simulationAssuState?.retirementSimulationParameters?.idSimulSimple !== ""
    ) {
      dispatch(setHasFetchedAges(false));
      dispatch(nextStepAction());
    }
  }, [dashboardState.hasFetchedAges, simulationAssuState]);

  useEffect(() => {
    if (
      pastForecast === undefined &&
      futureForecast === undefined &&
      workIncomesHistoryData &&
      workIncomesHistoryData.workIncomesHistory
    ) {
      setPastForecast(
        workIncomesHistoryData.workIncomesHistory.pastYearlyIncrease
      );
      setFutureForecast(
        workIncomesHistoryData.workIncomesHistory.expectedYearlyIncrease
      );
    }
  }, [workIncomesHistoryData, pastForecast]);

  useEffect(() => {
    if (
      hasSubmitParcours &&
      simulationAssuState.retirementSimulationParameters.idSimulSimple === "" &&
      simulationAssuState.retirementSimulationParameters.simulationErrorCode ===
        undefined
    ) {
      setCallTimer(new Date().getTime());
      // Suite au clic sur le bouton et à l'enregistrement des données dans la méthode onSubmit, on fait appel au simulateur 45s
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      dispatch(callRetirementSimulation(simulationAssuState.retirementSimulationParameters as RetirementSimulationParameters));
    }
  }, [hasSubmitParcours, simulationAssuState.retirementSimulationParameters]);

  useEffect(() => {
    if (
      hasSubmitParcours &&
      !simulationAssuState.retirementSimulationParameters.isParcoursDone
    ) {
      if (
        simulationAssuState.retirementSimulationParameters.idSimulSimple &&
        simulationAssuState.retirementSimulationParameters.idSimulSimple !== ""
      ) {
        const newTimer = new Date().getTime();
        saveEvent(
          trackingEvent.FLASH_SAPIENDO_OK,
          `${newTimer - callTimer} ms`
        );
        // après récupération des données de sapiendo, on mets à jour les données
        dispatch(resetAssetsIncomes());
        dispatch(callDashboard(true));
      } else if (
        simulationAssuState.retirementSimulationParameters
          .simulationErrorCode !== undefined
      ) {
        saveEvent(
          trackingEvent.FLASH_SAPIENDO_KO,
          `[${simulationAssuState.retirementSimulationParameters.simulationErrorCode}] ${simulationAssuState.retirementSimulationParameters.simulationErrorMessage}`
        );
        // Cas d'erreur. Annulation
        setHasSubmitParcours(false);
        handleSetVisible(true);
      }
    }
  }, [simulationAssuState.retirementSimulationParameters]);

  const saveSimulation = () => {
    if (
      carrierStartMonth !== undefined &&
      carrierStartYears !== undefined &&
      secteurPro &&
      montantSalaireAnnuel !== undefined &&
      futureForecast &&
      pastForecast
    ) {
      setHasSubmitParcours(true);
      updateSituation(true, retirementType);
      updateCategorieActive(categorieActive);

      const startDate = new Date(carrierStartYears, carrierStartMonth, 1, 12);
      dispatch(
        updateRetirementSimulation({
          ...simulationAssuState.retirementSimulationParameters,
          montantAnnuelSalaire: montantSalaireAnnuel,
          salaireBrutNet:
            secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE ||
            secteurPro === RetirementCategorySimulation.FONCTIONNAIRE
              ? 1
              : 2,
          dateDebCarriere: getDate(startDate),
          memberId,
          secteurPro,
          evolSalaireFutur: WorkIncomeRaisesTypeSimulation[futureForecast],
          evolSalairePasse: WorkIncomeRaisesTypeSimulation[pastForecast],
          genre: "m",
          idSimulSimple: "",
          simulationErrorCode: undefined,
          simulationErrorMessage: undefined,
        })
      );

      updateWorkIncomes(true, secteurPro, montantSalaireAnnuel);
      updateWorkIncomesHistory(
        true,
        startDate,
        montantSalaireAnnuel,
        pastForecast,
        futureForecast
      );
    }
  };

  const onSubmit = () => {
    if (
      montantSalaireAnnuel === 0 ||
      (montantSalaireAnnuel !== undefined && montantSalaireAnnuel >= 10000) ||
      hasSeenInfo
    ) {
      if (isParcoursRISDone) {
        setShowMiseAJourFlashModal(true);
      } else {
        saveSimulation();
      }
      trackClick("clic-sur-suivant");
    } else {
      setShowPopin(true);
    }
  };

  return (
    <>
      <WorkIncomes
        onSubmit={onSubmit}
        showPopin={showPopin}
        onHideModal={onHideModal}
        carrierStartYears={carrierStartYears}
        setCarrierStartYears={setCarrierStartYears}
        carrierStartMonth={carrierStartMonth}
        setCarrierStartMonth={setCarrierStartMonth}
        montantSalaireAnnuel={montantSalaireAnnuel}
        setMontantSalaireAnnuel={setMontantSalaireAnnuel}
        secteurPro={secteurPro}
        setSecteurPro={setSecteurPro}
        setMemberId={setMemberId}
        retirementType={retirementType}
        setRetirementType={setRetirementType}
        categorieActive={categorieActive}
        setCategorieActive={setCategorieActive}
        pastForecast={pastForecast}
        setPastForecast={setPastForecast}
        futureForecast={futureForecast}
        setFutureForecast={setFutureForecast}
        hasSubmitStep={hasSubmitParcours}
        errorHappened={errorHappened}
        showErrorModal={showErrorModal}
        handleError={handleSetVisible}
      />
      <MiseAJourFlashModal
        showModal={showMiseAJourFlashModal}
        setShowModal={setShowMiseAJourFlashModal}
        onClickValider={saveSimulation}
      />
    </>
  );
};
