export const externalTel: { [key: string]: string } = {
  EXTERNAL_TEL_LASSURANCERETRAITE_ETRANGER: "+33 9 71 10 39 60",
  EXTERNAL_TEL_CARPIMKO: "0130481000",
  EXTERNAL_TEL_CARCDSF: "0140554242",
  EXTERNAL_TEL_CARMF: "0140683200",
  EXTERNAL_TEL_CRPN: "0141922525",
  EXTERNAL_TEL_CNBF: "0142213230",
  EXTERNAL_TEL_LAMAISONDESARTISTES: "0142250653",
  EXTERNAL_TEL_CAVP: "0142669037",
  EXTERNAL_TEL_CRPCF: "0144581414",
  EXTERNAL_TEL_CRPCEN: "0144902020",
  EXTERNAL_TEL_LACIPAV: "0144956820",
  EXTERNAL_TEL_SECUMINES: "0145663400",
  EXTERNAL_TEL_CROPERA: "0147427208",
  EXTERNAL_TEL_CARVP: "0147707253",
  EXTERNAL_TEL_CRPRATP: "0149747220",
  EXTERNAL_TEL_BANQUE_DE_FRANCE: "0158500505",
  EXTERNAL_TEL_IRCANTEC: "0158509999",
  EXTERNAL_TEL_SAPIENDO: "0176381538",
  EXTERNAL_TEL_CAVEC: "0180492525",
  EXTERNAL_TEL_IRCEC: "0180501888",
  EXTERNAL_TEL_CAVAMAC: "0181693601",
  EXTERNAL_TEL_CAVOM: "0185553637",
  EXTERNAL_TEL_RETRAITE_ETAT: "0240088765",
  EXTERNAL_TEL_CNIEG: "0240840184",
  EXTERNAL_TEL_MSA_LFP: "03 20 900 500",
  EXTERNAL_TEL_PAS: "0388217474",
  EXTERNAL_TEL_CRSNCF: "0495040404",
  EXTERNAL_TEL_CNRACL: "0556113335",
  EXTERNAL_TEL_FSPOEIE_45_95: "0556113772",
  EXTERNAL_TEL_FSPOEIE_01_44: "0556113773",
  EXTERNAL_TEL_RAFP: "0556114060",
  EXTERNAL_TEL_ARTISTES_AUTEURS: "0 806 604 208",
  EXTERNAL_TEL_ENIM: "0809 54 00 64",
  EXTERNAL_TEL_AGIRC_ARRCO: "0 820 200 189",
  EXTERNAL_TEL_CPRN: "0968504949",
  EXTERNAL_TEL_APPELER: "3944",
  EXTERNAL_TEL_LASSURANCERETRAITE: "3960",
};

export function transformTel(tel: string): string {
  if (tel.length === 10 && !tel.includes(" ")) {
    return tel.replace(/(\d\d(?!$))/g, "$1 ");
  }

  return tel;
}
