import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { SGAlert } from "sg-alert";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStatusInfo,
} from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { setShowSGImportAssetModal } from "store/assets/assetsSlice";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { PARCOURS_REVENUS_COMPLEMENTAIRES } from "website/utils/privateRoutes";

interface DisclaimerPatrimoineProps {
  idTracking: string;
}

const DisclaimerPatrimoine: FunctionComponent<DisclaimerPatrimoineProps> = (
  props: DisclaimerPatrimoineProps
) => {
  const { idTracking } = props;
  const { trackClick } = useTracking();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnClick = () => {
    dispatch(setShowSGImportAssetModal(true));
    trackClick(idTracking);
    history.push(PARCOURS_REVENUS_COMPLEMENTAIRES);
  };

  return (
    <SGAlert
      type="info"
      span={8}
      icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
      description={
        <SGText size="m">
          <FormattedMessage id="dashboard.disclaimer-patrimoine" />
        </SGText>
      }
      action={
        <BoutonSupervision
          type="tertiary"
          icon={
            <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
          }
          iconposition="right"
          onClick={handleOnClick}
        >
          <FormattedMessage id="dashboard.disclaimer-patrimoine.lien" />
        </BoutonSupervision>
      }
      actionPlacement="bottom"
    />
  );
};

export { DisclaimerPatrimoine };
