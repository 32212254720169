import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStyledCorbeille,
  SGAvenirStyledDuree,
} from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AlertDeleteProfileModal } from "website/components/molecules/AlertDeleteProfileModal/AlertDeleteProfileModal";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { DASHBOARD, SIMULATEUR_FLASH } from "website/utils/privateRoutes";
import { BreadCrumbAssu } from "../components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { SGLink } from "sg-link";

const ModifierProfilPage: FunctionComponent = () => {
  const { trackClick, trackPage } = useTracking();

  const [isAlertDeleteProfileModalVisible, setAlertDeleteProfileModalVisible] =
    useState(false);

  useEffect(() => {
    trackPage("modifier-mon-profil", "accueil", "gestion-ecran");
  }, []);

  const handleOnClickOpenModal = () => {
    setAlertDeleteProfileModalVisible(true);
    trackClick("clic-sur-supprimer-mes-donnees");
  };

  return (
    <SGLayout background="light">
      <SGMediaQuery minwidth={0} maxwidth="md">
        <SGBox padding={{ top: "xxl" }} />
      </SGMediaQuery>
      <SGContent
        disableautomargin
        extra={
          <SGMediaQuery minwidth="md">
            <SGBox textalign="left" span={12} margin={{ top: "xxs" }}>
              <SGGridRow>
                <SGGridCol span={12}>
                  <BreadCrumbAssu step="modifierProfil.header" />
                </SGGridCol>
              </SGGridRow>
            </SGBox>
          </SGMediaQuery>
        }
      >
        <SGGridRow justify="center">
          <SGGridCol span={12}>
            <SGSpace direction="vertical" align="center" textalign="center">
              <SGTitle key="title" level={1} visuallevel={1}>
                <FormattedMessage id="modifierProfil.titre" />
              </SGTitle>
              <SGText key="text" size="l">
                <FormattedMessage id="modifierProfil.sousTitre" />
              </SGText>
            </SGSpace>
          </SGGridCol>

          <SGGridCol span={12} md={8}>
            <TuileCliquable
              width={null}
              icone={<SGAvenirStyledDuree />}
              iconeTaille="s"
              titre="Modifier vos informations"
              texte="Recommencer votre parcours de connexion pour mettre à jour vos données personnelles."
              onClick={() => trackClick("clic-sur-modifier-mes-informations")}
              lien={`#${SIMULATEUR_FLASH}`}
              ariaLabel="Modifier vos informations"
            />

            <TuileCliquable
              width={null}
              icone={<SGAvenirStyledCorbeille />}
              iconeTaille="s"
              titre="Supprimer mes données"
              texte="Demander la suppression des données de simulation Mon Compagnon Retraite."
              onClick={handleOnClickOpenModal}
              ariaLabel="Supprimer mes données"
            />
          </SGGridCol>

          <SGGridCol span={12}>
            <AlertDeleteProfileModal
              isVisible={isAlertDeleteProfileModalVisible}
              setVisible={setAlertDeleteProfileModalVisible}
            />

            <SGMediaQuery minwidth={0} maxwidth="sm">
              <SGBox margin={{ top: "xxl" }} textalign="center">
                <SGLink
                  href={`#${DASHBOARD}`}
                  type="tertiary"
                  icon={
                    <SGIcon
                      currentcolor
                      component={<SGAvenirNavFlecheBtnLien />}
                    />
                  }
                  iconside="right"
                  onClick={() => trackClick("clic-sur-retour-au-tableau-de-bord")}
                >
                  <FormattedMessage id="modifierProfil.retour" />
                </SGLink>
              </SGBox>
            </SGMediaQuery>
          </SGGridCol>
        </SGGridRow>
      </SGContent>
      <SGBox margin={{ top: "xxl" }} padding={{ top: "xxl", bottom: "xxl" }} />
    </SGLayout>
  );
};

export { ModifierProfilPage };
