import React, {FunctionComponent} from "react";
import {SGSpace} from "sg-space";
import {SGBlock, SGPrice, SGText} from "sg-typo";
import {convertNumberToStringWithSeparators} from "../../../utils/formatting/numberFormatter";

interface AssetSelectOptionProps {
   assetName: string;
   assetValuation: number;
   isLoss: boolean;
}

const AssetSelectOption: FunctionComponent<AssetSelectOptionProps> = (props: AssetSelectOptionProps) => {
   const {assetName, assetValuation, isLoss} = props;

   return (
      <SGSpace size="xl" direction="horizontal" disableautomargin>
         <SGText>
            {assetName}
         </SGText>
         <SGBlock textalign="right">
            <SGPrice status={isLoss ? "loss" : "default"} value={convertNumberToStringWithSeparators(assetValuation)} size="l"/>
         </SGBlock>
      </SGSpace>
   );
};

export {AssetSelectOption};
