import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { useMonPlanPreferences } from "website/components/hooks/monPlan/useMonPlanPreferences";
import {
    PreparationCard,
    PreparationComponent,
} from "./PreparationCard/PreparationCard";

interface CheckListProps {
  type: string;
}

export const CheckList: FunctionComponent<CheckListProps> = (
  props: CheckListProps
) => {
  const { type } = props;
  const { planPreferences, componentsPreparation, defaultChevrons } =
    useMonPlanPreferences();
  const intl = useIntl();

  return (
    <SGLayout tagName="div">
      <SGContent disableautomargin>
        <SGGridRow>
          <SGGridCol span={12}>
            <SGBox margin={{ top: "lg", bottom: "lg" }}>
              <SGTitle caps level={2}>
                {intl.formatMessage({ id: "monPlan.header" })}
              </SGTitle>
            </SGBox>
          </SGGridCol>
        </SGGridRow>
      </SGContent>
      <SGLayout background="light">
        <SGHeader disableautomargin />
        <SGContent disableautomargin>
          {componentsPreparation
            .filter(
              (component: PreparationComponent) => component.type === type
            )
            .map(
              (component: PreparationComponent) =>
                defaultChevrons[component.type] && (
                  <PreparationCard
                    component={component}
                    key={component.type}
                    planPreferences={planPreferences}
                    defaultChevrons={defaultChevrons[component.type]}
                  />
                )
            )}
        </SGContent>
      </SGLayout>
    </SGLayout>
  );
};
