import { SyntheticEvent } from "react";
import { SGLink } from "sg-link";
import { externalURL } from "website/utils/externalUrl";
import { AllerPlusLoinTuile } from "./SectionAllerPlusLoin";

export enum NomNosSolutionsEnum {
  PER = "per",
  ASV = "asv",
  MON_OBJECTIF = "monObjectif",
}

export enum TabNosSolutionsEnum {
  SOUSCRIPTION = "souscription",
  ALIMENTATION = "alimentation",
  FISCALITE = "fiscalite",
  MODE_DE_GESTION = "modeDeGestion",
  RECUPERATION = "recuperation",
}

export const ordreTuilesPage = {
  [NomNosSolutionsEnum.PER]: [
    AllerPlusLoinTuile.agenda,
    AllerPlusLoinTuile.diagEpargne,
    AllerPlusLoinTuile.per,
  ],
  [NomNosSolutionsEnum.ASV]: [
    AllerPlusLoinTuile.agenda,
    AllerPlusLoinTuile.diagEpargne,
  ],
  [NomNosSolutionsEnum.MON_OBJECTIF]: [
    AllerPlusLoinTuile.solutions,
    AllerPlusLoinTuile.diagEpargne,
    AllerPlusLoinTuile.agenda,
  ],
};

export function parseLink(
  word: string,
  openLink: (href: string, event?: SyntheticEvent) => boolean
): JSX.Element {
  const split = word.split("#;#");
  const label = split[1];
  const link = externalURL[split[0]];

  return (
    <SGLink
      href={link}
      onClick={(event: SyntheticEvent) => openLink(link, event)}
    >
      {label}
    </SGLink>
  );
}
