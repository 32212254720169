import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGHeader, SGLayout } from "sg-layout";
import { SGLogo } from "sg-logo";
import { SGBox } from "sg-space";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  MenuItem,
  getIdTracking,
  getMenu,
  getPathMenu,
  getPathSousMenu,
  useMenu,
} from "website/components/hooks/useMenu";
import { MenuDesktop } from "./MenuDesktop";

const HeaderDesktop: FunctionComponent = () => {
  const history = useHistory();
  const menuItems = useMenu();
  const { pathname, search } = useLocation();
  const { trackClick } = useTracking();

  const [menuCourant, setMenuCourant] = useState<MenuItem>();
  const [sousMenuCourant, setSousMenuCourant] = useState<MenuItem>();

  useEffect(() => {
    // Permet de mettre à jour le menu quand le path change
    if (pathname !== menuCourant?.path && pathname !== sousMenuCourant?.path) {
      const path = `${pathname}${search}`;
      const menu = getPathMenu(menuItems, path);

      setMenuCourant(menu);
      setSousMenuCourant(getPathSousMenu(path, menu));
    }
  }, [pathname, search]);

  const onChangeMenu = (key: string): void => {
    const newMenuCourant = getMenu(menuItems, key);
    setMenuCourant(newMenuCourant);
    if (newMenuCourant) {
      trackClick(`clic-sur-${getIdTracking(newMenuCourant.key)}`);
      if (newMenuCourant.sousMenus === undefined && newMenuCourant.path && newMenuCourant.path !== pathname) {
        history.push(newMenuCourant.path);
        setSousMenuCourant(undefined);
      }
    }
  };

  const onChangeSousMenu = (key: string): void => {
    if (menuCourant && menuCourant.sousMenus) {
      const newSousMenuCourant = getMenu(menuCourant.sousMenus, key);
      setSousMenuCourant(newSousMenuCourant);
      if (newSousMenuCourant?.path) {
        trackClick(
          `${getIdTracking(menuCourant.key)}::clic-sur-${getIdTracking(
            newSousMenuCourant.key
          )}`
        );
        history.push(newSousMenuCourant.path);
      }
    }
  };

  return (
    <SGHeader disableautomargin>
      <SGBox padding={{ top: "sm" }}>
        <>
          <SGGridRow gutter={[0, 0]}>
            <SGGridCol flex="0 0 0%" textalign="right" align="center">
              <SGBox margin={{ left: "lg", right: "xl" }}>
                <SGLogo baseline width={137} />
              </SGBox>
            </SGGridCol>
            <SGGridCol flex="1 0 0%" align="center" textalign="left">
              <MenuDesktop
                menuItems={menuItems.filter(
                  (menuItem) => menuItem.icone === undefined
                )}
                onChangeMenu={onChangeMenu}
                menuCourant={menuCourant}
              />
            </SGGridCol>
            <SGGridCol flex="0 0 0%">
              <SGBox margin={{ right: "lg" }}>
                <MenuDesktop
                  key={`${menuItems.length !== 1 ? "allmenus" : "Logoutmenu"}`}
                  menuItems={menuItems.filter(
                    (menuItem) => menuItem.icone !== undefined
                  )}
                  onChangeMenu={onChangeMenu}
                  menuCourant={menuCourant}
                  sizeText="xs"
                />
              </SGBox>
            </SGGridCol>
          </SGGridRow>

          {menuCourant?.sousMenus && (
            <>
              <SGDivider disableautomargin />

              <SGLayout background="light">
                <SGBox
                  margin={{ left: "xxl" }}
                  padding={{ left: "xxl", top: "xs", bottom: "xs" }}
                >
                  <MenuDesktop
                    menuItems={menuCourant.sousMenus}
                    onChangeMenu={onChangeSousMenu}
                    menuCourant={sousMenuCourant}
                    weightText="400"
                    sizeText="s"
                  />
                </SGBox>
              </SGLayout>
            </>
          )}
          <SGDivider disableautomargin />
        </>
      </SGBox>
    </SGHeader>
  );
};

export { HeaderDesktop };
