import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGCard, SGCardMeta } from "sg-card";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { CelluleTableau } from "./ArticleTableauFemmeExploitantAgricole";

interface CelluleMobile {
    header: string;
    text: string;
}

interface ArticleTableauMobileProps {
    titresColonnes: string[];
    donnees: CelluleTableau[][];
}

export const ArticleTableauMobile: FunctionComponent<ArticleTableauMobileProps> = (props: ArticleTableauMobileProps) => {
    const { titresColonnes, donnees } = props;
    const [cellulesTable, setCellulesTable] = useState<CelluleMobile[][]>();

    function determinerTexteCellule(ligne: CelluleTableau[], index: number): string {
        let currentIndex = 0;
        let i;
        for (i = 1; i < ligne.length; i += 1) {
            const cellule: CelluleTableau = ligne[i];
            currentIndex += cellule.cols ? cellule.cols.length : 1;
            if (currentIndex >= index) {
                break;
            }
        }

        return ligne[i].text;
    }

    function initLigne(_: string, index: number): CelluleMobile[] {
        return donnees.map((ligne: CelluleTableau[]) => ({
            header: ligne[0].text,
            text: determinerTexteCellule(ligne, index),
        }));
    }

    useEffect(() => {
        setCellulesTable(titresColonnes.map(initLigne));
    }, []);

    return (
        <>
            {
                cellulesTable &&
                titresColonnes.map((titre: string, index: number) =>
                    <SGCard
                        key={titre}
                        shadow={false}
                        disableautomargin
                    >
                        <SGCardMeta
                            title={(
                                <SGBlock
                                    disableautomargin
                                    textalign="center"
                                >
                                    <SGText>{titre}</SGText>
                                </SGBlock>
                            )}
                            titlebackground="dark"
                            description={
                                cellulesTable[index].map((cellule: CelluleMobile) =>
                                    <SGBlock component="p">
                                        <>
                                            <SGText strong>{cellule.header}</SGText>
                                            <SGBox margin={{ top: "md" }}>
                                                <SGText>
                                                    <FormattedMessage id={cellule.text} defaultMessage={cellule.text} values={{
                                                        ul: (word: string) => <SGBlock component="ul" disableautomargin>{word}</SGBlock>,
                                                        li: (word: string) => <li>{word}</li>
                                                    }} />
                                                </SGText>
                                            </SGBox>
                                        </>
                                    </SGBlock>
                                )
                            }
                        />
                    </SGCard>
                )
            }
        </>
    );
}
