import React, { FunctionComponent } from "react";
import { RouteProps } from "react-router-dom";

export interface ParcoursRISProps extends RouteProps {
  currentStep: number;
  steps: FunctionComponent[];
}
const ParcoursRevenusComplementaires: FunctionComponent<ParcoursRISProps> = (
  props: ParcoursRISProps
) => {
  const { currentStep, steps } = props;

  return (
    <>
      {steps.map((step: FunctionComponent, index: number) => {
        const Component = step;

        return (
          currentStep === index && (
            <Component key={currentStep} {...props} />
          )
        );
      })}
    </>
  );
};

export { ParcoursRevenusComplementaires };
