import {call, put, takeLeading} from "redux-saga/effects";
import {FETCH_NEW_PROJECTS, NewProject} from "./types";
import {safe} from "../error/utils";
import {apiGet} from "../apiCaller";
import {fetchNewProjectsError, newProjectsFetched} from "./newProjectsSlice";
import {AxiosError, AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeLeading(FETCH_NEW_PROJECTS, safe(onNewProjectsError, handleGetNewProjects));
}

function* handleGetNewProjects() {
   const payload: AxiosResponse<NewProject[]> = yield call(apiGet, "wealth/api/projects/new");
   yield put(newProjectsFetched(payload.data));
}

function* onNewProjectsError(err: AxiosError) {
   yield put(fetchNewProjectsError());
}
