import React, { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusError } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { previousStep } from "store/simulateurAvantApres/slice";
import { State } from "store/store";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { DASHBOARD } from "website/utils/privateRoutes";
import { Cartes } from "./Cartes";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGLink } from "sg-link";

export const ResultatsStep: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const dateApplication = useSelector<State, string>((state) => state.simulateurAvantApres.simulateurAvantApres.dateApplication);
    const isTunnelRedirect = useSelector<State, boolean>((state) => state.simulateurAvantApres.isTunnelRedirect);
    const dashboardState = useDashboardState();
    const { trackClick } = useTracking();

    return (
            <SGBox span={8}>
                <>
                    {dashboardState.dashboard?.risDate !== undefined &&
                        <SGAlert
                            description={<SGText size="m" weight="400">{intl.formatMessage({ id: "simulateurAvantApres.resultats.alert.ris" })}</SGText>}
                            icon={<SGIcon component={<SGAvenirStatusError />} size="l" />}
                            type="error"
                            span={8}
                        />
                    }
                    <SGMediaQuery minwidth="sm">
                        <Cartes />
                    </SGMediaQuery>
                    <SGMediaQuery minwidth={0} maxwidth="xs">
                        <SGCard>
                            <SGBox margin={{ top: "sm", bottom: "sm", left: "sm", right: "msm" }} forcemargin>
                                <>
                                    <SGTitle level={1} visuallevel={2}>
                                        {intl.formatMessage({ id: "simulateurAvantApres.resultats.titre" })}
                                    </SGTitle>
                                    <SGText weight="400" size="l">
                                        {intl.formatMessage({ id: "simulateurAvantApres.resultats.sousTitre" }, {
                                            date: new Date(dateApplication).toLocaleDateString('fr-fr', { year: "2-digit", month: "2-digit", day: "2-digit" }),
                                        })}
                                    </SGText>
                                </>
                            </SGBox>
                            <Cartes />
                        </SGCard>
                    </SGMediaQuery>
                    {(isTunnelRedirect && (
                        <>
                            <SGBlock textalign="center">
                                <SGText weight="600">
                                    <FormattedMessage id="simulateurAvantApres.resultats.introDashboard" />
                                </SGText>
                            </SGBlock>
                            <SGButtonGroup layout="column" align="center">
                                <SGLink
                                    href={`#${DASHBOARD}`}
                                    type="primary"
                                    onClick={() => trackClick("clic-sur-acceder-a-mon-tableau-de-bord")}
                                >
                                    {intl.formatMessage({ id: "simulateurAvantApres.resultats.button.dashboard" })}
                                </SGLink>
                            </SGButtonGroup>
                        </>
                    )) || (
                            <SGButtonGroup layout="column" align="center">
                                <SGLink
                                    href={`#${DASHBOARD}`}
                                    type="primary"
                                    onClick={() => trackClick("clic-sur-retour-tableau-de-bord")}
                                >
                                    {intl.formatMessage({ id: "simulateurAvantApres.resultats.button.dashboard" })}
                                </SGLink>
                                <SGButton
                                    type="secondary"
                                    onClick={() => {
                                        trackClick("clic-sur-modifier-mes-informations");
                                        dispatch(previousStep());
                                    }}
                                >
                                    {intl.formatMessage({ id: "simulateurAvantApres.resultats.button.modifier" })}
                                </SGButton>
                            </SGButtonGroup>
                        )
                    }
                </>
            </SGBox>
    );
};
