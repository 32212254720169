import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { EconomieImpot, ParametresSEI, SimulateurEconomiesImpotsState, TaxSimulation } from "./types";

const initialState: SimulateurEconomiesImpotsState = {
   currentStep: 0,
   newSimulation: false,
   parameters: {},
   savedParameters: {},
   taxEconomy: {
      taxSaving: 0,
      realSavingEffort: 0,
      incomeTaxWithoutPayment: {
         incomeTax: 0,
         taxableIncome: 0,
         taxableIncomeBracket1: 0,
         taxableIncomeBracket2: 0,
         taxableIncomeBracket3: 0,
         taxableIncomeBracket4: 0,
         taxableIncomeBracket5: 0,
      },
      incomeTaxWithPayment: {
         incomeTax: 0,
         taxableIncome: 0,
         taxableIncomeBracket1: 0,
         taxableIncomeBracket2: 0,
         taxableIncomeBracket3: 0,
         taxableIncomeBracket4: 0,
         taxableIncomeBracket5: 0,
      },
   },
   hasFetched: false,
   taxEconomySimulationError: undefined,
};

export const simulateurEconomiesImpotsSlice = createSlice({
   name: "simulateurEconomiesImpots",
   initialState,
   reducers: {
      resetParcours: (currentState): SimulateurEconomiesImpotsState => ({
         ...currentState,
         currentStep: initialState.currentStep,
         parameters: {
            ...currentState.savedParameters,
            payment: undefined,
            // Pour relancer l'appel, car non persisté
            totalAvailableCeiling: undefined,
         },
      }),
      nextStep: (currentState): SimulateurEconomiesImpotsState => ({
         ...currentState,
         currentStep: currentState.currentStep + 1,
      }),
      previousStep: (currentState): SimulateurEconomiesImpotsState => ({
         ...currentState,
         currentStep: currentState.currentStep - 1,
      }),
      goToStep: (currentState, action: PayloadAction<number>): SimulateurEconomiesImpotsState => ({
         ...currentState,
         currentStep: action.payload,
      }),
      setNewSimulation: (currentState, action: PayloadAction<boolean>): SimulateurEconomiesImpotsState => ({
         ...currentState,
         newSimulation: action.payload
      }),
      parametersUpdated: (currentState, action: PayloadAction<ParametresSEI>): SimulateurEconomiesImpotsState => ({
         ...currentState,
         parameters: {
            // On écrase les nouveaux paramètres
            ...currentState.parameters,
            ...action.payload,
         },
      }),
      savedParametersUpdated: (currentState, action: PayloadAction<ParametresSEI>): SimulateurEconomiesImpotsState => ({
         ...currentState,
         savedParameters: {
            // On écrase les nouveaux paramètres
            ...currentState.parameters,
            ...action.payload,
            // FIXME : OK si pas utilisé ailleurs que pour la progression
            simulationDate: new Date().toISOString(),
         },
      }),
      taxEconomyFetched: (currentState, action: PayloadAction<EconomieImpot>): SimulateurEconomiesImpotsState => ({
         ...currentState,
         hasFetched: true,
         taxEconomy: action.payload,
      }),
      // Retour depuis Dashboard
      taxSimulationFetched: (
         currentState,
         { payload: { taxSaving, ...parameters } }: PayloadAction<TaxSimulation>
      ): SimulateurEconomiesImpotsState => ({
         ...currentState,
         hasFetched: true,
         savedParameters: {
            ...parameters,
            // Pour relancer l'appel, car non persisté
            totalAvailableCeiling: undefined,
         },
         taxEconomy: {
            ...currentState.taxEconomy,
            taxSaving: taxSaving ?? initialState.taxEconomy.taxSaving,
         },
      }),
      taxEconomySimulationError: (currentState, action: PayloadAction<AxiosResponse>): SimulateurEconomiesImpotsState => ({
         ...currentState,
         taxEconomySimulationError: action.payload.data?.message !== undefined ? action.payload.data?.message : action.payload.data,
      }),
      resetTaxEconomySimulationError: (currentState): SimulateurEconomiesImpotsState => ({
         ...currentState,
         taxEconomySimulationError: initialState.taxEconomySimulationError,
      }),
   },
});

export const { resetParcours, nextStep, previousStep, goToStep, setNewSimulation, parametersUpdated, savedParametersUpdated, taxEconomyFetched, taxSimulationFetched, taxEconomySimulationError, resetTaxEconomySimulationError } =
   simulateurEconomiesImpotsSlice.actions;

export default simulateurEconomiesImpotsSlice.reducer;
