import React from "react";
import {Signup} from "../components/organisms/Utils/Signup/Signup";
import {SGContent} from "sg-layout";

const SignupPage = () => (
   <SGContent span={8}>
      <Signup/>
   </SGContent>
);

export {SignupPage};
