import React, { FunctionComponent } from "react";
import { SGFilter } from "sg-filter";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { SousThematique } from "website/components/molecules/Article/ArticleModel";

export interface FiltreThematique {
    titre: SousThematique;
    compteur: number;
}

interface FiltresDesktopProps {
    filtres: FiltreThematique[];
    onChangeFiltre: (sousThematique: SousThematique) => void;
    filtreCourant: SousThematique;
}
export const FiltresDesktop: FunctionComponent<FiltresDesktopProps> = (props: FiltresDesktopProps) => {
    const { filtres, onChangeFiltre, filtreCourant } = props;

    return filtres.length > 1 ? (
        <>
            <SGTitle level={2}>Filtrer par catégorie</SGTitle>
            <SGBox margin={{ top: "sm" }}>
                <>
                    {filtres.map((filtre) => (
                        <SGFilter key={filtre.titre} checked={filtre.titre === filtreCourant} onChange={() => onChangeFiltre(filtre.titre)}>
                            {`${filtre.titre}${" "}(${filtre.compteur})`}
                        </SGFilter>
                    ))}
                </>
            </SGBox>
        </>
    ) : (
        <></>
    );
};
