import { FETCH_WORK_INCOMES_HISTORY, SAVE_WORK_INCOMES_HISTORY, UPDATE_WORK_INCOMES_HISTORY_STORE, WorkIncomesHistory } from "./types";

export const fetchWorkIncomesHistory = () => ({
   type: FETCH_WORK_INCOMES_HISTORY,
});
export const saveWorkIncomesHistoryAction = (workIncomes: WorkIncomesHistory) => ({
   type: SAVE_WORK_INCOMES_HISTORY,
   payload: workIncomes,
});

export const updateWorkIncomesHistoryStore = (workIncomes: WorkIncomesHistory) => ({
   type: UPDATE_WORK_INCOMES_HISTORY_STORE,
   payload: workIncomes,
});
