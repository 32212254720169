export interface BankAdvisorState {
   id?: number;
   externalId?: string;
   clientId?: number;
   firstName: string;
   lastName: string;
   email: string;
   phoneNumber: string;
   matricule: number;
}

export interface RawMember {
  firstName?: string;
  lastName?: string;
  idStat?: string;
  dateOfBirth?: string | null;
  maritalStatus?: string | null;
  numberChild?: string | null;
  employmentStatus?: string | null;
  title?: string | null;
}

export interface ImportMember {
   civilite: string;
   codeNatureTiers: string;
   codePCS: string;
   codeRegimeMatrimonial: string;
   codeSegmentMarcheNSM: string;
   codeSituationFamiliale: string;
   codeStatutProfessionnel: string;
   dateNaissance: string;
   identifiantTiersParticulier: string;
   nom: string;
   nombreEnfants: string;
   prenom: string;
   typeResidencePrincipale: string;
   potentielSousSegmentNSM: string;
}

export interface RawLoanState {
   externalId: string;
   name: string;
   montantPret: string;
   monthlyAmount: string;
   durationInMonth: string;
   endDate: string;
   isPretConso: boolean;
   capitalRestantDu: string;
   dateSituation: string;
}

export interface RawAssetState {
   externalId: string;
   assetName: string;
   totalValuation: string;
   produit: string;
}

export interface RawAssetAndLoansState {
   assets: Array<RawAssetState>;
   pretConsos: Array<Partial<RawLoanState>>;
   loans: Array<Partial<RawLoanState>>;
}

export interface ImportState {
   isImportingMembers: boolean;
   alreadyImported: boolean;
   hasImportMembers: boolean;
}

export const SAVE_ADVISOR = "SAVE_ADVISOR";
export const UPDATE_IMPORT_DATA = "UPDATE_IMPORT_DATA";
export const IMPORT_MEMBERS = "IMPORT_MEMBERS";
export const IMPORT_ASSETS_AND_MEMBERS = "IMPORT_ASSETS_AND_MEMBERS";
export const IMPORT_LOANS = "IMPORT_LOANS";
export const ADVISOR_SAVED = "ADVISOR_SAVED";
export const START_IMPORT = "START_IMPORT";
