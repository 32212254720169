import { FunctionComponent } from "react";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedArticle } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGTag } from "sg-tag";
import { SGText } from "sg-typo";

interface TagTempsProps {
  temps: number;
  afficherIcone?: boolean;
}

export const TagTemps: FunctionComponent<TagTempsProps> = (
  props: TagTempsProps
) => {
  const { temps, afficherIcone = true } = props;

  return (
    <SGTag
      size="sm"
      tagcolor="0"
      type="float"
      placement={{ coords: [2, 2], location: "bottomLeft" }}
    >
      <>
        {afficherIcone && (
          <SGBox margin={{ right: "xs" }} inline>
            <SGIcon
              align="text-top"
              component={<SGAvenirStrokedArticle />}
              currentcolor
              size="s"
            />
          </SGBox>
        )}

        <SGText weight="600" size="s">
          {temps} MIN
        </SGText>
      </>
    </SGTag>
  );
};
