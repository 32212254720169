import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiDelete, apiGet, apiPost, apiPut } from "../apiCaller";
import { getAssetsWithoutLoans } from "../assets/actions";
import { onDefaultError, safe } from "../error/utils";
import { getExpensesAction } from "../expenses/actions";
import { loanDeleted, loanUpdated, loansFetched } from "./loansSlice";
import { DELETE_LOAN, FETCH_LOANS, Loan, LoanData, UPDATE_LOAN } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_LOANS, safe(onDefaultError, handleGetLoans));
   yield takeLeading(UPDATE_LOAN, safe(onDefaultError, handleUpdateLoan));
   yield takeLeading(DELETE_LOAN, safe(onDefaultError, handleDeleteLoan));
}

function* handleGetLoans() {
   const payload: AxiosResponse<Loan[]> = yield call(apiGet, `wealth/api/loans`);
   yield put(loansFetched(payload.data));
}

function* handleUpdateLoan(action: PayloadAction<Loan>) {
   const loanData: Partial<LoanData> = {
      displayedAmount: action.payload.displayedAmount,
      durationInMonth: action.payload.durationInMonth,
      monthlyPayments: action.payload.monthlyAmount,
      loanAmount: action.payload.displayedAmount,
      loanType: action.payload.loanType,
      amortizationType: action.payload.amortizationType
   };
   const resultLoan: AxiosResponse<LoanData> = yield call(apiPost, `wealth/api/compute-loan-parameters`, loanData);
   const loan: Loan = {
      ...action.payload,
      displayedAmount: resultLoan.data.displayedAmount,
      totalAmount: resultLoan.data.loanAmount,
      monthlyAmount: resultLoan.data.monthlyPayments,
      interestRate: resultLoan.data.annualRate,
      durationInMonth: resultLoan.data.durationInMonth
   };
   const payload: AxiosResponse<Loan> = yield call(apiPut, `wealth/api/loans`, loan);
   yield put(loanUpdated(payload.data));
   yield put(getExpensesAction());
}

function* handleDeleteLoan(action: PayloadAction<Loan>) {
   yield call(apiDelete, `wealth/api/loans/${action.payload.id}`);
   yield put(getAssetsWithoutLoans());
   yield put(loanDeleted(action.payload));
   yield put(getExpensesAction());
}
