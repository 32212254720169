import { FunctionComponent } from "react";
import { DashboardAge } from "store/dashboard/types";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useFeatures } from "website/components/hooks/useFeatures";
import { AccesSimulateurEconomieImpot } from "../../AccesSimulateurEconomieImpot/AccesSimulateurEconomieImpot";
import { Patrimoine } from "../CartesDonnees/Patrimoine";
import {
  SectionTemplate
} from "../SectionTemplate/SectionTemplate";

interface SectionPatrimoineProps {
  selectedRetirementAge?: DashboardAge;
}

const SectionPatrimoine: FunctionComponent<SectionPatrimoineProps> = (
  props: SectionPatrimoineProps
) => {
  const { selectedRetirementAge } = props;
  const { parcoursSEIActif } = useFeatures();

  return (
    <SectionTemplate sectionType={SectionDashBoardType.PATRIMOINE}>
      <Patrimoine
        key="patrimoine"
        selectedRetirementAge={selectedRetirementAge}
      />
      {parcoursSEIActif && <AccesSimulateurEconomieImpot key="acces-sei" />}
    </SectionTemplate>
  );
};

export { SectionPatrimoine };
