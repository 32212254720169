import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { DASHBOARD } from "website/utils/privateRoutes";

type ErrorModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  displayRetry?: boolean;
  description?: string;
};

export const ErrorModal: FunctionComponent<ErrorModalProps> = (props: ErrorModalProps) => {
  const { visible, setVisible, displayRetry, description } = props;
  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGModal
      closable
      centered
      visible={visible}
      className="error-modal"
      onCancel={() => setVisible(false)}
      header={
        <SGTitle level={1} textalign="left">
          {intl.formatMessage({ id: "modal.error.title" })}
        </SGTitle>
      }
    >
      <SGSpace direction="vertical" textalign="left" size="xl">
        <SGText key="text" size={isPhone ? "m" : "xl"} weight="600">
          {intl.formatMessage({ id: description !== undefined ? description : "modal.error.message" })}
        </SGText>
        <SGButtonGroup key="buttons" align="center" layout="column">
          <SGButton onClick={() => setVisible(false)}>
            {intl.formatMessage({ id: displayRetry ? "modal.error.button.retry" : "modal.error.button" })}
          </SGButton>
          {displayRetry &&
            <SGBox margin={{top: isPhone ? "" : "xs" }}>
              <SGLink href={`#${DASHBOARD}`}>
                {intl.formatMessage({ id: "modal.error.link.dashboard" })}
              </SGLink>
            </SGBox>
          }
        </SGButtonGroup>
      </SGSpace>
    </SGModal>
  );
};
