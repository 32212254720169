import React from "react";
import ReactDOM from "react-dom";
import {HashRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {App} from "./website/App";
import * as serviceWorker from "./serviceWorker";
import {IntlLocaleProvider} from "./website/utils/locale/IntlLocaleProvider";
import { ScrollToTop } from "website/components/hooks/scrollToTop";
import {store} from "./store/store";
import {Base2Theme} from "sg-themes";
import {ModalContextProvider} from "./website/components/providers/ModalProvider";
import { injectStore } from "website/utils/axios";

injectStore(store);

const {ConfigProvider} = require('sg_npm_components-plus-core');

ReactDOM.render(
   <React.StrictMode>
      {/* @ts-ignore */}
      <Provider store={store}>
         <IntlLocaleProvider>
            <ConfigProvider theme={Base2Theme} config={{lang: "fr_FR", enableAutoMargin: false}}>
               <ModalContextProvider>
                  {/* @ts-ignore */}
                  <Router>
                     <ScrollToTop/>
                     <App/>
                  </Router>
               </ModalContextProvider>
            </ConfigProvider>
         </IntlLocaleProvider>
      </Provider>
   </React.StrictMode>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
