import {
   CREATE_INCOME,
   CREATE_PLANNED_SAVINGS_BUYBACK_INCOME,
   DELETE_INCOME,
   FETCH_INCOMES,
   Income,
   PlannedSavingsBuybackIncome,
   UPDATE_INCOME,
   UPDATE_PLANNED_SAVINGS_BUYBACK_INCOME
} from "./types";
import {PayloadAction} from "@reduxjs/toolkit";
import {makeAction} from "../utils";

export const getIncomesAction = (): PayloadAction<null> => makeAction(FETCH_INCOMES, null);

export const createIncomeAction = (income: Partial<Income>): PayloadAction<Partial<Income>> => makeAction(CREATE_INCOME, income);

export const createPlannedSavingsBuybackIncomeAction = (income: Partial<PlannedSavingsBuybackIncome>): PayloadAction<Partial<PlannedSavingsBuybackIncome>> =>
   makeAction(CREATE_PLANNED_SAVINGS_BUYBACK_INCOME, income);

export const updateIncomeAction = (income: Partial<Income>) => makeAction(UPDATE_INCOME, income);

export const updatePlannedSavingsBuybackIncomeAction = (income: PlannedSavingsBuybackIncome) =>
   makeAction(UPDATE_PLANNED_SAVINGS_BUYBACK_INCOME, income);

export const deleteIncomeAction = (incomeId: number) => makeAction(DELETE_INCOME, incomeId);
