import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FeedbackState} from "./types";

const initialState: FeedbackState = {hasSentFeedback: false};

export const feedbackSlice = createSlice({
   name: "feedback",
   initialState,
   reducers: {
      hasSentFeedbackFetched: (currentState, action: PayloadAction<boolean>) => ({
         hasSentFeedback: action.payload
      })
   }
});

export const {hasSentFeedbackFetched} = feedbackSlice.actions;

export default feedbackSlice.reducer;
