import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { savePromotionalOffer } from "store/promotionalOffers/action";
import { PromotionalOffer } from "store/promotionalOffers/types";
import { usePromotionalOffers } from "website/components/hooks/usePromotionalOffers";
import { PARCOURS_RIS } from "website/utils/privateRoutes";
import { InfoAssuModal } from "../InfoAssuModal/InfoAssuModal";

interface OffreDepartAnticipeModalProps {
  visible: boolean;
  setVisible: (showParcoursRISModal: boolean) => void;
}

const OffreDepartAnticipeModal: FunctionComponent<
  OffreDepartAnticipeModalProps
> = (props: OffreDepartAnticipeModalProps) => {
  const { visible, setVisible } = props;
  const { offreDepartAnticipe, offresFetched } = usePromotionalOffers();
  const [initSaveOffre, setInitSaveOffer] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Si la popin est vu pour la première fois, max est défini à 1
   */
  useEffect(() => {
    if (offresFetched && !initSaveOffre) {
      setInitSaveOffer(true);
      const promotionalOffer: PromotionalOffer = {
        ...offreDepartAnticipe,
        lastOfferDate: new Date(),
        viewCountOffer: incrementViewCountOffer(
          offreDepartAnticipe.viewCountOffer
        ),
      };
      dispatch(savePromotionalOffer(promotionalOffer));
    }
  }, [offresFetched]);

  const incrementViewCountOffer = (viewCountOffer: number) =>
    viewCountOffer < 3 ? viewCountOffer + 1 : viewCountOffer;

  return (
    <InfoAssuModal
      onHide={() => setVisible(false)}
      onClick={() => {
        setVisible(false);
        history.push(PARCOURS_RIS);
      }}
      showInfoModal={visible}
      titleLevel={3}
      text="dashboard.modal.offreDepartAnticipe.text"
      title="dashboard.modal.offreDepartAnticipe.title"
      buttonPrimary="dashboard.modal.buttonPrimary.departAnticipe"
      buttonSecondary="dashboard.modal.buttonSecondary.departAnticipe"
      trackPageCategory3="tableau-de-bord"
      trackKey="popin-ris-depart-anticipe"
      trackKeyFormField1="non-determinee"
      trackKeyOnClick="popin-ris-depart-anticipe::clic-sur-j-analyse-mon-ris"
      trackKeyOnHide="popin-ris-depart-anticipe::clic-sur-fermer"
      trackKeyButtonSecondaryOnHide="popin-ris-depart-anticipe::clic-sur-annuler"
    />
  );
};

export { OffreDepartAnticipeModal };
