import { FunctionComponent } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGPriceCommercial, SGText } from "sg-typo";

interface BlocVersementProps {
  background: "light" | "Sec_Sg2";
  titre: string;
  montant?: number;
}

const BlocVersement: FunctionComponent<BlocVersementProps> = (
  props: BlocVersementProps
) => {
  const { background, titre, montant } = props;
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGLayout background={background}>
      <SGBox
        padding={{ top: "md", bottom: "md", right: "md", left: "md" }}
        alignchildren={isPhone ? "center" : undefined}
      >
        <SGGridRow gutter={[0, 0]} justify="center">
          <SGGridCol span={12} align="end" textalign="center">
            <SGText size={isPhone ? "m" : "s"}>{titre}</SGText>
          </SGGridCol>
          <SGGridCol span={12} textalign="center">
            <SGPriceCommercial
              size="l"
              value={`${montant?.toLocaleString("fr-FR") ?? "--"}`}
            />
          </SGGridCol>
        </SGGridRow>
      </SGBox>
    </SGLayout>
  );
};

export { BlocVersement };
