import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Income, IncomeState} from "./types";
import {insertElement, removeElement, updateElement} from "../utils";

const initialState: IncomeState = {incomes: new Array<Income>(), hasFetched: false};

export const incomesSlice = createSlice({
   name: "incomes",
   initialState,
   reducers: {
      incomeCreated: (currentState, action: PayloadAction<Income>) => ({
         ...currentState,
         incomes: insertElement(currentState.incomes, action.payload)
      }),
      incomeUpdated: (currentState, action: PayloadAction<Income>) => ({
         ...currentState,
         incomes: updateElement(currentState.incomes, action.payload)
      }),
      incomeDeleted: (currentState, action: PayloadAction<string>) => ({
         ...currentState,
         incomes: removeElement(currentState.incomes, action.payload)
      }),
      incomesFetched: (currentState, action: PayloadAction<Income[]>) => ({incomes: action.payload, hasFetched: true})
   }
});

export const {incomeCreated, incomeUpdated, incomeDeleted, incomesFetched} = incomesSlice.actions;

export default incomesSlice.reducer;
