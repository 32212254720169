import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { useMediaQuery } from "sg-media-query";
import { SGMenu, SGMenuItem } from "sg-menu";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { MenuItem } from "website/components/hooks/useMenu";
import { MenuEvent } from "./MenuHorizontalPhone";

interface MenuDesktopProps {
  menuItems: MenuItem[];
  menuCourant?: MenuItem;
  onChangeMenu: (key: string) => void;
  weightText?: "600" | "400";
  sizeText?: "xs" | "s" | "m";
}

const MenuDesktop: FunctionComponent<MenuDesktopProps> = ({
  menuItems,
  menuCourant,
  onChangeMenu,
  weightText = "600",
  sizeText = "m",
}) => {
  const isDesktop = useMediaQuery({ minwidth: "lg" });

  const getTexte = (key: string) => (
    <SGText key="text" weight={weightText} size={sizeText} whitespace="nowrap">
      <FormattedMessage id={`header.menu.${key}.titre`} />
    </SGText>
  );

  return (
    <SGMenu
      selectedKeys={menuCourant ? [menuCourant.key] : []}
      onClick={({ key }: MenuEvent) => onChangeMenu(key)}
    >
      {menuItems.map(({ key, disponibiliteParcours, icone }) => (
        <SGMenuItem key={key} disabled={disponibiliteParcours === false}>
          {icone ? (
            <SGGridRow gutter={[0, 0]}>
              <SGGridCol flex="0 0 0%" align="center">
                <SGDivider type="vertical" width={3} />
              </SGGridCol>
              <SGGridCol flex="1 0 0%" textalign="center" align="center">
                <SGBox margin={{ left: "md" }}>
                  <>
                    <SGBox inline padding={{ left: isDesktop ? "xs" : "sm" }}>
                      <SGIcon component={icone} />
                    </SGBox>
                    {getTexte(key)}
                  </>
                </SGBox>
              </SGGridCol>
            </SGGridRow>
          ) : (
            <SGBox margin={{ left: "xs" }}>{getTexte(key)}</SGBox>
          )}
        </SGMenuItem>
      ))}
    </SGMenu>
  );
};

export { MenuDesktop };
