import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard, SGCardMeta } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGText, SGTitle } from "sg-typo";
import { Media, TypeMedia } from "website/components/hooks/articles";
import { TagTemps } from "website/components/molecules/TagTemps/TagTemps";

interface CarteArticleVerticalProps {
    media: Media;
    path: string;
    handleOnClickLireArticle: (path: string, trackKey: string, typeMedia?: TypeMedia) => void;
    trackKey: string;
    afficherIconeTag?: boolean;
}
export const CarteArticleVertical: FunctionComponent<CarteArticleVerticalProps> = (props: CarteArticleVerticalProps) => {
    const { media, path, handleOnClickLireArticle, trackKey, afficherIconeTag = true} = props;
    const intl = useIntl();

    return (
        <SGCard
            hoverable
            coversize="md"
            key={media.path}
            disableautomargin
            actions={[
                <SGLink
                    href={`#${path}`}
                    key="lien"
                    type="tertiary"
                    icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                    iconside="right"
                    onClick={() => handleOnClickLireArticle(media.path, trackKey, media.type)}
                >
                    {intl.formatMessage({ id: `articles.bouton.lire.${media.type}` })}
                </SGLink>,
            ]}
            coverposition={media.type === TypeMedia.VIDEO ? "leftCenter" : undefined}
            cover={
                <>
                    <img
                        alt={`${intl.formatMessage({ id: "articles.couverture.alt" })}${intl.formatMessage({ id: `home.article.title.${media.id}` })}`}
                        src={media.image}
                    />
                    <TagTemps temps={media.time} afficherIcone={afficherIconeTag}/>
                </>
            }
        >
            <SGCardMeta
                title={
                    <SGTitle level={3} visuallevel={4}>
                        {intl.formatMessage({ id: `${media.type !== TypeMedia.VIDEO ? "home.article.title" : "video"}.${media.id}${media.type !== TypeMedia.VIDEO ? "" : ".title"}` })}
                    </SGTitle>
                }
                description={media.type !== TypeMedia.VIDEO && <SGText ellipsis>{intl.formatMessage({ id: `home.article.description.${media.id}` })}</SGText>}
            />
        </SGCard>
    );
};
