import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiGet } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { takeNewLatest } from "../sagas";
import { EFFORTS_FETCHED, FETCH_EFFORTS, FETCH_SAVINGPATHS_FROM_EFFORT, FETCH_SAVINGPATHS_FROM_OBJECTIVE, FETCH_SAVINGPATH_FROM_EFFORT, FETCH_SAVINGPATH_FROM_OBJECTIVE, Result, SAVINGPATHS_FROM_EFFORT_FETCHED, SAVINGPATHS_FROM_OBJECTIVE_FETCHED, SAVINGPATH_FROM_EFFORT_FETCHED, SAVINGPATH_FROM_OBJECTIVE_FETCHED } from "./types";

export function* watcherSaga() {
   yield takeLatest(FETCH_SAVINGPATH_FROM_OBJECTIVE, safe(onDefaultError, handlePostMonthlyIncomeObjective));
   yield takeLatest(FETCH_SAVINGPATHS_FROM_OBJECTIVE, safe(onDefaultError, handleGetMonthlyIncomesObjective));
   yield takeNewLatest(FETCH_EFFORTS, safe(onDefaultError, handleGetEfforts), "monthlyIncomeObjective");
   yield takeLatest(FETCH_SAVINGPATH_FROM_EFFORT, safe(onDefaultError, handlePostMonthlySavingEffort));
   yield takeLatest(FETCH_SAVINGPATHS_FROM_EFFORT, safe(onDefaultError, handleGetMonthlySavingEfforts));
}

function* handlePostMonthlyIncomeObjective(action: any) {
   // On ne met pas à jour la valeur saisie dans le state SAVINGPATH_FROM_EFFORT_FETCHED pour éviter les effets de bord du changement d'onglet
   const payload: AxiosResponse<number> = yield call(
      apiGet,
      `/wealth/api/retirement/asset-incomes/monthlyEffort?profile=${action.payload.profile}&incomeAtRetirement=${action.payload.monthlyIncomeObjective}`,
      process.env.REACT_APP_API_URL
   );
   yield put({ type: SAVINGPATH_FROM_OBJECTIVE_FETCHED, payload: payload.data });
}

function* handlePostMonthlySavingEffort(action: any) {
   // On ne met pas à jour la valeur saisie dans le state SAVINGPATH_FROM_OBJECTIVE_FETCHED pour éviter les effets de bord du changement d'onglet
   const payload: AxiosResponse<number> = yield call(
      apiGet,
      `/wealth/api/retirement/asset-incomes/incomeAtRetirement?profile=${action.payload.profile}&monthlyEffort=${action.payload.monthlySavingEffort}`,
      process.env.REACT_APP_API_URL
   );
   yield put({ type: SAVINGPATH_FROM_EFFORT_FETCHED, payload: payload.data });
}

function* handleGetMonthlyIncomesObjective(action: any) {
   const payload: Result[] = yield call(getResultMonthlyIncomeObjective, action.payload.profiles, action.payload.monthlyIncomeObjective);
   yield put({ type: SAVINGPATHS_FROM_OBJECTIVE_FETCHED, payload });
}

function* handleGetEfforts(action: any) {
   const payload: Result[] = yield call(getResultMonthlyIncomeObjective, action.payload.profiles, action.payload.monthlyIncomeObjective);
   yield put({ type: EFFORTS_FETCHED, payload });
}

function* handleGetMonthlySavingEfforts(action: any) {
   const payload: Result[] = yield call(getResultMonthlySavingEffort, action.payload.profiles, action.payload.monthlySavingEffort);
   yield put({ type: SAVINGPATHS_FROM_EFFORT_FETCHED, payload });
}

function* getResultMonthlyIncomeObjective(profiles: string[], monthlyIncomeObjective: number) {
   const results: Result[] = [];
   // eslint-disable-next-line no-restricted-syntax
   for (const profile of profiles) {
      const result: AxiosResponse<number> = yield call(
         apiGet,
         `/wealth/api/retirement/asset-incomes/monthlyEffort?profile=${profile}&incomeAtRetirement=${monthlyIncomeObjective}`,
         process.env.REACT_APP_API_URL
      );

      results.push({ age: profile, result: result.data });
   }

   return results;
}

function* getResultMonthlySavingEffort(profiles: string[], monthlySavingEffort: number) {
   const results: Result[] = []
   // eslint-disable-next-line no-restricted-syntax
   for (const profile of profiles) {
      const result: AxiosResponse<number> = yield call(
         apiGet,
         `/wealth/api/retirement/asset-incomes/incomeAtRetirement?profile=${profile}&monthlyEffort=${monthlySavingEffort}`,
         process.env.REACT_APP_API_URL
      );

      results.push({ age: profile, result: result.data });
   }

   return results;
}
