import { FC, PropsWithChildren, useState } from "react";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { InfoModal } from "./InfoModal/InfoModal";
import { InfoTooltipAssu } from "../InfoTooltipAssu/InfoTooltipAssu";
import { useMediaQuery } from "sg-media-query";

interface InfoTooltipProps {
  text: string;
  trackKey?: string;
  isModal?: boolean;
}

const InfoTooltip: FC<PropsWithChildren<InfoTooltipProps>> = (
  props: PropsWithChildren<InfoTooltipProps>
) => {
  const { text, isModal = true, trackKey } = props;

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackClick } = useTracking();

  const [showInfoModal, setShowInfoModal] = useState(false);

  return isModal ? (
    <>
      {" "}
      <SGButton
        type="icon"
        onClick={(event: Event) => {
          event.preventDefault();
          if (trackKey) {
            trackClick(trackKey);
          }
          setShowInfoModal(true);
        }}
        icon={<SGIcon component={<SGAvenirStatusInfo />} size="m" />}
      />
      {showInfoModal && (
        <InfoModal
          visible={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
          text={text}
        />
      )}
    </>
  ) : (
    <InfoTooltipAssu
      text={text}
      placement={isPhone ? "top" : "right"}
      transformations={{
        ul: (word: string) => <ul>{word}</ul>,
        li: (word: string) => <li>{word}</li>,
        box: (word: string) => <>{word}</>,
      }}
      trackKey={trackKey}
    />
  );
};

export { InfoTooltip };
