import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStyledTelechargement } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGSpinner } from "sg-spinner";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { telechargerSyntheseRetraite } from "store/pdf/actions";
import { PdfState } from "store/pdf/types";
import { State } from "store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const AccesSyntheseRetraite: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { trackClick } = useTracking();
  const pdfState = useSelector<State, PdfState>((state) => state.pdf);
  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  const handleOnClick = () => {
    trackClick("clic-sur-card-ma-synthese");
    dispatch(telechargerSyntheseRetraite());
  };

  return (
    <SGTile clickable={pdfState.enChargement || estSupervisionActive}>
      <SGBox>
        <SGGridRow align="middle">
          <SGGridCol flex="0 0 0%" textalign="center">
            <SGIcon component={<SGAvenirStyledTelechargement />} size="s" />
          </SGGridCol>
          <SGGridCol flex="1 0 0%">
            <SGTitle level={2} visuallevel={4}>
              <FormattedMessage id="accesSyntheseRetraite.titre" />
            </SGTitle>
            <SGText size="s">
              <FormattedMessage id="accesSyntheseRetraite.texte" />
            </SGText>
          </SGGridCol>
          <SGGridCol flex="0 0 0%">
            <SGButton
              key="button"
              type="link"
              icon={
                <SGIcon
                  component={
                    pdfState.enChargement ? <SGSpinner size="md" /> : null
                  }
                />
              }
              onClick={handleOnClick}
              disabled={pdfState.enChargement || estSupervisionActive}
            >
              <FormattedMessage id="accesSyntheseRetraite.bouton" />
            </SGButton>
          </SGGridCol>
        </SGGridRow>
      </SGBox>
    </SGTile>
  );
};
