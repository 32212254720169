import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent, SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { SGText, SGTitle } from "sg-typo";
import { thematiques } from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CategorieVideo } from "website/components/hooks/useVideos";
import { ONGLET_ACCUEIL, getIdTrackingOnglet } from "website/components/molecules/Article/ArticleModel";
import { OngletAccueil } from "website/components/organisms/Articles/OngletAccueil";
import { OngletThematique } from "website/components/organisms/Articles/OngletThematique";
import { CarrouselVideo } from "website/components/organisms/CarrouselVideo/CarrouselVideo";

export const ArticlesPage: FunctionComponent = () => {
    const intl = useIntl();
    const { trackPage, trackTab } = useTracking();

    const [ongletCourant, setOngletCourant] = useState<string>(ONGLET_ACCUEIL);
    const [mapPage, setMapPage] = useState<Map<string, number>>(new Map());
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const handleOnChangeOnglet = useCallback((onglet: string) => {
        if (ongletCourant !== onglet) {
            trackPage("comprendre-ma-retraite", getIdTrackingOnglet(onglet), "liste-article", "", "", { form_field_1: mapPage.get(onglet) ?? 1 });
        }
        setOngletCourant(onglet);
    }, [mapPage, ongletCourant]);

    const updateMapPage = useCallback((onglet: string, page: number) => {
        if ((mapPage.get(onglet) ?? 1) !== page) {
            trackPage("comprendre-ma-retraite", getIdTrackingOnglet(onglet), "liste-article", "", "", { form_field_1: page });
        }
        setMapPage(new Map(mapPage.set(onglet, page)));
    }, [mapPage]);

    const onglets = useMemo(() => {
        const thematiquesTab = thematiques.map((thematique) => ({
            ariaId: thematique,
            tab: `articles.tab.${thematique}`,
            component: <OngletThematique thematique={thematique} updateMapPage={updateMapPage} />,
        }));

        return [
            {
                ariaId: ONGLET_ACCUEIL,
                tab: `articles.tab.${ONGLET_ACCUEIL}`,
                component: <OngletAccueil handleOnChangeOnglet={handleOnChangeOnglet} />,
            },
            ...thematiquesTab,
        ];
    }, [updateMapPage, handleOnChangeOnglet]);

    useEffect(() => {
        trackPage("comprendre-ma-retraite", getIdTrackingOnglet(ongletCourant), "liste-article", "", "", { form_field_1: 1 });
    }, []);

    return (
        <SGLayout tagName="div">
            <SGContent disableautomargin>
                <SGBox margin={{ top: "lg", bottom: "lg" }}>
                    <SGGridRow justify="center" gutter={[0, 8]}>
                        <SGGridCol textalign="center" span={12}>
                            <SGTitle level={1} visuallevel={1}>
                                {intl.formatMessage({ id: "articles.titre" })}
                            </SGTitle>
                        </SGGridCol>
                        <SGGridCol textalign="center" span={12} md={10} lg={8}>
                            <SGText size="l"> {intl.formatMessage({ id: "articles.sousTitre" })}</SGText>
                        </SGGridCol>
                        <SGGridCol span={12}>
                            <SGTabs
                                activeKey={ongletCourant}
                                onChange={(onglet: string) => {
                                    trackTab(`clic-sur-onglet-${getIdTrackingOnglet(onglet)}`);
                                    handleOnChangeOnglet(onglet);
                                }}
                            >
                                {onglets.map((onglet) => (
                                    <SGTabPane ariaId={onglet.ariaId} tab={intl.formatMessage({ id: onglet.tab })} key={onglet.ariaId}>
                                        <SGBox margin={{ top: isPhone ? "lg" : "xl" }}>{onglet.component}</SGBox>
                                    </SGTabPane>
                                ))}
                            </SGTabs>
                        </SGGridCol>
                        <SGGridCol span={12}>
                            <SGBox margin={{ top: "lg" }}>
                                <CarrouselVideo categorie={CategorieVideo.ARTICLES} />
                            </SGBox>
                        </SGGridCol>
                    </SGGridRow>
                </SGBox>
            </SGContent>
        </SGLayout>
    );
};
