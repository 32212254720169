import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiPost } from "store/apiCaller";
import { onDefaultError, safe } from "store/error/utils";
import { simulateurAvantApresFetched } from "./slice";
import { FETCH_SIMULATEUR_AVANT_APRES, ParametresSimulateurAvantApres, SimulateurAvantApres } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_SIMULATEUR_AVANT_APRES, safe(onDefaultError, handleFetchSimulateurAvantApres));
}

/**
 * Appelle le simulateur avant/apres
 * @param action
 */
function* handleFetchSimulateurAvantApres(action: PayloadAction<ParametresSimulateurAvantApres>) {
    const payload: AxiosResponse<SimulateurAvantApres> = yield call(apiPost, `/backpfr/api/simulation-access/simulateur-avant-apres-2023`, action.payload);
    yield put(simulateurAvantApresFetched(payload.data));
}
