import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ImportState} from "./types";

const initialState: ImportState = {isImportingMembers: false, alreadyImported: false, hasImportMembers: false};

export const importSlice = createSlice({
   name: "import",
   initialState,
   reducers: {
      importDataUpdated: (currentState, action: PayloadAction<ImportState>) => ({
         isImportingMembers: action.payload.isImportingMembers,
         alreadyImported: action.payload.alreadyImported,
         hasImportMembers: action.payload.hasImportMembers
      }),
      importMembersSuccess: (currentState) => ({
         ...currentState,
         hasImportMembers: true
      }),
      importMembersTimeout: (currentState) => ({
         ...currentState,
         isImportingMembers: false
      }),
      importMembersError: (currentState) => ({
         ...currentState,
         isImportingMembers: false
      }),
      updateIsImporting: (currentState, action: PayloadAction<boolean>) => ({
         ...currentState,
         isImportingMembers: action.payload
      })
   }
});

export const {importDataUpdated, importMembersSuccess, importMembersTimeout, importMembersError, updateIsImporting} = importSlice.actions;

export default importSlice.reducer;
