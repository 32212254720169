import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Expense, ExpenseState, SpendingProfile} from "./types";
import {storeDeepEquals} from "../utils";

const initialState: ExpenseState = {
   expenses: new Array<Expense>(),
   hasFetchedExpenses: false,
   spendingProfile: SpendingProfile.MEDIUM_SAVING,
   hasFetchedSpendingProfile: false
};

const updateExpense = (expenses: Expense[], expenseUpdated: Expense) => {
   if (!expenses) {
      return [expenseUpdated];
   }
   if (!expenses.some((expense) => expense.type === expenseUpdated.type)) {
      return [...expenses, expenseUpdated];
   }

   return expenses.map((value) => {
      const expense = value;
      if (value.type === expenseUpdated.type) {
         return {...expense, monthlyAmount: expenseUpdated.monthlyAmount};
      }

      return expense;
   });
};

const removeExpense = (expenses: Expense[], expenseDeletedId: number) => expenses.filter((expense) => expense.id !== expenseDeletedId);

export const expensesSlice = createSlice({
   name: "expenses",
   initialState,
   reducers: {
      setFetchedExpensesToFalse: (currentState) => ({
         ...currentState,
         hasFetchedExpenses: false
      }),
      expensesFetched: (currentState, action: PayloadAction<Expense[]>) => ({
         ...currentState,
         expenses: storeDeepEquals(currentState.expenses, action.payload) ? currentState.expenses : action.payload,
         hasFetchedExpenses: true
      }),
      spendingProfileFetched: (currentState, action: PayloadAction<SpendingProfile>) => ({
         ...currentState,
         spendingProfile: action.payload,
         hasFetchedSpendingProfile: true
      }),
      updateExpenseInStore: (currentState, action: PayloadAction<Expense>) => ({
         ...currentState,
         expenses: updateExpense(currentState.expenses, action.payload)
      }),
      updateSpendingProfile: (currentState, action: PayloadAction<SpendingProfile>) => ({
         ...currentState,
         spendingProfile: action.payload
      }),
      expenseDeleted: (currentState, action: PayloadAction<Expense>) => ({
         ...currentState,
         expenses: removeExpense(currentState.expenses, action.payload.id)
      })
   }
});

export const {
   expensesFetched,
   setFetchedExpensesToFalse,
   spendingProfileFetched,
   updateExpenseInStore,
   updateSpendingProfile,
   expenseDeleted
} = expensesSlice.actions;

export default expensesSlice.reducer;
