import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlanPreference } from "store/planPreference/action";
import { hasFetchedPlanPreference, PlanPreference, PlanPreferenceState } from "store/planPreference/types";
import { State } from "store/store";
import { PreparationComponent } from "website/components/organisms/MonPlan/PreparationCard/PreparationCard";
import { SGAvenirStrokedSolution, SGAvenirStrokedAide, SGAvenirStrokedEpargne } from "sg-icon-pack-base";
import { useIntl } from "react-intl";
import { ARTICLES, MES_SERVICES } from "website/utils/privateRoutes";

/**
 * Stockage des chevrons par défaut
 */
export interface DefaultChevrons {
    [key: string]: string[];
}

/**
 * Utilisé seulement sur MonPlan
 */
const useMonPlanPreferences = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [planPreferences, setPlanPreferences] = useState<PlanPreference[]>();
    const planPreferenceState: PlanPreferenceState = useSelector<State, PlanPreferenceState>((state) => state.planPreference);

    const [defaultChevrons, setDefaultChevrons] = useState<DefaultChevrons>({});
    const [chevrons, setChevrons] = useState<DefaultChevrons>({});

    const getChoosenPref = (preference: string) =>
        planPreferenceState.planPreference?.filter((planPreference) => planPreference.preference === preference).shift()?.choosenPref;

    useEffect(() => {
        dispatch(fetchPlanPreference());
    }, []);

    useEffect(() => {
        if (hasFetchedPlanPreference(planPreferenceState)) {
            setPlanPreferences(planPreferenceState.planPreference);

            const allchevrons: DefaultChevrons = {};
            const chevrons: DefaultChevrons = {};
            componentsPreparation.forEach((comp) => {
                // Si au moins une case cochée dans le chevron, on le garde
                const openedChevrons = comp.chevrons.filter((chevron) => chevron.checkbox.filter((check) => getChoosenPref(check.name)).length > 0);
                chevrons[comp.type] = openedChevrons.map((chevron) => chevron.type);
                allchevrons[comp.type] = comp.chevrons.map((chevron) => chevron.type);
                if (openedChevrons.length === 0 || !chevrons[comp.type].includes(comp.chevrons[0].type)) {
                    chevrons[comp.type].push(comp.chevrons[0].type);
                }
            });

            setDefaultChevrons(chevrons);
            setChevrons(allchevrons);
        }
    }, [planPreferenceState]);

    const componentsPreparation: PreparationComponent[] = [
        {
            type: "pension",
            cards: [
                { component: "aide", icon: <SGAvenirStrokedSolution />, link: MES_SERVICES, colored: false },
                { component: "questions", icon: <SGAvenirStrokedAide />, link: ARTICLES, colored: true },
            ],
            chevrons: [
                {
                    type: "maintenant",
                    checkbox: [
                        { name: "pointSalaires", checked: getChoosenPref("pointSalaires") },
                        { name: "pointRIS", checked: getChoosenPref("pointRIS") },
                        { name: "correctionRIS", checked: getChoosenPref("correctionRIS") },
                    ],
                },
                {
                    type: "situation",
                    checkbox: [
                        { name: "projets", checked: getChoosenPref("projets") },
                        { name: "informations", checked: getChoosenPref("informations") },
                        { name: "analyseRIS", checked: getChoosenPref("analyseRIS") },
                        { name: "declare", checked: getChoosenPref("declare") },
                    ],
                },
                {
                    type: "approche",
                    checkbox: [
                        { name: "informationsRIS", checked: getChoosenPref("informationsRIS") },
                        { name: "entretien", checked: getChoosenPref("entretien") },
                        { name: "cessation", checked: getChoosenPref("cessation") },
                    ],
                },
                {
                    type: "depart",
                    checkbox: [
                        { name: "contrats", checked: getChoosenPref("contrats") },
                        { name: "demande", checked: getChoosenPref("demande") },
                        { name: "informe", checked: getChoosenPref("informe") },
                    ],
                },
            ],
            color: "purple",
        },
        {
            type: "patrimoine",
            cards: [
                {
                    component: "produits",
                    icon: <SGAvenirStrokedEpargne />,
                    link: window.env.REACT_APP_BDDF_URL + intl.formatMessage({ id: "link.epargne" }),
                    colored: true,
                },
            ],
            chevrons: [
                {
                    type: "maintenant",
                    checkbox: [
                        { name: "renseigne", checked: getChoosenPref("renseigne") },
                        { name: "definis", checked: getChoosenPref("definis") },
                        { name: "rendezVous", checked: getChoosenPref("rendezVous") },
                    ],
                },
                {
                    type: "situation",
                    checkbox: [
                        { name: "maj", checked: getChoosenPref("maj") },
                        { name: "actualise", checked: getChoosenPref("actualise") },
                    ],
                },
                { type: "depart", checkbox: [{ name: "liquidation", checked: getChoosenPref("liquidation") }], subtitle: true },
            ],
            color: "green",
        },
    ];

    return { planPreferences, componentsPreparation, defaultChevrons, chevrons };
};

export { useMonPlanPreferences };
