import { SyntheticEvent } from "react";
import { isWebView } from "../../../store/import/utils";
import natif from "awt-natif";

/**
 * Hook fournissant la méthode à appeler pour ouvrir un nouvel onglet.
 * Permet de gérer l'ouverture d'un navigateur depuis la webview en natif.
 */
const useOpenLink = () => {
   function openLink(href: string, event?: SyntheticEvent): boolean {
      if (event) {
         event.preventDefault();
         event.nativeEvent.preventDefault();
      }

      if (!isWebView()) {
         // Test du media si web
         window.open(href);
         // Si besoin, on peut utiliser isMobile() ici pour prévoir un comportement différent
      } else {
         // Sinon app mobile
         natif.ouvrirNavigateur(href);
      }

      return false;
   }

   return openLink;
};

export { useOpenLink };
