import { FunctionComponent, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGInputNumber, SGInputQuantity } from "sg-input";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { AccountState } from "store/account/types";
import { updateFamilyAction } from "store/members/actions";
import { CustodyType, Family, FamilyStatus, MaritalStatus, MatrimonialRegime, Me, Member } from "store/members/types";
import { isCoupleMaritalStatus, isIsolatedParentStatus } from "store/members/utils";
import { nextStep } from "store/parcoursRIS/action";
import {
    postParcoursRisInformationsComplementaires,
    resetParcoursRisInformationsComplementairesError,
} from "store/parcoursRisInformationsComplementaires/actions";
import {
    ParcoursRisInformationsComplementairesRequest,
    ParcoursRisInformationsComplementairesState,
    hasFetchedParcoursRisInformationsComplementaires,
} from "store/parcoursRisInformationsComplementaires/types";
import { ParcoursRisUploadState } from "store/parcoursRisUpload/types";
import { updatePersonalInformationsStore } from "store/personalInformations/action";
import { PersonalInformations, PersonalInformationsState, hasFetchedPersonalInformations } from "store/personalInformations/types";
import { SituationsHandicapEnum } from "store/simulationAssu/types";
import { State } from "store/store";
import { Loader } from "website/components/atoms/Loader/Loader";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { PAGE_CATEGORY_3_RIS, PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { FormSituationsHandicap } from "./FormSituationsHandicap/FormSituationsHandicap";
import "./ParcoursRISComplements.scss";

const ParcoursRISComplements: FunctionComponent = () => {
    const [numberChild, setNumberChild] = useState(0);
    const [sitFamEnCours, setSitFamEnCours] = useState<string>();
    const [situationsHandicap, setSituationsHandicap] = useState<SituationsHandicapEnum[]>([]);
    const [travailEtranger, setTravailEtranger] = useState<boolean>();
    const [serviceMilitaire, setServiceMilitaire] = useState<boolean | undefined>();
    const [nivEtude, setNivEtude] = useState<string>();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const max = 9;
    const [traitementIndiciaire, setTraitementIndiciaire] = useState<number | undefined>(0);
    const [retirementType, setRetirementType] = useState<string>();
    const [civilite, setCivilite] = useState<string>("M");
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { trackClick, trackPage } = useTracking();

    const optionNivEtude = ["non", "bac1", "bac2", "bac3", "bac4", "bac5"];
    const optionSitFam = ["marie", "divorce", "celibataire", "veuf", "pacse", "concubin"];
    const optionTravailEtranger = ["oui", "non"];
    const optionServiceMilitaire = ["oui", "non"];
    const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);

    const family = useFamily();
    const maxTraitementIndiciaire = 100000;

    const personalInformationsState: PersonalInformationsState = useSelector<State, PersonalInformationsState>((state) => state.personalInformations);

    const parcoursRisInformationsComplementairesState: ParcoursRisInformationsComplementairesState = useSelector<
        State,
        ParcoursRisInformationsComplementairesState
    >((state) => state.parcoursRisInformationsComplementaires);

    const parcoursRisUploadState: ParcoursRisUploadState = useSelector<State, ParcoursRisUploadState>((state) => state.parcoursRisUpload);

    const intl = useIntl();
    const dispatch = useDispatch();

    function getErrorChildrenCounter() {
        const error = errors?.childrenCounter;
        if (error?.type === "required") {
            return intl.formatMessage({ id: "tunnel.error.required" });
        }
        if (error?.type === "pattern") {
            return intl.formatMessage({ id: "tunnel.situation.error.childrenCounter.pattern" });
        }

        return "";
    }

    function getErrorTraitementIndiciaire() {
        const error = errors?.traitementIndiciaire;
        if (error?.type === "required") {
            return intl.formatMessage({ id: "tunnel.error.required" });
        }
        if (error?.type === "max") {
            return intl.formatMessage({ id: "parcoursRIS.error.traitementIndiciaire.max" });
        }

        return "";
    }

    const addChildren = () => {
        if (numberChild < max) {
            setNumberChild((prevState) => Math.round(+prevState) + 1);
        }
    };
    const removeChildren = () => {
        if (numberChild > 0) {
            setNumberChild((prevState) => Math.round(+prevState) - 1);
        }
    };
    const changeNumberChild = (val: any) => {
        setNumberChild(val);
    };

    function getSelectString(selectName?: boolean) {
        if (selectName === undefined) {
            return undefined;
        }

        return selectName ? "oui" : "non";
    }

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const { handleSubmit, setValue, formState: { errors, submitCount } } = methods;

    // Mise à jour du contrôle d'un champ s'il est rempli ou non
    useEffect(() => {
        setValue("childrenCounter", numberChild, {
            shouldValidate: submitCount > 0,
        });
    }, [methods, numberChild]);

    useEffect(() => {
        setValue("selectSitFamille", sitFamEnCours, {
            shouldValidate: submitCount > 0,
        });
    }, [sitFamEnCours]);

    useEffect(() => {
        setValue("selectNivEtude", nivEtude, {
            shouldValidate: submitCount > 0,
        });
    }, [nivEtude]);

    useEffect(() => {
        setValue("selectTravailEtranger", getSelectString(travailEtranger), {
            shouldValidate: submitCount > 0,
        });
    }, [travailEtranger]);

    useEffect(() => {
        setValue("traitementIndiciaire", traitementIndiciaire, {
            shouldValidate: submitCount > 0,
        });
    }, [traitementIndiciaire]);

    useEffect(() => {
        setValue("selectServiceMilitaire", getSelectString(serviceMilitaire), {
            shouldValidate: submitCount > 0,
        });
    }, [serviceMilitaire]);

    useEffect(() => {
        if (family?.me) {
            setRetirementType(family.me.retirementType);
        }
        if (family?.children && family.children.length > 0) {
            setNumberChild(
                family.children.filter((child: Member) => child.custodyType === CustodyType.FULL_CUSTODY || child.custodyType === CustodyType.FULL_CUSTODY_ME)
                    .length
            );
        }
    }, [family]);

    useEffect(() => {
        trackPage(PAGE_CATEGORY_3_RIS, "complements", PAGE_TYPE_FORMULAIRE, "simulation", "3");
        if (accountState.account && accountState.account.title) {
            setCivilite(accountState.account.title === "Mme" ? "F" : "M");
        }
    }, []);

    // Définit les champs de l'étape 2 après la récupération des données personalInformations
    useEffect(() => {
        if (hasFetchedPersonalInformations(personalInformationsState)) {
            setSitFamEnCours(personalInformationsState.personalInformations.situationFamiliale);
            setNivEtude(personalInformationsState.personalInformations.nivEtude);
            setTravailEtranger(personalInformationsState.personalInformations.travailEtranger);
            setTraitementIndiciaire(personalInformationsState.personalInformations.traitementIndiciaire);
            setServiceMilitaire(personalInformationsState.personalInformations.serviceMilitaire);
        }
    }, [personalInformationsState.hasFetched]);

    const onSubmit = () => {
        trackClick("clic-sur-suivant");
        setShowLoader(true);
        dispatch(resetParcoursRisInformationsComplementairesError());

        if (family.me) {
            updateFamily(family.me);
        }

        const dateConsentementSituationsHandicap =
            !situationsHandicap.includes(SituationsHandicapEnum.NE_PAS_REPONDRE)
            && !situationsHandicap.includes(SituationsHandicapEnum.NON) ? new Date() : undefined;
        if (nivEtude && sitFamEnCours && travailEtranger !== undefined) {
            const req: PersonalInformations = {
                ...personalInformationsState.personalInformations,
                situationFamiliale: sitFamEnCours,
                nivEtude,
                travailEtranger,
                traitementIndiciaire,
                serviceMilitaire,
                // Stockage d'une date pour la navigation en cours (sans F5)
                dateConsentementSituationsHandicap,
                situationsHandicap,
            };
            dispatch(updatePersonalInformationsStore(req));
        }

        const parcoursRisInformationsComplementairesRequest: ParcoursRisInformationsComplementairesRequest = {
            birthdate: family.me ? new Date(family.me.birthday).toISOString().slice(0, 10) : "1970-01-01",
            birthday: family.me ? new Date(family.me.birthday).getDate() : 1,
            children: numberChild,
            current_family_situation: sitFamEnCours ? optionSitFam.indexOf(sitFamEnCours) + 1 : 0,
            foreign_countries: [],
            gender: "H",
            graduate_education: nivEtude ? optionNivEtude.indexOf(nivEtude) + 1 : 0,
            identifier: parcoursRisUploadState.parcoursRisUpload.identifier,
            parcours_key: parcoursRisUploadState.parcoursRisUpload.parcours_key,
            situationsHandicap,
        };

        if (retirementType === "FONCTIONNAIRE") {
            parcoursRisInformationsComplementairesRequest.traitement_indiciaire = traitementIndiciaire;
        }

        dispatch(postParcoursRisInformationsComplementaires(parcoursRisInformationsComplementairesRequest));
    };

    useEffect(() => {
        if (
            hasFetchedParcoursRisInformationsComplementaires(parcoursRisInformationsComplementairesState) &&
            parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires.success
        ) {
            dispatch(nextStep());
        } else if (parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementairesError) {
            setShowErrorModal(true);
        }
    }, [parcoursRisInformationsComplementairesState]);

    const updateFamily = (meMember: Me) => {
        const familyToDispatch: Family = {
            children: [],
            me: {
                ...meMember,
                birthday: meMember.birthday || new Date("1970-01-01"),
                matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS,
            },
            relatives: [],
        };
        let ordinal = 0;
        for (let i = 0; i < numberChild; i += 1) {
            ordinal += 1;
            const custodyType =
                isIsolatedParentStatus(meMember.maritalStatus) || meMember.maritalStatus === MaritalStatus.WIDOWER
                    ? CustodyType.FULL_CUSTODY_ME
                    : CustodyType.FULL_CUSTODY;
            const child: Member = {
                name: `Mon enfant ${ordinal}`,
                status: FamilyStatus.CHILD,
                custodyType,
                deletable: false,
                projects: [],
                incomes: []
            };
            familyToDispatch.children.push(child);
        }
        if (isCoupleMaritalStatus(meMember.maritalStatus)) {
            const partner: any = { ...family.partner, matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS };
            familyToDispatch.partner = partner;
        }
        dispatch(updateFamilyAction(familyToDispatch, true, true));
    };

    return (
        <SGCard shadow={false} disableautomargin>
            <SGBox margin={{ left: "sm", right: "sm" }}>
                <SGGridRow justify="center">
                    <SGGridCol span={12} xl={9}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <SGGridRow gutter={[0, 24]}>
                                    <SGGridCol span={12}>
                                        <SGBox margin={{ top: "md" }}>
                                            <SGTitle textalign="left" level={2}>
                                                {intl.formatMessage({ id: "parcoursRIS.complements" })}
                                            </SGTitle>
                                        </SGBox>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGText size="m">
                                            <FormattedMessage id="parcoursRIS.complements.information" />
                                        </SGText>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGSpace direction="vertical" disableautomargin>
                                            <SGBlock key="block-1">
                                                <SGBox margin={{ top: "md", bottom: "sm" }}>
                                                    <SGTitle textalign="left" level={3}>
                                                        {intl.formatMessage({ id: "parcoursRIS.complements.situationPerso" })}
                                                    </SGTitle>
                                                </SGBox>
                                            </SGBlock>
                                            <SGBlock key="block-2">
                                                <Controller
                                                    control={methods.control}
                                                    name="selectSitFamille"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) => (
                                                        <SGSelect
                                                            size={isPhone ? "xl" : "m"}
                                                            disableautomargin
                                                            placeholder="Sélectionnez"
                                                            name="selectSitFamille"
                                                            value={sitFamEnCours ?? []}
                                                            className="parcours-ris-complements__select"
                                                            onChange={(value: string) => {
                                                                field.onChange(value);
                                                                setSitFamEnCours(value);
                                                            }}
                                                            label={<SGTextIntl intlId="parcoursRIS.complements.situationPerso.situationFamille" size="l" />}
                                                            status={errors?.selectSitFamille && "error"}
                                                            validate={intl.formatMessage({ id: "parcoursRIS.error.required" })}
                                                        >
                                                            {optionSitFam.map((situationFamille: string) => (
                                                                <SGSelectOption key={situationFamille} value={situationFamille}>
                                                                    <FormattedMessage id={`parcoursRIS.complements.select.${situationFamille}`} />
                                                                </SGSelectOption>
                                                            ))}
                                                        </SGSelect>
                                                    )}
                                                />
                                            </SGBlock>
                                            <SGBlock key="block-3" className="quantity">
                                                <Controller
                                                    control={methods.control}
                                                    name="childrenCounter"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) => (
                                                        <SGInputQuantity
                                                            disableautomargin // TODO KO
                                                            name="childrenCounter"
                                                            data-cy="childrenCounter-wrapper-dependent-children"
                                                            value={numberChild}
                                                            precision={0}
                                                            min={0}
                                                            max={max}
                                                            size={isPhone ? "xl" : "s"}
                                                            unitvalue=""
                                                            increment={1}
                                                            className="parcours-ris-complements__select"
                                                            onDecrement={removeChildren}
                                                            onIncrement={addChildren}
                                                            onChange={(value: number) => {
                                                                field.onChange(value);
                                                                changeNumberChild(value);
                                                            }}
                                                            label={
                                                                <SGText size="l">
                                                                    {intl.formatMessage(
                                                                        { id: "parcoursRIS.complements.situationPerso.enfants" },
                                                                        {
                                                                            tooltip: (
                                                                                <InfoTooltipAssu
                                                                                    text="parcoursRIS.complements.situationPerso.enfants.tooltip"
                                                                                    transformations={{ max: max.toString() }}
                                                                                    placement={isPhone ? "bottom" : "right"}
                                                                                />
                                                                            ),
                                                                        }
                                                                    )}
                                                                </SGText>
                                                            }
                                                            status={errors?.childrenCounter && "error"}
                                                            validate={errors?.childrenCounter && getErrorChildrenCounter()}
                                                        />
                                                    )}
                                                />
                                            </SGBlock>
                                            <FormSituationsHandicap
                                                key="situations-handicap"
                                                situationsHandicap={situationsHandicap}
                                                setSituationsHandicap={setSituationsHandicap}
                                                formMethods={methods}
                                            />
                                        </SGSpace>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGSpace direction="vertical" disableautomargin>
                                            <SGBlock key="block-1">
                                                <SGBox margin={{ top: "md", bottom: "sm" }}>
                                                    <SGTitle textalign="left" level={3}>
                                                        {intl.formatMessage({ id: "parcoursRIS.complements.situationPro" })}
                                                    </SGTitle>
                                                </SGBox>
                                            </SGBlock>
                                            <SGBlock key="block-2">
                                                <Controller
                                                    control={methods.control}
                                                    name="selectNivEtude"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) => (
                                                        <SGSelect
                                                            size={isPhone ? "xl" : "m"}
                                                            disableautomargin
                                                            placeholder="Sélectionnez"
                                                            name="selectNivEtude"
                                                            value={nivEtude ?? []}
                                                            className="parcours-ris-complements__select"
                                                            onChange={(value: string) => {
                                                                field.onChange(value);
                                                                setNivEtude(value);
                                                            }}
                                                            label={<SGTextIntl intlId="parcoursRIS.complements.situationPro.nivEtude" size="l" />}
                                                            status={errors?.selectNivEtude && "error"}
                                                            validate={intl.formatMessage({ id: "parcoursRIS.error.required" })}
                                                        >
                                                            {optionNivEtude.map((niveauEtude: string) => (
                                                                <SGSelectOption key={niveauEtude} value={niveauEtude}>
                                                                    <FormattedMessage id={`parcoursRIS.complements.select.${niveauEtude}`} />
                                                                </SGSelectOption>
                                                            ))}
                                                        </SGSelect>
                                                    )}
                                                />
                                            </SGBlock>
                                            <SGBlock key="block-3">
                                                <Controller
                                                    control={methods.control}
                                                    name="selectTravailEtranger"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) => (
                                                        <SGSelect
                                                            disableautomargin
                                                            size={isPhone ? "xl" : "m"}
                                                            placeholder="Sélectionnez"
                                                            name="selectTravailEtranger"
                                                            value={getSelectString(travailEtranger) ?? []}
                                                            className="parcours-ris-complements__select"
                                                            onChange={(value: string) => {
                                                                field.onChange(value);
                                                                setTravailEtranger(value === "oui");
                                                            }}
                                                            label={<SGTextIntl intlId="parcoursRIS.complements.situationPro.travailEtranger" size="l" />}
                                                            status={errors?.selectTravailEtranger && "error"}
                                                            validate={intl.formatMessage({ id: "parcoursRIS.error.required" })}
                                                        >
                                                            {optionTravailEtranger.map((value: string) => (
                                                                <SGSelectOption key={value} value={value}>
                                                                    <FormattedMessage id={`parcoursRIS.complements.select.${value}`} />
                                                                </SGSelectOption>
                                                            ))}
                                                        </SGSelect>
                                                    )}
                                                />
                                            </SGBlock>
                                            {retirementType === "FONCTIONNAIRE" && (
                                                <SGBlock key="block-4">
                                                    <Controller
                                                        control={methods.control}
                                                        name="traitementIndiciaire"
                                                        rules={{
                                                            required: true,
                                                            max: maxTraitementIndiciaire,
                                                            pattern: /^(0|[0-9][0-9]*)$/,
                                                        }}
                                                        render={({ field }) => (
                                                            <SGInputNumber
                                                                disableautomargin // TODO KO
                                                                label={
                                                                    <SGTextIntl intlId="parcoursRIS.complements.situationPro.traitementIndiciaire" size="l" />
                                                                }
                                                                max={maxTraitementIndiciaire}
                                                                value={traitementIndiciaire}
                                                                className="parcours-ris-complements__select"
                                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                onChange={(value: any) => {
                                                                    field.onChange(value);
                                                                    setTraitementIndiciaire(value);
                                                                }}
                                                                status={errors?.traitementIndiciaire && "error"}
                                                                validate={getErrorTraitementIndiciaire()}
                                                                size={isPhone ? "xl" : "m"}
                                                            />
                                                        )}
                                                    />
                                                </SGBlock>
                                            )}
                                            {civilite === "M" && (
                                                <SGBlock key="block-5">
                                                    <Controller
                                                        control={methods.control}
                                                        name="selectServiceMilitaire"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field }) => (
                                                            <SGSelect
                                                                size={isPhone ? "xl" : "m"}
                                                                disableautomargin
                                                                placeholder="Sélectionnez"
                                                                name="selectServiceMilitaire"
                                                                value={getSelectString(serviceMilitaire) ?? []}
                                                                className="parcours-ris-complements__select"
                                                                onChange={(value: string) => {
                                                                    field.onChange(value);
                                                                    setServiceMilitaire(value === "oui");
                                                                }}
                                                                label={<SGTextIntl intlId="parcoursRIS.complements.situationPro.serviceMilitaire" size="l" />}
                                                                status={errors?.selectServiceMilitaire && "error"}
                                                                validate={intl.formatMessage({ id: "parcoursRIS.error.required" })}
                                                            >
                                                                {optionServiceMilitaire.map((value: string) => (
                                                                    <SGSelectOption key={value} value={value}>
                                                                        <FormattedMessage id={`parcoursRIS.complements.select.${value}`} />
                                                                    </SGSelectOption>
                                                                ))}
                                                            </SGSelect>
                                                        )}
                                                    />
                                                </SGBlock>
                                            )}
                                        </SGSpace>
                                    </SGGridCol>
                                    <SGGridCol span={12} textalign={showLoader || isPhone ? "center" : "right"}>
                                        <SGBox margin={{ top: "md", bottom: "sm" }}>
                                            {showLoader ? (
                                                <Loader title="loader.title" />
                                            ) : (
                                                <SGButton
                                                    size="sm"
                                                    onClick={handleSubmit(onSubmit)}
                                                    ariaLabel={intl.formatMessage({ id: "common.next.ariaLabel" })}
                                                >
                                                    <SGTextIntl intlId="common.next" />
                                                </SGButton>
                                            )}
                                        </SGBox>
                                    </SGGridCol>
                                </SGGridRow>
                            </form>
                        </FormProvider>
                    </SGGridCol>
                </SGGridRow>
            </SGBox>
            {showErrorModal && <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} />}
        </SGCard>
    );
};

export { ParcoursRISComplements };
