import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGAvenirStyledPensionsRentes } from "sg-icon-pack-base";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { SIMULATEUR_ECONOMIES_IMPOTS } from "website/utils/privateRoutes";

const CarteSimulateurEconomieImpot: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const { parcoursSEIActif } = useFeatures();

  return parcoursSEIActif ? (
    <TuileCliquable
      accessible
      icone={<SGAvenirStyledPensionsRentes />}
      iconeTaille="s"
      lien={`#${SIMULATEUR_ECONOMIES_IMPOTS}`}
      titre={
        <FormattedMessage id="sectionAllerPlusLoin.tuile.economieImpot.titre" />
      }
      texte={
        <FormattedMessage id="sectionAllerPlusLoin.tuile.economieImpot.texte" />
      }
      onClick={() => trackClick("clic-sur-simuler-mon-economie-d-impot")}
      ariaLabel="Rediriger vers le parcours simulation d’économie d’impôt."
      alignchildren="center"
    />
  ) : null;
};

export { CarteSimulateurEconomieImpot };
