import { useSelector } from "react-redux";
import { DashboardState } from "store/dashboard/types";
import { State } from "../../../store/store";

const useParcoursEpargne = () => {
  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );

  const hasObjectif =
    dashboardState?.dashboard?.monthlyIncomeGoal !== undefined;

  return {
    hasObjectif,
  };
};

export { useParcoursEpargne };
