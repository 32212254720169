import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {AccountState} from "../../../store/account/types";
import {getAccountAction} from "../../../store/account/actions";

const useAccount = () => {
   const dispatch = useDispatch();
   const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);

   useEffect(() => {
      if (!accountState.hasFetched && sessionStorage.getItem("token")) {
         dispatch(getAccountAction());
      }
   }, []);

   return accountState.account;
};

export {useAccount};
