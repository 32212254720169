import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RealizedDonation, RealizedDonationState} from "./types";
import {insertElement, removeElement, updateElement} from "../utils";

const initialState: RealizedDonationState = {realizedDonations: new Array<RealizedDonation>(), hasFetched: false};

export const realizedDonationsSlice = createSlice({
   name: "realizedDonations",
   initialState,
   reducers: {
      realizedDonationCreated: (currentState, action: PayloadAction<RealizedDonation>) => ({
         ...currentState,
         realizedDonations: insertElement(currentState.realizedDonations, action.payload)
      }),
      realizedDonationUpdated: (currentState, action: PayloadAction<RealizedDonation>) => ({
         ...currentState,
         realizedDonations: updateElement(currentState.realizedDonations, action.payload)
      }),
      realizedDonationDeleted: (currentState, action: PayloadAction<RealizedDonation>) => ({
         ...currentState,
         realizedDonations: removeElement(currentState.realizedDonations, String(action.payload.id))
      }),
      realizedDonationsFetched: (currentState, action: PayloadAction<RealizedDonation[]>) => ({
         ...currentState,
         realizedDonations: action.payload,
         hasFetched: true
      })
   }
});

export const {realizedDonationCreated, realizedDonationUpdated, realizedDonationDeleted, realizedDonationsFetched} = realizedDonationsSlice.actions;

export default realizedDonationsSlice.reducer;
