import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  DashboardAge,
  DashboardState
} from "store/dashboard/types";
import { estAgeSansMalusAgircArrco } from "store/dashboard/utils";
import { useDashboardState } from "./useDashboardState";

/**
 * Récupère les âges triés du dashboard et l'âge choisi dans le dashboard.
 * Si aucun n'a été choisi, récupère le premier par l'année.
 */
const useSelectedRetirementAge = () => {
  const dashboardState: DashboardState = useDashboardState();
  const intl = useIntl();

  const ages: DashboardAge[] | undefined = useMemo(() => {
    if (!dashboardState.dashboard?.ages) {
      return undefined;
    }

    return [
        ...(dashboardState.dashboard.ages || []),
      ].map((age: DashboardAge) => ({
        ...age,
        typesDepartTitre: age.typesDepart
          .map((typeDepart: string) =>
            intl.formatMessage({ id: `agesCles.${typeDepart}` })
          )
          .join(" = "),
      }));
  }, [intl, dashboardState.dashboard?.ages]);

  const ageSansMalusPresent = useMemo(() => {
    if (!ages) {
      return undefined;
    }

    return ages.find((age: DashboardAge) =>
        estAgeSansMalusAgircArrco(
          age,
          dashboardState.dashboard.firstRISPathDate
        )
      ) !== undefined;
  }, [ages, dashboardState.dashboard?.firstRISPathDate]);

  const agesTries = useMemo(() => {
    if (!ages) {
      return undefined;
    }

      // Tri des objets DashboardAge par date croissante
    return ages
        .filter(
          (age: DashboardAge) =>
            !estAgeSansMalusAgircArrco(
              age,
              dashboardState.dashboard.firstRISPathDate
            )
        )
        .sort(
          (a, b) =>
            a.retirementYear - b.retirementYear ||
            (a.retirementMonth || 0) - (b.retirementMonth || 0)
        );
    }, [ages, dashboardState.dashboard?.firstRISPathDate]);

    const selectedRetirementAge = useMemo(() => {
      if (!agesTries) {
        return undefined;
      }

      let ageDepart;
      if (dashboardState.dashboard.choosenAge) {
        ageDepart = agesTries.find(
          (age: DashboardAge) =>
            dashboardState.dashboard.choosenAge === age.type
        );
      }
      // Si pas d'âge choisi ou âge non trouvé (cas d'un changement de liste via RIS, ou d'un âge filtré),
      // on prend le premier
      if (!ageDepart && agesTries.length > 0) {
        [ageDepart] = agesTries;
      }

      return ageDepart;
    }, [agesTries, dashboardState.dashboard?.choosenAge]);

  return {
    agesTries,
    ageSansMalusPresent,
    selectedRetirementAge,
  };
};

export { useSelectedRetirementAge };
