import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardAgeType, User } from "store/dashboard/types";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";
import { State } from "store/store";
import {
  PAGE_CATEGORY_3_FLASH,
  PAGE_NAME_ESTIMATION_PENSION,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { PensionSituation } from "../../PensionSituation/PensionSituation";

export const SimulateurFlashPension: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { trackPage } = useTracking();

  const simulationAssuState: RetirementSimulationParametersState = useSelector<
    State,
    RetirementSimulationParametersState
  >((state) => state.retirementSimulationParameters);

  const changeSelectedAgeRetirement = (type: DashboardAgeType) => {
    const req: User = {
      choosenAge: type,
    };
    dispatch(updatePrefs(req));

    // On met à jour le booléen isParcoursDone pour permettre le débranchement sur le dashboard
    dispatch(
      updateRetirementSimulation({
        ...simulationAssuState.retirementSimulationParameters,
        isParcoursDone: true,
      })
    );
  };

  useEffect(() => {
    trackPage(
      PAGE_CATEGORY_3_FLASH,
      PAGE_NAME_ESTIMATION_PENSION,
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "3"
    );
  }, []);

  return (
    <PensionSituation
      changeSelectedAgeRetirement={changeSelectedAgeRetirement}
    />
  );
};
