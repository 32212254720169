import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { RetirementAge, RetirementTypes } from "store/parcoursRisProjection/types";
import { RetirementPensionEstimation } from "website/components/molecules/RetirementPensionEstimation/RetirementPensionEstimation";

type CarteEstimationProps = {
    dates: RetirementAge[];
};

export const CarteEstimation: FunctionComponent<CarteEstimationProps> = (props: React.PropsWithChildren<CarteEstimationProps>) => {
    const { dates } = props;

    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

    return (
        <>
            <SGTitle level={3} visuallevel={isPhone ? 2 : 4}>
                {intl.formatMessage({ id: "parcoursRIS.resultat.monEstimationTitle" })}
            </SGTitle>
            <SGBox padding={{ top: "md" }}>
                <SGSpace size="md" direction="vertical">
                    {dates?.map(
                        (dateDepart) =>
                            RetirementTypes[dateDepart.types_depart[0]] && (
                                <SGCard key={dateDepart.date}>
                                    <RetirementPensionEstimation dateDepart={dateDepart} />
                                </SGCard>
                            )
                    )}
                </SGSpace>
            </SGBox>
        </>
    );
};
