import {CustodyType, Family, FamilyState, FamilyStatus, MaritalStatus, Member} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {cloneFamily, isCoupleMaritalStatus, isIsolatedParentStatus, membersToFamily} from "./utils";
import {insertElement, storeDeepEquals} from "../utils";

const updateMembers = (inputFamily: Family, memberUpdated: Member, oldMember: Member) => {
   const family = cloneFamily(inputFamily);

   // si le statut de ME est en couple et qu'il n'a pas de partenaire on la crée
   if (memberUpdated.status === FamilyStatus.ME && !!memberUpdated.maritalStatus && isCoupleMaritalStatus(memberUpdated.maritalStatus) && !family.partner) {
      family.partner = {
         birthday: memberUpdated.birthday,
         deletable: true,
         incomes: [],
         projects: [],
         name: "Mon conjoint",
         maritalStatus: memberUpdated.maritalStatus,
         matrimonialRegime: memberUpdated.matrimonialRegime,
         status: FamilyStatus.PARTNER
      };
   }
   if (memberUpdated.status === FamilyStatus.ME && isIsolatedParentStatus(memberUpdated.maritalStatus) && family.partner) {
      family.partner.maritalStatus = memberUpdated.maritalStatus || MaritalStatus.SINGLE;
   }

   switch (memberUpdated.status) {
      case FamilyStatus.ME: {
         if (!!memberUpdated.maritalStatus && !!memberUpdated.birthday) {
            family.me = {...memberUpdated, birthday: memberUpdated.birthday, maritalStatus: memberUpdated.maritalStatus};
         } else {
            throw new Error("me should have a valid birthday and marital status");
         }
         break;
      }
      case FamilyStatus.PARTNER: {
         if (memberUpdated.maritalStatus) {
            family.partner = {...memberUpdated, maritalStatus: memberUpdated.maritalStatus};
         } else {
            throw new Error("partner should have a valid marital status");
         }
         break;
      }
      case FamilyStatus.CHILD: {
         family.children = family.children.map((m: Member) => storeDeepEquals(oldMember, m) ? memberUpdated : m);
         break;
      }
      default: {
         family.relatives = family.relatives.map((m: Member) => storeDeepEquals(oldMember, m) ? memberUpdated : m);
      }
   }

   return family;
};

const computeShouldRedirectToIncomes = (familyState: FamilyState, actionType: string, newMember: Member): boolean => {
   if (familyState.shouldRedirectToIncomes) {
      return true;
   }
   // On doit rediriger vers la page d'incomes si on ajoute un conjoint ou si l'on change le statut d'activité de Me ou du conjoint
   switch (actionType) {
      case updateMemberInStore.type:
         if (newMember.status === FamilyStatus.ME) {
            return (familyState.family.me.employmentStatus !== newMember.employmentStatus);
         }
         if (newMember.status === FamilyStatus.PARTNER) {
            const {partner} = familyState.family;
            if (partner !== undefined) {
               return partner.employmentStatus !== newMember.employmentStatus;
            }
         }

         return false;
      case addMemberInStore.type:
         return newMember.status === FamilyStatus.PARTNER;
      default:
         return false;
   }
};

const removeMember = (inputFamily: Family, memberDeleted: Member): Family => {
   const family = cloneFamily(inputFamily);
   switch (memberDeleted.status) {
      case FamilyStatus.ME: {
         throw new Error("No delete me");
      }
      case FamilyStatus.PARTNER: {
         // Si l'on retire le conjoint, Me devient célibataire
         family.me.maritalStatus = MaritalStatus.SINGLE;
         family.me.matrimonialRegime = undefined;
         family.partner = undefined;
         for (let i = 0; i < family.children.length; i += 1) {
            family.children[i].custodyType = CustodyType.FULL_CUSTODY_ME;
         }
         break;
      }
      case FamilyStatus.CHILD: {
         const index = family.children.findIndex((m: Member) => storeDeepEquals(m, memberDeleted));
         family.children.splice(index, 1);
         break;
      }
      default: {
         const index = family.relatives.findIndex((m: Member) => storeDeepEquals(m, memberDeleted));
         family.relatives.splice(index, 1);
      }
   }

   return family;
};

const insertMember = (inputFamily: Family, memberAdded: Member) => {
   const family = cloneFamily(inputFamily);

   switch (memberAdded.status) {
      case FamilyStatus.ME: {
         throw new Error("cant add another ME");
      }
      case FamilyStatus.PARTNER: {
         // Si l'on ajoute un conjoint marié, Me devient marié
         if (memberAdded.maritalStatus === MaritalStatus.MARRIED) {
            family.me.maritalStatus = MaritalStatus.MARRIED;
            if (memberAdded.matrimonialRegime) {
               family.me.matrimonialRegime = memberAdded.matrimonialRegime;
            }
            for (let i = 0; i < family.children.length; i += 1) {
               family.children[i].custodyType = CustodyType.FULL_CUSTODY;
            }
         }
         if (isCoupleMaritalStatus(memberAdded.maritalStatus)) {
            for (let i = 0; i < family.children.length; i += 1) {
               family.children[i].custodyType = CustodyType.FULL_CUSTODY;
            }
         }
         if (memberAdded.maritalStatus) {
            family.partner = {...memberAdded, maritalStatus: memberAdded.maritalStatus};
         }
         break;
      }
      case FamilyStatus.CHILD: {
         family.children = insertElement(family.children, memberAdded);
         break;
      }
      default: {
         family.relatives = insertElement(family.relatives, memberAdded);
      }
   }

   return family;
};

const initialState: FamilyState = {
   family: {
      me: {
         name: "",
         id: 0,
         deletable: false,
         projects: [],
         incomes: [],
         status: FamilyStatus.ME,
         maritalStatus: MaritalStatus.SINGLE,
         birthday: new Date()
      },
      partner: undefined,
      children: [],
      relatives: []
   },
   hasFetched: false,
   shouldRedirectToIncomes: false,
   hasLocalChanges: false
};

export const membersSlice = createSlice({
   initialState,
   name: "members",
   reducers: {
      membersFetched: (currentState, action: PayloadAction<Family>) => ({...currentState, family: action.payload, hasLocalChanges: false, hasFetched: true}),
      importFamilySuccess: (currentState, action: PayloadAction<Member[]>) => ({
         ...currentState,
         family: membersToFamily(action.payload),
         hasLocalChanges: false,
         hasFetched: true
      }),
      updateMemberInStore: (currentState, action: PayloadAction<{ newMember: Member, oldMember: Member }>) => ({
         ...currentState,
         family: updateMembers(currentState.family, action.payload.newMember, action.payload.oldMember),
         hasLocalChanges: true,
         shouldRedirectToIncomes: computeShouldRedirectToIncomes(currentState, action.type, action.payload.newMember)
      }),
      deleteMemberInStore: (currentState, action: PayloadAction<Member>) => ({
         ...currentState,
         family: removeMember(currentState.family, action.payload),
         hasLocalChanges: true,
         shouldRedirectToIncomes: computeShouldRedirectToIncomes(currentState, action.type, action.payload)
      }),
      addMemberInStore: (currentState, action: PayloadAction<Omit<Member, "id">>) => ({
         ...currentState,
         family: insertMember(currentState.family, action.payload),
         hasLocalChanges: true,
         shouldRedirectToIncomes: computeShouldRedirectToIncomes(currentState, action.type, action.payload)
      }),
      updateFamilyStore: (currentState, action: PayloadAction<Family>) => ({
         ...currentState,
         family: action.payload,
         hasLocalChanges: true,
      }),
      resetAllInStore: (currentState) => ({
         ...currentState,
         hasLocalChanges: true,
         family: cloneFamily(currentState.family)
      })
   }
});

export const {
   membersFetched,
   importFamilySuccess,
   updateMemberInStore,
   deleteMemberInStore,
   addMemberInStore,
   updateFamilyStore,
   resetAllInStore
} = membersSlice.actions;

export default membersSlice.reducer;
