export interface PersonnalisationDashboardState {
  dashboard?: DashboardType;
  hasFetched: boolean;
}

export enum DashboardType {
  PENSION = "PENSION",
  PATRIMOINE = "PATRIMOINE",
  BESOIN = "BESOIN",
  NOVICE = "NOVICE",
}

export function getIdTrackingType(dashboard: string) {
  switch (dashboard) {
    case DashboardType.BESOIN:
      return "besoin";
    case DashboardType.NOVICE:
      return "novice";
    case DashboardType.PATRIMOINE:
      return "patrimoine";
    case DashboardType.PENSION:
        return "pension";
    default:
      return "";
  }
}

export const FETCH_PERSONNALISATION_DASHBOARD =
  "FETCH_PERSONNALISATION_DASHBOARD";

export const FETCH_PERSONNALISATION_DASHBOARD_PARAMS =
  "FETCH_PERSONNALISATION_DASHBOARD_PARAMS";
