import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import {
  SGAvenirStyledPensionsRentes
} from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGText } from "sg-typo";
import { SimulateurEconomiesImpotsState } from "store/simulateurEconomiesImpots/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useSimulateurEconomiesImpotsState } from "website/components/hooks/useSimulateurEconomiesImpotsState";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { SIMULATEUR_ECONOMIES_IMPOTS } from "website/utils/privateRoutes";

export const AccesSimulateurEconomieImpot: FunctionComponent = () => {
  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const { trackClick } = useTracking();

  const simulateurEconomiesImpotsState: SimulateurEconomiesImpotsState =
    useSimulateurEconomiesImpotsState();

  return (
    <TuileCliquable
      disableautomargin={!isPhone}
      icone={<SGAvenirStyledPensionsRentes />}
      iconeTaille="s"
      titre={intl.formatMessage({
        id: "dashboard.sei.title",
      })}
      texte={intl.formatMessage(
        {
          id:
            simulateurEconomiesImpotsState.savedParameters.payment === undefined
              ? "dashboard.sei.new"
              : "dashboard.sei.modify",
        },
        {
          economieImpot:
            simulateurEconomiesImpotsState.taxEconomy.taxSaving.toLocaleString(
              "fr-FR"
            ),
          b: (word: string) => <SGText weight="700">{word}</SGText>,
        }
      )}
      lien={`#${SIMULATEUR_ECONOMIES_IMPOTS}`}
      onClick={() => trackClick("clic-sur-per-mon-economie-d-impot")}
      titreTaille={4}
    />
  );
};
