import { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { ImportState } from "store/import/types";
import { CustodyType, FamilyStatus, MaritalStatus } from "store/members/types";
import { State } from "store/store";
import { useFamily } from "website/components/hooks/useFamily";
import { ChildrenCounterAssu } from "website/components/molecules/ChildrenCounterAssu/ChildrenCounterAssu";
import "./FamilySituation.scss";

interface FamilySituationProps {
  onSubmit: () => void;
  numberChild: number;
  setNumberChild: (value: any) => void;
  setDateOfBirth: (value: Date) => void;
  setMaritalStatus: (value: MaritalStatus) => void;
  onClickReturn: () => void;
  texteRetour: string;
}

export const FamilySituation: FunctionComponent<FamilySituationProps> = (
  props: FamilySituationProps
) => {
  const intl = useIntl();
  const {
    onSubmit,
    numberChild,
    setNumberChild,
    setDateOfBirth,
    setMaritalStatus,
    onClickReturn,
    texteRetour
  } = props;

  const max = 9;

  const [dateOfBirthToShow, setDateOfBirthToShow] = useState<string>();
  const importData: ImportState = useSelector<State, ImportState>(
    (state) => state.importData
  );
  const family = useFamily();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit, formState: { submitCount } } = methods;

  // Permet de revalider la valeur de l'input childrenCounter suite à l'utilisation d'un Controller
  useEffect(() => {
    methods.setValue("childrenCounter", numberChild, {
      shouldValidate: submitCount > 0,
    });
  }, [numberChild]);

  const formatDate = (date: Date) => {
    if (date) {
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }

    return "01/01/1970";
  };

  useEffect(() => {
    if (family?.me?.birthday) {
      setDateOfBirthToShow(formatDate(new Date(family.me.birthday)));
      setDateOfBirth(new Date(family.me.birthday));
      setMaritalStatus(family.me.maritalStatus);
      setNumberChild(
        family.children.filter(
          (child) =>
            child.status === FamilyStatus.CHILD &&
            (child.custodyType === CustodyType.FULL_CUSTODY ||
              child.custodyType === CustodyType.FULL_CUSTODY_ME)
        ).length
      );
    }
  }, [family, importData]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SGSpace direction="vertical" disableautomargin size="xl">
          <SGSpace key="space" direction="vertical" disableautomargin size="sm">
            <SGTitle key="titre" level={2}>
              {intl.formatMessage({ id: "tunnel.situation.title" })}
            </SGTitle>

            <SGText key="text" size={isPhone ? "l" : undefined}>
              {intl.formatMessage({ id: "tunnel.situation.subtitle" })}
            </SGText>

            <SGBox key="box" padding={{ top: "sm" }}>
              <SGText size={isPhone ? "l" : undefined}>
                {intl.formatMessage(
                  {
                    id: "tunnel.situation.dateOfBirth",
                  },
                  {
                    dateOfBirth: dateOfBirthToShow || "01/01/1970",
                    b: (word: string) => word,
                  }
                )}
              </SGText>
            </SGBox>
          </SGSpace>
          <ChildrenCounterAssu
            key="childrenCounterAssu"
            counter={numberChild}
            setCounter={setNumberChild}
            max={max}
            name="childrenCounter"
            placementTooltip="bottom"
            sizeInput="m"
          />
          <SGButtonGroup key="buttons" align="center" disableautomargin>
            <SGButton onClick={handleSubmit(onSubmit)} size="md">
              {intl.formatMessage({ id: "tunnel.buttons.next" })}
            </SGButton>
            <SGButton type="secondary" onClick={onClickReturn} size="md">
              <SGText>
                {intl.formatMessage({ id: texteRetour })}
              </SGText>
            </SGButton>
          </SGButtonGroup>
        </SGSpace>
      </form>
    </FormProvider>
  );
};
