import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardType, PersonnalisationDashboardState } from "./types";

const initialState: PersonnalisationDashboardState = {
   hasFetched: false,
};

export const personnalisationDashboardSlice = createSlice({
   name: "personnalisationDashboard",
   initialState,
   reducers: {
      personnalisationQuestionsFetched: (currentState, action: PayloadAction<DashboardType>): PersonnalisationDashboardState => ({
         ...currentState,
         dashboard: action.payload,
         hasFetched: true,
      }),
      reset: (): PersonnalisationDashboardState => ({
         ...initialState,
      }),
   },
});

export const { personnalisationQuestionsFetched, reset } =
personnalisationDashboardSlice.actions;

export default personnalisationDashboardSlice.reducer;
