import { FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { logout } from "store/login/actions";
import { State } from "store/store";
import { TrackingState } from "store/tracking/types";
import { Image } from "website/components/atoms/Image/Image";
import { LoadingStatus } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const MaintenancePage: FunctionComponent = () => {
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const dispatch = useDispatch();
    const { trackErrorPage, trackClick } = useTracking();
    const { trackingInitStatus, trackingReloading } = useSelector<State, TrackingState>(
      (state) => state.tracking
    );

    useEffect(() => {
        if (trackingInitStatus !== LoadingStatus.LOADING && !trackingReloading) {
            trackErrorPage("erreur-maintenance", "maintenance");
        }
    }, [trackingInitStatus, trackingReloading]);

    const handleOnClick = () => {
        trackClick("clic-sur-retour-aux-comptes");
        dispatch(logout());
    };

    return (
        <SGContent>
            <SGBox margin={{ bottom: "xl" }}>
                <SGGridRow align="middle">
                    <SGGridCol textalign="center" span={12} md={0}>
                        <Image src="images/erreurs/500-mob.svg" alt="Erreur 500" />
                    </SGGridCol>
                    <SGGridCol xs={12} md={6} textalign={isPhone ? "center" : "left"}>
                        <SGTitle level={1}>
                            <SGText color="error">
                                <FormattedMessage id="maintenance.title.error" />
                            </SGText>
                        </SGTitle>
                        <SGTitle level={2} visuallevel={1}>
                            <FormattedMessage id="maintenance.title" />
                        </SGTitle>
                        <SGBox margin={{ top: "sm" }}>
                            <SGText weight="600" size="l">
                                <FormattedMessage id="maintenance.subtitle" />
                            </SGText>
                        </SGBox>
                        <SGBox textalign={isPhone ? "center" : "left"} margin={{ top: "xl" }}>
                            <SGButton onClick={handleOnClick}>
                                <FormattedMessage id="maintenance.button" />
                            </SGButton>
                        </SGBox>
                    </SGGridCol>
                    <SGMediaQuery minwidth="md">
                        <SGGridCol textalign="right" span={6}>
                            <Image src="images/erreurs/500.svg" alt="Erreur 500" />
                        </SGGridCol>
                    </SGMediaQuery>
                </SGGridRow>
            </SGBox>
        </SGContent>
    );
};

export { MaintenancePage };
