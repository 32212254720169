import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountAction } from "store/account/actions";
import { useFamily } from "website/components/hooks/useFamily";
import { useFeatures } from "website/components/hooks/useFeatures";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { AccountData } from "../../../store/account/types";
import { importAssetAndMembers, startImportScrapping, updateImportData } from "../../../store/import/actions";
import { ImportState } from "../../../store/import/types";
import { State } from "../../../store/store";
import { setAuthToken } from "../axios";
import { getQueryStringParams } from "../formatting/queryString";
import { getUrlHostName } from "../import/sg.import-service";
import { DASHBOARD, TUNNEL } from "../privateRoutes";
import { redirectToRelativePath } from "../routes/routing";

const Authentication: FunctionComponent = () => {
    const idScript = "webScrappingScript";
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();

    const hasFetchedAccount = useSelector<State, boolean>((state) => state.account.hasFetched);
    // Pas possible d'utiliser useAccount en l'état car on alimente le token ici
    const account = useSelector<State, AccountData>((state) => state.account.account);

    const { disponibiliteParcoursRevenusComplementaires } = useFeatures();
    const family = useFamily();
    const importData: ImportState = useSelector<State, ImportState>((state) => state.importData);

    useEffect(() => {
        const token = getTokenFromParams();
        if (!hasFetchedAccount && token) {
            setAuthToken(token);
            sessionStorage.setItem("token", token);
            dispatch(getAccountAction());
        }
    }, []);

    /**
     * Une fois le token récupéré, récupération du compte.
     * Ensuite, import des membres et assets via Wealth.
     */
    useEffect(() => {
        if (disponibiliteParcoursRevenusComplementaires !== undefined) {
            if (hasFetchedAccount && !importData.alreadyImported) {
                saveEvent(trackingEvent.CONNEXION_MCR);
                dispatch(
                    updateImportData({
                        ...importData,
                        alreadyImported: true,
                        isImportingMembers: true,
                    })
                );
                dispatch(importAssetAndMembers(!!disponibiliteParcoursRevenusComplementaires));
            }
        }
    }, [hasFetchedAccount, disponibiliteParcoursRevenusComplementaires]);

    /**
     * Pas de récupération des loans avant d'avoir importé les members au moins une fois.
     */
    useEffect(() => {
        const scriptUri = sessionStorage.getItem("scripturi");
        if (hasFetchedAccount && family.me.id && scriptUri) {
            importSgData(scriptUri, account);

            // Récupération de redirectUrl si présent
            const redirectUrl = localStorage.getItem("redirectUrl") ?? DASHBOARD;
            localStorage.removeItem("redirectUrl");
            redirectToRelativePath(account.tunnelCompleted ? redirectUrl : TUNNEL);
        }
    }, [hasFetchedAccount, family.me]);

    /**
     * Importe le script de scrapping puis récupère les loans.
     *
     * @param scriptUri URI du script gfm scrapping
     * @param accountData Compte
     */
    const importSgData = (scriptUri: string, accountData: AccountData) => {
        try {
            const scriptTag = document.createElement("script");

            scriptTag.src = scriptUri;
            scriptTag.id = idScript;
            const host: string = getUrlHostName(scriptUri);
            scriptTag.onload = () => {
                const script = document.querySelector(`#${idScript}`);
                if (script && accountData.id !== -1) {
                    dispatch(startImportScrapping(host, () => script.remove()));
                }
            };

            document.body.appendChild(scriptTag);
        } catch (e: any) {
            // TODO
        }
    };

    return null;
};

export const getTokenFromParams = (): string | null => {
    const searchParams = getQueryStringParams(window.location.href.split("?")[1]);
    const { token, scripturi } = searchParams;

    if (token) {
        sessionStorage.setItem("token", token);
    }
    if (scripturi) {
        sessionStorage.setItem("scripturi", scripturi);
    }

    return token;
};

export { Authentication };
