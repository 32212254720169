import React, {FunctionComponent} from "react";
import {SGCard} from "sg-card";
import {SGIcon} from "sg-icon";
import {SGAvenirNavPlus} from "sg-icon-pack-base";
import {SGButtonIntl} from "../SGButtonIntl/SGButtonIntl";

interface ButtonAddNewElementProps {
   text: string;
   disabled?: boolean;
   onClick?: () => void;
   cypressName: string;
}

const ButtonAddNewElement: FunctionComponent<ButtonAddNewElementProps> = (
   props: ButtonAddNewElementProps
) => {
   const {text, disabled, onClick, cypressName} = props;

   return (
      <SGCard background="dark" clickable={!disabled} textalign="center" hoverable bordered>
         <SGButtonIntl type="tertiary" cypressName={cypressName} icon={<SGIcon currentcolor component={<SGAvenirNavPlus/>}/>}
                       intlId={text} disabled={disabled} onClick={onClick} />
      </SGCard>
   );
};

export {ButtonAddNewElement};
