import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";

interface SectionTemplateProps {
  sectionType: SectionDashBoardType;
  showHeader?: boolean;
}

const SectionTemplate: FunctionComponent<SectionTemplateProps> = (
  props: React.PropsWithChildren<SectionTemplateProps>
) => {
  const { sectionType, showHeader = true, children } = props;
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGSpace direction="vertical" size="md" disableautomargin>
      {showHeader && (
        <SGSpace key="space" direction="vertical" size="sm">
          <SGTitle key="titre" level={2} visuallevel={isPhone ? 2 : 3}>
            <FormattedMessage id={`dashboard.section.${sectionType}.titre`} />
          </SGTitle>
          <SGText key="texte" size="l">
            <FormattedMessage id={`dashboard.section.${sectionType}.texte`} />
          </SGText>
        </SGSpace>
      )}
      {children}
    </SGSpace>
  );
};

export { SectionTemplate };
