export interface ElxColor {
   label: string;
   hexCode: string;
}

// Dépenses
export const OLD_MAUVE: ElxColor = {label: "old-mauve", hexCode: "#6d213c"};
// Avantages Fiscaux
export const WHEAT: ElxColor = {label: "wheat", hexCode: "#ead2ac"};
// Taxes
export const CADET_BLUE: ElxColor = {label: "cadet-blue", hexCode: "#38a3a5"};
// Revenus Disponibles
export const ORANGE_YELLOW: ElxColor = {label: "orange-yellow", hexCode: "#f2bb05"};
//  Salaires
export const FOREST_GREEN: ElxColor = {label: "forest-green", hexCode: "#57a773"};
// Pensions
export const TEA_GREEN: ElxColor = {label: "tea-green", hexCode: "#d0ecc1"};
// Revenus des PER, vente d'asset à la retraite
export const FLAME_ORANGE: ElxColor = {label: "flame-orange", hexCode: "#ec4e20"};
// Pensions Alimentaires
export const CHESTNUT: ElxColor = {label: "chestnut", hexCode: "#8c3f2c"};
// Loyer
export const SILVER: ElxColor = {label: "silver", hexCode: "#bbc7ce"};
// Projets
export const LAVENDER_WEB: ElxColor = {label: "lavender-web", hexCode: "#e2e2f3"};
// Revenus Exceptionnels
export const GREY: ElxColor = {label: "grey", hexCode: "#4c483d"};

// Ces couleurs ne doivent être changées que si elles changent sur l'EIP Société Générale
// Comptes Courants
export const SG_BLUE: ElxColor = {label: "sg-blue", hexCode: "#046aa7"};
// Comptes bancaires
export const SG_ORANGE: ElxColor = {label: "sg-orange", hexCode: "#b56e2b"};
// Immobilier
export const SG_PINK: ElxColor = {label: "sg-pink", hexCode: "#f05b6f"};
// Prêts
export const SG_PURPLE: ElxColor = {label: "sg-purple", hexCode: "#7e00ae"};
// Autres
export const SG_BLACK: ElxColor = {label: "sg-black", hexCode: "#303030"};
// Couleur Principale
export const SG_RED: ElxColor = {label: "sg-red", hexCode: "#E2010B"};
