import { useEffect, useState } from "react";
import { DashboardState, hasFetchedDashboard } from "store/dashboard/types";
import { SimulateurEconomiesImpotsState } from "store/simulateurEconomiesImpots/types";
import { useSimulateurEconomiesImpotsState } from "../useSimulateurEconomiesImpotsState";
import { useDashboardState } from "./useDashboardState";

const useDashboardProgression = () => {
  const dashboardState: DashboardState = useDashboardState();
  const simulateurEconomiesImpotsState: SimulateurEconomiesImpotsState =
    useSimulateurEconomiesImpotsState();
  const [pourcentage, setPourcentage] = useState<number>(20);

  useEffect(() => {
    let pourcent = 20;
    if (hasFetchedDashboard(dashboardState)) {
      if (dashboardState.dashboard.firstRISPathDate) {
        pourcent += 20;
      }
      if (dashboardState.dashboard.firstPatrimonyPathDate) {
        pourcent += 20;
      }
      if (dashboardState.dashboard.firstMonthlyIncomeGoalInputDate) {
        pourcent += 20;
      }
      if (dashboardState.dashboard.firstMyPlanDate) {
        pourcent += 10;
      }
    }
    if (simulateurEconomiesImpotsState.savedParameters.simulationDate) {
      pourcent += 10;
    }
    if (
      hasFetchedDashboard(dashboardState) ||
      simulateurEconomiesImpotsState.savedParameters.simulationDate
    ) {
      setPourcentage(pourcent);
    }
  }, [
    dashboardState,
    simulateurEconomiesImpotsState.savedParameters.simulationDate,
  ]);

  return pourcentage;
};

export { useDashboardProgression };
