import React, {FunctionComponent} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Redirect} from "react-router-dom";
import {isLoggedIn} from "../../../../../store/account/types";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {StringInput} from "../../../atoms/StringInput/StringInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {emailValidationRegex, nameValidationRegex} from "./const";
import {useSignUp} from "./useSignUp";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {CGU, LOGIN} from "../../../../utils/publicRoutes";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGButtonGroup} from "sg-button";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {SGCard} from "sg-card";

const Signup: FunctionComponent = () => {
   const {shouldNavigateToPrivatePage, signUpState, accountState, onSubmit} =
      useSignUp();

   const formMethods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   const {handleSubmit} = formMethods;

   if (shouldNavigateToPrivatePage) {
      // @ts-ignore
      return <Redirect to="/advices"/>;
   }

   return (
      <>
         {signUpState.isSignedUp && isLoggedIn(accountState) && (
            /* @ts-ignore */
            <Redirect to={{pathname: "/tunnel"}}/>
         )}
         <SGCard textalign="center">
            <SGTitleIntl intlId="signup.title" cypressName="signup-title"/>
            <SGTextIntl intlId="signup.please.create.account"/>
            {signUpState.isLoginAlreadyUsed && (
               <SGAlertIntl type="warning" cypressName="signup-email-already-used-error" title="common.warning">
                  <SGTextIntl intlId="signup.login.already.used"/>
               </SGAlertIntl>
            )}

            {signUpState.isEmailInvalid && (
               <SGAlertIntl type="warning" cypressName="signup-email-invalid" title="common.warning">
                  <SGTextIntl intlId="signup.email.invalid"/>
               </SGAlertIntl>
            )}

            <FormProvider {...formMethods}>
               <form>
                  <FormInputs colLength={10}>
                     <StringInput label="signup.form.your.firstname" defaultValue="" name="firstname" minLength={0} maxLength={50}
                                  cypressName="signUp-firstName"
                                  placeholder="signup.form.your.firstname.placeholder" maxLengthMessage="signup.firstname.max.size"
                                  pattern={nameValidationRegex} patternMessage="signup.firstname.invalid.characters"/>

                     <StringInput label="login.form.your.email" defaultValue="" name="email" minLength={1} maxLength={50} cypressName="signUp-mail"
                                  placeholder="signup.form.your.email.placeholder" maxLengthMessage="login.form.error.username.size"
                                  minLengthMessage="login.form.error.username.size" pattern={emailValidationRegex}
                                  patternMessage="login.form.error.username.bad.email"/>

                     <StringInput label="login.form.your.password" tooltipLabel="login.form.password.pattern" tooltipTransformations={{br: <br/>}}
                                  defaultValue="" name="password" cypressName="signUp-password" minLength={8} minLengthMessage="login.form.error.password.size"
                                  maxLength={16} maxLengthMessage="login.form.error.password.size" isPassword
                                  pattern={/^(?=.*[A-Z])(?=.*[!^`\-"%'+,_.:;~?@#$&*<>])(?=.*[\d])(?=.*[a-z])(?!.*(.)\1{2}).{8,16}$/}
                                  patternMessage="login.form.error.password.pattern"
                                  onValueChange={() => {
                                     if (formMethods.getValues("confirmPassword") !== null) {
                                        formMethods.trigger("confirmPassword")
                                     }
                                  }}
                                  placeholder="login.form.your.password.placeholder"/>

                     <StringInput label="login.form.confirm.your.password" defaultValue="" name="confirmPassword" cypressName="signUp-confirmPassword"
                                  minLength={6} isPassword minLengthMessage="login.form.error.password.size" maxLength={100}
                                  maxLengthMessage="login.form.error.password.size" onValueChange={() => formMethods.trigger("confirmPassword")}
                                  placeholder="login.form.your.password.placeholder" validate={(value: string) =>
                        value === formMethods.getValues().password
                     } validateMessage="signup.different.passwords"/>
                  </FormInputs>
                  <ErrorElement cypressName="signup-error" errorTextPrefix="signup.error"/>
                  <SGButtonGroup layout="column" align="center">
                     <SGButtonIntl type="primary" size="md" disabled={!formMethods.formState.isValid} cypressName="signUp-submit"
                                   onClick={handleSubmit(onSubmit)} intlId="signup.action"/>
                     <SGButtonIntl type="link" cypressName="login-forgotten-password" onClick={() => redirectToRelativePath(LOGIN)}
                                   intlId="signup.already.signed.up"/>
                     <SGButtonIntl type="link" onClick={() => redirectToRelativePath(CGU)} intlId="signup.link.cgu"/>
                  </SGButtonGroup>
               </form>
            </FormProvider>
         </SGCard>
      </>
   );
};

export {Signup};
