import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { PersonalInformations, PersonalInformationsState, PERSONAL_INFORMATIONS_FETCHED, PERSONAL_INFORMATIONS_SAVED, UPDATE_PERSONAL_INFORMATIONS_STORE } from "./types";

const initialState: PersonalInformationsState = {
   hasFetched: false,
   personalInformations: {
      situationFamiliale: undefined,
      nivEtude: undefined,
      travailEtranger: undefined,
      categorieActive: undefined,
      traitementIndiciaire: undefined,
      serviceMilitaire: undefined,
      situationsHandicap: undefined,
   }
};

export const PersonalInformationsReducer: Reducer<PersonalInformationsState, PayloadAction<PersonalInformations>> = (
   currentState = initialState,
   action
): PersonalInformationsState => {
   switch (action.type) {
      case PERSONAL_INFORMATIONS_FETCHED:
         // Si non trouvé, on récupère quand même une 200 sans body...
         return {
            hasFetched: true,
            personalInformations: action.payload ? action.payload : initialState.personalInformations,
         };
      case PERSONAL_INFORMATIONS_SAVED:
         return {
            ...currentState,
            personalInformations: action.payload
         };
      case UPDATE_PERSONAL_INFORMATIONS_STORE:
         return {
            ...currentState,
            personalInformations: action.payload
         };
      default:
         return currentState;
   }
}
