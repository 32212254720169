import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Income, IncomeGraphCategory} from "../../../../store/incomes/types";
import {Expense, ExpenseGraphCategory,} from "../../../../store/expenses/types";
import {Loan} from "../../../../store/loans/types";
import {AssetCategory, AssetType, AssetWithValuation, LOAN, RealEstateAsset,} from "../../../../store/assets/types";
import {NameAndValuationFrame} from "../NameAndValuationFrame/NameAndValuationFrame";
import {ProjectType} from "../../../../store/projects/types";
import {getAssetCategory, getExpenseGraphCategory, getIncomeGraphCategory,} from "../../../../store/assets/utils";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {TaxCredit} from "../../../../store/tax/types";
import {FrameCategory} from "./FrameCategory";
import {CHESTNUT, SG_BLUE, SG_PINK, WHEAT} from "../../../utils/graphs/colors";
import {redirectToRelativePath} from "../../../utils/routes/routing";
import {
   EDIT_ASSETS,
   EDIT_CHILD_SUPPORT_EXPENSES,
   EDIT_EXPENSES,
   EDIT_INCOMES,
   EDIT_LOANS,
   EDIT_REALIZED_DONATIONS,
   EDIT_TAX_CREDITS
} from "../../../utils/privateRoutes";
import {SGAvenirStrokedModification} from "sg-icon-pack-base";
import {SGIcon} from "sg-icon";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAccordion, SGAccordionPanel} from "sg-accordion";
import {SGSpace} from "sg-space";
import {RealizedDonation} from "../../../../store/realizedDonations/types";
import {replaceSpecialCharsAndLowercase} from "../../../utils/formatting/replaceSpecialCharsAndLowercase";
import {SGBlock} from "sg-typo";

interface NameAndValuationCollapsableFramesProps {
   title: string;
   amountSubTitle?: string;
   emptyListText?: string;
   cypressName: string;
   incomes?: Income[];
   expenses?: Expense[];
   loans?: Loan[];
   assets?: AssetWithValuation[];
   taxCredits?: TaxCredit[];
   rentTaxCredits?: RealEstateAsset[];
   childSupportExpenses?: Expense[];
   realizedDonations?: RealizedDonation[];
}

interface AssetCategoryAndValues {
   category: AssetCategory;
   valuations: AssetWithValuation[];
}

export const assetsToCategoryArray = (assets: AssetWithValuation[]) => {
   const resultMap: Map<AssetCategory, AssetWithValuation[]> = new Map<AssetCategory, AssetWithValuation[]>();

   assets.forEach((asset) => {
      const assetCategory = getAssetCategory(asset.assetType);
      let array = resultMap.get(assetCategory);
      if (!array) {
         array = [];
         resultMap.set(assetCategory, array);
      }
      array.push(asset);
   });

   const resultArray: Array<AssetCategoryAndValues> = new Array<AssetCategoryAndValues>();
   resultMap.forEach((value, key) =>
      resultArray.push({category: key, valuations: value})
   );

   return resultArray.sort((a, b) => a.category.priority - b.category.priority);
};

interface IncomeCategoryAndValues {
   category: IncomeGraphCategory;
   incomes: Income[];
}

export const incomesToCategoryArray = (incomes: Income[]) => {
   const resultMap: Map<IncomeGraphCategory, Income[]> = new Map<IncomeGraphCategory, Income[]>();
   incomes.forEach((income) => {
      const incomeCategory = getIncomeGraphCategory(income.type);
      let array = resultMap.get(incomeCategory);
      if (!array) {
         array = [];
         resultMap.set(incomeCategory, array);
      }
      array.push(income);
   });

   const resultArray: Array<IncomeCategoryAndValues> = new Array<IncomeCategoryAndValues>();
   resultMap.forEach((value, key) =>
      resultArray.push({category: key, incomes: value})
   );

   return resultArray;
};

interface ExpenseCategoryAndValues {
   category: ExpenseGraphCategory;
   expenses: Expense[];
}

export const expensesToCategoryArray = (expenses: Expense[]) => {
   const resultMap: Map<ExpenseGraphCategory, Expense[]> = new Map<ExpenseGraphCategory, Expense[]>();
   expenses.forEach((expense) => {
      const expenseCategory = getExpenseGraphCategory(expense.type);
      let array = resultMap.get(expenseCategory);
      if (!array) {
         array = [];
         resultMap.set(expenseCategory, array);
      }
      array.push(expense);
   });

   const resultArray: Array<ExpenseCategoryAndValues> =
      new Array<ExpenseCategoryAndValues>();
   resultMap.forEach((value, key) =>
      resultArray.push({category: key, expenses: value})
   );

   return resultArray;
};

const NameAndValuationCollapsableFrames: FunctionComponent<NameAndValuationCollapsableFramesProps> = (props: NameAndValuationCollapsableFramesProps) => {
   const {
      title, amountSubTitle, emptyListText, cypressName, incomes, expenses,
      loans, assets, taxCredits, rentTaxCredits, childSupportExpenses, realizedDonations
   } = props;

   const intl = useIntl();
   const [hasFrames, setHasFrames] = useState(false);

   useEffect(() => {
      updateHasFrames();
   }, []);

   useEffect(() => {
      updateHasFrames();
   }, [incomes, expenses, assets, loans, taxCredits, childSupportExpenses, rentTaxCredits, realizedDonations]);

   const updateHasFrames = () => {
      setHasFrames(
         (!!incomes && incomes.length !== 0) ||
         (!!expenses && expenses.length !== 0) ||
         (!!assets && assets.length !== 0) ||
         (!!rentTaxCredits && rentTaxCredits.length !== 0) ||
         (!!loans && loans.length !== 0) ||
         (!!taxCredits && taxCredits.length !== 0) ||
         (!!childSupportExpenses && childSupportExpenses.length !== 0) ||
         (!!realizedDonations && realizedDonations.length !== 0)
      );
   };

   const goToEditor = () => {
      if (incomes) {
         redirectToRelativePath(EDIT_INCOMES);
      }
      if (expenses) {
         redirectToRelativePath(EDIT_EXPENSES);
      }
      if (assets || rentTaxCredits) {
         redirectToRelativePath(EDIT_ASSETS);
      }
      if (loans) {
         redirectToRelativePath(EDIT_LOANS);
      }
      if (taxCredits) {
         redirectToRelativePath(EDIT_TAX_CREDITS);
      }
      if (childSupportExpenses) {
         redirectToRelativePath(EDIT_CHILD_SUPPORT_EXPENSES);
      }
      if (realizedDonations) {
         redirectToRelativePath(EDIT_REALIZED_DONATIONS);
      }
   };

   return (
      <SGAccordion data-cy={cypressName} defaultActiveKey="defaultPanel" divider={false} disableContentMargin>
         <SGAccordionPanel key="defaultPanel" header={<SGSpace direction="horizontal" disableautomargin>
            <SGBlock align="left">
               <SGTextIntl intlId={title} cypressName={`${cypressName}-title-category`} strong size="m"/>
            </SGBlock>
            <SGBlock align="right">
               <SGButtonIntl type="tertiary" cypressName={`${cypressName}-modify`}
                              onClick={goToEditor} trackingName={`clic-sur::modifier-${replaceSpecialCharsAndLowercase(intl.formatMessage({id: title}))}`}
                              icon={<SGIcon currentcolor component={<SGAvenirStrokedModification/>}/>} intlId="common.modify"/>
            </SGBlock>
         </SGSpace>}>
            <SGSpace direction="vertical" disableautomargin>
               {incomes &&
                  incomesToCategoryArray(incomes).map(
                     (categoryAndValues: IncomeCategoryAndValues) => (
                        <>
                           {categoryAndValues.incomes.map(
                              (income: Income) => (
                                 <NameAndValuationFrame onClick={goToEditor} icon="cash" amountSubTitle={amountSubTitle}
                                                        key={`${String(income.id)} ${income.type}`} frameColor={categoryAndValues.category.color}
                                                        cypressName={`${cypressName}-${income.type}`} amount={income.monthlyAmount} isNet isPerMonth
                                                        title={income.name}/>
                              ))}
                        </>
                     )
                  )}

               {expenses &&
                  expensesToCategoryArray(expenses).map(
                     (categoryAndValues: ExpenseCategoryAndValues) => (
                        <>
                           {categoryAndValues.expenses.map(
                              (expense: Expense) => (
                                 <NameAndValuationFrame onClick={goToEditor} amountSubTitle={amountSubTitle}
                                                        icon={expense.type === ProjectType.ASSET_EXPENSE ? "home" : "expense"}
                                                        key={categoryAndValues.category.color.hexCode + expense.type + expense.member}
                                                        frameColor={categoryAndValues.category.color}
                                                        cypressName={`${cypressName}-${expense.type}`} amount={expense.monthlyAmount}
                                                        isNet={false} isPerMonth
                                                        title={intl.formatMessage({id: `expenseTypes.${expense.type}`})
                                                           + (expense.type === ProjectType.ASSET_EXPENSE ? ` : ${expense.name}` : "")}/>
                              ))}
                        </>
                     )
                  )}

               {assets &&
                  assetsToCategoryArray(assets).map((categoryAndValues) => (
                     <FrameCategory key={categoryAndValues.category.intlKey + categoryAndValues.valuations} assetCategory={categoryAndValues.category}
                                    assets={categoryAndValues.valuations} amountSubTitle={amountSubTitle} goToEditor={goToEditor}
                                    cypressName={cypressName}/>
                  ))}

               {loans && (
                  <FrameCategory assetCategory={LOAN} loans={loans} amountSubTitle={amountSubTitle} goToEditor={goToEditor} cypressName={cypressName}/>
               )}

               {taxCredits &&
                  taxCredits.map((taxCredit: TaxCredit) => (
                     <NameAndValuationFrame onClick={goToEditor} amountSubTitle={amountSubTitle} cypressName={`${cypressName}-${taxCredit.name}`}
                                            amount={taxCredit?.amount || 0} isNet={false} isPerMonth={false} isPerYear title={taxCredit.name}
                                            frameColor={WHEAT} key={taxCredit.id}
                                            subTitle={intl.formatMessage({id: `tax.credit.type.${taxCredit.taxCreditType}`})}/>
                  ))}

               {rentTaxCredits && rentTaxCredits.map((asset: RealEstateAsset) => (
                  <NameAndValuationFrame onClick={goToEditor} amountSubTitle={amountSubTitle} cypressName={`${cypressName}-${asset.assetType}`}
                                         amount={asset.assetValuation.totalValuation ? asset.assetValuation.totalValuation : 0}
                                         isNet={false} isPerMonth={false} title={asset.assetName} key={asset.id} frameColor={SG_PINK}
                                         subTitle={intl.formatMessage({id: `tax-arrangement.${asset.taxSystemSubType}`})}/>
               ))}

               {childSupportExpenses && childSupportExpenses.map((expense: Expense) => (
                  <NameAndValuationFrame onClick={goToEditor} amountSubTitle={amountSubTitle} cypressName={`${cypressName}-${expense.type}`}
                                         frameColor={CHESTNUT} key={expense.id}
                                         amount={expense.monthlyAmount} isNet={false} isPerMonth
                                         title={intl.formatMessage({id: "expenseTypes.CHILD_SUPPORT_EXPENSE"})}/>
               ))}

               {realizedDonations && realizedDonations.map((realizedDonation: RealizedDonation) => (
                  <NameAndValuationFrame onClick={goToEditor} amountSubTitle={amountSubTitle} cypressName={`${cypressName}-${realizedDonation.projectName}`}
                                         amount={realizedDonation.amount || 0} key={realizedDonation.id}
                                         subTitle={new Date(realizedDonation.startDate).getFullYear().toString()}
                                         frameColor={realizedDonation.assetType === AssetType.CASH_ACCOUNT ? SG_BLUE : SG_PINK}
                                         isNet={false} isPerMonth={false} title={realizedDonation.projectName}
                  />
               ))}

               {!!emptyListText && !hasFrames && (
                  <SGTextIntl cypressName={`${cypressName}-is-empty`} intlId={emptyListText} size="s"/>
               )}

            </SGSpace>
         </SGAccordionPanel>
      </SGAccordion>
   );
};

export {NameAndValuationCollapsableFrames};
