import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { EffortState, EFFORTS_FETCHED, SavingPathState, SAVINGPATHS_FROM_EFFORT_FETCHED, SAVINGPATHS_FROM_OBJECTIVE_FETCHED, SAVINGPATH_FROM_EFFORT_FETCHED, SAVINGPATH_FROM_OBJECTIVE_FETCHED } from "./types";

const initialSavingPathState: SavingPathState = {} as SavingPathState;
const initialEffortState: EffortState = {} as EffortState;

export const SavingPathReducer: Reducer<SavingPathState, PayloadAction<any>> = (
   currentState = initialSavingPathState,
   action
): SavingPathState => {
   switch (action.type) {
      case SAVINGPATHS_FROM_EFFORT_FETCHED:
      case SAVINGPATHS_FROM_OBJECTIVE_FETCHED:
         return {
            ...currentState,
            results: action.payload,
         };
      case SAVINGPATH_FROM_EFFORT_FETCHED:
         return {
            ...currentState,
            objective: action.payload,
         };
      case SAVINGPATH_FROM_OBJECTIVE_FETCHED:
         return {
            ...currentState,
            monthlyEffort: action.payload,
         }
      default:
         return currentState;
   }
};

export const EffortReducer: Reducer<EffortState, any> = (
   currentState = initialEffortState,
   action
): EffortState => {
   switch (action.type) {
      case EFFORTS_FETCHED: {
         return {
            ...currentState,
            results: action.payload,
         };
      }
      default:
         return currentState;
   }
};
