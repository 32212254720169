import {FunctionComponent, useEffect, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {SGModal} from "sg-modal";
import {SGButtonGroup} from "sg-button";
import {SGMessage} from "sg-message";
import {SGIcon} from "sg-icon";
import {SGAvenirStyledPrendreContact} from "sg-icon-pack-base";
import {SGSelectOption} from "sg-select";
import {useAssets} from "../../hooks/useAssets";
import {AssetState, AssetWithValuation} from "../../../../store/assets/types";
import {State} from "../../../../store/store";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {convertNumberToStringWithSeparators} from "../../../utils/formatting/numberFormatter";
import {createAsset} from "../../../../store/assets/actions";
import {SGAlertIntl} from "../SGAlertIntl/SGAlertIntl";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {FormProvider, useForm} from "react-hook-form";
import './SGImportAssetModal.scss';
import { useErrorAsset } from "website/components/hooks/useErrorAsset";

interface SGImportAssetModalProps {
   hideModal: () => void;
   onCreateOtherAsset: () => void;
   showSGImportAssetModal?: boolean;
   cypressName: string;
}

const SGImportAssetModal: FunctionComponent<SGImportAssetModalProps> = (props: SGImportAssetModalProps) => {
   const {hideModal, onCreateOtherAsset, showSGImportAssetModal = false} = props;
   const dispatch = useDispatch();
   const intl = useIntl();
   const { assets } = useAssets();

   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   // Gestion de la dialog d'import d'asset SG
   const [displaySGAssetSelector, setDisplaySGAssetSelector] = useState(showSGImportAssetModal);
   const [SGSelectableAssets, setSGSelectableAssets] = useState<AssetWithValuation[]>([]);
   const [SGAssetSelected, setSGAssetSelected] = useState<AssetWithValuation>();
   const [nbAssets, setNbAssets] = useState<number>();
   const { errors, errorsParameters } = useErrorAsset();

   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);

   const onSelectSGAsset = (newAssetExternalId: string) => {
      setSGAssetSelected(assetState.assetsImported.filter((a) => a.externalId === newAssetExternalId).shift());
   };

   useEffect(() => {
      if (assetState.assetsImported) {
         const selectableAssets = assetState.assetsImported.filter(
            (a) => a.externalId !== null && !assets.some((existingAsset) => existingAsset.externalId === a.externalId)
         );
         setSGSelectableAssets(selectableAssets);
      }

      if (nbAssets !== assets.length && nbAssets) {
         hideModal();
      }
      setNbAssets(assets.length)
   }, [assetState.assetsImported, assets, nbAssets]);

   return (
      <SGModal visible onCancel={() => hideModal()}>
         <SGMessage className="SGImportAsset"
            icon={<SGIcon component={<SGAvenirStyledPrendreContact/>} size="xl"/>}
            title={<SGTitleIntl intlId="assets.import.sg.modal.title" cypressName="assets-import-modal-title"/>}>
            <p>
               <SGTextIntl intlId="assets.import.sg.modal.text"/>
            </p>
            {displaySGAssetSelector && (
               <FormProvider {...methods}>
                  {SGSelectableAssets.length > 0 && (
                     <SelectInput
                        name="SGAsset"
                        onValueChange={onSelectSGAsset}
                        cypressName="sg-import-select-asset"
                        placeholder={intl.formatMessage({id: "assets.import.sg.modal.choose.sg.account"})}
                        importedErrors={errors.length > 0 ? intl.formatMessage({id: `form.asset.error.${errors[0]}`},errorsParameters) : undefined}>
                        {SGSelectableAssets.map(
                           (asset: AssetWithValuation) => (
                              <SGSelectOption key={asset.id} data-cy={`import-sg-select-${asset.assetName}`} value={asset.externalId || ""}>
                                 {`${asset.assetName} - ${convertNumberToStringWithSeparators(asset?.assetValuation?.totalValuation || 0)}€`}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>
                  )}
                  {SGSelectableAssets.length === 0 && (
                     <SGAlertIntl type="warning" cypressName="notification-frame-no-sg-account" title="common.warning">
                        <SGTextIntl intlId="assets.import.sg.modal.no.sg.account"/>
                     </SGAlertIntl>
                  )}
                  <SGButtonGroup align="center">
                     <SGButtonIntl type="primary" size="md" disabled={SGAssetSelected === undefined} cypressName="asset-create-other" intlId="common.add"
                                   onClick={() => {
                                      if (SGAssetSelected !== undefined) {
                                         dispatch(createAsset({...SGAssetSelected, id: undefined}));
                                      }
                                   }}/>
                     <SGButtonIntl type="secondary" size="md" cypressName="asset-import-sg" onClick={() => hideModal()}
                                   intlId="common.cancel"/>
                  </SGButtonGroup>
               </FormProvider>
            )}
            {!displaySGAssetSelector && (
               <SGButtonGroup align="center">
                  <SGButtonIntl type="primary" size="md" cypressName="asset-create-other" intlId="assets.import.sg.modal.button.other" onClick={() => {
                     hideModal();
                     onCreateOtherAsset();
                  }}/>
                  <SGButtonIntl type="secondary" size="md" cypressName="asset-import-sg" intlId="assets.import.sg.modal.button" onClick={() => {
                     setDisplaySGAssetSelector(true);
                     setSGAssetSelected(undefined);
                  }}/>
               </SGButtonGroup>
            )}
         </SGMessage>
      </SGModal>
   );
};

export {SGImportAssetModal};
