import { FETCH_WORK_INCOMES, SAVE_WORK_INCOMES, UPDATE_WORK_INCOMES_STORE, WorkIncome } from "./types";

export const fetchWorkIncomes = () => ({
   type: FETCH_WORK_INCOMES,
});
export const saveWorkIncomes = (workIncomes: WorkIncome[]) => ({
   type: SAVE_WORK_INCOMES,
   payload: workIncomes,
});
export const updateWorkIncomesStore = (workIncomes: WorkIncome[]) => ({
   type: UPDATE_WORK_INCOMES_STORE,
   payload: workIncomes,
});
