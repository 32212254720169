import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getMembersAction } from "store/members/actions";
import { updateFamilyStore } from "store/members/membersSlice";
import {
  CustodyType,
  Family,
  FamilyStatus,
  MaritalStatus,
  MatrimonialRegime,
  Me,
  Member,
} from "store/members/types";
import {
  isCoupleMaritalStatus,
  isIsolatedParentStatus,
} from "store/members/utils";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters } from "store/simulationAssu/types";
import { nextStepAction } from "store/tunnel/actions";
import {
  PAGE_CATEGORY_3_FLASH,
  PAGE_NAME_MA_SITUATION_PERSONNELLE,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { formatDateSimulation } from "website/utils/date/DateUtils";
import { FamilySituation } from "../../FamilySituation/FamilySituation";

const SimulateurFlashFamilySituation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const family = useFamily();

  const history = useHistory();

  const [numberChild, setNumberChild] = useState(0);
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus>(
    MaritalStatus.SINGLE
  );
  const [dateOfBirth, setDateOfBirth] = useState<Date>();
  const { trackClick, trackPage } = useTracking();

  useEffect(() => {
    dispatch(getMembersAction());
    trackPage(
      PAGE_CATEGORY_3_FLASH,
      PAGE_NAME_MA_SITUATION_PERSONNELLE,
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "1"
    );
  }, []);

  const onSubmit = () => {
    const newMe: Me = {
      ...family.me,
      maritalStatus,
    };
    const familyToDispatch: Family = {
      children: [],
      me: {
        ...newMe,
        matrimonialRegime:
          MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS,
      },
      relatives: [],
    };
    let ordinal = 0;
    for (let i = 0; i < numberChild; i += 1) {
      ordinal += 1;
      const custodyType =
        isIsolatedParentStatus(maritalStatus) ||
        maritalStatus === MaritalStatus.WIDOWER
          ? CustodyType.FULL_CUSTODY_ME
          : CustodyType.FULL_CUSTODY;

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const child: Member = {
        name: `Mon enfant ${ordinal}`,
        status: FamilyStatus.CHILD,
        custodyType,
      } as Member;
      familyToDispatch.children.push(child);
    }
    if (isCoupleMaritalStatus(maritalStatus)) {
      const partner: any = {
        ...family.partner,
        matrimonialRegime:
          MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS,
      };
      familyToDispatch.partner = partner;
    }

    const retirementSimulationParametersToDispatch: Partial<RetirementSimulationParameters> =
      {
        nbEnfant: numberChild,
        dateNaissance: dateOfBirth
          ? formatDateSimulation(dateOfBirth)
          : "01-01-1970",
      };

    dispatch(
      updateRetirementSimulation(retirementSimulationParametersToDispatch)
    );

    dispatch(updateFamilyStore(familyToDispatch));
    trackClick("clic-sur-suivant");
    dispatch(nextStepAction());
  };

  return (
    <FamilySituation
      onSubmit={onSubmit}
      numberChild={numberChild}
      setNumberChild={setNumberChild}
      setDateOfBirth={setDateOfBirth}
      setMaritalStatus={setMaritalStatus}
      onClickReturn={() => {
        trackClick("clic-sur-annuler");
        history.goBack();
      }}
      texteRetour="tunnel.buttons.cancel"
    />
  );
};

export { SimulateurFlashFamilySituation };
