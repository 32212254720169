import { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGInputNumber, SGInputQuantity } from "sg-input";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import "./AssetEditModal.scss";

interface AssetEditModalProps {
    visible: boolean;
    setVisible: (showAssetEditModal: boolean) => void;
    assetValuation: number;
    initPercentage: number;
    onSubmitPercentage: (percentage: number) => void;
}
const AssetEditModal: FunctionComponent<AssetEditModalProps> = (props: AssetEditModalProps) => {
    const { visible, setVisible, assetValuation, initPercentage, onSubmitPercentage } = props;
    const intl = useIntl();
    const [newAssetValuation, setNewAssetValuation] = useState<number>(roundDown10(assetValuation * (initPercentage / 100)));
    const [percentage, setPercentage] = useState<number>(initPercentage);
    const methods = useForm();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const ONGLET_MONTANT = "montant";
    const ONGLET_POURCENTAGE = "pourcentage";
    const [ongletCourant, setOngletCourant] = useState<string>(ONGLET_MONTANT);

    const handleOnChangeOnglet = (onglet: string) => {
        setOngletCourant(onglet);
    };

    // Arrondit le montant lorsque l'on sort du champ de saisie
    useEffect(() => {
        if (newAssetValuation) {
            setNewAssetValuation(roundDown10(newAssetValuation));
        }
    }, []);

    // MAJ du montant selon pourcentage
    useEffect(() => {
        if (ONGLET_POURCENTAGE === ongletCourant) {
            setNewAssetValuation(roundDown10(assetValuation * (percentage / 100)));
        }
    }, [percentage]);

    const onClose = () => {
        setVisible(false);
    };

    const augmenterPourcentage = () => {
        if (percentage < 100) {
            setPercentage((prevState) => Math.min(100, Math.round(+prevState) + 10));
        }
    };
    const reduirePourcentage = () => {
        if (percentage > 0) {
            setPercentage((prevState) => Math.max(0, Math.round(+prevState) - 10));
        }
    };
    const changePourcentage = (val: any) => {
        setPercentage(val);
    };

    const handleChangeMontant = (event: number) => {
        if (newAssetValuation !== undefined) {
            if (event <= assetValuation) {
                // Pas d'arrondi tant qu'on est pas sorti du champ de saisie
                setNewAssetValuation(event);
                setPercentage(Math.round((event / assetValuation) * 100));
            }
            // On remet au max si l'utilisateur dépasse le montant de l'asset
            else {
                setNewAssetValuation(assetValuation);
                setPercentage(100);
            }
        }
    };

    return (
        <SGModal visible={visible} centered onCancel={onClose}>
            <SGGridRow justify="start">
                <SGGridCol align="start">
                    <SGBox padding={{ top: "sm", bottom: "sm" }}>
                        <SGTitle level={2} visuallevel={3}>
                            {intl.formatMessage({ id: `asset.edit.modal.title` }, { linebreak: <br /> })}
                        </SGTitle>
                    </SGBox>
                </SGGridCol>
            </SGGridRow>
            <SGBox margin={{ top: "sm" }}>
                <SGGridRow justify="center">
                    <SGGridCol flex="initial">
                        <SGTabs
                            size="sm"
                            activeKey={ongletCourant}
                            onChange={(onglet: string) => handleOnChangeOnglet(onglet)}
                        >
                            <SGTabPane
                                ariaId={ONGLET_MONTANT}
                                tab={intl.formatMessage({ id: `asset.edit.modal.tab.${ONGLET_MONTANT}` })}
                                key={ONGLET_MONTANT}
                            >
                                <SGBox padding={{ top: isPhone ? "md" : "sm" }}>
                                    <SGGridRow justify="center" gutter={isPhone ? ["sm", "none"] : ["sm", "none"]}>
                                        <SGGridCol span={6} md={8} offset={isPhone ? 2 : 0}>
                                            <FormProvider {...methods}>
                                                <SGInputNumber
                                                    min={0}
                                                    max={assetValuation}
                                                    precision={0}
                                                    placeholder="--"
                                                    value={newAssetValuation}
                                                    onChange={handleChangeMontant}
                                                />
                                            </FormProvider>
                                        </SGGridCol>
                                        <SGGridCol span={4} md={4} align="end">
                                            <SGText weight="700" color="default" size="m">
                                                {intl.formatMessage(
                                                    {
                                                        id: "asset.edit.modal.tab.montant.soit.pourcentage",
                                                    },
                                                    {
                                                        percentage,
                                                    }
                                                )}
                                            </SGText>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGBox>
                            </SGTabPane>
                            <SGTabPane
                                ariaId={ONGLET_POURCENTAGE}
                                tab={intl.formatMessage({ id: `asset.edit.modal.tab.${ONGLET_POURCENTAGE}` })}
                                key={ONGLET_POURCENTAGE}
                            >
                                <SGBox padding={{ top: isPhone ? "md" : "sm" }}>
                                    <SGGridRow align="bottom" justify="center" gutter={["sm", "none"]}>
                                        <SGGridCol span={7}>
                                            {
                                                // FIXME Supprimer le SGBlock quand le composant sera corrigé
                                            }
                                            <SGBlock className="asset-edit-modal__inputQuantity">
                                                <SGInputQuantity
                                                    increment={10}
                                                    onChange={changePourcentage}
                                                    placeholder=""
                                                    unitvalue="%"
                                                    value={percentage}
                                                    max={100}
                                                    min={0}
                                                    precision={0}
                                                    onIncrement={augmenterPourcentage}
                                                    onDecrement={reduirePourcentage}
                                                />
                                            </SGBlock>
                                        </SGGridCol>
                                        <SGGridCol flex="initial">
                                            <SGText weight="700" color="default" size="m">
                                                {intl.formatMessage(
                                                    {
                                                        id: "asset.edit.modal.tab.pourcentage.soit.montant",
                                                    },
                                                    {
                                                        assetValuation: newAssetValuation?.toLocaleString("fr-FR"),
                                                    }
                                                )}
                                            </SGText>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGBox>
                            </SGTabPane>
                        </SGTabs>
                    </SGGridCol>
                </SGGridRow>
            </SGBox>
            <SGBox margin={{ top: isPhone ? "md" : "xs" }}>
                <SGGridRow justify="center">
                    <SGText color="lighter" weight="400" size="s">
                        {intl.formatMessage(
                            {
                                id: "asset.edit.modal.montantMax",
                            },
                            {
                                montantMax: roundDown10(assetValuation)?.toLocaleString("fr-FR"),
                                label: (word: string) => <SGText weight="700">{word}</SGText>,
                            }
                        )}
                    </SGText>
                </SGGridRow>
            </SGBox>
            <SGButtonGroup align={isPhone ? "center" : "opposite"}>
                <SGButton
                    onClick={() => {
                        setVisible(false);
                        onSubmitPercentage(percentage);
                    }}
                >
                    {intl.formatMessage({ id: `asset.edit.modal.valider` })}
                </SGButton>
                <SGButton
                    type="secondary"
                    onClick={() => {
                        setVisible(false);
                    }}
                >
                    {intl.formatMessage({ id: `asset.edit.modal.annuler` })}
                </SGButton>
            </SGButtonGroup>
        </SGModal>
    );
};

export { AssetEditModal };
