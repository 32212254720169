import { ChangeEvent, FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMediaQuery } from "sg-media-query";
import { SGRadio, SGRadioGroup } from "sg-radio";
import { SGText } from "sg-typo";

interface RadioGroupTileProps {
  name: string;
  label: string;
  value?: string;
  setValue: (value: string) => void;
  radioGroupList: Array<string>;
}

export const RadioGroupTile: FunctionComponent<RadioGroupTileProps> = (
  props: RadioGroupTileProps
) => {
  const { name, label, value, setValue, radioGroupList } = props;

  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { control, formState: { errors } } = useFormContext();

  const messageErreur = intl.formatMessage({ id: "tunnel.error.required" });

  const getRadio = (radioValue: string) => {
    const texte = (
      <FormattedMessage
        id={`radioGroupText.${radioValue}`}
        values={{
          b: (word: string) => <SGText strong>{word}</SGText>,
        }}
      />
    );

    return (
      <SGRadio key={radioValue} aria-label={texte} value={radioValue}>
        <SGText size="m">{texte}</SGText>
      </SGRadio>
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: true,
      }}
      defaultValue={value}
      render={({ field }) => (
        <SGRadioGroup
          value={value}
          status={errors && errors[name] ? "error" : "success"}
          buttonstyle={isPhone ? "tile" : "default"}
          layout={isPhone ? "column" : "row"}
          validate={errors && errors[name] && messageErreur}
          legend={
            <SGText size="l">
              <FormattedMessage id={label} />
            </SGText>
          }
          name={name}
          disableautomargin
          onChange={(event: ChangeEvent<HTMLButtonElement>) => {
            field.onChange(event.target.value);
            setValue(event.target.value);
          }}
        >
          {radioGroupList.map((radioValue: string) => getRadio(radioValue))}
        </SGRadioGroup>
      )}
    />
  );
};
