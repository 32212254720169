import { FETCH_RETIREMENT_SIMULATION, UPDATE_RETIREMENT_SIMULATION, RetirementSimulationParameters, NEXT_STEP_SIMULATION, PREVIOUS_STEP_SIMULATION, RESET_PARCOURS_SIMULATION } from "./types";

export const callRetirementSimulation = (data: RetirementSimulationParameters) => ({
   type: FETCH_RETIREMENT_SIMULATION,
   payload: data,
});

export const updateRetirementSimulation = (data: Partial<RetirementSimulationParameters>) => ({
   type: UPDATE_RETIREMENT_SIMULATION,
   payload: data,
});

export const resetParcours = () => ({
   type: RESET_PARCOURS_SIMULATION,
});

export const nextStep = () => ({
   type: NEXT_STEP_SIMULATION,
});

export const previousStep = () => ({
   type: PREVIOUS_STEP_SIMULATION,
});
