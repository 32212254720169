import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import {
  UPDATE_WORK_INCOMES_STORE,
  WorkIncome, WorkIncomesState, WORK_INCOMES_FETCHED, WORK_INCOMES_SAVED
} from "./types";

export const WorkIncomesReducer: Reducer<WorkIncomesState, PayloadAction<WorkIncome[]>> = (
  currentState: WorkIncomesState = {},
  action
): WorkIncomesState => {
  switch (action.type) {
    case WORK_INCOMES_FETCHED:
      if (action.payload) {
        return { workIncomes: action.payload };
      }

      return currentState;
    case WORK_INCOMES_SAVED:
      return { workIncomes: action.payload };
    case UPDATE_WORK_INCOMES_STORE:
      return { workIncomes: action.payload };
    default:
      return currentState;
  }
};
