import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { DashboardState, hasFetchedDashboard } from "store/dashboard/types";
import { State } from "store/store";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useAssets } from "website/components/hooks/useAssets";
import { DisclaimerAgircArrco } from "../DisclaimerAgircArrco/DisclaimerAgircArrco";
import { DisclaimerPatrimoine } from "../DisclaimerPatrimoine/DisclaimerPatrimoine";

const DisclaimerDashboard: FunctionComponent = (
) => {
  const { showDisclaimerPatrimoine } = useAssets();
  const { ageSansMalusPresent } = useSelectedRetirementAge();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const hasFetched =
    showDisclaimerPatrimoine !== undefined &&
    hasFetchedDashboard(dashboardState);

  if (hasFetched) {
    // Permet d'éviter d'une popin afficher alors on n'a pas fetch
    if (
      showDisclaimerPatrimoine &&
      dashboardState.dashboard.firstPatrimonyPathDate !== undefined
    ) {
      return (
        <DisclaimerPatrimoine idTracking="clic-sur-disclaimer-actualiser-mes-actifs" />
      );
    }
    if (ageSansMalusPresent) {
      return <DisclaimerAgircArrco />;
    }
  }

  return <></>;
};

export { DisclaimerDashboard };
