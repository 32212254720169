// Type guard with "type predicate"
import {AxiosError} from "axios";
import {ElyxirError} from "./types";

const isAxiosError = (candidate: unknown): candidate is AxiosError => !!candidate && typeof candidate === 'object' && 'isAxiosError' in candidate;

const isStringAxiosError = (candidate: AxiosError): candidate is AxiosError<string> => !!candidate.response?.data && typeof candidate.response.data === 'string';
const isElyxirAxiosError = (candidate: AxiosError): candidate is AxiosError<ElyxirError> => !!candidate.response?.data && typeof candidate.response.data === 'object';
const isObjectAxiosError = (candidate: AxiosError): candidate is AxiosError<{ [key: string]: string }> => !!candidate.response?.data && typeof candidate.response.data === 'object';

export {isAxiosError, isStringAxiosError, isElyxirAxiosError, isObjectAxiosError};
