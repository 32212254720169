import { FC, useMemo } from "react";
import { SGCarousel } from "sg-carousel";
import { SGLink } from "sg-link";
import { SGBox } from "sg-space";
import {
  AccesContenu,
  Media,
  TypeMedia,
  articles,
  getIdTrackingType,
} from "website/components/hooks/articles";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useVideos } from "website/components/hooks/useVideos";
import { CarteArticleVertical } from "website/components/organisms/Articles/CarteArticleVertical";
import { SectionTemplate } from "website/components/organisms/Dashboard/Personnalisation/SectionTemplate/SectionTemplate";
import { ARTICLE, ARTICLES } from "website/utils/privateRoutes";

interface AccesContenusProps {
  idMedias: AccesContenu[];
  nbSlides?: number;
}
const AccesContenus: FC<AccesContenusProps> = (props) => {
  const { idMedias, nbSlides = 2 } = props;

  const videos = useVideos();

  const { trackClick } = useTracking();

  const mediasCarrousel: Media[] = useMemo(
    () =>
      idMedias
        .map((media) =>
          media.type === TypeMedia.VIDEO
            ? videos.find((video) => video.id === media.id)
            : articles.find((article) => article.id === media.id)
        )
        // Si non trouvé, on ignore
        .filter((article): article is Media => !!article),
    [idMedias]
  );

  const handleOnClickLireArticle = (
    path: string,
    trackKey: string,
    typeMedia?: TypeMedia
  ) => {
    const idTrackingType: string = typeMedia
      ? `-${getIdTrackingType(typeMedia)}-`
      : "";
    trackClick(`${trackKey}${idTrackingType}${path}`);
  };

  return (
    <SectionTemplate sectionType={SectionDashBoardType.ARTICLES}>
      <SGCarousel
        key="carousel"
        slidesToScroll={1}
        settings={{
          infinite: true,
          slidesToShow: nbSlides,
        }}
      >
        {mediasCarrousel.map((media) => (
          <CarteArticleVertical
            key={media.id}
            path={`${ARTICLE}${media.path}`}
            media={media}
            handleOnClickLireArticle={handleOnClickLireArticle}
            trackKey="clic-sur-carrousel"
            afficherIconeTag={false}
          />
        ))}
      </SGCarousel>
      <SGBox key="box-2" textalign="center">
        <SGLink
          href={`#${ARTICLES}`}
          onClick={() => trackClick(`clic-sur-voir-tous-les-articles`)}
          type="secondary"
        >
          Voir tous les articles
        </SGLink>
      </SGBox>
    </SectionTemplate>
  );
};

export { AccesContenus };
