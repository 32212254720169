import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";

export interface AccordeonQuestionsProps {
  questionsKey: string[];
  questionDefautKey?: string;
  titre: string;
  description?: string;
  transformations?: Record<string, string | React.ReactNode>;
}

const AccordeonQuestions: FunctionComponent<AccordeonQuestionsProps> = (
  props: AccordeonQuestionsProps
) => {
  const {
    questionsKey,
    questionDefautKey,
    titre,
    description,
    transformations,
  } = props;

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  return (
    <SGSpace disableautomargin direction="vertical" size="md">
      <SGSpace key="space" direction="vertical" size="xs">
        <SGTitle
          key="titre"
          level={2}
          visuallevel={isPhone ? 3 : 4}
        >
          <FormattedMessage id={titre} />
        </SGTitle>
        {description && (
          <SGText key="text" whitespace="pre-line">
            <FormattedMessage id={description} />
          </SGText>
        )}
      </SGSpace>
      <SGAccordion
        key={`accordion-${questionDefautKey ? "ouvert" : "fermer"}`}
        disableAutomargin
        divider
        defaultActiveKey={questionDefautKey}
      >
        {questionsKey.map((questionKey: string) => (
          <SGAccordionPanel
            key={questionKey}
            header={
              <SGText>
                <FormattedMessage
                  id={`accordeonQuestions.question.${questionKey}`}
                />
              </SGText>
            }
          >
            <SGText whitespace="pre-line">
              <FormattedMessage
                id={`accordeonQuestions.reponse.${questionKey}`}
                values={{
                  ...transformations,
                  b: (word: string) => <SGText weight="bold">{word}</SGText>,
                }}
              />
            </SGText>
          </SGAccordionPanel>
        ))}
      </SGAccordion>
    </SGSpace>
  );
};

export { AccordeonQuestions };
