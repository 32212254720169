import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien, SGAvenirStrokedArticle } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Article } from "website/components/hooks/articles";
import { getDateString } from "./OngletThematique";
import { ARTICLE } from "website/utils/privateRoutes";
import { SGLink } from "sg-link";

interface CarteArticleHorizontalProps {
    article: Article;
    handleOnClickLireArticle: (path: string, trackKey : string) => void;
    trackKey: string
    afficherDate?: boolean;
}
export const CarteArticleHorizontal: FunctionComponent<CarteArticleHorizontalProps> = (props: CarteArticleHorizontalProps) => {
    const { article, handleOnClickLireArticle, afficherDate = false, trackKey } = props;
    const intl = useIntl();

    return (
        <SGGridRow gutter={[0, 8]}>
            <SGGridCol span={5}>
                <img
                    alt={`${intl.formatMessage({ id: "articles.couverture.alt" })}${intl.formatMessage({ id: `home.article.title.${article.id}` })}`}
                    src={article.image}
                />
            </SGGridCol>
            <SGGridCol span={7}>
                <SGBox margin={{ left: "sm" }}>
                <SGGridRow>
                    <SGGridCol span={12}>
                        <SGBox margin={{ bottom: "xs" }}>
                            <SGTitle level={3} visuallevel={4}>
                                {intl.formatMessage({ id: `home.article.title.${article.id}` })}
                            </SGTitle>
                        </SGBox>
                    </SGGridCol>
                    {afficherDate && (
                        <SGGridCol span={7} textalign="left">
                            <SGText weight="600">{getDateString(article.date)}</SGText>
                        </SGGridCol>
                    )}

                    <SGGridCol span={afficherDate ? 5 : 12}>
                        <SGBox textalign="right">
                            <>
                                <SGBox margin={{ right: "xxs" }} inline>
                                    <SGIcon align="text-bottom" component={<SGAvenirStrokedArticle />} currentcolor size="s" />
                                </SGBox>
                                <SGText weight="600" size="s">
                                    {article.time} MIN
                                </SGText>
                            </>
                        </SGBox>
                    </SGGridCol>
                </SGGridRow>
                </SGBox>
            </SGGridCol>
            <SGGridCol span={12}>
                <SGText ellipsis>{intl.formatMessage({ id: `home.article.description.${article.id}` })}</SGText>
            </SGGridCol>
            <SGGridCol span={12}>
                <SGBox textalign="right">
                    <SGLink
                        href={`#${ARTICLE}${article.path}`}
                        key="lien"
                        type="primarylink"
                        icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                        iconposition="right"
                        onClick={() => handleOnClickLireArticle(article.path, trackKey)}
                        ariaLabel={intl.formatMessage({ id: `articles.bouton.lire.${article.type}.ariaLabel` })}
                    >
                        {intl.formatMessage({ id: `articles.bouton.lire.${article.type}` })}
                    </SGLink>
                </SGBox>
            </SGGridCol>
            <SGGridCol span={12}>
                <SGDivider disableautomargin />
            </SGGridCol>
        </SGGridRow>
    );
};
