import React, {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import {Redirect, useLocation} from "react-router-dom";
import {LoginState} from "../../../../store/login/types";
import {State} from "../../../../store/store";
import {AccountState, isLoggedIn} from "../../../../store/account/types";
import {login, loginAs} from "../../../../store/login/actions";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {redirectToRelativePath} from "../../../utils/routes/routing";
import {PASSWORD_RESET_INIT, SIGNUP} from "../../../utils/publicRoutes";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAlertIntl} from "../SGAlertIntl/SGAlertIntl";
import {SGCheckBox} from "sg-checkbox";
import {SGButtonGroup} from "sg-button";
import {SGBlock} from "sg-typo";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";

interface LoginFormData {
   username: string;
   password: string;
   loggedAs: string;
}

const LoginForm: FunctionComponent = () => {
   const dispatch = useDispatch();
   const location = useLocation();

   const loginState = useSelector<State, LoginState>((state) => state.login);

   const accountState = useSelector<State, AccountState>(
      (state) => state.account
   );

   const [navigateToPrivatePage, setNavigateToPrivatePage] =
      useState<boolean>(false);
   const [showLoggedAs, setShowLoggedAs] = useState<boolean>(false);
   const [demoMode, setDemoMode] = useState(false);

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });
   const {handleSubmit} = methods;

   useEffect(() => {
      setShowLoggedAs(
         new URLSearchParams(location.search).get("admin") === "true"
      );
   }, []);

   useEffect(() => {
      if (showLoggedAs) {
         methods.setValue(
            "username",
            new URLSearchParams(location.search).get("me")
         );
         methods.setValue(
            "loggedAs",
            new URLSearchParams(location.search).get("email")
         );
      }
   }, [showLoggedAs]);

   useEffect(() => {
      if (isLoggedIn(accountState)) {
         setNavigateToPrivatePage(true);
      }
   }, [loginState, accountState.account.id]);

   if (navigateToPrivatePage) {
    return window.env.REACT_APP_INSTANCE === 'assu' ?
    // @ts-ignore
    <Redirect to="/dashboard"/> : <Redirect to="/advices"/>
   }

   const onSubmit = (data: FieldValues) => {
      if (data.loggedAs === undefined) {
         dispatch(login({username: data.username, password: data.password}));
      } else {
         dispatch(
            loginAs({
               username: data.username,
               password: data.password,
               logAsUsername: data.loggedAs,
               demoMode,
            })
         );
      }
   };

   return (
      <>
         {loginState.isPasswordInvalid && (
            <SGAlertIntl type="warning" cypressName="login-password-invalid" title="common.warning">
               <SGTextIntl intlId="login.unauthorized"/>
            </SGAlertIntl>
         )}
         <FormProvider {...methods}>
            <form>
               <FormInputs colLength={10}>
                  <StringInput defaultValue="" name="username" minLength={1} maxLength={50} pattern={/^.+@.+$/} cypressName="login-username"
                               placeholder="login.form.your.email.placeholder" minLengthMessage="login.form.error.username.size"
                               maxLengthMessage="login.form.error.username.size" patternMessage="login.form.error.username.bad.email"
                               label="login.form.your.email"/>

                  <StringInput label="login.form.your.password" minLength={6} maxLength={100} maxLengthMessage="login.form.error.password.size"
                               minLengthMessage="login.form.error.password.size" name="password" isPassword
                               cypressName="login-password" placeholder="login.form.your.password.placeholder" defaultValue=""/>

                  {showLoggedAs && (
                     <SGBlock>
                        <StringInput label="login.form.login-as" required name="loggedAs" cypressName="login-as" pattern={/^.+@.+$/}
                                    patternMessage="login.form.error.username.bad.email" placeholder="login.form.login-as.placeholder"
                                    defaultValue=""/>

                        <SGCheckBox name="demoMode" data-cy="login.form-checkbox-demo-mode"
                           onChange={(event: ChangeEvent<HTMLInputElement>) => setDemoMode(event.target.checked)}>
                           <SGTextIntl intlId="login.form.demo-mode"/>
                        </SGCheckBox>
                     </SGBlock>
                  )}
               </FormInputs>
               <SGButtonGroup layout="column" align="center">
                  <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="login-submit" onClick={handleSubmit(onSubmit)}
                                intlId="login.connexion"/>
                  <SGButtonIntl type="link" cypressName="login-forgotten-password" onClick={() => redirectToRelativePath(PASSWORD_RESET_INIT)}
                                intlId="login.forgotten.password"/>
                  <SGBlock>
                     <SGTextIntl intlId="login.no.account"/>
                     <SGButtonIntl type="link" cypressName="signUp" onClick={() => redirectToRelativePath(SIGNUP)} intlId="login.try.us"/>
                  </SGBlock>
               </SGButtonGroup>
            </form>
         </FormProvider>
      </>
   );
};

export {LoginForm};
