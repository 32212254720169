import {
  CONTINUE_PARCOURS,
  GO_TO_STEP_4_PARCOURS_RIS,
  NEXT_STEP,
  PREVIOUS_STEP,
  RESET_PARCOURS,
  STOP_PARCOURS,
} from "./types";

export const resetParcours = () => ({
  type: RESET_PARCOURS,
});

export const nextStep = () => ({
  type: NEXT_STEP,
});

export const previousStep = () => ({
  type: PREVIOUS_STEP,
});

export const stopParcours = () => ({
  type: STOP_PARCOURS,
});

export const continueParcours = () => ({
  type: CONTINUE_PARCOURS,
});

export const goToStep4 = () => ({
  type: GO_TO_STEP_4_PARCOURS_RIS,
});
