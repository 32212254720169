import React, { FunctionComponent } from "react";
import { SGBox } from "sg-space";
import { SGStep, SGSteps } from "sg-steps";
import { SGBlock } from "sg-typo";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";

interface StepsAssuProps {
    currentStep: number;
    steps: string[];
}

export const StepsAssu: FunctionComponent<StepsAssuProps> = (props: React.PropsWithChildren<StepsAssuProps>) => {
    const { currentStep, steps } = props;

    useScrollToTopWithCurrentStep(currentStep);

    const getStatus = (index: number): string => {
        const indexStep: number = currentStep;
        if (indexStep === index) {
            return "process";
        }
        if (indexStep < index) {
            return "wait";
        }

        return "finish";
    };

    return (
        <SGBox margin={{ bottom: "md" }}>
            <SGBlock>
                <SGSteps labelPlacement="vertical" current={currentStep}>
                    {steps.map((categorie, index) => (
                        <SGStep
                            title={categorie}
                            status={getStatus(index)}
                            key={categorie}
                        />
                    ))}
                </SGSteps>
            </SGBlock>
        </SGBox>
    );
}
