import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PromotionalOffer, PromotionalOfferState } from "./types";

const initialState: PromotionalOfferState = {
   promotionalOffers: [],
   hasFetched: false,
};

export const initialOffer: PromotionalOffer = {
   userId: -1,
   typeOffer: "offre",
   viewCountOffer: 0
};

export const promotionalOfferSlice = createSlice({
   name: "promotionalOffers",
   initialState,
   reducers: {
      promotionalOffersFetched: (currentState, action: PayloadAction<PromotionalOffer[]>) => ({
         ...currentState,
         hasFetched: true,
         promotionalOffers: action.payload,
      }),
      promotionalOfferSaved: (currentState, action: PayloadAction<PromotionalOffer>) => ({
         ...currentState,
         promotionalOffers: [
            ...currentState.promotionalOffers.filter((offer: PromotionalOffer) => offer.typeOffer !== action.payload.typeOffer),
            action.payload
         ],
      }),
   },
});

export const { promotionalOffersFetched, promotionalOfferSaved } = promotionalOfferSlice.actions;

export default promotionalOfferSlice.reducer;
