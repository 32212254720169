import React, {FunctionComponent, PropsWithChildren,} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {SGTextIntl} from "../SGTextIntl/SGTextIntl";
import {SGSelect} from "sg-select";
import {SGPopover} from "sg-popover";
import {SGButton} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirStatusInfo} from "sg-icon-pack-base";
import {useIntl} from "react-intl";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

interface SelectInputProps {
   label?: string;
   tooltipLabel?: string;
   placeholder?: string;
   defaultValue?: string | number;
   name: string;
   required?: boolean;
   disabled?: boolean;
   onValueChange?: (newValue: string) => void;
   cypressName: string;
   importedErrors?: string;
}

const SelectInput: FunctionComponent<PropsWithChildren<SelectInputProps>> = ({
                                                                                label,
                                                                                tooltipLabel,
                                                                                placeholder,
                                                                                defaultValue,
                                                                                name,
                                                                                required,
                                                                                disabled,
                                                                                onValueChange,
                                                                                cypressName,
                                                                                children,
                                                                                importedErrors
                                                                             }) => {
   const { formState: { errors }, control } = useFormContext();
   const intl = useIntl();

   const validationRules: RegisterOptions = {};
   if (!disabled) {
      validationRules.required = required === undefined ? true : required;
   }

   const computeLabelNode = (label: string): React.ReactNode => {
      if (tooltipLabel) {
         return <>
            <SGTextIntl intlId={label}/>
            <SGPopover trigger="hover" content={<SGTextIntl intlId={tooltipLabel}/>} placement="topLeft" onOpenChange={() => true}>
               <SGButton type="icon" icon={<SGIcon component={<SGAvenirStatusInfo/>}/>} {...{tabIndex: -1}} />
            </SGPopover>
         </>
      }

      return <SGTextIntl intlId={label}/>;
   }

   return (
      <Controller control={control} name={name} rules={validationRules} defaultValue={defaultValue || null} render={({ field }) => (
         <SGSelect name={name} disabled={disabled} data-cy={cypressName}
                   value={(field.value === null || field.value === undefined) ? [] : field.value}
                   status={(errors && errors[name]) || importedErrors ? "error" : "default"}
                   label={label && computeLabelNode(label)}
                   validate={importedErrors || intl.formatMessage({id: "common.required"})}
                   placeholder={placeholder || intl.formatMessage({id: "common.form.choose.one"})}
                   onSelect={(value: string) => {
                      field.onChange(value)
                      if (onValueChange) {
                         onValueChange(value);
                      }
                   }}>
            {children}
         </SGSelect>
      )}
      />

   );
};

export {SelectInput};
