import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Simulation, SimulationState} from "./types";
import {storeDeepEquals} from "../utils";

const initialState: SimulationState = {
   simulation: {
      birthday: 0,
      from: 0,
      to: 0,
      incomeExpenses: {},
      alerts: {OPTIMISTIC: {}, CONSERVATIVE: {}},
      graphs: {OPTIMISTIC: {}, CONSERVATIVE: {}},
      netGraphs: {OPTIMISTIC: {}, CONSERVATIVE: {}}
   },
   hasFetched: false
};

export const simulationSlice = createSlice({
   name: "simulation",
   initialState,
   reducers: {
      simulationFetched: (currentState, action: PayloadAction<Simulation>) => ({
         simulation: storeDeepEquals(currentState.simulation, action.payload) ? currentState.simulation : action.payload,
         hasFetched: true
      })
   }
});

export const {simulationFetched} = simulationSlice.actions;

export default simulationSlice.reducer;
