import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import {
  SGAvenirStrokedDocumentContrat,
  SGAvenirStrokedDon,
  SGAvenirStrokedOperateurEnLigne,
  SGAvenirStrokedRevenusEpargne,
  SGAvenirStrokedStetoscope,
} from "sg-icon-pack-base";
import { SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { TuileServiceAccompagnement } from "website/components/molecules/TuileServiceAccompagnement/TuileServiceAccompagnement";
import {
  BESOIN_EPARGNE,
  MES_SERVICES,
  MON_PLAN,
} from "website/utils/privateRoutes";
import { TuileDiagnosticEpargne } from "../TuileDiagnosticEpargne/TuileDiagnosticEpargne";

export const enum Tab {
  sapiendo = "sapiendo",
  services = "services",
}

export const DecouverteServicesAccompagnement: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <SGSpace direction="vertical" size="xs">
      <SGTitle key="title" level={2} visuallevel={4}>
        {intl.formatMessage({ id: "home.decouvrirServicesAccompagnement" })}
      </SGTitle>
      <TuileServiceAccompagnement
        key="tuile-1"
        titre={intl.formatMessage({
          id: "home.serviceAccompagnement.monPlan.titre",
        })}
        texte={intl.formatMessage({
          id: "home.serviceAccompagnement.monPlan.texte",
        })}
        lien={`#${MON_PLAN}`}
        Icon={SGAvenirStrokedDocumentContrat}
        trackKey="clic-sur-mon-plan"
      />
      {/* Tuile Le meilleur des services publics */}
      <TuileServiceAccompagnement
        key="tuile-2"
        titre={intl.formatMessage({
          id: "home.serviceAccompagnement.servicesPublics.titre",
        })}
        texte={intl.formatMessage({
          id: "home.serviceAccompagnement.servicesPublics.texte",
        })}
        lien={`#${MES_SERVICES}?tab=${Tab.services}`}
        Icon={SGAvenirStrokedDon}
        trackKey="clic-sur-le-meilleur-service-public"
      />
      {/* Tuile Sapiendo, l'expert retraite */}
      <TuileServiceAccompagnement
        key="tuile-3"
        titre={intl.formatMessage({
          id: "home.serviceAccompagnement.sapiendo.titre",
        })}
        texte={intl.formatMessage({
          id: "home.serviceAccompagnement.sapiendo.texte",
        })}
        lien={`#${MES_SERVICES}?tab=${Tab.sapiendo}`}
        Icon={SGAvenirStrokedOperateurEnLigne}
        trackKey="clic-sur-sapiendo"
      />
      {/* Tuile Epargner pour ma retraite */}
      <TuileServiceAccompagnement
        key="tuile-4"
        titre={intl.formatMessage({
          id: "home.serviceAccompagnement.epargnerRetraite.titre",
        })}
        texte={intl.formatMessage({
          id: "home.serviceAccompagnement.epargnerRetraite.texte",
        })}
        lien={`#${BESOIN_EPARGNE}`}
        Icon={SGAvenirStrokedRevenusEpargne}
        trackKey="clic-sur-epargner-pour-ma-retraite"
      />
      <TuileDiagnosticEpargne
        key="diag-epargne"
        icone={<SGAvenirStrokedStetoscope />}
      />
    </SGSpace>
  );
};
