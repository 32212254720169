import {
  Dashboard,
  PathType,
  PathDate,
  DashboardAge,
  DashboardAgeType,
  MONTHS,
} from "./types";

/**
 * Récupère le parcours le plus récent et sa date.
 * @param dashboard Dashboard en mémoire
 */
export const getLastPathDate = (dashboard: Partial<Dashboard>): PathDate => {
  const pathsDates: PathDate[] = [
    { path: PathType.ONBOARDING, date: dashboard.onBoardingDate },
    { path: PathType.RIS, date: dashboard.firstRISPathDate },
    { path: PathType.PATRIMONY, date: dashboard.firstPatrimonyPathDate },
    {
      path: PathType.INCOMEGOAL,
      date: dashboard.firstMonthlyIncomeGoalInputDate,
    },
    { path: PathType.SAVING, date: dashboard.firstSavingPathDate },
    { path: PathType.PLAN, date: dashboard.firstMyPlanDate },
  ]
    // On retire les dates undefined avec un type guard
    .filter((pathDate): pathDate is { path: PathType; date: string } =>
      Boolean(pathDate.date)
    )
    .map((obj) => ({ path: obj.path, date: new Date(obj.date) }))
    // Tri décroissant des dates (qui sont ici en string, et non en Date)
    .sort((pathA: PathDate, pathB: PathDate) =>
      pathB.date < pathA.date ? -1 : 1
    );

  return pathsDates.length > 0
    ? pathsDates[0]
    : { path: PathType.ONBOARDING, date: new Date() };
};

/**
 * Détermine si l'âge est le type 3 des codes du RIS Sapiendo
 * Si RIS fait, age_maximum correspond au 3
 * @param age DashboardAge
 * @param dateParcoursRis Date du parcours RIS si définie. Faire un simulateur flash la réinitialise
 * @returns La présence de l'âge interdit
 */
export const estAgeSansMalusAgircArrco = (
  age: DashboardAge,
  dateParcoursRis?: string
): boolean =>
  dateParcoursRis !== undefined && age.type === DashboardAgeType.age_maximum;

export const getAge = (age: DashboardAge): string =>
  `${age.retirementAgeYear} ans ${
    age.retirementAgeMonth !== 0 ? `${age.retirementAgeMonth} mois ` : ""
  }`;

export const getDateAge = (age: DashboardAge): string =>
  `${MONTHS[age.retirementMonth - 1].toLocaleLowerCase()} ${
    age.retirementYear
  }`;
