import { ReactComponent as Calendar } from "assets/images/calendar-epargner.svg";
import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedTelechargement } from "sg-icon-pack-base";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { AssetState } from "store/assets/types";
import { hasFetchedDashboard } from "store/dashboard/types";
import { State } from "store/store";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { LegalNotice } from "website/components/molecules/LegalNotice/LegalNotice";
import { MaPension } from "../MaPension/MaPension";
import { MonEpargneAConstituer } from "../MonEpargneAConstituer/MonEpargneAConstituer";
import { MonPatrimoine } from "../MonPatrimoine/MonPatrimoine";
import { ObjectifRevenus } from "../ObjectifRevenus/ObjectifRevenus";
import { Revenus } from "../Revenus/Revenus";
import "./Synthese.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const Synthese: FunctionComponent = () => {
  const intl = useIntl();
  const { selectedRetirementAge } = useSelectedRetirementAge();
  const {
    montantPension,
    montantRevenusAFinancer,
    montantRevenusComplementaires,
    montantTotal,
    montantEffortEpargne,
  } = useMonPlanMontants(selectedRetirementAge);
  const openSGAgenda = useSGAgenda();
  const { disponibiliteSGAgenda } = useFeatures();
  const isPhone = useMediaQuery({ minwidth: 0, maxwidth: "sm" });
  const { trackClick } = useTracking();

  const dashboardState = useDashboardState();
  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const { assets } = assetState;

  const [isParcoursPatrimoineDone, setIsParcoursPatrimoineDone] =
    useState(false);
  const [isParcoursEpargnerDone, setIsParcoursEpargnerDone] = useState(false);
  const [isParcoursRISDone, setIsParcoursRISDone] = useState<boolean>(false);
  const [initGraph, setInitGraph] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  const handleOnClickRdv = () => {
    trackClick("clic-sur-contacter-mon-conseiller", {
      form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
    });
    // FIXME : Déporter dans BoutonSGAgenda
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  const handleOnClickImpression = () => {
    trackClick("clic-sur-imprimer-mon-plan");
    window.print();
  };

  useEffect(() => {
    if (hasFetchedDashboard(dashboardState)) {
      setIsParcoursPatrimoineDone(
        dashboardState?.dashboard?.firstPatrimonyPathDate !== undefined
      );
      setIsParcoursEpargnerDone(
        dashboardState?.dashboard?.firstSavingPathDate !== undefined
      );
      setIsParcoursRISDone(dashboardState?.dashboard?.risDate !== undefined);
    }
  }, [dashboardState]);

  // Obligatoire pour laisser le temps au graphe de se dimensionner dans le SGGridCol parent
  useEffect(() => {
    setInitGraph(true);
  }, [initGraph]);

  return (
    <SGGridRow align="middle">
      <SGGridCol flex="auto" textalign="center">
        <SGText
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: isPhone ? "14px" : "24px",
            lineHeight: isPhone ? "20px" : "29px",
            color: "#000",
          }}
        >
          {intl.formatMessage(
            { id: "monPlan.title" },
            {
              nbAnneeAgeDepartRetraite:
                selectedRetirementAge?.retirementAgeYear,
              nbMoisAgeDepartRetraite: selectedRetirementAge?.retirementAgeMonth
                ? `et ${selectedRetirementAge?.retirementAgeMonth} mois`
                : "",
              montant: montantTotal?.toLocaleString("fr-FR"),
              red: (word: string) => <SGText color="error">{word}</SGText>,
              linebreak: isPhone ? " " : <br />,
            }
          )}
        </SGText>
      </SGGridCol>
      <div className="synthese__button-display">
        <SGMediaQuery minwidth="sm">
          <SGGridCol flex="inital">
            <SGButton
              disableautomargin
              onClick={handleOnClickImpression}
              icon={
                <SGIcon
                  currentcolor
                  component={<SGAvenirStrokedTelechargement />}
                />
              }
              disabled={estSupervisionActive}
            >
              {intl.formatMessage({ id: "monPlan.button.impression" })}
            </SGButton>
          </SGGridCol>
        </SGMediaQuery>
      </div>
      <SGGridCol span={12}>
        <SGCard>
          <SGBox
            padding={isPhone ? {} : { top: "md", bottom: "md", right: "sm" }}
          >
            <SGGridRow>
              <SGGridCol span={12} sm={5}>
                {initGraph && (
                  <Revenus
                    montantTotal={montantTotal}
                    montantPension={montantPension}
                    montantRevenusComplementaires={
                      montantRevenusComplementaires
                    }
                    montantRevenusAFinancer={montantRevenusAFinancer}
                  />
                )}
              </SGGridCol>
              <SGGridCol span={12} sm={7}>
                <SGGridRow>
                  <SGGridCol span={12}>
                    <ObjectifRevenus
                      monthlyIncomeGoal={
                        dashboardState?.dashboard?.monthlyIncomeGoal
                      }
                    />
                  </SGGridCol>
                  <SGGridCol span={12} sm={6}>
                    <MaPension
                      dashboard={dashboardState?.dashboard}
                      isParcoursRISDone={isParcoursRISDone}
                    />
                  </SGGridCol>
                  <SGGridCol span={12} sm={6}>
                    <SGGridRow>
                      <SGGridCol span={12}>
                        <MonPatrimoine
                          isParcoursPatrimoineDone={isParcoursPatrimoineDone}
                          assets={assets}
                        />
                      </SGGridCol>
                      <SGGridCol span={12}>
                        <MonEpargneAConstituer
                          isParcoursEpargnerDone={isParcoursEpargnerDone}
                          effortEpargne={montantEffortEpargne}
                        />
                      </SGGridCol>
                    </SGGridRow>
                  </SGGridCol>
                </SGGridRow>
              </SGGridCol>
            </SGGridRow>
          </SGBox>
        </SGCard>
      </SGGridCol>
      <SGMediaQuery minwidth={0} maxwidth="sm">
        <div className="containerAssu">
          <SGGridCol>
            <LegalNotice />
          </SGGridCol>
          <SGGridCol textalign="center">
            <BoutonSGAgenda
              type="primary"
              icon={<Calendar />}
              onClick={handleOnClickRdv}
              data-cy="disclaimer-submit"
            >
              <FormattedMessage id="monPlan.button.contacter" />
            </BoutonSGAgenda>
          </SGGridCol>
        </div>
      </SGMediaQuery>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </SGGridRow>
  );
};

export { Synthese };
