import React, {FunctionComponent} from "react";
import {Loan} from "../../../../store/loans/types";
import {AssetCategory, AssetWithValuation,} from "../../../../store/assets/types";
import {NameAndValuationFrame} from "../NameAndValuationFrame/NameAndValuationFrame";
import {computeValuationPerAssetType, isAssociatedToSCI, isCoOwned} from "../../../../store/assets/utils";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {useSCIAssets} from "../../hooks/useSCIAssets";
import {SGIcon} from "sg-icon";
import {
   SGAvenirStrokedAutresRevenus,
   SGAvenirStrokedCompteATerme,
   SGAvenirStrokedEmprunter,
   SGAvenirStrokedEpargne,
   SGAvenirStrokedHabitation,
   SGAvenirStrokedPortefeuille
} from "sg-icon-pack-base";
import {SGSpace} from "sg-space";
import {SGBlock} from "sg-typo";
import {useLoans} from "../../hooks/useLoans";
import {getEndDate} from "../../../../store/loans/utils";

interface FrameCategoryProps {
   assetCategory: AssetCategory;
   goToEditor: () => void;
   amountSubTitle?: string;
   loans?: Loan[];
   assets?: AssetWithValuation[];
   cypressName: string;
}

export const getCategoryIcone = (category: AssetCategory) => {
   switch (category.intlKey) {
      case "INVESTMENT_ACCOUNT":
         return <SGIcon component={<SGAvenirStrokedEpargne/>}/>;

      case "SAVINGS_ACCOUNT":
      case "TERM_ACCOUNT":
         return <SGIcon component={<SGAvenirStrokedCompteATerme/>}/>;

      case "REAL_ESTATE":
         return <SGIcon component={<SGAvenirStrokedHabitation/>}/>;

      case "BANK_ACCOUNT":
         return <SGIcon component={<SGAvenirStrokedPortefeuille/>}/>;
      case "LOAN":
         return <SGIcon component={<SGAvenirStrokedEmprunter/>}/>;
      case "OTHER":
      default:
         return <SGIcon component={<SGAvenirStrokedAutresRevenus/>}/>;
   }
};

const FrameCategory: FunctionComponent<FrameCategoryProps> = (props: FrameCategoryProps) => {
   const {assetCategory, loans, assets, amountSubTitle, goToEditor, cypressName} = props;
   const sciAssets = useSCIAssets();
   const allLoans = useLoans([]);

   return (
      <SGSpace direction="vertical" data-cy={assetCategory.intlKey} disableautomargin>
         {((assets && assets.length !== 0) || (loans && loans.length !== 0)) && (
            <SGBlock>
               {getCategoryIcone(assetCategory)}
               &nbsp;
               <SGTextIntl intlId={`assetCategory.${assetCategory.intlKey}`} strong/>
            </SGBlock>
         )}
         {assets && assets.map((asset: AssetWithValuation) => (
            <NameAndValuationFrame key={asset.id} onClick={goToEditor} isCoOwned={isCoOwned(asset)} amountSubTitle={amountSubTitle}
                                   cypressName={`${cypressName}-${asset.assetType}`} isBeingBoughtBack={asset.beingBoughtBack}
                                   amount={computeValuationPerAssetType(asset, allLoans)} frameColor={assetCategory.color}
                                   isNet={false} isPerMonth={false} title={asset.assetName} isAssociatedToSCI={isAssociatedToSCI(sciAssets, asset)}/>
         ))}
         {loans && loans.map((loan: Loan) => (
            <NameAndValuationFrame onClick={goToEditor} amountSubTitle={amountSubTitle} cypressName={`${cypressName}-${loan.loanType}`}
                                   amount={-Math.trunc(loan.remainingPrincipal)} isNet={false} isPerMonth={false} title={loan.name}
                                   loanMonthlyAmount={loan.monthlyAmount} loanEndDate={getEndDate(loan)} frameColor={assetCategory.color} key={loan.id}
                                   isAssociatedToSCI={isAssociatedToSCI(sciAssets, loan)}/>
         ))}
      </SGSpace>
   );
};

export {FrameCategory};
