import { ReactNodeLike } from "prop-types";
import React, { FunctionComponent } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGSpace } from "sg-space";
import { SGTile } from "sg-tile";

interface CadreInfoSupProps {
  icone: ReactNodeLike;
}

const CadreInfoSup: FunctionComponent<CadreInfoSupProps> = (
  props: React.PropsWithChildren<CadreInfoSupProps>
) => {
  const { icone, children } = props;

  return (
    <SGTile background="Sec_Sg2">
      <SGGridRow align="middle">
        <SGGridCol flex="0 0 0%">
          <SGIcon key="icone" component={icone} currentcolor />
        </SGGridCol>
        <SGGridCol flex="1 0 0%">
          <SGSpace
            key="contenus"
            direction="vertical"
            disableautomargin
            size="xs"
          >
            {children}
          </SGSpace>
        </SGGridCol>
      </SGGridRow>
    </SGTile>
  );
};

export { CadreInfoSup };
