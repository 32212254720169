import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { SGMediaQuery } from "sg-media-query";
import { Video } from "website/components/hooks/useVideos";
import { CarteVideo, PlayerRef } from "website/components/molecules/CarteVideo/CarteVideo";
import { PauseVideoContext } from "website/components/providers/PauseVideoProvider";
import { CarrouselVideoCarteMobile } from "./CarrouselVideoCarteMobile/CarrouselVideoCarteMobile";

interface CarrouselVideoCarteProps {
    showLoader?: boolean;
    video: Video;
    currentIndex: number;
    pauseIndex?: number;
}

// Composant card du parcours epargner
export const CarrouselVideoCarte: FunctionComponent<CarrouselVideoCarteProps> = (props: React.PropsWithChildren<CarrouselVideoCarteProps>) => {
    const { video, currentIndex, pauseIndex, showLoader = false } = props;
    const {pauseVideoCarrousel, setPauseVideoCarrousel, setPauseVideo} = useContext(PauseVideoContext);

    const [player, setPlayer] = useState<PlayerRef>();

    useEffect(() => {
        if (player && pauseIndex === currentIndex) {
            player.pause();
        }
    }, [pauseIndex]);

    useEffect(() => {
        if (player && pauseVideoCarrousel === true) {
          player.pause();
          setPauseVideoCarrousel(false);
        }
      }, [pauseVideoCarrousel]);

    return (
        <>
            <SGMediaQuery minwidth={0} maxwidth="xs">
                <CarrouselVideoCarteMobile video={video} getPlayer={setPlayer} />
            </SGMediaQuery>
            <SGMediaQuery minwidth="sm">
                <CarteVideo
                    video={video}
                    getPlayer={setPlayer}
                    onClickPlayer={() => setPauseVideo(true)}
                    showLoader={showLoader}
                />
            </SGMediaQuery>
        </>
    );
};
