import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { ArticleContenu } from "website/components/molecules/Article/ArticleContenu/ArticleContenu";
import { ServiceModel } from "website/components/organisms/MesServices/ServiceModel";
import { TypeMedia } from "website/components/hooks/articles";

type ServiceContenuProps = {
    service: ServiceModel;
    logo: string;
};

export const ServiceContenu: FunctionComponent<ServiceContenuProps> = (props: React.PropsWithChildren<ServiceContenuProps>) => {
    const { service, logo } = props;

    return (
        <SGBox margin={{ left: "xl", right: "xl", top: "xl", bottom: "xl" }}>
            <>
            <SGGridRow>
                <SGGridCol sm={6} md={8} xxl={9}>
                    <SGTitle level={3} visuallevel={4}>
                        <FormattedMessage id="contenu.title" defaultMessage={service.title} values={{ linebreak: <br /> }} />
                    </SGTitle>
                    {service.subtitle && (
                        <SGText titlerender size="xs">
                            {service.subtitle}
                        </SGText>
                    )}
                </SGGridCol>
                <SGGridCol sm={6} md={4} xxl={3}>
                    <img alt={`logo-service-${logo}`} src={logo} />
                </SGGridCol>
            </SGGridRow>
            <SGDivider />
            <SGGridRow>
                <SGBox style={{width: "100%"}} padding={{right: "md", left: "md", bottom: "sm"}} margin={{ top: "md" }}><>
                {service.contenus.map((contenu, index) => (
                    // Sans l'index, problème lors du changement de service
                    <ArticleContenu key={`${contenu.type}_${String(index)}`} contenu={contenu} type={TypeMedia.ARTICLE} />
                ))}
                </></SGBox>
            </SGGridRow>
            </>
        </SGBox>
    );
};
