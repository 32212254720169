import { useEffect } from "react";

const useScrollToTopWithCurrentStep = (currentStep: number) => {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [currentStep]);

   return null;
};

export { useScrollToTopWithCurrentStep};
