import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { callDashboard, setHasFetchedAges } from "store/dashboard/actions";
import {
  RetirementCategory,
  RetirementCategorySimulation,
} from "store/members/types";
import { PersonalInformationsState } from "store/personalInformations/types";
import { updateParameters } from "store/simulateurAvantApres/slice";
import {
  CategorieActiveChoix,
  ParametresSimulateurAvantApres,
} from "store/simulateurAvantApres/types";
import {
  callRetirementSimulation,
  updateRetirementSimulation,
} from "store/simulationAssu/actions";
import {
  RetirementSimulationParameters,
  RetirementSimulationParametersState,
} from "store/simulationAssu/types";
import { State } from "store/store";
import { nextStepAction } from "store/tunnel/actions";
import {
  WorkIncomeRaisesType,
  WorkIncomeRaisesTypeSimulation,
  WorkIncomesHistoryState,
} from "store/workIncomesHistory/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import {
  PAGE_CATEGORY_3_ONBOARDING,
  PAGE_NAME_MA_SITUATION_PROFESSIONNELLE,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { useUpdateSituation } from "website/components/hooks/useUpdateSituation";
import { getDate } from "website/utils/date/DateUtils";
import { WorkIncomes } from "../../../WorkIncomes/WorkIncomes";

export const TunnelWorkIncomes: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { trackClick, trackPage } = useTracking();
  const saveEvent = useTrackingEvent();
  const {
    updateCategorieActive,
    updateSituation,
    updateWorkIncomes,
    updateWorkIncomesHistory,
  } = useUpdateSituation();

  const dashboardState = useDashboardState();

  const [pastForecast, setPastForecast] = useState<WorkIncomeRaisesType>();
  const [futureForecast, setFutureForecast] = useState<WorkIncomeRaisesType>();
  const [retirementType, setRetirementType] = useState<RetirementCategory>();
  const [categorieActive, setCategorieActive] = useState<CategorieActiveChoix>(
    CategorieActiveChoix.NON
  );
  const [carrierStartYears, setCarrierStartYears] = useState<number>();
  const [carrierStartMonth, setCarrierStartMonth] = useState<number>();
  const [secteurPro, setSecteurPro] = useState<string>();
  const [montantSalaireAnnuel, setMontantSalaireAnnuel] = useState<number>();
  const [memberId, setMemberId] = useState<number>();
  const [showPopin, setShowPopin] = useState(false);
  const [hasSeenInfo, setHasSeenInfo] = useState(false);
  const [hasSubmitStep, setHasSubmitStep] = useState<boolean>(false);
  const [callTimer, setCallTimer] = useState<number>(0);
  const [errorHappened, setErrorHappened] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const personalInformationsState: PersonalInformationsState = useSelector<
    State,
    PersonalInformationsState
  >((state) => state.personalInformations);

  const simulationAssuState: RetirementSimulationParametersState = useSelector<
    State,
    RetirementSimulationParametersState
  >((state) => state.retirementSimulationParameters);

  const parametres = useSelector<State, ParametresSimulateurAvantApres>(
    (state) => state.simulateurAvantApres.parametres
  );

  const workIncomesHistoryData: WorkIncomesHistoryState = useSelector<
    State,
    WorkIncomesHistoryState
  >((state) => state.workIncomesHistory);

  const onHideModal = () => {
    setShowPopin(false);
    setHasSeenInfo(true);
  };

  const handleSetVisible = (visible: boolean) => {
    setErrorHappened(visible);
    setShowErrorModal(visible);
  };

  useEffect(() => {
    trackPage(
      PAGE_CATEGORY_3_ONBOARDING,
      PAGE_NAME_MA_SITUATION_PROFESSIONNELLE,
      PAGE_TYPE_FORMULAIRE,
      "inscription",
      "3"
    );
  }, []);

  useEffect(() => {
    if (
      pastForecast === undefined &&
      futureForecast === undefined &&
      workIncomesHistoryData &&
      workIncomesHistoryData.workIncomesHistory
    ) {
      setPastForecast(
        workIncomesHistoryData.workIncomesHistory.pastYearlyIncrease
      );
      setFutureForecast(
        workIncomesHistoryData.workIncomesHistory.expectedYearlyIncrease
      );
    }
  }, [workIncomesHistoryData, pastForecast]);

  useEffect(() => {
    if (personalInformationsState.hasFetched) {
      setCategorieActive(
        personalInformationsState.personalInformations.categorieActive
          ? CategorieActiveChoix.OUI
          : CategorieActiveChoix.NON
      );
    }
  }, [personalInformationsState.hasFetched]);

  useEffect(() => {
    if (
      dashboardState.hasFetchedAges &&
      simulationAssuState?.retirementSimulationParameters?.idSimulSimple &&
      simulationAssuState?.retirementSimulationParameters?.idSimulSimple !== ""
    ) {
      dispatch(setHasFetchedAges(false));
      dispatch(nextStepAction());
    }
  }, [dashboardState.hasFetchedAges, simulationAssuState]);

  useEffect(() => {
    if (
      hasSubmitStep &&
      simulationAssuState.retirementSimulationParameters.idSimulSimple === "" &&
      simulationAssuState.retirementSimulationParameters.simulationErrorCode ===
        undefined
    ) {
      setCallTimer(new Date().getTime());
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      dispatch(callRetirementSimulation(simulationAssuState.retirementSimulationParameters as RetirementSimulationParameters));
    }
  }, [hasSubmitStep, simulationAssuState.retirementSimulationParameters]);

  useEffect(() => {
    if (
      hasSubmitStep &&
      simulationAssuState.retirementSimulationParameters.idSimulSimple &&
      simulationAssuState.retirementSimulationParameters.idSimulSimple !== ""
    ) {
      const newTimer = new Date().getTime();
      saveEvent(
        trackingEvent.ONBOARDING_SAPIENDO_OK,
        `${newTimer - callTimer} ms`
      );
      // Mise à jour des assets depuis wealth après leur alimentation via scrapping
      // Et après avoir réalisé la simulation
      dispatch(getAssetsWithoutLoans());
      dispatch(getAssetsIncomes());
      dispatch(callDashboard(true));
    } else if (
      simulationAssuState.retirementSimulationParameters.simulationErrorCode !==
      undefined
    ) {
      saveEvent(
        trackingEvent.ONBOARDING_SAPIENDO_KO,
        `[${simulationAssuState.retirementSimulationParameters.simulationErrorCode}] ${simulationAssuState.retirementSimulationParameters.simulationErrorMessage}`
      );
      // Cas d'erreur. Annulation
      setHasSubmitStep(false);
      handleSetVisible(true);
    }
  }, [simulationAssuState.retirementSimulationParameters]);

  const onSubmitTunnel = () => {
    if (
      carrierStartYears !== undefined &&
      carrierStartMonth !== undefined &&
      secteurPro &&
      montantSalaireAnnuel !== undefined &&
      futureForecast &&
      pastForecast
    ) {
      setHasSubmitStep(true);
      updateSituation(true, retirementType);
      updateCategorieActive(categorieActive);

      // FIXME Décalage de 2h retirant 1 jour dans le toString => ajout de 12h en paliatif temporaire
      const startDate = new Date(carrierStartYears, carrierStartMonth, 1, 12);
      dispatch(
        updateRetirementSimulation({
          ...simulationAssuState.retirementSimulationParameters,
          montantAnnuelSalaire: montantSalaireAnnuel,
          salaireBrutNet:
            secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE ||
            secteurPro === RetirementCategorySimulation.FONCTIONNAIRE
              ? 1
              : 2,
          dateDebCarriere: getDate(startDate),
          memberId,
          secteurPro,
          genre: "m",
          evolSalaireFutur: WorkIncomeRaisesTypeSimulation[futureForecast],
          evolSalairePasse: WorkIncomeRaisesTypeSimulation[pastForecast],
          idSimulSimple: "",
          simulationErrorCode: undefined,
          simulationErrorMessage: undefined,
        })
      );

      trackClick("clic-sur-suivant");
      updateWorkIncomes(true, secteurPro, montantSalaireAnnuel);

      updateWorkIncomesHistory(
        true,
        startDate,
        montantSalaireAnnuel,
        pastForecast,
        futureForecast
      );
      // Mise à jour des parametres du simulateur avant/apres
      dispatch(
        updateParameters({
          ...parametres,
          dateDebutCarriere: getDate(startDate),
          categorieActive: categorieActive === CategorieActiveChoix.OUI,
          statut: secteurPro,
        })
      );
    }
  };

  const onSubmit = () => {
    if (
      montantSalaireAnnuel === 0 ||
      (montantSalaireAnnuel !== undefined && montantSalaireAnnuel >= 10000) ||
      hasSeenInfo
    ) {
      onSubmitTunnel();
    } else {
      setShowPopin(true);
    }
  };

  return (
    <WorkIncomes
      onSubmit={onSubmit}
      showPopin={showPopin}
      onHideModal={onHideModal}
      carrierStartYears={carrierStartYears}
      setCarrierStartYears={setCarrierStartYears}
      carrierStartMonth={carrierStartMonth}
      setCarrierStartMonth={setCarrierStartMonth}
      montantSalaireAnnuel={montantSalaireAnnuel}
      setMontantSalaireAnnuel={setMontantSalaireAnnuel}
      secteurPro={secteurPro}
      setSecteurPro={setSecteurPro}
      setMemberId={setMemberId}
      retirementType={retirementType}
      setRetirementType={setRetirementType}
      categorieActive={categorieActive}
      setCategorieActive={setCategorieActive}
      pastForecast={pastForecast}
      setPastForecast={setPastForecast}
      futureForecast={futureForecast}
      setFutureForecast={setFutureForecast}
      hasSubmitStep={hasSubmitStep}
      errorHappened={errorHappened}
      showErrorModal={showErrorModal}
      handleError={handleSetVisible}
    />
  );
};
