import { AxiosError, AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import {
  API_ERROR_400,
  API_ERROR_403,
  API_ERROR_422,
  API_ERROR_OTHER,
} from "./types";
import {
  RetirementAge,
  RetirementTypes,
  agesDepartAnticipe,
  agesDepartAnticipeRaisonSante,
} from "store/parcoursRisProjection/types";

export function* onApiCallError(err: AxiosError) {
  const { response }: { response?: AxiosResponse } = err;
  if (response?.status === 422) {
    yield put({ type: API_ERROR_422, payload: err });
  } else if (response?.status === 400) {
    yield put({ type: API_ERROR_400, payload: err });
  } else if (response?.status === 403) {
    yield put({ type: API_ERROR_403, payload: err });
  } else {
    yield put({ type: API_ERROR_OTHER, payload: err });
  }
}

export const estAgeDepartAnticipeSapiendo = (dateDepart: RetirementAge): boolean =>
  dateDepart.types_depart.filter((typeDepart: number) =>
    agesDepartAnticipe.includes(typeDepart)
  ).length > 0;

export const estAgeDepartAnticipeRaisonSanteSapiendo = (dateDepart: RetirementAge): boolean =>
  dateDepart.types_depart.filter((typeDepart: number) =>
    agesDepartAnticipeRaisonSante.includes(typeDepart)
  ).length > 0;

export const estAgeDepartAnticipeCarriereLongueSapiendo = (dateDepart: RetirementAge): boolean =>
  dateDepart.types_depart.includes(RetirementTypes.AGE_DA_CARRIERE_LONGUE);
