import { Family, FETCH_MEMBERS, UPDATE_FAMILY } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { makeAction } from "../utils";

export const getMembersAction = (): PayloadAction<null> => makeAction(FETCH_MEMBERS, null);

export interface UpdateFamilyAction {
   family: Family,
   override: boolean,
   reloadStores: boolean
}

export const updateFamilyAction = (data: Family, override: boolean, reloadStores: boolean): PayloadAction<UpdateFamilyAction> =>
   makeAction(UPDATE_FAMILY, { family: data, override, reloadStores });
