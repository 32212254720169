import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonnalisationQuestionsState, Question } from "./types";

const initialState: PersonnalisationQuestionsState = {
   questions: [],
   hasFetched: false,
};

export const personnalisationQuestionsSlice = createSlice({
   name: "personnalisationQuestions",
   initialState,
   reducers: {
      personnalisationQuestionsFetched: (currentState, action: PayloadAction<Question[]>): PersonnalisationQuestionsState => ({
         ...currentState,
         questions: action.payload,
         hasFetched: true,
      }),
      reset: (): PersonnalisationQuestionsState => ({
         ...initialState,
      }),
      setQuestions: (currentState, action: PayloadAction<Question[]>): PersonnalisationQuestionsState => ({
         ...currentState,
         questions: action.payload,
      }),
   },
});

export const { personnalisationQuestionsFetched, reset, setQuestions } =
personnalisationQuestionsSlice.actions;

export default personnalisationQuestionsSlice.reducer;
