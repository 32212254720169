import {
  FETCH_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
  ParcoursRisInformationsComplementaires,
  ParcoursRisInformationsComplementairesRequest,
  RESET_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_ERROR,
  UPDATE_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
} from "./types";

export const postParcoursRisInformationsComplementaires = (
  data: ParcoursRisInformationsComplementairesRequest
) => ({
  type: FETCH_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
  payload: data,
});

export const resetParcoursRisInformationsComplementairesError = () => ({
  type: RESET_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_ERROR,
});

export const updateParcoursRisInformationsComplementaires = (data: Partial<ParcoursRisInformationsComplementaires>) => ({
  type: UPDATE_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
  payload: data,
});
