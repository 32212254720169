import React, {FunctionComponent, useState} from "react";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {StringInput} from "../../../atoms/StringInput/StringInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {CGU, LOGIN} from "../../../../utils/publicRoutes";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGButtonGroup} from "sg-button";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {submitNewPassword} from "../../../../../store/login/actions";
import {useDispatch} from "react-redux";
import {useUrlParams} from "../../../hooks/useUrlParams";
import {ERR_NO_KEY} from "../../../../../store/login/types";
import {SGCard} from "sg-card";

const PasswordResetFinish: FunctionComponent = () => {
   const dispatch = useDispatch();
   const [key] = useUrlParams("key");

   const formMethods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   const {handleSubmit} = formMethods;
   const [didSubmit, setDidSubmit] = useState<boolean>(false);

   const onSubmit = (data: FieldValues) => {
      dispatch(submitNewPassword({key: !key ? ERR_NO_KEY : key, newPassword: data.password}));
      setDidSubmit(true);
   };

   return (
      <SGCard textalign="center">
         <SGTitleIntl intlId="password-reset.finish.title" cypressName="password-reset-finish-title"/>
         <SGTextIntl intlId={didSubmit ? "password-reset.finish.login" : "password-reset.finish.password"}/>
         {didSubmit && <SGButtonIntl type="link" cypressName="password-reset-finish-login" onClick={() => redirectToRelativePath(LOGIN)}
                                     intlId="password-reset.finish.back-to-login"/>}

         {!didSubmit &&
         <FormProvider {...formMethods}>
            <form>
               <FormInputs colLength={10}>
                  <StringInput label="login.form.your.password" tooltipLabel="login.form.password.pattern" tooltipTransformations={{br: <br/>}}
                               defaultValue="" name="password" cypressName="password-reset-finish-password" minLength={8}
                               minLengthMessage="login.form.error.password.size"
                               maxLength={16} maxLengthMessage="login.form.error.password.size" isPassword
                               pattern={/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])(?!.*(.)\1{2}).{8,16}$/}
                               patternMessage="login.form.error.password.pattern"
                                  onValueChange={() => formMethods.trigger("confirmPassword")} placeholder="login.form.your.password.placeholder"/>

                     <StringInput label="login.form.confirm.your.password" defaultValue="" name="confirmPassword" cypressName="password-reset-finish-confirmPassword"
                                  minLength={6} isPassword minLengthMessage="login.form.error.password.size" maxLength={100}
                                  maxLengthMessage="login.form.error.password.size" onValueChange={() => formMethods.trigger("confirmPassword")}
                                  placeholder="login.form.your.password.placeholder" validate={(value: string) =>
                        value === formMethods.getValues().password
                     } validateMessage="signup.different.passwords"/>
                  </FormInputs>
               <SGButtonGroup layout="column" align="center">
                  <SGButtonIntl type="primary" size="md" disabled={!formMethods.formState.isValid} cypressName="password-reset-finish-submit"
                                onClick={handleSubmit(onSubmit)} intlId="password-reset.finish.submit"/>
                  <SGButtonIntl type="link" onClick={() => redirectToRelativePath(CGU)} intlId="signup.link.cgu"/>
               </SGButtonGroup>
            </form>
         </FormProvider>}
      </SGCard>
   );
};
export {PasswordResetFinish};
