import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiPost } from "store/apiCaller";
import { onApiCallErrorNoError, safe } from "store/error/utils";
import { SAVE_TRACKING_EVENT, TrackingEvent, TRACKING_EVENT_SAVED } from "./types";

export function* watcherSaga() {
   yield takeLeading(SAVE_TRACKING_EVENT, safe(onApiCallErrorNoError, handleSaveTrackingEvent));
}

/**
 * Ajoute un évènement déclenché par l'utilisateur
 * @param action Action contenant TrackingEvent
 */
function* handleSaveTrackingEvent(action: PayloadAction<TrackingEvent>) {
   yield call(apiPost, `/backpfr/api/user-tracking-events`, action.payload);
   yield put({ type: TRACKING_EVENT_SAVED, payload: [action.payload] });
}
