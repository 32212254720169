import { PayloadAction } from '@reduxjs/toolkit';
import { take, fork, cancel } from 'redux-saga/effects'

/**
 * Takes the first action with same parameters.
 * Cancels the previous task if the named parameter in the payload changed.
 *
 * @param patternOrChannel Channel
 * @param saga Saga
 * @param args Args The first arg is the key to test in the payload
 */
export const takeNewLatest = (patternOrChannel: any, saga: (...args: any) => void, ...args: any) => fork(function* _() {
    const [key, ...newArgs]: [string, ...any] = args;
    let lastTask: any;
    let lastParam: any;
    while (true) {
        const action: PayloadAction<any> = yield take(patternOrChannel);
        if (action.payload[key] !== lastParam) {
            if (lastTask) {
                yield cancel(lastTask); // cancel is no-op if the task has already terminated
            }
            lastParam = action.payload[key];
            lastTask = yield fork(saga, ...newArgs.concat(action));
        }
    }
});
