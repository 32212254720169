import {call, put, takeLeading} from "redux-saga/effects";
import {
   CREATE_TAX_CREDIT,
   DELETE_TAX_CREDIT,
   FETCH_TAX_CREDITS,
   TaxCredit,
   TaxSystem,
   UPDATE_PARTNER_TAX_SYSTEM,
   UPDATE_TAX_CREDIT
} from "./types";
import { onDefaultError, safe } from "../error/utils";
import { apiDelete, apiGet, apiPost, apiPut } from "../apiCaller";
import {PayloadAction} from "@reduxjs/toolkit";
import {
   partnerTaxSystemUpdated,
   taxCreditCreated,
   taxCreditDeleted,
   taxCreditsFetched,
   taxCreditUpdated,
} from "./taxSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
  yield takeLeading(UPDATE_PARTNER_TAX_SYSTEM, safe(onDefaultError, handleUpdatePartnerTaxSystem));
  yield takeLeading(FETCH_TAX_CREDITS, safe(onDefaultError, handleGetTaxCredits));
  yield takeLeading(CREATE_TAX_CREDIT, safe(onDefaultError, handleCreateTaxCredit));
  yield takeLeading(UPDATE_TAX_CREDIT, safe(onDefaultError, handleUpdateTaxCredit));
  yield takeLeading(DELETE_TAX_CREDIT, safe(onDefaultError, handleDeleteTaxCredit));
}

function* handleUpdatePartnerTaxSystem(action: PayloadAction<TaxSystem>) {
   const payload: AxiosResponse<TaxSystem> = yield call(apiPut, `wealth/api/simulation/tax-system/partner?assetTaxSystem=${action.payload}`);
   yield put(partnerTaxSystemUpdated(payload.data));
}

function* handleGetTaxCredits() {
   const payload: AxiosResponse<TaxCredit[]> = yield call(apiGet, `wealth/api/tax-credit`);
   yield put(taxCreditsFetched(payload.data));
}

function* handleCreateTaxCredit(action: PayloadAction<Omit<TaxCredit, "id">>) {
   const payload: AxiosResponse<TaxCredit> = yield call(apiPost, `wealth/api/tax-credit`, action.payload);
   yield put(taxCreditCreated(payload.data));
}

function* handleUpdateTaxCredit(action: PayloadAction<TaxCredit>) {
   const payload: AxiosResponse<TaxCredit> = yield call(apiPut, `wealth/api/tax-credit`, action.payload);
   yield put(taxCreditUpdated(payload.data));
}

function* handleDeleteTaxCredit(action: PayloadAction<TaxCredit>) {
   yield call(apiDelete, `wealth/api/tax-credit/${action.payload.id}`);
   yield put(taxCreditDeleted(action.payload));
}
