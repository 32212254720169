import { Reducer } from "react";
import {
  WORK_INCOMES_HISTORY_FETCHED,
  WorkIncomesHistoryState,
  WORK_INCOMES_HISTORY_SAVED,
  UPDATE_WORK_INCOMES_HISTORY_STORE,
} from "./types";

const initialState: WorkIncomesHistoryState = {} as WorkIncomesHistoryState;

export const WorkIncomesHistoryReducer: Reducer<WorkIncomesHistoryState, any> = (
  currentState = initialState,
  action
): WorkIncomesHistoryState => {
  switch (action.type) {
    case WORK_INCOMES_HISTORY_FETCHED:
      if (action.payload) {
        return { workIncomesHistory: action.payload };
      }

      return currentState;
    case WORK_INCOMES_HISTORY_SAVED:
      return { workIncomesHistory: action.payload };
    case UPDATE_WORK_INCOMES_HISTORY_STORE:
      return { workIncomesHistory: action.payload };
    default:
      return currentState;
  }
};
