import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeatures } from "store/features/actions";
import {
  FeatureList,
  FeaturesState,
  hasFetchedFeatures,
} from "store/features/types";
import { State } from "../../../store/store";

const useFeatures = (): FeatureList => {
  const dispatch = useDispatch();
  const featuresState: FeaturesState = useSelector<State, FeaturesState>(
    (state) => state.features
  );
  const [disponibiliteMCR, setDisponibiliteMCR] = useState<boolean>();
  const [disponibiliteSimulateurFlash, setDisponibiliteSimulateurFlash] =
    useState<boolean>();
  const [parcoursSEIActif, setParcoursSEIActif] = useState<boolean>();
  const [disponibiliteAppeler, setDisponibiliteAppeler] = useState<boolean>();
  const [disponibiliteWebCallback, setDisponibiliteWebCallback] =
    useState<boolean>();
  const [simulateurAvantApres, setSimulateurAvantApres] = useState<boolean>();
  const [disponibiliteSGAgenda, setDisponibiliteSGAgenda] = useState<boolean>();
  const [
    disponibiliteParcoursRevenusComplementaires,
    setDisponibiliteParcoursRevenusComplementaires,
  ] = useState<boolean>();
  const [disponibiliteOffrePER, setDisponibiliteOffrePER] = useState<boolean>();
  const [disponibiliteParcoursRIS, setDisponibiliteParcoursRIS] =
    useState<boolean>();
  const [
    disponibiliteHorsSgPatrimoineSupervision,
    setDisponibiliteHorsSgPatrimoineSupervision,
  ] = useState<boolean>();
  const [disponibiliteSupervision, setDisponibiliteSupervision] =
    useState<boolean>();
  const [hasFetchedFeatureFlipping, setHasFetchedFeatureFlipping] =
    useState<boolean>(false);
  const [
    visibiliteQuestionnaireDashboard,
    setVisibiliteQuestionnaireDashboard,
  ] = useState<boolean>();

  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  /**
   * Cherche la fonctionnalité dans la liste, si définie.
   * @param name Fonctionnalité à chercher
   * @returns La valeur ou undefined si non trouvé (ou non fetch)
   */
  const findFeature = (name: string): boolean | undefined =>
    featuresState.features?.find((feature) => feature.id === name)?.active;

  useEffect(() => {
    if (!hasFetchedFeatures(featuresState)) {
      dispatch(getFeatures());
    }
  }, []);

  useEffect(() => {
    if (hasFetchedFeatures(featuresState)) {
      setDisponibiliteMCR(findFeature("disponibiliteMCR"));
      const disponibiliteSapiendo = findFeature("disponibiliteSapiendo");
      setDisponibiliteSimulateurFlash(
        findFeature("disponibiliteSimulateurFlash") && disponibiliteSapiendo
      );
      setParcoursSEIActif(findFeature("parcoursSEI") && disponibiliteSapiendo);
      setDisponibiliteAppeler(findFeature("disponibiliteAppeler"));
      setDisponibiliteWebCallback(findFeature("disponibiliteWebCallback"));
      setSimulateurAvantApres(
        findFeature("simulateurAvantApres") && disponibiliteSapiendo
      );
      setDisponibiliteSGAgenda(findFeature("disponibiliteSGAgenda"));
      setDisponibiliteParcoursRevenusComplementaires(
        findFeature("disponibiliteParcoursRevenusComplementaires")
      );
      setDisponibiliteOffrePER(findFeature("disponibiliteOffrePER"));
      setDisponibiliteParcoursRIS(
        findFeature("disponibiliteParcoursRIS") && disponibiliteSapiendo
      );
      setDisponibiliteSupervision(findFeature("disponibiliteSupervision"));
      setVisibiliteQuestionnaireDashboard(
        findFeature("visibiliteQuestionnaireDashboard")
      );
    }
    setHasFetchedFeatureFlipping(hasFetchedFeatures(featuresState));
  }, [featuresState]);

  useEffect(() => {
    if (hasFetchedFeatures(featuresState)) {
      setDisponibiliteSGAgenda(
        !estSupervisionActive && findFeature("disponibiliteSGAgenda")
      );
      setDisponibiliteHorsSgPatrimoineSupervision(
        !estSupervisionActive ||
          findFeature("disponibiliteHorsSgPatrimoineSupervision")
      );
    }
  }, [featuresState, estSupervisionActive]);

  return {
    disponibiliteMCR,
    disponibiliteSimulateurFlash,
    parcoursSEIActif,
    disponibiliteAppeler,
    disponibiliteWebCallback,
    simulateurAvantApres,
    disponibiliteSGAgenda,
    disponibiliteParcoursRevenusComplementaires,
    disponibiliteOffrePER,
    disponibiliteParcoursRIS,
    disponibiliteHorsSgPatrimoineSupervision,
    disponibiliteSupervision,
    hasFetchedFeatureFlipping,
    visibiliteQuestionnaireDashboard,
  };
};

export { useFeatures };
