import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoginState } from "./types";

const initialState: LoginState = {
  login: { username: "", password: "" },
  isLoggedIn: false,
  isPasswordInvalid: false,
  forceLogOut: false,
  logoutRedirection: undefined,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess: (currentState) => ({
      ...currentState,
      isLoggedIn: true,
      forceLogOut: false,
    }),
    loginErrorWrongPassword401: (currentState) => ({
      ...currentState,
      isPasswordInvalid: true,
      forceLogOut: false,
    }),
    userLogout: (currentState, action: PayloadAction<string | undefined>) => ({
      ...currentState,
      forceLogOut: true,
      logoutRedirection: action.payload || window.env.REACT_APP_LOGOUT_URL,
    }),
  },
});

export const { loginSuccess, loginErrorWrongPassword401, userLogout } =
  loginSlice.actions;

export default loginSlice.reducer;
