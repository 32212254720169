import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPersonnalisationDashboardParams } from "store/personnalisation/dashboard/actions";
import { DashboardType } from "store/personnalisation/dashboard/types";
import { State } from "store/store";

export enum SectionDashBoardType {
  REVENUS = "revenus",
  BESOINS = "besoins",
  PATRIMOINE = "patrimoine",
  PENSION = "pension",
  REFORME_ET_SYNTHESE = "reformeEtSynthese",
  BANNIERES = "bannieres",
  ARTICLES = "articles",
  PREPARER_MA_RETRAITE = "preparerMaRetraite",
}

const usePersonnalisationDashboard = () => {
  const dispatch = useDispatch();
  const hasFetched = useSelector<State, boolean>(
    (state) => state.personnnalisationDashboard.hasFetched
  );
  const dashboard: string | undefined = useSelector<State, string | undefined>(
    (state) => state.personnnalisationDashboard.dashboard
  );

  const sectionsPerso = useMemo(() => {
    switch (dashboard) {
      case DashboardType.PATRIMOINE:
        return [
          SectionDashBoardType.PATRIMOINE,
          SectionDashBoardType.BESOINS,
          SectionDashBoardType.PENSION,
          SectionDashBoardType.BANNIERES,
          SectionDashBoardType.REFORME_ET_SYNTHESE,
          SectionDashBoardType.PREPARER_MA_RETRAITE,
          SectionDashBoardType.ARTICLES,
        ];
      case DashboardType.NOVICE:
        return [
          SectionDashBoardType.ARTICLES,
          SectionDashBoardType.PENSION,
          SectionDashBoardType.BESOINS,
          SectionDashBoardType.PATRIMOINE,
          SectionDashBoardType.BANNIERES,
          SectionDashBoardType.REFORME_ET_SYNTHESE,
          SectionDashBoardType.PREPARER_MA_RETRAITE,
        ];
      case DashboardType.PENSION:
        return [
          SectionDashBoardType.PENSION,
          SectionDashBoardType.BESOINS,
          SectionDashBoardType.PATRIMOINE,
          SectionDashBoardType.BANNIERES,
          SectionDashBoardType.REFORME_ET_SYNTHESE,
          SectionDashBoardType.PREPARER_MA_RETRAITE,
          SectionDashBoardType.ARTICLES,
        ];
      default: // Besoin
        return [
          SectionDashBoardType.BESOINS,
          SectionDashBoardType.PATRIMOINE,
          SectionDashBoardType.PENSION,
          SectionDashBoardType.BANNIERES,
          SectionDashBoardType.REFORME_ET_SYNTHESE,
          SectionDashBoardType.PREPARER_MA_RETRAITE,
          SectionDashBoardType.ARTICLES,
        ];
    }
  }, [dashboard]);

  useEffect(() => {
    if (!hasFetched) {
      dispatch(fetchPersonnalisationDashboardParams());
    }
  }, [hasFetched]);

  return { sectionsPerso, dashboard };
};

export { usePersonnalisationDashboard };
