import {
   IMPORT_ASSETS_AND_MEMBERS,
   IMPORT_LOANS,
   ImportState,
   RawAssetAndLoansState,
   START_IMPORT,
   UPDATE_IMPORT_DATA
} from "./types";

export const updateImportData = (importData: ImportState) => ({
   type: UPDATE_IMPORT_DATA,
   payload: importData
});

/**
 * Importe les membres et assets depuis Wealth
 * @param disponibiliteParcoursRevenusComplementaires Feature flipping
 * @returns PayloadAction
 */
export const importAssetAndMembers = (
   disponibiliteParcoursRevenusComplementaires: boolean,
) => ({
   type: IMPORT_ASSETS_AND_MEMBERS,
   payload: { disponibiliteParcoursRevenusComplementaires }
});

/**
 * Importe les loans dans Wealth
 * @param rawAssetAndLoansState Loans déjà parsés
 * @returns PayloadAction
 */
export const importLoans = (
   rawAssetAndLoansState: RawAssetAndLoansState,
) => ({
   type: IMPORT_LOANS,
   payload: { rawAssetAndLoansState }
});

/**
 * Parse les loans dans le scrapping puis les importe
 * @param host Host
 * @param destroyScript Script à détruire
 * @returns PayloadAction
 */
export const startImportScrapping = (
   host: string,
   destroyScript: () => void
) => ({
   type: START_IMPORT,
   payload: { host, destroyScript }
});
