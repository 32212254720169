import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet, apiPostFile } from "../apiCaller";
import { safe } from "../error/utils";
import { onApiCallError } from "../parcoursRIS/utils";
import {
  FETCH_PARCOURS_RIS_UPLOAD,
  FETCH_PARCOURS_RIS_UPLOAD_ACCESSIBLE,
  ParcoursRisUpload,
  PARCOURS_RIS_UPLOAD_ACCESSIBLE_FETCHED,
  PARCOURS_RIS_UPLOAD_FETCHED
} from "./types";

export default function* watcherSaga() {
  yield takeLeading(
    FETCH_PARCOURS_RIS_UPLOAD,
    safe(onApiCallError, handlePostParcoursRisUpload)
  );
  yield takeLeading(
    FETCH_PARCOURS_RIS_UPLOAD_ACCESSIBLE,
    safe(onApiCallError, handleGetAccessibiliteParcoursRisUpload)
  );
}

function* handlePostParcoursRisUpload(action: any) {
  const formData = new FormData();
  formData.append("ris", action.payload.ris);
  const payload: AxiosResponse<ParcoursRisUpload> = yield call(
    apiPostFile,
    `/backpfr/api/parcours-ris/upload`,
    formData,
    window.env.REACT_APP_API_URL
  );
  yield put({ type: PARCOURS_RIS_UPLOAD_FETCHED, payload: payload.data });
}

function* handleGetAccessibiliteParcoursRisUpload() {
  yield call(apiGet, `/backpfr/api/parcours-ris/upload/isAccessible`, window.env.REACT_APP_API_URL);
  yield put({ type: PARCOURS_RIS_UPLOAD_ACCESSIBLE_FETCHED });
}
