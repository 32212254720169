import { FunctionComponent, useState } from "react";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFleche,
  SGAvenirStyledCalendrierAujourdhui,
} from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { DashboardAge } from "store/dashboard/types";
import { getAge, getDateAge } from "store/dashboard/utils";
import { DrawerAge } from "./DrawerAge";
import { useMediaQuery } from "sg-media-query";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface AgeProps {
  setSelectedRetirementAge: (retirementAge: DashboardAge) => void;
  agesDepartRetraite: DashboardAge[];
  selectedRetirementAge?: DashboardAge;
}

const Age: FunctionComponent<AgeProps> = (props: AgeProps) => {
  const {
    agesDepartRetraite,
    selectedRetirementAge,
    setSelectedRetirementAge,
  } = props;

  const [showDrawer, setShowDrawer] = useState(false);
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackClick } = useTracking();

  const onClick = () => {
    trackClick("clic-sur-mon-age-de-depart");
    setShowDrawer(true);
  };

  return (
    <>
      <SGTile clickable="full" disableautomargin>
        <SGGridRow align="middle">
          <SGGridCol flex="0 0 0%">
            <SGIcon
              component={<SGAvenirStyledCalendrierAujourdhui />}
              size="s"
            />
          </SGGridCol>
          <SGGridCol flex="1 0 0%">
            <SGTitle level={2} visuallevel={isPhone ? 3 : 5}>
              Mon âge de départ
            </SGTitle>
            {selectedRetirementAge && (
              <SGBox margin={{ top: "xs" }}>
                <>
                  <SGText titlerender size={isPhone ? "l" : "m"}>
                    {getAge(selectedRetirementAge)}
                  </SGText>
                  <SGText size="xs" color="lighter">
                    {getDateAge(selectedRetirementAge)}
                  </SGText>
                </>
              </SGBox>
            )}
          </SGGridCol>
          <SGGridCol flex="0 0 0%">
            <SGButton
              type="link"
              onClick={onClick}
            >
              Modifier
            </SGButton>
          </SGGridCol>
        </SGGridRow>
      </SGTile>
      <DrawerAge
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        agesDepartRetraite={agesDepartRetraite}
        setSelectedRetirementAge={setSelectedRetirementAge}
        selectedRetirementAge={selectedRetirementAge}
      />
    </>
  );
};

export { Age };
