import { ServiceCardModel } from "../organisms/MesServices/ServiceModel";

const services: ServiceCardModel[] = [
    {
        id: "info-retraite",
        logo: "/images/mesServices/info-retraite.svg",
        trackKey: "info-retraite"
    },
    {
        id: "cnav",
        logo: "/images/mesServices/cnav.svg",
        trackKey: "la-caisse-nationale-d-assurance-vieillesse-cnav"
    },
    {
        id: "travailleurs-independants",
        logo: "/images/mesServices/travailleurs_independants_gradient.png",
        trackKey: "le-regime-general-des-travailleurs-independants"
    },
    {
        id: "agirc-arrco",
        logo: "/images/mesServices/agirc-arrco.svg",
        trackKey: "l-agirc-arrco"
    },
    {
        id: "retraite-publique",
        logo: "/images/mesServices/retraite-publique.svg",
        trackKey: "ma-retraite-publique"
    },
    {
        id: "retraite-etat",
        logo: "/images/mesServices/retraite-etat.svg",
        trackKey: "service-des-retraites-de-l-etat"
    },
    {
        id: "tous",
        logo: "/images/mesServices/tous.svg",
        trackKey: "tous-les-sites-des-caisses-de-retraite"
    },
];
export { services };
