import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Loan, LoanState, LoanType} from "../../../store/loans/types";
import {getLoansAction} from "../../../store/loans/actions";

const useLoans = (filteredOutTypes: LoanType[]) => {
   const dispatch = useDispatch();
   const [loans, setLoans] = useState<Loan[]>([]);
   const loanState: LoanState = useSelector<State, LoanState>((state) => state.loans);

   useEffect(() => {
      if (!loanState.hasFetched) {
         dispatch(getLoansAction());
      }
   }, []);

   useEffect(() => {
      if (loanState.hasFetched) {
         setLoans(loanState.loans.filter((loan) => !loan.loanType || !filteredOutTypes.includes(loan.loanType)));
      }
   }, [loanState.loans]);

   return loans;
};

export {useLoans};
