import {call, put, takeLeading} from "redux-saga/effects";
import {FETCH_GLOBAL_SIMULATION, Simulation} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiGet} from "../apiCaller";
import {simulationFetched} from "./simulationSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeLeading(FETCH_GLOBAL_SIMULATION, safe(onDefaultError, handleGetGlobalSimulation));
}

function* handleGetGlobalSimulation() {
   const payload: AxiosResponse<Simulation> = yield call(apiGet, `wealth/api/simulation/global`);
   yield put(simulationFetched(payload.data));
}
