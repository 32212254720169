import { FunctionComponent, SyntheticEvent } from "react";
import { FormattedMessage } from "react-intl";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGMenu, SGMenuItem } from "sg-menu";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { MenuItem } from "website/components/hooks/useMenu";

interface MenuHorizontalPhoneProps {
  menuItems: MenuItem[];
  menuCourant?: MenuItem;
  onChangeMenu: (key: string) => void;
}

export interface MenuEvent {
  domEvent: SyntheticEvent;
  key: string;
}

const MenuHorizontalPhone: FunctionComponent<MenuHorizontalPhoneProps> = ({
  menuCourant,
  menuItems,
  onChangeMenu,
}) => (
  <SGMenu
    mode="horizontal"
    selectedKeys={menuCourant ? [menuCourant.key] : []}
    onClick={({ key }: MenuEvent) => onChangeMenu(key)}
  >
    {menuItems.map(
      ({
        key,
        disponibiliteParcours,
        icone,
        showDividerMobile,
        showTextMobile,
      }) => (
        <SGMenuItem key={key} disabled={disponibiliteParcours === false}>
          <SGBox margin={showDividerMobile !== false ? { top: "xs" } : {}}>
            <SGGridRow gutter={[0, 0]} align="middle">
              {showDividerMobile !== false && (
                <SGGridCol flex="0 0 0%" align="center">
                  <SGBox margin={{ right: "sm" }}>
                    <SGDivider type="vertical" width={3} />
                  </SGBox>
                </SGGridCol>
              )}
              <SGGridCol flex="1 0 0%" textalign="center" align="center">
                <SGBox padding={{ left: "sm" }}>
                  <SGIcon
                    size={showTextMobile !== false ? "m" : "l"}
                    component={icone}
                  />
                </SGBox>
                {showTextMobile !== false && (
                  <SGText key="text" weight="600" size="xs" whitespace="nowrap">
                    <FormattedMessage id={`header.menu.${key}.titre`} />
                  </SGText>
                )}
              </SGGridCol>
            </SGGridRow>
          </SGBox>
        </SGMenuItem>
      )
    )}
  </SGMenu>
);
export { MenuHorizontalPhone };
