import { ReactComponent as LinkImage } from "assets/images/link-image-black.svg";
import { FunctionComponent, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { TypeMedia, getLienArticle } from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { ArticleContacterCard } from "website/components/molecules/Article/ArticleContacterCard/ArticleContacterCard";
import { ArticleInformationCard } from "website/components/molecules/Article/ArticleInformationCard/ArticleInformationCard";
import { LegendImage } from "website/components/molecules/LegendImage/LegendImage";
import { externalURL } from "website/utils/externalUrl";
import { ArticleCadreGris } from "../ArticleCadreGris/ArticleCadreGris";
import { ArticleDisclaimer } from "../ArticleDisclaimer/ArticleDisclaimer";
import { ArticleModal } from "../ArticleModal/ArticleModal";
import { Contenu, ContenuType } from "../ArticleModel";
import { ArticleTableau } from "../ArticleTableau/ArticleTableau";
import { ArticleTableauFemmeExploitantAgricole } from "../ArticleTableauFemmeExploitantAgricole/ArticleTableauFemmeExploitantAgricole";
import { ArticleVideo } from "../ArticleVideo/ArticleVideo";
import "./ArticleContenu.scss";
import { useHistory } from "react-router";
import { PARCOURS_RIS } from "website/utils/privateRoutes";
import { externalTel, transformTel } from "website/utils/externalTel";
import { externalMail } from "website/utils/externalMail";

interface ArticleContenuProps {
    contenu: Contenu;
    setSelectedAnchor?: (legend: string) => void;
    selectedAnchor?: string;
    articlePath?: any;
    type: TypeMedia;
}

/**
 * Composant permettant la gestion des types d'un contenu.
 * et renvoie un composant en fonction de ce type
 *
 * @param props contenu de l'article du .json
 * @returns un composant en fonction du type du contenu en entrée
 */
export const ArticleContenu: FunctionComponent<ArticleContenuProps> = (props: ArticleContenuProps) => {
    const { contenu, setSelectedAnchor, selectedAnchor, articlePath, type } = props;
    const [showArticleModal, setShowArticleModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const { trackClick } = useTracking();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const contenuTexte = "contenu.texte";

    const openLink = useOpenLink();

    /**
     * Permet de réinitialiser le selectedAnchor
     * si selectedAnchor est égal au chevron sélectionné
     * @param id la valeur du chevron sélectionné
     */
    const onChangeInitAnchor = (id: string) => {
        if (setSelectedAnchor && selectedAnchor) {
            setSelectedAnchor(selectedAnchor === id ? "" : selectedAnchor);
        }
    };

    function onClickImage(idTracking: string) {
        if (isPhone && idTracking) {
            trackClick(`clic-sur-${idTracking}`);
        }
        setShowImageModal(isPhone);
    }

    function onClickLink(event: SyntheticEvent, blank: boolean, link: string, linkTracking: string) {
        if (articlePath) {
            trackClick(`clic-sur-${parseIdTracking(linkTracking)}`);
        }
        if (blank) {
            openLink(link, event);
        }
    }

    function parseIdTracking(link: string): string {
        // External
        if (link.startsWith("EXTERNAL_URL_") && externalURL[link]) {
            return link.replace("EXTERNAL_URL_", "").replace(/_/g, "-").toLocaleLowerCase();
        }

        return link.slice(1 + link.lastIndexOf("/"));
    }

    function parseLink(word: string): JSX.Element {
        const split = word[0].split("#;#");
        const label = split.length === 1 ? word : split[1];
        const link = split.length === 1 ? contenu.links[0] : contenu.links[parseInt(split[0])];

        // Lien inactif/futur
        if (link === "") {
            return <span>{label}</span>;
        }

        // External
        if (link.startsWith("EXTERNAL_URL_") && externalURL[link]) {
            return makeLink(label, externalURL[link], link, true, "external");
        }
        // Internal
        if (link.startsWith("#") || link === "") {
            return makeLink(label, link, link, false, "internal");
        }

        return makeLink(label, `#${getLienArticle(link)}`, link, false, "internal");
    }

    function parseMail(word: string): JSX.Element {
        const mail = externalMail[word[0]]

        return <SGLink href={`mailto:${mail}`}>{mail}</SGLink>
    }

    function parseTelephone(word: string): string {
        return transformTel(externalTel[word[0]]);
    }

    function makeLink(word: string, link: string, linkTracking: string, blank = false, className?: string): JSX.Element {
        return (
            <SGLink
                key="lien"
                className={`article-contenu__link article-contenu__link-${className}`}
                href={link}
                onClick={(event: SyntheticEvent) => onClickLink(event, blank, link, linkTracking)}
                iconside="right"
                icon={
                    blank && (
                        <SGIcon
                            component={<LinkImage className="article-contenu__link-image" />}
                            align="text-bottom"
                            disableautomargin
                            currentcolor
                            size="xxs"
                        />
                    )
                }
            >
                {word}
            </SGLink>
        );
    }

    const getTitle = (level: number, visuallevel: number, title: string, marginBottom: string, marginTop?: string) => (
        <SGGridCol span={12}>
            <SGBox margin={{ bottom: marginBottom, top: marginTop }}>
                <SGTitle level={level} visuallevel={visuallevel} textalign="left" id={contenu.id}>
                    {getFormattedText(contenuTexte, title)}
                </SGTitle>
            </SGBox>
        </SGGridCol>
    );

    const getFormattedText = (id : string, text : string) =>
        <FormattedMessage
            id={id}
            defaultMessage={text}
            values={{
                p: (word: string) => <p>{word}</p>,
                ul: (word: string) => <ul className="article-contenu__liste">{word}</ul>,
                ul4: (word: string) => <ul className="article-contenu__liste-margin4">{word}</ul>,
                ul8: (word: string) => <ul className="article-contenu__liste-margin8">{word}</ul>,
                ul15: (word: string) => <ul className="article-contenu__liste-margin15">{word}</ul>,
                li: (word: string) => <li>{word}</li>,
                b: (word: string) => <strong>{word}</strong>,
                i: (word: string) => <em>{word}</em>,
                sup: (word: string) => <sup>{word}</sup>,
                linebreak: <br />,
                link: parseLink,
                modal: (word: string) => (
                    <>
                        <SGLink
                            className="article-contenu__link"
                            onClick={() => {
                                trackClick(`clic-sur-${contenu.modal.idTracking}`);
                                setShowArticleModal(true);
                            }}
                            href={undefined}
                        >
                            {word}
                        </SGLink>

                        <ArticleModal
                            contenuModal={isPhone && contenu.modal.mobile ? contenu.modal.mobile : contenu.modal.desktop}
                            setShowArticleModal={setShowArticleModal}
                            showArticleModal={showArticleModal}
                            idTracking={contenu.modal.idTracking}
                        />
                    </>
                ),
                // Autres
                orange: (word: string) => <span className="article-contenu__orange">{word}</span>,
                intro: (word: string) => <span style={{ color: "#010035" }}>{word}</span>,
                tel: parseTelephone,
                mail: parseMail,
            }}
    />;

    const getParagraph = () => (
        <>
            {!isPhone && contenu.h2 && getTitle(2, 3, contenu.h2, contenu.h3 ? "xs" : "md")}
            {contenu.h3 && getTitle(3, 4, contenu.h3, "sm", "xs")}
            {contenu.h4 && getTitle(4, 5, contenu.h4, "sm", "xs")}
            {contenu.h5 && getTitle(5, 5, contenu.h5, "sm", "xs")}
            {contenu.text && (
                <SGGridCol span={12} textalign="left">
                    <SGText
                        whitespace="pre-line"
                    >
                        {getFormattedText(contenuTexte, contenu.text)}
                    </SGText>
                </SGGridCol>
            )}
            {contenu.children && (
                <SGGridCol span={12} textalign="left">
                    <SGText>
                        {contenu.children?.map((child) => (
                            <SGGridRow key={`${child.type}_${child.h2}_${child.h3}_${child.text}`}>
                                <ArticleContenu contenu={child} articlePath={articlePath} type={type} />
                            </SGGridRow>
                        ))}
                    </SGText>
                </SGGridCol>
            )}
        </>
    );

    switch (contenu.type) {
        case ContenuType.legend:
            return (
                <SGGridCol span={12}>
                    <LegendImage legend={contenu} setSelectedAnchor={setSelectedAnchor} />
                </SGGridCol>
            );
        case ContenuType.retenir:
            return (
                <SGGridCol span={12}>
                    <ArticleInformationCard contenu={contenu} />
                </SGGridCol>
            );
        case ContenuType.paragraphe:
            return (
                <SGGridCol
                    span={12}
                    key={contenu.h2}
                >
                    <SGBox margin={{top: `${contenu.h2 && !isPhone ? "md" : "xs"}`, bottom: "xs"}}>
                        <SGGridRow>
                            {isPhone && contenu.h2 ? (
                                <SGGridCol span={12} textalign="left" key={`${contenu.id}_${selectedAnchor}`}>
                                    <SGAccordion
                                        disableautomargin
                                        expandIconPosition="right"
                                        defaultActiveKey={contenu.id}
                                        onChange={() => onChangeInitAnchor(contenu.id)}
                                    >
                                        <SGAccordionPanel key={selectedAnchor} header={getTitle(2, 3, contenu.h2, "sm")}>
                                            {getParagraph()}
                                        </SGAccordionPanel>
                                    </SGAccordion>
                                </SGGridCol>
                            ) : (
                                // Desktop ou mobile orphelin (sans h2)
                                getParagraph()
                            )}
                        </SGGridRow>
                    </SGBox>
                </SGGridCol>
            );
        case ContenuType.image:
            return (
                <>
                    <SGGridCol>
                        <img
                            width={802}
                            className="article-contenu__image"
                            alt={contenu?.alt}
                            src={contenu?.id}
                            onClick={() => {
                                onClickImage(contenu?.idTracking);
                            }}
                        />
                    </SGGridCol>
                    {isPhone && showImageModal && (
                        <ArticleModal
                            contenuModal={{ title: "", image: contenu?.id, alt: contenu?.alt }}
                            setShowArticleModal={setShowImageModal}
                            showArticleModal={showImageModal}
                            idTracking={contenu?.idTracking}
                        />
                    )}
                </>
            );
        case ContenuType.basDePage:
            return (
                <>
                    {contenu.informations.map((information) => (
                        <SGGridCol key={information} span={12}>
                            <SGBox margin={{ bottom: "sm" }}>
                                <SGText>
                                    {getFormattedText(contenuTexte, information)}
                                </SGText>
                            </SGBox>
                        </SGGridCol>
                    ))}
                </>
            );
        case ContenuType.contacter:
            return (
                <SGGridCol span={12}>
                    <ArticleContacterCard />
                </SGGridCol>
            );
        case ContenuType.cadreGris:
            return (
                <SGGridCol span={12}>
                    <ArticleCadreGris contenu={contenu} articlePath={articlePath} type={type}/>
                </SGGridCol>
            );
        case ContenuType.chevron:
            return (
                <SGGridCol span={12}>
                    <SGAccordion expandIconPosition="right">
                        <SGAccordionPanel
                            header={
                                <SGSpace disableautomargin direction="vertical">
                                    <SGTitle key="title" level={3} visuallevel={4}>{contenu.h3}</SGTitle>
                                    {contenu.text && <SGText key="text">{contenu.text}</SGText>}
                                </SGSpace>
                            }
                            {...{ id: contenu.id }}
                        >
                            {contenu.children.map((child) => (
                                <SGGridRow key={child.type}>
                                    <ArticleContenu contenu={child} articlePath={articlePath} type={type}/>
                                </SGGridRow>
                            ))}
                        </SGAccordionPanel>
                    </SGAccordion>
                </SGGridCol>
            );
        case ContenuType.parcoursRIS:
            return (
                <>
                    <SGGridCol span={12} align="center">
                        <SGLink href={`#${PARCOURS_RIS}`} type="primary">
                            <FormattedMessage id="home.article.parcoursRIS" />
                        </SGLink>
                    </SGGridCol>
                </>
            );
        case ContenuType.imageTexte:
            return (
                <SGGridRow>
                    <SGGridCol span={contenu?.taille}>
                        <img alt="cover" src={isPhone ? contenu?.idMobile : contenu?.id} />
                    </SGGridCol>
                    <SGGridCol span={12 - contenu?.taille} key={contenu.type}>
                        {contenu.children.map((info) => (
                            <SGGridRow key={info.id}>
                                <ArticleContenu contenu={info} articlePath={articlePath} type={type} />
                            </SGGridRow>
                        ))}
                    </SGGridCol>
                </SGGridRow>
            );
        case ContenuType.row:
            return (
                <SGGridCol span={12}>
                    <SGGridRow {...{ id: contenu.id }}>
                        {contenu.children.map((child) => (
                            <ArticleContenu key={child.type} contenu={child} articlePath={articlePath} type={type}/>
                        ))}
                    </SGGridRow>
                </SGGridCol>
            );
        case ContenuType.col3:
        case ContenuType.col6:
            return (
                <SGGridCol span={parseInt(contenu.type.substr(3))} xs={12}>
                    {contenu.children.map((child) => (
                        <SGGridRow key={child.type}>
                            <ArticleContenu contenu={child} articlePath={articlePath} type={type}/>
                        </SGGridRow>
                    ))}
                </SGGridCol>
            );
        case ContenuType.divider:
            return (
                <SGGridCol span={12}>
                    <SGDivider width={18} align="center" />
                </SGGridCol>
            );
        case ContenuType.tableauFemmeExploitantAgricole:
            return (
                <SGGridCol span={12}>
                    <ArticleTableauFemmeExploitantAgricole />
                </SGGridCol>
            );
        case ContenuType.video:
                return (
                    <SGGridCol span={12}>
                        <ArticleVideo url={contenu.url} cover={contenu.cover} type={type}/>
                    </SGGridCol>
                );
        case ContenuType.disclaimer:
            return (
                <SGGridCol span={12}>
                    <ArticleDisclaimer renvoi={contenu.anchor} />
                </SGGridCol>
            );
        case ContenuType.tableau:
            return (
                 <SGGridCol span={12}>
                    <SGBox margin={{ bottom: "sm" }}>
                        <ArticleTableau contenu={contenu} />
                    </SGBox>
                </SGGridCol>
            );
        default:
            return <></>;
    }
};
