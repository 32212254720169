import { Fragment, FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGModal } from "sg-modal";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import {
  PAGE_CATEGORY_3_FLASH
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { MODIFIER_PROFIL } from "website/utils/privateRoutes";

interface MiseAJourFlashModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onClickValider: () => void;
}

const ID_TRACKING_POPIN = "popin-rappel-modification-informations";

const MiseAJourFlashModal: FunctionComponent<MiseAJourFlashModalProps> = ({
  showModal,
  setShowModal,
  onClickValider,
}) => {
  const { trackPopin, resetTracking } = useTracking();
  const history = useHistory();

  useEffect(() => {
    if (showModal) {
      trackPopin(
        false,
        PAGE_CATEGORY_3_FLASH,
        ID_TRACKING_POPIN
      );
    }
  }, [showModal]);

  const trackClick = (cta: "annuler" | "valider") => {
    trackPopin(
      true,
      PAGE_CATEGORY_3_FLASH,
      `${ID_TRACKING_POPIN}::clic-sur-${cta}`,
      "simulation"
    );
    resetTracking();
  };

  return (
    <Fragment key="miseAJourFlashModal">
      {showModal && (
        <SGModal
          visible
          onCancel={() => {
            trackClick("annuler");
            setShowModal(false);
          }}
        >
          <SGSpace direction="vertical" size="md">
            <SGBox key="box-titre" padding={{ top: "lg" }}>
              <SGTitle level={1} visuallevel={2}>
                <FormattedMessage id="simulateurFlash.modal.miseAJour.titre" />
              </SGTitle>
            </SGBox>
            <SGBox key="box-texte" padding={{ bottom: "sm" }}>
              <SGText size="l">
                <FormattedMessage
                  id="simulateurFlash.modal.miseAJour.texte"
                  values={{
                    b: (word: string) => <SGText strong>{word}</SGText>,
                  }}
                />
              </SGText>
            </SGBox>
            <SGButtonGroup key="buttons" align="center">
              <SGButton
                onClick={() => {
                  trackClick("valider");
                  onClickValider();
                  setShowModal(false);
                }}
                size="sm"
              >
                <FormattedMessage id="simulateurFlash.modal.miseAJour.button.valider" />
              </SGButton>
              <SGButton
                onClick={() => {
                  trackClick("annuler");
                  setShowModal(false);
                  history.push(MODIFIER_PROFIL);
                }}
                type="secondary"
                size="sm"
              >
                <FormattedMessage id="simulateurFlash.modal.miseAJour.button.annuler" />
              </SGButton>
            </SGButtonGroup>
          </SGSpace>
        </SGModal>
      )}
    </Fragment>
  );
};

export { MiseAJourFlashModal };
