import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGText } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGTextIntl } from "../SGTextIntl/SGTextIntl";
import "./DetailTab.scss";

export interface DetailTabProps {
  titleDetailTab: string;
  textDetailTab: string;
  hideListStyle?: boolean;
  transformations?: Record<string, string | React.ReactNode>;
}

const DetailTab: FunctionComponent<DetailTabProps> = (
  props: DetailTabProps
) => {
  const { titleDetailTab, textDetailTab, hideListStyle, transformations } =
    props;
  const intl = useIntl();
  const { trackClick } = useTracking();

  return (
    <SGAccordion expandIconPosition="right" disableAutomargin divider onChange={() => { trackClick("clic-sur-pourquoi-ces-questions"); }}>
      <SGAccordionPanel
        header={
          <SGText titlerender>
            {intl.formatMessage({ id: titleDetailTab })}
          </SGText>
        }
        tile
        legend="34"
      >
        <SGText size="s" style={{ lineHeight: "24px" }}>
          <SGTextIntl
            intlId={textDetailTab}
            transformations={{
              ...transformations,
              ul: (word: string) => (
                <ul
                  className={hideListStyle ? "detail-tab__hide-list-style" : ""}
                >
                  {word}
                </ul>
              ),
              li: (word: string) => (
                <li
                  key={crypto.randomUUID()}
                  className="detail-tab__text-paragraph"
                >
                  {word}
                </li>
              ),
              b: (word: string) => <SGText weight="bold">{word}</SGText>,
              linebreak: <br />,
            }}
          />
        </SGText>
      </SGAccordionPanel>
    </SGAccordion>
  );
};

export { DetailTab };
