import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {SGTitle} from "sg-typo";

interface SGTitleIntlProps {
   intlId: string;
   cypressName: string;
   colorlight?: boolean;
}

const SGTitleIntl: FunctionComponent<SGTitleIntlProps> = (props: SGTitleIntlProps) => {
   const {intlId, colorlight, cypressName} = props;

   const intl = useIntl();

   return (
      <SGTitle data-cy={cypressName} level={3} colorlight={colorlight}>
         {intl.formatMessage({id: intlId})}
      </SGTitle>
   );
};

export {SGTitleIntl};
