import { useEffect, useState } from "react";
import { roundDown10 } from "../../../utils/formatting/numberFormatter";
import { useSelectedRetirementAge } from "./useSelectedRetirementAge";

/**
 * Détermine les montants maximums d'objectif et d'épargne pour la saisie.
 */
const useMontantsMax = () => {
   const { selectedRetirementAge } = useSelectedRetirementAge();

   const [maxObjectifInput, setMaxObjectifInput] = useState<number>(0);
   const [maxEpargneInput, setMaxEpargneInput] = useState<number>(0);

   useEffect(() => {
      if (selectedRetirementAge) {
         // Dernier salaire + 15%
         setMaxObjectifInput(roundDown10(1.15 * selectedRetirementAge.lastMonthlyIncome));
         // Dernier salaire + 15%
         setMaxEpargneInput(roundDown10(1.15 * selectedRetirementAge.lastMonthlyIncome));
      }
   }, [selectedRetirementAge?.lastMonthlyIncome]);

   return { maxObjectifInput, maxEpargneInput };
};

export { useMontantsMax };
