import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGAlert } from "sg-alert";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavCroix, SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGText } from "sg-typo";
import "./BlueCard.scss";
import { SGBox } from "sg-space";

interface BlueCardProps {
    text: string;
    title?: string;
    showCard: boolean;
    setShowCard?: (showCard: boolean) => void;
}

export const BlueCard: FunctionComponent<BlueCardProps> = (props: React.PropsWithChildren<BlueCardProps>) => {
    const { text, showCard, setShowCard, title } = props;

    return (
        <>
            {showCard && (
                <SGBox margin={{ top: "md" }}>
                    <SGAlert
                        type="info"
                        icon={<SGIcon component={<SGAvenirStatusInfo />} size="xs" />}
                        message={title !== undefined && (
                            <FormattedMessage id={title} />
                        )}
                        className="blue-card"
                        description={<>
                            <SGText size="m">
                                <FormattedMessage id={`blue.card.${text}`} />
                            </SGText>
                            <SGMediaQuery minwidth="sm">
                                {setShowCard && (
                                    <SGButton
                                        type="icon"
                                        icon={<SGIcon component={<SGAvenirNavCroix />} />}
                                        onClick={() => setShowCard(!showCard)}
                                    />
                                )}
                            </SGMediaQuery>
                        </>
                        }
                    />
                </SGBox>
            )}
        </>
    );
};
