export interface FeedbackState {
  hasSentFeedback: boolean;
}

export interface Feedback {
  rating: number;
  comment: string;
}

// Actions
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const FETCH_HAS_SENT_FEEDBACK = "FETCH_HAS_SENT_FEEDBACK";
export const HAS_SENT_FEEDBACK_FETCHED = "HAS_SENT_FEEDBACK_FETCHED";
