import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPromotionalOffers } from "store/promotionalOffers/action";
import { initialOffer } from "store/promotionalOffers/promotionalOfferSlice";
import {
   PromotionalOffer,
   PromotionalOfferState,
} from "store/promotionalOffers/types";
import { State } from "store/store";
import { useAccount } from "./useAccount";

const usePromotionalOffers = () => {
  const dispatch = useDispatch();
  const hasFetchedAccount = useSelector<State, boolean>(
    (state) => state.account.hasFetched
  );
  const account = useAccount();
  const promotionalOfferState: PromotionalOfferState = useSelector<
    State,
    PromotionalOfferState
  >((state) => state.promotionalOffers);
  const [offresFetched, setOffresFetched] = useState(false);
  const [offrePER, setOffrePER] = useState<PromotionalOffer>(initialOffer);
  const [offreAvantApres, setOffreAvantApres] =
    useState<PromotionalOffer>(initialOffer);
  const [offreDepartAnticipe, setOffreDepartAnticipe] =
    useState<PromotionalOffer>(initialOffer);
  const [
    notificationQuestionnaireDashboard,
    setNotificationQuestionnaireDashboard,
  ] = useState<PromotionalOffer>(initialOffer);

  /**
   * Permet de récupérer une offre promotionnelle depuis la liste en fonction d'un type
   * @param typeOffer type de l'offre promotionnelle recherchée
   * @param previousOffer offre promotionnelle initiée si l'offre cherchée n'existe pas dans la liste
   * @returns un objet PromotionalOffer
   */
  const getOfferFromList = (
    typeOffer: string,
    previousOffer: PromotionalOffer
  ) => {
    const offer = promotionalOfferState.promotionalOffers.find(
      (promotionalOffer) => promotionalOffer.typeOffer === typeOffer
    );

    return offer ?? previousOffer;
  };

  useEffect(() => {
    if (
      hasFetchedAccount &&
      account.id !== -1 &&
      !promotionalOfferState.hasFetched
    ) {
      dispatch(fetchPromotionalOffers());
    }
  }, [hasFetchedAccount, account]);

  useEffect(() => {
    if (promotionalOfferState.hasFetched) {
      setOffresFetched(true);
      setOffrePER(getOfferFromList("PER", initialOffer));
      setOffreAvantApres(getOfferFromList("AVANT_APRES", initialOffer));
      setOffreDepartAnticipe(getOfferFromList("DEPART_ANTICIPE", initialOffer));
      setNotificationQuestionnaireDashboard(
        getOfferFromList("QUESTIONNAIRE_DASHBOARD", initialOffer)
      );
    }
  }, [promotionalOfferState]);

  return {
    offresFetched,
    offrePER,
    offreAvantApres,
    offreDepartAnticipe,
    notificationQuestionnaireDashboard,
  };
};

export { usePromotionalOffers };
