import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AssetPercentages, AssetPercentagesState } from "store/assetPercentages/types";
import { AssetState } from "store/assets/types";
import { State } from "../../../store/store";

const useAssetPercentages = () => {
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
   const assetPercentagesState: AssetPercentagesState = useSelector<State, AssetPercentagesState>((state) => state.assetPercentages);
   const [assetPercentages, setAssetPercentages] = useState<AssetPercentages>(assetPercentagesState.assetPercentages);

   const {assets} = assetState;

   useEffect(() => {
      if (assetState.hasFetched) {
         const assetPercentages: AssetPercentages = {};
         assets.forEach((asset) => {
            if (asset.id) {
               assetPercentages[asset.id] = asset.percentageUsedForRetirement;
            }
         });
         setAssetPercentages(assetPercentages);
      }
   }, [assetState]);

   return assetPercentages;
};

export { useAssetPercentages };
