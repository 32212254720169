import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGButtonGroup } from "sg-button";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStyledTimer } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGTag } from "sg-tag";
import { SGText, SGTitle } from "sg-typo";
import { savePromotionalOffer } from "store/promotionalOffers/action";
import { PromotionalOffer } from "store/promotionalOffers/types";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { Image } from "website/components/atoms/Image/Image";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useFeatures } from "website/components/hooks/useFeatures";
import { usePromotionalOffers } from "website/components/hooks/usePromotionalOffers";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import "./OffrePERModal.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface OffrePERModalProps {
    visible: boolean;
    setVisible: (showParcoursRISModal: boolean) => void;
}

const OffrePERModal: FunctionComponent<OffrePERModalProps> = (props: OffrePERModalProps) => {
    const { visible, setVisible } = props;
    const intl = useIntl();
    const { offrePER, offresFetched } = usePromotionalOffers();
    const [initSaveOffre, setInitSaveOffer] = useState<boolean>(false);
    const dispatch = useDispatch();
    const openSGAgenda = useSGAgenda();
    const {disponibiliteSGAgenda} = useFeatures();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const { trackPopin, resetTracking } = useTracking();

    const tabPER = [
        {
            name: "commencer",
            weight: "500",
            size: "15px",
            lineHeight: "18px",
        },
        {
            name: "pourcentage",
            weight: "700",
            size: "31px",
            fontFamily: "Montserrat",
        },
        {
            name: "frais",
            weight: "500",
            size: "15px",
            lineHeight: "6px",
        },
    ];

    useEffect(() => {
        if (visible) {
            trackPopin(false, "campagne-promo", "per-sans-frais-de-versement");
        }
    }, [visible]);

    /**
     * Si la popin est vu pour la première fois, max est défini à 1
     */
    useEffect(() => {
        if (offresFetched && !initSaveOffre) {
            setInitSaveOffer(true);
            const promotionalOffer: PromotionalOffer = {
                ...offrePER,
                lastOfferDate: new Date(),
                viewCountOffer: incrementViewCountOffer(offrePER.viewCountOffer),
            };
            dispatch(savePromotionalOffer(promotionalOffer));
        }
    }, [offresFetched]);

    const incrementViewCountOffer = (viewCountOffer: number) => (viewCountOffer < 2 ? viewCountOffer + 1 : viewCountOffer);

    const acceptOffrePERModalButton = () => {
        trackPopin(true, "campagne-promo", "per-sans-frais-de-versement::clic-sur-prendre-rendez-vous","" ,"", PAGE_TYPE_FORMULAIRE,{form_field_2: disponibiliteSGAgenda ? "active" : "non-active"});
        const promotionalOffer: PromotionalOffer = {
            ...offrePER,
            acceptOfferDate: new Date(),
        };

        dispatch(savePromotionalOffer(promotionalOffer));
        // FIXME : Déporter dans BoutonSGAgenda
        if (disponibiliteSGAgenda) {
            openSGAgenda();
        } else {
           // SGAgenda désactivé, on affiche un message d'erreur
           setShowErrorModal(true);
        }
    };

    const onCancel = () => {
        trackPopin(true, "campagne-promo", "per-sans-frais-de-versement::clic-sur-croix-fermeture");
        resetTracking();
        setVisible(false);
    };

    return (
        <>
            <SGModal className="offre-per-modal__modal" visible={visible} centered closable onCancel={() => onCancel()}>
                <SGScroll>
                    <SGMediaQuery minwidth="sm" maxwidth="xl">
                        <SGGridRow>
                            <SGGridCol span={12} align="start">
                                <SGTitle caps level={2}>
                                    {intl.formatMessage({ id: `offrePER.modal.title` })}
                                </SGTitle>
                            </SGGridCol>
                            <SGGridCol offset={6}>
                                <SGDivider width={4} disableautomargin className="offre-per-modal__divider" />
                            </SGGridCol>
                            <SGGridCol>
                                <SGGridRow className="offre-per-modal__container-haut">
                                    <SGGridCol span={12}>
                                        <Image className="offre-per-modal__image" src="images/dashboard/offre-per-modal-desktop.svg" alt="offrePERModal" />
                                    </SGGridCol>
                                    <SGGridCol className="offre-per-modal__container-absolute">
                                        <SGTag tagcolor="20">
                                            <SGText className="offre-per-modal__tag">{intl.formatMessage({ id: `offrePER.modal.offre` })}</SGText>
                                        </SGTag>
                                    </SGGridCol>
                                </SGGridRow>
                            </SGGridCol>
                        </SGGridRow>
                    </SGMediaQuery>
                    <SGMediaQuery minwidth={0} maxwidth="xs">
                        <SGGridRow className="offre-per-modal__container-haut">
                            <SGGridCol>
                                <Image className="offre-per-modal__image" src="images/dashboard/offre-per-modal.svg" alt="offrePERModal" />
                            </SGGridCol>
                            <SGGridCol className="offre-per-modal__container-absolute">
                                <SGGridRow>
                                    <SGGridCol>
                                        <SGTag tagcolor="20">
                                            <SGText className="offre-per-modal__tag">{intl.formatMessage({ id: `offrePER.modal.offre` })}</SGText>
                                        </SGTag>
                                    </SGGridCol>
                                    <SGGridCol style={{ marginTop: "110px" }} span={8} align="start">
                                        <SGTitle caps level={2} style={{ color: "#FFFFFF" }}>
                                            {intl.formatMessage({ id: `offrePER.modal.title` })}
                                        </SGTitle>
                                    </SGGridCol>
                                    <SGGridCol span={12} textalign="center">
                                        <SGDivider width={3} disableautomargin className="offre-per-modal__divider" />
                                    </SGGridCol>
                                </SGGridRow>
                            </SGGridCol>
                        </SGGridRow>
                    </SGMediaQuery>
                    <SGGridRow gutter={[0, 10]} justify="center" className="offre-per-modal__container-contenu">
                        <SGGridCol span={12}>
                            <SGGridRow>
                                <SGGridCol span={12}>
                                    <SGGridRow align="middle" justify="center" className="offre-per-modal__icon">
                                        <SGGridCol span={12} textalign="center">
                                            <SGIcon component={<SGAvenirStyledTimer />} />
                                            <SGText strong style={{ color: "#E2010B" }}>
                                                {intl.formatMessage({ id: `offrePER.modal.moment` })}
                                            </SGText>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGGridCol>
                                {tabPER.map((per) => (
                                    <SGGridCol span={12} key={per.name} textalign="center">
                                        <SGText
                                            weight={per.weight}
                                            style={{ fontFamily: per.fontFamily, color: "#010035", fontSize: per.size, lineHeight: per.lineHeight }}
                                        >
                                            {intl.formatMessage(
                                                { id: `offrePER.modal.${per.name}` },
                                                {
                                                    pourcent: (word: string) => <label style={{ fontSize: "15px" }}>{word}</label>,
                                                    sup: (word: string) => <sup>{word}</sup>,
                                                }
                                            )}
                                        </SGText>
                                    </SGGridCol>
                                ))}
                            </SGGridRow>
                        </SGGridCol>
                        <SGButtonGroup align="center">
                            <BoutonSGAgenda onClick={acceptOffrePERModalButton}>
                                {intl.formatMessage({ id: `offrePER.modal.button` })}
                            </BoutonSGAgenda>
                        </SGButtonGroup>
                    </SGGridRow>
                    <SGGridRow gutter={[0, 10]} justify="center" className="offre-per-modal__bas-de-page">
                        <SGGridCol sm={8} xs={12} textalign="left">
                            <SGText size="xs" style={{ color: "#636C6E", lineHeight: "10px" }}>
                                {intl.formatMessage({ id: `offrePER.modal.basDePage` })}
                            </SGText>
                        </SGGridCol>
                    </SGGridRow>
                </SGScroll>
            </SGModal>
            {showErrorModal && (
                <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} description="modal.error.message.indisponible" />
            )}
        </>
    );
};

export { OffrePERModal };
