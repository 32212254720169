import { Reducer } from "react";
import { CONTINUE_PARCOURS_REVENUS_COMPLEMENTAIRES, NEXT_STEP_REVENUS_COMPLEMENTAIRES, PREVIOUS_STEP_REVENUS_COMPLEMENTAIRES, RESET_PARCOURS_REVENUS_COMPLEMENTAIRES, STOP_PARCOURS_REVENUS_COMPLEMENTAIRES, ParcoursRevenusComplementairesState, GO_TO_STEP_PARCOURS_REVENUS_COMPLEMENTAIRES } from "./types";

export const ParcoursRevenusComplementairesReducer: Reducer<ParcoursRevenusComplementairesState, any> = (currentState = {} as ParcoursRevenusComplementairesState, action): ParcoursRevenusComplementairesState => {
   switch (action.type) {
      case RESET_PARCOURS_REVENUS_COMPLEMENTAIRES:
         return {
            currentStep: 0,
            stopParcours: false,
         };
      case NEXT_STEP_REVENUS_COMPLEMENTAIRES:
         return {
            currentStep: currentState.currentStep + 1,
            stopParcours: false,
         };
      case PREVIOUS_STEP_REVENUS_COMPLEMENTAIRES:
         return {
            currentStep: currentState.currentStep - 1,
            stopParcours: false,
         };
      case STOP_PARCOURS_REVENUS_COMPLEMENTAIRES:
         return {
            currentStep: currentState.currentStep,
            stopParcours: true,
         };
      case CONTINUE_PARCOURS_REVENUS_COMPLEMENTAIRES:
         return {
            currentStep: currentState.currentStep,
            stopParcours: false,
         };
      case GO_TO_STEP_PARCOURS_REVENUS_COMPLEMENTAIRES:
         return {
            currentStep: action.payload,
            stopParcours: false,
         };
      default:
         return currentState;
   }
};
