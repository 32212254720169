import React, { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGBox } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { Contenu } from "../ArticleModel";

interface ArticleInformationCardProps {
    contenu: Contenu;
}
/**
 * @param props contenu de type "retenir"
 * @returns Renvoie un encadré gris contenant les informations "à retenir" de l'article
 */
export const ArticleInformationCard: FunctionComponent<ArticleInformationCardProps> = (props: ArticleInformationCardProps) => {
    const intl = useIntl();
    const { contenu } = props;

    return (
        <SGBlock type="texthighlight">
            <SGGridRow>
                <SGGridCol span={12} textalign="left">
                    <SGBox margin={{ bottom: "xs" }}>
                        <SGTitle level={3} caps textalign="left">
                            {intl.formatMessage({ id: "home.article.retenir" })}
                        </SGTitle>
                    </SGBox>
                </SGGridCol>
            </SGGridRow>
            <SGGridRow justify="start">
                {contenu.informations.map((information, index) => (
                    <React.Fragment key={information}>
                        <SGGridCol span={2} sm={1}>
                            <SGText size="xxl" titlerender>0{index + 1}</SGText>
                        </SGGridCol>
                        <SGGridCol span={9} sm={10}>
                            <SGBox margin={{top: "xxs"}}>
                                <SGText>
                                    <FormattedMessage
                                        id="information"
                                        defaultMessage={information}
                                        values={{
                                            b: (word: string) => <strong>{word}</strong>,
                                        }}
                                    />
                                </SGText>
                            </SGBox>
                        </SGGridCol>
                        <SGGridCol span={1} />
                    </React.Fragment>
                ))}
            </SGGridRow>
        </SGBlock>
    );
};
