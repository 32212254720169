import { ReactComponent as Check } from "assets/images/green-check.svg";
import { ReactComponent as LogoCross } from "assets/images/redcross.svg";
import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { Loader } from "website/components/atoms/Loader/Loader";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { ServiceSapiendoModel } from "../../organisms/MesServices/ServiceModel";
import { InformationSapiendoCard } from "../InformationSapiendoCard/InformationSapiendoCard";
import { InfosServiceSapiendoCard } from "../InfosServiceSapiendoCard/InfosServiceSapiendoCard";
import "./ServiceSapiendoModal.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export interface ServiceSapiendoModalProps {
    selectedService: ServiceSapiendoModel;
    showModalInfo?: boolean;
    setShowModalInfo: (show: boolean) => void;
    handleOpenModal: () => void;
    trackKey?: string;
}

export const ServiceSapiendoModal: FunctionComponent<ServiceSapiendoModalProps> = (props: React.PropsWithChildren<ServiceSapiendoModalProps>) => {
    const { selectedService, showModalInfo, setShowModalInfo, handleOpenModal, trackKey } = props;
    const intl = useIntl();
    const openLink = useOpenLink();
    const { trackPopin, resetTracking } = useTracking();

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        trackPopin(false, "mes-services", `sapiendo-l-expert-retraite::popin-${trackKey}`);
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
        }, 700);
    }, []);

    const handleOnClose = () => {
        resetTracking();
        setShowModalInfo(false);
    }

    const getServiceContent = () =>
        <SGSpace direction="vertical" disableautomargin>
            {selectedService.content.map((item) => (
                <SGGridRow gutter={[8]} key={item}>
                    <SGGridCol>
                        <Check />
                    </SGGridCol>
                    <SGGridCol textalign="left" span={11}>
                        <SGText size="m">
                            <FormattedMessage
                                id="paragraph"
                                defaultMessage={item}
                                values={{
                                    linebreak: <br />,
                                    b: (word: string) => <strong>{word}</strong>,
                                    li: (word: string) => <li>{word}</li>,
                                    ul4: (word: string) => (
                                        <ul className="service-sapiendo-modal__liste-padding4 service-sapiendo-modal__liste-margin-top0">{word}</ul>
                                    ),
                                    linkFile: (word: string) => {
                                        const link = "https://www.sapiendo-retraite.fr/modules/sapiendo/documents/Bilan_retraite.pdf";

                                        return (
                                            <SGLink onClick={(event: SyntheticEvent) => openLink(link, event)} href={link}>
                                                {word}
                                            </SGLink>
                                        );
                                    },
                                }}
                            />
                        </SGText>
                    </SGGridCol>
                </SGGridRow>
            ))}
        </SGSpace>

    return (
        <SGModal
            className="service-sapiendo-modal__container child-scrollable"
            closable
            visible={showModalInfo}
            centered
            onCancel={handleOnClose}
            closeIcon={<LogoCross />}
        >
            {showLoader ? (
                <Loader />
            ) : (
                <SGScroll>
                    <SGBox margin={{ bottom: "sm" }}>
                        <SGGridRow gutter={[0, 20]}>
                            <SGGridCol span={12}>
                                <SGGridRow>
                                    <SGGridCol align="start">
                                        <SGText
                                            className="service-sapiendo-modal__header-title"
                                            strong
                                            titlerender
                                            style={{
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            <FormattedMessage
                                                id="selectedService.title"
                                                defaultMessage={selectedService.title}
                                                values={{
                                                    linebreak: <br />,
                                                    red: (word: string) => <span style={{ color: "#DC0117" }}>{word}</span>,
                                                }}
                                            />
                                        </SGText>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGGridRow justify="center">
                                            <div className="service-sapiendo-modal__red-underline" />
                                        </SGGridRow>
                                    </SGGridCol>
                                </SGGridRow>
                            </SGGridCol>
                            <SGGridCol>
                                <SGText size="xl" weight="bolder" color="error">
                                    {intl.formatMessage({ id: "mesServices.sapiendo.modal.subtitle" })}
                                </SGText>
                            </SGGridCol>
                        </SGGridRow>
                    </SGBox>
                    <SGGridRow gutter={[0, 12]}>
                        <SGMediaQuery minwidth="xl">
                            <SGGridCol span={12} align="start">
                                <SGText style={{ color: "#000000" }} weight="600" size="m">
                                    {intl.formatMessage({ id: "mesServices.sapiendo.modal.content.title" })}
                                </SGText>
                            </SGGridCol>
                            <SGGridCol span={12}>{getServiceContent()}</SGGridCol>
                        </SGMediaQuery>
                        <SGMediaQuery minwidth={0} maxwidth="lg">
                            <SGGridCol span={12}>
                                <SGAccordion accordion={false} expandIconPosition="right" divider={false} >
                                    <SGAccordionPanel
                                        header={intl.formatMessage({ id: "mesServices.sapiendo.modal.content.title.mobile" })}
                                    >
                                        {getServiceContent()}
                                    </SGAccordionPanel>
                                    <SGAccordionPanel header={intl.formatMessage({ id: "mesServices.sapiendo.modal.raisons.title" })}>
                                        <InfosServiceSapiendoCard content={selectedService.reasons} />
                                    </SGAccordionPanel>
                                    {selectedService.promotionalInsert && (
                                        <SGAccordionPanel header={selectedService.promotionalInsert.title}>
                                            <InfosServiceSapiendoCard check content={selectedService.promotionalInsert.content} />
                                        </SGAccordionPanel>
                                    )}
                                </SGAccordion>
                            </SGGridCol>
                        </SGMediaQuery>
                    </SGGridRow>
                    {selectedService.details && (
                        <SGBox margin={{ top: "md" }}>
                            <SGGridRow justify="start" gutter={[0, 18]}>
                                {selectedService.details.map((contenu) => (
                                    <SGGridCol span={12} align="start">
                                        <SGText size="m" style={{ color: "#000000" }}>
                                            <FormattedMessage
                                                id="contenu"
                                                defaultMessage={contenu}
                                                values={{
                                                    linebreak: <br />,
                                                    li: (word: string) => <li>{word}</li>,
                                                    ul4: (word: string) => <ul className="service-sapiendo-modal__liste-padding4">{word}</ul>,
                                                    b: (word: string) => <strong>{word}</strong>,
                                                    p: (word: string) => <p>{word}</p>,
                                                }}
                                            />
                                        </SGText>
                                    </SGGridCol>
                                ))}
                            </SGGridRow>
                        </SGBox>
                    )}
                    <SGBox margin={{ top: "md" }}>
                        <SGGridRow>
                            <SGGridCol>
                                <SGMediaQuery minwidth="xl">
                                    <InfosServiceSapiendoCard
                                        title={intl.formatMessage({ id: "mesServices.sapiendo.modal.raisons.title" })}
                                        content={selectedService.reasons}
                                    />
                                </SGMediaQuery>
                                <InformationSapiendoCard selectedService={selectedService} trackKey={trackKey} />
                                {selectedService.promotionalInsert && (
                                    <>
                                        <SGMediaQuery minwidth="xl">
                                            <InfosServiceSapiendoCard
                                                title={selectedService.promotionalInsert.title}
                                                check
                                                content={selectedService.promotionalInsert.content}
                                                textButton={selectedService.promotionalInsert.button}
                                                onClickButton={handleOpenModal}
                                            />
                                        </SGMediaQuery>
                                        <SGMediaQuery minwidth={0} maxwidth="lg">
                                            <SGGridCol span={12} textalign="right">
                                                <SGButton
                                                    type="primarylink"
                                                    onClick={handleOpenModal}
                                                    iconposition="right"
                                                    icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                                                >
                                                    <FormattedMessage id="textButton" defaultMessage={selectedService.promotionalInsert.button} />
                                                </SGButton>
                                            </SGGridCol>
                                        </SGMediaQuery>
                                    </>
                                )}
                            </SGGridCol>
                        </SGGridRow>
                    </SGBox>
                </SGScroll>
            )}
        </SGModal>
    );
};
