import {PayloadAction} from "@reduxjs/toolkit";

export const insertElement = <T>(elements: T[], elt: T) => [...elements, elt];

export const updateElement = <T extends { id: number }>(elements: T[], updated: T) => elements.map((elt: T) => elt.id === updated.id ? updated : elt);

export function removeElement<T extends { id: number }>(elements: T[], deletedElementId: string) {
   const newElements = elements.slice();
   const index = newElements
   .map((elt: T) => elt.id)
   .indexOf(parseInt(deletedElementId));
   newElements.splice(index, 1);

   return newElements;
}

export const makeAction = <T>(type: string, payload: T): PayloadAction<T> => ({type, payload});

export const storeDeepEquals = <T>(oldElement: T, newElement: T) => JSON.stringify(oldElement) === JSON.stringify(newElement);
