import { AccessibilitePage } from "website/pages/AccessibilitePage";
import { ForbiddenPage } from "website/pages/ForbiddenPage";
import { CGUPage } from "../pages/CGUPage";
import { LoginPage } from "../pages/LoginPage";
import { MaintenancePage } from "../pages/MaintenancePage";
import { PasswordResetFinishPage } from "../pages/PasswordResetFinishPage";
import { PasswordResetInitPage } from "../pages/PasswordResetInitPage";
import { SignupPage } from "../pages/SignupPage";
import { Authentication } from "./auth/Authentication";
import { IntrouvablePage } from "../pages/IntrouvablePage";

export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const PASSWORD_RESET_INIT = "/password/reset/init";
export const PASSWORD_RESET_FINISH = "/password/reset/finish";
export const CGU = "/cgu";
export const AUTHENTICATION = "/auth";
export const FORBIDDEN = "/acces-interdit";
export const MAINTENANCE = "/maintenance";
/** TODO A SUPPRIMER */
export const INFORMATION_FOR_RETIRED = "/information-for-retired";
export const ACCESSIBILITE = "/accessibilite";
export const INTROUVABLE = "/introuvable";

export const publicRoutes = [
   { path: LOGIN, component: LoginPage, hiddenWhenLoginDisabled: true },
   { path: SIGNUP, component: SignupPage, hiddenWhenLoginDisabled: true },
   { path: PASSWORD_RESET_INIT, component: PasswordResetInitPage, hiddenWhenLoginDisabled: true },
   { path: PASSWORD_RESET_FINISH, component: PasswordResetFinishPage, hiddenWhenLoginDisabled: true },
   { path: CGU, component: CGUPage, hiddenWhenLoginDisabled: false },
   { path: AUTHENTICATION, component: Authentication, hiddenWhenLoginDisabled: false },
   { path: FORBIDDEN, component: ForbiddenPage, hiddenWhenLoginDisabled: false },
   { path: MAINTENANCE, component: MaintenancePage, hiddenWhenLoginDisabled: false },
   { path: ACCESSIBILITE, component: AccessibilitePage, hiddenWhenLoginDisabled: false },
   { path: INTROUVABLE, component: IntrouvablePage, hiddenWhenLoginDisabled: false },
];
