import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { AccountData } from "store/account/types";
import { State } from "store/store";
import { capitalize } from "website/utils/formatting/stringUtils";

const SectionBienvenue: FunctionComponent = () => {
  const account: AccountData = useSelector<State, AccountData>(
    (state) => state.account.account
  );

  const civilite =
    account?.title && account?.title !== "" ? account.title : "M.";

  return (
    <SGSpace direction="vertical" size="sm" disableautomargin>
      <SGText key="titre" titlerender size="xxl">
        Bonjour {civilite} {capitalize(account?.lastName)}
      </SGText>
      <SGText key="text" size="l">
        Bienvenue sur Mon Compagnon Retraite,{" "}
        <SGText weight="600">
          votre guide pour préparer votre future retraite.
        </SGText>
      </SGText>
    </SGSpace>
  );
};

export { SectionBienvenue };
