import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Loan, LoanState} from "./types";
import {insertElement, removeElement, updateElement} from "../utils";

const initialState: LoanState = {loans: new Array<Loan>(), loansImported: new Array<Loan>(), hasFetched: false};

export const loansSlice = createSlice({
   name: "loans",
   initialState,
   reducers: {
      loanUpdated: (currentState, action: PayloadAction<Loan>) => ({
         ...currentState,
         loans: updateElement(currentState.loans, action.payload)
      }),
      loanDeleted: (currentState, action: PayloadAction<Loan>) => ({
         ...currentState,
         loans: removeElement(currentState.loans, String(action.payload.id))
      }),
      loansFetched: (currentState, action: PayloadAction<Loan[]>) => ({...currentState, loans: action.payload, hasFetched: true}),
      loansImportedFetched: (currentState, action: PayloadAction<Loan[]>) => ({
         ...currentState,
         loansImported: action.payload
      })
   }
});

export const { loanUpdated, loanDeleted, loansFetched, loansImportedFetched} = loansSlice.actions;

export default loansSlice.reducer;
