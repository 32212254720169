import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import {
   AssetPercentages,
   AssetPercentagesState
} from "./types";

export const AssetPercentagesReducer: Reducer<AssetPercentagesState, PayloadAction<AssetPercentages>> = (
   currentState: any = {}
): AssetPercentagesState => currentState;
