import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet, apiPost } from "store/apiCaller";
import { onDefaultError, safe } from "store/error/utils";
import { FETCH_WORK_INCOMES_HISTORY, SAVE_WORK_INCOMES_HISTORY, WorkIncomesHistory, WORK_INCOMES_HISTORY_FETCHED, WORK_INCOMES_HISTORY_SAVED } from "./types";

export default function* watcherSaga() {
   yield takeLeading(FETCH_WORK_INCOMES_HISTORY, safe(onDefaultError, handleFetchWorkIncomesHistory));
   yield takeLeading(SAVE_WORK_INCOMES_HISTORY, safe(onDefaultError, handleSaveWorkIncomesHistory));
}

function* handleFetchWorkIncomesHistory() {
   const payload: AxiosResponse<WorkIncomesHistory> = yield call(apiGet, `wealth/api/work-incomes-history`);
   yield put({ type: WORK_INCOMES_HISTORY_FETCHED, payload: payload.data });
}

function* handleSaveWorkIncomesHistory(action: any) {
   const payload: AxiosResponse<WorkIncomesHistory> = yield call(apiPost, `wealth/api/work-incomes-history`, action.payload);
   yield put({ type: WORK_INCOMES_HISTORY_SAVED, payload: payload.data });
}
