import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NewProject, NewProjectState} from "./types";
import {ProjectCategory, ProjectType} from "../projects/types";

const addNewProjectFields = (newProject: NewProject): NewProject => {
   let title = "";
   let description = "";
   let url = "";
   let category = "";
   switch (newProject.projectType) {
      case ProjectType.REAL_ESTATE_PRIMARY: {
         title = "new.project.title.real.estate.primary";
         description = "new.project.description.real.estate.primary";
         url = "/real-estate-project";
         category = ProjectCategory.REAL_ESTATE_OR_CONSTRUCTION;
         break;
      }
      case ProjectType.RETIREMENT_PROJECT: {
         title = "new.project.title.retirement";
         description = "new.project.description.retirement";
         url = "/retirement-project";
         category = ProjectCategory.LIFE_PROJECT;
         break;
      }
      case ProjectType.CONSTRUCTION_WORK: {
         title = "new.project.title.construction.work";
         description = "new.project.description.construction.work";
         url = "/construction-work";
         category = ProjectCategory.REAL_ESTATE_OR_CONSTRUCTION;
         break;
      }
      case ProjectType.EXCEPTIONAL_EXPENSE: {
         title = "new.project.title.exceptional.expense";
         description = "new.project.description.exceptional.expense";
         url = "/exceptional-expense-project";
         category = ProjectCategory.OTHER;
         break;
      }
      case ProjectType.WEDDING: {
         title = "new.project.title.wedding";
         description = "new.project.description.wedding";
         url = "/wedding-project";
         category = ProjectCategory.LIFE_PROJECT;
         break;
      }
      case ProjectType.CAPITAL_GAIN: {
         title = "new.project.title.capital.gain";
         description = "new.project.description.capital.gain";
         url = "/capital-gain-project";
         category = ProjectCategory.OPTIMIZE_PROJECT;
         break;
      }
      case ProjectType.ADDITIONAL_INCOME: {
         title = "new.project.title.additional.income";
         description = "new.project.description.additional.income";
         url = "/additional-income-project";
         category = ProjectCategory.OPTIMIZE_PROJECT;
         break;
      }
      case ProjectType.CHILDREN_EDUCATION: {
         title = "new.project.title.children.education";
         description = "new.project.description.children.education";
         url = "/children-education-project";
         category = ProjectCategory.LIFE_PROJECT;
         break;
      }
      case ProjectType.RECEIVED_DONATION: {
         title = "new.project.title.received.donation";
         description = "new.project.description.received.donation";
         url = "/received-donation-project";
         category = ProjectCategory.OTHER;
         break;
      }
      case ProjectType.GIVEN_DONATION: {
         title = "new.project.title.given.donation";
         description = "new.project.description.given.donation";
         url = "/given-donation-project";
         category = ProjectCategory.OTHER;
         break;
      }
      case ProjectType.CHANGE_LIFE_PROJECT: {
         title = "new.project.title.change.life";
         description = "new.project.description.change.life";
         url = "/change-life-project";
         category = ProjectCategory.CHANGE_PROJECT;
         break;
      }
      case ProjectType.TRAVEL: {
         title = "new.project.title.travel";
         description = "new.project.description.travel";
         url = "/travel-project";
         category = ProjectCategory.CHANGE_PROJECT;
         break;
      }
      case ProjectType.SABBATICAL_LEAVE: {
         title = "new.project.title.sabbatical.leave";
         description = "new.project.description.sabbatical.leave";
         url = "/sabbatical-leave-project";
         category = ProjectCategory.CHANGE_PROJECT;
         break;
      }
      case ProjectType.TAX_EXEMPTION_PROJECT: {
         title = "new.project.title.tax.exemption";
         description = "new.project.description.tax.exemption";
         url = "/tax-exemption-project";
         category = ProjectCategory.OPTIMIZE_PROJECT;
         break;
      }
      default: {
         console.warn("Type de projet non géré");
         break;
      }
   }

   return {...newProject, title, description, url, category};
};

const initialState: NewProjectState = {newProjects: new Array<NewProject>(), cannotFetchNewProjects: false, hasFetched: false};

export const newProjectsSlice = createSlice({
   name: "newProjects",
   initialState,
   reducers: {
      newProjectsFetched: (currentState, action: PayloadAction<NewProject[]>) => {
         // Dans le cas où l'on a le projet CHANGE_LIFE, on ajoute le projet TRAVEL et SABBATICAL LEAVE
         const changeLifeProject = action.payload.filter((r: NewProject) => r.projectType === ProjectType.CHANGE_LIFE_PROJECT)[0];
         if (changeLifeProject !== undefined) {
            const takeABreakProject: NewProject = {
               ...changeLifeProject, projectType: ProjectType.TRAVEL
            };
            action.payload.push(takeABreakProject);
            const sabbaticalLeaveProject: NewProject = {
               ...changeLifeProject, projectType: ProjectType.SABBATICAL_LEAVE
            };
            action.payload.push(sabbaticalLeaveProject);
         }

         return {
            ...currentState, newProjects: action.payload.map((r: NewProject) => addNewProjectFields(r)), hasFetched: true
         };
      },
      fetchNewProjectsError: (currentState) => ({
         ...currentState, cannotFetchNewProjects: true
      })
   }
});

export const {newProjectsFetched, fetchNewProjectsError} = newProjectsSlice.actions;

export default newProjectsSlice.reducer;
