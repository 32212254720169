import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet, apiPost } from "store/apiCaller";
import { onApiCallErrorNoError, safe } from "store/error/utils";
import { promotionalOfferSaved, promotionalOffersFetched } from "./promotionalOfferSlice";
import { FETCH_PROMOTIONAL_OFFERS, PromotionalOffer, SAVE_PROMOTIONAL_OFFER } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_PROMOTIONAL_OFFERS, safe(onApiCallErrorNoError, handleFetchPromotionalOffers));
   yield takeLeading(SAVE_PROMOTIONAL_OFFER, safe(onApiCallErrorNoError, handleSavePromotionalOffer));
}

/**
 * Récupère les offres promotionnelles d'un utilisateur
 */
function* handleFetchPromotionalOffers() {
   const payload: AxiosResponse<PromotionalOffer[]> = yield call(apiGet, `/backpfr/api/user-promotional-offers`);
   yield put(promotionalOffersFetched(payload.data));
}

/**
 * Mets à jour les informations sur l'offre promotionnelle d'un utilisateur
 * @param action Action contenant PromotionalOffer
 */
function* handleSavePromotionalOffer(action: PayloadAction<PromotionalOffer>) {
   yield call(apiPost, `/backpfr/api/user-promotional-offers`, action.payload);
   yield put(promotionalOfferSaved(action.payload));
}
