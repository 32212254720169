import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiDelete, apiGet, apiPost, apiPut } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { getExpensesAction } from "../expenses/actions";
import { getIncomesAction } from "../incomes/actions";
import { getLoansAction } from "../loans/actions";
import { getAssetsWithoutLoans } from "./actions";
import { assetCreated, assetDeleted, assetForRetirementUpdated, assetUpdated, assetsFetched } from "./assetsSlice";
import {
   AssetType,
   AssetWithValuation,
   CREATE_ASSET,
   CREATE_REAL_ESTATE_ASSET,

   CREATE_SCI_ASSET,
   DELETE_ASSET,
   FETCH_ASSETS_WITHOUT_LOANS,
   RealEstateAsset,
   SCIAsset,
   UPDATE_ASSET,
   UPDATE_ASSETS_FOR_RETIREMENT,
   UPDATE_REAL_ESTATE_ASSET,
   UPDATE_SCI_ASSET
} from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_ASSETS_WITHOUT_LOANS, safe(onDefaultError, handleGetAssetsNoLoans));
   yield takeLeading(CREATE_ASSET, safe(onDefaultError, handleCreateAsset));
   yield takeLeading(CREATE_REAL_ESTATE_ASSET, safe(onDefaultError, handleCreateRealEstateAsset));
   yield takeLeading(CREATE_SCI_ASSET, safe(onDefaultError, handleCreateSCIAsset));
   yield takeLeading(UPDATE_ASSET, safe(onDefaultError, handleUpdateAsset));
   yield takeLeading(UPDATE_REAL_ESTATE_ASSET, safe(onDefaultError, handleUpdateRealEstateAsset));
   yield takeLeading(UPDATE_SCI_ASSET, safe(onDefaultError, handleUpdateSCIAsset));
   yield takeLeading(DELETE_ASSET, safe(onDefaultError, handleDeleteAsset));
   yield takeLeading(UPDATE_ASSETS_FOR_RETIREMENT, safe(onDefaultError, handleUpdateAssetsForRetirement));
}

function* handleGetAssetsNoLoans() {
   const payload: AxiosResponse<AssetWithValuation[]> = yield call(apiGet, `wealth/api/assets?noLoans=true`);
   yield put(assetsFetched(payload.data));
}

function* handleCreateAsset(action: PayloadAction<Partial<AssetWithValuation>>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/`, action.payload);
   yield put(assetCreated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
}

function* handleUpdateAsset(action: PayloadAction<AssetWithValuation>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/`, action.payload);
   yield put(assetUpdated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
}

function* handleUpdateAssetsForRetirement(action: PayloadAction<AssetWithValuation[]>) {
   const currentAssets = action.payload;
   const unused = currentAssets.filter((asset) => asset.percentageUsedForRetirement === 0)
      .map((a) => a.id?.toString().concat("=").concat(a.percentageUsedForRetirement.toString()));
   const used = currentAssets.filter((asset) => asset.percentageUsedForRetirement > 0)
      .map((a) => a.id?.toString().concat("=").concat(a.percentageUsedForRetirement.toString()));
   let params = "";
   if (unused.length > 0) {
      params = params.concat(unused.join("&"));
      if (used.length > 0) {
         params = params.concat("&");
      }
   }
   if (used.length > 0) {
      params = params.concat(used.join("&"));
   }
   if (params.length > 0) {
      yield call(apiPut, `/wealth/api/assets/percentageUsedForRetirement?${params}`, null);
   }
   // PFR: Ajout des assets sans mise è jour
   yield put(assetForRetirementUpdated(currentAssets));
}

function* handleDeleteAsset(action: PayloadAction<AssetWithValuation>) {
   yield call(apiDelete, `wealth/api/assets/${action.payload.id}`);
   yield put(assetDeleted(action.payload));
   yield put(getIncomesAction());
   yield put(getExpensesAction());
   yield put(getAssetsWithoutLoans());
   yield put(getLoansAction());
}

function* handleCreateRealEstateAsset(action: PayloadAction<Partial<RealEstateAsset>>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/real-estates/`, action.payload);
   yield put(assetCreated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
   yield put(getLoansAction());
}

function* handleUpdateRealEstateAsset(action: PayloadAction<Partial<RealEstateAsset>>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/real-estates/`, action.payload);
   yield put(assetUpdated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
   yield put(getLoansAction());
}

function* handleCreateSCIAsset(action: PayloadAction<SCIAsset>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/sci/`, action.payload);
   yield put(getAssetsWithoutLoans());
   yield put(getIncomesAction());
   yield put(getLoansAction());
   yield put(assetCreated(payload.data));
}

function* handleUpdateSCIAsset(action: PayloadAction<SCIAsset>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/sci/`, action.payload);
   yield put(getAssetsWithoutLoans());
   yield put(getIncomesAction());
   yield put(getLoansAction());
   yield put(assetUpdated(payload.data));
}
