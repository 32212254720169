import {useLocation} from "react-router-dom";

const useUrlParams = (...args: string[]): (string|null)[] => {
   const location = useLocation();
   const urlParametersParsed = new URLSearchParams(location.search);

   return args.map((arg) => urlParametersParsed.get(arg));
};

export {useUrlParams};
