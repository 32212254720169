import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { PROFILE_DELETED, ProfileState } from "./types";

const initialState: ProfileState = { isDone: false };

export const ProfileReducer: Reducer<
  ProfileState,
  PayloadAction<ProfileState>
> = (currentState = initialState, action): ProfileState => {
  if (action.type === PROFILE_DELETED) {
    return {
      ...currentState,
      isDone: true,
    };
  }

  return currentState;
};
