import { FunctionComponent } from "react";
import { useMediaQuery } from "sg-media-query";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useFeatures } from "website/components/hooks/useFeatures";
import { AccesSimulateurReforme } from "../../AccesSimulateurReforme/AccesSimulateurReforme";
import { AccesSyntheseRetraite } from "../AccesSyntheseRetraite/AccesSyntheseRetraite";
import { SectionTemplate } from "../SectionTemplate/SectionTemplate";

const SectionReformeEtSynthese: FunctionComponent = () => {
  const { simulateurAvantApres } = useFeatures();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "lg" });

  return (
    <SectionTemplate
      sectionType={SectionDashBoardType.PENSION}
      showHeader={false}
    >
      {simulateurAvantApres && (
        <AccesSimulateurReforme key="accesSimulateurReforme" />
      )}
      {isPhone && <AccesSyntheseRetraite key="accesSyntheseRetraite" />}
    </SectionTemplate>
  );
};

export { SectionReformeEtSynthese };
