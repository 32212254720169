import { createSlice } from "@reduxjs/toolkit";
import { PdfState } from "./types";

const initialState: PdfState = {
   enChargement: false,
   erreur: false,
};

export const pdfSlice = createSlice({
   name: "pdf",
   initialState,
   reducers: {
      telechargerPdf: (currentState) => ({
         ...currentState,
         enChargement: true,
      }),
      pdfFetched: (currentState) => ({
         ...currentState,
         enChargement: false,
      }),
      pdfEnErreur: (currentState) => ({
         ...currentState,
         enChargement: false,
         erreur: true,
      }),
      resetErreurPdf: (currentState) => ({
         ...currentState,
         erreur: false,
      }),
   },
});

export const { telechargerPdf, pdfFetched, pdfEnErreur, resetErreurPdf } = pdfSlice.actions;

export default pdfSlice.reducer;
