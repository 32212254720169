import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFeatures } from "website/components/hooks/useFeatures";
import {
  fetchParcoursRisUploadAccessible,
  resetParcoursRisUploadAccessible,
} from "store/parcoursRisUpload/actions";
import { ParcoursRisUploadState } from "store/parcoursRisUpload/types";
import { State } from "store/store";
import { ErrorModal } from "../ErrorModal/ErrorModal";

interface MaxRisErrorModalProps {
  accessibiliteRisClicked: boolean;
  setAccessibiliteRisClicked: (accessibiliteRisClicked: boolean) => void;
  redirectToParcoursRIS: () => void;
}

const MaxRisErrorModal: FunctionComponent<MaxRisErrorModalProps> = (
  props: MaxRisErrorModalProps
) => {
  const {
    accessibiliteRisClicked,
    setAccessibiliteRisClicked,
    redirectToParcoursRIS,
  } = props;
  const dispatch = useDispatch();
  const parcoursRisUploadState: ParcoursRisUploadState = useSelector<
    State,
    ParcoursRisUploadState
  >((state) => state.parcoursRisUpload);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { disponibiliteParcoursRIS } = useFeatures();

  useEffect(() => {
    if (accessibiliteRisClicked) {
      dispatch(fetchParcoursRisUploadAccessible());
    }
  }, [accessibiliteRisClicked]);

  useEffect(() => {
    if (parcoursRisUploadState.accessible === false) {
      // Limite atteinte, on affiche un message
      setShowErrorModal(true);
      setAccessibiliteRisClicked(false);
      dispatch(resetParcoursRisUploadAccessible());
    } else if (
      parcoursRisUploadState.accessible &&
      accessibiliteRisClicked &&
      disponibiliteParcoursRIS
    ) {
      setAccessibiliteRisClicked(false);
      redirectToParcoursRIS();
    }
  }, [parcoursRisUploadState]);

  return (
    <>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="dashboard.pension.modal.error.message"
        />
      )}
    </>
  );
};

export { MaxRisErrorModal };
