import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import {
  AssetIncomesState,
  hasFetchedAssets as hasFetchedAssetsIncomes,
} from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "../../../store/assets/actions";
import { AssetState, AssetWithValuation } from "../../../store/assets/types";
import {
  getAssociatedSciName,
  isAssociatedToSCI,
} from "../../../store/assets/utils";
import { State } from "../../../store/store";
import { useFeatures } from "./useFeatures";

const useAssets = () => {
  const dispatch = useDispatch();

  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);
  const hasFetchedIncomes = hasFetchedAssetsIncomes(assetIncomesState);

  const { disponibiliteParcoursRevenusComplementaires } = useFeatures();

  useEffect(() => {
    if (!assetState.hasFetched) {
      dispatch(getAssetsWithoutLoans());
    }
    if (!hasFetchedIncomes) {
      dispatch(getAssetsIncomes());
    }
  }, []);

  const assets: AssetWithValuation[] = useMemo(() => {
    if (assetState.hasFetched) {
      const arrayForSort = [...assetState.assets];

      return arrayForSort.sort(
        (asset1: AssetWithValuation, asset2: AssetWithValuation) => {
          const asset1SortLabel = isAssociatedToSCI(assetState.assets, asset1)
            ? `${getAssociatedSciName(assetState.assets, asset1)} - ${
                asset1.assetName
              }`
            : asset1.assetName;
          const asset2SortLabel = isAssociatedToSCI(assetState.assets, asset2)
            ? `${getAssociatedSciName(assetState.assets, asset2)} - ${
                asset2.assetName
              }`
            : asset2.assetName;

          return asset1SortLabel > asset2SortLabel ? 1 : -1;
        }
      );
    }

    return [];
  }, [assetState]);

  const showDisclaimerPatrimoine = useMemo(() => {
    if (
      assetState.hasFetched &&
      (assetState.hasFetchedAssetsImported ||
        disponibiliteParcoursRevenusComplementaires === false)
    ) {
      return (
        disponibiliteParcoursRevenusComplementaires &&
        assetState.assetsImported.filter(
          (a) =>
            a.externalId !== null &&
            !assets.some(
              (existingAsset) => existingAsset.externalId === a.externalId
            )
        ).length > 0
      );
    }

    return undefined;
  }, [assetState, assets, disponibiliteParcoursRevenusComplementaires]);

  return {
    assets,
    hasFetchedAssets: assetState.hasFetched,
    assetIncomes: assetIncomesState.assetIncomes,
    hasFetchedIncomes,
    showDisclaimerPatrimoine,
  };
};

export { useAssets };
