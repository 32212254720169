import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { setAuthToken } from "../../website/utils/axios";
import { apiPost } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import {ERR_NO_KEY, Login, LOGIN, LOGIN_AS, LoginAs, NewPassword, REQUEST_NEW_PASSWORD, SUBMIT_NEW_PASSWORD, USER_LOGOUT} from "./types";
import {getAccountAction} from "../account/actions";
import {globalError} from "../error/errorsSlice";
import {loginErrorWrongPassword401, loginSuccess, userLogout} from "./loginSlice";

export function* watcherSaga() {
   yield takeLeading(LOGIN, safe(onLoginError, handleLogin));
   yield takeLeading(LOGIN_AS, safe(onLoginError, handleLoginAs));
   yield takeLeading(USER_LOGOUT, safe(onDefaultError, handleLogout));
   yield takeLeading(REQUEST_NEW_PASSWORD, safe(onDefaultError, handlePasswordResetInit));
   yield takeLeading(SUBMIT_NEW_PASSWORD, safe(onDefaultError, handlePasswordResetFinish));
}

function* handlePasswordResetInit(action: PayloadAction<string>) {
   yield call(apiPost, `api/account/reset-password/init`, action.payload);
}

function* handleLogout(action: PayloadAction<string | undefined>) {
   yield put(userLogout(action.payload));
}

function* handlePasswordResetFinish(action: PayloadAction<NewPassword>) {
   const {key} = action.payload;
   if (!key || key === ERR_NO_KEY) {
      yield put(globalError("wrong password reset key"));
   } else {
      yield call(apiPost, `api/account/reset-password/finish`, action.payload);
   }
}

function* handleLogin(action: PayloadAction<Login>) {
   /* eslint-disable camelcase */
   const payload: AxiosResponse<{ id_token: string }> = yield call(apiPost, `api/authenticate`, action.payload);
   sessionStorage.setItem("token", payload.data.id_token);
   setAuthToken(payload.data.id_token);
   yield put(getAccountAction());
   yield put(loginSuccess());
}

function* handleLoginAs(action: PayloadAction<LoginAs>) {
   const {demoMode, ...actionPayload} = action.payload;
   /* eslint-disable camelcase */
   const payload: AxiosResponse<{ id_token: string }> = yield call(apiPost, `api/authenticate-as`, actionPayload);
   sessionStorage.setItem("token", payload.data.id_token);
   setAuthToken(payload.data.id_token);
   yield put(getAccountAction());
   yield put(loginSuccess());
   sessionStorage.setItem("demoMode", `${demoMode}`);
}

function* onLoginError(err: AxiosError) {
   const {response} = err;
   if (response?.status === 401) {
      yield put(loginErrorWrongPassword401());
   } else {
      onDefaultError(err);
   }
}
