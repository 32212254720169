import React, {FunctionComponent, PropsWithChildren, ReactElement} from "react";
import {SGAlert} from "sg-alert";
import {useIntl} from "react-intl";
import {SGIcon} from "sg-icon";
import {SGAvenirStatusError, SGAvenirStatusInfo} from "sg-icon-pack-base";
import {SGBlock} from "sg-typo";

interface SGAlertIntlProps {
   type: "warning" | "error" | "info";
   cypressName: string;
   title: string;
}

const SGAlertIntl: FunctionComponent<PropsWithChildren<SGAlertIntlProps>> = ({type, cypressName, title, children}: PropsWithChildren<SGAlertIntlProps>) => {
   const intl = useIntl();

   const getIcon = (): ReactElement => {
      switch (type) {
         case "info":
            return <SGAvenirStatusInfo/>;
         case "warning":
         case "error":
         default:
            return <SGAvenirStatusError/>;
      }
   };

   return (
      <SGBlock>
         <SGAlert type={type} message={title && intl.formatMessage({id: title})} description={children}
                  data-cy={cypressName} icon={<SGIcon component={getIcon()} size="l"/>}/>
      </SGBlock>

   );
};

export {SGAlertIntl};
