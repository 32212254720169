import { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router";
import { SGDrawer } from "sg-drawer";
import {
  SGAvenirStrokedEpargne,
  SGAvenirStrokedFamille,
  SGAvenirStrokedFinancement,
} from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { externalURL } from "website/utils/externalUrl";
import {
  NOS_SOLUTIONS_ASV,
  NOS_SOLUTIONS_PER,
} from "website/utils/privateRoutes";

enum TypeTuile {
  PER = "plan-epargne-retraite",
  ASV = "assurance-vie",
  AUTRE = "autres-solutions",
}

interface DrawerSolutionsProps {
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
}

const DrawerSolutions: FunctionComponent<DrawerSolutionsProps> = (
  props: DrawerSolutionsProps
) => {
  const { showDrawer, setShowDrawer } = props;
  const history = useHistory();
  const openLink = useOpenLink();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackDrawer, trackClick } = useTracking();

  useEffect(() => {
    if (showDrawer) {
      trackDrawer("drawer-nos-solutions-d-epargne");
    }
  }, [showDrawer]);

  const onClose = () => {
    trackClick(
      "drawer-nos-solutions-d-epargne::clic-sur-fermer"
    );
    setShowDrawer(false);
  };

  const handleOnClickTuile = (typeTuile: TypeTuile) => {
    trackClick(
      `drawer-nos-solutions-d-epargne::clic-sur-${typeTuile}`
    );
    setShowDrawer(false);
    switch (typeTuile) {
      case TypeTuile.PER:
        history.push(NOS_SOLUTIONS_PER);
        break;
      case TypeTuile.ASV:
        history.push(NOS_SOLUTIONS_ASV);
        break;
      case TypeTuile.AUTRE:
      default:
        openLink(externalURL.EXTERNAL_URL_EPARGNER_PLACER);
        break;
    }
  };

  return (
    <SGDrawer
      placement={isPhone ? "bottom" : "right"}
      visible={showDrawer}
      onClose={onClose}
    >
      <SGSpace direction="vertical" size="md" disableautomargin>
        <SGTitle key="titre" level={2} visuallevel={isPhone ? 2 : 4}>
          Nos solutions d’épargne
        </SGTitle>
        <SGText key="sous-titre">
          M’informer sur les solutions d’épargne qui permettent de se constituer
          un complément de revenus pour la retraite
        </SGText>
        <TuileCliquable
          key="tuile-1"
          icone={<SGAvenirStrokedEpargne />}
          titre="Plan Épargne Retraite"
          texte="pour se constituer un capital ou des revenus réguliers pour une
                retraite sereine."
          onClick={() => handleOnClickTuile(TypeTuile.PER)}
        />
        <TuileCliquable
          key="tuile-2"
          icone={<SGAvenirStrokedFamille />}
          titre="Assurance Vie"
          texte="pour se constituer une épargne."
          onClick={() => handleOnClickTuile(TypeTuile.ASV)}
        />
        <TuileCliquable
          key="tuile-3"
          icone={<SGAvenirStrokedFinancement />}
          titre="Autres solutions"
          onClick={() => handleOnClickTuile(TypeTuile.AUTRE)}
        />
      </SGSpace>
    </SGDrawer>
  );
};

export { DrawerSolutions };
