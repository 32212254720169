import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGTitle } from "sg-typo";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { Faq } from "../Faq/Faq";
import "./HypothesisModal.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGScroll } from "sg-scroll";

export enum HypothesisAnchor {
    SAPIENDO = "sapiendo",
    FLASH = "flash",
    RSI = "rsi",
    PATRIMOINE = "patrimoine",
    EPARGNER = "epargner",
}

interface HypothesisModalProps {
    visible: boolean;
    setVisible: (showHypothesisModal: boolean) => void;
    anchor?: HypothesisAnchor;
    closeFirstGroup?: boolean;
    closeSecondGroup?: boolean;
}

export const HypothesisModal: FunctionComponent<HypothesisModalProps> = (props: HypothesisModalProps) => {
    const { visible, setVisible, anchor, closeFirstGroup, closeSecondGroup } = props;
    const intl = useIntl();
    const [openFirstGroup, setOpenFirstGroup] = useState(true);
    const [openSecondGroup, setOpenSecondGroup] = useState(true);
    const [defaultAnchor, setDefaultAnchor] = useState<string[][]>([[], []]);
    const { trackPopin, resetTracking } = useTracking();

    const hypothesisFirstBodyList = ["hypothesis.sapiendo", "hypothesis.flash", "hypothesis.rsi"];
    const hypothesisSecondBodyList = ["hypothesis.patrimoine", "hypothesis.epargner"]; // TODO: lien agenda dans le texte patrimoine
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const handleOnClose = () => {
        setVisible(false);
        resetTracking();
    }

    useEffect(() => {
        if (visible) {
            trackPopin(false, "", "hypothese-de-simulation", "", "", PAGE_TYPE_GESTION);
        }
    }, [visible]);

    useEffect(() => {
        if (anchor) {
            setDefaultAnchor(
                [HypothesisAnchor.SAPIENDO, HypothesisAnchor.FLASH, HypothesisAnchor.RSI].includes(anchor)
                    ? [[`hypothesis.${anchor}`], []]
                    : [[], [`hypothesis.${anchor}`]]
            );
        } else {
            setDefaultAnchor([[], []]);
        }
    }, [anchor]);

    useEffect(() => {
        setOpenFirstGroup(!closeFirstGroup);
        setOpenSecondGroup(!closeSecondGroup);
    }, [closeFirstGroup, closeSecondGroup]);

    return (
        <SGModal
            className="hypothesis-modal__modal"
            visible={visible}
            centered
            onCancel={handleOnClose}
            closable
            header={
                <>
                    <SGTitle level={isPhone ? 2 : 3} textalign="left">
                        {intl.formatMessage({ id: `hypothesis.title` })}
                    </SGTitle>
                    <SGTitle level={isPhone ? 2 : 3} textalign="left">
                        {intl.formatMessage({ id: `hypothesis.subtitle` })}
                    </SGTitle>
                </>
            }
        >
            <SGGridRow gutter={[0, 18]}>
                <SGGridCol span={12} textalign="left">
                    <SGGridRow gutter={[0, 10]}>
                        <SGGridCol span={12}>
                            <SGScroll disableautomargin>
                            <Faq
                                titleFaq="hypothesis.pension.title"
                                bodyFaq={hypothesisFirstBodyList}
                                isMainAccordionOpen={openFirstGroup}
                                openDefaultKeys={defaultAnchor[0]}
                                isModal
                            />
                            <Faq
                                titleFaq="hypothesis.projection.title"
                                bodyFaq={hypothesisSecondBodyList}
                                isMainAccordionOpen={openSecondGroup}
                                openDefaultKeys={defaultAnchor[1]}
                                isModal
                            />
                            </SGScroll>
                        </SGGridCol>
                    </SGGridRow>
                </SGGridCol>
            </SGGridRow>
        </SGModal>
    );
}
