import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGCard, SGCardMeta } from "sg-card";
import { SGCheckBox, SGCheckBoxGroup } from "sg-checkbox";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { AccountState } from "store/account/types";
import { savePlanPreference } from "store/planPreference/action";
import { PlanPreference } from "store/planPreference/types";
import { State } from "store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import "./PreparationCard.scss";

export interface PreparationComponent {
  type: string;
  cards: {
    component: string;
    icon: JSX.Element;
    link: string;
    colored: boolean;
  }[];
  chevrons: {
    type: string;
    checkbox: { name: string; checked?: boolean; setChecked?: void }[];
    subtitle?: boolean;
  }[];
  color: string;
}

interface PreparationCardProps {
  component: PreparationComponent;
  planPreferences?: PlanPreference[];
  defaultChevrons: string[];
  allchevrons?: string[];
}
const PreparationCard: FunctionComponent<PreparationCardProps> = (
  props: PreparationCardProps
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const accountState: AccountState = useSelector<State, AccountState>(
    (state) => state.account
  );
  const openLink = useOpenLink();
  const { trackClick } = useTracking();

  const { component, defaultChevrons, planPreferences, allchevrons } = props;
  const [openChevrons, setOpenChevrons] = useState<string[]>([]);
  const [openDefaultChevrons, setOpenDefaultChevrons] =
    useState<boolean>(false);

  const getPlanPreference = (preference: string) =>
    planPreferences
      ?.filter((planPreference) => planPreference.preference === preference)
      .shift();

  const isPhone = useMediaQuery({ minwidth: 0, maxwidth: "sm" });

  function linkParams(link: string, cardComponent: string) {
    if (isExternalLink(link)) {
      return {
        target: "_blank",
        onClick: (event: SyntheticEvent) => {
          // Pour le moment, il n'y a qu'un seul lien externe,
          // test au cas où plus tard on passe dans cette fonction avec un autre lien
          if (cardComponent === "produits") {
            trackClick("clic-sur-je-decouvre-les-produits-d-epargne");
          }
          openLink(link, event);
        },
        href: link,
      };
    }

    return {
      href: `#${link}`,
      onClick: () => {
        switch (cardComponent) {
          case "questions":
            trackClick("clic-sur-je-decouvre-les-articles");
            break;
          case "aide":
            trackClick("clic-sur-je-decouvre-mes-services");
            break;
          default:
        }
      },
    };
  }

  function isExternalLink(link: string): boolean {
    return link.startsWith("http");
  }
  useEffect(() => {
    handleOpenChevrons(allchevrons || [], true);
  }, []);

  useEffect(() => {
    if (openDefaultChevrons) {
      handleOpenChevrons(defaultChevrons, false);
    }
  }, [openDefaultChevrons]);

  const handleOpenChevrons = (
    chevrons: string[],
    openDefaultChevrons: boolean
  ) => {
    setOpenChevrons(chevrons);
    setOpenDefaultChevrons(openDefaultChevrons);
  };

  function changeChoosenPref(nomCheckBox: string): void {
    const planPreference = getPlanPreference(nomCheckBox);
    if (planPreference) {
      const newPlanPreference: Partial<PlanPreference> = {
        preference: planPreference?.preference,
        choosenPref: !planPreference?.choosenPref,
      };
      dispatch(savePlanPreference(newPlanPreference));
    } else {
      const newPlanPreference: Partial<PlanPreference> = {
        preference: nomCheckBox,
        choosenPref: true,
      };
      dispatch(savePlanPreference(newPlanPreference));
    }
  }

  return (
    <SGBox margin={{top: "lg", bottom: "xl"}}>
    <SGGridRow justify="center">
      <SGGridCol span={12} lg={7} textalign={isPhone ? "left" : "center"}>
        <SGTitle level={isPhone ? 2 : 3}>
          {intl.formatMessage({
            id: `monPlan.preparation.${component.type}.title`,
          })}
        </SGTitle>
      </SGGridCol>
      <SGGridCol span={12}>
        <SGCard
          className={`preparation-card__card preparation-card__${component.color}-card`}
        >
          <SGGridRow>
            <SGGridCol span={12} lg={7}>
              <SGAccordion
                accordion={false}
                className="preparation-card__accordion"
                onChange={(value: any) => {
                  setOpenChevrons(value);
                }}
                activeKey={openChevrons}
              >
                {component.chevrons.map((chevron) => (
                  <SGAccordionPanel
                    key={chevron.type}
                    header={
                      <div>
                        {intl.formatMessage({
                          id: `monPlan.preparation.${component.type}.${chevron.type}.title`,
                        })}
                      </div>
                    }
                  >
                    <SGCheckBoxGroup
                      layout="column"
                      legend={
                        chevron.subtitle ? (
                          <SGText size="m">
                            {intl.formatMessage({
                              id: `monPlan.preparation.${component.type}.${chevron.type}.subtitle`,
                            })}
                          </SGText>
                        ) : (
                          ""
                        )
                      }
                      value={["true"]}
                      className="preparation-card__checkbox-group"
                      disableautomargin
                    >
                      {chevron.checkbox.map((checkbox) => (
                        <SGCheckBox
                          onChange={() => changeChoosenPref(checkbox.name)}
                          key={checkbox.name}
                          value={checkbox.checked ? "true" : "false"}
                        >
                          <SGText>
                            {intl.formatMessage(
                              {
                                id: `monPlan.preparation.${component.type}.${chevron.type}.${checkbox.name}`,
                              },
                              {
                                i: (word: string) => (
                                  <em style={{ fontWeight: 300 }}>{word}</em>
                                ),
                                linebreak: <br />,
                              }
                            )}
                          </SGText>
                        </SGCheckBox>
                      ))}
                    </SGCheckBoxGroup>
                  </SGAccordionPanel>
                ))}
              </SGAccordion>
            </SGGridCol>
            <SGGridCol span={12} lg={4} offset={isPhone ? 0 : 1}>
              <div className="preparation-card__card-container">
                <SGGridRow>
                  {component.cards.map((card) => (
                    <SGGridCol
                      span={12}
                      key={card.component}
                    >
                      <SGCard
                        hoverable
                        title={
                          <SGGridRow gutter={[14]}>
                            <SGGridCol span={2}>
                              <SGIcon component={card.icon} size="l" />
                            </SGGridCol>
                            <SGGridCol span={10}>
                              <SGTitle level={3}>
                                {intl.formatMessage({
                                  id: `monPlan.preparation.${component.type}.${card.component}.card.title`,
                                })}
                              </SGTitle>
                            </SGGridCol>
                          </SGGridRow>
                        }
                        actions={[
                          <SGLink
                            type="primarylink"
                            icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                            iconside="right"
                            {...linkParams(card.link, card.component)}
                          >
                            {intl.formatMessage({
                              id: `monPlan.preparation.${component.type}.${card.component}.card.link`,
                            })}
                          </SGLink>,
                        ]}
                        className={`preparation-card__secondary-card ${
                          card.colored
                            ? "preparation-card__secondary-card--colored"
                            : ""
                        }`}
                      >
                        <SGCardMeta
                          description={
                            <SGText size="s">
                              {intl.formatMessage(
                                {
                                  id: `monPlan.preparation.${component.type}.${card.component}.card.subtitle`,
                                },
                                {
                                  b: (word: string) => (
                                    <span style={{ fontWeight: 600 }}>
                                      {word}
                                    </span>
                                  ),
                                }
                              )}
                            </SGText>
                          }
                        />
                      </SGCard>
                    </SGGridCol>
                  ))}
                </SGGridRow>
              </div>
            </SGGridCol>
          </SGGridRow>
        </SGCard>
      </SGGridCol>
    </SGGridRow>
    </SGBox>
  );
};
export { PreparationCard };
