import React, { FunctionComponent } from "react";
import { RouteProps } from "react-router-dom";
import "./SimulateurEconomiesImpots.scss";

export interface SimulateurEconomiesImpotsProps extends RouteProps {
    currentStep: number;
    steps: FunctionComponent[];
}
const SimulateurEconomiesImpots: FunctionComponent<SimulateurEconomiesImpotsProps> = (props: SimulateurEconomiesImpotsProps) => {
    const { currentStep, steps } = props;

    return (
        <div className="simulateurEconomiesImpots__step-content">
            {steps.map((step: FunctionComponent, index: number) => {
                const Component = step;

                return currentStep === index && <Component key={currentStep} {...props} />;
            })}
        </div>
    );
};

export { SimulateurEconomiesImpots };
