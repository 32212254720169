import React, { FC, PropsWithChildren } from "react";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGPopover } from "sg-popover";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import "./InfoTooltipAssu.scss";

interface InfoTooltipAssuProps {
    text?: string;
    placement: string;
    transformations?: Record<string, string | React.ReactNode>;
    trackKey?: string;
}

/**
 * Affiche un SGPopover avec icône d'information.
 * @param props text (fr.json) ou children est obligatoire
 * @returns Infoboulle avec un (i)
 */
const InfoTooltipAssu: FC<PropsWithChildren<InfoTooltipAssuProps>> = (props: PropsWithChildren<InfoTooltipAssuProps>) => {
    const { text, children, placement, transformations, trackKey } = props;
    const { trackClick } = useTracking();

    return (
        <SGPopover
            trigger="click"
            content={
                children || (
                  <SGTextIntl
                    size="m"
                    weight="400"
                    intlId={text ?? ""}
                    transformations={transformations}
                  />
                )
            }
            placement={placement}
            onOpenChange={() => true}
        >
            <SGButton type="icon" onClick={() => trackKey && trackClick(trackKey)} icon={<SGIcon component={<SGAvenirStatusInfo />} size="m" />} />
        </SGPopover>
    );
};

export { InfoTooltipAssu };
