import { AxiosResponse } from "axios";
import { Reducer } from "react";
import {
  PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_FETCHED,
  RESET_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_ERROR,
  ParcoursRisInformationsComplementairesState,
  UPDATE_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
} from "./types";
import { API_ERROR_422 } from "../parcoursRIS/types";

const initialState: ParcoursRisInformationsComplementairesState = {} as ParcoursRisInformationsComplementairesState;

export const ParcoursRisInformationsComplementairesReducer: Reducer<
  ParcoursRisInformationsComplementairesState,
  any
> = (
  currentState = initialState,
  action
): ParcoursRisInformationsComplementairesState => {
  switch (action.type) {
    case PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_FETCHED:
      return {
        ...currentState,
        parcoursRisInformationsComplementaires: action.payload,
      };
    case API_ERROR_422:
      const { response }: { response: AxiosResponse } = action.payload;

      return {
        ...currentState,
        parcoursRisInformationsComplementairesError: response.data?.message,
      };
    case RESET_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_ERROR:
      return {
        ...currentState,
        parcoursRisInformationsComplementairesError: "",
      };
    case UPDATE_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES:
      return {
        ...currentState,
        parcoursRisInformationsComplementaires: action.payload,
        parcoursRisInformationsComplementairesError: "",
      };
    default:
      return currentState;
  }
};
