import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";

export interface RISProgressBarProps {
  type: "pension" | "salaire";
  value: number;
}

export const RISProgressBar: FunctionComponent<RISProgressBarProps> = (
  props: RISProgressBarProps
) => {
  const { type, value } = props;
  const intl = useIntl();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  return (
    <SGBox>
      <SGGridRow align="middle">
        <SGGridCol flex="auto" textalign="left">
          <SGText
            size="s"
            weight={type === "pension" ? "600" : undefined}
            style={
              type === "pension" ? { color: "#9F2D62" } : { color: "#747374" }
            }
          >
            {intl.formatMessage(
              { id: `parcoursRIS.resultat.progress.${type}` },
              {
                big: (word: string) => (
                  <SGText size={isPhone ? "l" : "m"}>{word}</SGText>
                ),
              }
            )}
          </SGText>
        </SGGridCol>
        <SGGridCol flex="initial">
          <SGText
            size={type === "pension" ? "xxl" : "l"}
            weight="700"
            style={
              type === "pension" ? { color: "#9F2D62" } : { color: "#747374" }
            }
          >
            {`${value.toLocaleString("fr-FR")}${" "}€`}
          </SGText>
        </SGGridCol>
      </SGGridRow>
    </SGBox>
  );
};
