import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiPost } from "../apiCaller";
import { safe } from "../error/utils";
import { onApiCallError } from "../parcoursRIS/utils";
import {
  FETCH_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
  ParcoursRisInformationsComplementaires,
  PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_FETCHED
} from "./types";

export default function* watcherSaga() {
  yield takeLeading(
    FETCH_PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES,
    safe(onApiCallError, handlePostParcoursRisInformationsComplementaires)
  );
}

function* handlePostParcoursRisInformationsComplementaires(action: any) {
  const payload: AxiosResponse<ParcoursRisInformationsComplementaires> = yield call(
    apiPost,
    `/backpfr/api/parcours-ris/informations-complementaires`,
    action.payload,
    undefined,
    window.env.REACT_APP_API_URL
  );
  yield put({
    type: PARCOURS_RIS_INFORMATIONS_COMPLEMENTAIRES_FETCHED,
    payload: payload.data,
  });
}
