import React, { FunctionComponent, useState } from "react";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { CategorieVideo, useVideos } from "website/components/hooks/useVideos";
import { CarteVideo } from "website/components/molecules/CarteVideo/CarteVideo";
import { ErrorModal } from "../../../molecules/ErrorModal/ErrorModal";

// Composant card du parcours epargner
const MonPlanTakeActionCard: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const openSGAgenda = useSGAgenda();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleOnClickRdv = (
    disponibiliteSGAgenda: boolean,
  ) => {
    trackClick("clic-sur-contacter-mon-conseiller",
      {
        form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
      }
    );

    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  const videos = useVideos(CategorieVideo.MONPLAN, handleOnClickRdv);

  return (
    <>
      {videos.length > 0 && (
        <CarteVideo
          video={videos[0]}
          showLoader={false}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </>
  );
};

export { MonPlanTakeActionCard };
