import React, { FunctionComponent } from "react";
import "./SpinnerAssu.scss";

const SpinnerAssu: FunctionComponent = () => (
  <div className="spinner__circle">
    <div className="spinner__circle-gradient" />
    <div className="spinner__circle-inner" />
  </div>
);

export default SpinnerAssu;
