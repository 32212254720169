import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiPost, apiPut } from "store/apiCaller";
import { safe } from "store/error/utils";
import { plafondEconomieImpotFetched, plafondEconomieImpotSimulationReset } from "./slice";
import { FETCH_TAX_ECONOMY_CEILING_SIMULATION, ParametresPlafondEconomieImpot, PlafondsSEI } from "./types";
import { onApiCallError } from "./utils";

export function* watcherSaga() {
   yield takeLatest(FETCH_TAX_ECONOMY_CEILING_SIMULATION, safe(onApiCallError, handleFetchTaxEconomyCeiling));
}

/**
 * Appelle le simulateur du plafond economie impot
 * @param action Action contenant ParametresPlafondEconomieImpot
 */
function* handleFetchTaxEconomyCeiling(action: PayloadAction<ParametresPlafondEconomieImpot>) {
   // Si on a une simulationKey dans une ancienne réponse, on lance un PUT. Sinon, ce sera un POST
   const simulationKey: string = yield select((state) => state.simulateurPlafondEconomieImpot.plafondsSEI.simulationKey);
   yield put(plafondEconomieImpotSimulationReset());
   const payload: AxiosResponse<PlafondsSEI> = simulationKey
      ? yield call(apiPut, `/backpfr/api/simulation-access/simulateur-plafond-economie-impot/${simulationKey}`, action.payload)
      : yield call(apiPost, `/backpfr/api/simulation-access/simulateur-plafond-economie-impot`, action.payload);
   yield put(plafondEconomieImpotFetched({plafondsSEI: payload.data, parameters: action.payload}));
}
