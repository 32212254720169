import {
   CREATE_REALIZED_DONATION,
   DELETE_REALIZED_DONATION,
   FETCH_REALIZED_DONATIONS,
   RealizedDonation,
   RESET_REALIZED_DONATION_STATE,
   UPDATE_REALIZED_DONATION
} from "./types";

export const getRealizedDonationsAction = () => ({
   type: FETCH_REALIZED_DONATIONS
});

export const updateRealizedDonationAction = (realizedDonation: RealizedDonation) => ({
   type: UPDATE_REALIZED_DONATION,
   payload: realizedDonation
});

export const createRealizedDonationAction = (realizedDonation: Omit<RealizedDonation, "id">) => ({
   type: CREATE_REALIZED_DONATION,
   payload: realizedDonation
});

export const deleteRealizedDonationAction = (realizedDonation: RealizedDonation) => ({
   type: DELETE_REALIZED_DONATION,
   payload: realizedDonation
});

export const resetRealizedDonationStateAction = () => ({
   type: RESET_REALIZED_DONATION_STATE
});
