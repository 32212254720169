import { FETCH_EFFORTS, FETCH_SAVINGPATHS_FROM_OBJECTIVE, FETCH_SAVINGPATHS_FROM_EFFORT, FETCH_SAVINGPATH_FROM_EFFORT, FETCH_SAVINGPATH_FROM_OBJECTIVE, MonthlyIncomeRequest, MonthlyIncomesRequest, SavingEffortRequest, SavingEffortsRequest } from "./types";

export const getSavingEffort = (data: SavingEffortRequest) => ({
   type: FETCH_SAVINGPATH_FROM_OBJECTIVE,
   payload: data,
});
export const getSavingEfforts = (data: SavingEffortsRequest) => ({
   type: FETCH_SAVINGPATHS_FROM_OBJECTIVE,
   payload: data,
});
export const getEffortsState = (data: SavingEffortsRequest) => ({
   type: FETCH_EFFORTS,
   payload: data,
});
export const getMonthlyIncome = (data: MonthlyIncomeRequest) => ({
   type: FETCH_SAVINGPATH_FROM_EFFORT,
   payload: data,
});

export const getMonthlyIncomes = (data: MonthlyIncomesRequest) => ({
   type: FETCH_SAVINGPATHS_FROM_EFFORT,
   payload: data,
});
