import { FunctionComponent, useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGText } from "sg-typo";
import { ImportState } from "store/import/types";
import { RetirementCategory } from "store/members/types";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { State } from "store/store";
import { useFamily } from "website/components/hooks/useFamily";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";

interface SituationInputProps {
  retirementType?: RetirementCategory;
  setRetirementType: (value: RetirementCategory) => void;
  setCategorieActive: (value: CategorieActiveChoix) => void;
}

export const SituationInput: FunctionComponent<SituationInputProps> = (
  props: SituationInputProps
) => {
  const { retirementType, setRetirementType, setCategorieActive } = props;
  const retirementTypeValues: RetirementCategory[] =
    Object.values(RetirementCategory);

  const family = useFamily();
  const { setShowDetailFonctionnaire } = useContext(DetailTabContext);
  const { control, setValue, formState: { errors, submitCount } } = useFormContext();
  const isVisible = useMediaQuery({ maxwidth: "xl" });

  const importData: ImportState = useSelector<State, ImportState>(
    (state) => state.importData
  );

  // Permet de revalider la valeur du select retirementCategories suite à l'utilisation d'un Controller
  useEffect(() => {
    setValue("retirementCategories", retirementType, {
      shouldValidate: submitCount > 0,
    });
    setShowDetailFonctionnaire(
      retirementType === RetirementCategory.FONCTIONNAIRE
    );
  }, [retirementType]);

  useEffect(() => {
    if (family.me.retirementType) {
      setRetirementType(family.me.retirementType);
    }
  }, [family, importData]);

  return (
    <Controller
      key="retirementCategories"
      control={control}
      name="retirementCategories"
      rules={{
        required: true,
      }}
      render={({ field }) => (
        <SGSelect
          placeholder="Sélectionnez"
          value={retirementType || ""}
          size={!isVisible ? "xl" : "l"}
          onChange={(value: RetirementCategory) => {
            field.onChange(value);
            setRetirementType(value);
            if (value !== RetirementCategory.FONCTIONNAIRE) {
              setCategorieActive(CategorieActiveChoix.NON);
            }
          }}
          label={
            <SGText size="l">
              <FormattedMessage id="tunnel.situation.situationQuestion"/>
            </SGText>
          }
          status={errors?.retirementCategories && "error"}
          validate={<FormattedMessage id="tunnel.error.required" />}
        >
          <SGSelectOption value="" style={{ display: "none" }}>
            Sélectionnez
          </SGSelectOption>
          {retirementTypeValues.map(
            (retirementCategory: RetirementCategory) => (
              <SGSelectOption
                key={retirementCategory}
                value={retirementCategory}
              >
                <FormattedMessage id={`retirementType.${retirementCategory}`} />
              </SGSelectOption>
            )
          )}
        </SGSelect>
      )}
    />
  );
};
