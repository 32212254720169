import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedMesRendezVous } from "sg-icon-pack-base";
import { SGContent } from "sg-layout";
import { SGLink } from "sg-link";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGSticky } from "sg-sticky";
import { SGText, SGTitle } from "sg-typo";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { BreadCrumbAssu } from "website/components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import {
  IconePointsCles,
  SectionPointsCles,
} from "website/components/molecules/NosSolutions/SectionPointsCles";
import { SectionTabsNosSolutions } from "website/components/molecules/NosSolutions/SectionTabsNosSolutions";
import {
  NomNosSolutionsEnum,
  TabNosSolutionsEnum,
  ordreTuilesPage,
} from "website/components/molecules/NosSolutions/utils";
import { DASHBOARD } from "website/utils/privateRoutes";
import { MentionsSolutions } from "./MentionsSolutions";

interface NosSolutionsTemplateProps {
  nom: NomNosSolutionsEnum;
  icones: IconePointsCles[];
  tabs: TabNosSolutionsEnum[];
  spanSectionPointsCles: number;
  trackingName: string;
}

const NosSolutionsTemplate: FunctionComponent<NosSolutionsTemplateProps> = (
  props: NosSolutionsTemplateProps
) => {
  const { nom, icones, tabs, spanSectionPointsCles, trackingName } = props;
  const intl = useIntl();
  const { trackPage, trackClick } = useTracking();
  const openSGAgenda = useSGAgenda();
  const { disponibiliteSGAgenda } = useFeatures();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    trackPage("nos-solutions", trackingName, "gestion-ecran");
  }, []);

  const handleOnClickRdv = () => {
    trackClick(`sticky::clic-sur-contacter-mon-conseiller`);
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  return (
    <>
      <SGContent>
        <SGMediaQuery minwidth="sm">
          <BreadCrumbAssu step="nosSolutions.per.breadCrumbAssu" />
        </SGMediaQuery>
        <SGBox span={isPhone ? 12 : 10} margin={{ bottom: "md" }}>
          <SGSpace
            size="sm"
            textalign={isPhone ? "left" : "center"}
            direction="vertical"
          >
            <SGTitle key="titre" level={1}>
              {intl.formatMessage({
                id: `nosSolutions.${nom}.titre`,
              })}
            </SGTitle>
            {nom === NomNosSolutionsEnum.ASV && (
              <SGText key="text" size="l">
                {intl.formatMessage({
                  id: `nosSolutions.${nom}.description`,
                })}
              </SGText>
            )}
          </SGSpace>
        </SGBox>
        <SGBox
          padding={{ bottom: isPhone ? "xxl" : "lg" }}
          span={spanSectionPointsCles === 6 ? 8 : 12}
        >
          <SectionPointsCles
            icones={icones}
            nom={nom}
            span={spanSectionPointsCles}
          />
        </SGBox>
        <SGBox
          span={isPhone ? 12 : 8}
          padding={{ top: isPhone ? "xxl" : "lg" }}
        >
          <SectionTabsNosSolutions tabs={tabs} nom={nom} />
        </SGBox>
        <SGBox margin={{ top: "md", bottom: "xl" }}>
          <SGButtonGroup disableautomargin align="center">
            <SGLink
              href={`#${DASHBOARD}`}
              type="primary"
              onClick={() =>
                trackClick("clic-sur-retourner-a-mon-espace-retraite")
              }
            >
              {intl.formatMessage({
                id: `nosSolutions.${nom}.bouton.retour`,
              })}
            </SGLink>
          </SGButtonGroup>
        </SGBox>
        <SGBox margin={{ bottom: isPhone ? "lg" : "xxl" }}>
          <SGGridRow gutter={[0, isPhone ? 16 : 32]} justify="center">
            <SGGridCol span={12}>
              <SectionAllerPlusLoin
                nom={nom}
                ordreTuilesPage={ordreTuilesPage[nom]}
              />
            </SGGridCol>
            <SGGridCol span={12} sm={8}>
              <MentionsSolutions nom={nom} />
            </SGGridCol>
          </SGGridRow>
        </SGBox>
        <SGSticky>
          <SGContent disableautomargin>
            <SGGridRow align="middle" justify="center" gutter={[0, 0]}>
              <SGMediaQuery minwidth="sm">
                <SGGridCol flex="1 0 0%">
                  <SGTitle level={2} visuallevel={4}>
                    <FormattedMessage id="nosSolutions.sticky.titre" />
                  </SGTitle>
                </SGGridCol>
              </SGMediaQuery>
              <SGGridCol flex="0 0 0%">
                <BoutonSGAgenda
                  disableautomargin
                  type="primary"
                  icon={
                    <SGIcon
                      component={<SGAvenirStrokedMesRendezVous />}
                      currentcolor
                    />
                  }
                  onClick={handleOnClickRdv}
                >
                  <FormattedMessage id="nosSolutions.sticky.bouton" />
                </BoutonSGAgenda>
              </SGGridCol>
            </SGGridRow>
          </SGContent>
        </SGSticky>
      </SGContent>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </>
  );
};

export { NosSolutionsTemplate };
