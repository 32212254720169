// State
export interface Login {
   username: string;
   password: string;
}

export interface LoginAs extends Login {
   logAsUsername: string;
   demoMode: boolean;
}

export interface LoginState {
   login: Login;
   isLoggedIn: boolean;
   isPasswordInvalid: boolean;
   forceLogOut: boolean;
   logoutRedirection?: string;
}

export interface NewPassword {
   key: string;
   newPassword: string;
}

// Actions
export const LOGIN = "LOGIN";
export const REQUEST_NEW_PASSWORD = "REQUEST_NEW_PASSWORD";
export const SUBMIT_NEW_PASSWORD = "SUBMIT_NEW_PASSWORD";
export const LOGIN_AS = "LOGIN_AS";
export const USER_LOGOUT = "USER_LOGOUT";
export const ERR_NO_KEY = "ERR_NO_KEY";
