import { PlafondConjointUsageEnum, ProvisionEnum, TnsStatusEnum } from "store/simulateurEconomiesImpots/types";

export interface SimulateurPlafondEconomieImpotState {
   parameters: ParametresPlafondEconomieImpot;
   plafondsSEI: PlafondsSEI;
   hasFetched: boolean;
   plafondsSEIError?: string;
}

// Objet récupéré du service sapiendo correspondant aux plafonds du client et de son conjoint, et le plafond total
export interface PlafondsSEI {
   availableCeiling: number;
   personalCeiling: number;
   partnerCeiling: number;
   simulationKey?: string;
}

// Objet envoyé à backPFR pour le service calculant les plafonds du client et de son conjoint, et le plafond total
export interface ParametresPlafondEconomieImpot {
   taxIncome?: number;
   tnsStatus?: TnsStatusEnum;
   isJointDeclaration?: boolean;
   partnerTaxIncome?: number;
   remainingCeilingN1?: number;
   remainingCeilingN2?: number;
   remainingCeilingN3?: number;
   donePayment?: number;
   remainingPartnerCeilingN1?: number;
   remainingPartnerCeilingN2?: number;
   remainingPartnerCeilingN3?: number;
   isPartnerCeilingMutualized?: boolean;
   isPartnerTns?: boolean;
   partnerDonePayment?: number;
   usePartnerCeiling?: PlafondConjointUsageEnum;
   contractType? : ProvisionEnum;
}

export const FETCH_TAX_ECONOMY_CEILING_SIMULATION = "FETCH_TAX_ECONOMY_CEILING_SIMULATION";
