import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { globalError502 } from "store/error/errorsSlice";
import { blocageSupervision } from "store/supervision/sagas";
import { apiGet, apiPost, apiPut } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { CREATE_PREFS, DASHBOARD_FETCHED, Dashboard, FETCH_DASHBOARD, PREFS_UPDATED, UPDATE_HAS_FETCHED_AGES, UPDATE_PREFS, UPDATE_RELOAD_PREFS, User } from "./types";
import { logError } from "website/utils/logging";

export function* watcherSaga() {
   yield takeLeading(FETCH_DASHBOARD, safe(onApiCallError, handleFetchDashboard));
   yield takeLeading(CREATE_PREFS, safe(onApiCallError, handleCreatePrefs));
   yield takeLatest(UPDATE_PREFS, safe(onDefaultError, handleUpdatePrefs));
   yield takeLatest(UPDATE_RELOAD_PREFS, safe(onDefaultError, handleUpdateAndReloadPrefs));
}

function* handleFetchDashboard(action?: PayloadAction<boolean>) {
   const payload: AxiosResponse<Dashboard> = yield call(apiGet, `/backpfr/api/userRetirementPath`, process.env.REACT_APP_API_URL);
   yield put({ type: DASHBOARD_FETCHED, payload: payload.data });
   yield put({type: UPDATE_HAS_FETCHED_AGES, payload: action?.payload ?? false })
}

function* onApiCallError(err: any) {
   // Pour la redirection vers la page de maintenance
   logError("Erreur fatale dashboard", err);
   yield put(globalError502());
}

function* handleCreatePrefs() {
   yield call(apiPost, `/backpfr/api/userRetirementPath`);
   yield put({ type: PREFS_UPDATED, payload: {} });
}

function* handleUpdatePrefs(action: PayloadAction<User>) {
   const blocage: boolean = yield blocageSupervision();
   if (blocage) {
      // On est en supervision, on n'importe pas les datas
      return;
   }

   yield call(apiPut, `/backpfr/api/userRetirementPath`, action.payload);
   yield put({ type: PREFS_UPDATED, payload: action.payload });
}

function* handleUpdateAndReloadPrefs(action: PayloadAction<User>) {
   const blocage: boolean = yield blocageSupervision();
   if (blocage) {
      // On est en supervision, on n'importe pas les datas
      return;
   }

   yield call(apiPut, `/backpfr/api/userRetirementPath`, action.payload);
   yield put({ type: PREFS_UPDATED, payload: action.payload });

   yield handleFetchDashboard();
}
