export interface ErrorState {
   hasError: boolean;
   message: string;
   type: ErrorType;
   errorCodes?: Array<string>;
   errorCodesParameters?: Record<string, string>;
}

export interface ElyxirError {
   detail?: string,
   errorCode?: string,
   errorParams?: string;
   fieldErrors?: Array<{ message: string }>,
   message?: string
}

export enum ErrorType {
   NO_ERROR = "NO_ERROR",
   // 400
   BAD_INPUT_ERROR = "BAD_INPUT_ERROR",
   // 401
   AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR",
   // 403
   FORBIDDEN_ERROR = "FORBIDDEN_ERROR",
   // 404, 500, 504
   NON_FATAL_ERROR = "NON_FATAL_ERROR",
   // 502
   FATAL_ERROR = "FATAL_ERROR",
   UNAVAILABLE_ERROR = "UNAVAILABLE_ERROR",
   TIME_OUT_ON_PDF_DOWNLOAD_ERROR = "TIME_OUT_ON_PDF_DOWNLOAD_ERROR",
   IMPORT_ERROR = "IMPORT_ERROR"
}

export const RESET_ERRORS = "RESET_ERRORS";
