import { PayloadAction } from "@reduxjs/toolkit";
import {call, put, PutEffect} from "redux-saga/effects";
import {globalError, globalError400, globalError401, globalError403, globalError404, globalError500, globalError502, globalError504, globalNoError500} from "./errorsSlice";
import { AxiosError } from "axios";

export const safe = <T>(handler: ((err: AxiosError) => Generator<PutEffect<{ payload: unknown, type: string }>, void, unknown>) | undefined, saga: (action: PayloadAction<T>) => Generator) =>
   function* safe(action: PayloadAction<T>) {
      try {
         yield call(saga, action);
      } catch (err) {
         if (handler) {
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            yield call(handler, err);
         }
      }
   };

export function* onDefaultError(err: AxiosError) {
   if (err.response) {
      const {response} = err;
      switch (response?.status) {
         case 400:
            yield put(globalError400(err));
            break;
         case 401:
            yield put(globalError401());
            break;
         case 403:
            yield put(globalError403());
            break;
         case 404:
            yield put(globalError404());
            break;
         case 500:
            yield put(globalError500());
            break;
         case 502:
            yield put(globalError502());
            break;
         case 504:
            yield put(globalError504());
            break;
         default:
            yield put(globalError("Erreur inconnue"));
            break;
      }
   } else if (err.stack) {
      yield put(globalError(err.stack));
   } else {
      yield put(globalError(String(err)));
   }
}

export function* onApiCallErrorNoError(err: AxiosError) {
   if (err.response?.status === 500) {
      yield put(globalNoError500());
   } else {
      onDefaultError(err);
   }
}
