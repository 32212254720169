import { SituationsHandicapEnum } from "store/simulationAssu/types";

export interface PersonalInformations {
   situationFamiliale?: string;
   nivEtude?: string;
   travailEtranger?: boolean;
   categorieActive?: boolean;
   traitementIndiciaire?: number;
   serviceMilitaire?: boolean;
   dateConsentementSituationsHandicap?: Date;
   situationsHandicap? : SituationsHandicapEnum[];
}
export interface PersonalInformationsState {
   personalInformations: PersonalInformations;
   hasFetched: boolean;
}

export const hasFetchedPersonalInformations = (PersonalInformationsState: PersonalInformationsState): boolean => PersonalInformationsState.hasFetched;

export const FETCH_PERSONAL_INFORMATIONS = "FETCH_PERSONAL_INFORMATIONS";
export const PERSONAL_INFORMATIONS_FETCHED = "PERSONAL_INFORMATIONS_FETCHED";
export const SAVE_PERSONAL_INFORMATIONS = "SAVE_PERSONAL_INFORMATIONS";
export const PERSONAL_INFORMATIONS_SAVED = "PERSONAL_INFORMATIONS_SAVED";
export const UPDATE_PERSONAL_INFORMATIONS_STORE = "UPDATE_PERSONAL_INFORMATIONS_STORE";
