import React, {FunctionComponent, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IntlProvider} from "react-intl";
import {State} from "../../../store/store";
import {AccountState} from "../../../store/account/types";
import messagesFr from "../../../locales/fr.json";
import messagesEn from "../../../locales/en.json";
import {logError} from "../logging";

const IntlLocaleProvider: FunctionComponent = ({children}) => {
   const accountState = useSelector<State, AccountState>(
      (state) => state.account
   );

   const [isProfileFrTranslationLoaded, setProfileFrTranslationLoaded] = useState(false);
   const [isProfileEnTranslationLoaded, setProfileEnTranslationLoaded] = useState(false);

   const [allMessagesFr, setAllMessagesFr] = useState(messagesFr);
   const [allMessagesEn, setAllMessagesEn] = useState(messagesEn);

   const [locale, setLocale] = useState("fr");
   const [messages, setMessages] = useState(accountState.account.langKey === "en" ? allMessagesEn : allMessagesFr);

   if (!isProfileFrTranslationLoaded && window.env.REACT_APP_INSTANCE !== undefined) {
      import(`../../../locales/${window.env.REACT_APP_INSTANCE}/fr.json`).then(
         (messagesEnvFr: JSON) => {
            setAllMessagesFr({
               ...messagesFr,
               ...messagesEnvFr,
            });

            if (accountState.account.langKey === "fr") {
               setMessages(allMessagesFr);
            }
            setProfileFrTranslationLoaded(true);
         }
      );
   }
   if (!isProfileEnTranslationLoaded && window.env.REACT_APP_INSTANCE !== undefined) {
      import(`../../../locales/${window.env.REACT_APP_INSTANCE}/en.json`).then(
         (messagesEnvEn: JSON) => {
            setAllMessagesEn({
               ...messagesEn,
               ...messagesEnvEn,
            });

            if (accountState.account.langKey === "en") {
               setMessages(allMessagesEn);
            }

            setProfileEnTranslationLoaded(true);
         }
      );
   }

   useEffect(() => {
      if (accountState.account.langKey) {
         setLocale(accountState.account.langKey);
      } else {
         logError("langKey was null in user account");
      }
      if (accountState.account.langKey === "en") {
         setMessages(allMessagesEn);
      } else {
         setMessages(allMessagesFr);
      }
   }, [accountState.account.langKey, allMessagesFr, allMessagesEn]);

   return (
      <IntlProvider messages={messages} locale={locale || "fr"} defaultLocale="fr">
         {isProfileFrTranslationLoaded && isProfileEnTranslationLoaded && children}
      </IntlProvider>
   );
};

export {IntlLocaleProvider};
