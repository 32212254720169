import { FunctionComponent, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { SGIcon } from "sg-icon";
import { SGAvenirNavPlus } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { SGUploadDragger } from "sg-upload";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface FileWithStatus extends File {
  status: string;
  errorMessage: string;
}

interface FileUpload {
  file: FileWithStatus;
  fileList: FileWithStatus[];
}

interface UploadButtonProps {
  setUploadedFile: (file?: File) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  maxSize?: number;
  errorMessageSize: string;
  onClick?: () => void;
}

const UploadButton: FunctionComponent<UploadButtonProps> = (
  props: UploadButtonProps
) => {
  const intl = useIntl();
  const {
    setUploadedFile,
    errorMessage,
    setErrorMessage,
    maxSize,
    errorMessageSize,
    onClick,
  } = props;
  const { trackClick } = useTracking();

  const [uploadedFiles, setUploadedFiles] = useState<FileWithStatus[]>([]);

  const handleBeforeUpload = (file: FileWithStatus) => {
    if (!file) {
      setErrorMessage("upload.file.erreur.empty");
    }

    return false;
  };

  const handleFileUpload = (upload: FileUpload) => {
    const { file } = upload;
    if (
      file &&
      (!maxSize || file.size <= maxSize) &&
      file.status !== "removed"
    ) {
      setUploadedFile(file);
      setErrorMessage("");
    }
    // mise à jour de la liste actuelle des fichiers
    setUploadedFiles(
      upload.fileList.map((fileSelect) => ({
        ...fileSelect,
        status: maxSize && fileSelect.size > maxSize ? "error" : "done",
        errorMessage:
          maxSize && file.size > maxSize
            ? intl.formatMessage({ id: errorMessageSize })
            : "",
      }))
    );
  };

  const handleRemoveFile = () => {
    trackClick("clic-sur-supprimer-fichier");
    setUploadedFiles([]);
    setErrorMessage("");
    setUploadedFile(undefined);
  };

  const margeConteneur = useMemo(() => {
    if (uploadedFiles.length === 0 && !errorMessage) {
      return { top: "sm", bottom: "md" };
    }

    return { top: "sm", bottom: "sm" };
  }, [uploadedFiles, errorMessage]);

  return (
    <SGBox padding={margeConteneur}>
      <>
        <SGUploadDragger
          accept=".pdf"
          singleFile
          defaultFileList={uploadedFiles}
          fileList={uploadedFiles}
          onRemove={handleRemoveFile}
          beforeUpload={handleBeforeUpload} // désactivation de l'envoi automatique du fichier au backend.
          onChange={handleFileUpload}
          onClick={onClick}
        >
          <SGIcon component={<SGAvenirNavPlus />} size="m" />
          <SGBox margin={{ left: "md", right: "md", top: "xxs" }}>
            <SGBox>
              <SGText size="m">
                {intl.formatMessage(
                  { id: "parcoursRIS.preparation.parcourir" },
                  {
                    p: (word: string) => (
                      <SGBox>
                        <>{word}</>
                      </SGBox>
                    ),
                  }
                )}
              </SGText>
            </SGBox>
          </SGBox>
        </SGUploadDragger>
        {errorMessage && (
          <SGBox margin={errorMessage ? { top: "md" } : {}}>
            <SGText color="error">
              {intl.formatMessage({ id: errorMessage })}
            </SGText>
          </SGBox>
        )}
      </>
    </SGBox>
  );
};

export { UploadButton };
