import { useDispatch, useSelector } from "react-redux";
import {
  DashboardAge,
  DashboardAgeType,
  DashboardState,
} from "store/dashboard/types";
import { updateParcoursRisInformationsComplementaires } from "store/parcoursRisInformationsComplementaires/actions";
import {
  ParcoursRisInformationsComplementaires,
  ParcoursRisInformationsComplementairesState,
} from "store/parcoursRisInformationsComplementaires/types";
import { updateParcoursRisProjection } from "store/parcoursRisProjection/actions";
import {
  ParcoursRisProjection,
  ParcoursRisProjectionState,
  RetirementAge,
  RetirementTypesConversion,
} from "store/parcoursRisProjection/types";
import { getDate } from "website/utils/date/DateUtils";
import { State } from "../../../store/store";
import { useSelectedRetirementAge } from "./dashboard/useSelectedRetirementAge";
import { useState, useMemo, useCallback } from "react";

/**
 * Permet d'initaliser parcoursRisProjection et parcoursRisInformationsComplementaires en fonction des valeurs stockées dans dashboardState.dashboard
 */
export const useResultatRis = () => {
  const dispatch = useDispatch();
  const [isUpdateResultatRis, setIsUpdateResultatRis] = useState(false);
  const { agesTries } = useSelectedRetirementAge();
  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const parcoursRisProjectionState: ParcoursRisProjectionState = useSelector<
    State,
    ParcoursRisProjectionState
  >((state) => state.parcoursRisProjection);
  const parcoursRisInformationsComplementairesState: ParcoursRisInformationsComplementairesState =
    useSelector<State, ParcoursRisInformationsComplementairesState>(
      (state) => state.parcoursRisInformationsComplementaires
    );

  const hasUpdateResultatRis = useMemo(
    () =>
      parcoursRisProjectionState.parcoursRisProjection?.dates !== undefined &&
      parcoursRisProjectionState.parcoursRisProjection?.fiabilite !==
        undefined &&
      parcoursRisInformationsComplementairesState
        .parcoursRisInformationsComplementaires?.acquis !== undefined &&
      isUpdateResultatRis,
    [
      parcoursRisProjectionState,
      parcoursRisInformationsComplementairesState,
      isUpdateResultatRis,
    ]
  );
  const hasAgesTries = useMemo(() => agesTries !== undefined, [agesTries]);

  const convertDashboardAge = (age: DashboardAge) => ({
      age: {
        years: age.retirementAgeYear,
        months: age.retirementAgeMonth,
      },
      date: getDate(new Date(age.retirementYear, age.retirementMonth - 1, 1)),
      pension_mensuelle: age.retirementMonthlyIncome,
      dernier_revenu_estime_mensuel: age.lastMonthlyIncome,
      revenus_mensuels_nets_totaux: age.retirementMonthlyIncome,
      types_depart: age.typesDepart.map((typeDepart: DashboardAgeType) => RetirementTypesConversion[typeDepart]),
    });

  const updateResultatRis = useCallback(() => {
    if (
      hasAgesTries &&
      dashboardState.dashboard &&
      dashboardState.dashboard.reliability &&
      dashboardState.dashboard.ages &&
      dashboardState.dashboard.risDate &&
      dashboardState.dashboard.acquiredQuarters !== undefined &&
      dashboardState.dashboard.remainingQuarters !== undefined
    ) {
      const ages: RetirementAge[] =
        agesTries?.map(convertDashboardAge) ?? [];
      const parcoursRisProjection: Partial<ParcoursRisProjection> = {
        dates: ages,
        fiabilite: dashboardState.dashboard.reliability,
      };
      dispatch(updateParcoursRisProjection(parcoursRisProjection));

      const ageDepart = agesTries?.find(
        (date) =>
          date.type === DashboardAgeType.age_taux_plein ||
          date.type === DashboardAgeType.age_taux_plein_auto
      );
      const parcoursRisInformationsComplementaires: Partial<ParcoursRisInformationsComplementaires> =
        {
          acquis: {
            age_depart: {
              years: ageDepart?.retirementAgeYear ?? 0,
              months: ageDepart?.retirementAgeMonth ?? 0,
            },
            date_depart: dashboardState.dashboard.departureDate ?? "",
            trimestres_acquis: dashboardState.dashboard.acquiredQuarters ?? 0,
            trimestres_total:
              (dashboardState.dashboard.acquiredQuarters ?? 0) +
              (dashboardState.dashboard.remainingQuarters ?? 0),
            trimestres_restants:
              dashboardState.dashboard.remainingQuarters ?? 0,
            date_trimestre_restants: dashboardState.dashboard.risDate ?? "",
            types_depart: [],
            statuts: [],
          },
        };
      dispatch(
        updateParcoursRisInformationsComplementaires(
          parcoursRisInformationsComplementaires
        )
      );
      setIsUpdateResultatRis(true);
    }
  }, [hasAgesTries, agesTries, dashboardState]);

  return { updateResultatRis, hasUpdateResultatRis, hasAgesTries };
};
