import { FC } from "react";
import { useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGBox } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import {
  NomNosSolutionsEnum,
  parseLink,
} from "website/components/molecules/NosSolutions/utils";

interface MentionsSolutionsProps {
  nom: NomNosSolutionsEnum;
}

export const MentionsSolutions: FC<MentionsSolutionsProps> = (props) => {
  const { nom } = props;
  const intl = useIntl();
  const openLink = useOpenLink();

  return (
    <>
      <SGText size="xs" whitespace="pre-line">
        {intl.formatMessage(
          {
            id: `nosSolutions.${nom}.section.allerPlusLoin.indices`,
          },
          {
            b: (word) => <SGText strong>{word}</SGText>,
            sup: (word) => <sup>{word}</sup>,
            ul: (word) => (
              <SGBlock disableautomargin component="ul">
                {word}
              </SGBlock>
            ),
            li: (word) => <li>{word}</li>,
            box: (word) => (
              <SGBox margin={{ bottom: "sm" }}>
                <>{word}</>
              </SGBox>
            ),
            link: (word) => parseLink(word[0], openLink),
          }
        )}
      </SGText>
      <SGAccordion expandIconPosition="right" divider>
        <SGAccordionPanel
          header={
            <SGTitle level={4}>
              {intl.formatMessage({
                id: `nosSolutions.${nom}.section.allerPlusLoin.mention.titre`,
              })}
            </SGTitle>
          }
        >
          <SGText>
            {intl.formatMessage(
              {
                id: `nosSolutions.${nom}.section.allerPlusLoin.mention.texte`,
              },
              {
                box: (word) => <SGBox margin={{ bottom: "md" }}>{word}</SGBox>,
              }
            )}
          </SGText>
        </SGAccordionPanel>
      </SGAccordion>
    </>
  );
};
