import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  AssetType,
  AssetWithValuation,
  REAL_ESTATE_ASSETS,
  RealEstateAsset,
} from "../../../../store/assets/types";
import { AssetEdit } from "../AssetEdit/AssetEdit";
import {
  AssetEditFormData,
  computeValuationPerAssetType,
  isAssociatedToSCI,
  parseFormDataForAssetMemberLink,
  parseFormDataForLoanAsset,
  parseFormDataForRealEstateRentAsset,
  parseFormDataForSCI,
} from "../../../../store/assets/utils";
import {
  deleteAsset,
  updateAsset,
  updateRealEstateAsset,
  updateSCIAsset,
} from "../../../../store/assets/actions";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import { isSCIAssetType } from "../../../../store/assets/typeguards";
import { useSCIAssets } from "../../hooks/useSCIAssets";
import { useLoans } from "../../hooks/useLoans";
import { useFamily } from "../../hooks/useFamily";
import { EditorContext } from "../../providers/EditorProvider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGText } from "sg-typo";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedCorbeille,
  SGAvenirStrokedModification,
} from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { CustomNumberFormat } from "website/utils/formatting/CustomNumberFormat";
import { SGBox } from "sg-space";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";

interface AssetElementProps {
  asset: AssetWithValuation;
  associatedRealEstateAssets: RealEstateAsset[] | undefined;
  isAssetMemberLinkDisabled: boolean;
  displayAssetShares: boolean;
  cypressName: string;
}

const AssetElement: FunctionComponent<AssetElementProps> = ({
  asset,
  cypressName,
  associatedRealEstateAssets,
  isAssetMemberLinkDisabled,
  displayAssetShares,
}: AssetElementProps) => {
  const [isEditingAsset, setIsEditingAsset] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const { isEditing, setEditing } = useContext(EditorContext);

  const dispatch = useDispatch();
  const sciAssets = useSCIAssets();
  const family = useFamily();
  const loans = useLoans([]);

  useEffect(() => {
    setIsEditingAsset(false);
    if (isDeleteModalVisible) {
      setDeleteModalVisible(false);
    }
  }, [asset]);

  useEffect(() => {
    setEditing(isEditingAsset);
  }, [isEditingAsset]);

  const isImportedAsset = (): boolean => asset.externalId !== null;

  const handleClickRemove = () => {
    if (asset.id != null) {
      dispatch(deleteAsset(asset));
    }
  };

  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onChange",
  });

  const renderAssetElement = () => (
    <SGGridRow
      data-cy={`${cypressName}-element`}
      justify="center"
      align="middle"
    >
      <SGMediaQuery minwidth="sm">
        <SGGridCol xs={12} sm={6}>
          <SGText size="l">{asset.assetName}</SGText>
        </SGGridCol>
        <SGGridCol xs={12} sm={3}>
          {!isAssociatedToSCI(sciAssets, asset) && (
            <SGText size="l">
              <CustomNumberFormat
                thousandSeparator={" "}
                suffix=" €"
                displayType="text"
                value={computeValuationPerAssetType(asset, loans)}
              />
            </SGText>
          )}
        </SGGridCol>
      </SGMediaQuery>
      <SGMediaQuery minwidth={0} maxwidth="xs">
        <SGGridCol span={8}>
          <SGGridRow>
            <SGText size="l">{asset.assetName}</SGText>
          </SGGridRow>
          <SGGridRow>
            {!isAssociatedToSCI(sciAssets, asset) && (
              <SGText weight="600" size="l">
                <CustomNumberFormat
                  thousandSeparator={" "}
                  suffix=" €"
                  displayType="text"
                  value={computeValuationPerAssetType(asset, loans)}
                />
              </SGText>
            )}
          </SGGridRow>
        </SGGridCol>
      </SGMediaQuery>
      <SGGridCol span={4} sm={3} textalign="right">
        {!isImportedAsset() && (
          <BoutonSupervision
            type="icon"
            disabled={isEditing}
            onClick={() => setIsEditingAsset(true)}
            icon={<SGIcon component={<SGAvenirStrokedModification />} />}
            cypressName={`${cypressName}-edit-button`}
          />
        )}
        <SGBox margin={{ left: "sm" }} inline>
          <BoutonSupervision
            type="icon"
            disabled={isEditing}
            onClick={() => setDeleteModalVisible(true)}
            icon={<SGIcon component={<SGAvenirStrokedCorbeille />} />}
            cypressName={`${cypressName}-delete-button`}
          />
        </SGBox>
      </SGGridCol>
    </SGGridRow>
  );

  const onSubmit: SubmitHandler<FieldValues> = (formData: FieldValues) => {
    const data = formData as AssetEditFormData;
    let currentAsset: Partial<RealEstateAsset> = {
      ...asset,
      assetName: data.assetName,
      assetValuation: {
        ...asset.assetValuation,
        totalValuation: data.totalValuation,
      },
      acquisitionDate: data.acquisitionDate,
      loans: [],
    };

    if (data.assetMemberLink) {
      currentAsset.assetMemberLinkSet = parseFormDataForAssetMemberLink(
        data,
        family
      );
    } else {
      currentAsset.assetMemberLinkSet = asset.assetMemberLinkSet;
    }

    if (data.loans) {
      currentAsset.loans = parseFormDataForLoanAsset(data, loans);
    }

    if (asset.assetType === AssetType.REAL_ESTATE_RENT) {
      currentAsset = parseFormDataForRealEstateRentAsset(currentAsset, data);
    }

    if (isSCIAssetType(currentAsset) && associatedRealEstateAssets) {
      currentAsset.isTaxTransparent = data.isTaxTransparent;
      currentAsset.associatedRealEstateAssets = parseFormDataForSCI(
        data,
        associatedRealEstateAssets
      );
    }

    if (
      !!currentAsset.assetType &&
      REAL_ESTATE_ASSETS.includes(currentAsset.assetType)
    ) {
      dispatch(updateRealEstateAsset(currentAsset));
    } else if (isSCIAssetType(currentAsset)) {
      dispatch(updateSCIAsset(currentAsset));
    } else {
      dispatch(updateAsset(currentAsset as AssetWithValuation));
    }
  };

  const renderAssetEdit = () => (
    <FormProvider {...methods}>
      <form>
        <AssetEdit
          {...methods}
          asset={asset}
          onCancel={() => setIsEditingAsset(false)}
          onSubmit={onSubmit}
          isRealEstateIncome={false}
          cypressName={`${cypressName}-edit`}
          isAssetMemberLinkDisabled={isAssetMemberLinkDisabled}
          displayAssetShares={displayAssetShares}
        />
      </form>
    </FormProvider>
  );

  return (
    <>
      {isEditingAsset && renderAssetEdit()}
      {!isEditingAsset && renderAssetElement()}

      {/* Modal de confirmation de suppression */}
      {isDeleteModalVisible && (
        <DeleteModal
          hideModal={() => setDeleteModalVisible(false)}
          onSubmit={handleClickRemove}
          cypressName={cypressName}
          textId="common.delete.modal.text"
        />
      )}
    </>
  );
};

export { AssetElement };
