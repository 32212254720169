import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AssetWithValuation } from "store/assets/types";
import { DashboardState } from "store/dashboard/types";
import { State } from "../../../store/store";

const useParcoursPatrimoine = () => {
  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );

  const assets: AssetWithValuation[] = useSelector<State, AssetWithValuation[]>(
    (state) => state.assets.assets
  );

  const isParcoursPatrimoineDone =
    dashboardState?.dashboard?.firstPatrimonyPathDate !== undefined;

  const isAssetsChecked = useMemo(
    () =>
      assets?.filter((asset) => asset.percentageUsedForRetirement > 0)
        .length !== 0,
    [assets]
  );

  return {
    isParcoursPatrimoineDone,
    isAssetsChecked,
  };
};

export { useParcoursPatrimoine };
