import { useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "store/store";

// Liste des sous segments non autorisés
const lstSousSegments = ["12", "13"];

// Liste des sous segments autorisés
const lstSousSegmentsWebCallback = ["13", "14", "15", "16", "05"];

export const useDroitsSegment = () => {
  const codeSegmentMarcheNSM: string = useSelector<State, string>(
    (state) => state.account.account.codeSegmentMarcheNSM ?? ""
  );

  const potentielSousSegmentNSM: string = useSelector<State, string>(
    (state) => state.account.account.potentielSousSegmentNSM
  );

  const isDiagnosticEpargneAvailable = useMemo(
    () => !lstSousSegments.includes(potentielSousSegmentNSM),
    [potentielSousSegmentNSM]
  );

  const isWebCallbackAvailable = useMemo(
    () => codeSegmentMarcheNSM === "30" && lstSousSegmentsWebCallback.includes(potentielSousSegmentNSM),
    [potentielSousSegmentNSM, codeSegmentMarcheNSM]
  );

  return { isDiagnosticEpargneAvailable, isWebCallbackAvailable };
};
