import {call, put, takeLeading} from "redux-saga/effects";
import {CREATE_REALIZED_DONATION, DELETE_REALIZED_DONATION, FETCH_REALIZED_DONATIONS, RealizedDonation, UPDATE_REALIZED_DONATION} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiDelete, apiGet, apiPost, apiPut} from "../apiCaller";
import {PayloadAction} from "@reduxjs/toolkit";
import {getMembersAction} from "../members/actions";
import {realizedDonationCreated, realizedDonationDeleted, realizedDonationsFetched, realizedDonationUpdated} from "./realizedDonationsSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeLeading(FETCH_REALIZED_DONATIONS, safe(onDefaultError, handleGetRealizedDonations));
   yield takeLeading(UPDATE_REALIZED_DONATION, safe(onDefaultError, handleUpdateRealizedDonation));
   yield takeLeading(CREATE_REALIZED_DONATION, safe(onDefaultError, handleCreateRealizedDonation));
   yield takeLeading(DELETE_REALIZED_DONATION, safe(onDefaultError, handleDeleteRealizedDonation));
}

function* handleGetRealizedDonations() {
   const payload: AxiosResponse<RealizedDonation[]> = yield call(apiGet, `wealth/api/donations`);
   yield put(realizedDonationsFetched(payload.data));
}

function* handleCreateRealizedDonation(action: PayloadAction<Omit<RealizedDonation, "id">>) {
   const payload: AxiosResponse<RealizedDonation> = yield call(apiPost, `wealth/api/donations`, action.payload);
   yield put(realizedDonationCreated(payload.data));
   if (action.payload.member?.id === undefined) {
      yield put(getMembersAction());
   }
}

function* handleUpdateRealizedDonation(action: PayloadAction<RealizedDonation>) {
   const payload: AxiosResponse<RealizedDonation> = yield call(apiPut, `wealth/api/donations/${action.payload.id}`, action.payload);
   yield put(realizedDonationUpdated(payload.data));
   if (action.payload.member?.id === undefined) {
      yield put(getMembersAction());
   }
}

function* handleDeleteRealizedDonation(action: PayloadAction<RealizedDonation>) {
   yield call(apiDelete, `wealth/api/donations/${action.payload.id}`);
   yield put(realizedDonationDeleted(action.payload));
}
