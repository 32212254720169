import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { State } from "../../../../../store/store";
import { SignUpState } from "../../../../../store/signup/types";
import { AccountState, isLoggedIn } from "../../../../../store/account/types";
import { signUp } from "../../../../../store/signup/actions";
import { FieldValues } from "react-hook-form";

interface UseSignUpReturnType {
  shouldNavigateToPrivatePage: boolean;
  signUpState: SignUpState;
  accountState: AccountState;
  onSubmit: (data: FieldValues) => void;
}

const useSignUp = (): UseSignUpReturnType => {
  const dispatch = useDispatch();

  const [shouldNavigateToPrivatePage, setShouldNavigateToPrivatePage] =
    useState<boolean>(false);

  const signUpState = useSelector<State, SignUpState>((state) => state.signUp);
  const accountState = useSelector<State, AccountState>(
    (state) => state.account
  );

  useEffect(() => {
    if (isLoggedIn(accountState)) {
      setShouldNavigateToPrivatePage(true);
    }
  }, [signUpState, accountState.account.id]);

  const onSubmit = (data: FieldValues) => {
    const signUpData = {
      firstname: data.firstname,
      email: data.email,
      login: data.email,
      password: data.password,
    };
    dispatch(signUp(signUpData));
  };

  return {
    shouldNavigateToPrivatePage,
    signUpState,
    accountState,
    onSubmit,
  };
};

export { useSignUp };
