import { NEXT_STEP, PREVIOUS_STEP, RESET_TUNNEL, GO_TO_STEP } from "./types";

export const resetTunnelAction = () => ({
   type: RESET_TUNNEL
});

export const nextStepAction = () => ({
   type: NEXT_STEP
});

export const previousStepAction = () => ({
   type: PREVIOUS_STEP
});

export const goToStep = (step: number) => ({
   type: GO_TO_STEP,
   payload: step
});
