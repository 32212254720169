import moment from "moment";
import { FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  SGAvenirStrokedBouclierOk,
  SGAvenirStrokedCaracteristiqueCompte,
  SGAvenirStrokedPartenariat,
} from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { callPersonnalisationQuestions } from "store/personnalisation/questions/actions";
import {
  ChoixType,
  ParametresPersonnalisationQuestions,
} from "store/personnalisation/questions/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { nextStepAction } from "store/tunnel/actions";
import {
  PAGE_CATEGORY_3_ONBOARDING,
  PAGE_NAME_MA_RETRAITE,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";

function getIdTrackingChoix(choix: ChoixType) {
  switch (choix) {
    case ChoixType.SEREIN:
      return "serein";
    case ChoixType.INQUIET:
      return "inquiet";
    case ChoixType.CURIEUX:
      return "curieux";
    default:
      return "";
  }
}

export const TunnelVisionRetraite: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const { trackPage, trackClick } = useTracking();

  const pageName: string | undefined = useSelector<State, string | undefined>(
    (state) => state.tracking.pageName
  );

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const hasFetched: boolean = useSelector<State, boolean>(
    (state) => state.personnnalisationQuestions.hasFetched
  );

  const family = useFamily();

  const choicesType = Object.keys(ChoixType);

  const possedeDroitsAction = possedeDroitsActionUtilisateur(supervisionState);

  useEffect(() => {
    if (pageName !== PAGE_NAME_MA_RETRAITE) {
      trackPage(
        PAGE_CATEGORY_3_ONBOARDING,
        PAGE_NAME_MA_RETRAITE,
        PAGE_TYPE_FORMULAIRE,
        "inscription",
        "1"
      );
    }
  }, []);

  useEffect(() => {
    if (hasFetched) {
      dispatch(nextStepAction());
    }
  }, [hasFetched]);

  const handleChoice = (choice: string) => {
    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    const choixType = (ChoixType as any)[choice];
    trackClick(
      `clic-sur-card-je-suis-${getIdTrackingChoix(
        choixType
      )}`
    );
    const parametresPersonnalisation: ParametresPersonnalisationQuestions = {
      choice: choixType,
      age: moment().diff(family.me.birthday ?? "01/01/1970", "years"),
    };
    dispatch(callPersonnalisationQuestions(parametresPersonnalisation));
  };

  function getIcone(choiceType: string): JSX.Element | undefined {
    switch (choiceType) {
      case ChoixType.SEREIN:
        return <SGAvenirStrokedBouclierOk />;
      case ChoixType.INQUIET:
        return <SGAvenirStrokedPartenariat />;
      case ChoixType.CURIEUX:
        return <SGAvenirStrokedCaracteristiqueCompte />;
      default:
        return undefined;
    }
  }

  return (
    <SGSpace direction="vertical" disableautomargin sm={{ size: "xl" }}>
      <SGTitle key="titre" level={2}>
        <FormattedMessage id="tunnel.vision.title" />
      </SGTitle>
      <SGBox key="box" span={isPhone ? 12 : 6}>
        <SGSpace direction="vertical" disableautomargin sm={{ size: "md" }}>
          {choicesType.map((choiceType) => (
            <TuileCliquable
              key={choiceType}
              titre={
                <FormattedMessage id={`tunnel.vision.title.${choiceType}`} />
              }
              titreTaille={isPhone ? 3 : 4}
              texte={
                <FormattedMessage id={`tunnel.vision.texte.${choiceType}`} />
              }
              texteTaille="m"
              icone={getIcone(choiceType)}
              onClick={() => handleChoice(choiceType)}
              accessible={possedeDroitsAction}
              ariaLabel={`Choisir l'option ${choiceType}`}
            />
          ))}
        </SGSpace>
      </SGBox>
    </SGSpace>
  );
};
