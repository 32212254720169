import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { TRACKING_CLICK_HYPOTHESES } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { HypothesisAnchor, HypothesisModal } from "website/components/molecules/HypothesisModal/HypothesisModal";

const LegalNotice: FunctionComponent = () => {
    const intl = useIntl();
    const { trackClick } = useTracking();

    const [showHypothesisModal, setShowHypothesisModal] = useState(false);
    const [anchorHypothesis, setAnchorHypothesis] = useState<HypothesisAnchor>();
    const [closeFirstGroupModal, setCloseFirstGroupModal] = useState(false);
    const [closeSecondGroupModal, setCloseSecondGroupModal] = useState(false);

    const openHypothesisModal = (closeFirstGroup: boolean, closeSecondGroup: boolean, anchor?: HypothesisAnchor) => {
        onClickHypothesisModal();
        setAnchorHypothesis(anchor);
        setCloseFirstGroupModal(closeFirstGroup);
        setCloseSecondGroupModal(closeSecondGroup);
    };

    const values = {
        p: (word: string) => <SGBlock component="p">{word}</SGBlock>,
        sapiendo: (word: string) => (
            <SGButton type="link" onClick={() => openHypothesisModal(false, false, HypothesisAnchor.SAPIENDO)}>
                {word}
            </SGButton>
        ),
        patrimoine: (word: string) => (
            <SGButton type="link" onClick={() => openHypothesisModal(true, false)}>
                {word}
            </SGButton>
        ),
        hypothese: (word: string) => (
            <SGButton type="link" onClick={() => openHypothesisModal(true, true)}>
                <SGBox textalign="center">
                    <>{word}</>
                </SGBox>
            </SGButton>
        ),
    };

    function onClickHypothesisModal() {
        trackClick(TRACKING_CLICK_HYPOTHESES);
        setShowHypothesisModal(true);
    }

    return (
        <>
            <SGBlock>
                {intl.formatMessage(
                    { id: "legalNotice.text" },
                    { ...values },
                )}
            </SGBlock>
            {showHypothesisModal && (
                <HypothesisModal
                    setVisible={setShowHypothesisModal}
                    visible={showHypothesisModal}
                    anchor={anchorHypothesis}
                    closeFirstGroup={closeFirstGroupModal}
                    closeSecondGroup={closeSecondGroupModal}
                />
            )}
        </>
    );
};

export { LegalNotice };
