import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { API_ERROR_OTHER } from "store/parcoursRIS/types";
import { plafondEconomieImpotSimulationError } from "./slice";
import { ParametresPlafondEconomieImpot } from "./types";
import {
  ParametresSEI,
  PlafondConjointUsageEnum,
  ProvisionEnum,
} from "store/simulateurEconomiesImpots/types";

export function* onApiCallError(err: any) {
  const { response }: { response: AxiosResponse } = err;
  if (response?.status !== undefined) {
    yield put(plafondEconomieImpotSimulationError(response));
  } else {
    yield put({ type: API_ERROR_OTHER, payload: err });
  }
}

/**
 * Permet de convertir un objet de type "ParametresSEI" en un objet de type "ParametresPlafondEconomieImpot"
 * @param params objet de type "ParametresSEI"
 * @returns objet de type "ParametresPlafondEconomieImpot"
 */
export const convertParametresSEIToParametresPlafondEconomieImpot = (
  params: ParametresSEI
): ParametresPlafondEconomieImpot => {
  const isMadelin = params.provision === ProvisionEnum.MADELIN
  const alimentationConjoint: Partial<ParametresPlafondEconomieImpot> =
    params.isJointDeclaration
      ? {
          partnerTaxIncome: params.partnerTaxIncome,
          isPartnerTns: params.isPartnerTns,
        }
      : {};

      const alimentationConjointMadelin: Partial<ParametresPlafondEconomieImpot> =
      !isMadelin && params.isJointDeclaration
        ? {
            remainingPartnerCeilingN1: params.remainingPartnerCeilingN1,
            remainingPartnerCeilingN2: params.remainingPartnerCeilingN2,
            remainingPartnerCeilingN3: params.remainingPartnerCeilingN3,
            isPartnerCeilingMutualized:
              params.usePartnerCeiling === PlafondConjointUsageEnum.NONE ||
              params.usePartnerCeiling === PlafondConjointUsageEnum.PARTIAL
                ? params.isPartnerCeilingMutualized
                : undefined,
            partnerDonePayment:
              params.usePartnerCeiling === PlafondConjointUsageEnum.PARTIAL
                ? params.partnerDonePayment
                : undefined,
            usePartnerCeiling: params.usePartnerCeiling,
          }
        : {};

  return {
    taxIncome: params.taxIncome,
    isJointDeclaration: params.isJointDeclaration,
    remainingCeilingN1: isMadelin ? undefined : params.remainingCeilingN1,
    remainingCeilingN2: isMadelin ? undefined : params.remainingCeilingN2,
    remainingCeilingN3: isMadelin ? undefined : params.remainingCeilingN3,
    tnsStatus: params.tnsStatus,
    donePayment: params.donePayment,
    contractType: params.provision,
    ...alimentationConjoint,
    ...alimentationConjointMadelin,
  };
};

/* eslint-disable @typescript-eslint/consistent-type-assertions */
const getValueParametresPlafondEconomieImpot = (
  parametresPlafondEconomieImpot: ParametresPlafondEconomieImpot,
  key: string
) =>
  parametresPlafondEconomieImpot[key as keyof ParametresPlafondEconomieImpot];

/**
 * Permet de vérifier si les objets de type 'ParametresPlafondEconomieImpot' sont égaux
 * @param parametresSEI1 paramètre 1
 * @param parametresSEI2 paramètre 2
 * @returns Ils sont égaux
 */
export const isEqualParametresPlafondEconomieImpot = (
  parametresSEI1?: ParametresPlafondEconomieImpot,
  parametresSEI2?: ParametresPlafondEconomieImpot
): boolean => {
  if (parametresSEI1 === undefined || parametresSEI2 === undefined) {
    return false;
  }

  const compareParametresSEI1 = Object.keys(parametresSEI1).some(
    (key) =>
      (getValueParametresPlafondEconomieImpot(parametresSEI1, key) ?? 0) !==
      (getValueParametresPlafondEconomieImpot(parametresSEI2, key) ?? 0)
  );

  const compareParametresSEI2 = Object.keys(parametresSEI2).some(
    (key) =>
      (getValueParametresPlafondEconomieImpot(parametresSEI1, key) ?? 0) !==
      (getValueParametresPlafondEconomieImpot(parametresSEI2, key) ?? 0)
  );

  return !(compareParametresSEI1 || compareParametresSEI2);
};

/**
 * Permet de vérifier si une donnée peut être convertir en JSON
 * @param data la donnée
 * @returns si la donnée est un format JSON
 */
export const isJson = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }

  return true;
};
