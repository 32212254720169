import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { SimulateurAvantApres } from "store/simulateurAvantApres/types";
import { RetirementSimulationAge } from "store/simulationAssu/types";
import { State } from "store/store";
import { Carte } from "./Carte";

enum TypeReforme {
    AVANT = "avant",
    APRES = "apres",
  }

export const Cartes: FunctionComponent = () => {
    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });
    const parametres = useSelector<State, SimulateurAvantApres>((state) => state.simulateurAvantApres.simulateurAvantApres);

    const color = (type: TypeReforme) => ({color: type === TypeReforme.AVANT ? "#545454" : "#010035"});
    const size = {fontSize: isPhone ? "1.5rem" : "2rem"};

    const getAge = (type: TypeReforme, age?: Partial<RetirementSimulationAge>) =>
        <SGText size={isPhone ? "xs" : "m"} weight="700" style={color(type)}>
            {intl.formatMessage({ id: "simulateurAvantApres.resultats.age"},{
                type: <SGText size="m" weight="600"> {intl.formatMessage({ id: `simulateurAvantApres.resultats.${type}` })}</SGText>,
                annee: <SGText type="number" style={size}> {age?.ageAn}</SGText>,
                mois: <SGText type="number" style={size}> {age?.ageMois}</SGText>,
                hidden: (word) => age?.ageMois !== 0 ? word : "",
                date: <SGText weight="400">{ age?.dateDepartRetraite ? new Date(age.dateDepartRetraite).toLocaleDateString() : ""}</SGText>,
                linebreak: <br />,
            })}
        </SGText>

    const getTrimestres = (type: TypeReforme, nbTrimestres: number) =>
        <SGText size={isPhone ? "xs" : "m"} weight="700" style={color(type)}>
            {intl.formatMessage({ id: `simulateurAvantApres.resultats.trimestres${isPhone ? ".mob" : ""}`},{
                type: <SGText size="m" weight="600"> {intl.formatMessage({ id: `simulateurAvantApres.resultats.${type}` })}</SGText>,
                nbTrimestres: <SGText type="number" style={size}> {nbTrimestres}</SGText>,
                linebreak: <br />,
            })}
        </SGText>

    const getDepartAnticipe = (type: TypeReforme, age?: Partial<RetirementSimulationAge>) =>
        <>
            <SGText size="m" weight="600"> {intl.formatMessage({ id: `simulateurAvantApres.resultats.${type}` })}</SGText>
            <SGText size="xs" titlerender style={color(type)}>
                {intl.formatMessage({ id: `simulateurAvantApres.resultats.departAnticipe.${age ? 'oui' : 'non'}`},{
                    annee: age?.ageAn,
                    mois: age?.ageMois,
                    hidden: (word) => age?.ageMois !== 0 ? word : "",
                    strong: (word) => <SGText style={size}> {word}</SGText>,
                    linebreak: <br />,
                })}
            </SGText>
        </>

    const getApresRetraite = (type: TypeReforme) =>
        <SGText size="xs" weight="600" style={color(type)}>
            {intl.formatMessage({ id: `simulateurAvantApres.resultats.apresRetraite.${type}`},{
                type: <SGText size="m"> {intl.formatMessage({ id: `simulateurAvantApres.resultats.${type}` })}</SGText>,
                strong: (word) => <SGText size="xxl"> {word}</SGText>,
                linebreak: <br />,
            })}
        </SGText>

    return (
        <SGSpace direction="vertical">
            <Carte
                key="carte-1"
                titre="ageLegal"
                sousTitre
                apres={getAge(TypeReforme.APRES, parametres.ageLegalApres)}
                avant={getAge(TypeReforme.AVANT, parametres.ageLegalAvant)}
            />
            <Carte
                key="carte-2"
                titre="ageTauxPlein"
                apres={getAge(TypeReforme.APRES, parametres.ageTauxPleinApres)}
                avant={getAge(TypeReforme.AVANT, parametres.ageTauxPleinAvant)}
            />
            <Carte
                key="carte-3"
                titre="trimestres"
                apres={getTrimestres(TypeReforme.APRES, parametres.nbTrimestresApres)}
                avant={getTrimestres(TypeReforme.AVANT, parametres.nbTrimestresAvant)}
            />
            <Carte
                key="carte-4"
                titre="departAnticipe"
                apres={getDepartAnticipe(TypeReforme.APRES, parametres.ageDepartAnticipeApres)}
                avant={getDepartAnticipe(TypeReforme.AVANT, parametres.ageDepartAnticipeAvant)}
            />
            <Carte
                key="carte-5"
                titre="apresRetraite"
                apres={getApresRetraite(TypeReforme.APRES)}
                avant={getApresRetraite(TypeReforme.AVANT)}
            />
        </SGSpace>
    );
};
