import { ApmRoute } from "@elastic/apm-rum-react";
import { Location } from "history";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Redirect, RouteProps } from "react-router-dom";
import { AccountState, isLoggedIn } from "store/account/types";
import { DashboardState, hasFetchedDashboard } from "store/dashboard/types";
import { getURLParams, isMobileEnvironment } from "store/import/utils";
import { LoginState } from "store/login/types";
import { State } from "store/store";
import { LOGOUT, REFORME_DEPART_ANTICIPE, TUNNEL } from "../privateRoutes";
import { LOGIN } from "../publicRoutes";
import { RequireFeatureFlipping } from "./RequireFeatureFlipping";
import {
  FeatureList,
  FeaturesState,
  hasFetchedFeatures,
} from "store/features/types";

interface PrivateRouteProps extends RouteProps {
  component: FunctionComponent;
  errorPage?: string;
  features: FeatureList;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const {
    component: Component,
    errorPage,
    path,
    location,
    features,
    ...rest
  } = props;

  const loginState = useSelector<State, LoginState>((state) => state.login);

  const dashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const accountState = useSelector<State, AccountState>(
    (state) => state.account
  );

  const featuresState: FeaturesState = useSelector<State, FeaturesState>(
    (state) => state.features
  );

  const loginSSO = (location?: Location<unknown>) => {
    // Si l'utilisateur souhaitait se rendre sur un chemin spécifique
    if (location) {
      const { pathname, search } = location;
      localStorage.setItem("redirectUrl", pathname + search);
    }
    window.location.href = `${window.env.REACT_APP_API_URL}${
      !isMobileEnvironment()
        ? window.env.REACT_APP_SSO_URL
        : window.env.REACT_APP_SSO_URL_MOBILE
    }${encodeURIComponent(getURLParams())}`;
  };

  const createRedirectTunnelURL = (pathname: string): string =>
    `${window.location.href.replace(window.location.hash, "")}#/${pathname}`;

  const renderRoute = (props: PrivateRouteProps) => {
    if (!hasFetchedFeatures(featuresState)) {
      return null;
    }

    if (isLoggedIn(accountState)) {
      const agesAvantReforme =
        dashboardState.dashboard?.ages &&
        dashboardState.dashboard.ages.filter(
          (age) =>
            new Date(age.creationDate) <
            new Date(window.env.REACT_APP_DEPART_ANTICIPE_DEBUT)
        ).length > 0;

      const afficherMajReforme =
        accountState.account.tunnelCompleted &&
        hasFetchedDashboard(dashboardState) &&
        !dashboardState.isOnboarded &&
        dashboardState.dashboard?.onBoardingDate !== undefined &&
        agesAvantReforme &&
        path !== REFORME_DEPART_ANTICIPE;

      // Dans le cas où on a fini le tunnel et qu'on est connecté on renvoie vers le composant demandé
      if (
        !afficherMajReforme &&
        (accountState.account.tunnelCompleted ||
          path === TUNNEL ||
          path === LOGOUT)
      ) {
        return (
          <RequireFeatureFlipping
            route={{ path, location }}
            errorPage={errorPage}
            features={features}
          >
            <Component {...props} />
          </RequireFeatureFlipping>
        );
      }

      // Dans le cas où on n'a pas fini le tunnel et qu'on est connecté on renvoie vers le tunnel
      const pageForcee = afficherMajReforme ? REFORME_DEPART_ANTICIPE : TUNNEL;
      window.history.replaceState(
        null,
        "",
        createRedirectTunnelURL(pageForcee)
      );

      return <Redirect to={{ pathname: pageForcee }} />;
    }
    // Dans le cas où on n'est pas connecté et qu'on est en mode SSO on renvoie vers l'url de login SSO
    if (
      window.env.REACT_APP_USE_SSO === "true" &&
      window.env.REACT_APP_DISPLAY_LOGIN !== "true"
    ) {
      const { location } = props;
      loginSSO(location);

      return null;
    }

    // Dans le cas où on n'est pas connecté et qu'on est n'est pas en mode SSO on renvoie vers l'url de login
    return <Redirect to={{ pathname: LOGIN }} />;
  };

  return (
    <div>
      {!loginState.forceLogOut && (
        <ApmRoute {...rest} render={() => renderRoute(props)} />
      )}
    </div>
  );
};
