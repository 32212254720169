import { FunctionComponent, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedCorbeille } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGSelectOption, SGSelectOptionGroup } from "sg-select";
import { isSCIAssetType } from "../../../../store/assets/typeguards";
import {
  AssetCategory,
  AssetMemberLink,
  AssetType,
  AssetWithValuation,
  DISPLAYED_ASSET_CATEGORIES,
  MANDATORY_ACQUISITION_DATE_ASSETS,
  RealEstateAsset,
  RealEstateAssetTaxSystemSubType,
  RealEstateAssetTaxSystemType,
} from "../../../../store/assets/types";
import {
  AssetEditFormData,
  categoriesActifs,
  getAssetCategory,
  typesActifs,
} from "../../../../store/assets/utils";
import { Loan } from "../../../../store/loans/types";
import { ButtonAddNewElement } from "../../atoms/ButtonAddNewElement/ButtonAddNewElement";
import { DateInput, DatePattern } from "../../atoms/DateInput/DateInput";
import { ErrorElement } from "../../atoms/ErrorElement/ErrorElement";
import { FormInputs } from "../../atoms/FormInputs/FormInputs";
import { NumericInput } from "../../atoms/NumericInput/NumericInput";
import { SGTextIntl } from "../../atoms/SGTextIntl/SGTextIntl";
import { SelectInput } from "../../atoms/SelectInput/SelectInput";
import { StringInput } from "../../atoms/StringInput/StringInput";
import { useAssets } from "../../hooks/useAssets";
import { useLoans } from "../../hooks/useLoans";
import { useLoansNotAssociatedToAssets } from "../../hooks/useLoansNotAssociatedToAssets";
import { useRealEstateAssets } from "../../hooks/useRealEstateAssets";
import { SCIEditForm } from "../AssetEditForms/SCIEditForm/SCIEditForm";
import { AssetMemberLinkEdit } from "../AssetMemberLinkEdit/AssetMemberLinkEdit";
import { RealEstateSimulator } from "../RealEstateSimulator/RealEstateSimulator";
import { getNonAssociatedRealEstateAssets } from "./utils";

interface AssetEditProps {
  asset?: AssetWithValuation;
  onCancel?: () => void;
  onSubmit: SubmitHandler<FieldValues>;
  isRealEstateIncome: boolean;
  isAssetMemberLinkDisabled: boolean;
  displayAssetShares: boolean;
  cypressName: string;
}

const AssetEdit: FunctionComponent<AssetEditProps> = ({
  asset,
  onCancel,
  onSubmit,
  isRealEstateIncome,
  isAssetMemberLinkDisabled,
  displayAssetShares,
  cypressName,
}: AssetEditProps) => {
  const intl = useIntl();
  const { assets } = useAssets();
  const realEstateAssets = useRealEstateAssets();
  const allLoans = useLoans([]);
  const availableLoansForAssociation = useLoansNotAssociatedToAssets();

  const taxSystemValues: RealEstateAssetTaxSystemType[] = Object.values(
    RealEstateAssetTaxSystemType
  );
  const taxArrangementValues: RealEstateAssetTaxSystemSubType[] = Object.values(
    RealEstateAssetTaxSystemSubType
  );
  const taxArrangementStartDate = 2008;

  const [currentAsset, setCurrentAsset] = useState<Partial<RealEstateAsset>>({
    assetName: "",
    assetValuation: {},
    assetMemberLinkSet: [],
  });
  const [isNew, setIsNew] = useState(true);
  const [assetMemberLinkSet, setAssetMemberLinkSet] = useState<
    Partial<AssetMemberLink>[]
  >([]);
  const [associatedLoans, setAssociatedLoans] = useState<Loan[]>([]);
  const [filteredNonAssociatedLoans, setFilteredNonAssociatedLoans] = useState<
    Loan[]
  >(availableLoansForAssociation);
  const [associatedRealEstateAssets, setAssociatedRealEstateAssets] = useState<
    RealEstateAsset[]
  >([]);
  const [nonAssociatedRealEstateAssets, setNonAssociatedRealEstateAssets] =
    useState<RealEstateAsset[]>([]);
  const [isPriceKnown, setIsPriceKnown] = useState<boolean>(true);
  const [isMonthlyFeesKnown, setIsMonthlyFeesKnown] = useState<boolean>(true);
  const [surface, setSurface] = useState<string>();

  // On affiche la date d'acquisition si le booléen correspondant est à true dans la configuration ou toujours pour certains assetTypes
  const displayAcquisitionDate =
    currentAsset?.assetType !== undefined &&
    MANDATORY_ACQUISITION_DATE_ASSETS.includes(currentAsset?.assetType);

  const methods = useFormContext();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  useEffect(() => {
    if (asset && isNew) {
      setIsNew(false);
      setCurrentAsset({
        ...asset,
        acquisitionDate: new Date(asset.acquisitionDate),
      });
      setAssetMemberLinkSet(asset.assetMemberLinkSet);
      setAssociatedLoans(
        allLoans.filter((loan) => asset.loans.includes(loan.id))
      );
      if (isSCIAssetType(asset)) {
        setAssociatedRealEstateAssets(asset.associatedRealEstateAssets);
      }
      setIsPriceKnown(true);
      setIsMonthlyFeesKnown(true);
    } else {
      setCurrentAsset({ ...asset, acquisitionDate: new Date() });
      setAssociatedLoans(
        allLoans.filter((loan) => currentAsset.loans?.includes(loan.id))
      );
      if (isRealEstateIncome) {
        const amountOfRentalAssetType = assets.filter(
          (m) => m.assetType === AssetType.REAL_ESTATE_RENT
        ).length;
        const defaultName =
          amountOfRentalAssetType < 1
            ? intl.formatMessage({ id: `form.income.name.realEstateTitle` })
            : `${intl.formatMessage({
                id: `form.income.name.realEstateTitle`,
              })} ${amountOfRentalAssetType + 1}`;
        setCurrentAsset((prevState) => ({
          ...prevState,
          assetType: AssetType.REAL_ESTATE_RENT,
          assetName: defaultName,
        }));
        methods.setValue("assetName", defaultName, { shouldValidate: true });
      }
    }
  }, [asset, isRealEstateIncome, assets, allLoans]);

  useEffect(() => {
    if (asset) {
      setFilteredNonAssociatedLoans(
        availableLoansForAssociation.filter(
          (loan) =>
            !asset.loans.find(
              (associatedLoanId) => loan.id === associatedLoanId
            )
        )
      );
    } else {
      setFilteredNonAssociatedLoans(availableLoansForAssociation);
    }
  }, [asset, availableLoansForAssociation, allLoans]);

  useEffect(() => {
    setNonAssociatedRealEstateAssets(
      getNonAssociatedRealEstateAssets(
        realEstateAssets,
        associatedRealEstateAssets
      )
    );
  }, [associatedRealEstateAssets, realEstateAssets]);

  const onAssetTypeChange = (newAssetType: string) => {
    const amountOfAssetsWithSameType = assets.filter(
      (m) => m.assetType === newAssetType
    ).length;
    const defaultName =
      amountOfAssetsWithSameType < 1
        ? intl.formatMessage({ id: `assetType.${newAssetType}` })
        : `${intl.formatMessage({ id: `assetType.${newAssetType}` })} ${
            amountOfAssetsWithSameType + 1
          }`;

    setCurrentAsset((prevState) => ({
      ...prevState,
      assetType: newAssetType,
      assetName: defaultName,
    }));
    methods.setValue("assetName", defaultName, { shouldValidate: true });
  };

  const onSelectTaxTypeChange = (newTaxType: string) => {
    setCurrentAsset((prevState) => ({
      ...prevState,
      taxSystemType: newTaxType,
    }));
  };

  const onSelectTaxSubTypeChange = (newTaxSubType: string) => {
    setCurrentAsset((prevState) => ({
      ...prevState,
      taxSystemSubType: newTaxSubType,
    }));
  };

  const onAssetValuationChange = (assetValuationInput: number) => {
    const assetValuation = {
      ...currentAsset.assetValuation,
      totalValuation: +assetValuationInput,
    };
    setCurrentAsset((prevState) => ({ ...prevState, assetValuation }));
  };

  const onRentChange = (periodicRentInput: number) => {
    setCurrentAsset((prevState) => ({
      ...prevState,
      periodicRentAmount: +periodicRentInput,
    }));
  };

  const onCostChange = (periodicCostInput: number) => {
    setCurrentAsset((prevState) => ({
      ...prevState,
      monthlyCostAmount: +periodicCostInput,
    }));
  };

  const handleDatePickerChange = (value: Date) => {
    const date = new Date(value);
    setCurrentAsset((prevState) => ({
      ...prevState,
      acquisitionDate: date,
    }));
  };

  const calculatePrice = (estimatedPrice: number) => {
    const assetValuation = {
      ...currentAsset.assetValuation,
      totalValuation: estimatedPrice,
    };
    setCurrentAsset((prevState) => ({ ...prevState, assetValuation }));

    methods.setValue("totalValuation", estimatedPrice, {
      shouldValidate: true,
    });
  };

  const calculateFees = (monthlyFees: number) => {
    setCurrentAsset((prevState) => ({
      ...prevState,
      monthlyCostAmount: monthlyFees,
    }));
    methods.setValue("periodicCostAmount", monthlyFees, {
      shouldValidate: true,
    });
  };

  const givenSurface = (givenSurface: string) => {
    setSurface(givenSurface);
  };

  const isRealEstateType = () =>
    currentAsset.assetType === AssetType.REAL_ESTATE_RENT ||
    currentAsset.assetType === AssetType.REAL_ESTATE_PRIMARY ||
    currentAsset.assetType === AssetType.REAL_ESTATE_SECONDARY;

  const displayRealEstateSimulatorForPriceEstimation = () => (
    <RealEstateSimulator
      setPrice={calculatePrice}
      displayMonthlyFees
      setMonthlyFees={calculateFees}
      retrieveSurface={givenSurface}
      priceKnown={isPriceKnown}
      setPriceKnown={setIsPriceKnown}
      monthlyFeesKnown={isMonthlyFeesKnown}
      setMonthlyFeesKnown={setIsMonthlyFeesKnown}
    />
  );

  const displayRealEstateRentForm = () => (
    <>
      {
        // les division par 12 sur les montants pour les passer de annuel à mensuel ne causent pas de décimales car les montants envoyés au back sont multipliés par 12
        // A supprimer quand les montants envoyés au back seront passés en mensuel
      }
      {isMonthlyFeesKnown && (
        <NumericInput
          defaultValue={
            currentAsset.id
              ? Math.trunc((currentAsset.yearlyCostAmount || 0) / 12)
              : undefined
          }
          name="periodicCostAmount"
          label="form.asset.periodicCostAmount"
          suffix="€"
          displayPeriodicChoice
          cypressName={`${cypressName}-periodic-cost-amount`}
          min={0}
          max={100_000}
          placeholder={intl.formatMessage({
            id: "form.asset.periodicCostAmount.placeholder",
          })}
          onValueChange={onCostChange}
          minMessage="form.asset.error.periodicRentAmount"
          maxMessage="form.asset.error.periodicRentAmount"
        />
      )}
      <NumericInput
        defaultValue={
          currentAsset.id
            ? Math.trunc((currentAsset.yearlyRentAmount || 0) / 12)
            : undefined
        }
        name="periodicRentAmount"
        label="form.asset.periodicRentAmount"
        suffix="€"
        displayPeriodicChoice
        cypressName={`${cypressName}-periodic-rent-amount`}
        min={0}
        onValueChange={onRentChange}
        max={100_000}
        placeholder={intl.formatMessage({ id: "form.asset.rent.placeholder" })}
        minMessage="form.asset.error.periodicRentAmount"
        maxMessage="form.asset.error.periodicRentAmount"
      />

      <SelectInput
        name="rentalInvestmentAssetTaxSystem"
        required
        onValueChange={onSelectTaxTypeChange}
        label="form.asset.tax-system"
        cypressName={`${cypressName}-rental-investment-tax-system`}
        defaultValue={currentAsset?.taxSystemType}
        placeholder={intl.formatMessage({
          id: "form.asset.choose-a-tax-system-type",
        })}
      >
        {taxSystemValues.map((taxSystem: RealEstateAssetTaxSystemType) => (
          <SGSelectOption key={taxSystem} value={taxSystem}>
            {intl.formatMessage({ id: `tax-system.${taxSystem}` })}
          </SGSelectOption>
        ))}
      </SelectInput>
      {displayForDefiscalisationTaxSystem()}
    </>
  );

  const displayForDefiscalisationTaxSystem = () => {
    if (
      currentAsset.taxSystemType ===
      RealEstateAssetTaxSystemType.DEFISCALISATION_REAL_ESTATE_INCOME
    ) {
      return displayTaxArrangement();
    }
  };

  const displayTaxArrangement = () => {
    if (
      !!currentAsset.acquisitionDate &&
      currentAsset.acquisitionDate.getFullYear() >= taxArrangementStartDate
    ) {
      return (
        <SelectInput
          name="rentalInvestmentAssetTaxArrangement"
          onValueChange={onSelectTaxSubTypeChange}
          defaultValue={currentAsset?.taxSystemSubType}
          required
          label="form.asset.tax-arrangement"
          placeholder={intl.formatMessage({
            id: "form.asset.choose-a-tax-arrangement-type",
          })}
          cypressName={`${cypressName}-rental-investment-tax-arrangement`}
        >
          {taxArrangementValues.map(
            (taxArrangement: RealEstateAssetTaxSystemSubType) => (
              <SGSelectOption key={taxArrangement} value={taxArrangement}>
                {intl.formatMessage({
                  id: `tax-arrangement.${taxArrangement}`,
                })}
              </SGSelectOption>
            )
          )}
        </SelectInput>
      );
    }
  };

  const addAssetMemberLink = () => {
    const newAssetMemberLink: Partial<AssetMemberLink> = {};
    setAssetMemberLinkSet((prevState) => [...prevState, newAssetMemberLink]);
  };

  const removeAssetMemberLink = (index: number) => {
    const updateAssetMemberLinkSet = assetMemberLinkSet.slice();
    updateAssetMemberLinkSet.splice(index, 1);
    setAssetMemberLinkSet(updateAssetMemberLinkSet);
  };

  const removeAssociatedLoan = (index: number) => {
    const updatedAssociatedLoans = [...associatedLoans];
    const removedLoan = updatedAssociatedLoans.splice(index, 1)[0];
    setAssociatedLoans(updatedAssociatedLoans);
    setFilteredNonAssociatedLoans([...filteredNonAssociatedLoans, removedLoan]);
  };

  const handleLoanChange = (newLoanId: string) => {
    const selectedLoan = filteredNonAssociatedLoans.find(
      (loan) => loan.id === Number(newLoanId)
    );
    if (selectedLoan && selectedLoan.id) {
      const newAssociatedLoans = [...associatedLoans, selectedLoan];
      setAssociatedLoans(newAssociatedLoans);
      setFilteredNonAssociatedLoans(
        filteredNonAssociatedLoans.filter((loan) => loan.id !== selectedLoan.id)
      );
      methods.setValue("associated-loans", undefined);
    }
  };

  const handleRealEstateAssociationDropDownChange = (
    newRealEstateId: string
  ) => {
    const selectedRealEstateAsset = realEstateAssets.find(
      (asset) => asset.id === Number(newRealEstateId)
    );
    if (selectedRealEstateAsset && selectedRealEstateAsset?.id) {
      const newAssociatedRealEstateAssets =
        associatedRealEstateAssets === null
          ? [selectedRealEstateAsset]
          : [...associatedRealEstateAssets, selectedRealEstateAsset];

      setAssociatedRealEstateAssets(newAssociatedRealEstateAssets);
      methods.setValue("associated-real-estate-assets", undefined);
    }
  };

  const handleRemoveAssociatedRealEstateAsset = (index: number) => {
    const associatedRealEstateAssetsCopy = [...associatedRealEstateAssets];
    associatedRealEstateAssetsCopy.splice(index, 1);
    setAssociatedRealEstateAssets(associatedRealEstateAssetsCopy);
  };

  const getAssetValuation = () => {
    if (
      currentAsset &&
      currentAsset.assetValuation?.totalValuation !== undefined
    ) {
      return currentAsset.assetValuation?.totalValuation;
    }
    if (asset) {
      return asset.assetValuation?.totalValuation;
    }
  };

  const displayAssetValuation = () => (
    <NumericInput
      defaultValue={getAssetValuation()}
      name="totalValuation"
      cypressName={`${cypressName}-total-valuation`}
      min={0}
      max={10_000_000}
      suffix="€"
      placeholder={intl.formatMessage({
        id: "form.asset.total-valuation.placeholder",
      })}
      label={
        currentAsset.assetType === AssetType.SCI
          ? "form.asset.checking-account.valuation"
          : "form.asset.assetValuation"
      }
      onValueChange={onAssetValuationChange}
      maxMessage="form.asset.error.totalValuation"
      minMessage="form.asset.error.totalValuation"
    />
  );

  const displayRealEstateRentEdit = () => (
    <FormInputs colLength={10}>
      {isPriceKnown && displayAssetValuation()}
      {isPriceKnown &&
        !!methods.getValues("totalValuation") &&
        isMonthlyFeesKnown &&
        displayRealEstateRentForm()}
    </FormInputs>
  );

  const displayAssetEdit = () => (
    <FormInputs colLength={10}>
      {isRealEstateType() && displayRealEstateSimulatorForPriceEstimation()}
      {(isPriceKnown || !isRealEstateType()) && displayAssetValuation()}
    </FormInputs>
  );

  const getAssetName = () => {
    if (asset) {
      return asset.assetName;
    }

    return currentAsset.assetName ?? "";
  };

  return (
    <>
      <FormInputs colLength={10}>
        {!isRealEstateIncome && (
          <SelectInput
            required
            name="assetType"
            onValueChange={onAssetTypeChange}
            disabled={!isNew}
            label="form.asset.asset-type"
            defaultValue={asset ? asset?.assetType : currentAsset.assetType}
            cypressName={`${cypressName}-type`}
            placeholder={intl.formatMessage({
              id: "form.asset.choose-a-asset-type",
            })}
          >
            {DISPLAYED_ASSET_CATEGORIES.filter((categorie) =>
              categoriesActifs.includes(categorie)
            ).map((category: AssetCategory) => (
              <SGSelectOptionGroup
                label={intl.formatMessage({
                  id: `assetCategory.${category.intlKey}`,
                })}
                key={category.intlKey}
              >
                {typesActifs
                  .filter((type) => getAssetCategory(type) === category)
                  // Empêche la création d'une seconde résidence principale
                  .filter(
                    (assetType: AssetType) =>
                      asset?.assetType !== undefined ||
                      !(
                        assetType === AssetType.REAL_ESTATE_PRIMARY &&
                        !!realEstateAssets &&
                        realEstateAssets.some(
                          (a) => a.assetType === AssetType.REAL_ESTATE_PRIMARY
                        )
                      )
                  )
                  .map((assetType) => (
                    <SGSelectOption
                      value={assetType}
                      key={assetType.toString()}
                    >
                      {intl.formatMessage({ id: `assetType.${assetType}` })}
                    </SGSelectOption>
                  ))}
              </SGSelectOptionGroup>
            ))}
          </SelectInput>
        )}

        <StringInput
          defaultValue={getAssetName()}
          name="assetName"
          cypressName={`${cypressName}-name`}
          label="form.asset.asset-name"
        />

        {displayAcquisitionDate && (
          <DateInput
            defaultValue={asset ? new Date(asset.acquisitionDate) : new Date()}
            name="acquisitionDate"
            label="form.loan.start-date"
            pattern={DatePattern.MONTHS}
            cypressName={`${cypressName}-acquisition-date`}
            onDateChange={(value: Date) => handleDatePickerChange(value)}
            max={new Date()}
          />
        )}
      </FormInputs>
      {currentAsset.assetType === AssetType.REAL_ESTATE_RENT
        ? displayRealEstateRentEdit()
        : displayAssetEdit()}
      <FormInputs colLength={10}>
        {isSCIAssetType(currentAsset) && (
          <SCIEditForm
            currentAsset={currentAsset}
            cypressName={cypressName}
            associatedAssets={associatedRealEstateAssets}
            nonAssociatedAssets={nonAssociatedRealEstateAssets}
            associatedLoans={associatedLoans}
            nonAssociatedLoans={filteredNonAssociatedLoans}
            onAssetSelectionChange={handleRealEstateAssociationDropDownChange}
            onRemoveAssociatedAsset={handleRemoveAssociatedRealEstateAsset}
            onLoanSelectionChange={handleLoanChange}
            onRemoveAssociatedLoan={removeAssociatedLoan}
          />
        )}
      </FormInputs>
      {isPriceKnown &&
        displayAssetShares &&
        !isAssetMemberLinkDisabled &&
        methods.getValues("totalValuation") !== undefined && (
          <SGAccordion disableContentMargin>
            <SGAccordionPanel
              header={
                <SGTextIntl
                  intlId="form.assets.choose.shares"
                  cypressName="asset-display-shares"
                />
              }
            >
              <SGGridRow>
                {assetMemberLinkSet &&
                  assetMemberLinkSet.map(
                    (
                      assetMemberLink: Partial<AssetMemberLink>,
                      index: number
                    ) => (
                      <SGGridCol
                        md={6}
                        xs={12}
                        key={
                          assetMemberLink.familyMember
                            ? `${assetMemberLink.familyMember.id}|${index}`
                            : index
                        }
                      >
                        <SGCard disableautomargin bordered>
                          <SGButtonGroup align="right">
                            <SGButton
                              type="icon"
                              data-cy={`${cypressName}-asset-member-link-delete-${index}`}
                              onClick={() => removeAssetMemberLink(index)}
                              size="sm"
                            >
                              <SGIcon
                                component={<SGAvenirStrokedCorbeille />}
                              />
                            </SGButton>
                          </SGButtonGroup>
                          <AssetMemberLinkEdit
                            {...methods}
                            assetMemberLink={assetMemberLink}
                            currentAsset={currentAsset}
                            ind={index}
                            cypressName={`${cypressName}-asset-member-link-${index}`}
                          />
                        </SGCard>
                      </SGGridCol>
                    )
                  )}
              </SGGridRow>
              <ButtonAddNewElement
                text="form.asset.add.member.link"
                cypressName={`${cypressName}-add-share`}
                onClick={addAssetMemberLink}
              />
            </SGAccordionPanel>
          </SGAccordion>
        )}

      <SGButtonGroup align={isPhone ? "center" : "right"}>
        <SGButton
          type="primary"
          size="sm"
          data-cy={`${cypressName}-submit`}
          onClick={methods.handleSubmit(onSubmit)}
          disabled={
            !methods.formState.isValid ||
            ((!isPriceKnown || !isMonthlyFeesKnown) &&
              currentAsset.assetType === AssetType.REAL_ESTATE_RENT)
          }
        >
          {intl.formatMessage({ id: "common.save" })}
        </SGButton>
        <SGButton
          type="secondary"
          size="sm"
          data-cy={`${cypressName}-cancel`}
          onClick={() => (onCancel ? onCancel() : {})}
        >
          {intl.formatMessage({ id: "common.cancel" })}
        </SGButton>
      </SGButtonGroup>
      <ErrorElement
        cypressName={cypressName}
        errorTextPrefix="form.asset.error"
      />
    </>
  );
};

export { AssetEdit };
