import { FunctionComponent, useState } from "react";
import { SGButton } from "sg-button";
import { SGDrawer } from "sg-drawer";
import { SGFilter } from "sg-filter";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFiltres } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SousThematique, Thematique } from "website/components/molecules/Article/ArticleModel";
import { FiltreThematique } from "./FiltresDesktop";

interface FiltresMobileProps {
    filtres: FiltreThematique[];
    onChangeFiltre: (sousThematique: SousThematique, isTracking: boolean) => void;
    filtreCourant: SousThematique;
    thematique: Thematique;
}
export const FiltresMobile: FunctionComponent<FiltresMobileProps> = (props: FiltresMobileProps) => {
    const { filtres, onChangeFiltre, filtreCourant, thematique } = props;
    const [visible, setVisible] = useState(false);
    const { trackClick } = useTracking();

    const handleOnClickFiltre = (filtre: SousThematique, isTracking = true) => {
        onChangeFiltre(filtre, isTracking);
        setVisible(false);
    };

    return filtres.length > 1 ? (
        <>
            <SGIcon component={<SGAvenirNavFiltres />} />
            <SGBox margin={{ left: "xs" }} inline>
                <SGButton
                    type="link"
                    onClick={() => {
                        trackClick("clic-sur-filtrer");
                        setVisible(true);
                    }}
                >
                    Filtrer par catégorie{filtreCourant !== SousThematique.tous && `${" "}(1)`}
                </SGButton>
            </SGBox>
            <SGDrawer
                title={
                    <SGBox margin={{ bottom: "lg" }}>
                        <SGGridRow align="middle">
                            <SGGridCol flex="initial">
                                <SGTitle level={2}>Filtrer par catégorie</SGTitle>
                            </SGGridCol>
                            {filtreCourant !== SousThematique.tous && (
                                <SGGridCol flex="auto">
                                    <SGButton
                                        type="link"
                                        onClick={() => {
                                            trackClick("clic-sur-reinitialiser-filtre");
                                            handleOnClickFiltre(SousThematique.tous, false);
                                        }}
                                    >
                                        Réinitialiser
                                    </SGButton>
                                </SGGridCol>
                            )}
                        </SGGridRow>
                    </SGBox>
                }
                placement="bottom"
                visible={visible}
                onClose={() => {
                    trackClick("clic-sur-fermer-filtres");
                    setVisible(false);
                }}
            >
                {filtres
                    .filter((filtre) => filtre.titre !== SousThematique.tous)
                    .map((filtre) => (
                        <SGFilter key={filtre.titre} checked={filtre.titre === filtreCourant} onChange={() => handleOnClickFiltre(filtre.titre)}>
                            {`${filtre.titre}${" "}(${filtre.compteur})`}
                        </SGFilter>
                    ))}
            </SGDrawer>
        </>
    ) : (
        <></>
    );
};
