import { DashboardAgeType } from "store/dashboard/types";
import { RetirementSimulationParameters } from "store/simulationAssu/types";

export interface ParcoursRisProjectionState {
   parcoursRisProjection: ParcoursRisProjection;
   parcoursRisProjectionError: string; // Erreurs API renvoyées par le back lors de la récupération des données
}

export const hasFetchedParcoursRisProjection = (parcoursRisProjectionState: ParcoursRisProjectionState): boolean =>
   parcoursRisProjectionState.parcoursRisProjection !== undefined;

/* eslint-disable camelcase */
export interface ParcoursRisProjection {
   dates: RetirementAge[];
   date_parcours?: string;
   fiabilite: number;
   trimestres_acquis?: number;
   trimestres_restants?: number;
}

export interface RetirementAge {
   age: Age;
   date: string;
   pension_mensuelle: number;
   trimestres_acquis?: number;
   trimestres_requis?: number;
   dernier_revenu_estime_mensuel: number;
   revenus_mensuels_nets_totaux: number;
   types_depart: number[];
   decote_temporaire_arrco_agirc_mensuelle?: number;
}
/* eslint-enable camelcase */

export interface Age {
   years: number;
   months: number;
}

export interface ParcoursRisResultsRequest {
   memberId: number;
   userId: number;
   parcoursKey: string;
   projections: Projection[];
   /* Données issues d'un appel précédent pour persistence */
   acquis: Acquis;
   /* Données issues de simulation-45s */
   ancienneSimulation: Partial<RetirementSimulationParameters>;
}

export interface Acquis {
   trimestresAcquis: number;
   trimestresRestants: number;
   dateTrimestreRestants: string;
   dateDepart: string;
}

/* eslint-disable camelcase */
export interface Projection {
   statut: number;
   remuneration_annuelle?: number;
   remuneration_mensuelle?: number;
   pente?: number;
   remuneration_type?: string;
   taux_arrco_special?: number;
   prime_mensuelle?: number;
   classe?: string;
   cavec_salarie?: boolean;
}
/* eslint-enable camelcase */

// actions
export const FETCH_PARCOURS_RIS_PROJECTION = "FETCH_PARCOURS_RIS_PROJECTION";
export const PARCOURS_RIS_PROJECTION_FETCHED = "PARCOURS_RIS_PROJECTION_FETCHED";
export const RESET_PARCOURS_RIS_PROJECTION_ERROR = "RESET_PARCOURS_RIS_PROJECTION_ERROR";
export const UPDATE_PARCOURS_RIS_PROJECTION = "UPDATE_PARCOURS_RIS_PROJECTION";

export enum RetirementTypes {
   AGE_LEGAL = 1,
   AGE_TAUX_PLEIN = 2,
   AGE_SANS_MALUS = 3,
   AGE_TP_CARCDSF = 4,
   AGE_TP_CARPIMKO = 5,
   AGE_TP_CAVEC = 6,
   AGE_TP_CAVAMAC = 12,
   AGE_TP_CAPV = 13,
   AGE_TP_CPRN = 14,

   AGE_DEPART_IMMEDIAT = 7,
   AGE_TAUX_PLEIN_AUTO = 8,

   // DA correspond à Depart anticipé
   AGE_DA_CARRIERE_LONGUE = 9,
   AGE_DA_INAPT_TRAVAIL = 91,
   AGE_DA_INAPT_PERMANENTE = 92,

   AGE_TP_PLUS_UN_AN = 24,
   AGE_TP_SURCOTE= 25
}

export const agesDepartAnticipe = [RetirementTypes.AGE_DA_CARRIERE_LONGUE, RetirementTypes.AGE_DA_INAPT_PERMANENTE, RetirementTypes.AGE_DA_INAPT_TRAVAIL];
export const agesDepartAnticipeRaisonSante = [RetirementTypes.AGE_DA_INAPT_PERMANENTE, RetirementTypes.AGE_DA_INAPT_TRAVAIL];

type RetirementTypesConversionType = {
   [key in DashboardAgeType]: number;
};

/**
 * Code issu de Sapiendo
 */
export const RetirementTypesConversion: RetirementTypesConversionType = {
   age_legal: 1,
   age_taux_plein: 2,
   age_maximum: 3,
   age_tp_carcdsf: 4,
   age_tp_carpimko: 5,
   age_tp_cavec: 6,
   age_tp_cavamac: 12,
   age_tp_cavp: 13,
   age_tp_cprn: 14,

   age_depart_immediat: 7,
   age_taux_plein_auto: 8,

   age_da_carriere_longue: 9,
   age_da_inapt_travail: 91,
   age_da_inapt_permanente: 92,

   age_tp_plus_un_an: 24,
   age_tp_surcote: 25
};
