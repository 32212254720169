export interface PromotionalOffer {
   userId: number;
   typeOffer: string;
   lastOfferDate?: Date;
   acceptOfferDate?: Date;
   viewCountOffer: number;
}
export interface PromotionalOfferState {
   promotionalOffers: PromotionalOffer[];
   hasFetched: boolean;
}

export const FETCH_PROMOTIONAL_OFFERS = "FETCH_PROMOTIONAL_OFFERS";
export const SAVE_PROMOTIONAL_OFFER = "SAVE_PROMOTIONAL_OFFER";
