import { FunctionComponent, useContext, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps } from "react-router-dom";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { createPrefs } from "store/dashboard/actions";
import { hasFetchedDashboard } from "store/dashboard/types";
import { State } from "store/store";
import { goToStep, resetTunnelAction } from "store/tunnel/actions";
import { AccordeonQuestions } from "website/components/atoms/AccordeonQuestions/AccordeonQuestions";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useAccount } from "website/components/hooks/useAccount";
import { FaqTunnel } from "website/components/molecules/FaqTunnel/FaqTunnel";
import { TunnelStep } from "website/components/organisms/Tunnel/TunnelStep/TunnelStep";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import { TUNNEL_STEPS } from "website/pages/TunnelPage";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";

export interface TunnelProps extends RouteProps {
  steps: FunctionComponent[];
}

const faqBodyList = ["personal.data", "sapiendo"];

const questionsRevenus = [
  "statut",
  "date",
  "revenus",
  "evolution",
  "estimation",
];

export const TunnelAssu: FunctionComponent<TunnelProps> = (
  props: TunnelProps
) => {
  const { steps } = props;
  const dispatch = useDispatch();

  const account = useAccount();
  const dashboardState = useDashboardState();
  const { agesTries } = useSelectedRetirementAge();

  const currentStep = useSelector<State, number>(
    (state) => state.tunnel.currentStep
  );

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const { showDetailFonctionnaire } = useContext(DetailTabContext);

  const simulationAssuState: RetirementSimulationParametersState = useSelector<
    State,
    RetirementSimulationParametersState
  >((state) => state.retirementSimulationParameters);

  // On remet à zéro les étapes
  useEffect(() => {
    dispatch(resetTunnelAction());
  }, []);

  useEffect(() => {
    if (hasFetchedDashboard(dashboardState)) {
      if (!dashboardState.dashboard.creationDate) {
        dispatch(createPrefs());
      } else if (
        !account.tunnelCompleted &&
        agesTries &&
        agesTries.length > 0 &&
        dashboardState.dashboard.choosenAge === undefined &&
        simulationAssuState.retirementSimulationParameters?.idSimulSimple ===
          undefined
      ) {
        // On passe à la dernière étape, après remise à zéro
        dispatch(goToStep(TUNNEL_STEPS.PENSION));
      }
    }
  }, [
    dashboardState,
    agesTries,
    account.tunnelCompleted,
    simulationAssuState.retirementSimulationParameters?.idSimulSimple,
  ]);

  const questions: string[] = useMemo(() => {
    switch (currentStep) {
      case TUNNEL_STEPS.SITUATION:
        return ["enfants"];
      case TUNNEL_STEPS.REVENUS:
        return showDetailFonctionnaire
          ? [questionsRevenus[0], "fonctionnaire", ...questionsRevenus.slice(1)]
          : questionsRevenus;
      default:
        return [];
    }
  }, [showDetailFonctionnaire, currentStep]);

  return (
    <SGSpace size="xl" direction="vertical">
      {steps.map(
        (step, index) =>
          currentStep === index && (
            <TunnelStep key={step.name} stepIndex={index} component={step} />
          )
      )}
      {questions.length > 0 && (
        <AccordeonQuestions
          key="accordeonQuestions"
          questionsKey={questions}
          titre="accordeonQuestions.titre.besoinDePrecision"
          description="accordeonQuestions.description.besoinDePrecision"
        />
      )}
      {currentStep === TUNNEL_STEPS.VISION && (
        <SGSpace key="space" direction="vertical" disableautomargin size="md">
          <SGTitle key="titre" visuallevel={isPhone ? 3 : 4}>
            <FormattedMessage id="tunnel.vision.title.mcr" />
          </SGTitle>
          <SGText key="texte" size="l">
            <FormattedMessage
              id="tunnel.vision.texte.mcr"
              values={{
                b: (word: string) => <SGText strong>{word}</SGText>,
              }}
            />
          </SGText>
        </SGSpace>
      )}
      <FaqTunnel
        key="faqTunnel"
        questionsKey={faqBodyList}
        questionDefautKey={
          currentStep === 0 && !isPhone ? faqBodyList[0] : undefined
        }
        titre="accordeonQuestions.titre.informations"
      />
    </SGSpace>
  );
};
