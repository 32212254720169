import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupervisionState } from "./types";

const initialState: SupervisionState = {
  estActive: false,
  estModeConsultation: true,
  hasFetched: false,
};

export const supervisionSlice = createSlice({
  name: "supervision",
  initialState,
  reducers: {
    setSupervision: (currentState, action: PayloadAction<boolean>) => ({
      ...currentState,
      estActive: action.payload,
      hasFetched: true,
    }),
    switchMode: (currentState) => ({
      ...currentState,
      estModeConsultation: !currentState.estModeConsultation,
    }),
  },
});

export const { setSupervision, switchMode } = supervisionSlice.actions;

export default supervisionSlice.reducer;
