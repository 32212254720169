import React, {FunctionComponent} from "react";
import "./SGTextIntl.scss";
import {useIntl} from "react-intl";
import {SGText} from "sg-typo";

interface SGTextIntlProps {
   intlId: string;
   size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
   strong?: boolean;
   italic?: boolean;
   color?: "default" | "error" | "warning" | "info" | "lighter" | "lightest";
   cypressName?: string;
   transformations?: Record<string, string | React.ReactNode>;
   weight?: "300" | "400" | "600" | "700" | "bold" | "bolder" | "lighter" ;
   style?: any;
   // TODO: A supprimer
   className?: string;
}

const SGTextIntl: FunctionComponent<SGTextIntlProps> = (props: SGTextIntlProps) => {
   const {intlId, size, strong, italic, color, cypressName, transformations, style, className, weight} = props;

   const intl = useIntl();

   return className ? (
      <SGText data-cy={cypressName} strong={strong} italic={italic} color={color || "default"} size={size || "s"} style={style} className={className} weight={weight}>
         {intl.formatMessage({id: intlId}, transformations)}
      </SGText>
   ) : (
      <SGText data-cy={cypressName} strong={strong} italic={italic} color={color || "default"} size={size || "s"} style={style} weight={weight}>
         {intl.formatMessage({id: intlId}, transformations)}
      </SGText>
   );
};

export {SGTextIntl};
