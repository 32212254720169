import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGText, SGTitle } from "sg-typo";
import { Image } from "website/components/atoms/Image/Image";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { MES_SERVICES } from "website/utils/privateRoutes";

const CarteOffreSapiendo: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const intl = useIntl();

  return (
    <SGCard
      clickable
      coversize="sm"
      layout="row"
      coverposition="leftCenter"
      cover={<Image alt="Offre Sapiendo" src="offreSapiendo.png" />}
      actions={[
        <SGLink
          icon={<SGIcon component={<SGAvenirNavFleche />} />}
          iconside="right"
          type="cta"
          href={`#${MES_SERVICES}`}
          onClick={() => trackClick("clic-sur-sapiendo-expert-retraite")}
        />,
      ]}
    >
      <SGTitle key="title" level={3} visuallevel={5}>
        <FormattedMessage id="carteOffreSapiendo.title" />
      </SGTitle>
      <SGText key="text" size="s">
        {intl.formatMessage({ id: `carteOffreSapiendo.text` })}
      </SGText>
    </SGCard>
  );
};

export { CarteOffreSapiendo };
