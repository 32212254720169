import { ReactComponent as Rectangle1 } from "assets/images/rectangle236.svg";
import { ReactComponent as Rectangle2 } from "assets/images/rectangle237.svg";
import React, { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Acquis } from "store/parcoursRisInformationsComplementaires/types";
import {
  RetirementAge,
  RetirementTypes,
} from "store/parcoursRisProjection/types";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";

type CarteTrimestresProps = {
  acquis: Acquis;
  dates: RetirementAge[];
};

export const CarteTrimestres: FunctionComponent<CarteTrimestresProps> = (
  props: React.PropsWithChildren<CarteTrimestresProps>
) => {
  const { acquis, dates } = props;

  const dateReleve = acquis.date_trimestre_restants
    .split("-")
    .reverse()
    .join("/");
  const dateDepart = acquis.date_depart.split("-").reverse().join("/");

  let ratioTrimestres = Math.round(
    (acquis.trimestres_acquis * 100) / acquis.trimestres_total
  );
  ratioTrimestres = ratioTrimestres > 90 ? 90 : ratioTrimestres;
  const styleAcquis = {
    width: `${ratioTrimestres}%`,
    backgroundColor: "rgba(3, 62, 100)",
    border: "2px solid #033E64",
    borderRadius: "9px 0px 0px 9px",
  };
  const styleRestants = {
    width: `${99.5 - ratioTrimestres}%`,
    border: "2px solid #033E64",
    borderRadius: "0px 9px 9px 0px",
  };

  const isAgeTauxPlein =
    dates.filter(
      (date) =>
        date.types_depart.includes(RetirementTypes.AGE_TAUX_PLEIN) ||
        date.types_depart.includes(RetirementTypes.AGE_TAUX_PLEIN_AUTO)
    ).length !== 0;

  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const isTabletEtPetitDesktop = useMediaQuery({
    minwidth: "md",
    maxwidth: "xl",
  });

  return (
    <SGSpace direction="vertical" size="lg">
      <SGTitle key="title" level={3} visuallevel={isPhone ? 2 : 4}>
        {intl.formatMessage({ id: "parcoursRIS.resultat.mesTrimestres" })}
      </SGTitle>
      <SGBox
        key="box"
        padding={{ left: isPhone ? "sm" : "md", right: isPhone ? "sm" : "md" }}
      >
        <SGGridRow justify="space-between" align="middle">
          <SGGridCol span={12} md={7}>
            <SGGridRow justify="space-between" align="stretch">
              <SGGridCol style={styleAcquis} align="center" textalign="center">
                <SGText color="lightest" style={{ margin: "auto" }}>
                  {acquis.trimestres_acquis}
                </SGText>
              </SGGridCol>
              <SGGridCol
                style={styleRestants}
                align="center"
                textalign="center"
              >
                <SGText style={{ color: "#033E64", margin: "auto" }}>
                  {acquis.trimestres_restants}
                </SGText>
              </SGGridCol>
            </SGGridRow>
          </SGGridCol>

          <SGGridCol span={12} md={3} xxl={5}>
            <SGBox margin={{ top: isPhone ? "md" : undefined }}>
              <SGGridRow justify="center" align="middle">
                <SGGridCol span={6} md={12} xxl={6}>
                  <SGGridRow
                    align="top"
                    justify={isTabletEtPetitDesktop ? "start" : "end"}
                  >
                    <SGGridCol>
                      <Rectangle1 />
                    </SGGridCol>
                    <SGGridCol>
                      <SGText weight="600">
                        <FormattedMessage id="parcoursRIS.resultat.trimestresAcquis" />
                      </SGText>
                    </SGGridCol>
                  </SGGridRow>
                </SGGridCol>
                <SGGridCol span={6} md={12} xxl={6}>
                  <SGGridRow align="top">
                    <SGGridCol>
                      <Rectangle2 />
                    </SGGridCol>
                    <SGGridCol>
                      <SGText weight="600">
                        <FormattedMessage id="parcoursRIS.resultat.trimestresNonAcquis" />
                      </SGText>
                    </SGGridCol>
                  </SGGridRow>
                </SGGridCol>
              </SGGridRow>
            </SGBox>
          </SGGridCol>
        </SGGridRow>
      </SGBox>

      <SGTextIntl
        key="text"
        size="m"
        intlId={
          isAgeTauxPlein
            ? "parcoursRIS.resultat.infosTrimestres"
            : "parcoursRIS.resultat.infosTrimestres.sansAgeTauxPlein"
        }
        transformations={{
          b: (word: string) => <SGText weight="700">{word}</SGText>,
          mois: (word: string) =>
            acquis.age_depart.months !== 0 ? (
              <SGText>{word}</SGText>
            ) : (
              <SGText />
            ),
          dateReleve,
          trimestresAcquis: acquis.trimestres_acquis,
          trimestresRestants: acquis.trimestres_restants,
          anneeAgeDepart: acquis.age_depart.years,
          moisAgeDepart: acquis.age_depart.months,
          trimestresTotal: acquis.trimestres_total,
          dateDepart,
        }}
      />
    </SGSpace>
  );
};
