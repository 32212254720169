import { useDispatch, useSelector } from "react-redux";
import { updateFamilyAction } from "store/members/actions";
import { updateFamilyStore } from "store/members/membersSlice";
import { savePersonalInformations } from "store/personalInformations/action";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { State } from "store/store";
import {
  saveWorkIncomes,
  updateWorkIncomesStore,
} from "store/workIncomes/action";
import { WorkIncome, WorkIncomePathType } from "store/workIncomes/types";
import {
  saveWorkIncomesHistoryAction,
  updateWorkIncomesHistoryStore,
} from "store/workIncomesHistory/action";
import {
  WorkIncomeRaisesType,
  WorkIncomesHistory
} from "store/workIncomesHistory/types";
import { Family, RetirementCategory } from "../../../store/members/types";
import { useAccount } from "./useAccount";
import { useFamily } from "./useFamily";
import { usePersonalInformations } from "./usePersonalInformations";

const useUpdateSituation = () => {
  const dispatch = useDispatch();
  const family = useFamily();
  const { personalInformationsState } = usePersonalInformations();
  const account = useAccount();

  const workIncomesHistory: WorkIncomesHistory = useSelector<
    State,
    WorkIncomesHistory
  >((state) => state.workIncomesHistory.workIncomesHistory);

  const updateSituation = (
    saveInBD: boolean,
    retirementType?: RetirementCategory
  ) => {
    const familyToDispatch: Family = {
      ...family,
      me: {
        ...family.me,
        retirementType,
      },
    };
    if (saveInBD) {
      dispatch(updateFamilyAction(familyToDispatch, true, true));
    } else {
      dispatch(updateFamilyStore(familyToDispatch));
    }
  };

  const updateCategorieActive = (categorieActive?: CategorieActiveChoix) => {
    dispatch(
      savePersonalInformations({
        ...personalInformationsState.personalInformations,
        categorieActive: categorieActive === CategorieActiveChoix.OUI,
      })
    );
  };

  const updateWorkIncomes = (
    saveInBD: boolean,
    secteurPro: string,
    montantSalaireAnnuel: number
  ) => {
    const workIncomes: WorkIncome[] = [
      {
        userId: account.id,
        originPath: WorkIncomePathType.ONBOARDING,
        profession: parseInt(secteurPro),
        income: montantSalaireAnnuel,
      },
    ];

    if (saveInBD) {
      dispatch(saveWorkIncomes(workIncomes));
    } else {
      dispatch(updateWorkIncomesStore(workIncomes));
    }
  };

  const updateWorkIncomesHistory = (
    saveInBD: boolean,
    carrierStart: Date,
    montantSalaireAnnuel: number,
    pastForecast: WorkIncomeRaisesType,
    futureForecast: WorkIncomeRaisesType
  ) => {
    const workIncomesHistoryToSave: WorkIncomesHistory = {
      ...workIncomesHistory,
      carrierStartDate: carrierStart,
      currentYearlyAmount: montantSalaireAnnuel,
      pastYearlyIncrease: pastForecast,
      expectedYearlyIncrease: futureForecast,
    };

    if (saveInBD) {
      dispatch(saveWorkIncomesHistoryAction(workIncomesHistoryToSave));
    } else {
      dispatch(updateWorkIncomesHistoryStore(workIncomesHistoryToSave));
    }
  };

  return {
    updateSituation,
    updateCategorieActive,
    updateWorkIncomes,
    updateWorkIncomesHistory,
  };
};

export { useUpdateSituation };
