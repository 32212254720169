import { FunctionComponent, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { WorkIncomeRaisesType } from "store/workIncomesHistory/types";
import { RadioGroupTile } from "../../atoms/RadioGroupTile/RadioGroupTile";

interface EvolutionsInputProps {
  name: string;
  label: string;
  forecast?: WorkIncomeRaisesType;
  setForecast: (value: WorkIncomeRaisesType) => void;
}

export const EvolutionsInput: FunctionComponent<EvolutionsInputProps> = (
  props: EvolutionsInputProps
) => {
  const { name, label, forecast, setForecast } = props;

  const { setValue, formState: { submitCount } } = useFormContext();

  const futureForecastList: WorkIncomeRaisesType[] =
    Object.values(WorkIncomeRaisesType);

  const changeForecast = (val: any) => {
    setForecast(val);
  };

  // Nécessaire pour la validation de la donnée futureForecast
  useEffect(() => {
    setValue(name, forecast, {
      shouldValidate: submitCount > 0,
    });
  }, [forecast]);

  return (
    <RadioGroupTile
      label={label}
      radioGroupList={futureForecastList}
      value={forecast}
      setValue={changeForecast}
      name={name}
    />
  );
};
