import { ReactComponent as LogoCross } from "assets/images/redcross.svg";
import { FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import "./ArticleModal.scss";
import { SGBox } from "sg-space";

interface ArticleModalProps {
    setShowArticleModal: (showArticleModal: boolean) => void;
    showArticleModal: boolean;
    contenuModal: { title: string; image: string; alt?: string };
    idTracking: string;
}

const ArticleModal: FunctionComponent<ArticleModalProps> = (props: ArticleModalProps) => {
    const { setShowArticleModal, showArticleModal, contenuModal, idTracking } = props;
    const { trackPopin, resetTracking } = useTracking();

    useEffect(() => {
        if (showArticleModal) {
            trackPopin(false, "comprendre-ma-retraite", idTracking);
        }
    }, [showArticleModal]);

    const handleOnClose = () => {
        setShowArticleModal(false);
        resetTracking();
    };

    return (
        <SGModal className="article-modal__container" visible={showArticleModal} centered onCancel={handleOnClose} closeIcon={<LogoCross />}>
            {contenuModal.title && (
                <SGGridRow justify="start">
                    <SGGridCol span={10} align="start">
                        <SGBox padding={{ bottom: "sm" }}>
                            <SGTitle level={2} visuallevel={4} caps>
                                <FormattedMessage
                                    id="contenuModal.title"
                                    defaultMessage={contenuModal.title}
                                />
                            </SGTitle>
                        </SGBox>
                    </SGGridCol>
                </SGGridRow>
            )}
            <SGMediaQuery minwidth={0} maxwidth="xs">
                <SGScroll>
                    <img className="article-modal__image" alt={contenuModal.alt ?? "cover"} src={contenuModal.image} />
                </SGScroll>
            </SGMediaQuery>
            <SGMediaQuery minwidth="sm">
                <SGGridRow justify="center">
                    <SGGridCol>
                        <img alt={contenuModal.alt ?? "cover"} src={contenuModal.image} />
                    </SGGridCol>
                </SGGridRow>
            </SGMediaQuery>
        </SGModal>
    );
};

export { ArticleModal };
