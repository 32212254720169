import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet } from "store/apiCaller";
import { onDefaultError, safe } from "store/error/utils";
import { FETCH_PERSONNALISATION_QUESTIONS, ParametresPersonnalisationQuestions, Question } from "./types";
import { personnalisationQuestionsFetched } from "./slice";

export function* watcherSaga() {
   yield takeLeading(FETCH_PERSONNALISATION_QUESTIONS, safe(onDefaultError, handleFetchPersonnalisationQuestions));
}

function* handleFetchPersonnalisationQuestions(action: PayloadAction<ParametresPersonnalisationQuestions>) {
    const payload: AxiosResponse<Question[]> = yield call(apiGet, `/backpfr/api/personnalisation/questions?choice=${action.payload.choice}&age=${action.payload.age}`);
    yield put(personnalisationQuestionsFetched(payload.data));
}
