import { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";
import { logout } from "store/login/actions";
import { State } from "store/store";
import { useAccount } from "website/components/hooks/useAccount";
import { PARAM_RESULTAT } from "website/pages/ParcoursRISPage";
import {
  PARCOURS_REVENUS_COMPLEMENTAIRES,
  PARCOURS_RIS,
  SGAGENDA_CONNEXION,
  SIMULATEUR_AVANT_APRES,
  SIMULATEUR_ECONOMIES_IMPOTS,
  SIMULATEUR_FLASH,
  TUNNEL,
} from "../privateRoutes";
import { MAINTENANCE } from "../publicRoutes";
import { FeatureList } from "store/features/types";

interface RequireFeatureFlippingProps {
  route: RouteProps;
  errorPage?: string;
  features: FeatureList;
}

/**
 *  Permet de vérifier si l'utilisateur est autorisé à afficher une page en fonction des features désactivées
 */
export const RequireFeatureFlipping: FunctionComponent<
  React.PropsWithChildren<RequireFeatureFlippingProps>
> = (props: React.PropsWithChildren<RequireFeatureFlippingProps>) => {
  const { errorPage, route, children, features } = props;
  const { path, location } = route;
  const {
    disponibiliteParcoursRIS,
    parcoursSEIActif,
    simulateurAvantApres,
    disponibiliteSimulateurFlash,
    disponibiliteParcoursRevenusComplementaires,
    disponibiliteMCR,
    disponibiliteSupervision,
    disponibiliteSGAgenda,
    hasFetchedFeatureFlipping,
  } = features;
  const customError = errorPage ?? MAINTENANCE;

  const hasFetchedAccount = useSelector<State, boolean>(
    (state) => state.account.hasFetched
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const account = useAccount();

  /**
   * Permet de vérifier si l'utilisateur est autorisé d'afficher une route en fonction des features désactivées
   */
  const isAuthorizedFeatureFlipping = () => {
    if (
      path === PARCOURS_RIS &&
      !location?.search.includes(PARAM_RESULTAT) &&
      disponibiliteParcoursRIS === false
    ) {
      return false;
    }

    if (path === SIMULATEUR_ECONOMIES_IMPOTS && parcoursSEIActif === false) {
      return false;
    }

    if (path === SIMULATEUR_AVANT_APRES && simulateurAvantApres === false) {
      return false;
    }

    if (
      (path === SIMULATEUR_FLASH || path === TUNNEL) &&
      disponibiliteSimulateurFlash === false
    ) {
      return false;
    }

    if (
      path === PARCOURS_REVENUS_COMPLEMENTAIRES &&
      disponibiliteParcoursRevenusComplementaires === false
    ) {
      return false;
    }
    if (path === SGAGENDA_CONNEXION && disponibiliteSGAgenda === false) {
      return false;
    }

    return true;
  };

  /**
   * Permet de vérifier si l'utilisateur est autorisé d'afficher une route si c'est un parcours à ne faire qu'une fois
   */
  const isAuthorizedAlreadyDone = () => {
    if (path === TUNNEL && account.tunnelCompleted) {
      return false;
    }

    return true;
  };

  /**
   * Permet de vérifier si l'utilisateur est autorisé à voir l'application (sinon l'application est désactivée)
   */
  const isAuthorizedGlobalFeatureFlipping = () =>
    disponibiliteMCR === true &&
    (!account.supervision || disponibiliteSupervision === true);

  const { isAuthorized, isLogout } = useMemo(() => {
    if (hasFetchedFeatureFlipping && hasFetchedAccount) {
      const checkAuthorizedGlobalsFeatureFlipping =
        isAuthorizedGlobalFeatureFlipping();
      const checkAuthorizedFeatureFlipping = isAuthorizedFeatureFlipping();
      const checkAuthorizedAlreadyDone = isAuthorizedAlreadyDone();

      return {
        isAuthorized:
          checkAuthorizedGlobalsFeatureFlipping &&
          checkAuthorizedFeatureFlipping &&
          checkAuthorizedAlreadyDone,
        isLogout: !checkAuthorizedGlobalsFeatureFlipping,
      };
    }

    return {
      isAuthorized: undefined,
    };
  }, [hasFetchedFeatureFlipping, hasFetchedAccount]);

  useEffect(() => {
    if (isAuthorized === false) {
      if (isLogout === true) {
        dispatch(logout(`/#${MAINTENANCE}`));
      } else {
        history.push(customError);
      }
    }
  }, [isAuthorized, isLogout]);

  return <>{isAuthorized === true && children}</>;
};
