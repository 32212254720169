export interface PlanPreference {
   userId: number;
   preference: string;
   choosenPref: boolean;
}
export interface PlanPreferenceState {
   planPreference?: PlanPreference[];
}

export const hasFetchedPlanPreference = (planPreferenceState: PlanPreferenceState): boolean => planPreferenceState.planPreference !== undefined;

export const FETCH_PLAN_PREFERENCE = "FETCH_PLAN_PREFERENCE";
export const PLAN_PREFERENCE_FETCHED = "PLAN_PREFERENCE_FETCHED";
export const SAVE_PLAN_PREFERENCE = "SAVE_PLAN_PREFERENCE";
export const PLAN_PREFERENCE_SAVED = "PLAN_PREFERENCE_SAVED";
