export interface SignUpState {
   signup: SignUp;
   isSignedUp: boolean;
   isLoginAlreadyUsed: boolean;
   isEmailInvalid: boolean;
}

export interface SignUp {
   firstname: string;
   login: string;
   email: string;
   password: string;
}

// Actions
const SIGNUP = "SIGNUP";
const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
const SIGNUP_ERROR_LOGIN_ALREADY_USED_400 =
   "SIGNUP_ERROR_LOGIN_ALREADY_USED_400";
const SIGNUP_ERROR_EMAIL_INVALID = "SIGNUP_ERROR_EMAIL_INVALID";

export {
   SIGNUP,
   SIGNUP_SUCCESS,
   SIGNUP_ERROR_LOGIN_ALREADY_USED_400,
   SIGNUP_ERROR_EMAIL_INVALID
};
