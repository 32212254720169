import { FunctionComponent, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { callDashboard, updateDashboard } from "store/dashboard/actions";
import { DashboardState } from "store/dashboard/types";
import { ParcoursRisInformationsComplementairesState } from "store/parcoursRisInformationsComplementaires/types";
import { ParcoursRisProjectionState } from "store/parcoursRisProjection/types";
import { State } from "store/store";
import { SupervisionState } from "store/supervision/types";
import { PAGE_CATEGORY_3_RIS, PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";
import { CarteEstimationDepartAnticipe } from "./CarteEstimationDepartAnticipe/CarteEstimationDepartAnticipe";
import { CarteReleve } from "./CarteReleve/CarteReleve";
import { FooterEstimation } from "./FooterEstimation/FooterEstimation";
import { TabEstimationTrimestres } from "./TabEstimationTrimestres/TabEstimationTrimestres";
import { SGAlert } from "sg-alert";
import { SGAvenirStatusWarning } from "sg-icon-pack-base";
import { SGIcon } from "sg-icon";

export const ParcoursRISResultats: FunctionComponent = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { trackPage } = useTracking();

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const parcoursRisProjectionState: ParcoursRisProjectionState = useSelector<State, ParcoursRisProjectionState>((state) => state.parcoursRisProjection);
    const dashboardState: DashboardState = useSelector<State, DashboardState>((state) => state.dashboard);
    const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
    const assetIncomesState: AssetIncomesState = useSelector<State, AssetIncomesState>((state) => state.assetIncomes);
    const parcoursRisInformationsComplementairesState: ParcoursRisInformationsComplementairesState = useSelector<
        State,
        ParcoursRisInformationsComplementairesState
    >((state) => state.parcoursRisInformationsComplementaires);
    const supervisionState: SupervisionState = useSelector<
        State,
        SupervisionState
    >((state) => state.supervision);

    useScrollToTopWithCurrentStep(4);

    const { fiabilite } = parcoursRisProjectionState.parcoursRisProjection;
    const { acquis } = parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires;

    useEffect(() => {
        trackPage(PAGE_CATEGORY_3_RIS, "resultats", PAGE_TYPE_FORMULAIRE, "simulation", "5");
        dispatch(callDashboard());
    }, []);

    useEffect(() => {
        dispatch(
            updateDashboard({
                ...dashboardState.dashboard,
                reliability: fiabilite,
                acquiredQuarters: acquis.trimestres_acquis,
                remainingQuarters: acquis.trimestres_restants,
                departureDate: acquis.date_depart,
            })
        );

        // Dans le cas ou les assets sont déjà générés car le Patrimoine est calculer, il faut recharger pour faire correspondre aux nouveaux ages générés par le parcours RIS
        if (assetState.hasFetched) {
            dispatch(getAssetsWithoutLoans());
        }
        if (hasFetchedAssets(assetIncomesState)) {
            dispatch(getAssetsIncomes());
        }
    }, [parcoursRisInformationsComplementairesState]);

    return (
        <SGBox margin={{ top: "md", bottom: "xl" }} span={isPhone ? 12 : 9}>
            <SGSpace direction="vertical" size={isPhone ? "lg" : "xl"} disableautomargin>
                {supervisionState.estActive && !supervisionState.estModeConsultation && (
                    <SGAlert
                        key="alert"
                        description={
                            <SGText size="m">Ces données ne correspondent pas au profil du client.</SGText>
                        }
                        icon={<SGIcon component={<SGAvenirStatusWarning />} size="l" />}
                        type="warning"
                        span={9}
                    />
                )}
                <SGBox key="box-1" margin={{ top: "lg", bottom: "lg" }}>
                    <SGTitle textalign={isPhone ? "left" : "center"} level={2}>
                        {intl.formatMessage({ id: "parcoursRIS.header.resultat" })}
                    </SGTitle>
                </SGBox>
                <CarteReleve
                    key="carte-releve"
                    acquis={parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires.acquis}
                    fiabilite={parcoursRisProjectionState.parcoursRisProjection.fiabilite}
                />
                <CarteEstimationDepartAnticipe key="carte-estimation" dates={parcoursRisProjectionState.parcoursRisProjection.dates} />
                <TabEstimationTrimestres
                    key="tab"
                    dates={parcoursRisProjectionState.parcoursRisProjection.dates}
                    acquis={parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires.acquis}
                />
                <SGBox key="box-2" forcemargin margin={{ top: "sm" }}>
                    <FooterEstimation />
                </SGBox>
            </SGSpace>
        </SGBox>
    );
};
