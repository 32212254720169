import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { RoundedSalary } from "website/components/atoms/RoundedSalary/RoundedSalary";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { OngletEffortEpargne } from "../EffortEpargne";

interface EffortEpargneTabProps {
  currentTab: "objectif" | "epargne";
  editableValue: number | undefined;
  setEditableValue: (value: number) => void;
  maxValueInput: number;
  otherValue: number | undefined;
  revenuAFinancer: number;
  setHasChangedValue?: (val: boolean) => void;
}

const EffortEpargneTab: FunctionComponent<EffortEpargneTabProps> = (
  props: EffortEpargneTabProps
) => {
  const {
    currentTab,
    editableValue,
    setEditableValue,
    maxValueInput,
    otherValue,
    revenuAFinancer,
    setHasChangedValue,
  } = props;
  const { selectedRetirementAge } = useSelectedRetirementAge();

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  return (
    <>
      <SGBox align="left" span={4} margin={{ top: "sm", bottom: "xxs" }}>
        <RoundedSalary
          label={`monObjectif.effortEpargne.${currentTab}.label`}
          labelSize="m"
          salary={editableValue}
          setSalary={setEditableValue}
          maxValueInput={maxValueInput}
          disabled={!possedeDroitsActionUtilisateur(supervisionState)}
          setHasChangedValue={setHasChangedValue}
          disableautomargin
        />
      </SGBox>
      {selectedRetirementAge?.lastMonthlyIncome && (
        <SGText color="lighter" size="s">
          <FormattedMessage
            id="monObjectif.effortEpargne.revenuEstime"
            values={{
              revenu: roundDown10(
                selectedRetirementAge.lastMonthlyIncome
              ).toLocaleString("fr-FR"),
              b: (word: string) => <SGText weight="700">{word}</SGText>,
            }}
          />
        </SGText>
      )}
      <SGBox margin={{ top: "sm" }}>
        <SGAlert
          type="info"
          icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
          span={7}
          description={
            currentTab === OngletEffortEpargne.objectif ? (
              <SGText>
                <FormattedMessage
                  id={
                    otherValue && revenuAFinancer
                      ? "monObjectif.effortEpargne.objectif.epargner.pour.generer"
                      : "monObjectif.effortEpargne.objectif.non.saisi"
                  }
                  values={{
                    montantAEpargner: otherValue?.toLocaleString("fr-FR"),
                    montantGenere: revenuAFinancer?.toLocaleString("fr-FR"),
                    b: (word: string) => <SGText weight="600">{word}</SGText>,
                  }}
                />
              </SGText>
            ) : (
              <SGText>
                <FormattedMessage
                  id={
                    editableValue && otherValue
                      ? "monObjectif.effortEpargne.epargne.pour.atteindre.objectif"
                      : "monObjectif.effortEpargne.epargne.non.saisie"
                  }
                  values={{
                    montantAEpargner: editableValue?.toLocaleString("fr-FR"),
                    objectifRevenu: otherValue?.toLocaleString("fr-FR"),
                    b: (word: string) => <SGText weight="600">{word}</SGText>,
                  }}
                />
              </SGText>
            )
          }
        />
      </SGBox>
    </>
  );
};

export { EffortEpargneTab };
