import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {AssetState, RealEstateAsset} from "../../../store/assets/types";
import {getAssetsWithoutLoans} from "../../../store/assets/actions";
import {isRealEstateAssetType} from "../../../store/assets/typeguards";

const useRealEstateAssets = () => {
   const dispatch = useDispatch();
   const [realEstateAssets, setRealEstateAssets] = useState<RealEstateAsset[]>([]);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);

   useEffect(() => {
      if (!assetState.hasFetched) {
         dispatch(getAssetsWithoutLoans());
      }
   }, []);

   useEffect(() => {
      if (assetState.hasFetched) {
         const realEstateAssetsToReturn: RealEstateAsset[] = [];
         assetState.assets.forEach((asset) => {
            if (isRealEstateAssetType(asset)) {
               realEstateAssetsToReturn.push(asset);
            }
         });
         setRealEstateAssets(realEstateAssetsToReturn);
      }
   }, [assetState.assets]);

   return realEstateAssets;
};

export {useRealEstateAssets};
