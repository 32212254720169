import React, {FunctionComponent} from "react";
import {RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

interface TunnelStepProps extends RouteProps {
   component: FunctionComponent;
   stepIndex: number;
}

const TunnelStep: FunctionComponent<TunnelStepProps> = (
   props: TunnelStepProps
) => {
  const { component: Component, stepIndex: step, ...rest } = props;
  const currentStep = useSelector<State, number>(
    (state) => state.tunnel.currentStep
  );

  return currentStep === step ? (
    <div>
       <Component {...rest} />
    </div>
  ) : null;
};

export { TunnelStep };
