import { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { callPersonnalisationDashboard } from "store/personnalisation/dashboard/actions";
import { reset } from "store/personnalisation/questions/slice";
import { Question } from "store/personnalisation/questions/types";
import { State } from "store/store";
import { nextStepAction, previousStepAction } from "store/tunnel/actions";
import {
  PAGE_CATEGORY_3_ONBOARDING,
  PAGE_NAME_MA_RETRAITE,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { BesoinsInput } from "website/components/molecules/SimulateurInputs/BesoinsInput";

export const TunnelBesoinRetraite: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { trackPage, trackClick } = useTracking();

  const pageName: string | undefined = useSelector<State, string | undefined>(
    (state) => state.tracking.pageName
  );

  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;

  const [questionsIds, setQuestionsIds] = useState<number[]>([]);
  const questions: Question[] = useSelector<State, Question[]>(
    (state) => state.personnnalisationQuestions.questions
  );

  useEffect(() => {
    if (pageName !== PAGE_NAME_MA_RETRAITE) {
      trackPage(
        PAGE_CATEGORY_3_ONBOARDING,
        PAGE_NAME_MA_RETRAITE,
        PAGE_TYPE_FORMULAIRE,
        "inscription",
        "1"
      );
    }
  }, []);

  useEffect(() => {
    setQuestionsIds(
      questions
        .filter((question: Question) => question.checked)
        .map((question: Question) => question.id)
    );
  }, [questions]);

  const onSubmit = () => {
    trackClick("clic-sur-suivant");
    dispatch(callPersonnalisationDashboard(questionsIds));
    dispatch(nextStepAction());
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SGSpace direction="vertical" disableautomargin size="xl">
          <SGSpace key="space" direction="vertical" disableautomargin size="sm">
            <SGTitle key="titre" level={2}>
              <FormattedMessage id="tunnel.besoin.title" />
            </SGTitle>
            <BesoinsInput
              key="besoinsInput"
              questions={questions}
              questionsIds={questionsIds}
              setQuestionsIds={setQuestionsIds}
            />
          </SGSpace>
          <SGButtonGroup key="buttons" align="center" disableautomargin>
            <SGButton
              disabled={questionsIds.length === 0}
              onClick={handleSubmit(onSubmit)}
              size="md"
            >
              <FormattedMessage id="tunnel.buttons.next" />
            </SGButton>
            <SGButton
              type="secondary"
              onClick={() => {
                trackClick("clic-sur-retour");
                dispatch(reset());
                dispatch(previousStepAction());
              }}
              size="md"
            >
              <SGText>
                <FormattedMessage id="tunnel.buttons.previous" />
              </SGText>
            </SGButton>
          </SGButtonGroup>
        </SGSpace>
      </form>
    </FormProvider>
  );
};
