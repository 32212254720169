export interface RetirementSimulationParametersState {
  retirementSimulationParameters: Partial<RetirementSimulationParameters>;
}

export const hasFetchedSimulationAssu = (
  simulationAssuState: RetirementSimulationParametersState
): boolean => simulationAssuState.retirementSimulationParameters !== undefined;

export interface RetirementSimulationParameters {
  memberId: number;
  idSimulSimple: string;
  secteurPro: string;
  montantAnnuelSalaire: number;
  salaireBrutNet: number;
  evolSalairePasse: string;
  evolSalaireFutur: string;
  genre: string;
  dateNaissance: string;
  dateDebCarriere: string;
  nbEnfant: number;
  // XXX Pas utilisé => Récupéré du back
  agesDepartRetraite?: Array<RetirementSimulationAge>;
  simulationErrorCode?: number;
  simulationErrorMessage?: string;
  isParcoursDone: boolean;
  currentStep: number;
}

export interface RetirementSimulationAge {
  type: string;
  ageAn: number;
  ageMois: number;
  dateDepartRetraite: string;
  derRem: number;
  pensionTot: number;
  pensionBase: number;
  pensionComp1: number;
  pensionComp2: number;
  perteEstimee: number;
}

export enum SituationsHandicapEnum {
  OUI = "OUI",
  NON = "NON",
  NE_PAS_REPONDRE = "NE_PAS_REPONDRE",
  RENTE_COMPRISE_10_20 = "RENTE_COMPRISE_10_20",
  RENTE_SUPERIEUR_EGALE_20 = "RENTE_SUPERIEUR_EGALE_20",
  TAUX_INCAPACITE_SUPERIEUR_50 = "TAUX_INCAPACITE_SUPERIEUR_50",
  AAH_OU_TAUX_INCAPACITE_SUPERIEUR_80 = "AAH_OU_TAUX_INCAPACITE_SUPERIEUR_80",
  PENSION_INVALIDITE = "PENSION_INVALIDITE",
}

// actions
export const FETCH_RETIREMENT_SIMULATION = "FETCH_RETIREMENT_SIMULATION";
export const FETCH_RETIREMENT_SIMULATION_ERROR = "FETCH_RETIREMENT_SIMULATION_ERROR";
export const RETIREMENT_SIMULATION_FETCHED = "RETIREMENT_SIMULATION_FETCHED";
export const UPDATE_RETIREMENT_SIMULATION = "UPDATE_RETIREMENT_SIMULATION";
export const RESET_PARCOURS_SIMULATION = "RESET_PARCOURS_SIMULATION";
export const NEXT_STEP_SIMULATION = "NEXT_STEP_SIMULATION";
export const PREVIOUS_STEP_SIMULATION = "PREVIOUS_STEP_SIMULATION";
export const SIMULATEUR_FLASH_DONE = "SIMULATEUR_FLASH_DONE";
