import { AxiosResponse } from "axios";
import { call, delay, put, takeLeading } from "redux-saga/effects";
import { apiGet } from "../apiCaller";
import { safe } from "../error/utils";
import { pdfEnErreur, pdfFetched, telechargerPdf } from "./pdfSlice";
import { TELECHARGER_SYNTHESE_RETRAITE } from "./types";
import { downloadPDF } from "./utils";

export function* watcherSaga() {
   yield takeLeading(TELECHARGER_SYNTHESE_RETRAITE, safe(onApiCallError, handlePreparerPdf));
}

function* onApiCallError() {
   yield put(pdfEnErreur());
}

function* handlePreparerPdf() {
   yield put(telechargerPdf());
   const pdf: AxiosResponse<string> = yield call(apiGet, `/backpfr/api/synthese-retraite/preparer`, process.env.REACT_APP_API_URL);
   downloadPDF(`/backpfr/api/synthese-retraite/${pdf.data}.pdf`);

   // Ajout d'un timer car appelé trop tôt car PDF téléchargé autrement que via apiGet
   yield delay(10_000);
   yield put(pdfFetched());
}
