import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AccountState } from "store/account/types";
import { RetirementCategory, RetirementCategorySimulation } from "store/members/types";
import { State } from "store/store";
import { fetchWorkIncomes } from "store/workIncomes/action";
import { WorkIncome, WorkIncomePathType, WorkIncomesState, hasFetchedWorkIncomes } from "store/workIncomes/types";

export function secteurProToProfession(secteurPro: string) {
  switch (secteurPro) {
    case RetirementCategorySimulation.SALARIE_PRIVE_CADRE:
      return RetirementCategory.SALARIE_PRIVE_CADRE;
    case RetirementCategorySimulation.ARTISAN:
      return RetirementCategory.ARTISAN;
    case RetirementCategorySimulation.COMMERCANT:
      return RetirementCategory.COMMERCANT;
    case RetirementCategorySimulation.LIBERAL:
      return RetirementCategory.LIBERAL;
    case RetirementCategorySimulation.FONCTIONNAIRE:
      return RetirementCategory.FONCTIONNAIRE;
    case RetirementCategorySimulation.ASSISTANT_MEDICAL:
      return RetirementCategory.ASSISTANT_MEDICAL;
    case RetirementCategorySimulation.EXPLOITANT_AGRICOLE:
      return RetirementCategory.EXPLOITANT_AGRICOLE;
    default:
      return "";
  }
}

export interface Income {
  userId: number;
  originPath: number;
  profession?: string;
  income: number;
  statut: string;
}

const useWorkIncomes = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [workIncomes, setWorkIncomes] = useState<Income[]>();
  const workIncomesState: WorkIncomesState = useSelector<State, WorkIncomesState>((state) => state.workIncomes);

  useEffect(() => {
    dispatch(fetchWorkIncomes());
  }, []);

  useEffect(() => {
    const getIncomeStatus = (originPath: WorkIncomePathType, status: number) => {
      if (originPath === WorkIncomePathType.ONBOARDING) {
        return intl.formatMessage({ id: `monPlan.pension.${String(status) === RetirementCategorySimulation.SALARIE_PRIVE_CADRE
          || String(status) === RetirementCategorySimulation.FONCTIONNAIRE ? "brut" : "net"}`});
      }

      return intl.formatMessage({ id: `monPlan.pension.${status === 1 || status === 2 || status === 3 ? "brut" : "net"}`});
    };

    const getStatus = (originPath: WorkIncomePathType, status: number) => {
      if (originPath === WorkIncomePathType.ONBOARDING) {
        return intl.formatMessage({ id: `retirementType.${secteurProToProfession(String(status))}`});
      }

      return intl.formatMessage({ id: `parcoursRIS.projection.statut.${status}` })
    };

    /**
     * Convertir les revenus mensuels en revenus annuels
     *
     * @param workIncome revenus lié à une profession
     * @returns revenus annuels
     */
     const convertMonthlyIncomeToYear = (workIncome: WorkIncome) => (workIncome.profession === 6 || workIncome.profession === 7) && workIncome.originPath === WorkIncomePathType.RIS ? workIncome.income * 12 : workIncome.income

    if (hasFetchedWorkIncomes(workIncomesState)) {
      setWorkIncomes(
        workIncomesState?.workIncomes?.map((workIncome: WorkIncome) => ({ ...workIncome, income: convertMonthlyIncomeToYear(workIncome), profession: getStatus(workIncome.originPath, workIncome.profession), statut: getIncomeStatus(workIncome.originPath, workIncome.profession) }))
          .sort((a: Income, b: Income) => b.income - a.income).slice(0, 2)
      );
    }
  }, [workIncomesState.workIncomes]);

  return workIncomes;
}

export { useWorkIncomes };
