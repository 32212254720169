import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {AssetState, AssetWithValuation} from "../../../store/assets/types";
import {getAssetsWithoutLoans} from "../../../store/assets/actions";
import {isSCIAssetType} from "../../../store/assets/typeguards";

const useSCIAssets = () => {
   const dispatch = useDispatch();
   const [sciAssets, setSciAssets] = useState<AssetWithValuation[]>([]);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);

   useEffect(() => {
      if (!assetState.hasFetched) {
         dispatch(getAssetsWithoutLoans());
      }
   }, []);

   useEffect(() => {
      if (assetState.hasFetched) {
         setSciAssets(assetState.assets.filter((asset) => isSCIAssetType(asset)));
      }
   }, [assetState.assets]);

   return sciAssets;
};

export {useSCIAssets};
