import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGText, SGTitle } from "sg-typo";
import { resetAssetsIncomes } from "store/assetIncomes/actions";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { DashboardState } from "store/dashboard/types";
import { nextStep } from "store/parcoursRevenusComplementaires/action";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { Loader } from "website/components/atoms/Loader/Loader";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAssets } from "website/components/hooks/useAssets";
import { AssetsEditor } from "website/components/organisms/Editors/AssetsEditor/AssetsEditor";
import { EditorContext } from "website/components/providers/EditorProvider";
import { DASHBOARD } from "website/utils/privateRoutes";
import { DisclaimerPatrimoine } from "../../Dashboard/DisclaimerPatrimoine/DisclaimerPatrimoine";
import "./ParcoursRevenusComplementairesAggregate.scss";
import { SGLink } from "sg-link";

const ParcoursRevenusComplementairesAggregate: FunctionComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { trackClick, trackPage } = useTracking();
  const { showDisclaimerPatrimoine } = useAssets();
  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );

  const { isEditing } = useContext(EditorContext);

  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  useEffect(() => {
    trackPage(
      "patrimoine",
      "agreger-mon-patrimoine",
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "1"
    );
    dispatch(getAssetsWithoutLoans());
    // Pour forcer la récupération à la seconde étape
    dispatch(resetAssetsIncomes());
  }, []);

  const onSubmit = () => {
    trackClick("clic-sur-suivant");
    setShowLoader(true);

    setTimeout(() => {
      dispatch(nextStep());
    }, 1000);
  };

  /**
   * Permet d'afficher le bouton Suivant
   * PFR-3485 : en mode supervision, le bouton suivant est grisé uniquement si on n'a jamais fait le parcours Patrimoine
   * @returns le rendu du bouton Suivant
   */
  const renderBoutonSuivant = () => (
    <>
      {dashboardState.dashboard?.firstPatrimonyPathDate === undefined ? (
        <BoutonSupervision
          cypressName="parcours-revenus-complementaires-submit"
          onClick={onSubmit}
          disabled={isEditing}
        >
          {intl.formatMessage({ id: "common.next" })}
        </BoutonSupervision>
      ) : (
        <SGButton
          data-cy="parcours-revenus-complementaires-submit"
          onClick={onSubmit}
          disabled={isEditing}
        >
          {intl.formatMessage({ id: "common.next" })}
        </SGButton>
      )}
    </>
  );

  return (
    <SGGridRow gutter={[0, 20]} className="aggregate-step__container">
      <SGGridCol>
        <SGTitle level={2} visuallevel={4}>
          {intl.formatMessage({
            id: "parcoursRevenusComplementaires.step.agreger",
          })}
        </SGTitle>
        <p>
          <SGText>
            {intl.formatMessage({
              id: "parcoursRevenusComplementaires.agreger.subtitle",
            })}
          </SGText>
        </p>
        {showDisclaimerPatrimoine && <DisclaimerPatrimoine idTracking="clic-sur-disclaimer-actualiser-mes-actifs" />}
      </SGGridCol>

      <SGGridCol span={12}>
        <SGGridRow justify="center" gutter={[0, 50]}>
          <SGGridCol span={12} md={9}>
            {assetState.hasFetched && (
              <AssetsEditor
                displayAssetShares={false}
                cypressName="profile-assets"
              />
            )}
          </SGGridCol>

          <SGGridCol span={9}>
            {showLoader ? (
              <Loader title="loader.title" />
            ) : (
              <>
                <SGMediaQuery minwidth="sm">
                  <SGButtonGroup align="opposite" responsive={false}>
                    <SGLink
                      href={`#${DASHBOARD}`}
                      icon={
                        <SGIcon
                          component={<SGAvenirNavFlecheBtnLien />}
                          currentcolor
                          rotate="left"
                        />
                      }
                      onClick={() => trackClick("clic-sur-annuler")}
                      type="tertiary"
                    >
                      {intl.formatMessage({ id: "common.cancel" })}
                    </SGLink>
                    {renderBoutonSuivant()}
                  </SGButtonGroup>
                </SGMediaQuery>
                <SGMediaQuery minwidth="xs" maxwidth="xs">
                  <SGButtonGroup align={isPhone ? "center" : "opposite"} layout="column">
                    {renderBoutonSuivant()}
                    <SGLink
                      href={`#${DASHBOARD}`}
                      underline
                      onClick={() => trackClick("clic-sur-annuler")}
                    >
                      {intl.formatMessage({ id: "common.cancel" })}
                    </SGLink>
                  </SGButtonGroup>
                </SGMediaQuery>
              </>
            )}
          </SGGridCol>
        </SGGridRow>
      </SGGridCol>
    </SGGridRow>
  );
};

export { ParcoursRevenusComplementairesAggregate };
