import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  FETCH_RETIREMENT_SIMULATION,
  RETIREMENT_SIMULATION_FETCHED,
  FETCH_RETIREMENT_SIMULATION_ERROR,
  RetirementSimulationParameters,
} from "./types";
import { safe } from "../error/utils";
import { apiPost } from "../apiCaller";
import { onApiCallError } from "./utils";
import { AxiosResponse } from "axios";

export function* watcherSaga() {
  yield takeLeading(
    FETCH_RETIREMENT_SIMULATION,
    safe(onApiCallError, handlePostGlobalSimulationAssu)
  );
}

function* handlePostGlobalSimulationAssu(action: PayloadAction<RetirementSimulationParameters>) {
  const payload: AxiosResponse<any> = yield call(
    apiPost,
    `/backpfr/api/simulation-access/simulateur-45s`,
    action.payload,
    undefined,
    window.env.REACT_APP_API_URL
  );
  if (payload.data.errors !== undefined) {
    // Est-ce que ça peut vraiment arriver ? Il faudrait une 200 avec un objet errors
    yield put({ type: FETCH_RETIREMENT_SIMULATION_ERROR, payload: payload.data });
  } else {
    yield put({ type: RETIREMENT_SIMULATION_FETCHED, payload: payload.data });
  }
}
