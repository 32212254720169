export interface ParcoursRisUploadState {
  parcoursRisUpload: ParcoursRisUpload;
  parcoursRisUploadErrorCode?: number; // Code erreur API renvoyé par le back lors de la récupération des données
  parcoursRisUploadErrorMessage?: string; // Erreurs API renvoyées par le back lors de la récupération des données
  accessible?: boolean;
}

export const hasFetchedParcoursRisUpload = (
  parcoursRisUploadState: ParcoursRisUploadState
): boolean => parcoursRisUploadState.parcoursRisUpload !== undefined;

export interface ParcoursRisUpload {
  success: boolean;
  message: string;
  identifier: string;
  // eslint-disable-next-line camelcase
  parcours_key: string;
  error: boolean;
  processed: boolean;
  traitementIndiciaireRequired: boolean;
}

export interface ParcoursRisUploadRequest {
  ris: File;
  // eslint-disable-next-line camelcase
  third_user?: boolean;
  // eslint-disable-next-line camelcase
  third_user_firstname?: string;
  // eslint-disable-next-line camelcase
  third_user_lastname?: string;
}

// actions
export const FETCH_PARCOURS_RIS_UPLOAD = "FETCH_PARCOURS_RIS_UPLOAD";
export const PARCOURS_RIS_UPLOAD_FETCHED = "PARCOURS_RIS_UPLOAD_FETCHED";
export const RESET_PARCOURS_RIS_UPLOAD_ERROR =
  "RESET_PARCOURS_RIS_UPLOAD_ERROR";
export const UPDATE_PARCOURS_RIS_UPLOAD = "UPDATE_PARCOURS_RIS_UPLOAD";
export const FETCH_PARCOURS_RIS_UPLOAD_ACCESSIBLE = "FETCH_PARCOURS_RIS_UPLOAD_ACCESSIBLE";
export const PARCOURS_RIS_UPLOAD_ACCESSIBLE_FETCHED = "PARCOURS_RIS_UPLOAD_ACCESSIBLE_FETCHED";
export const RESET_PARCOURS_RIS_UPLOAD_ACCESSIBLE = "RESET_PARCOURS_RIS_UPLOAD_ACCESSIBLE";
