import {LOGIN, Login, LOGIN_AS, LoginAs, NewPassword, REQUEST_NEW_PASSWORD, SUBMIT_NEW_PASSWORD, USER_LOGOUT} from "./types";
import {makeAction} from "../utils";

export const login = (data: Login) => ({
  type: LOGIN,
  payload: data,
});

export const loginAs = (data: LoginAs) => ({
  type: LOGIN_AS,
  payload: data,
});

export const logout = (redirection?: string) => ({
  type: USER_LOGOUT,
  payload: redirection,
});

export const requestNewPassword = (email:string) => makeAction(REQUEST_NEW_PASSWORD, email);
export const submitNewPassword = (password:NewPassword) => makeAction(SUBMIT_NEW_PASSWORD, password);
