import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet, apiPost } from "store/apiCaller";
import { onApiCallErrorNoError, safe } from "store/error/utils";
import { FETCH_PLAN_PREFERENCE, PlanPreference, PLAN_PREFERENCE_FETCHED, PLAN_PREFERENCE_SAVED, SAVE_PLAN_PREFERENCE } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_PLAN_PREFERENCE, safe(onApiCallErrorNoError, handleFetchPlanPreference));
   yield takeLeading(SAVE_PLAN_PREFERENCE, safe(onApiCallErrorNoError, handleSavePlanPreference));
}

/**
 * Récupère les préférences mon plan d'un utilisateur
 * @param action Action contenant l'id de l'utilisateur
 */
function* handleFetchPlanPreference() {
   const payload: AxiosResponse<PlanPreference[]> = yield call(apiGet, `/backpfr/api/userPlanPreferences`);
   yield put({ type: PLAN_PREFERENCE_FETCHED, payload: payload.data });
}

/**
 * Mets à jour une préférence mon plan d'un utilisateur
 * @param action Action contenant PlanPreference
 */
function* handleSavePlanPreference(action: PayloadAction<PlanPreference>) {
   yield call(apiPost, `/backpfr/api/userPlanPreferences`, action.payload);
   yield put({ type: PLAN_PREFERENCE_SAVED, payload: [action.payload] });
}
