import {ProjectType} from "../projects/types";
import {AssetType} from "../assets/types";
import {Member} from "../members/types";

export interface RealizedDonationState {
   realizedDonations: RealizedDonation[];
   hasFetched: boolean;
}

export interface RealizedDonation {
   id: number;
   projectName: string;
   projectType: ProjectType;
   amount?: number;
   startDate: string;
   member?: Member;
   assetType: AssetType;
   alreadyRealized: boolean;
}

export const buildDefaultRealizedDonation = (name: string): Omit<RealizedDonation, "id"> => ({
   projectName: name,
   projectType: ProjectType.GIVEN_DONATION,
   assetType: AssetType.CASH_ACCOUNT,
   startDate: new Date().toDateString(),
   alreadyRealized: false,
});

// actions
export const FETCH_REALIZED_DONATIONS = "FETCH_REALIZED_DONATIONS";
export const REALIZED_DONATIONS_FETCHED = "REALIZED_DONATIONS_FETCHED";
export const UPDATE_REALIZED_DONATION = "UPDATE_REALIZED_DONATION";
export const REALIZED_DONATION_UPDATED = "REALIZED_DONATION_UPDATED";
export const CREATE_REALIZED_DONATION = "CREATE_REALIZED_DONATION";
export const REALIZED_DONATION_CREATED = "REALIZED_DONATION_CREATED";
export const DELETE_REALIZED_DONATION = "DELETE_REALIZED_DONATION";
export const REALIZED_DONATION_DELETED = "REALIZED_DONATION_DELETED";
export const RESET_REALIZED_DONATION_STATE = "RESET_REALIZED_DONATION_STATE";
