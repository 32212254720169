import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startImportPrivateData } from "store/importPrivateData/actions";
import { PrivateDataState } from "store/importPrivateData/types";
import { State } from "../../../store/store";
import { getUrlHostName } from "../../utils/import/sg.import-service";

const usePrivateData = () => {
   const dispatch = useDispatch();
   const idScript = "webScrappingScript";
   const privateDataState: PrivateDataState = useSelector<State, PrivateDataState>(
      (state) => state.privateData
   );

   // Pour avoir l'idStat au plus tôt lors des chargements de page
   const [idStat, setIdStat] = useState<string>();

   useEffect(() => {
      if (!privateDataState.hasFetched) {
         const importSgData = (uri: string) => {
            try {
               const scriptTag = document.createElement("script");

               scriptTag.src = uri;
               scriptTag.id = idScript;
               scriptTag.onload = () => {
                  const script = document.querySelector(`#${idScript}`);
                  if (script) {
                     const host: string = getUrlHostName(uri);
                     dispatch(startImportPrivateData(host));
                  }
               };
               scriptTag.onerror = () => {
                  setIdStat("");
               };
               document.body.appendChild(scriptTag);
            } catch (e) {
               setIdStat("");
            }
         };

         let scriptUri = sessionStorage.getItem("scripturi");

         if (!scriptUri) {
            const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
            scriptUri = searchParams.get("scripturi") || "";
         }

         if (scriptUri) {
            sessionStorage.setItem("scripturi", scriptUri);
            const script = document.querySelector(`#${idScript}`);

            if (script) {
               const host: string = getUrlHostName(scriptUri);
               dispatch(startImportPrivateData(host));
            } else {
               importSgData(scriptUri);
            }
         } else {
            setIdStat("");
         }
      }
   }, [sessionStorage]);

   useEffect(() => {
      if (privateDataState.hasFetched) {
         setIdStat(privateDataState.idStat);
      }
   }, [privateDataState.idStat]);

   return { idStat };
};

export { usePrivateData };
