import { CONTINUE_PARCOURS_REVENUS_COMPLEMENTAIRES, GO_TO_STEP_PARCOURS_REVENUS_COMPLEMENTAIRES, NEXT_STEP_REVENUS_COMPLEMENTAIRES, PREVIOUS_STEP_REVENUS_COMPLEMENTAIRES, RESET_PARCOURS_REVENUS_COMPLEMENTAIRES, STOP_PARCOURS_REVENUS_COMPLEMENTAIRES } from "./types";

export const resetParcours = () => ({
   type: RESET_PARCOURS_REVENUS_COMPLEMENTAIRES,
});

export const nextStep = () => ({
   type: NEXT_STEP_REVENUS_COMPLEMENTAIRES,
});

export const previousStep = () => ({
   type: PREVIOUS_STEP_REVENUS_COMPLEMENTAIRES,
});

export const stopParcours = () => ({
   type: STOP_PARCOURS_REVENUS_COMPLEMENTAIRES,
});

export const continueParcours = () => ({
   type: CONTINUE_PARCOURS_REVENUS_COMPLEMENTAIRES,
});

export const goToStep = (step: number) => ({
   type: GO_TO_STEP_PARCOURS_REVENUS_COMPLEMENTAIRES,
   payload: step
});
