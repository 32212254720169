import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {AssetMemberLink, AssetWithValuation, PossessionType,} from "../../../../store/assets/types";
import {MemberWithId} from "../../../../store/members/types";
import {isRealEstateAsset} from "../../../../store/assets/utils";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {usePersistedMembers} from "../../hooks/usePersistedMembers";

interface AssetMemberLinkEditProps {
   assetMemberLink: Partial<AssetMemberLink>;
   currentAsset: Partial<AssetWithValuation>;
   ind: number;
   cypressName: string;
}

const AssetMemberLinkEdit: FunctionComponent<AssetMemberLinkEditProps> = ({
                                                                             ind,
                                                                             assetMemberLink,
                                                                             currentAsset,
                                                                             cypressName
                                                                          }: AssetMemberLinkEditProps) => {
   const intl = useIntl();
   const availableMembers = usePersistedMembers();
   const possessionTypeValues: PossessionType[] = Object.values(PossessionType);

   return (
      <FormInputs colLength={10}>
         <SelectInput name={`assetMemberLink[${ind}].familyMemberId`} cypressName={`${cypressName}-member`}
                      placeholder={intl.formatMessage({id: "form.asset.assetMemberLink.select-member"})}
                      defaultValue={assetMemberLink?.familyMember?.id} required label="form.asset.assetMemberLink.member">
            {availableMembers.map((member: MemberWithId) => (
               <SGSelectOption key={member.id} value={member.id}>
                  {member.name}
               </SGSelectOption>
            ))}
         </SelectInput>

         <SelectInput name={`assetMemberLink[${ind}].possessionType`} cypressName={`${cypressName}-possession-type`}
                      defaultValue={assetMemberLink?.possessionType} label="form.asset.assetMemberLink.possessionType">
            {possessionTypeValues.filter((p) => p !== PossessionType.SCI).map(
               (possessionType: PossessionType) => (
                  <SGSelectOption key={possessionType} value={possessionType}>
                     {intl.formatMessage({
                        id: `possessionType.${possessionType}`,
                     })}
                  </SGSelectOption>
               )
            )}
         </SelectInput>

         {isRealEstateAsset(currentAsset) && (
            <NumericInput defaultValue={assetMemberLink?.percentage} suffix="%" name={`assetMemberLink[${ind}].percentage`}
                          cypressName={`${cypressName}-percentage`} min={0} max={100} label="form.asset.assetMemberLink.percentage"
                          placeholder={intl.formatMessage({id: "form.asset.assetMemberLink.percentage.placeholder"})}/>
         )}

         {!isRealEstateAsset(currentAsset) && (
            <SelectInput name={`assetMemberLink[${ind}].percentage`} defaultValue={assetMemberLink?.percentage}
                         cypressName={`${cypressName}-percentage`} label="form.asset.assetMemberLink.percentage">
               <SGSelectOption value={50}>50</SGSelectOption>
               <SGSelectOption value={100} selected>
                  100
               </SGSelectOption>
            </SelectInput>
         )}
      </FormInputs>
   );
};

export {AssetMemberLinkEdit};
