import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useMediaQuery } from "sg-media-query";
import { SGText } from "sg-typo";
import { savePromotionalOffer } from "store/promotionalOffers/action";
import { PromotionalOffer } from "store/promotionalOffers/types";
import { resetParcours } from "store/simulateurAvantApres/slice";
import { usePromotionalOffers } from "website/components/hooks/usePromotionalOffers";
import { SIMULATEUR_AVANT_APRES } from "website/utils/privateRoutes";
import { InfoAssuModal } from "../InfoAssuModal/InfoAssuModal";

interface OffreAvantApresModalProps {
    visible: boolean;
    setVisible: (showParcoursRISModal: boolean) => void;
}

const OffreAvantApresModal: FunctionComponent<OffreAvantApresModalProps> = (props: OffreAvantApresModalProps) => {
    const { visible, setVisible } = props;
    const { offreAvantApres, offresFetched } = usePromotionalOffers();
    const [initSaveOffre, setInitSaveOffer] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    /**
     * Si la popin est vu pour la première fois, max est défini à 1
     */
    useEffect(() => {
        if (offresFetched && !initSaveOffre) {
            setInitSaveOffer(true);
            const promotionalOffer: PromotionalOffer = {
                ...offreAvantApres,
                lastOfferDate: new Date(),
                viewCountOffer: incrementViewCountOffer(offreAvantApres.viewCountOffer),
            };
            dispatch(savePromotionalOffer(promotionalOffer));
        }
    }, [offresFetched]);

    const incrementViewCountOffer = (viewCountOffer: number) => (viewCountOffer < 3 ? viewCountOffer + 1 : viewCountOffer);

    return (
        <InfoAssuModal
            onHide={() => setVisible(false)}
            onClick={() => {
                dispatch(resetParcours());
                history.push(SIMULATEUR_AVANT_APRES);
            }}
            trackKey="popin-reforme-des-retraites"
            trackKeyOnHide="popin-reforme-des-retraites::clic-sur-fermer"
            trackKeyOnClick="popin-reforme-des-retraites::clic-sur-je-decouvre"
            showInfoModal={visible}
            tranform={{
                linebreak: <br />,
                p: (word: any) => <SGText weight="400" size={isPhone ? "s" : "m"} >{word}</SGText>
            }}
            text="modal.info.text.new"
            title="modal.info.title.new"
            buttonPrimary="modal.info.button.new"
            isBoutonSupervision
        />
    );
};

export { OffreAvantApresModal };
