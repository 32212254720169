import { FunctionComponent, ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SGTable } from "sg-table";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { Contenu } from "../ArticleModel";

interface ArticleTableauProps {
  contenu: Contenu;
}
/**
 * Le contenu de type "tableau"
 * Exemple json
 * {
    "type": "tableau",
    "headerTab": ["Enfants", "Majoration de"],
    "datasTab": [
      ["3", "10%"],
      ["4", "15%"],
      ["5", "20%"],
      ["6", "25%"],
      ["7 et plus", "30%"]
    ]
  }
  @returns Renvoie un tableau
 */
export const ArticleTableau: FunctionComponent<ArticleTableauProps> = (
  props: ArticleTableauProps
) => {
  const { contenu } = props;
  const {
    datasTab: donnees,
    headerTab: entete,
    rowspan,
    disableautomargin = false,
  } = contenu;

  const renderCell = (cellText: string): ReactNode => (
    <SGText whitespace="pre-line">
      <FormattedMessage
        id="cellule.texte"
        defaultMessage={cellText}
        values={{
          p: (word: string) => <SGBlock disableautomargin component="p">{word}</SGBlock>,
          b: (word: string) => <SGText weight="700">{word}</SGText>,
          sup: (word: string) => <sup>{word}</sup>,
        }}
      />
    </SGText>
  );

  const getTitle = () => {
    if (contenu.h3) {
      return (
        <SGTitle level={3} visuallevel={4}>
          {contenu.h3}
        </SGTitle>
      );
    }
    if (contenu.h4) {
      return (
        <SGTitle level={4} visuallevel={5}>
          {contenu.h4}
        </SGTitle>
      );
    }

    return <></>;
  };

  const donneeSource = useMemo(
    () =>
      donnees.map((ligne: string[]) =>
        ligne.reduce(
          (previous, current: string, index: number) => ({
            ...previous,
            [`col${index}`]: current,
          }),
          {}
        )
      ),
    []
  );
  const colonnes = useMemo(
    () =>
      entete.map((ligne: string, indexCol: number) => ({
        align: "center",
        dataIndex: `col${indexCol}`,
        key: `col${indexCol}`,
        title: ligne === "" ? undefined : ligne,
        onCell: (_: any, index: any) => {
          if (rowspan) {
            const row = rowspan.find((value: any) => value.col === indexCol);
            if (index === row?.start) {
              return {
                rowSpan: row?.count,
                style: {
                  verticalAlign: "middle",
                },
              };
            }

            if (row && index > row.start && index <= row.start + row.count) {
              return { rowSpan: 0 };
            }
          }

          return {};
        },
        render: renderCell,
      })),
    []
  );

  return (
    <SGTable
      disableautomargin={disableautomargin}
      dataSource={donneeSource}
      pagination={false}
      columns={colonnes}
      headercolor="dark"
      title={getTitle}
    />
  );
};
