import { FunctionComponent, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SGInputNumber } from "sg-input";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { ImportState } from "store/import/types";
import { State } from "store/store";
import { WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { useFamily } from "website/components/hooks/useFamily";

const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

interface DateDebutCarriereInputProps {
  carrierStartYears?: number;
  setCarrierStartYears: (value: number) => void;
  carrierStartMonth?: number;
  setCarrierStartMonth: (value: number) => void;
}

export const DateDebutCarriereInput: FunctionComponent<
  DateDebutCarriereInputProps
> = (props: DateDebutCarriereInputProps) => {
  const {
    carrierStartYears,
    setCarrierStartYears,
    carrierStartMonth,
    setCarrierStartMonth,
  } = props;

  const {
    control,
    setValue,
    formState: { errors, submitCount },
  } = useFormContext();
  const family = useFamily();

  const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<
    State,
    WorkIncomesHistoryState
  >((state) => state.workIncomesHistory);
  const importData: ImportState = useSelector<State, ImportState>(
    (state) => state.importData
  );

  const minCarrierStartYears = useMemo(() => {
    const birthday = new Date(family.me.birthday);

    return birthday.getFullYear() + 16;
  }, [family, importData]);

  const maxCarrierStartYears = new Date().getFullYear();

  useEffect(() => {
    if (
      workIncomesHistoryState &&
      workIncomesHistoryState.workIncomesHistory &&
      workIncomesHistoryState.workIncomesHistory.carrierStartDate
    ) {
      const carrierStartDate = new Date(
        workIncomesHistoryState.workIncomesHistory.carrierStartDate
      );
      setCarrierStartYears(carrierStartDate.getFullYear());
      setCarrierStartMonth(carrierStartDate.getMonth());
    }
  }, [workIncomesHistoryState]);

  useEffect(() => {
    setValue("carrierStartYears", carrierStartYears, {
      shouldValidate: submitCount > 0,
    });
  }, [carrierStartYears]);

  useEffect(() => {
    setValue("carrierStartMonth", carrierStartMonth, {
      shouldValidate: submitCount > 0,
    });
  }, [carrierStartMonth]);

  return (
    <SGSpace direction="vertical" size="sm">
      <SGText key="text" size="l">
        <FormattedMessage id="tunnel.workIncomesHistory.startWork" />
      </SGText>
      <SGSpace
        key="space"
        align="stretch"
        xs={{ direction: "vertical", size: "sm" }}
        sm={{ direction: "horizontal", size: "md" }}
      >
        <Controller
          key="carrierStartMonth"
          control={control}
          name="carrierStartMonth"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SGSelect
              placeholder="Sélectionnez"
              label={
                <SGText size="l">
                  <FormattedMessage id="tunnel.workIncomesHistory.mois" />
                </SGText>
              }
              value={
                carrierStartMonth !== undefined ? MONTHS[carrierStartMonth] : []
              }
              onChange={(value: number) => {
                field.onChange(value);
                setCarrierStartMonth(value);
              }}
              status={errors?.carrierStartMonth && "error"}
              validate={<FormattedMessage id="tunnel.error.required" />}
              helper={
                <FormattedMessage id="tunnel.workIncomesHistory.startWork.month.helper" />
              }
            >
              {MONTHS.map((month: string, index: number) => (
                <SGSelectOption key={month} value={index}>
                  {month}
                </SGSelectOption>
              ))}
            </SGSelect>
          )}
        />
        <Controller
          key="carrierStartYears"
          control={control}
          name="carrierStartYears"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SGInputNumber
              placeholder="1980"
              label={
                <SGText size="l">
                  <FormattedMessage id="tunnel.workIncomesHistory.année" />
                </SGText>
              }
              value={carrierStartYears}
              min={minCarrierStartYears}
              max={maxCarrierStartYears}
              onChange={(value: any) => {
                field.onChange(value);
                setCarrierStartYears(value);
              }}
              status={errors?.carrierStartYears && "error"}
              validate={<FormattedMessage id="tunnel.error.required" />}
              unitvalue=""
              thousandSeparator=""
            />
          )}
        />
      </SGSpace>
    </SGSpace>
  );
};
