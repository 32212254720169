export enum LoadingStatus {
  LOADING,
  DONE,
  ERROR,
}

/* eslint-disable camelcase */
export interface TcVarsEvent {
  event: string;
  tc_vars: TcVars;
}

export interface TcVars {
  env_work: string;
  env_channel: string;
  env_market: string;
  env_is_private: boolean;
  user_id: string;
  user_is_logged: boolean;
  user_is_identified: boolean;
  user_is_supervisor: boolean;
  page_noload: boolean;
  page_section: string;
  page_category_1: string;
  page_category_2: string;
  page_category_3: string;
  page_name: string;
  page_type: string;
  event_name: string;
  product_category_1: string;
  product_category_2: string;
  form_type: string;
  form_step: string;
  page_error_code: string;
  page_error_label: string;
  form_field_1?: string | number;
  form_field_2?: string;
  page_field_3?: string;
}

interface TrackPage {
  page_section: string;
  page_category_1: string;
  page_category_2: string;
  page_category_3: string;
  page_name: string;
  page_type: string;
  page_field_2?: string;
  page_field_3?: string;
  product_category_1: string;
  product_category_2: string;
  form_type: string;
  form_step: string;
  page_error_code: string;
  page_error_label: string;
  form_field_1?: string | number;
  form_field_2?: string;
}

export interface TrackingTrackEvent {
  page_category_1?: string;
  page_category_2?: string;
  page_category_3?: string;
  page_name?: string;
  event_name?: string;
  page_type?: string;
  page_field_1?: string;
  page_field_2?: string;
  page_field_3?: string;
  product_category_1?: string;
  product_category_2?: string;
  form_type?: string;
  form_step?: string;
  page_error_code?: string;
  page_error_label?: string;
  form_field_1?: string | number;
  form_field_2?: string;
}

/* eslint-enable camelcase */

// variable pour le tracking
export interface BddfTms {
  trackPage: (data: TrackPage) => void;
  trackEvent: (
    category: string | null,
    action: string,
    data: TrackingTrackEvent
  ) => void;
}

// tracking au clic d'un lien ouvrant la popin hypotheses
export const PAGE_TYPE_FORMULAIRE = "formulaire-ecran";
export const PAGE_TYPE_GESTION = "gestion-ecran";
export const PAGE_TYPE_ERREUR = "erreur";
export const TRACKING_CLICK_HYPOTHESES = "clic-sur-le-detail-des-hypotheses";

export const PAGE_CATEGORY_3_RIS = "parcours-ris";
export const PAGE_CATEGORY_3_MODIFIER_PROFIL = "modifier-mon-profil";
export const PAGE_CATEGORY_3_FLASH = "simulateur-flash";
export const PAGE_CATEGORY_3_ONBOARDING = "parcours-onboarding"

export const PAGE_NAME_LOADER_CONNEXION = "loader-entree-parcours-connexion";
export const PAGE_NAME_LOADER_CHARGEMENT = "loader-entree-parcours-chargement";
export const PAGE_NAME_MA_RETRAITE = "ma-retraite";
export const PAGE_NAME_MA_SITUATION_PERSONNELLE = "ma-situation-personnelle";
export const PAGE_NAME_MA_SITUATION_PROFESSIONNELLE = "ma-situation-professionnelle";
export const PAGE_NAME_ESTIMATION_PENSION = "estimation-pension";
