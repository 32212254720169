import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {FamilyState, MemberWithId} from "../../../store/members/types";
import {getMembersAction} from "../../../store/members/actions";
import {isMemberWithId} from "../../../store/members/typeguards";
import {toMembers} from "../../../store/members/utils";

const usePersistedMembers = (): MemberWithId[] => {
   const dispatch = useDispatch();
   const [persistedMembers, setPersistedMembers] = useState<MemberWithId[]>([]);
   const familyState: FamilyState = useSelector<State, FamilyState>((state) => state.family);

   useEffect(() => {
      if (!familyState.hasFetched) {
         dispatch(getMembersAction());
      }
   }, []);

   useEffect(() => {
      if (familyState.hasFetched) {
         const membersPersistedToReturn: MemberWithId[] = [];
         toMembers(familyState.family).forEach((member) => {
            if (isMemberWithId(member)) {
               membersPersistedToReturn.push(member);
            }
         });
         setPersistedMembers(membersPersistedToReturn);
      }
   }, [familyState]);

   return persistedMembers;
};

export {usePersistedMembers};
