import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CallToActionState, MeetingAppointmentState} from "./types";

const initialState: CallToActionState = {simulationPDF: undefined, meetingAppointmentState: undefined};

export const callToActionSlice = createSlice({
   name: "callToAction",
   initialState,
   reducers: {
      meetingCanBeSentFetched: (currentState, action: PayloadAction<MeetingAppointmentState>) => ({
         ...currentState,
         meetingAppointmentState: action.payload
      }),
      simulationPdfFetched: (currentState, action: PayloadAction<BlobPart>) => ({
         ...currentState,
         simulationPDF: action.payload
      }),
      meetingScheduled: () => ({
         meetingAppointmentState: MeetingAppointmentState.ALREADY_SENT_FOR_THIS_USER
      })
   }
});

export const {meetingCanBeSentFetched, simulationPdfFetched, meetingScheduled} = callToActionSlice.actions;

export default callToActionSlice.reducer;
