import { PayloadAction } from "@reduxjs/toolkit";
import { FETCH_PERSONNALISATION_DASHBOARD, FETCH_PERSONNALISATION_DASHBOARD_PARAMS } from "./types";

export const callPersonnalisationDashboard = (params: number[]): PayloadAction<number[]> => ({
   type: FETCH_PERSONNALISATION_DASHBOARD,
   payload: params,
});

export const fetchPersonnalisationDashboardParams = () => ({
   type: FETCH_PERSONNALISATION_DASHBOARD_PARAMS,
});
