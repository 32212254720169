import debounce from "lodash.debounce";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStatusError,
  SGAvenirStatusInfo,
} from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { callTaxEconomySimulation } from "store/simulateurEconomiesImpots/actions";
import {
  resetParcours,
  resetTaxEconomySimulationError,
} from "store/simulateurEconomiesImpots/slice";
import {
  EconomieImpot,
  ParametresSEI,
} from "store/simulateurEconomiesImpots/types";
import { getTrackProfile } from "store/simulateurEconomiesImpots/utils";
import { callTaxEconomyCeiling } from "store/simulateurPlafondEconomieImpot/actions";
import {
  ParametresPlafondEconomieImpot,
  PlafondsSEI,
} from "store/simulateurPlafondEconomieImpot/types";
import {
  convertParametresSEIToParametresPlafondEconomieImpot,
  isEqualParametresPlafondEconomieImpot,
} from "store/simulateurPlafondEconomieImpot/utils";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { RoundedSalary } from "website/components/atoms/RoundedSalary/RoundedSalary";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import {
  OffreType,
  PromotionalBanner,
} from "website/components/molecules/PromotionalBanner/PromotionalBanner";
import { anneeN } from "website/utils/date/DateUtils";
import { DASHBOARD } from "website/utils/privateRoutes";
import { BarreProgressionPlafond } from "./BarreProgressionPlafond/BarreProgressionPlafond";
import { BlocImpot } from "./BlocImpot/BlocImpot";
import { BlocVersement } from "./BlocVersement/BlocVersement";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { SGLink } from "sg-link";

export const VersementStep: FunctionComponent = () => {
  const dispatch = useDispatch();
  const openSGAgenda = useSGAgenda();
  const { disponibiliteSGAgenda, disponibiliteOffrePER } = useFeatures();
  const { trackClick, trackPage } = useTracking();

  const intl = useIntl();
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const newSimulation: boolean = useSelector<State, boolean>(
    (state) => state.simulateurEconomiesImpots.newSimulation
  );
  const hasFetchEconomie: boolean = useSelector<State, boolean>(
    (state) => state.simulateurEconomiesImpots.hasFetched
  );
  const taxEconomy: EconomieImpot = useSelector<State, EconomieImpot>(
    (state) => state.simulateurEconomiesImpots.taxEconomy
  );

  const plafondsSEI: PlafondsSEI = useSelector<State, PlafondsSEI>(
    (state) => state.simulateurPlafondEconomieImpot.plafondsSEI
  );
  const plafondsSEIError = useSelector<State, string | undefined>(
    (state) => state.simulateurPlafondEconomieImpot.plafondsSEIError
  );
  const parameters: ParametresPlafondEconomieImpot = useSelector<
    State,
    ParametresPlafondEconomieImpot
  >((state) => state.simulateurPlafondEconomieImpot.parameters);
  const newParameters: ParametresSEI = useSelector<State, ParametresSEI>(
    (state) => state.simulateurEconomiesImpots.parameters
  );
  const savedParameters: ParametresSEI = useSelector<State, ParametresSEI>(
    (state) => state.simulateurEconomiesImpots.savedParameters
  );
  const [currentParameters] = useState<ParametresSEI>(
    newSimulation ? newParameters : savedParameters
  );
  const [plafond, setPlafond] = useState<number>();
  const [versement, setVersement] = useState(
    newSimulation ? undefined : savedParameters.payment
  );
  const [etatBlocsImpots, setEtatBlocsImpots] = useState([false, false]);

  const isPlafondErreur = useMemo(
    () => plafondsSEIError !== undefined,
    [plafondsSEIError, currentParameters]
  );

  const [showErrorModal, setShowErrorModal] = useState<boolean | undefined>(
    undefined
  );
  const [showErrorSGAgendaModal, setShowErrorSGAgendaModal] = useState<
    boolean | undefined
  >(undefined);
  const taxEconomySimulationError = useSelector<State, string | undefined>(
    (state) => state.simulateurEconomiesImpots.taxEconomySimulationError
  );
  const [hasChangedValue, setHasChangedValue] = useState<boolean>(false);

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const showPromotionalBanner = () =>
    new Date() > new Date(window.env.REACT_APP_OFFRE_PER_DEBUT) &&
    new Date() < new Date(window.env.REACT_APP_OFFRE_PER_FIN) &&
    disponibiliteOffrePER;

  const maxValueInput = 999_999;
  const minValueInput = 150;

  useEffect(() => {
    if (showErrorModal === undefined) {
      setShowErrorModal(false);
    } else {
      setShowErrorModal(taxEconomySimulationError !== undefined);
    }
  }, [taxEconomySimulationError]);

  useEffect(() => {
    // Permet d'autoriser le premier l'appel à l'api 'simulateur-fiscal-economie-impot' si le versement est inférieur à 150
    if (hasFetchEconomie) {
      setHasChangedValue(true);
    }
  }, [hasFetchEconomie]);

  useEffect(() => {
    trackPage(
      "parcours-sei",
      "mon-economie-impot",
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "4",
      {
        form_field_1: getTrackProfile(
          newSimulation ? newParameters.tnsStatus : savedParameters.tnsStatus
        ),
      }
    );
  }, []);

  const debouncedCallTaxEconomy = useCallback(
    debounce(
      (nextValue: ParametresSEI) =>
        dispatch(callTaxEconomySimulation(nextValue)),
      500
    ),
    [] // will be created only once initially
  );

  // Calculs de plafonds
  useEffect(() => {
    if (currentParameters) {
      // Lors d'un resetParcours ou d'une ancienne simulation, le plafond est remis à undefined
      if (!currentParameters.totalAvailableCeiling) {
        // Si nouvelle simulation: calcul avec les nouveaux parametres
        // Sinon avec les parametres enregistrés
        const parametresPlafondEconomieImpot =
          convertParametresSEIToParametresPlafondEconomieImpot(
            currentParameters
          );
        // Appel au plafond uniquement si les paramètres changent
        if (
          !isEqualParametresPlafondEconomieImpot(
            parameters,
            parametresPlafondEconomieImpot
          )
        ) {
          dispatch(callTaxEconomyCeiling(parametresPlafondEconomieImpot));
        }
      }
    }
  }, [currentParameters]);

  // Si le versement change, on appelle la simulation
  useEffect(() => {
    if (plafondsSEI.availableCeiling !== undefined && versement !== undefined && (!hasChangedValue || versement >= minValueInput)) {
      setPlafond(plafondsSEI.availableCeiling);
      debouncedCallTaxEconomy({
        ...currentParameters,
        totalAvailableCeiling: plafondsSEI.availableCeiling,
        payment: versement,
      });
    }
  }, [versement, plafondsSEI.availableCeiling, hasChangedValue]);

  const onResetParcours = () => {
    trackClick("clic-sur-refaire-une-simulation");
    dispatch(resetParcours());
  };

  const getPlafond = () => {
    if (plafondsSEI.availableCeiling) {
      return plafondsSEI.availableCeiling;
    }

    return 0;
  };

  const isAlertVersement = () => {
    if (versement === null || versement === undefined || plafond === null) {
      return false;
    }

    return versement !== plafond;
  };

  const handleError = (visible: boolean) => {
    setShowErrorModal(visible);
    if (!visible) {
      dispatch(resetTaxEconomySimulationError());
    }
  };

  const handleDeplier = (deplie: boolean, typeBloc: string) => {
    setEtatBlocsImpots([
      typeBloc === "apres" ? deplie : etatBlocsImpots[0],
      typeBloc === "avant" ? deplie : etatBlocsImpots[1],
    ]);
  };

  const handleOnClickRdv = () => {
    trackClick(`clic-sur-prendre-rendez-vous`, {
      form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
    });
    // FIXME : Déporter dans BoutonSGAgenda
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorSGAgendaModal(true);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(() => false)} className="versement-step">
          <SGGridRow justify="center" gutter={[0, isPhone ? 16 : 24]}>
            <SGGridCol span={12}>
              <SGTitle level={2} textalign="left">
                {intl.formatMessage({
                  id: "simulateurEconomiesImpots.step.versement.header",
                })}
              </SGTitle>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGText size="l">
                {intl.formatMessage(
                  {
                    id: "simulateurEconomiesImpots.step.versement.description",
                  },
                  { anneeN }
                )}
              </SGText>
            </SGGridCol>
            <SGGridCol span={isPhone ? 12 : 4}>
              <RoundedSalary
                disableautomargin
                salary={versement}
                setSalary={setVersement}
                maxValueInput={maxValueInput}
                minValueInput={minValueInput}
                minMessage={`Le montant minimum de versement est de ${minValueInput.toLocaleString("fr-FR")}${" "}€`}
                required
                disabled={
                  !possedeDroitsActionUtilisateur(supervisionState) ||
                  isPlafondErreur
                }
              />
            </SGGridCol>
            {isPlafondErreur && (
              <SGAlert
                span={9}
                icon={<SGIcon component={<SGAvenirStatusError />} size="l" />}
                message={<SGTextIntl intlId="modal.error.message" />}
                type="error"
              />
            )}
            <SGGridCol span={12} sm={9}>
              <BarreProgressionPlafond
                plafond={getPlafond()}
                versement={versement ?? 0}
                isPlafondErreur={isPlafondErreur}
              />
            </SGGridCol>
            {plafond !== undefined &&
              versement !== undefined &&
              isAlertVersement() &&
              !isPlafondErreur && (
                <SGGridCol span={12}>
                  <SGAlert
                    span={9}
                    description={
                      <SGText size="m">
                        {intl.formatMessage({
                          id: `simulateurEconomiesImpots.step.versement.alert.texte.${
                            versement > plafond ? "superieur" : "inferieur"
                          }`,
                        })}
                      </SGText>
                    }
                    icon={
                      <SGIcon component={<SGAvenirStatusInfo />} size="l" />
                    }
                    message={
                      versement > plafond &&
                      intl.formatMessage({
                        id: "simulateurEconomiesImpots.step.versement.alert.titre",
                      })
                    }
                    type="info"
                  />
                </SGGridCol>
              )}

            {/* Partie basse */}
            {versement !== undefined &&
              hasFetchEconomie &&
              !isPlafondErreur && (
                <>
                  <SGGridCol span={12}>
                    <SGSpace
                      xs={{ direction: "horizontal", size: "sm" }}
                      sm={{ direction: "horizontal", size: "md" }}
                      align="stretch"
                      disableautomargin
                    >
                      <BlocVersement
                        key="blocVersement-1"
                        background="light"
                        montant={taxEconomy.taxSaving}
                        titre="Économie d’impôts liée au versement complémentaire"
                      />
                      <BlocVersement
                        key="blocVersement-2"
                        background="Sec_Sg2"
                        montant={versement - taxEconomy.taxSaving}
                        titre="Éffort d’épargne réel"
                      />
                    </SGSpace>
                  </SGGridCol>

                  <SGGridCol span={12}>
                    <SGGridRow
                      gutter={[24, 16]}
                      align={
                        etatBlocsImpots.find((etat) => !etat) !== undefined
                          ? "top"
                          : "stretch"
                      }
                    >
                      <SGGridCol span={12} sm={6}>
                        <BlocImpot
                          typeBloc="apres"
                          incomeTax={taxEconomy.incomeTaxWithPayment}
                          handleDeplier={(deplie: boolean) =>
                            handleDeplier(deplie, "apres")
                          }
                        />
                      </SGGridCol>
                      <SGGridCol span={12} sm={6}>
                        <BlocImpot
                          typeBloc="avant"
                          incomeTax={taxEconomy.incomeTaxWithoutPayment}
                          handleDeplier={(deplie: boolean) =>
                            handleDeplier(deplie, "avant")
                          }
                        />
                      </SGGridCol>
                    </SGGridRow>
                  </SGGridCol>
                </>
              )}
            <SGGridCol span={12} textalign="right">
              <BoutonSupervision
                type="tertiary"
                icon={
                  <SGIcon
                    component={<SGAvenirNavFlecheBtnLien />}
                    currentcolor
                  />
                }
                iconposition="right"
                onClick={onResetParcours}
              >
                {intl.formatMessage({
                  id: "simulateurEconomiesImpots.step.versement.back",
                })}
              </BoutonSupervision>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGBlock type="texthighlight">
                <SGTitle visuallevel={4} level={3} caps>
                  {intl.formatMessage({
                    id: "simulateurEconomiesImpots.step.versement.SaviezVous.titre",
                  })}
                </SGTitle>
                <SGText>
                  {intl.formatMessage(
                    {
                      id: "simulateurEconomiesImpots.step.versement.SaviezVous.texte",
                    },
                    { b: (word) => <SGText strong>{word}</SGText> }
                  )}
                </SGText>
              </SGBlock>
            </SGGridCol>
            {showPromotionalBanner() && (
              <SGGridCol span={12}>
                <PromotionalBanner offreType={OffreType.PER} />
              </SGGridCol>
            )}
            {/* Boutons */}
            <SGGridCol span={12}>
              <SGBox margin={{ top: isPhone ? "lg" : "xxl" }}>
                <SGButtonGroup
                  layout={isPhone ? "column" : "row"}
                  align={isPhone ? "center" : "opposite"}
                >
                  <BoutonSGAgenda onClick={handleOnClickRdv}>
                    {intl.formatMessage({
                      id: "simulateurEconomiesImpots.step.versement.contacter.conseiller",
                    })}
                  </BoutonSGAgenda>
                  <SGLink
                    href={`#${DASHBOARD}`}
                    type="secondary"
                    onClick={() => {
                      trackClick(
                        "clic-sur-retour-a-mon-tableau-de-bord"
                      )
                    }}
                  >
                    {intl.formatMessage({
                      id: "simulateurEconomiesImpots.step.versement.next",
                    })}
                  </SGLink>
                </SGButtonGroup>
              </SGBox>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGText color="lighter" size="xs">
                {intl.formatMessage({
                  id: "simulateurEconomiesImpots.step.versement.basDePage",
                })}
              </SGText>
            </SGGridCol>
          </SGGridRow>
        </form>
      </FormProvider>
      {showErrorModal && (
        <ErrorModal visible={showErrorModal} setVisible={handleError} />
      )}
      {showErrorSGAgendaModal && (
        <ErrorModal
          visible={showErrorSGAgendaModal}
          setVisible={setShowErrorSGAgendaModal}
          description="modal.error.message.indisponible"
        />
      )}
    </>
  );
};
