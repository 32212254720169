// model des articles définis en json
export interface ArticleModel {
   h1: string;
   theme: string;
   date: string;
   time: string;
   imageTitle: string;
   alt?: string;
   cardTitle: string;
   contenus: Contenu[];
}
// model des contenus des articles définis en json
export interface Contenu {
   id: string;
   idMobile: string;
   type: ContenuType;
   h2: string;
   h3: string;
   h4: string;
   h5: string;
   text: string;
   informations: string[];
   // Si <link>{index}#;#{label}</link> ou premier si <link>label</link>
   links: string[];
   anchor: string;
   children: Contenu[];
   taille: number;
   color: string;
   modal: { idTracking: string; desktop: { title: string; image: string; alt: string }; mobile: { title: string; image: string; alt: string } };
   // Pour cadreGris
   contenu: Contenu;
   idTracking: string;
   alt?: string;
   url: string;
   cover: string;
   datasTab: string[][];
   headerTab: string [];
   rowspan: {col: number; start: number; count: number;}[];
   disableautomargin: boolean;
}
// définit les différents types de contenus
export enum ContenuType {
   legend = "legend",
   retenir = "retenir",
   paragraphe = "paragraphe",
   image = "image",
   basDePage = "basDePage",
   contacter = "contacter",
   chevron = "chevron",
   parcoursRIS = "parcoursRIS",
   imageTexte = "imageTexte",
   divider = "divider",
   row = "row",
   col3 = "col3",
   col6 = "col6",
   cadreGris = "cadreGris",
   tableau= "tableau",
   tableauFemmeExploitantAgricole = "tableauFemmeExploitantAgricole",
   video = "video",
   disclaimer = "disclaimer",
}
// définit les différentes thématiques
export enum Theme {
   fonctionnementRetraite = "fonctionnement-retraite",
   astuceRetraite = "astuce-retraite",
   strategieRetraite = "strategie-retraite",
}

export enum Thematique {
   fonctionnementRetraite = "fonctionnementRetraite",
   femmeRetraite = "femmeRetraite",
   momentsVie = "momentsVie",
   tnsEtFonctionnaires = "tnsEtFonctionnaires",
   epargner = "epargner",
}

export enum SousThematique {
   tous = "Tous",
   age = "Âge",
   conseil = "Conseil",
   depart = "Départ",
   comprendre = "Comprendre",
   calcul = "Calcul",
   astuces = "Les astuces",
   ris = "Le RIS",
   enfants = "Les enfants",
   artisansCommercants = "Artisans / commerçants",
   professionLiberale = "Profession libérale",
   medecins = "Médecins",
   fonctionnaires = "Fonctionnaires",
   travailleurIndependant = "Travailleur indépendant",
}

export function getIdTracking(theme: string): string {
   switch (theme) {
      case Theme.fonctionnementRetraite:
         return "comment-fonctionne-la-retraite";
      case Theme.astuceRetraite:
         return "astuces-et-cas-particuliers";
      case Theme.strategieRetraite:
         return "les-meilleures-strategies-pour-preparer-sa-retraite";
      default:
         return "";
   }
}

export const ONGLET_ACCUEIL = "accueil";

export function getIdTrackingOnglet(onglet: string) {
   switch (onglet) {
      case Thematique.fonctionnementRetraite:
         return "fonctionnement-de-la-retraite";
      case Thematique.femmeRetraite:
         return "femmes-et-retraite";
      case Thematique.tnsEtFonctionnaires:
         return "tns-et-fonctionnaires";
      case Thematique.momentsVie:
         return "moments-de-vie";
      case Thematique.epargner:
         return "epargner"
      default:
         return ONGLET_ACCUEIL;
   }
}

export function getIdTrackingFiltre(filtre: string) {
   switch (filtre) {
      case SousThematique.tous:
         return "tous";
      case SousThematique.age:
         return "age";
      case SousThematique.conseil:
         return "conseil";
      case SousThematique.depart:
         return "depart";
      case SousThematique.comprendre:
         return "comprendre";
      case SousThematique.calcul:
         return "calcul";
      case SousThematique.astuces:
         return "astuces";
      case SousThematique.ris:
         return "ris";
      case SousThematique.enfants:
         return "enfants";
      case SousThematique.artisansCommercants:
         return "artisans-commercants";
      case SousThematique.professionLiberale:
         return "profession-liberale";
      case SousThematique.medecins:
         return "medecins";
      case SousThematique.fonctionnaires:
         return "fonctionnaires";
         case SousThematique.travailleurIndependant:
            return "travailleur-independant";
      default:
         return "";
   }
}
