import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGText, SGTitle } from "sg-typo";
import { Loader } from "website/components/atoms/Loader/Loader";
import { ServiceModel } from "../../organisms/MesServices/ServiceModel";
import { ArticleContenu } from "../Article/ArticleContenu/ArticleContenu";
import "./ServiceContenuModal.scss";
import { SGBox, SGSpace } from "sg-space";
import { TypeMedia } from "website/components/hooks/articles";

export interface ServiceContenuModalProps {
    service: ServiceModel;
    logo: string;
    showModalService?: boolean;
    setShowModalService: (show: boolean) => void;
}

export const ServiceContenuModal: FunctionComponent<ServiceContenuModalProps> = (props: React.PropsWithChildren<ServiceContenuModalProps>) => {
    const { service, logo, showModalService, setShowModalService } = props;
    const intl = useIntl();

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
        }, 700);
    }, []);

    return (
        <SGModal
            className="service-contenu-modal__container"
            closable
            visible={showModalService}
            centered
            onCancel={() => setShowModalService(false)}
        >
            {showLoader ? (
                <Loader />
            ) : (
                <SGScroll>
                    <SGSpace direction="vertical">
                        <SGGridRow key="row-1" gutter={[0, 20]} align="middle">
                            <SGGridCol span={8} textalign="left">
                                <SGTitle level={3}>
                                    {intl.formatMessage({ id: `mesServices.services.card.${service.id}.title` })}
                                </SGTitle>
                            </SGGridCol>
                            <SGGridCol span={3}>
                                <img style={{width: "100%"}} alt={`logo-service-${logo}`} src={logo}/>
                            </SGGridCol>
                        </SGGridRow>
                        <SGGridRow key="row-2" gutter={[0, 12]}>
                            <SGBox style={{width: "100%"}} padding={{right: "sm"}}><>
                            {service.subtitle && <SGGridCol span={12} align="start">
                                <SGText style={{ color: "#000000" }} weight="600" size="m">
                                    <FormattedMessage id="contenu.subtitle" defaultMessage={service.subtitle} values={{ linebreak: <br /> }} />
                                </SGText>
                            </SGGridCol>}
                            <SGSpace direction="vertical">
                                {service.contenus.map((contenu, index) => (
                                    // Sans l'index, problème lors du changement de service
                                    <ArticleContenu key={`${contenu.type}_${String(index)}`} contenu={contenu} type={TypeMedia.ARTICLE}/>
                                ))}
                            </SGSpace>
                            </></SGBox>
                        </SGGridRow>
                    </SGSpace>
                </SGScroll>
            )}
        </SGModal>
    );
};
