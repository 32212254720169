import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { AssetWithValuation } from "store/assets/types";
import { resetParcours } from "store/parcoursRevenusComplementaires/action";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { PARCOURS_REVENUS_COMPLEMENTAIRES } from "website/utils/privateRoutes";
import "./MonPatrimoine.scss";
import { SGLink } from "sg-link";

interface MonPatrimoineProps {
  isParcoursPatrimoineDone?: boolean;
  assets: AssetWithValuation[];
}

const MonPatrimoine: FunctionComponent<MonPatrimoineProps> = (
  props: MonPatrimoineProps
) => {
  const { isParcoursPatrimoineDone, assets } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [partPatrimoineRetraite, setPartPatrimoineRetraite] =
    useState<number>(0);
  const [totalPatrimoine, setTotalPatrimoine] = useState<number>(0);
  const [totalPatrimoineRetraite, setTotalPatrimoineRetraite] =
    useState<number>(0);

  const { disponibiliteParcoursRevenusComplementaires } = useFeatures();
  const { trackClick } = useTracking();

  useEffect(() => {
    if (assets && assets.length > 0) {
      const filteredAssets = assets.filter(
        (asset) => asset.percentageUsedForRetirement > 0
      );

      // Calcule le montant total des assets cochées par l'utilisateur dans l'étape 2 du parcours patrimoine
      if (filteredAssets.length > 0) {
        setTotalPatrimoineRetraite(
          filteredAssets
            .map((asset) => getAssetPart(asset))
            .reduce(
              (totalValuationA, totalValuationB) =>
                totalValuationA + totalValuationB
            )
        );
      }

      // Calcule le montant total des assets de l'étape 1 du parcours patrimoine
      setTotalPatrimoine(
        assets
          .map((asset) => asset.assetValuation.totalValuation || 0)
          .reduce(
            (totalValuationA, totalValuationB) =>
              totalValuationA + totalValuationB
          )
      );
    }
  }, [assets]);

  useEffect(() => {
    setPartPatrimoineRetraite(
      Math.floor((totalPatrimoineRetraite / (totalPatrimoine || 1)) * 100)
    );
  }, [totalPatrimoine, totalPatrimoineRetraite]);

  const trackRedirectToParcoursRevenusComplementaires = () => {
    trackClick(
      `mon-patrimoine::clic-sur-${
        isParcoursPatrimoineDone
          ? "je-modifie-mon-patrimoine"
          : "je-renseigne-mon-patrimoine"
      }`
    );
    dispatch(resetParcours());
  };

  /**
   * Calcule la part d'une asset par rapport au pourcentage défini par l'utilisateur
   *
   * @param asset
   * @returns part de l'asset en fonction de son pourcentage
   */
  const getAssetPart = (asset: AssetWithValuation) => {
    if (asset.id && asset.assetValuation.totalValuation) {
      return (
        (asset.percentageUsedForRetirement / 100) *
        asset.assetValuation.totalValuation
      );
    }

    return 0;
  };

  return (
    <SGCard
      coversize="md"
      hoverable
      borderleft="7px solid #208382"
      title={
        <SGText
          style={{
            color: "#208382",
          }}
          size="xl"
          weight="600"
        >
          {intl.formatMessage({ id: "monPlan.monPatrimoine.title" })}
        </SGText>
      }
      className=" mon-patrimoine__card"
    >
      <>
        <SGGridRow>
          <SGGridCol>
            <SGText>
              <SGTextIntl
                intlId={`monPlan.monPatrimoine.${
                  isParcoursPatrimoineDone ? "done" : "not.done"
                }`}
                size="m"
                transformations={{
                  totalPatrimoine: totalPatrimoine?.toLocaleString("fr-FR"),
                  partPatrimoineRetraite:
                    partPatrimoineRetraite?.toLocaleString("fr-FR"),
                  b: (word: string) => <strong>{word}</strong>,
                }}
              />
            </SGText>
          </SGGridCol>
        </SGGridRow>
        {disponibiliteParcoursRevenusComplementaires && (
          <SGBox margin={{ top: "sm" }} textalign="right">
            <SGLink
              href={`#${PARCOURS_REVENUS_COMPLEMENTAIRES}`}
              onClick={trackRedirectToParcoursRevenusComplementaires}
              type="tertiary"
              icon={
                <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
              }
              iconside="right"
            >
              {intl.formatMessage({
                id: `monPlan.monPatrimoine.${
                  isParcoursPatrimoineDone ? "modifier" : "renseigner"
                }`,
              })}
            </SGLink>
          </SGBox>
        )}
      </>
    </SGCard>
  );
};
export { MonPatrimoine };
