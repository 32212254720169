import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountData, AccountState } from "./types";

const initialState: AccountState = {
  account: {
    id: -1,
    login: "",
    firstName: "",
    lastName: "",
    email: "",
    imageUrl: "",
    activated: false,
    langKey: "fr",
    createdBy: "",
    createdDate: "",
    lastModifiedBy: "",
    lastModifiedDate: "",
    authorities: [],
    loggedAs: "",
    tunnelCompleted: false,
    disclaimerRead: false,
    assetsImported: false,
    cookieProvider: "",
    ssouser: false,
    title: "",
    visitedTaxSimulator: false,
    visitedSuccessionSimulator: false,
    completedTutorial: false,
    potentielSousSegmentNSM: "",
    codeSegmentMarcheNSM: undefined,
    supervision: true,
  },
  hasFetched: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    accountUpdated: (currentState, action: PayloadAction<AccountData>) => ({
      ...currentState,
      account: action.payload,
    }),
    accountFetched: (currentState, action: PayloadAction<AccountData>) => ({
      account: {
        ...action.payload,
        codeSegmentMarcheNSM: currentState.account.codeSegmentMarcheNSM,
      },
      hasFetched: true,
    }),
  },
});

export const { accountUpdated, accountFetched } = accountSlice.actions;

export default accountSlice.reducer;
