import { Reducer } from "react";
import {
  PRIVATE_DATA_FETCHED,
  PrivateDataState
} from "./types";

const initialState: PrivateDataState = {
  idStat: undefined,
  hasFetched: false,
  hasFetchedError: false,
};

export const PrivateDataReducer: Reducer<PrivateDataState, any> = (
  currentState = initialState,
  action
): PrivateDataState => {
  switch (action.type) {
    case PRIVATE_DATA_FETCHED:
      return {
        hasFetched: true,
        ...action.payload
      };
    default:
      return currentState;
  }
};
