import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";

interface CarteProps {
    titre: string,
    sousTitre? : boolean,
    avant: JSX.Element,
    apres: JSX.Element
}

export const Carte: FunctionComponent<CarteProps> = (props: CarteProps) => {
    const intl = useIntl();
    const { titre, sousTitre = false, avant, apres } = props;
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });

    return (
        <SGCard>
            <SGGridRow>
                <SGGridCol span={12} textalign={isPhone ? "left" : "center"}>
                    <SGText size="xl" weight={isPhone ? "600" : "700"} style={{color: "#010035"}}>
                        {intl.formatMessage({ id: `simulateurAvantApres.resultats.${titre}.titre` })}
                    </SGText>
                </SGGridCol>
                <SGGridCol span={12}>
                    <SGBox margin={{top: "sm", bottom: isPhone && !sousTitre ? "md" : "sm"}}>
                        <SGDivider disableautomargin/>
                    </SGBox>
                </SGGridCol>
                { sousTitre &&
                    <SGGridCol span={12} textalign={isPhone ? "left" : "center"}>
                        <SGBox margin={{bottom: "md"}}>
                            <SGText size="l" weight="600">
                                {intl.formatMessage({ id: `simulateurAvantApres.resultats.${titre}.sousTitre` })}
                            </SGText>
                        </SGBox>
                    </SGGridCol>
                }
                <SGGridCol span={6} textalign="center">
                    { avant }
                </SGGridCol>
                <SGGridCol span={6} textalign="center">
                    { apres }
                </SGGridCol>
            </SGGridRow>
        </SGCard>
    );
};
