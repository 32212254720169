import { PayloadAction } from "@reduxjs/toolkit";
import { Appointment, CREATE_APPOINTMENT, FETCH_APPOINTMENT, UPDATE_APPOINTMENT } from "./types";

/**
 * Appelle le backpfr pour récupérer le rdv
 */
export const fetchAppointment = () => ({
  type: FETCH_APPOINTMENT,
});

/**
 * Appelle le backpfr pour créer le rdv
 */
export const createAppointment = (data: Partial<Appointment>) => ({
  type: CREATE_APPOINTMENT,
  payload: data,
});

/**
 * Met à jour l'appointment dans le store uniquement
 * @param data Appointment complet avec mises à jour
 */
export const updateAppointment = (data: Partial<Appointment>): PayloadAction<Partial<Appointment>> => ({
  type: UPDATE_APPOINTMENT,
  payload: data,
});
