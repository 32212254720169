import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { AssetIncomes, ASSET_INCOMES_FETCHED, FETCH_ASSETS_INCOMES } from "./types";

export function* watcherSaga() {
  yield takeLeading(
    FETCH_ASSETS_INCOMES,
    safe(onDefaultError, handleGetAssetIncomes)
  );
}

function* handleGetAssetIncomes() {
  const payload: AxiosResponse<AssetIncomes> = yield call(apiGet, `wealth/api/retirement/asset-incomes`);
  yield put({ type: ASSET_INCOMES_FETCHED, payload: payload.data });
}
