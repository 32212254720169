import React, {FunctionComponent} from "react";
import {CGU} from "../components/organisms/CGU/CGU";
import {SGContent} from "sg-layout";

const CGUPage: FunctionComponent = () => (
   <SGContent>
      <CGU/>
   </SGContent>
);

export {CGUPage};
