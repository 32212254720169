import { useEffect, useState } from "react";
import { hasFetchedDashboard, PathType } from "store/dashboard/types";
import { getLastPathDate } from "store/dashboard/utils";
import { MessageType } from "website/components/templates/Dashboard/Dashboard";
import { useDashboardState } from "./useDashboardState";

const useDashboardLastPathDoneAndMessage = () => {
    const dashboardState = useDashboardState();
    const [lastPathDone, setLastPathDone] = useState<PathType>();
    const [messageType, setMessageType] = useState<MessageType>();

    useEffect(() => {
        if (hasFetchedDashboard(dashboardState)) {
            const newLastPathDone = getLastPathDate(dashboardState.dashboard).path;
            // Inutile d'aller plus loin car on ne regarde que le dernier événement
            if (newLastPathDone === lastPathDone) {
                return;
            }

            let type;

            if (
                dashboardState.dashboard.firstRISPathDate &&
                dashboardState.dashboard.firstPatrimonyPathDate &&
                dashboardState.dashboard.monthlyIncomeGoal &&
                dashboardState.dashboard.firstSavingPathDate &&
                dashboardState.dashboard.firstMyPlanDate
            ) {
                // Si tous les parcours sont renseignés, on affiche le message F
                type = MessageType.F;
            } else {
                // Sinon, on affiche en fonction de la dernière étape réalisée
                switch (newLastPathDone) {
                    case PathType.ONBOARDING:
                        // OnBoarding - A
                        type = MessageType.A;
                        break;
                    case PathType.RIS:
                        // RIS - B
                        type = MessageType.B;
                        break;
                    case PathType.PATRIMONY:
                        // Patrimony
                        if (!dashboardState.dashboard.firstRISPathDate) {
                            // C2
                            type = MessageType.C2;
                        } else if (!dashboardState.dashboard.monthlyIncomeGoal) {
                            // C1
                            type = MessageType.C1;
                        } else {
                            // C3
                            type = MessageType.C3;
                        }
                        break;
                    case PathType.INCOMEGOAL:
                        // Income goal - D
                        if (
                            dashboardState.dashboard.firstRISPathDate &&
                            dashboardState.dashboard.firstPatrimonyPathDate &&
                            !dashboardState.dashboard.firstSavingPathDate
                        ) {
                            type = MessageType.D;
                        }
                        break;
                    case PathType.SAVING:
                        // Saving - E
                        if (
                            dashboardState.dashboard.firstRISPathDate &&
                            dashboardState.dashboard.firstPatrimonyPathDate &&
                            dashboardState.dashboard.monthlyIncomeGoal
                        ) {
                            type = MessageType.E;
                        }
                        break;
                    default:
                }
            }

            setLastPathDone(type ? newLastPathDone : PathType.DEFAULT); // Peut-être renvoyer les deux variables

            if (!type) {
                type = MessageType.Z;
            }

            setMessageType(type);
        }
    }, [dashboardState]);

    return { lastPathDone, messageType };
};

export { useDashboardLastPathDoneAndMessage };
