import React, {Children, PropsWithChildren, ReactNode} from "react";
import {SGGridCol, SGGridRow} from "sg-grid";

interface FormInputsProps {
   colLength: 6 | 8 | 10; // indique la répartition de l'input et de son libellé (column = 12, column-short = 8, halh-half = 6/6)
   textalign?: "left" | "right" | "center"
}

const FormInputs = ({colLength, textalign, children}: PropsWithChildren<FormInputsProps>) => {
   const arrayChildren = Children.toArray(children);

   return (
      <SGGridRow justify="center">
         {arrayChildren && arrayChildren.map(
            (child: ReactNode, index) => (
               <SGGridCol key={Math.trunc(index)} xs={10} lg={colLength} textalign={textalign || "left"}>
                  {child}
               </SGGridCol>
            )
         )}
      </SGGridRow>
   );
};

export {FormInputs};
