import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { SGSpace } from "sg-space";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CarteCliquable } from "website/components/molecules/CarteCliquable/CarteCliquable";
import { Tab } from "website/components/molecules/DecouverteServicesAccompagnement/DecouverteServicesAccompagnement";
import { MES_SERVICES, MON_PLAN } from "website/utils/privateRoutes";
import {
  SectionTemplate
} from "../SectionTemplate/SectionTemplate";

const SectionPreparerMaRetraite: FunctionComponent = () => {
  const history = useHistory();
  const { trackClick } = useTracking();

  const onClicKPlanAction = () => {
    trackClick("clic-sur-card-mon-plan-d-action");
    history.push(MON_PLAN);
  };

  const onClicKServices = () => {
    trackClick("clic-sur-card-mes-services");
    history.push(`${MES_SERVICES}?tab=${Tab.services}`);
  };

  return (
    <SectionTemplate sectionType={SectionDashBoardType.PREPARER_MA_RETRAITE}>
      <SGSpace
        key="space-cards"
        align="stretch"
        xs={{ direction: "vertical", size: "md" }}
        sm={{ direction: "horizontal", size: "md" }}
      >
        <CarteCliquable
          key="planAction"
          src="images/dashboard/planAction.png"
          alt="Mon plan d'action"
          idTexte="sectionPreparerMaRetraite.carte.planAction.texte"
          idTitre="sectionPreparerMaRetraite.carte.planAction.titre"
          onClick={onClicKPlanAction}
        />
        <CarteCliquable
          key="services"
          src="images/dashboard/services.png"
          alt="Mes services"
          idTexte="sectionPreparerMaRetraite.carte.services.texte"
          idTitre="sectionPreparerMaRetraite.carte.services.titre"
          onClick={onClicKServices}
        />
      </SGSpace>
    </SectionTemplate>
  );
};

export { SectionPreparerMaRetraite };
