import { FETCH_PERSONAL_INFORMATIONS, PersonalInformations, SAVE_PERSONAL_INFORMATIONS, UPDATE_PERSONAL_INFORMATIONS_STORE } from "./types";

export const fetchPersonalInformations = () => ({
   type: FETCH_PERSONAL_INFORMATIONS,
});
export const savePersonalInformations = (personalInformations: Partial<PersonalInformations>) => ({
   type: SAVE_PERSONAL_INFORMATIONS,
   payload: personalInformations,
});

export const updatePersonalInformationsStore = (personalInformations: PersonalInformations) => ({
   type: UPDATE_PERSONAL_INFORMATIONS_STORE,
   payload: personalInformations,
});
