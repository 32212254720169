import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { FEATURES_FETCHED, Feature, FeaturesState } from "./types";

const initialState: FeaturesState = {
   hasFetched: false,
   features: [],
};

export const FeaturesReducer: Reducer<FeaturesState, PayloadAction<Feature[]>> = (
   currentState: FeaturesState = initialState,
   action
): FeaturesState => {
   switch (action.type) {
      case FEATURES_FETCHED:
         return {
            ...currentState,
            features: action.payload,
            hasFetched: true,
         };
      default:
         return currentState;
   }
};
