import React, {FunctionComponent} from "react";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGButtonIntl} from "../SGButtonIntl/SGButtonIntl";
import {replaceSpecialCharsAndLowercase} from "../../../utils/formatting/replaceSpecialCharsAndLowercase";

interface SelectInputButtonsProps {
   labels: string[];
   choices: string[];
   choice: string | undefined
   setChoice: (choice: string) => void;
   size: "sm" | "md"
   cypressName: string;
   direction: "horizontal" | "vertical";
   trackingName?: string
}

const SelectInputButtons: FunctionComponent<SelectInputButtonsProps> = ({
                                                                           labels, choices, choice, setChoice, size, cypressName, direction, trackingName
                                                                        }: SelectInputButtonsProps) => {
   const getColspan = () => {
      if (direction === "horizontal") {
         return choices.length % 2 === 0 ? 6 : 4;
      }

      return 12;
   }

   return (
      <SGGridRow>
         {labels.map((label: string, index: number) => (
            <SGGridCol span={getColspan()} textalign="center" key={label} >
               <SGButtonIntl type={choice === choices[index] ? "primary" : "secondary"} intlId={label}
                             cypressName={`${cypressName}-button-${label.split(".").pop()}`}
                             onClick={() => setChoice(choices[index])} trackingName={`${trackingName}-${replaceSpecialCharsAndLowercase(choices[index])}`}
                             size={size} disableautomargin={direction === "horizontal"}/>
            </SGGridCol>
         ))}
      </SGGridRow>
   );
};

export {SelectInputButtons};
