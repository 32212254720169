import { ReactComponent as LogoCross } from "assets/images/redcross.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGText, SGTitle } from "sg-typo";
import "./InfoModalFiabiliteRIS.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGBox } from "sg-space";

interface InfoModalFiabiliteRISProps {
    visible: boolean;
    setVisible: (showInfoModalFiabiliteRIS: boolean) => void;
}

const InfoModalFiabiliteRIS: FunctionComponent<InfoModalFiabiliteRISProps> = (props: InfoModalFiabiliteRISProps) => {
    const intl = useIntl();
    const { visible, setVisible } = props;
    const { resetTracking } = useTracking();

    const handleOnClose = () => {
        setVisible(false);
        resetTracking();
    };

    const contenus = [
        {
            nom: "subtitle.anomalies",
            type: "subtitle",
        },
        {
            nom: "exemples",
        },
        {
            nom: "subtitle.consequences",
            type: "subtitle",
        },
        {
            nom: "consequences.text",
        },
    ];

    return (
        <SGModal className="info-modal-fiabilite-ris__container" visible={visible} centered closable={false}>
            <SGGridRow className="info-modal-fiabilite-ris__row-scroll">
                <SGGridCol span={12} textalign="left">
                    <SGTitle caps level={3}>
                        {intl.formatMessage({ id: "infoFiabiliteRIS.modal.title" })}
                    </SGTitle>
                </SGGridCol>
                <SGMediaQuery minwidth="sm">
                    <SGGridCol>
                        <LogoCross
                            onClick={handleOnClose}
                            className="info-modal-fiabilite-ris__croix"
                            id="parasol"
                            icon-name="parasol"
                            width="100%"
                            height="100%"
                        />
                    </SGGridCol>
                </SGMediaQuery>
                <SGGridCol span={12}>
                    <SGGridRow className="info-modal-fiabilite-ris__contenus-container">
                        {contenus.map((contenu) =>
                            contenu.type === "subtitle" ? (
                                <SGGridCol key={contenu.nom} span={12} textalign="left">
                                    <SGBox margin={{ top: "sm", bottom: "xs" }} forcemargin>
                                        <SGTitle level={4} visuallevel={5}>
                                            {intl.formatMessage({ id: `infoFiabiliteRIS.modal.${contenu.nom}` })}
                                        </SGTitle>
                                    </SGBox>
                                </SGGridCol>
                            ) : (
                                <SGGridCol key={contenu.nom} span={12} textalign="left">
                                    <SGText>
                                        {intl.formatMessage(
                                            { id: `infoFiabiliteRIS.modal.${contenu.nom}` },
                                            {
                                                ul: (word: string) => <ul>{word}</ul>,
                                                li: (word: string) => <li>{word}</li>,
                                                p: (word: string) => <p>{word}</p>,
                                                i: (word: string) => <em>{word}</em>,
                                                sup: (word: string) => <sup>{word}</sup>,
                                            }
                                        )}
                                    </SGText>
                                </SGGridCol>
                            )
                        )}
                    </SGGridRow>
                </SGGridCol>
            </SGGridRow>
            <SGMediaQuery minwidth={0} maxwidth="xs">
                <SGGridRow justify="center">
                    <SGGridCol>
                        <SGButton size="sm" onClick={handleOnClose}>
                            {intl.formatMessage({ id: "modal.error.button" })}
                        </SGButton>
                    </SGGridCol>
                </SGGridRow>
            </SGMediaQuery>
        </SGModal>
    );
};

export { InfoModalFiabiliteRIS };
