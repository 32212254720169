import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { AgenceTheme } from "sg-themes";
import { SGText } from "sg-typo";
import { State } from "store/store";
import { SupervisionState } from "store/supervision/types";
import { SGContent, SGLayout } from "sg-layout";

const { ConfigProvider } = require("sg_npm_components-plus-core");

export const BanniereModeSupervision: FunctionComponent = () => {
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  return (
    <>
      {supervisionState.estActive && (
        <SGLayout background="Sec_Sg3">
          <SGContent disableautomargin>
            <ConfigProvider theme={AgenceTheme}>
              <SGBox
                alignchildren={["center"]}
              >
                <>
                  <SGIcon component={<SGAvenirStatusInfo />} size="l" />
                  <SGBox margin={{ left: "sm", right: "sm" }} inline>
                    <SGText size="md">
                      Vous êtes en mode visualisation, vous consultez les données du client sans pouvoir les modifier.
                    </SGText>
                  </SGBox>
                </>
              </SGBox>
            </ConfigProvider>
          </SGContent>
        </SGLayout>
      )}
    </>
  );
};
