import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "store/login/actions";
import { Loader } from "website/components/atoms/Loader/Loader";

/**
 * Composant pour ajouter l'action de logout dans le routage pour la pop-in de sortie RiS
 */
const LogoutPage: FunctionComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    }, []);

    return <Loader title="loader.title" />;
};

export { LogoutPage };
