import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGAvenirStrokedDocumentValide, SGAvenirStrokedPensionsRentes, SGAvenirStrokedTelechargement } from "sg-icon-pack-base";
import { SGText, SGTitle } from "sg-typo";
import { DashboardAge, DashboardState } from "store/dashboard/types";
import { State } from "store/store";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { PARCOURS_RIS, SIMULATEUR_FLASH } from "website/utils/privateRoutes";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { PARAM_RESULTAT, QUERY_PARAM_ETAPE } from "website/pages/ParcoursRISPage";
import { CarteDonnee, NomCarteEnum, TypeContenuCarteEnum } from "./CarteDonnee";
import { CadreInfoSup } from "../CadreInfoSup";
import { SGDrawer } from "sg-drawer";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { RisTile } from "../../RisTile/RisTile";
import { MaxRisErrorModal } from "website/components/molecules/MaxRisErrorModal/MaxRisErrorModal";

interface PensionProps {
  selectedRetirementAge?: DashboardAge;
}

const ID_TRACKING_DRAWER = "drawer-definir-ma-pension";

const Pension: FunctionComponent<PensionProps> = (props: PensionProps) => {
  const history = useHistory();
  const {
    selectedRetirementAge,
  } = props;
  const [montantPension, setMontantPension] = useState<number>();
  const [fiabilite, setFiabilite] = useState<number>();
  const [isParcoursRISDone, setIsParcoursRISDone] = useState(false);
  const [dateParcoursRis, setDateParcoursRis] = useState<string>();
  const [showDrawer, setShowDrawer] = useState(false);

  const { disponibiliteSimulateurFlash, disponibiliteParcoursRIS } = useFeatures();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const intl = useIntl();
  const { trackDrawer, trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const [accessibiliteRisClicked, setAccessibiliteRisClicked] = useState<boolean>(false);

  useEffect(() => {
    if (showDrawer) {
      trackDrawer(ID_TRACKING_DRAWER);
    }
  }, [showDrawer]);

  useEffect(() => {
    setFiabilite(dashboardState?.dashboard?.reliability);
    setIsParcoursRISDone(dashboardState?.dashboard?.risDate !== undefined);
    if (dashboardState?.dashboard?.lastProjectCreationDate !== undefined) {
      const { lastProjectCreationDate } = dashboardState.dashboard;
      setDateParcoursRis(
        new Date(lastProjectCreationDate).toLocaleDateString()
      );
    }
  }, [dashboardState]);

  useEffect(() => {
    if (
      selectedRetirementAge &&
      selectedRetirementAge.retirementMonthlyIncome
    ) {
      setMontantPension(
        roundDown10(selectedRetirementAge.retirementMonthlyIncome)
      );
    }
  }, [selectedRetirementAge]);

  const handleOnClickCardLink = () => {
    if (isParcoursRISDone) {
      // Action consulter
      trackClick("clic-sur-consulter-pension");
      history.push(`${PARCOURS_RIS}?${QUERY_PARAM_ETAPE}=${PARAM_RESULTAT}`);
    } else {
      // Action analyser
      trackClick("clic-sur-analyser-pension");
      setShowDrawer(true);
    }
  };

  const redirectToParcoursRIS = () => {
    if (disponibiliteParcoursRIS) {
      history.push(PARCOURS_RIS);
    }
  };

  return (
   <>
    <CarteDonnee
      nom={NomCarteEnum.PENSION}
      tagColor="26"
      isActif={isParcoursRISDone}
      typeContenu={TypeContenuCarteEnum.MONTANT}
      montant={montantPension}
      disponibiliteFeatureFlipping={disponibiliteSimulateurFlash || disponibiliteParcoursRIS}// feature flipping sapiendo
      onClick={handleOnClickCardLink}
    >
      <CadreInfoSup
        key="cadre-info-sup"
        icone={isParcoursRISDone ? <SGAvenirStrokedDocumentValide/> : <SGAvenirStrokedTelechargement/>}
      >
        <SGText key="titre-pension-inactive" weight="600">
          {(intl.formatMessage(
            {id: `dashboard.cadreGris.carteDonnee.pension.titre.risDone${isParcoursRISDone}`},
            {percentage: fiabilite}
          ))}
        </SGText>
        <SGText key="text-pension-inactive" weight="300" size="s">
          {(intl.formatMessage(
            {id: `dashboard.cadreGris.carteDonnee.pension.text.risDone${isParcoursRISDone}`},
            {dateAnalyse: dateParcoursRis}
          ))}
        </SGText>
      </CadreInfoSup>
    </CarteDonnee>

    <SGDrawer
      visible={showDrawer}
      onClose={() => {
        setShowDrawer(false);
        trackClick(`${ID_TRACKING_DRAWER}::clic-sur-fermer`);
      }}
      placement={isPhone ? "bottom" : "right"}
      title={
        <SGTitle level={2} visuallevel={3}>
          {intl.formatMessage(
            { id: "dashboard.carteDonnee.pension.popup.titre" })}
        </SGTitle>
      }
    >
      <SGText size="l" weight="400">
        {intl.formatMessage({ id: "dashboard.carteDonnee.pension.popup.text" })}
      </SGText>
      <SGSpace direction="vertical" size="md">
        <RisTile
          key="tuile-ris"
          accessibility={disponibiliteParcoursRIS}
          tileName="parcoursRIS"
          tileIcon={<SGAvenirStrokedTelechargement/>}
          onClick={() => {
            if (disponibiliteParcoursRIS) {
              setAccessibiliteRisClicked(true);
              trackClick(`${ID_TRACKING_DRAWER}::clic-sur-analyser-mon-ris`);
            }
          }}
        />
        <RisTile
          key="tuile-flash"
          accessibility={disponibiliteSimulateurFlash}
          tileName="simulateurFlash"
          tileIcon={<SGAvenirStrokedPensionsRentes/>}
          onClick={() => {
            if (disponibiliteSimulateurFlash) {
              trackClick(`${ID_TRACKING_DRAWER}::clic-sur-modifier-mon-estimation-de-pension`);
            }
          }}
          lien={disponibiliteSimulateurFlash ? `#${SIMULATEUR_FLASH}` : undefined}
        />
        <MaxRisErrorModal
          key="modal"
          accessibiliteRisClicked={accessibiliteRisClicked}
          setAccessibiliteRisClicked={setAccessibiliteRisClicked}
          redirectToParcoursRIS={redirectToParcoursRIS}
        />
      </SGSpace>
    </SGDrawer>
  </>
  );
};

export { Pension };
