export interface PersonnalisationQuestionsState {
  questions: Question[];
  hasFetched: boolean;
}

export interface Question {
  id: number;
  label: string;
  checked: boolean;
}

export interface ParametresPersonnalisationQuestions {
  choice: ChoixType;
  age: number;
}

export enum ChoixType {
  SEREIN = "SEREIN",
  INQUIET = "INQUIET",
  CURIEUX = "CURIEUX",
}

export const FETCH_PERSONNALISATION_QUESTIONS =
  "FETCH_PERSONNALISATION_QUESTIONS";
