import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { TrackingEvent, TrackingEventState, TRACKING_EVENT_SAVED } from "./types";

export const TrackingEventReducer: Reducer<TrackingEventState, PayloadAction<TrackingEvent>> = (
   currentState: TrackingEventState = {},
   action
): TrackingEventState => {
   switch (action.type) {
      case TRACKING_EVENT_SAVED:
         return { trackingEvent: action.payload };
      default:
         return currentState;
   }
};
