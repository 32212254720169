import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { SGBox } from "sg-space";
import { TypeMedia } from "website/components/hooks/articles";
import { PauseVideoContext } from "website/components/providers/PauseVideoProvider";
import { PlayerRef, VideoPlayer } from "../../VideoPlayer/VideoPlayer";

interface ArticleVideoProps {
  url: string;
  cover: string;
  type: TypeMedia;
}

/**
 * Le contenu de type "video"
 * @returns Renvoie une video
 */
export const ArticleVideo: FunctionComponent<ArticleVideoProps> = (
  props: ArticleVideoProps
) => {
  const { url, cover, type } = props;

  const [player, setPlayer] = useState<PlayerRef>();
  const { pauseVideo, setPauseVideoCarrousel, setPauseVideo } =
    useContext(PauseVideoContext);

  useEffect(() => {
    // Pour mettre en pause la video si on lance une vidéo dans le carrousel
    if (pauseVideo === true) {
      player?.pause();
      setPauseVideo(false);
    }
  }, [pauseVideo]);

  return (
    <SGBox margin={{ bottom: "md" }}>
      <SGBox className="video-page__container">
        <VideoPlayer
          cover={cover}
          urlVideo={url}
          getPlayer={setPlayer}
          onClickPlayer={() => setPauseVideoCarrousel(true)}
          type={type}
        />
      </SGBox>
    </SGBox>
  );
};
