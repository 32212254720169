import { Reducer } from "react";
import { RIS_STEPS } from "website/pages/ParcoursRISPage";
import {
  CONTINUE_PARCOURS,
  GO_TO_STEP_4_PARCOURS_RIS,
  NEXT_STEP,
  PREVIOUS_STEP,
  ParcoursRISState,
  RESET_PARCOURS,
  STOP_PARCOURS,
} from "./types";

const initialState: ParcoursRISState = {
  currentStep: 0,
  stopParcours: false,
};

export const ParcoursRISReducer: Reducer<ParcoursRISState, any> = (
  currentState = initialState,
  action
): ParcoursRISState => {
  switch (action.type) {
    case RESET_PARCOURS:
      return {
        ...currentState,
        currentStep: RIS_STEPS.PREPARATION,
        stopParcours: false,
      };
    case NEXT_STEP:
      return {
        ...currentState,
        currentStep: currentState.currentStep + 1,
        stopParcours: false,
      };
    case PREVIOUS_STEP:
      return {
        ...currentState,
        currentStep: currentState.currentStep - 1,
        stopParcours: false,
      };
    case STOP_PARCOURS:
      return {
        ...currentState,
        stopParcours: true,
      };
    case CONTINUE_PARCOURS:
      return {
        ...currentState,
        stopParcours: false,
      };
    case GO_TO_STEP_4_PARCOURS_RIS:
      return {
        ...currentState,
        currentStep: RIS_STEPS.RESULTATS,
        stopParcours: false,
      };
    default:
      return currentState;
  }
};
