import { ReactComponent as Phone } from "assets/images/phone-arrow-up-right-regular.svg";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { calculateAgeByBirthdate } from "store/members/utils";
import { useFamily } from "website/components/hooks/useFamily";
import { ServiceSapiendoCard } from "website/components/molecules/ServiceSapiendoCard/ServiceSapiendoCard";
import { externalTel, transformTel } from "website/utils/externalTel";
import { ReactComponent as Sapiendo } from "../../../../../assets/images/sapiendo.svg";
import "./ExpertRetraite.scss";

export enum ServiceFormule {
    ESSENTIELLE = "essentielle",
    PREMIUM = "premium",
}

export enum ServiceType {
    ENTRETIEN = "entretien",
    BILAN = "bilan",
    AIDE = "aide",
}

export interface ServiceCardInfo {
    type: string;
    fileName: string;
    items: string[];
    formule?: ServiceFormule;
    trackKey?: string;
}

export interface ExpertRetraiteProps {
    services: ServiceCardInfo[];
    setServiceId: (serviceId: string) => void;
    setShowModalInfo: (show: boolean) => void;
}

const ExpertRetraite: FunctionComponent<ExpertRetraiteProps> = (props: React.PropsWithChildren<ExpertRetraiteProps>) => {
    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });
    const isTablet = useMediaQuery({ minwidth: 'sm', maxwidth: 'lg' });

    const { services, setServiceId, setShowModalInfo } = props;

    const family = useFamily();

    const [servicesCards, setServicesCards] = useState<ServiceCardInfo[]>();

    useEffect(() => {
        if (family?.me) {
            const age = calculateAgeByBirthdate(family.me.birthday);
            if (age < 40) {
                setServicesCards([services[0]]);
            } else if (age <= 55) {
                setServicesCards(services.slice(0, 3));
            } else if (age > 55) {
                setServicesCards([services[1], services[3], services[4]]);
            }
        }
    }, [family]);

    return (
        <SGGridRow gutter={[0, 63]}>
            <SGGridCol>
                <SGCard>
                    <SGBox margin={{ top: "md", right: "md", bottom: "md", left: "md" }}>
                        <SGSpace direction="vertical">
                            <SGGridRow key="row-1">
                                <SGMediaQuery minwidth={0} maxwidth="lg">
                                    <SGGridCol offset={6} xs={6} >
                                        <Sapiendo className="expert-retraite__card-sapiendo-logo-wrapper"/>
                                    </SGGridCol>
                                </SGMediaQuery>
                                <SGGridCol>
                                    <SGTitle level={2}>{intl.formatMessage({ id: "mesServices.tabs.sapiendo" }, { space: "\u00a0" })}</SGTitle>
                                </SGGridCol>
                            </SGGridRow>
                            <SGGridRow key="row-2" gutter={[9]}>
                                <SGGridCol span={12} xl={9}>
                                    <SGText>
                                        {intl.formatMessage(
                                            { id: "mesServices.sapiendo.description" },
                                            { p: (word: string) => <p>{word}</p>, b: (word: string) => <strong>{word}</strong> }
                                        )}
                                    </SGText>
                                </SGGridCol>
                                <SGMediaQuery minwidth="xl">
                                    <SGGridCol>
                                        <Sapiendo />
                                    </SGGridCol>
                                </SGMediaQuery>
                            </SGGridRow>
                        </SGSpace>
                    </SGBox>
                    <SGBox margin={{ top: "md", right: "md", bottom: "md", left: "md" }}>
                        <SGBlock className="expert-retraite__call-paragraph">
                            <SGGridRow align="middle" gutter={[24]}>
                                <SGGridCol span={2} sm={3}>
                                    <SGDivider disableautomargin />
                                </SGGridCol>
                                <SGGridCol span={8} sm={6}>
                                    <SGGridRow gutter={[14]} justify="center">
                                        <SGGridCol>
                                            <SGIcon component={<Phone />} />
                                        </SGGridCol>
                                        <SGGridCol>
                                            <SGText size="xs" style={{ color: "#010035" }} weight="600">
                                                {
                                                    intl.formatMessage(
                                                        { id: `mesServices.sapiendo.appel.title${isPhone || isTablet ? ".mobile" : ""}` },
                                                        { number: (word: string) => <a href={`tel:${externalTel.EXTERNAL_TEL_SAPIENDO}`} className="expert-retraite__call-link"><strong style={{ color: "#E2010B", fontSize: "20px" }}>{transformTel(externalTel[word])}</strong></a>, linebreak: <br /> }
                                                    )
                                                }
                                            </SGText>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGGridCol>
                                <SGGridCol span={2} sm={3}>
                                    <SGDivider disableautomargin />
                                </SGGridCol>
                            </SGGridRow>
                            <SGGridRow>
                                <SGGridCol>
                                    <SGText size="xs">
                                        {intl.formatMessage(
                                            { id: "mesServices.sapiendo.appel.info" },
                                            { linebreak: <br />, b: (word: string) => <strong>{word}</strong> }
                                        )}
                                    </SGText>
                                </SGGridCol>
                            </SGGridRow>
                        </SGBlock>
                    </SGBox>
                </SGCard>
            </SGGridCol>
            <SGGridCol span={12} textalign={isPhone ? "left" : "center"}>
                <SGTitle level={2}>{intl.formatMessage({ id: "mesServices.sapiendo.services.title" })}</SGTitle>
            </SGGridCol>
            <SGGridCol span={12}>
                <SGGridRow justify="center" align="stretch">
                    {servicesCards?.map((service) => (
                        <SGGridCol span={12} md={4} textalign="center" key={service.type}>
                            <ServiceSapiendoCard service={service} setServiceId={setServiceId} setShowModalInfo={setShowModalInfo} />
                        </SGGridCol>
                    ))}
                </SGGridRow>
            </SGGridCol>
        </SGGridRow >
    );
};
export { ExpertRetraite };
