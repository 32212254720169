export interface SimulationState {
   simulation: Simulation;
   hasFetched: boolean;
}

export type SimulationAlertByYear = { a: string, d: AlertType[] };
export type SimulationAlerts = { [key in Scenario.OPTIMISTIC | Scenario.CONSERVATIVE]: { [key: number]: SimulationAlertByYear } };
export type SimulationGraph = { [key in Scenario.OPTIMISTIC | Scenario.CONSERVATIVE]: { [key: string]: { a: number, d: number } } };
export type SimulationIncomeGraph = { [key: string]: { a: number, d: number } };

export interface Simulation {
   birthday: number;
   from: number;
   to: number;
   incomeExpenses: SimulationIncomeGraph;
   alerts: SimulationAlerts;
   graphs: SimulationGraph;
   netGraphs: SimulationGraph;
}

export enum Scenario {
   CONSERVATIVE = "CONSERVATIVE",
   OPTIMISTIC = "OPTIMISTIC",
}

export interface HighChartsAlert {
   year: number;
   alert: string;
   type: string;
}

export interface HighChartsPoint {
   x: number;
   y: number;
   type: string;
}

export enum AlertType {
   ALERT_NET_WORTH_WITHOUT_REAL_ESTATE_PRIMARY_AND_LOANS_IS_NEGATIVE = "ALERT_NET_WORTH_WITHOUT_REAL_ESTATE_PRIMARY_AND_LOANS_IS_NEGATIVE",
   ALERT_EXPENSES_GREATER_THAN_INCOMES = "ALERT_EXPENSES_GREATER_THAN_INCOMES",
   ALERT_TAX_BENEFIT_IS_OVER = "ALERT_TAX_BENEFIT_IS_OVER",
   ALERT_NOT_FEASIBLE_PROJECTS = "ALERT_NOT_FEASIBLE_PROJECTS",
   ALERT_INVESTMENT_IMPOSSIBLE = "ALERT_INVESTMENT_IMPOSSIBLE",
   WARNING_DEPARTURE_OF_A_CHILD = "WARNING_DEPARTURE_OF_A_CHILD",
   ALERT_NO_REMAINING_MONEY_FOR_PLANNED_BUYBACK = "ALERT_NO_REMAINING_MONEY_FOR_PLANNED_BUYBACK",
   ALERT_NO_REMAINING_MONEY_FOR_ADDITIONAL_INCOME = "ALERT_NO_REMAINING_MONEY_FOR_ADDITIONAL_INCOME",
   ALERT_DEFICIT_CREATED = "ALERT_DEFICIT_CREATED",
}

export const SIMULATION_ALERTS = [
   AlertType.ALERT_NET_WORTH_WITHOUT_REAL_ESTATE_PRIMARY_AND_LOANS_IS_NEGATIVE.toString(),
   AlertType.ALERT_NOT_FEASIBLE_PROJECTS.toString(),
   AlertType.ALERT_INVESTMENT_IMPOSSIBLE.toString(),
   AlertType.ALERT_NO_REMAINING_MONEY_FOR_PLANNED_BUYBACK.toString(),
   AlertType.ALERT_NO_REMAINING_MONEY_FOR_ADDITIONAL_INCOME.toString(),
   AlertType.ALERT_DEFICIT_CREATED.toString()
];
export const INCOMES_EXPENSES_ALERTS = [
   AlertType.ALERT_TAX_BENEFIT_IS_OVER.toString(),
   AlertType.ALERT_EXPENSES_GREATER_THAN_INCOMES.toString()
];

// actions
export const FETCH_GLOBAL_SIMULATION = "FETCH_GLOBAL_SIMULATION";
