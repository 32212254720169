import natif from "awt-natif";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { isWebView } from "store/import/utils";
import { MODIFIER_PROFIL } from "website/utils/privateRoutes";
import { useTracking } from "./tracking/useTracking";
import { useAccount } from "./useAccount";

/**
 * Active le menu contextuel dans l'application mobile
 */
const useMenuContextuel = () => {
  const history = useHistory();
  const intl = useIntl();
  const { trackClick } = useTracking();
  const { tunnelCompleted } = useAccount();

  /**
   * Actions du menu contextuel
   */
  const callbackMenuContextuel = useCallback(
    (num: string) => {
      if (num === "1") {
          trackClick("clic-sur-menu-contextuel-modifier-mon-profil");
          history.push(MODIFIER_PROFIL);
      } else {
          console.error(`Rubrique inconnue ${num}: ${typeof num}`);
      }
    },
    [history, trackClick]
  );

  useEffect(() => {
    if (isWebView() && tunnelCompleted) {
      natif.menuContextuel(
        "Menu",
        [intl.formatMessage({ id: "menuContextuel.modifierProfil" })],
        callbackMenuContextuel
      );
    }
  }, [callbackMenuContextuel, intl, tunnelCompleted]);
};

export { useMenuContextuel };
