import { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFleche,
  SGAvenirStyledMesRendezVous,
} from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "../ErrorModal/ErrorModal";

interface CarteSGAgendaProps {
  alignchildren?: "center";
  titreTaille?: number;
}

const CarteSGAgenda: FunctionComponent<CarteSGAgendaProps> = (
  props: CarteSGAgendaProps
) => {
  const { alignchildren, titreTaille } = props;
  const openSGAgenda = useSGAgenda();
  const { trackClick } = useTracking();
  const { disponibiliteSGAgenda } = useFeatures();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const titleLevel = 3;

  const handleOnClickRdv = () => {
    trackClick("clic-sur-contacter-un-conseiller-sg");
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  return (
    <>
      <SGTile clickable="full" disableautomargin>
        <SGBox alignchildren={alignchildren ? [alignchildren] : undefined}>
          <SGGridRow align="middle">
            <SGGridCol flex="0 0 0%">
              <SGIcon component={<SGAvenirStyledMesRendezVous />} size="s" />
            </SGGridCol>
            <SGGridCol flex="1 0 0%">
              <SGTitle level={titleLevel} visuallevel={titreTaille ?? titleLevel + 2}>
                Contacter un conseiller <SGText color="error">SG</SGText>
              </SGTitle>
              <SGBox>
                <SGText size="s">
                  <FormattedMessage id="carteSgAgenda.text" />
                </SGText>
              </SGBox>
            </SGGridCol>
            <SGGridCol flex="0 0 0%">
              <BoutonSGAgenda
                icon={<SGIcon component={<SGAvenirNavFleche />} />}
                type="icon"
                onClick={handleOnClickRdv}
                ariaLabel="Prendre rendez-vous avec votre conseiller SG"
              />
            </SGGridCol>
          </SGGridRow>
        </SGBox>
      </SGTile>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </>
  );
};

export { CarteSGAgenda };
