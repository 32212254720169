import React, { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGBox } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { ArticleContenu } from "../ArticleContenu/ArticleContenu";
import { Contenu } from "../ArticleModel";
import { TypeMedia } from "website/components/hooks/articles";

interface ArticleCadreGrisProps {
    contenu: Contenu;
    articlePath: any;
    type: TypeMedia;
}
/**
 *
 * @param props contenu de type "cadreGris"
 * @returns Renvoie un encadré gris contenant un texte "à savoir"
 */
export const ArticleCadreGris: FunctionComponent<ArticleCadreGrisProps> = (props: ArticleCadreGrisProps) => {
    const intl = useIntl();
    const { contenu, articlePath, type } = props;

    const getTitle = () => {
        const valuesFormattedMessage = {
            sup: (word: string) => <sup>{word}</sup>,
        }

        if (contenu.h3) {
            return <SGTitle visuallevel={5} level={3}>
                <FormattedMessage
                    id="contenu.h3"
                    defaultMessage={contenu.h3}
                    values={valuesFormattedMessage}
                />
            </SGTitle>
        }
        if (contenu.h4) {
            return <SGTitle visuallevel={5} level={4}>
                <FormattedMessage
                    id="contenu.h4"
                    defaultMessage={contenu.h4}
                    values={valuesFormattedMessage}
                />
            </SGTitle>
        }
        if (contenu.h5) {
            return <SGTitle level={5}>
                <FormattedMessage
                    id="contenu.h5"
                    defaultMessage={contenu.h5}
                    values={valuesFormattedMessage}
                />
            </SGTitle>
        }

        return <SGTitle visuallevel={3} level={3} caps>
            {intl.formatMessage({ id: "home.article.savoir" })}
        </SGTitle>
    }

    return (
        <SGBox margin={{ bottom: "md", top: "md" }} forcemargin>
            <SGBlock type="texthighlight">
                <SGGridRow>
                    <SGGridCol span={12} textalign="left">
                        <SGBox margin={{ bottom: "xs" }}>
                            {getTitle()}
                        </SGBox>
                    </SGGridCol>
                    {contenu.children ? (
                        contenu.children.map((child) => <ArticleContenu key={child.type} contenu={child} articlePath={articlePath} type={type}/>)
                    ) : (
                        <SGGridCol span={12} textalign="left">
                            <SGText>
                                <FormattedMessage
                                    id="contenu.text"
                                    defaultMessage={contenu.text}
                                    values={{
                                        b: (word: string) => <SGText strong>{word}</SGText>,
                                        sup: (word: string) => <sup>{word}</sup>,
                                        ul: (word: string) => <ul className="article-contenu__liste">{word}</ul>,
                                        li: (word: string) => <li>{word}</li>,
                                    }}
                                />
                            </SGText>
                        </SGGridCol>
                    )}
                </SGGridRow>
            </SGBlock>
        </SGBox>

    );
};
