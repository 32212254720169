import { FunctionComponent } from "react";
import { DashboardAge } from "store/dashboard/types";
import { SectionDashBoardType } from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { CarteOffreSapiendo } from "website/components/molecules/CarteOffreSapiendo/CarteOffreSapiendo";
import { Pension } from "../CartesDonnees/Pension";
import { SectionTemplate } from "../SectionTemplate/SectionTemplate";

interface SectionPensionProps {
  selectedRetirementAge?: DashboardAge;
}

const SectionPension: FunctionComponent<SectionPensionProps> = (
  props: SectionPensionProps
) => {
  const { selectedRetirementAge } =
    props;

  return (
    <SectionTemplate sectionType={SectionDashBoardType.PENSION}>
      <Pension key="pension" selectedRetirementAge={selectedRetirementAge} />
      <CarteOffreSapiendo key="carteOffreSapiendo"/>
    </SectionTemplate>
  );
};

export { SectionPension };
