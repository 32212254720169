import { AxiosError, AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { FETCH_RETIREMENT_SIMULATION_ERROR } from "./types";

export function* onApiCallError(err: AxiosError) {
    const { response }: { response?: AxiosResponse } = err;
    if (response?.status !== undefined) {
        yield put({ type: FETCH_RETIREMENT_SIMULATION_ERROR, payload: { code: response.status, message:
            response.data?.message ?? response.data?.error } });
    } else {
        yield put({ type: FETCH_RETIREMENT_SIMULATION_ERROR, payload: { code: 500, message: JSON.stringify(err) } });
    }
}
