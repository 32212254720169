import React, {PropsWithChildren, useState} from "react";

/**
 * Ce provider a pour but de propager l'informations de pop ups ouvertes
 *
 * Ce provider doit être appelé comme suit dans un composant dans lequel on veut partager le state:
 * <ModalContextProvider>
 *    <Component1/>
 *    <Component2/>
 * </ModalContextProvider>
 *
 * A l'intérieur de Component1 et Component2 on peut alors récupérer le booléen et le setter en récupérant les valeurs du contexte et en les utilisant :
 * const {isDisplayingFeedbackModal, setDisplayingFeedbackModal} = useContext(ModalContext);
 */

export type ModalContextType = {
   isDisplayingFeedbackModal: boolean;
   setDisplayingFeedbackModal: (isDisplayingFeedbackModal: boolean) => void;
};

export const ModalContext = React.createContext<ModalContextType>({
   isDisplayingFeedbackModal: false, setDisplayingFeedbackModal: (() => null)
});

export const ModalContextProvider = ({children}: PropsWithChildren<Record<never, never>>) => {
   const [isDisplayingFeedbackModal, setDisplayingFeedbackModal] = useState(false);

   return (
      <ModalContext.Provider value={{isDisplayingFeedbackModal, setDisplayingFeedbackModal}}>
         {children}
      </ModalContext.Provider>
   );
}
