export const getQueryStringParams = (query: string): Record<string, string> => {
   const queryParams: Record<string, string> = {};
   if (query) {
      (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .forEach((param: string) => {
            const [key, value] = param.split('=');
            queryParams[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
         }, {}
      );
   }

   return queryParams;
};
