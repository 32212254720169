import React, {FunctionComponent, PropsWithChildren, useEffect, useState,} from "react";
import {useDispatch} from "react-redux";
import {SGTextIntl} from "../SGTextIntl/SGTextIntl";
import {SGSpinner} from "sg-spinner";
import {SGAlertIntl} from "../../molecules/SGAlertIntl/SGAlertIntl";
import {useIntl} from "react-intl";
import {SGBlock} from "sg-typo";
import {globalError} from "../../../../store/error/errorsSlice";

export enum SpinnerSize {
   SM = 'sm',
   MD = 'md',
   LG = 'lg'
}

interface SpinnerWrapperProps {
   displayComponent: boolean;
   spinnerSize: SpinnerSize;
   fatalErrorOnTimeout: boolean;
}

const SpinnerWrapper: FunctionComponent<PropsWithChildren<SpinnerWrapperProps>> = ({
                                                                                      displayComponent, spinnerSize, fatalErrorOnTimeout, children
                                                                                   }: PropsWithChildren<SpinnerWrapperProps>) => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const SPINNER_FIRST_TIMEOUT = 5_000;
   const SPINNER_SECOND_TIMEOUT = 10_000;

   const firstTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
   const secondTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
   const [displaySpinnerOverflow, setDisplaySpinnerOverflow] = useState<boolean>(false);
   const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(false);

   useEffect(() => {
      firstTimeoutRef.current = setTimeout(() => {
         setDisplaySpinnerOverflow(true);
      }, SPINNER_FIRST_TIMEOUT);

      secondTimeoutRef.current = setTimeout(() => {
         if (fatalErrorOnTimeout) {
            dispatch(globalError("Spinner timeout"));
            console.warn(
               `One component took more than ${SPINNER_SECOND_TIMEOUT}ms to load`
            );
         } else {
            setDisplayErrorMessage(true);
         }
      }, SPINNER_SECOND_TIMEOUT);

      return () => {
         if (firstTimeoutRef.current !== undefined) {
            clearTimeout(firstTimeoutRef.current);
         }
         if (secondTimeoutRef.current !== undefined) {
            clearTimeout(secondTimeoutRef.current);
         }
      };
   }, []);

   useEffect(() => {
      if (displayComponent) {
         if (firstTimeoutRef.current !== undefined) {
            clearTimeout(firstTimeoutRef.current);
         }
         if (secondTimeoutRef.current !== undefined) {
            clearTimeout(secondTimeoutRef.current);
         }
      }
   }, [displayComponent]);

   return (
      <SGBlock textalign="center" aria-busy={!displayComponent}>
         {displayErrorMessage && (
            <SGAlertIntl cypressName="spinner-overflow-error-frame" type="warning" title="common.warning">
               <SGTextIntl intlId="common.component.spinner.overflow.error"/>
            </SGAlertIntl>
         )}
         {!displayErrorMessage && !displayComponent && (
            <SGSpinner role="progressbar" aria-label={`progressbar-${spinnerSize}`} size={spinnerSize} tip={displaySpinnerOverflow ? intl.formatMessage({id: "common.component.spinner.overflow"}) : 0}/>
         )}
         {!displayErrorMessage && displayComponent && children}
      </SGBlock>
   );
};

export {SpinnerWrapper};
