import { PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { AssetIncomes, AssetIncomesState, ASSET_INCOMES_FETCHED, RESET_ASSETS_INCOMES } from "./types";

// TODO: A corriger en ajoutant hasFetched
const initialState: AssetIncomesState = {
} as AssetIncomesState;

export const AssetIncomesReducer: Reducer<AssetIncomesState, PayloadAction<AssetIncomes>> = (
  currentState = initialState,
  action
): AssetIncomesState => {
  switch (action.type) {
    case ASSET_INCOMES_FETCHED: {
      return { assetIncomes: action.payload };
    }
    case RESET_ASSETS_INCOMES: {
      return initialState;
    }
    default: {
      return currentState;
    }
  }
};
