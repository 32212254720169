import { FETCH_PARCOURS_RIS_PROJECTION, ParcoursRisProjection, ParcoursRisResultsRequest, RESET_PARCOURS_RIS_PROJECTION_ERROR, UPDATE_PARCOURS_RIS_PROJECTION } from "./types";

export const postParcoursRisProjection = (data: ParcoursRisResultsRequest) => ({
   type: FETCH_PARCOURS_RIS_PROJECTION,
   payload: data,
});

export const resetParcoursRisProjectionError = () => ({
   type: RESET_PARCOURS_RIS_PROJECTION_ERROR,
});

export const updateParcoursRisProjection = (data: Partial<ParcoursRisProjection>) => ({
   type: UPDATE_PARCOURS_RIS_PROJECTION,
   payload: data,
});
