import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { SGAvenirStyledSuiviVirement } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { resetParcours } from "store/simulateurAvantApres/slice";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { SIMULATEUR_AVANT_APRES } from "website/utils/privateRoutes";

export const AccesSimulateurReforme: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
  const { trackClick } = useTracking();

  const handleOnClick = () => {
    trackClick("clic-sur-la-reforme-et-moi");
    dispatch(resetParcours());
    history.push(SIMULATEUR_AVANT_APRES);
  };

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  return (
    <TuileCliquable
      accessible={possedeDroitsActionUtilisateur(supervisionState)}
      disableautomargin={!isPhone}
      icone={<SGAvenirStyledSuiviVirement />}
      iconeTaille="s"
      titre="La Réforme & moi"
      texte="Visualiser vos dates prévisionnelles de départ à la retraite"
      onClick={handleOnClick}
      titleLevel={2}
    />
  );
};
