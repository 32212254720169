export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  let result;

  if (parts.length === 2) {
    const last = parts.pop();
    if (last) {
      result = last.split(";").shift();
    }
  }

  return !result ? null : result;
}

export function setCookie(name: string, value: string, domainSuffix?: string, days?: number): void {
  let expires = "";
  let domain = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  if (domainSuffix) {
    domain = `; domain=${domainSuffix}`;
  }
  document.cookie = `${name}=${value || ""}${domain}${expires}; path=/`;
}
