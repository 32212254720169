import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { apiGet, apiPost } from "store/apiCaller";
import { onDefaultError, safe } from "store/error/utils";
import { setQuestions } from "../questions/slice";
import { Question } from "../questions/types";
import { personnalisationQuestionsFetched } from "./slice";
import {
  DashboardType,
  FETCH_PERSONNALISATION_DASHBOARD,
  FETCH_PERSONNALISATION_DASHBOARD_PARAMS,
} from "./types";
import { logError } from "website/utils/logging";

export function* watcherSaga() {
  yield takeLeading(
    FETCH_PERSONNALISATION_DASHBOARD,
    safe(onDefaultError, handleFetchPersonnalisationDashboard)
  );
  yield takeLeading(
    FETCH_PERSONNALISATION_DASHBOARD_PARAMS,
    safe(onApiCallError, handleFetchPersonnalisationDashboardParams)
  );
}

function* handleFetchPersonnalisationDashboard(
  action: PayloadAction<number[]>
) {
  const payload: AxiosResponse<DashboardType> = yield call(
    apiPost,
    `/backpfr/api/personnalisation/dashboard`,
    action.payload
  );
  yield put(personnalisationQuestionsFetched(payload.data));

  const questions: Question[] = yield select(
    (state) => state.personnnalisationQuestions.questions
  );
  yield put(
    setQuestions(
      questions.map((question) => ({
        ...question,
        checked: action.payload.includes(question.id),
      }))
    )
  );
}

function* handleFetchPersonnalisationDashboardParams() {
  const payload: AxiosResponse<DashboardType> = yield call(
    apiGet,
    `/backpfr/api/personnalisation/dashboard`
  );
  yield put(personnalisationQuestionsFetched(payload.data));
}

function* onApiCallError(err: any) {
  logError("Erreur Fetch Personnalisation Dashboard Params", err);
  yield put(personnalisationQuestionsFetched(DashboardType.BESOIN));
}
