import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGModal } from "sg-modal";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";

interface InfoModalProps {
    visible: boolean;
    onCancel: () => void;
    text: string;
}

export const InfoModal: FunctionComponent<InfoModalProps> = (props: InfoModalProps) => {
    const { visible, onCancel, text } = props;
    const intl = useIntl();

    return (
        <SGModal
            visible={visible}
            onCancel={onCancel}
        >
            <SGBlock />
            <SGBox textalign="left" margin={{top: "md"}}>
                <SGText>
                    {intl.formatMessage({ id: text }, {
                        box: (word) => <SGBox padding={{ top: "sm" }}><>{word}</></SGBox>,
                        ul: (word) => <SGBlock component="ul">{word}</SGBlock>,
                        li: (word) => <li>{word}</li>,
                        b: (word) => <SGText strong>{word}</SGText>
                    })}
                </SGText>
            </SGBox>
        </SGModal>
    );
};
