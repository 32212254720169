import { RetirementCategorySimulation } from "store/members/types";
import { RetirementSimulationAge } from "store/simulationAssu/types";

export interface SimulateurAvantApresState {
   currentStep: number;
   isTunnelRedirect: boolean;
   hasFetched: boolean;
   showStep2?: boolean; // Permet de savoir si on doit afficher l'étape 2
   parametres: ParametresSimulateurAvantApres;
   simulateurAvantApres: SimulateurAvantApres;
}

export interface ParametresSimulateurAvantApres {
   simulationKey?: string;
   statut?: RetirementCategorySimulation;
   categorieActive?: boolean;
   dateNaissance?: string;
   dateDebutCarriere?: string;
   genre?: Genre;
   nbEnfants?: number;
}
export interface SimulateurAvantApres {
   dateApplication: string;
   nbTrimestresAvant: number;
   ageLegalAvant?: Partial<RetirementSimulationAge>;
   ageTauxPleinAvant?: Partial<RetirementSimulationAge>;
   ageDepartAnticipeAvant?: Partial<RetirementSimulationAge>;
   nbTrimestresApres: number;
   ageLegalApres?: Partial<RetirementSimulationAge>;
   ageTauxPleinApres?: Partial<RetirementSimulationAge>;
   ageDepartAnticipeApres?: Partial<RetirementSimulationAge>;
}

export enum Genre {
   HOMME = "m",
   FEMME = "f",
}

export enum CategorieActiveChoix {
   OUI = "Oui",
   NON = "Non",
}

export const FETCH_SIMULATEUR_AVANT_APRES = "FETCH_SIMULATEUR_AVANT_APRES";
