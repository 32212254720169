import {call, put, takeLeading} from "redux-saga/effects";
import {FETCH_CAN_MEETING_BE_SENT, FETCH_SIMULATION_PDF, MeetingAppointmentState, SCHEDULE_MEETING} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiGet, apiPost} from "../apiCaller";
import {PayloadAction} from "@reduxjs/toolkit";
import {meetingCanBeSentFetched, meetingScheduled, simulationPdfFetched} from "./callToActionSlice";
import {AxiosError, AxiosResponse} from "axios";
import {globalErrorTimeOutOnPDFDownload} from "../error/errorsSlice";

export function* watcherSaga() {
   yield takeLeading(FETCH_CAN_MEETING_BE_SENT, safe(onDefaultError, handleGetCanMeetingBeSent));
   yield takeLeading(FETCH_SIMULATION_PDF, safe(onTimeoutPDFError, handleGetPdfSimulation));
   yield takeLeading(SCHEDULE_MEETING, safe(onDefaultError, handleScheduleMeeting));
}

function* onTimeoutPDFError(err: AxiosError) {
   const isTimeoutError = err.response?.status === 504;

   if (isTimeoutError) {
      yield put(globalErrorTimeOutOnPDFDownload());
   } else {
      onDefaultError(err);
   }
}

function* handleGetCanMeetingBeSent(action: PayloadAction<string>) {
   const params: string = action.payload ? `?targeting=${action.payload}` : "";
   const payload: AxiosResponse<MeetingAppointmentState> = yield call(apiGet, `wealth/api/advisor-meeting/state${params}`);
   yield put(meetingCanBeSentFetched(payload.data));
}

function* handleGetPdfSimulation(action: PayloadAction<{ incExpSvg: string, balanceSvg: string, email: string, alerts: Array<string> }>) {
   const payload: AxiosResponse<BlobPart> = yield call(apiPost, `wealth/api/pdf-document`, {requestBilan: true, ...action.payload}, {responseType: "arraybuffer"});
   yield put(simulationPdfFetched(payload.data));
}

function* handleScheduleMeeting(action: PayloadAction<{ incExpSvg: string, balanceSvg: string, email: string, alerts: Array<string> }>) {
   yield call(apiPost, `wealth/api/pdf-document`, {requestBilan: false, ...action.payload});
   yield put(meetingScheduled());
}
