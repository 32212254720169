import { call, put, takeLeading, } from "redux-saga/effects";
import { apiDelete } from "../apiCaller";
import { DELETE_PROFILE, PROFILE_DELETED } from "./types";
import { onDefaultError, safe } from "store/error/utils";

export function* watcherSaga() {
   yield takeLeading(DELETE_PROFILE, safe(onDefaultError, handleDeleteProfile));
}

function* handleDeleteProfile() {
   yield call(apiDelete, `/api/users/self`);
   yield put({ type: PROFILE_DELETED});
}
