import {CustodyType, Family, FamilyStatus, MaritalStatus, MatrimonialRegime, Me, Member, MemberWithId} from "./types";
import {isMemberWithId} from "./typeguards";

export const MIN_AGE_FOR_ME_OR_PARTNER = 18;
export const MIN_AGE_TO_RETIRE = 62;
export const MAX_AGE_TO_RETIRE = 70;

export const isIsolatedParentStatus = (maritalStatus?: MaritalStatus): boolean => maritalStatus === MaritalStatus.SINGLE || maritalStatus === MaritalStatus.DIVORCED_OR_SEPARATED;

export const isCoupleMaritalStatus = (maritalStatus?: MaritalStatus): boolean => maritalStatus === MaritalStatus.BOUND_BY_PACS || maritalStatus === MaritalStatus.FREE_UNION || maritalStatus === MaritalStatus.MARRIED;

export const isOfficialCoupleMaritalStatus = (maritalStatus?: MaritalStatus): boolean => maritalStatus === MaritalStatus.BOUND_BY_PACS || maritalStatus === MaritalStatus.MARRIED;

export const getMembersOfCouple = (family: Family): MemberWithId[] => {
   if (family.me && isMemberWithId(family.me)) {
      if (family.partner && isMemberWithId(family.partner)) {
         return [family.me, family.partner];
      }

      return [family.me];
   }

   return [];
};

export const hasChildrenUnder6 = (members: Member[]): boolean => {
   const today = new Date();

   return members.filter((m) => m.status === FamilyStatus.CHILD && m.birthday != null && today.getFullYear() - new Date(m.birthday).getFullYear() <= 6).length > 0;
};

export const calculateAgeByBirthdate = (birthdate: Date): number => {
   const today = new Date();
   const birthday = new Date(birthdate);
   let years = today.getFullYear() - birthday.getFullYear();

   if (today.getMonth() < birthday.getMonth() || (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate())) {
      years -= 1;
   }

   return years;
};

export const getDefaultCustodyType = (me: Member): CustodyType => isOfficialCoupleMaritalStatus(me.maritalStatus) ? CustodyType.FULL_CUSTODY : CustodyType.FULL_CUSTODY_ME;

export const getDefaultMatrimonialRegime = (matrimonialRegime: MatrimonialRegime | undefined): MatrimonialRegime => matrimonialRegime === undefined || matrimonialRegime === null ? MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS : matrimonialRegime;

export const getAmountOfMembersWithSameStatus = (family: Family, status: FamilyStatus) => {
   if (status === FamilyStatus.ME) {
      return 1;
   }
   if (status === FamilyStatus.PARTNER) {
      return family.partner ? 1 : 0;
   }
   if (status === FamilyStatus.CHILD) {
      return family.children.length;
   }

   return family.relatives.filter((m) => m.status === status).length;
};

export const getAvailableCustodyTypes = (family: Family): CustodyType[] => {
   const custodyTypes: CustodyType[] = Object.values(CustodyType);
   if (!family.partner) {
      return custodyTypes.filter(
         (x: CustodyType) =>
            x === CustodyType.FULL_CUSTODY_ME ||
            x === CustodyType.SHARED_CUSTODY_ME ||
            x === CustodyType.NO_CUSTODY
      );
   }
   if (isOfficialCoupleMaritalStatus(family.me.maritalStatus)) {
      return custodyTypes.filter(
         (x: CustodyType) =>
            x === CustodyType.FULL_CUSTODY ||
            x === CustodyType.SHARED_CUSTODY ||
            x === CustodyType.NO_CUSTODY
      );
   }

   return custodyTypes.filter(
      (x: CustodyType) =>
         x === CustodyType.FULL_CUSTODY_ME ||
         x === CustodyType.FULL_CUSTODY_PARTNER ||
         x === CustodyType.SHARED_CUSTODY ||
         x === CustodyType.SHARED_CUSTODY_ME ||
         x === CustodyType.SHARED_CUSTODY_PARTNER ||
         x === CustodyType.NO_CUSTODY
   );
};

export const getMatrimonialRegimes = (): MatrimonialRegime[] => Object.values(MatrimonialRegime);

export const toMembers = (family: Family): Member[] => {
   if (!family) {
      return [];
   }
   const members: Member[] = family.partner == null ? [family.me] : [family.me, family.partner];

   return members
   .concat(family.children)
   .concat(family.relatives);
};

// in theory you will only need this when importing
export const membersToFamily = (members: Array<Member>): Family => {
   const me: Member = members.filter((m) => m.status === FamilyStatus.ME)[0];
   const partner: Member | undefined = members.filter((m) => m.status === FamilyStatus.PARTNER).shift();
   let typedMe: Me;
   if (!me.birthday) {
      throw new Error("can't have a me without birthday");
   } else {
      typedMe = {...me, maritalStatus: me.maritalStatus || MaritalStatus.SINGLE, birthday: me.birthday};
   }

   return {
      me: typedMe,
      partner: (!partner ? undefined : {...partner, maritalStatus: partner.maritalStatus || typedMe.maritalStatus}),
      children: members.filter((m) => m.status === FamilyStatus.CHILD),
      relatives: members.filter((m) => m.status !== FamilyStatus.ME && m.status !== FamilyStatus.PARTNER && m.status !== FamilyStatus.CHILD)
   };
};

export const cloneMember = (member: Member): Member => ({
   ...member,
   incomes: !member.incomes ? [] : [...member.incomes], // a shallow clone is enough for incomes and projects
   projects: !member.projects ? [] : [...member.projects]
});

// reacts lets you either modify the state or return an updated version of it, but gets angry if you try doing both, so you have to deep clone
export const cloneFamily = (family: Family): Family => ({
   me: {...cloneMember(family.me), maritalStatus: family.me.maritalStatus, birthday: family.me.birthday},
   partner: (!family.partner ? undefined : {...cloneMember(family.partner), maritalStatus: family.partner.maritalStatus}),
   children: family.children.map((m) => cloneMember(m)),
   relatives: family.relatives.map((m) => cloneMember(m))
});
