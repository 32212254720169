import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";

interface RisTileProps {
  accessibility?: boolean;
  tileName: string;
  tileIcon: JSX.Element;
  lien?: string;
  onClick: () => void;
}

const RisTile: FunctionComponent<RisTileProps> = (props: RisTileProps) => {
  const { accessibility, tileName, tileIcon, lien, onClick } = props;

  const intl = useIntl();

  return (
    <TuileCliquable
      accessible={accessibility}
      icone={tileIcon}
      titre={intl.formatMessage({
        id: `dashboard.carteDonnee.pension.popup.${tileName}.title`,
      })}
      texte={intl.formatMessage({
        id: `dashboard.carteDonnee.pension.popup.${
          accessibility ? tileName : "unavailableService"
        }.text`,
      })}
      lien={lien}
      onClick={onClick}
    />
  );
};

export { RisTile };
