import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParametresSimulateurAvantApres, SimulateurAvantApres, SimulateurAvantApresState } from "./types";

const initialState: SimulateurAvantApresState = {
   currentStep: 0,
   isTunnelRedirect: false,
   hasFetched: false,
   showStep2: undefined, // On ne sait pas au début
   parametres: {},
   simulateurAvantApres: {
      dateApplication: "",
      nbTrimestresAvant: 0,
      nbTrimestresApres: 0,
   }
};

export const simulateurAvantApresSlice = createSlice({
   name: "simulateurAvantApres",
   initialState,
   reducers: {
      resetParcours: (currentState): SimulateurAvantApresState => ({
         ...currentState,
         currentStep: initialState.currentStep,
         isTunnelRedirect: initialState.isTunnelRedirect,
      }),
      resetParcoursTunnel: (currentState): SimulateurAvantApresState => ({
         ...currentState,
         currentStep: initialState.currentStep,
         isTunnelRedirect: true,
      }),
      nextStep: (currentState): SimulateurAvantApresState => ({
         ...currentState,
         currentStep: currentState.currentStep + 1,
      }),
      previousStep: (currentState): SimulateurAvantApresState => ({
         ...currentState,
         currentStep: currentState.currentStep - 1,
      }),
      goToStep: (currentState, action: PayloadAction<number>): SimulateurAvantApresState => ({
         ...currentState,
         currentStep: action.payload,
      }),
      setShowStep2: (currentState, action: PayloadAction<boolean>): SimulateurAvantApresState => ({
         ...currentState,
         showStep2: action.payload,
      }),
      updateParameters: (currentState, action: PayloadAction<ParametresSimulateurAvantApres>): SimulateurAvantApresState => ({
         ...currentState,
         hasFetched: false,
         parametres: {
            ...currentState.parametres,
            ...action.payload
         },
      }),
      simulateurAvantApresFetched: (currentState, action: PayloadAction<SimulateurAvantApres>): SimulateurAvantApresState => ({
         ...currentState,
         hasFetched: true,
         simulateurAvantApres: {
            ...action.payload
         },
      }),
   },
});

export const { resetParcours, resetParcoursTunnel, nextStep, previousStep, goToStep, setShowStep2, updateParameters, simulateurAvantApresFetched } =
simulateurAvantApresSlice.actions;

export default simulateurAvantApresSlice.reducer;
