import { PayloadAction } from "@reduxjs/toolkit";
import { FETCH_TAX_ECONOMY_SIMULATION, FETCH_TAX_ECONOMY_SIMULATION_PARAMS, ParametresSEI } from "./types";

export const callTaxEconomySimulation = (params: ParametresSEI): PayloadAction<ParametresSEI> => ({
   type: FETCH_TAX_ECONOMY_SIMULATION,
   payload: params,
});

export const fetchTaxEconomySimulationParams = () => ({
   type: FETCH_TAX_ECONOMY_SIMULATION_PARAMS,
});
