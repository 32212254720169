import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";

interface ArticleDisclaimerProps {
  renvoi?: string;
}

/**
 * Le contenu de type "disclaimer"
 * @returns Renvoie un paragraphe explicatif (statique)
 */
export const ArticleDisclaimer: FunctionComponent<
  ArticleDisclaimerProps
> = (props: ArticleDisclaimerProps) => {
  const { renvoi } = props;
  const intl = useIntl();

  return (
    <SGBox margin={{ top: "md", bottom: "md" }}>
      <SGText>
        {intl.formatMessage(
          {
            id: "home.article.disclaimer",
          },
          {
            p: (word) => (
                <SGBlock component="p">{word}</SGBlock>
            ),
            renvoi: <sup>{renvoi}</sup>,
          }
        )}
      </SGText>
    </SGBox>
  );
};
