const agentsNonTitulairesEtat = "articles/images/cover/agentsNonTitulairesEtatResume.jpg"
const anticiper = "articles/images/cover/anticiper.png";
const comprendreIndemnites = "articles/images/comprendre-indemnites/comprendreIndemnites-resume.jpg";
const comprendreRIS = "articles/images/cover/comprendreRIS.jpg";
const cumulEmploiRetraite = "articles/images/cover/cumulEmploiRetraite.jpg";
const enseignantsRetraite = "articles/images/enseignants-retraite/enseignants-retraite-resume.jpg";
const estimerBudgetRetraite = "articles/images/cover/estimerBudgetRetraite.jpg"
const expertComptableAnticiperDepartRetraite = "articles/images/expert-comptable-anticiper-depart-retraite/resume.jpg";
const femmeArtisan = "articles/images/cover/femmeArtisan.png"
const femmeExploitantAgricole = "articles/images/cover/femmeExploitantAgricole.png";
const femmeFoyer = "articles/images/cover/femmeFoyer-cover.png"
const fonctionnairesPensionRetraite = "articles/images/fonctionnaires-pension-retraite/fonctionnairesPensionRetraite-resume.jpg"
const fonctionnaireRetraite = "articles/images/fonctionnaire-retraite/fonctionnaireRetraite-resume.jpg"
const idee = "articles/images/cover/idee.png";
const impactEnfantRetraite = "articles/images/cover/impactEnfantRetraite.jpg";
const impactPensionRetraite = "articles/images/cover/impactPensionRetraite.png";
const jargon = "articles/images/cover/retraiteSansJargon.png";
const liquiderDroitsRetraite = "articles/images/liquider-droits-retraite/liquiderDroitsRetraite-resume.jpg";
// imageTitle se base sur le contenu de la chaîne et non son nom
const medecinLiberalCalculRetraite = "articles/images/medecin-liberal-calcul-retraite/comment-se-calcule-votre-retraite.jpg";
const optimiserRetraiteObligatoire = "articles/images/cover/optimiserRetraiteObligatoire.jpg";
const organiser = "articles/images/cover/organiser.png";
const partirRetraiteTauxPlein = "articles/images/partir-retraite-taux-plein/partir-retraite-taux-plein-intro.jpg";
const pensionReversion = "articles/images/cover/pensionReversion.jpg";
const periodesInvalidite = "articles/images/cover/periodesInvalidite.jpg";
const quiContacterRetraite = "articles/images/qui-contacter-retraite/quiContacterRetraite-resume.jpg";
const preparer = "articles/images/cover/preparer.png";
const rachatTrimestres = "articles/images/cover/rachatTrimestres-cover.jpg";
const retraiteConjointCollaborateur = "articles/images/cover/retraiteConjointCollaborateur-cover.png"
const salarieCommercantArtisanAgricole = "articles/images/salarie-commercant-artisan-agricole/salarieCommercantArtisanAgricole-resume.jpg";
const serviceMilitaire = "articles/images/cover/serviceMilitaire.jpg";
const travailEtranger = "articles/images/cover/travailEtranger.png";
const trimestreRetraite = "articles/images/cover/trimestreRetraite-cover.jpg";
const zoomRetraiteProgressive = "articles/images/cover/zoomRetraiteProgressive.svg";
const medecinLiberalRachatTrimestres = "articles/images/cover/medecinLiberalRachatTrimestres.jpg";
const microEntrepreneurs = "articles/images/micro-entrepreneurs/microEntrepreneurs-resume.jpg"
const arretMaladieSalarieImpact = "articles/images/arret-maladie-salarie-impact/arretMaladieSalarieImpact-resume.jpg";
const risDetecterErreurs = "articles/images/ris-detecter-erreurs/risDetecterErreurs-resume.jpg";
const conditionsRetraiteAnticipee = "articles/images/conditions-retraite-anticipee/conditionsRetraiteAnticipee-resume.jpg";
const materniteImpactRetraite = "articles/images/materniteImpactRetraite/materniteImpactRetraite-resume.jpg";
const artisansCommercants = "articles/images/artisans-commercants/artisansCommercants-resume.jpg";
const impositionPensionRetraite = "articles/images/imposition-pension-retraite/impositionPensionRetraite-resume.jpg";
const cipavCalculRetraite = "articles/images/CIPAV-calcul-retraite/cipavCalculRetraite-resume.jpg";
const artisansCommercantsClefs = "articles/images/artisans-commercants-clefs/artisansCommercantsClefs-resume.jpg";
const professionLiberalRetraiteCIPAV = "articles/images/profession-liberal-retraite-CIPAV/professionLiberalRetraiteCIPAV-resume.jpg";
const medecinLiberalAgeRetraite = "articles/images/medecin-liberal-age-retraite/medecinLiberalAgeRetraite-resume.jpg";
const enfantRetraiteProfessionLiberale = "articles/images/enfant-retraite-profession-liberale/enfantRetraiteProfessionLiberale-resume.jpg";
const tempsPartielEnfants = "articles/images/temps-partiel-enfants/tempsPartielEnfants-resume.jpg";

/**
 * Contient la liste d'images utilisé dans Article pour afficher l'image titre de l'article sélectionné
 */
const articleImages = [
   agentsNonTitulairesEtat,
   anticiper,
   comprendreIndemnites,
   comprendreRIS,
   cumulEmploiRetraite,
   enseignantsRetraite,
   estimerBudgetRetraite,
   expertComptableAnticiperDepartRetraite,
   femmeArtisan,
   femmeFoyer,
   fonctionnairesPensionRetraite,
   fonctionnaireRetraite,
   femmeExploitantAgricole,
   idee,
   impactEnfantRetraite,
   impactPensionRetraite,
   jargon,
   liquiderDroitsRetraite,
   medecinLiberalCalculRetraite,
   optimiserRetraiteObligatoire,
   organiser,
   partirRetraiteTauxPlein,
   pensionReversion,
   periodesInvalidite,
   preparer,
   quiContacterRetraite,
   rachatTrimestres,
   retraiteConjointCollaborateur,
   serviceMilitaire,
   travailEtranger,
   trimestreRetraite,
   zoomRetraiteProgressive,
   medecinLiberalRachatTrimestres,
   microEntrepreneurs,
   arretMaladieSalarieImpact,
   risDetecterErreurs,
   conditionsRetraiteAnticipee,
   materniteImpactRetraite,
   artisansCommercants,
   enfantRetraiteProfessionLiberale,
   medecinLiberalAgeRetraite,
   impositionPensionRetraite,
   cipavCalculRetraite,
   artisansCommercantsClefs,
   professionLiberalRetraiteCIPAV,
   salarieCommercantArtisanAgricole,
   tempsPartielEnfants
];

export { articleImages };
