import natif from "awt-natif";
import { isWebView } from "store/import/utils";
/**
 * Lance le téléchargement du fichier PDF.
 * @param pdfUrl Contenu base64 du PDF
 */
export const downloadPDF = (pdfUrl: string): void => {
   const baseUrl = window?.env?.REACT_APP_API_URL || "http://localhost:8080"
   // const url = `data:application/pdf;base64,${pdfUrl}`
   const url = `${baseUrl}${pdfUrl}`;
   if (isWebView()) {
      natif.ouvrirPDF(url);
   } else {
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "Synthèse retraite.pdf";
      downloadLink.click();
   }
};
