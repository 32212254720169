import { FunctionComponent, useEffect, useState } from "react";
import { SGAvenirStrokedArticle, SGAvenirStrokedVideo } from "sg-icon-pack-base";
import { Article, Media, TypeMedia, articles } from "website/components/hooks/articles";
import { ArticleContenu } from "website/components/molecules/Article/ArticleContenu/ArticleContenu";
import { ArticleModel } from "website/components/molecules/Article/ArticleModel";
import { articleImages } from "../../../components/hooks/articleImages";
import { MediaTemplate } from "../../../components/organisms/MediaTemplate/MediaTemplate";

interface ArticlePageProps {
  media: Media;
}

export const ArticlePage: FunctionComponent<ArticlePageProps> = (
  props: ArticlePageProps
) => {
  const { media } = props;
  const [articleJson, setArticleJson] = useState<ArticleModel>();
  const [article, setArticle] = useState<Article>();
  const [selectedAnchor, setSelectedAnchor] = useState<string>("");

  // Récupère le json correspondant à l'article selectionné en fonction de son path
  // puis set l'article en fonction du json récupéré
  const getData = (path: string) => {
    fetch(`articles/${path}.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      cache: "no-cache",
    })
      .then((response) => response.json())
      .then((myJson) => {
        setArticleJson(myJson);
      });
  };

  useEffect(() => {
    getData(media.path);
  }, [media.path]);

  useEffect(() => {
    setArticle(
      articles.find((articleTrie) => media.path === articleTrie.path)
    );
  }, [media.path]);

  return article && articleJson ? (
    <MediaTemplate
      media={media}
      titre={articleJson.h1}
      icone={media.type === TypeMedia.ARTICLE ? <SGAvenirStrokedArticle /> : <SGAvenirStrokedVideo />}
      image={
        <img
          className="media-template__cover"
          width={1009}
          alt={articleJson.alt ?? articleJson.imageTitle}
          src={articleImages
            .find((image) => image.includes(articleJson.imageTitle))}
        />
      }
    >
      {articleJson.contenus.map((contenu, index) => (
        // Sans l'index, problème lors du changement d'article
        <ArticleContenu
          key={`${media.path}_${contenu.type}_${String(index)}`}
          contenu={contenu}
          setSelectedAnchor={setSelectedAnchor}
          selectedAnchor={selectedAnchor}
          articlePath={media.path}
          type={media.type}
        />
      ))}
    </MediaTemplate>
  ) : (
    <></>
  );
};
