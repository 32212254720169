import { ReactComponent as Check } from "assets/images/green-check.svg";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGText } from "sg-typo";
import "./InfosServiceSapiendoCard.scss";
import { SGSpace } from "sg-space";

export interface InfosServiceSapiendoCardProps {
    content: string[];
    title?: string;
    check?: boolean;
    textButton?: string;
    onClickButton?: () => void;
}

export const InfosServiceSapiendoCard: FunctionComponent<InfosServiceSapiendoCardProps> = (props: React.PropsWithChildren<InfosServiceSapiendoCardProps>) => {
    const { onClickButton, content, textButton, title, check } = props;

    return (
        <SGCard className="infos-service-sapiendo-card">
            <SGGridRow gutter={[0, 23]}>
                {title && (
                    <SGGridCol span={12} align="start">
                        <SGText weight="bolder" style={{ fontSize: "24px", color: "#E2010B" }}>
                            <FormattedMessage id="title" defaultMessage={title} />
                        </SGText>
                    </SGGridCol>
                )}
                <SGGridCol span={12}>
                    <SGSpace direction="vertical" disableautomargin>
                        {content.map((item, index) => (
                            <SGGridRow gutter={[8]} key={item}>
                                <SGGridCol>
                                    {check ? (
                                        <Check />
                                    ) : (
                                        <div className="infos-service-sapiendo-card__round-icon">
                                            <SGText size="m" weight="600" style={{ color: "#FFFFFF" }}>
                                                {index + 1}
                                            </SGText>
                                        </div>
                                    )}
                                </SGGridCol>
                                <SGGridCol align="start" span={10} sm={11}>
                                    <SGText size="m" style={{ color: "#000000" }}>
                                        <FormattedMessage
                                            id="content"
                                            defaultMessage={item}
                                            values={{
                                                linebreak: <br />,
                                                b: (word: string) => <strong>{word}</strong>,
                                                sup: (word: string) => <sup>{word}</sup>,
                                                source: (word: string) => <p style={{ fontSize: "14px", color: "#636C6E" }}>{word}</p>,
                                            }}
                                        />
                                    </SGText>
                                </SGGridCol>
                            </SGGridRow>
                        ))}
                    </SGSpace>
                </SGGridCol>
                {textButton && (
                    <SGGridCol span={12} textalign="right">
                        <SGButton
                            type="primarylink"
                            onClick={onClickButton}
                            iconposition="right"
                            icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                        >
                            <FormattedMessage id="textButton" defaultMessage={textButton} />
                        </SGButton>
                    </SGGridCol>
                )}
            </SGGridRow>
        </SGCard>
    );
};
