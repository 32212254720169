// state
export interface AssetIncomesState {
   assetIncomes: AssetIncomes;
}

export const hasFetchedAssets = (assetIncomesState: AssetIncomesState): boolean => assetIncomesState.assetIncomes !== undefined;

// interfaces
/**
 * Revenu de chaque Asset par Age
 */
export interface AssetIncomes {
   [key: string]: { [key: number]: number };
}

export interface Incomes {
   income: number;
   ageType: string;
   age: number;
   months?: number;
   date: string;
   checkedAssets: number;
}

// enum
export enum AgeTypeEnum {
   MIN = "MIN",
   OPTIMAL = "OPTIMAL",
   MAX = "MAX",
   AGE_TP_CARCDSF = "AGE_TP_CARCDSF",
   AGE_TP_CARPIMKO = "AGE_TP_CARPIMKO",
   AGE_TP_CAVEC = "AGE_TP_CAVEC",
   AGE_TP_CAVAMAC = "AGE_TP_CAVAMAC",
   AGE_TP_CAVP = "AGE_TP_CAVP",
   AGE_TP_CPRN = "AGE_TP_CPRN",

   AGE_DEPART_IMMEDIAT = "AGE_DEPART_IMMEDIAT",
   AGE_TAUX_PLEIN_AUTO = "AGE_TAUX_PLEIN_AUTO",

   AGE_DA_CARRIERE_LONGUE = "AGE_DA_CARRIERE_LONGUE",
   AGE_DA_INAPT_TRAVAIL = "AGE_DA_INAPT_TRAVAIL",
   AGE_DA_INAPT_PERMANENTE = "AGE_DA_INAPT_PERMANENTE",

   AGE_TP_PLUS_UN_AN = "AGE_TP_PLUS_UN_AN",
   AGE_TP_SURCOTE= "AGE_TP_SURCOTE"
}

// actions
export const FETCH_ASSETS_INCOMES = "FETCH_ASSETS_INCOMES";
export const RESET_ASSETS_INCOMES = "RESET_ASSETS_INCOMES";
export const ASSET_INCOMES_FETCHED = "ASSET_INCOMES_FETCHED";
