import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGModal } from "sg-modal";
import { SGNotification } from "sg-notification";
import { SGText } from "sg-typo";
import { savePromotionalOffer } from "store/promotionalOffers/action";
import { PromotionalOffer } from "store/promotionalOffers/types";
import { usePromotionalOffers } from "website/components/hooks/usePromotionalOffers";
import "./QuestionnaireDashboard.scss";

interface QuestionnaireDashboardProps {
  visible: boolean;
  setVisible: (showParcoursRISModal: boolean) => void;
}

const QuestionnaireDashboard: FunctionComponent<QuestionnaireDashboardProps> = (
  props: QuestionnaireDashboardProps
) => {
  const { visible, setVisible } = props;
  const { notificationQuestionnaireDashboard, offresFetched } =
    usePromotionalOffers();
  const [initSaveOffre, setInitSaveOffer] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [showNotification, setShowNotification] = useState<boolean>(false);

  const [showQuestionnaireDashboardModal, setShowQuestionnaireDashboardModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (visible && !showNotification && !showQuestionnaireDashboardModal) {
      setShowNotification(true);
    }
  }, [visible]);

  /**
   * Si la popin est vu pour la première fois, max est défini à 1
   */
  useEffect(() => {
    if (offresFetched && showNotification && !initSaveOffre) {
      setInitSaveOffer(true);
      const promotionalOffer: PromotionalOffer = {
        ...notificationQuestionnaireDashboard,
        lastOfferDate: new Date(),
        viewCountOffer: incrementViewCountOffer(
          notificationQuestionnaireDashboard.viewCountOffer
        ),
      };
      dispatch(savePromotionalOffer(promotionalOffer));
    }
  }, [offresFetched, showNotification]);

  const incrementViewCountOffer = (viewCountOffer: number) =>
    viewCountOffer < 3 ? viewCountOffer + 1 : viewCountOffer;

  const onClickNotification = () => {
    setShowQuestionnaireDashboardModal(true);
    setShowNotification(false);

    const promotionalOffer: PromotionalOffer = {
      ...notificationQuestionnaireDashboard,
      lastOfferDate: new Date(),
      acceptOfferDate: new Date(),
    };
    dispatch(savePromotionalOffer(promotionalOffer));
  };

  return (
    <>
      {showNotification && (
        <SGNotification
          show={showNotification}
          onClose={() => {
            setShowNotification(false);
            setVisible(false);
          }}
          placement="bottomRight"
          message={
            <SGGridRow>
              <SGGridCol>
                <SGText>Votre avis compte&nbsp;!</SGText>
              </SGGridCol>
              <SGGridCol span={12} textalign="right">
                <SGButton size="sm" onClick={onClickNotification}>
                  Compléter
                </SGButton>
              </SGGridCol>
            </SGGridRow>
          }
        />
      )}
      <SGModal
        visible={showQuestionnaireDashboardModal}
        onCancel={() => {
          setShowQuestionnaireDashboardModal(false);
          setVisible(false);
        }}
        className="questionnaire-dashboard__questionnaire"
      >
        <iframe
          className="questionnaire-dashboard__iframe"
          src={window.env.REACT_APP_QUESTIONNAIRE_DB_URL}
          title="questionnaire"
        />
      </SGModal>
    </>
  );
};

export { QuestionnaireDashboard };
