import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGAlert } from "sg-alert";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStatusInfo,
} from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { PARCOURS_RIS } from "website/utils/privateRoutes";

const DisclaimerAgircArrco: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const handleOnClick = () => {
    trackClick("clic-sur-telecharger-mon-ris");
  };

  return (
    <SGAlert
      type="info"
      span={8}
      icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
      description={
        <SGText size="m">
          <FormattedMessage id="dashboard.disclaimer-age-agirc-arrco" />
        </SGText>
      }
      action={
        <SGLink
          type="tertiary"
          size="sm"
          onClick={handleOnClick}
          href={`#${PARCOURS_RIS}`}
          icon={
            <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
          }
          iconside="right"
        >
          <FormattedMessage id="dashboard.disclaimer-age-agirc-arrco.lien" />
        </SGLink>
      }
      actionPlacement="bottom"
    />
  );
};

export { DisclaimerAgircArrco };
