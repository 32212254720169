import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Family, FamilyState} from "../../../store/members/types";
import {getMembersAction} from "../../../store/members/actions";

const useFamily = (): Family => {
   const dispatch = useDispatch();
   const familyState: FamilyState = useSelector<State, FamilyState>((state) => state.family);

   useEffect(() => {
      if (!familyState.hasFetched) {
         dispatch(getMembersAction());
      }
   }, []);

   return familyState.family;
};

export {useFamily};
