import React, { ChangeEvent, FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGRadio, SGRadioGroup } from "sg-radio";
import { SGText } from "sg-typo";
import "./RadioGroup.scss";

interface RadioGroupProps {
    title: string;
    radioGroupList: Array<string>;
    selectedValue?: string;
    setValue: (value: string) => void;
    name: string;
    fontSize?: string;
    orientation: "horizontal" | "vertical";
}

export const RadioGroup: FunctionComponent<RadioGroupProps> = (props: RadioGroupProps) => {
    const { title, radioGroupList, selectedValue, setValue, name, fontSize, orientation = "horizontal" } = props;

    const intl = useIntl();

    const { control, formState: { errors }} = useFormContext();

    return (
        <SGGridCol span={12}>
            <SGGridRow gutter={[0, 10]}>
                <SGGridCol span={12} sm={10}>
                    <Controller
                        control={control}
                        name={name}
                        rules={{
                            required: true,
                        }}
                        defaultValue={selectedValue ?? null}
                        render={({ field }) => (
                            <SGRadioGroup
                            className="radio-group__container"
                                value={selectedValue}
                                status={errors && errors[name] ? "error" : "success"}
                                validate={errors && errors[name] && intl.formatMessage({id: "tunnel.error.required"})}
                                legend={
                                    <SGText size={fontSize || "l"}>{intl.formatMessage({ id: title })}</SGText>
                                }
                                name={name}
                                disableautomargin
                                onChange={(event: ChangeEvent<HTMLButtonElement>) => {
                                    field.onChange(event.target.value);
                                    setValue(event.target.value);
                                }}
                            >
                                <SGGridRow justify="space-around">
                                    {radioGroupList.map((radioValue: string) => (
                                        <SGGridCol span={4} sm={3} key={radioValue}>
                                            <SGGridRow justify="center">
                                                <SGGridCol textalign="center" span={orientation === "horizontal" ? 6 : 12}>
                                                    <SGRadio
                                                        aria-label={intl.formatMessage(
                                                            {
                                                                id: `radioGroupText.${radioValue}`
                                                            },
                                                            {
                                                                b: (word: string) => word,
                                                                linebreak: "",
                                                            })
                                                        }
                                                        className="radio-group__button-radio"
                                                        value={radioValue}
                                                    />
                                                </SGGridCol>
                                                <SGGridCol span={orientation === "horizontal" ? 6 : 12} textalign="center">
                                                    <SGText color={errors && errors[name] && "error"} size={fontSize || "s"}>
                                                        {intl.formatMessage(
                                                            {
                                                                id: `radioGroupText.${radioValue}`,
                                                            },
                                                            {
                                                                b: (word: string) => (
                                                                    <span className={` ${!errors[name] && "radio-group__black-raises"}`}>{word}</span>
                                                                ),
                                                                linebreak: <br />,
                                                            }
                                                        )}
                                                    </SGText>
                                                </SGGridCol>
                                            </SGGridRow>
                                        </SGGridCol>
                                    ))}
                                </SGGridRow>
                            </SGRadioGroup>
                        )}
                    />
                </SGGridCol>
            </SGGridRow>
        </SGGridCol>
    );
}
