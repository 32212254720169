import { FunctionComponent, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { hasFetchedDashboard } from "store/dashboard/types";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";
import { AccordeonQuestions } from "website/components/atoms/AccordeonQuestions/AccordeonQuestions";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useAccount } from "website/components/hooks/useAccount";
import { FaqTunnel } from "website/components/molecules/FaqTunnel/FaqTunnel";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import { FLASH_STEPS } from "website/pages/SimulateurFlashPage";
import { DASHBOARD } from "website/utils/privateRoutes";
import { State } from "../../../../store/store";
import { TunnelStep } from "../../organisms/Tunnel/TunnelStep/TunnelStep";

export interface SimulateurFlashProps extends RouteProps {
  steps: FunctionComponent[];
}

const faqBodyList = ["personal.data", "sapiendo"];

const questionsRevenus = [
  "statut",
  "date",
  "revenus",
  "evolution",
  "estimation",
];

export const SimulateurFlash: FunctionComponent<SimulateurFlashProps> = (
  props: SimulateurFlashProps
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const account = useAccount();
  const dashboardState = useDashboardState();
  const { showDetailFonctionnaire } = useContext(DetailTabContext);
  const simulationAssuState: RetirementSimulationParametersState = useSelector<
    State,
    RetirementSimulationParametersState
  >((state) => state.retirementSimulationParameters);

  const currentStep = useSelector<State, number>(
    (state) => state.tunnel.currentStep
  );
  const { steps } = props;

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  useEffect(() => {
    if (
      hasFetchedDashboard(dashboardState) &&
      simulationAssuState?.retirementSimulationParameters?.isParcoursDone
    ) {
      dispatch(
        updateRetirementSimulation({
          ...simulationAssuState.retirementSimulationParameters,
          isParcoursDone: false,
        })
      );
      history.push(DASHBOARD);
    }
  }, [dashboardState]);

  const questions: string[] = useMemo(() => {
    switch (currentStep) {
      case FLASH_STEPS.SITUATION:
        return ["enfants"];
      case FLASH_STEPS.REVENUS:
        return showDetailFonctionnaire
          ? [questionsRevenus[0], "fonctionnaire", ...(questionsRevenus.slice(1))]
          : questionsRevenus;
      default:
        return [];
    }
  }, [showDetailFonctionnaire, currentStep]);

  return (
    <SGSpace size="xl" direction="vertical">
      {steps.map(
        (step, index) =>
          currentStep === index && (
            <TunnelStep key={step.name} stepIndex={index} component={step} />
          )
      )}
      {questions.length > 0 && (
        <AccordeonQuestions
          key="accordeonQuestions"
          questionsKey={questions}
          titre="accordeonQuestions.titre.besoinDePrecision"
          description="accordeonQuestions.description.besoinDePrecision"
        />
      )}

      <FaqTunnel
        key="faqTunnel"
        questionsKey={faqBodyList}
        questionDefautKey={
          currentStep === 0 && !isPhone ? faqBodyList[0] : undefined
        }
        titre="accordeonQuestions.titre.informations"
      />
    </SGSpace>
  );
};
