import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { PROJECTS } from "../../website/utils/privateRoutes";
import { redirectToRelativePath } from "../../website/utils/routes/routing";
import { apiDelete, apiGet, apiPost } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { getMembersAction } from "../members/actions";
import { getNewProjects } from "../newProjects/actions";
import { getProjectsAction } from "./actions";
import {
   allProjectsDeleted,
   projectsFetched,
   retirementProjectFetched,
   retirementProjectUpdated,
   updateIsRetirementProjectAutomaticallyUpdated
} from "./projectsSlice";
import {
   CREATE_RETIREMENT_PROJECT,
   DELETE_ALL_PROJECTS,
   FETCH_PROJECTS,
   Project,
   RetirementProjectValues,
   UPDATE_RETIREMENT_PROJECT_IN_STORE,
   UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME
} from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_PROJECTS, safe(onDefaultError, handleGetProjects));
   yield takeLeading(UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME, safe(onDefaultError, handleUpdateRetirementProjectWithNewIncome));
   yield takeLeading(CREATE_RETIREMENT_PROJECT, safe(onDefaultError, handleCreateRetirementProject));
   yield takeLeading(UPDATE_RETIREMENT_PROJECT_IN_STORE, safe(onDefaultError, handleUpdateRetirementProjectInStore));
   yield takeLeading(DELETE_ALL_PROJECTS, safe(onDefaultError, handleDeleteAllProjects));
}

function* handleGetProjects() {
   const payload: AxiosResponse<Project[]> = yield call(apiGet, `wealth/api/projects`);
   yield put(projectsFetched(payload.data));
}

function* handleCreateRetirementProject() {
   const retirementProject: RetirementProjectValues = yield select((state) => state.projects.retirementProject);
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiPost, `wealth/api/projects/retirement?persist=true`, retirementProject);
   yield put(getNewProjects());
   redirectToRelativePath(`${PROJECTS}?currentProject=${payload.data.projectId}`);
   yield put(getProjectsAction());
   yield put(getMembersAction());
}

function* handleUpdateRetirementProjectInStore(action: PayloadAction<RetirementProjectValues>) {
   yield put(retirementProjectUpdated(action.payload));
}

function* handleUpdateRetirementProjectWithNewIncome(action: PayloadAction<{
   retirementProjectId: number,
   myCurrentIncome: number | undefined,
   partnerCurrentIncome: number | undefined,
}>) {
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiGet, `wealth/api/projects/retirement/${action.payload.retirementProjectId}`);
   const retirementSimulation: AxiosResponse<RetirementProjectValues> = yield call(
      apiPost,
      `wealth/api/projects/retirement`,
      {
         myRetirement: {
            memberId: payload.data.myRetirement.memberId,
            birthdate: payload.data.myRetirement.birthdate,
            currentMonthlyIncome: action.payload.myCurrentIncome,
            salaryEvolutionLevel: payload.data.myRetirement.salaryEvolutionLevel,
            type: payload.data.myRetirement.type,
            retirementYearToRetirementIncomeMap: {},
            retirementYearToIncomeBeforeRetirementMap: {}
         },
         partnerRetirement: payload.data.partnerRetirement
            ? {
               memberId: payload.data.partnerRetirement.memberId,
               birthdate: payload.data.partnerRetirement.birthdate,
               currentMonthlyIncome: action.payload.partnerCurrentIncome,
               salaryEvolutionLevel: payload.data.partnerRetirement.salaryEvolutionLevel,
               type: payload.data.partnerRetirement.type,
               retirementYearToRetirementIncomeMap: {},
               retirementYearToIncomeBeforeRetirementMap: {}
            }
            : undefined
      });

   const updatedRetirementProject: AxiosResponse<RetirementProjectValues> = yield call(
      apiPost,
      `wealth/api/projects/retirement?persist=true`,
      {
         myRetirement: {
            memberId: payload.data.myRetirement.memberId,
            birthdate: payload.data.myRetirement.birthdate,
            memberRetirementDate: payload.data.myRetirement.memberRetirementDate,
            retirementIncomes: [{
               name: "Retirement Income",
               income: retirementSimulation.data.myRetirement.retirementYearToRetirementIncomeMap[new Date(payload.data.myRetirement.memberRetirementDate).getFullYear() - new Date(payload.data.myRetirement.birthdate).getFullYear()]
            }],
            currentMonthlyIncome: action.payload.myCurrentIncome,
            salaryEvolutionLevel: payload.data.myRetirement.salaryEvolutionLevel,
            type: payload.data.myRetirement.type,
            retirementYearToRetirementIncomeMap: {},
            retirementYearToIncomeBeforeRetirementMap: {}
         },
         partnerRetirement: payload.data.partnerRetirement
            ? {
               memberId: payload.data.partnerRetirement.memberId,
               birthdate: payload.data.partnerRetirement.birthdate,
               memberRetirementDate: payload.data.partnerRetirement.memberRetirementDate,
               retirementIncomes: [{
                  name: "Retirement Income",
                  income: retirementSimulation.data.partnerRetirement.retirementYearToRetirementIncomeMap[new Date(payload.data.partnerRetirement.memberRetirementDate).getFullYear() - new Date(payload.data.partnerRetirement.birthdate).getFullYear()]
               }],
               currentMonthlyIncome: action.payload.partnerCurrentIncome,
               salaryEvolutionLevel: payload.data.partnerRetirement.salaryEvolutionLevel,
               type: payload.data.partnerRetirement.type,
               retirementYearToRetirementIncomeMap: {},
               retirementYearToIncomeBeforeRetirementMap: {}
            }
            : undefined
      });

   yield put(getProjectsAction());
   yield put(retirementProjectFetched(updatedRetirementProject.data));
   yield put(updateIsRetirementProjectAutomaticallyUpdated(true));
}

function* handleDeleteAllProjects() {
   yield call(apiDelete, `wealth/api/projects/`);
   yield put(allProjectsDeleted());
   yield put(getProjectsAction());
   yield put(getNewProjects());
}
