import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SGAlert } from "sg-alert";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { DashboardState } from "store/dashboard/types";
import {
  goToStep,
  resetParcours,
} from "store/parcoursRevenusComplementaires/action";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { StepsAssu as Steps } from "website/components/molecules/StepsAssu/StepsAssu";
import { EditorContextProvider } from "website/components/providers/EditorProvider";
import { State } from "../../store/store";
import { BreadCrumbAssu } from "../components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { ParcoursRevenusComplementairesAggregate } from "../components/organisms/ParcoursRevenusComplementaires/ParcoursRevenusComplementairesAggregate/ParcoursRevenusComplementairesAggregate";
import { ParcoursRevenusComplementairesEstimate } from "../components/organisms/ParcoursRevenusComplementaires/ParcoursRevenusComplementairesEstimate/ParcoursRevenusComplementairesEstimate";
import { ParcoursRevenusComplementaires } from "../components/templates/ParcoursRevenusComplementaires/ParcoursRevenusComplementaires";

export const PARAM_RESULTAT = "resultat";
export const QUERY_PARAM_ETAPE = "etape";

const ParcoursRevenusComplementairesPage: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const etapeSearchParams = urlSearchParams.get(QUERY_PARAM_ETAPE);

  const [reset, setReset] = useState(false);
  const currentStep = useSelector<State, number>(
    (state) => state.parcoursRevenusComplementaires.currentStep
  );

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);
  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );

  // Réinitialisation du parcours
  useEffect(() => {
    if (etapeSearchParams !== PARAM_RESULTAT) {
      dispatch(resetParcours());
    } else {
      dispatch(goToStep(1));
    }
    setReset(true);
  }, []);

  const steps: FunctionComponent[] = [
    ParcoursRevenusComplementairesAggregate,
    ParcoursRevenusComplementairesEstimate,
  ];

  const parcoursRevenusComplementairesSteps = [
    intl.formatMessage({ id: "parcoursRevenusComplementaires.step.agreger" }),
    intl.formatMessage({ id: "parcoursRevenusComplementaires.step.estimer" }),
  ];

  const idTrackingSteps = ["agreger-mon-patrimoine", "estimer-mes-revenus"];

  return reset ? (
    <div className="containerAssu">
      <SGMediaQuery minwidth="sm">
        <SGGridRow>
          <SGGridCol>
            <BreadCrumbAssu
              step="parcoursRevenusComplementaires.header"
              idTracking={idTrackingSteps[currentStep]}
            />
          </SGGridCol>
        </SGGridRow>
      </SGMediaQuery>
      <SGGridRow justify="center" gutter={[0, 0]}>
        {!possedeDroitsActionUtilisateur(supervisionState) &&
          dashboardState.dashboard?.firstPatrimonyPathDate === undefined &&
          currentStep === 0 && (
            <SGGridCol span={12}>
              <SGBox margin={{ bottom: "md" }}>
                <SGAlert
                  disableautomargin
                  icon={<SGIcon component={<SGAvenirStatusInfo />} size="m" />}
                  message={intl.formatMessage({
                    id: "parcoursRevenusComplementaires.alerte.supervision",
                  })}
                  span={10}
                  type="info"
                />
              </SGBox>
            </SGGridCol>
          )}
        <SGGridCol xl={10} xs={12}>
          <SGBox margin={{ top: "sm", bottom: "sm" }}>
            <SGTitle
              caps
              visuallevel="2"
            >
              {intl.formatMessage({
                id: "parcoursRevenusComplementaires.header",
              })}
            </SGTitle>
          </SGBox>
          <SGGridRow>
            <SGGridCol span={12}>
              <Steps
                currentStep={currentStep}
                steps={parcoursRevenusComplementairesSteps}
              />
            </SGGridCol>
          </SGGridRow>
        </SGGridCol>
        <SGGridCol xl={10} xs={12}>
          <SGBox margin={{ bottom: "sm" }}>
            <EditorContextProvider>
              <ParcoursRevenusComplementaires
                currentStep={currentStep}
                steps={steps}
              />
            </EditorContextProvider>
          </SGBox>
        </SGGridCol>
      </SGGridRow>
    </div>
  ) : (
    <></>
  );
};

export { ParcoursRevenusComplementairesPage };
