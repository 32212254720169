import { Breadcrumb } from "antd";
import { ReactComponent as Separator } from "assets/images/Vector.svg";
import React, { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { SGText } from "sg-typo";
import { TrackingTrackEvent } from "website/components/hooks/tracking/types";
import { DASHBOARD } from "website/utils/privateRoutes";
import "./BreadCrumbAssu.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface BreadCrumbAssuProps {
  step: string;
  retour?: string;
  idTracking?: string;
  complementTracking?: Partial<TrackingTrackEvent>;
}

const BreadCrumbAssu: FunctionComponent<BreadCrumbAssuProps> = (
  props: React.PropsWithChildren<BreadCrumbAssuProps>
) => {
  const { step, retour, idTracking, complementTracking = undefined } = props;
  const { trackClick } = useTracking();

  const [link] = useState(retour ?? DASHBOARD);

  return (
    <div className="breadcrumb">
      <Breadcrumb separator="" className="breadcrumb__container">
        <Breadcrumb.Item>
          <NavLink
            to={link}
            onClick={() => {
              trackClick(
                `${idTracking ? idTracking.concat("::") : ""}${
                  retour
                    ? "clic-sur-fil-comprendre-ma-retraire"
                    : "clic-sur-fil-mon-tableau-de-bord"
                }`,
                complementTracking
              );
            }}
            className="breadcrumb__item breadcrumb__step breadcrumb__step-link"
            activeClassName="breadcrumb__item__isActive"
          >
            <FormattedMessage
              id={retour ? `breadcrumb.${retour}` : "breadcrumb.bilanRetraite"}
            />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Separator className="breadcrumb__item breadcrumb__separator" />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <SGText className="breadcrumb__item breadcrumb__step">
            <FormattedMessage id={step} defaultMessage={step} />
          </SGText>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export { BreadCrumbAssu };
