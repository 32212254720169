import { FunctionComponent, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RetirementCategorySimulation } from "store/members/types";
import { State } from "store/store";
import { WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { InputNumberBrutNet } from "website/components/molecules/InputNumberBrutNet/InputNumberBrutNet";

interface RevenusInputProps {
  montantSalaireAnnuel?: number;
  setMontantSalaireAnnuel: (value: number | undefined) => void;
  secteurPro?: string;
}

export const RevenusInput: FunctionComponent<RevenusInputProps> = (
  props: RevenusInputProps
) => {
  const { montantSalaireAnnuel, setMontantSalaireAnnuel, secteurPro } = props;

  const { setValue, formState: { submitCount } } = useFormContext();

  const isBrutan =
    secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE ||
    secteurPro === RetirementCategorySimulation.FONCTIONNAIRE;

  const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<
    State,
    WorkIncomesHistoryState
  >((state) => state.workIncomesHistory);

  useEffect(() => {
    if (workIncomesHistoryState && workIncomesHistoryState.workIncomesHistory) {
      setMontantSalaireAnnuel(
        workIncomesHistoryState.workIncomesHistory.currentYearlyAmount
      );
    }
  }, [workIncomesHistoryState]);

  useEffect(() => {
    setValue("montantSalaireAnnuel", montantSalaireAnnuel, {
      shouldValidate: submitCount > 0,
    });
  }, [montantSalaireAnnuel]);

  return (
    <InputNumberBrutNet
      key="input-salaire"
      name="montantSalaireAnnuel"
      min={1000}
      max={99_999_999}
      label="tunnel.workIncomes.revenu"
      value={montantSalaireAnnuel}
      onChange={setMontantSalaireAnnuel}
      brutNet={isBrutan ? "brutan" : "netan"}
    />
  );
};
