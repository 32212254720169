import {
   CREATE_RETIREMENT_PROJECT,
   FETCH_PROJECTS,
   RetirementProjectValues,
   UPDATE_RETIREMENT_PROJECT_IN_STORE,
   UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME
} from "./types";

export const getProjectsAction = () => ({
   type: FETCH_PROJECTS
});

export const updateRetirementProjectWithNewIncome = (
   retirementProjectId: number,
   myCurrentIncome: number | undefined,
   partnerCurrentIncome: number | undefined
) => ({
   type: UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME,
   payload: {
      retirementProjectId,
      myCurrentIncome,
      partnerCurrentIncome
   }
});

export const updateRetirementProjectValuesInStoreAction = (retirementProject: RetirementProjectValues) => ({
   type: UPDATE_RETIREMENT_PROJECT_IN_STORE,
   payload: retirementProject
});

export const createRetirementProjectValuesAction = () => ({
   type: CREATE_RETIREMENT_PROJECT
});
