import React, { FunctionComponent } from "react";

interface ImageProps {
    src: string;
    alt?: string;
    className?: string;
}

/**
 * Récupère uniquement le nom de l'image (sans son path)
 *
 * @param src chemin src d'une image
 * @returns nom alt de l'image
 */
function getAltString(src: string): string {
    // index de la première lettre du nom de l'image
    const indexResult = src.length - src.split("").reverse().join("").search('/');

    return src.substring(indexResult, src.length - 4);
}

const Image: FunctionComponent<ImageProps> = (props: ImageProps) => {
    const { src, alt, className } = props;

    return <img src={src} alt={alt || getAltString(src)} className={className} />
}

export { Image };
