import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrackingState } from "./types";
import { LoadingStatus } from "website/components/hooks/tracking/types";

const initialState: TrackingState = {
  trackingInitStatus: LoadingStatus.LOADING,
  trackingReloading: true,
};

export const TrackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setTrackingInitStatus: (
      currentState,
      action: PayloadAction<TrackingState>
    ): TrackingState => ({
      ...currentState,
      trackingInitStatus: action.payload.trackingInitStatus,
      trackingReloading: action.payload.trackingReloading,
    }),
    setPageName: (
      currentState,
      action: PayloadAction<string>
    ): TrackingState => ({
      ...currentState,
      pageName: action.payload,
    }),
  },
});

export const { setTrackingInitStatus, setPageName } = TrackingSlice.actions;

export default TrackingSlice.reducer;
