import {AssetType, AssetWithValuation, RealEstateAsset, SCIAsset} from "./types";
import {Loan} from "../loans/types";

const isRealEstateAssetType = (asset: AssetWithValuation | RealEstateAsset | Loan | Partial<RealEstateAsset>): asset is RealEstateAsset => "taxSystemSubType" in asset;

const isSCIAssetType = (asset: AssetWithValuation | RealEstateAsset | Partial<RealEstateAsset>): asset is SCIAsset => asset?.assetType === AssetType.SCI;

const isAssetWithValuationType = (asset: AssetWithValuation | RealEstateAsset | Partial<RealEstateAsset>): asset is AssetWithValuation => "assetValuation" in asset;

export {isRealEstateAssetType, isSCIAssetType, isAssetWithValuationType};
