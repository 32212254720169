import React from "react";
import {SGContent} from "sg-layout";
import {PasswordResetFinish} from "../components/organisms/Utils/PasswordReset/PasswordResetFinish";

const PasswordResetFinishPage = () => (
   <SGContent span={8}>
      <PasswordResetFinish/>
   </SGContent>
);

export {PasswordResetFinishPage};
