import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGDivider } from "sg-divider";
import { SGDrawer } from "sg-drawer";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGHeader } from "sg-layout";
import { SGLogo } from "sg-logo";
import { SGBox } from "sg-space";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  MenuItem,
  getIdTracking,
  getMenu,
  getPathMenu,
  getPathSousMenu,
  getSousMenu,
  useMenu,
} from "website/components/hooks/useMenu";
import { MenuHorizontalPhone } from "./MenuHorizontalPhone";
import { MenuInlinePhone } from "./MenuInlinePhone";

const HeaderPhone: FunctionComponent = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const menuItems = useMenu();
  const { trackClick } = useTracking();

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [menuCourant, setMenuCourant] = useState<MenuItem>();

  const menuDrawer = menuItems.filter(
    (menuItem) => menuItem.icone === undefined
  );

  useEffect(() => {
    // Permet de mettre à jour le menu quand le path change
    if (pathname !== menuCourant?.path) {
      const path = `${pathname}${search}`;
      const menu = getPathMenu(menuItems, path);
      setMenuCourant(
        menu?.path === `${pathname}${search}`
          ? menu
          : getPathSousMenu(path, menu)
      );
    }
  }, [pathname, search]);

  const onChangeMenu = (key: string): void => {
    const newMenu = getMenu(menuItems, key);
    const newMenuCourant =
      newMenu?.key === key ? newMenu : getSousMenu(key, newMenu);
    setMenuCourant(newMenuCourant);
    if (newMenu && newMenuCourant && newMenuCourant.path) {
      if (newMenu?.key === newMenuCourant?.key) {
        trackClick(`clic-sur-${getIdTracking(newMenuCourant.key)}`);
      } else {
        trackClick(
          `${getIdTracking(newMenu.key)}::clic-sur-${getIdTracking(
            newMenuCourant.key
          )}`
        );
      }
      setShowDrawer(false);
      history.push(newMenuCourant.path);
    }
  };

  return (
    <SGHeader disableautomargin>
      <SGBox margin={{ top: "sm", bottom: "sm", left: "md" }}>
        <SGGridRow align="middle" gutter={[0, 0]}>
          {menuDrawer.length !== 0 && (
            <SGGridCol flex="0 0 0%">
              <SGButton
                icon={
                  <img
                    style={{ width: "24px", height: "26px", marginTop: "8px" }}
                    alt="Menu"
                    src="images\logo\menu.svg"
                  />
                }
                type="icon"
                onClick={() => {
                  trackClick("clic-sur-ouvrir-menu");
                  setShowDrawer((show) => !show);
                }}
                ariaLabel="Ouverture du menu"
              />
            </SGGridCol>
          )}
          <SGGridCol flex="1 0 0%" textalign="center">
            <SGLogo width={60} />
          </SGGridCol>
          <SGGridCol flex="0 0 0%" align="center">
            <MenuHorizontalPhone
              key={`${menuItems.length !== 1 ? "allmenus" : "Logoutmenu"}`}
              menuItems={menuItems.filter(
                (menuItem) => menuItem.icone !== undefined
              )}
              menuCourant={menuCourant}
              onChangeMenu={onChangeMenu}
            />
          </SGGridCol>
        </SGGridRow>
      </SGBox>
      <SGDivider disableautomargin />
      <SGDrawer
        placement="left"
        visible={showDrawer}
        onClose={() => {
          trackClick("clic-sur-fermer-menu");
          setShowDrawer((show) => !show);
        }}
      >
        <MenuInlinePhone menuItems={menuDrawer} onChangeMenu={onChangeMenu} />
      </SGDrawer>
    </SGHeader>
  );
};

export { HeaderPhone };
