import { AgeTypeEnum } from "store/assetIncomes/types";
import { DashboardAge, DashboardAgeType } from "store/dashboard/types";

export interface SavingPathState {
   objective: number;
   monthlyEffort: number;
   results?: Result[];
}

export interface EffortState {
   results?: Result[];
}

export const hasFetchedSavingPathObjective = (savingPathState: SavingPathState): boolean => savingPathState.objective !== undefined;

export const hasFetchedSavingPathMonthlyEffort = (savingPathState: SavingPathState): boolean => savingPathState.monthlyEffort !== undefined;

export const hasFetchedSavingPaths = (savingPathState: SavingPathState): boolean => savingPathState.results !== undefined;

export const hasFetchedEffort = (effortState: EffortState): boolean => effortState.results !== undefined;

export interface SavingEffortRequest {
   profile: string;
   monthlyIncomeObjective: number;
}

export interface SavingEffortsRequest {
   profiles: string[];
   monthlyIncomeObjective: number;
}

export interface MonthlyIncomeRequest {
   profile: string;
   monthlySavingEffort: number;
}

export interface MonthlyIncomesRequest {
   profiles: string[];
   monthlySavingEffort: number;
}

export interface Result {
   age: string;
   result: number;
}

export interface DataComparer {
   age: DashboardAge;
   montantEpargne?: number;
   objectif?: number;
   deltaObjectif?: number;
   montantPatrimoine?: number;
}

export const FETCH_SAVINGPATH_FROM_OBJECTIVE = "FETCH_SAVINGPATH_FROM_OBJECTIVE";
export const SAVINGPATH_FROM_OBJECTIVE_FETCHED = "SAVINGPATH_FROM_OBJECTIVE_FETCHED";
export const FETCH_SAVINGPATH_FROM_EFFORT = "FETCH_SAVINGPATH_FROM_EFFORT";
export const SAVINGPATH_FROM_EFFORT_FETCHED = "SAVINGPATH_FROM_EFFORT_FETCHED";

export const FETCH_SAVINGPATHS_FROM_OBJECTIVE = "FETCH_SAVINGPATHS_FROM_OBJECTIVE";
export const SAVINGPATHS_FROM_OBJECTIVE_FETCHED = "SAVINGPATHS_FROM_OBJECTIVE_FETCHED";
export const FETCH_SAVINGPATHS_FROM_EFFORT = "FETCH_SAVINGPATHS_FROM_EFFORT";
export const SAVINGPATHS_FROM_EFFORT_FETCHED = "SAVINGPATHS_FROM_EFFORT_FETCHED";
export const FETCH_EFFORTS = "FETCH_EFFORTS";
export const EFFORTS_FETCHED = "EFFORTS_FETCHED";

export const convertAgeTypeToWealthProfile = (type: DashboardAgeType) => {
   switch (type) {
      case DashboardAgeType.age_legal:
         return AgeTypeEnum.MIN;
      case DashboardAgeType.age_taux_plein:
         return AgeTypeEnum.OPTIMAL;
      case DashboardAgeType.age_maximum:
         return AgeTypeEnum.MAX;
      default:
         return type.toUpperCase();
   }
};
