import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGStep, SGSteps } from "sg-steps";
import { SGText, SGTitle } from "sg-typo";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { useFamily } from "website/components/hooks/useFamily";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";
import { TunnelBesoinRetraite } from "website/components/organisms/Tunnel/TunnelAssu/TunnelBesoin/TunnelBesoinRetraite";
import { TunnelPension } from "website/components/organisms/Tunnel/TunnelAssu/TunnelPension/TunnelPension";
import { TunnelVisionRetraite } from "website/components/organisms/Tunnel/TunnelAssu/TunnelVisionRetraite/TunnelVisionRetraite";
import { TunnelWorkIncomes } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWorkIncomes/TunnelWorkIncomes";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { LOGOUT } from "website/utils/privateRoutes";
import { State } from "../../store/store";
import { TunnelFamilySituation as TunnelFamilySituationAssu } from "../components/organisms/Tunnel/TunnelAssu/TunnelFamilySituation/TunnelFamilySituation";
import { TunnelAssu } from "../components/templates/TunnelAssu/TunnelAssu";

export enum TUNNEL_STEPS {
  VISION,
  BESOIN,
  SITUATION,
  REVENUS,
  PENSION,
}

export const TunnelPage: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  // Fetch pour plus tard
  const family = useFamily();
  const { personalInformationsState } = usePersonalInformations();

  const step = useSelector<State, number>((state) => state.tunnel.currentStep);

  const currentStep = step < 2 ? 0 : step - 1;

  const steps = [
    TunnelVisionRetraite,
    TunnelBesoinRetraite,
    TunnelFamilySituationAssu,
    TunnelWorkIncomes,
    TunnelPension,
  ];

  const categories = [
    intl.formatMessage({ id: "tunnel.step.etape1" }),
    intl.formatMessage({ id: "tunnel.step.etape2" }),
    intl.formatMessage({ id: "tunnel.step.etape3" }),
    intl.formatMessage({ id: "tunnel.step.pension" }),
  ];

  useScrollToTopWithCurrentStep(currentStep);

  const getStatus = (index: number): string => {
    if (currentStep === index) {
      return "process";
    }
    if (currentStep < index) {
      return "wait";
    }

    return "finish";
  };

  const getProgress = (index: number): number => {
    if (currentStep === index && step === TUNNEL_STEPS.VISION) {
      return 25;
    }
    if (currentStep === index && step === TUNNEL_STEPS.BESOIN) {
      return 75;
    }

    return currentStep === index ? 50 : 100;
  };

  return (
    <SGLayout tagName="div">
      <SGContent disableautomargin>
        <SGGridRow justify="center" gutter={[0, 20]}>
          <SGGridCol span={12}>
            {!possedeDroitsActionUtilisateur(supervisionState) && (
              <SGBox margin={{ top: "md" }}>
                <SGAlert
                  description={
                    <SGText size="m">
                      Le client n’a pas réalisé son onboarding, invitez-le à
                      découvrir Mon&#160;Compagnon&#160;Retraite.
                    </SGText>
                  }
                  icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                  type="info"
                  span={9}
                  action={
                    <SGLink href={`#${LOGOUT}`} size="sm" type="primary">Retour</SGLink>
                  }
                  actionPlacement="top"
                />
              </SGBox>
            )}
          </SGGridCol>
          <SGGridCol span={12}>
            <SGBox margin={isPhone ? {} : { top: "sm" }}>
              <SGTitle level={1} textalign={isPhone ? "left" : "center"}>
                {intl.formatMessage({ id: "tunnel.title" })}
              </SGTitle>
            </SGBox>
          </SGGridCol>
          <SGGridCol span={12}>
            <SGBox margin={{ bottom: "md" }}>
              <SGSteps current={currentStep}>
                {categories.map((categorie, index) => (
                  <SGStep
                    title={categorie}
                    status={getStatus(index)}
                    key={categorie}
                    progress={getProgress(index)}
                  />
                ))}
              </SGSteps>
            </SGBox>
          </SGGridCol>
        </SGGridRow>
      </SGContent>
      <SGLayout background="light">
        <SGHeader disableautomargin />
        <SGContent disableautomargin>
          <SGBox span={isPhone ? 12 : 8}>
            <DetailTabProvider>
              <TunnelAssu steps={steps} />
            </DetailTabProvider>
          </SGBox>
        </SGContent>
      </SGLayout>
    </SGLayout>
  );
};
