import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardAge, User } from "store/dashboard/types";
import { DashboardType } from "store/personnalisation/dashboard/types";
import {
  articlesAccesContenuDashboard,
  contenusAccesContenuDashboardNovice,
} from "website/components/hooks/articles";
import {
  SectionDashBoardType,
  usePersonnalisationDashboard,
} from "website/components/hooks/dashboard/usePersonnalisationDashboard";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useFeatures } from "website/components/hooks/useFeatures";
import { AccesContenus } from "website/components/molecules/AccesContenus/AccesContenus";
import { LegalNotice } from "website/components/molecules/LegalNotice/LegalNotice";
import {
  OffreType,
  PromotionalBanner,
} from "website/components/molecules/PromotionalBanner/PromotionalBanner";
import { DisclaimerDashboard } from "website/components/organisms/Dashboard/DisclaimerDashboard/DisclaimerDashboard";
import { Age } from "website/components/organisms/Dashboard/Personnalisation/Age/Age";
import { Revenus } from "website/components/organisms/Dashboard/Personnalisation/Revenus/Revenus";
import { SectionBesoins } from "website/components/organisms/Dashboard/Personnalisation/SectionBesoins/SectionBesoins";
import { SectionBienvenue } from "website/components/organisms/Dashboard/Personnalisation/SectionBienvenue/SectionBienvenue";
import { SectionPatrimoine } from "website/components/organisms/Dashboard/Personnalisation/SectionPatrimoine/SectionPatrimoine";
import { SectionPension } from "website/components/organisms/Dashboard/Personnalisation/SectionPension/SectionPension";
import { SectionPreparerMaRetraite } from "website/components/organisms/Dashboard/Personnalisation/SectionPreparerMaRetraite/SectionPreparerMaRetraite";
import { SectionReformeEtSynthese } from "website/components/organisms/Dashboard/Personnalisation/SectionReformeEtSynthese/SectionReformeEtSynthese";

const DashboardPerso: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { sectionsPerso, dashboard } = usePersonnalisationDashboard();
  const { simulateurAvantApres, disponibiliteOffrePER } = useFeatures();
  const { agesTries, selectedRetirementAge } = useSelectedRetirementAge();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "lg" });

  const [ageDepartDashboard, setAgeDepartDashboard] = useState<DashboardAge[]>(
    []
  );
  const [showDrawerBesoins, setShowDrawerBesoins] = useState(false);

  useEffect(() => {
    if (agesTries) {
      // Construction des AgesDeparts à partir de dashboardInformations
      setAgeDepartDashboard(agesTries);
    }
  }, [agesTries]);

  const showPromotionalBanner = () =>
    new Date() > new Date(window.env.REACT_APP_OFFRE_PER_DEBUT) &&
    new Date() < new Date(window.env.REACT_APP_OFFRE_PER_FIN) &&
    disponibiliteOffrePER;

  const changeSelectedAgeRetirement = (val: DashboardAge) => {
    // save choosen age
    const req: User = {
      choosenAge: val.type,
    };
    dispatch(updatePrefs(req));
  };

  const getSection = (section: SectionDashBoardType) => {
    switch (section) {
      case SectionDashBoardType.PATRIMOINE:
        return (
          <SectionPatrimoine selectedRetirementAge={selectedRetirementAge} />
        );
      case SectionDashBoardType.BESOINS:
        return (
          <SectionBesoins
            selectedRetirementAge={selectedRetirementAge}
            setShowDrawerBesoins={setShowDrawerBesoins}
            showDrawerBesoins={showDrawerBesoins}
          />
        );
      case SectionDashBoardType.PENSION:
        return <SectionPension selectedRetirementAge={selectedRetirementAge} />;
      case SectionDashBoardType.ARTICLES:
        return (
          <AccesContenus
            idMedias={
              dashboard === DashboardType.NOVICE
                ? contenusAccesContenuDashboardNovice
                : articlesAccesContenuDashboard
            }
          />
        );
      case SectionDashBoardType.REFORME_ET_SYNTHESE:
        return simulateurAvantApres || isPhone ? (
          <SectionReformeEtSynthese />
        ) : null;
      case SectionDashBoardType.PREPARER_MA_RETRAITE:
        return <SectionPreparerMaRetraite />;
      case SectionDashBoardType.BANNIERES:
        return showPromotionalBanner() ? (
          <PromotionalBanner offreType={OffreType.PER} />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <SGSpace direction="vertical" size="lg">
      <SGSpace key="space" direction="vertical" size="md" disableautomargin>
        <DisclaimerDashboard key="disclaimerDashboard" />
        {isPhone && <SectionBienvenue key="sectionBienvenue" />}
        <Age
          key="age"
          agesDepartRetraite={ageDepartDashboard}
          setSelectedRetirementAge={changeSelectedAgeRetirement}
          selectedRetirementAge={selectedRetirementAge}
        />
      </SGSpace>
      <SGBox key="box-revenu" span={isPhone ? 12 : 6}>
        <Revenus setShowDrawer={setShowDrawerBesoins} />
      </SGBox>
      {sectionsPerso.map((section) => (
        <Fragment key={section}>{getSection(section)}</Fragment>
      ))}
      <LegalNotice key="legalNotice" />
    </SGSpace>
  );
};

export { DashboardPerso };
