import { Genre } from "./types";

export function toStringDate(value: Date, separator: string, isDefaultDay = false): string {
    const date = new Date(value)

    return `${isDefaultDay ? "01" : String(date.getDate()).padStart(2, '0')}${separator}${String(date.getMonth() + 1).padStart(2, '0')}${separator}${date.getFullYear()}`;
 }

 export function getDate(value: string): Date {
    const date = value.split('-')

    return new Date(`${date[2]}/${date[1]}/${date[0]}`);
 }

export function genreTransco(civilite: string) {
   switch (civilite) {
      case "Mme":
         return Genre.FEMME;
      case "M.":
         return Genre.HOMME;
      default:
         return Genre.HOMME;
   }
}
