export interface TrackingEvent {
   userId: number;
   trackingId?: string;
   type: string;
   fromWebview: boolean;
   operatingSystem: string;
   webBrowser: string;
   hardware: string;
   complement?: string;
}
export interface TrackingEventState {
   trackingEvent?: TrackingEvent;
}

export const SAVE_TRACKING_EVENT = "SAVE_TRACKING_EVENT";
export const TRACKING_EVENT_SAVED = "TRACKING_EVENT_SAVED";
