import { FunctionComponent } from "react";
import { useHistory, useLocation } from "react-router";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGLayout } from "sg-layout";
import { SGMediaQuery } from "sg-media-query";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { BanniereModeSupervision } from "website/components/molecules/BanniereModeSupervision/BanniereModeSupervision";
import {
  DASHBOARD,
  REFORME_DEPART_ANTICIPE,
  TUNNEL,
} from "website/utils/privateRoutes";
import "./Header.scss";
import { HeaderDesktop } from "./templates/HeaderAssu/HeaderDesktop";
import { HeaderPhone } from "./templates/HeaderAssu/HeaderPhone";

const Header: FunctionComponent = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackClick } = useTracking();
  const lstNoShowButton = [DASHBOARD, TUNNEL, REFORME_DEPART_ANTICIPE];

  return (
    <div className="header">
      <BanniereModeSupervision />
      <SGMediaQuery minwidth="lg">
        <HeaderDesktop />
      </SGMediaQuery>
      <SGMediaQuery minwidth={0} maxwidth="md">
        <HeaderPhone />
        {!lstNoShowButton.includes(pathname) && (
          <SGLayout
            backbutton={
              <SGButton
                onClick={() => {
                  trackClick("clic-sur-navigation-retour");
                  history.goBack();
                }}
                icon={
                  <SGIcon
                    component={<SGAvenirNavFlecheBtnLien />}
                    currentcolor
                    rotate="left"
                  />
                }
                type="tertiary"
              >
                Retour
              </SGButton>
            }
          />
        )}
      </SGMediaQuery>
    </div>
  );
};

export { Header };
