import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { DashboardAge, hasFetchedDashboard } from "store/dashboard/types";
import { getSavingEffort } from "store/savingPath/actions";
import {
    SavingEffortRequest,
    SavingPathState,
    convertAgeTypeToWealthProfile,
    hasFetchedSavingPathMonthlyEffort,
} from "store/savingPath/types";
import { State } from "store/store";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { useDashboardState } from "../dashboard/useDashboardState";

/**
 * Utilisé seulement sur MonPlan
 */
const useMonPlanMontants = (
  selectedRetirementAge?: DashboardAge,
  montantEpargne?: number
) => {
  const dispatch = useDispatch();
  const dashboardState = useDashboardState();

  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);
  const savingPathState: SavingPathState = useSelector<State, SavingPathState>(
    (state) => state.savingPath
  );

  const [montantTotal, setMontantTotal] = useState<number>();
  const [montantPension, setMontantPension] = useState<number>();
  const [montantRevenusComplementaires, setMontantRevenusComplementaires] =
    useState<number>();
  const [montantRevenusAFinancer, setMontantRevenusAFinancer] =
    useState<number>();
  const [montantEffortEpargne, setMontantEffortEpargne] = useState<number>();

  const { assets } = assetState;
  const { assetIncomes } = assetIncomesState;

  const hasFetchedDashboardState: boolean = hasFetchedDashboard(dashboardState);
  const hasFetchedAssetIncomesState = hasFetchedAssets(assetIncomesState);

  useEffect(() => {
    // Fetch systématique car l'étape 2 du patrimoine écrase les assets de l'étape 1
    dispatch(getAssetsWithoutLoans());
    if (!hasFetchedAssets(assetIncomesState)) {
      dispatch(getAssetsIncomes());
    }
  }, []);

  useEffect(() => {
    if (montantEpargne) {
      setMontantEffortEpargne(montantEpargne);
    }
  }, [montantEpargne]);

  useEffect(() => {
    if (
      hasFetchedSavingPathMonthlyEffort(savingPathState) &&
      montantEpargne === undefined
    ) {
      setMontantEffortEpargne(roundDown10(savingPathState.monthlyEffort));
    }
  }, [savingPathState]);

  useEffect(() => {
    if (montantEpargne === undefined && montantTotal && selectedRetirementAge) {
      const req: SavingEffortRequest = {
        profile: convertAgeTypeToWealthProfile(selectedRetirementAge.type),
        monthlyIncomeObjective: montantTotal,
      };
      dispatch(getSavingEffort(req));
    }
  }, [montantTotal, selectedRetirementAge]);

  useEffect(() => {
    if (selectedRetirementAge) {
      let total = 0;
      let retirementAdditionalIncome = 0;
      let pension = 0;

      if (selectedRetirementAge.retirementMonthlyIncome) {
        pension = roundDown10(selectedRetirementAge.retirementMonthlyIncome);
        setMontantPension(pension);
        total += pension;
      }

      if (assetState.hasFetched && hasFetchedAssets(assetIncomesState)) {
        retirementAdditionalIncome = roundDown10(
          computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)
        );
      }

      if (retirementAdditionalIncome > 0) {
        setMontantRevenusComplementaires(retirementAdditionalIncome);
        total += retirementAdditionalIncome;
      }
      if (
        hasFetchedDashboard(dashboardState) &&
        dashboardState.dashboard.firstMonthlyIncomeGoalInputDate &&
        dashboardState.dashboard.monthlyIncomeGoal !== undefined &&
        dashboardState.dashboard.monthlyIncomeGoal > total
      ) {
        setMontantTotal(dashboardState.dashboard.monthlyIncomeGoal);
        let totalRevenusAFinancer =
          dashboardState.dashboard.monthlyIncomeGoal - pension;
        totalRevenusAFinancer -= retirementAdditionalIncome;
        if (totalRevenusAFinancer > 0) {
          setMontantRevenusAFinancer(totalRevenusAFinancer);
        } else {
          setMontantRevenusAFinancer(undefined);
        }
      } else {
        setMontantTotal(total);
        setMontantRevenusAFinancer(undefined);
      }
    }
  }, [selectedRetirementAge, assetState, assetIncomesState, dashboardState]);

  return {
    montantTotal,
    montantPension,
    montantRevenusAFinancer,
    montantRevenusComplementaires,
    montantEffortEpargne,
    hasFetchedDashboardState,
    hasFetchedAssetIncomesState,
  };
};

export { useMonPlanMontants };
