import React, { FunctionComponent, useEffect, useState } from "react";
import { SGMediaQuery } from "sg-media-query";
import { ArticleTableauDesktop } from "./ArticleTableauDesktop";
import { ArticleTableauMobile } from "./ArticleTableauMobile";

export interface CelluleTableau {
    text: string;
    cols?: number[];
}

export const ArticleTableauFemmeExploitantAgricole: FunctionComponent = () => {
    const [colonnes, setColonnes] = useState<string[]>();
    const [donnees, setDonnees] = useState<CelluleTableau[][]>();

    useEffect(() => {
        setColonnes(["Conjoint collaborateur", "Conjoint associé", "Conjoint salarié"]);
        setDonnees([
            [
                {
                    text: "Statut marital",
                },
                {
                    text: "Marié, pacsé ou union libre avec l’exploitant",
                    cols: [1, 2, 3],
                }
            ],
            [
                {
                    text: "Durée par statut",
                },
                {
                    text: "5 ans maximum, puis transformation automatique du statut en conjoint salarié",
                },
                {
                    text: "Pas de durée maximale",
                    cols: [2, 3],
                },
            ],
            [
                {
                    text: "Conditions d’accès au statut de conjoint",
                },
                {
                    text: "<ul><li>Travailler régulièrement dans l’entreprise</li><li>Ne pas être rémunéré</li><li>Ne pas être associé</li></ul>",
                },
                {
                    text: "<ul><li>Travailler régulièrement dans l’entreprise</li><li>Détenir des parts sociales dans l’entreprise</li></ul>",
                },
                {
                    text: "<ul><li>Travailler régulièrement dans l’entreprise</li><li>Avoir un CDD ou un CDI avec l’entreprise</li><li>Toucher un salaire supérieur ou égal au Smic sur la base d’un plein temps</li></ul>",
                },
            ],
            [
                {
                    text: "Cumul avec d’autres activités",
                },
                {
                    text: "Cumul possible avec des activités salariées, indépendantes, la retraite",
                    cols: [1, 2, 3],
                },
            ],
            [
                {
                    text: "Rémunération du conjoint",
                },
                {
                    text: "Aucune",
                },
                {
                    text: "Dividendes",
                },
                {
                    text: "Au moins le Smic",
                },
            ],
            [
                {
                    text: "Droit au chômage",
                },
                {
                    text: "<ul><li>Pas d’assurance chômage</li><li>Maintien possible des allocations chômage si reprise d’une activité indépendante</li></ul>",
                },
                {
                    text: "Non sauf si cumul avec une activité salariée",
                },
                {
                    text: "Droit à l’assurance chômage si accord de Pôle Emploi",
                },
            ],
            [
                {
                    text: "Fin de statut de conjoint",
                },
                {
                    text: "<ul><li>Automatique au bout de 5 ans</li><li>Demande du conjoint collaborateur</li><li>Changement de statut de l’entreprise</li><li>Divorce ou cessation du PACS</li></ul>",
                },
                {
                    text: "Fin des droits sociaux",
                },
                {
                    text: "<ul><li>Fin de CDD</li><li>Démission</li><li>Licenciement</li></ul>",
                },
            ]
        ]);
    }, []);

    return colonnes && donnees ? (
        <>
            <SGMediaQuery minwidth="sm">
                <ArticleTableauDesktop titresColonnes={colonnes} donnees={donnees} />
            </SGMediaQuery>
            <SGMediaQuery minwidth={0} maxwidth="xs">
                <ArticleTableauMobile titresColonnes={colonnes} donnees={donnees} />
            </SGMediaQuery>
        </>
    ) : (
        <></>
    );
}
