import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, delay, put, race, select, takeLeading } from "redux-saga/effects";
import { globalError502 } from "store/error/errorsSlice";
import { FETCH_MEMBERS, Member } from "store/members/types";
import { BANK_DATA_FETCH_TIMEOUT_DELAY } from "../../globalParams";
import { parseAssetsAndLoans, parseBankAdvisor } from "../../website/utils/import/sg.import-service";
import { updateAccountAction } from "../account/actions";
import { AccountData } from "../account/types";
import { apiPost } from "../apiCaller";
import { assetsImportedFetched } from "../assets/assetsSlice";
import { AssetWithValuation, FETCH_ASSETS_WITHOUT_LOANS } from "../assets/types";
import { onDefaultError, safe } from "../error/utils";
import { loansImportedFetched } from "../loans/loansSlice";
import { FETCH_LOANS, Loan } from "../loans/types";
import { importFamilySuccess } from "../members/membersSlice";
import { importLoans } from "./actions";
import { importDataUpdated, importMembersSuccess, updateIsImporting } from "./importSlice";
import {
   BankAdvisorState,
   IMPORT_ASSETS_AND_MEMBERS,
   IMPORT_LOANS,
   IMPORT_MEMBERS,
   ImportMember, ImportState,
   RawAssetAndLoansState,
   RawMember,
   SAVE_ADVISOR,
   START_IMPORT,
   UPDATE_IMPORT_DATA
} from "./types";
import { getBankPromiseForLoansAndBankAdvisor, getEnvironment } from "./utils";

export function* watcherSaga() {
   yield takeLeading(SAVE_ADVISOR, safe(onDefaultError, handleSaveAdvisor));
   yield takeLeading(UPDATE_IMPORT_DATA, safe(onDefaultError, handleUpdateImportData));
   // MCR: Erreur fatale si problème lors de l'import
   yield takeLeading(IMPORT_ASSETS_AND_MEMBERS, safe(onApiCallError, handleImportAssetAndMembers));
   yield takeLeading(IMPORT_LOANS, safe(onApiCallError, handleImportLoans));
   yield takeLeading(IMPORT_MEMBERS, safe(onDefaultError, handleImportMembers));
   yield takeLeading(START_IMPORT, safe(onDefaultError, handleImportScrapping));
}

function* onApiCallError(err: any) {
   // Pour la redirection vers la page de maintenance
   console.error("Erreur fatale import", err);
   yield put(globalError502());
}

function* handleUpdateImportData(action: PayloadAction<ImportState>) {
   yield put(importDataUpdated(action.payload));
}

function* handleSaveAdvisor(action: PayloadAction<Partial<BankAdvisorState>>) {
   yield call(apiPost, `wealth/api/sg-import/bank-advisor`, action.payload);
}

function* handleImportMembers(action: PayloadAction<RawMember | undefined>) {
   const payload: AxiosResponse<Member[]> = yield call(apiPost, `wealth/api/sg-import/member`, action.payload);
   yield put(importFamilySuccess(payload.data));
   yield put(importMembersSuccess());
}

function civiliteTransco(civilite: string) {
   switch (civilite) {
      case "M":
      case "L":
         return "Mme";
      case "R":
         return "M.";
      default:
         return civilite;
   }
}

function* handleImportAssetAndMembers(action: PayloadAction<{
   disponibiliteParcoursRevenusComplementaires: boolean,
   skipImportMembers: boolean,
}>) {
   let account: AccountData = yield select((state) => state.account.account);

   if (!action.payload.skipImportMembers) {
      const dataAccount: AxiosResponse<ImportMember> = yield call(apiPost, `wealth/api/sg-import/init/members`);
      // MCR: Patch pour alimenter l'utilisateur
      if (dataAccount.status === 200 && !account.lastName) {
         account = {
            ...account,
            firstName: dataAccount.data.prenom ?? "",
            lastName: dataAccount.data.nom ?? "",
            title: civiliteTransco(dataAccount.data.civilite ?? ""),
         }
      }
      account = { ...account, potentielSousSegmentNSM: dataAccount.data.potentielSousSegmentNSM, codeSegmentMarcheNSM: dataAccount.data.codeSegmentMarcheNSM }
      yield put(updateAccountAction(account));
      yield put({ type: FETCH_MEMBERS });
      // MCR: Patch en cas de problème de récupération de l'utilisateur
      if (!dataAccount.data.dateNaissance) {
         throw new Error("Erreur : l'utilisateur n'est pas récupéré (reconciliation)");
      }
   }

   // On est en supervision, on n'importe pas les datas
   if (action.payload.disponibiliteParcoursRevenusComplementaires) {
      const payload: AxiosResponse<AssetWithValuation[]> = yield call(apiPost, `wealth/api/sg-import/init/assets?createIfNotExist=${!account.assetsImported}`);
      yield put(assetsImportedFetched(payload.data));
   }

   yield put({ type: FETCH_ASSETS_WITHOUT_LOANS });
}

/**
 * Parse les loans depuis le scrapping.
 * TODO : Faudrait-il s'assurer d'avoir appelé handleImportAssetAndMembers au moins une fois, avant ?
 * @param action Assets et loans issus du scrapping
 */
function* handleImportLoans(action: PayloadAction<{
   rawAssetAndLoansState: RawAssetAndLoansState,
}>) {
   let account: AccountData = yield select((state) => state.account.account);
   const payload: AxiosResponse<{ assets: AssetWithValuation[], loans: Loan[], pretConsos: Loan[] }> = yield call(apiPost, `wealth/api/sg-import/assets-and-loans?createIfNotExist=${!account.assetsImported}`, action.payload.rawAssetAndLoansState);
   yield put({ type: FETCH_ASSETS_WITHOUT_LOANS });

   yield put(loansImportedFetched(payload.data.loans.concat(payload.data.pretConsos)));

   // MCR: Patch pour récupérer l'account mis à jour plus haut
   account = yield select((state) => state.account.account);
   if (payload.status === 200 && !account.assetsImported) {
      // A voir si on ne fait pas ça qu'après avoir eu les prêts, via scrapping
      yield put(updateAccountAction({ ...account, assetsImported: true }));
   }
   yield put({ type: FETCH_ASSETS_WITHOUT_LOANS });
   yield put({ type: FETCH_LOANS });
}

function* racePromise<Type>(promise: Promise<Type>, timeoutMs: number) {
   const { data, timeoutPromise } = yield race({
      data: call(() => promise),
      timeoutPromise: delay(timeoutMs)
   });

   return { data, timeout: !!timeoutPromise };
}

function* handleImportScrapping(action: PayloadAction<{ host: string, destroyScript: () => void }>) {
   const { host } = action.payload;
   const env = getEnvironment();

   try {
      const { data: bankData, timeout } = yield racePromise(getBankPromiseForLoansAndBankAdvisor(env), BANK_DATA_FETCH_TIMEOUT_DELAY);
      if (!timeout && host) {
         // Avec Scrapping
         // C'est dans parseAssetsAndLoans que la variable de session "assetsAndLoans" va être mise à jour
         yield put(importLoans(parseAssetsAndLoans(host, bankData)));
         yield call(handleSaveAdvisor, { type: SAVE_ADVISOR, payload: parseBankAdvisor(host, bankData) });
      }
   } catch (e: any) {
      console.warn(e.message); // non blocking error
   }

   yield put(updateIsImporting(false));
}
