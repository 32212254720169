import { MEMBERS_FETCH_TIMEOUT_DELAY } from "globalParams";
import { call, delay, put, race, takeLeading } from "redux-saga/effects";
import { safe } from "store/error/utils";
import { getEnvironment } from "store/import/utils";
import { downloadData, parsePrivateData } from "website/utils/import/sg.import-service";
import {
   PrivateDataState,
   PRIVATE_DATA_FETCHED,
   START_IMPORT_PRIVATE_DATA
} from "./types";

export function* watcherSaga() {
   // undefined => On ignore l'erreur
   yield takeLeading(START_IMPORT_PRIVATE_DATA, safe(undefined, handleImportPrivateData));
}

function* handleImportPrivateData(action: any) {
   const {host} = action.payload;
   const env = getEnvironment();
   console.warn(`current env: ${env}`);

   const {memberData} = yield race({
      memberData: call(() => downloadData(env, ["dcr-donnees-personnelles.html"])),
      timeout: delay(MEMBERS_FETCH_TIMEOUT_DELAY)
   });

   const privateData: PrivateDataState = parsePrivateData(host, memberData);

   yield put({ type: PRIVATE_DATA_FETCHED, payload: privateData });
}
