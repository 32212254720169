import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFamilyAction } from "store/members/actions";
import {
  CustodyType,
  Family,
  FamilyStatus,
  MaritalStatus,
  MatrimonialRegime,
  Me,
  Member,
} from "store/members/types";
import {
  isCoupleMaritalStatus,
  isIsolatedParentStatus,
} from "store/members/utils";
import { updateParameters } from "store/simulateurAvantApres/slice";
import { ParametresSimulateurAvantApres } from "store/simulateurAvantApres/types";
import { genreTransco, toStringDate } from "store/simulateurAvantApres/utils";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters } from "store/simulationAssu/types";
import { State } from "store/store";
import { nextStepAction, previousStepAction } from "store/tunnel/actions";
import {
  PAGE_CATEGORY_3_ONBOARDING,
  PAGE_NAME_MA_SITUATION_PERSONNELLE,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAccount } from "website/components/hooks/useAccount";
import { useFamily } from "website/components/hooks/useFamily";
import { formatDateSimulation } from "website/utils/date/DateUtils";
import { FamilySituation } from "../../../FamilySituation/FamilySituation";

export const TunnelFamilySituation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const family = useFamily();
  const account = useAccount();

  const [numberChild, setNumberChild] = useState(0);
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus>(
    MaritalStatus.SINGLE
  );
  const [dateOfBirth, setDateOfBirth] = useState<Date>();
  const parametres = useSelector<State, ParametresSimulateurAvantApres>(
    (state) => state.simulateurAvantApres.parametres
  );
  const { trackPage, trackClick } = useTracking();

  useEffect(() => {
    trackPage(
      PAGE_CATEGORY_3_ONBOARDING,
      PAGE_NAME_MA_SITUATION_PERSONNELLE,
      PAGE_TYPE_FORMULAIRE,
      "inscription",
      "2"
    );
  }, []);

  const onSubmit = () => {
    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    const newMe: Me = {
      birthday: dateOfBirth || new Date("1970-01-01"),
      name: "Moi même",
      status: FamilyStatus.ME,
      maritalStatus,
      retirementType: family.me.retirementType,
    } as Me;
    const familyToDispatch: Family = {
      children: [],
      me: {
        ...newMe,
        matrimonialRegime:
          MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS,
      },
      relatives: [],
    };
    let ordinal = 0;
    for (let i = 0; i < numberChild; i += 1) {
      ordinal += 1;
      const custodyType =
        isIsolatedParentStatus(maritalStatus) ||
        maritalStatus === MaritalStatus.WIDOWER
          ? CustodyType.FULL_CUSTODY_ME
          : CustodyType.FULL_CUSTODY;
      const child: Member = {
        name: `Mon enfant ${ordinal}`,
        status: FamilyStatus.CHILD,
        custodyType,
      } as Member;
      familyToDispatch.children.push(child);
    }
    if (isCoupleMaritalStatus(maritalStatus)) {
      const partner: any = {
        ...family.partner,
        matrimonialRegime:
          MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS,
      };
      familyToDispatch.partner = partner;
    }

    const retirementSimulationParametersToDispatch: Partial<RetirementSimulationParameters> =
      {
        nbEnfant: numberChild,
        dateNaissance: dateOfBirth
          ? formatDateSimulation(dateOfBirth)
          : "01-01-1970",
      };

    trackClick("clic-sur-suivant");
    dispatch(
      updateRetirementSimulation(retirementSimulationParametersToDispatch)
    );
    // Enregistrement des données de l'étape 3 en BDD
    dispatch(updateFamilyAction(familyToDispatch, true, true));
    // Mise à jour des parametres du simulateur avant/apres
    dispatch(
      updateParameters({
        ...parametres,
        nbEnfants: numberChild,
        dateNaissance: toStringDate(family.me.birthday, "-"),
        genre: genreTransco(account.title),
      })
    );
    dispatch(nextStepAction());
  };

  return (
    <FamilySituation
      onSubmit={onSubmit}
      numberChild={numberChild}
      setNumberChild={setNumberChild}
      setDateOfBirth={setDateOfBirth}
      setMaritalStatus={setMaritalStatus}
      onClickReturn={() => {
        trackClick("clic-sur-retour");
        dispatch(previousStepAction());
      }}
      texteRetour="tunnel.buttons.previous"
    />
  );
};
