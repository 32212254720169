export interface WorkIncomesHistory {
   id?: number;
   currentYearlyAmount?: number;
   carrierStartDate?: Date;
   pastYearlyIncrease?: WorkIncomeRaisesType;
   expectedYearlyIncrease?: WorkIncomeRaisesType;
}

export interface WorkIncomesHistoryState {
   workIncomesHistory: WorkIncomesHistory;
}

export const hasFetchedWorkIncomesHistory = (workIncomesHistoryState: WorkIncomesHistoryState): boolean =>
   workIncomesHistoryState.workIncomesHistory !== undefined;

export const FETCH_WORK_INCOMES_HISTORY = "FETCH_WORK_INCOMES_HISTORY";
export const WORK_INCOMES_HISTORY_FETCHED = "WORK_INCOMES_HISTORY_FETCHED";
export const SAVE_WORK_INCOMES_HISTORY = "SAVE_WORK_INCOMES_HISTORY";
export const WORK_INCOMES_HISTORY_SAVED = "WORK_INCOMES_HISTORY_SAVED";
export const UPDATE_WORK_INCOMES_HISTORY_STORE = "UPDATE_WORK_INCOMES_HISTORY_STORE";

export enum WorkIncomeRaisesType {
   NO_WORK_INCOME_RAISES = "NO_WORK_INCOME_RAISES",
   LOW_WORK_INCOME_RAISES = "LOW_WORK_INCOME_RAISES",
   HIGH_WORK_INCOME_RAISES = "HIGH_WORK_INCOME_RAISES",
}

export enum WorkIncomeRaisesTypeSimulation {
   NO_WORK_INCOME_RAISES = "1",
   LOW_WORK_INCOME_RAISES = "2",
   HIGH_WORK_INCOME_RAISES = "3",
}
