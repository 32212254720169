import {AssetMemberLink} from "../assets/types";

export interface LoanState {
   loans: Loan[];
   loansImported: Loan[]; // Contient les prêts existant existant sur le site de la Société Générale
   hasFetched: boolean;
}

export interface Loan {
   id: number;
   externalId: string;
   clientId: number;
   assetId: number;
   name: string;
   monthlyAmount: number;
   totalAmount: number;
   displayedAmount: number;
   remainingPrincipal: number;
   startDate: Date | string;
   durationInMonth: number;
   interestRate: number;
   associatedTo: number;
   valuationDate: Date;
   amortizationType: AmortizationType;
   loanType: LoanType;
   assetMemberLinkSet: Array<AssetMemberLink>;
}

export enum AmortizationType {
   AMORTIZATION = "AMORTIZATION", // crédit amortissable
   IN_FINE = "IN_FINE", // crédit in fine
}

export enum LoanType {
   REAL_ESTATE = "REAL_ESTATE", // real estate
   CONSUMER = "CONSUMER", // crédit conso
   STUDENT = "STUDENT", // prêt étudiant
}

export interface LoanData {
   loanAmount: number;
   displayedAmount: number;
   monthlyPayments: number;
   durationInMonth: number;
   annualRate: number;
   loanType: LoanType;
   amortizationType: AmortizationType;
}

// actions
export const FETCH_LOANS = "FETCH_LOANS";
export const LOANS_FETCHED = "LOANS_FETCHED";
export const LOANS_IMPORTED_FETCHED = "LOANS_IMPORTED_FETCHED";
export const UPDATE_LOAN = "UPDATE_LOAN";
export const LOAN_UPDATED = "LOAN_UPDATED";
export const LOAN_CREATED = "LOAN_CREATED";
export const DELETE_LOAN = "DELETE_LOAN";
export const LOAN_DELETED = "LOAN_DELETED";
