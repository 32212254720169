import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGCheckBox, SGCheckBoxGroup } from "sg-checkbox";
import { SGText } from "sg-typo";
import { Question } from "store/personnalisation/questions/types";

interface BesoinsInputProps {
  questions: Question[];
  questionsIds: number[];
  setQuestionsIds: (value: number[]) => void;
}

export const BesoinsInput: FunctionComponent<BesoinsInputProps> = (
  props: BesoinsInputProps
) => {
  const { questions, questionsIds, setQuestionsIds } = props;

  return (
    <SGCheckBoxGroup
      placeholder="Sélectionnez"
      value={questionsIds}
      onChange={(value: number[]) => {
        setQuestionsIds(value);
      }}
      legend={
        <SGText size="l">
          <FormattedMessage id="tunnel.besoin.question.label" />
        </SGText>
      }
      buttonstyle="tile"
      layout="column"
    >
      {questions.map((value: Question) => (
        <SGCheckBox key={value.id} value={value.id}>
          <FormattedMessage id={`tunnel.besoin.question.${value.label}`} />
        </SGCheckBox>
      ))}
    </SGCheckBoxGroup>
  );
};
