import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGTile } from "sg-tile";
import { SGPriceCommercial, SGText, SGTitle } from "sg-typo";

export enum NomCarteEnum {
  PATRIMOINE = "patrimoine",
  PENSION = "pension",
  BESOINS = "besoins",
}

export enum TypeContenuCarteEnum {
  DESCRIPTION = "description",
  MONTANT = "montant",
}

interface CarteDonneeProps {
  nom: NomCarteEnum;
  isActif?: boolean;
  tagColor: string;
  typeContenu: TypeContenuCarteEnum;
  montant?: number;
  onClick: () => void;
  lien?: string;
  disponibiliteFeatureFlipping?: boolean;
  showSkeleton?: boolean;
}

const CarteDonnee: FunctionComponent<CarteDonneeProps> = (
  props: React.PropsWithChildren<CarteDonneeProps>
) => {
  const {
    nom,
    isActif = false,
    typeContenu,
    tagColor,
    montant,
    onClick,
    lien,
    disponibiliteFeatureFlipping = false,
    showSkeleton = false,
    children,
  } = props;
  const intl = useIntl();

  const action = isActif ? "actif" : "inactif";

  const boutonLien = () => {
    if (lien) {
      return (
        <SGLink
          type="tertiary"
          icon={
            <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
          }
          iconside="right"
          href={lien}
          onClick={onClick}
        >
          {intl.formatMessage({
            id: `dashboard.carteDonnee.${nom}.${action}.button`,
          })}
        </SGLink>
      );
    }

    return (
      <SGButton
        type="tertiary"
        icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
        iconposition="right"
        onClick={onClick}
      >
        {intl.formatMessage({
          id: `dashboard.carteDonnee.${nom}.${action}.button`,
        })}
      </SGButton>
    );
  };

  return (
    <SGTile clickable>
      <SGSpace direction="vertical" disableautomargin size="sm">
        <SGTag key="tag" tagcolor={tagColor} border>
          {intl.formatMessage({ id: `dashboard.carteDonnee.${nom}.tag` })}
        </SGTag>
        <SGTitle key="titre" visuallevel={5} level={3}>
          {intl.formatMessage({
            id: `dashboard.carteDonnee.${nom}.${action}.titre`,
          })}
        </SGTitle>
        {showSkeleton ? (
          <SGSkeleton key="skeleton" title={false} />
        ) : (
          [
            typeContenu === TypeContenuCarteEnum.DESCRIPTION && (
              <SGText key="description">
                {intl.formatMessage({
                  id: `dashboard.carteDonnee.${nom}.description`,
                })}
              </SGText>
            ),
            typeContenu === TypeContenuCarteEnum.MONTANT && (
              <SGPriceCommercial
                key="prix"
                value={montant?.toLocaleString("fr-FR") || "--"}
                size="xl"
                layout="l2"
                term="mois (net)"
              />
            ),
            children,
          ]
        )}
        <SGBox key="box" textalign="right">
          {disponibiliteFeatureFlipping ? (
            boutonLien()
          ) : (
            <SGText>
              {intl.formatMessage({
                id: "dashboard.patrimoine.indisponible",
              })}
            </SGText>
          )}
        </SGBox>
      </SGSpace>
    </SGTile>
  );
};

export { CarteDonnee };
