import React, {FunctionComponent} from "react";
import {Login} from "../components/organisms/Utils/Login/Login";
import {SGContent} from "sg-layout";

const LoginPage: FunctionComponent = () => (
   <SGContent span={8}>
      <Login/>
   </SGContent>
);

export {LoginPage};
