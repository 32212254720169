import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {parseISO, startOfDay} from "date-fns";
import {useIntl} from "react-intl";
import {SGInputDate} from "sg-input";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {SGTextIntl} from "../SGTextIntl/SGTextIntl";
import {SGPopover} from "sg-popover";
import {SGButton} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirStatusInfo} from "sg-icon-pack-base";

interface DateInputProps {
   label: string;
   tooltipLabel?: string;
   tooltipTransformations?: Record<string, string | React.ReactNode>;
   defaultValue?: Date | string;
   name: string;
   placeholder?: string;
   required?: boolean;
   rangeMessage?: string;
   disabled?: boolean;
   pattern?: DatePattern;
   min?: Date | string;
   max?: Date | string;
   minYear?: number;
   maxYear?: number;
   onDateChange?: (date: Date) => void;
   cypressName: string;
}

export enum DatePattern {
   DAYS = "dd/MM/yyyy",
   MONTHS = "MM/yyyy",
   YEARS = "yyyy",
}

const toDate = (value: Date | string | undefined): Date | undefined => {
   if (!value) {
      return undefined;
   }
   if (Object.prototype.toString.call(value) === "[object Date]") {
      return Number.isNaN(new Date(value).getTime()) ? undefined : new Date(value);
   }
   if (typeof value === "string") {
      const date = parseISO(value);

      return Number.isNaN(date.getTime()) ? undefined : date;
   }

   return undefined;
};

const isWithinAllowedPeriod = (
   value: Date | string,
   formValues: {
      minValue?: Date | null,
      maxValue?: Date | null
   }
): boolean => {
   const parsedSelectedDate = toDate(value);
   if (!parsedSelectedDate) {
      return false;
   }

   const {minValue, maxValue} = formValues;

   const minOk =
      !minValue ||
      parsedSelectedDate.getFullYear() > minValue.getFullYear() ||
      (parsedSelectedDate.getFullYear() === minValue.getFullYear() &&
         parsedSelectedDate.getMonth() > minValue.getMonth()) ||
      (parsedSelectedDate.getFullYear() === minValue.getFullYear() &&
         parsedSelectedDate.getMonth() === minValue.getMonth() &&
         parsedSelectedDate.getDay() >= minValue.getDay());

   const maxOk =
      !maxValue ||
      parsedSelectedDate.getFullYear() < maxValue.getFullYear() ||
      (parsedSelectedDate.getFullYear() === maxValue.getFullYear() &&
         parsedSelectedDate.getMonth() < maxValue.getMonth()) ||
      (parsedSelectedDate.getFullYear() === maxValue.getFullYear() &&
         parsedSelectedDate.getMonth() === maxValue.getMonth() &&
         parsedSelectedDate.getDay() <= maxValue.getDay());

   return minOk && maxOk;
};

const DateInput = ({
                      label, tooltipLabel, tooltipTransformations, defaultValue, name, placeholder,
                      required, rangeMessage, disabled, pattern, min, max, minYear, maxYear, onDateChange, cypressName,
                   }: DateInputProps) => {
   const {control, formState: { errors } } = useFormContext();
   const intl = useIntl();
   const pickerDefaultValue = !defaultValue ? undefined : toDate(defaultValue);

   const minYearValue = (minYear && new Date(`${minYear}-01-01`)) || null;
   const maxYearValue = (maxYear && new Date(`${maxYear}-01-01`)) || null;

   const minValue = toDate(min) || minYearValue;
   const maxValue = toDate(max) || maxYearValue;

   const validationRules: RegisterOptions = {};

   if (!disabled) {
      validationRules.required = required === undefined || true;
      if (minValue || maxValue) {
         validationRules.validate = isWithinAllowedPeriod;
      } else if (minYear || maxYear) {
         validationRules.validate = (value: number): boolean => {
            const minOk = !minYear || value >= minYear;
            const maxOk = !maxYear || value <= maxYear;

            return minOk && maxOk;
         };
      }
   }

   const computeErrorMessage = (): string => {
      const error = errors[name];
      if (error) {
         switch (error.type) {
            case "validate":
               return intl.formatMessage({id: rangeMessage || "common.validation.date.range"});
            case "required":
            default:
               return intl.formatMessage({id: "common.required"});
         }
      }

      return "";
   }

   const computeLabelNode = (): React.ReactNode => {
      if (tooltipLabel) {
         return <>
            <SGTextIntl intlId={label}/>
            <SGPopover trigger="hover" content={<SGTextIntl intlId={tooltipLabel} transformations={tooltipTransformations}/>} placement="topLeft" onOpenChange={() => true}>
               <SGButton type="icon" icon={<SGIcon component={<SGAvenirStatusInfo/>}/>} {...{tabIndex: -1}} />
            </SGPopover>
         </>
      }

      return <SGTextIntl intlId={label}/>;
   }

   return (
      <Controller control={control} name={name} rules={validationRules} defaultValue={pickerDefaultValue || null}
                  render={({field, fieldState}) => (
                     <SGInputDate
                        label={computeLabelNode()}
                        {...field}
                        onChange={(value: Date) => {
                           if (value != null) {
                              const atStart = startOfDay(value);
                              field.onChange(atStart);
                              onDateChange?.(atStart);
                           }
                        }}
                        selected={field.value}
                        dateFormat={pattern || DatePattern.MONTHS}
                        placeholder={placeholder && intl.formatMessage({id: placeholder})}
                        showYearPicker={pattern === DatePattern.YEARS} showMonthYearPicker={pattern === DatePattern.MONTHS}
                        showYearDropdown={pattern !== DatePattern.YEARS} showMonthDropdown={pattern === DatePattern.DAYS}
                        minDate={minValue} maxDate={maxValue} disabled={disabled}
                        status={(errors && errors[name]) ? "error" : "default"}
                        validate={computeErrorMessage()} data-cy={cypressName} id={cypressName}
                     />
                  )}
      />
   );
};

export {DateInput};
