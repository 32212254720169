import { Fragment, FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedAppeler,
  SGAvenirStrokedNousVousRappellons,
} from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { SGPhoneNumberBanalise } from "sg-phone-number";
import { SGBox, SGSpace } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { isWebView } from "store/import/utils";
import { State } from "store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useDroitsSegment } from "website/components/hooks/useDroitsSegment";
import { useFeatures } from "website/components/hooks/useFeatures";
import { externalTel } from "website/utils/externalTel";

export const AccompagnementTelephonique: FunctionComponent = () => {
  const intl = useIntl();
  const { trackClick } = useTracking();
  const {isWebCallbackAvailable} = useDroitsSegment();
  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );
  const urlWebCallback = window.env.REACT_APP_WEBCALLBACK_URL;
  const { disponibiliteAppeler, disponibiliteWebCallback } = useFeatures();

  const getBlock = (
    icon: any,
    type: "appeler" | "webCallback",
    lien: JSX.Element
  ) => (
    <SGGridRow key="row" justify="center" gutter={[16, 0]}>
      <SGGridCol flex="0 0 0%" align="start">
        {icon}
      </SGGridCol>
      <SGGridCol flex="1 0 0%">
        <SGSpace direction="vertical" disableautomargin size="md">
          <SGText key="text" size="s" whitespace="pre-line">
            {intl.formatMessage({
              id: `accompagnementTelephonique.${type}.titre`,
            })}
            {type === "appeler" && (
              <SGBox margin={{ top: "sm" }}>
                {intl.formatMessage(
                  { id: `accompagnementTelephonique.${type}.titre.horaire` },
                  {
                    strong: (word) => <SGText strong>{word}</SGText>,
                  }
                )}
              </SGBox>
            )}
          </SGText>
          <SGBox key="box" textalign="center">
            {lien}
          </SGBox>
        </SGSpace>
      </SGGridCol>
    </SGGridRow>
  );

  return (
    <Fragment key="accompagnementTelephonique">
      {isWebCallbackAvailable && (
        <SGTile clickable>
          <SGBox padding={{ top: "md", bottom: "md", right: "md", left: "md" }}>
            <SGGridRow justify="center" gutter={[0, 24]}>
              <SGGridCol span={12} textalign="center">
                <SGTitle level={2} visuallevel={4}>
                  {intl.formatMessage({
                    id: "accompagnementTelephonique.titre",
                  })}
                </SGTitle>
              </SGGridCol>
              {disponibiliteAppeler && (
                <SGGridCol span={12} sm={disponibiliteWebCallback ? 5 : 8}>
                  {getBlock(
                    <SGIcon
                      component={<SGAvenirStrokedAppeler />}
                      currentcolor
                      disableautomargin
                    />,
                    "appeler",
                    <SGBox
                      key="box"
                      inline
                      onClick={() => trackClick("clic-sur-appeler-3944")}
                    >
                      <SGPhoneNumberBanalise
                        format="short"
                        number={externalTel.EXTERNAL_TEL_APPELER}
                      />
                    </SGBox>
                  )}
                </SGGridCol>
              )}
              {disponibiliteWebCallback && (
                <SGGridCol span={12} sm={disponibiliteAppeler ? 5 : 8}>
                  {getBlock(
                    <SGIcon
                      component={<SGAvenirStrokedNousVousRappellons />}
                      currentcolor
                      disableautomargin
                    />,
                    "webCallback",
                    estSupervisionActive ? (
                      <SGButton key="bouton" type="link" disabled>
                        {intl.formatMessage({
                          id: "accompagnementTelephonique.webCallback.lien",
                        })}
                      </SGButton>
                    ) : (
                      <SGLink
                        key="lien"
                        href={urlWebCallback}
                        target={isWebView() ? "_self" : "_blank"}
                        onClick={() =>
                          trackClick("clic-sur-je-souhaite-etre-rappele")
                        }
                      >
                        {intl.formatMessage({
                          id: "accompagnementTelephonique.webCallback.lien",
                        })}
                      </SGLink>
                    )
                  )}
                </SGGridCol>
              )}
            </SGGridRow>
          </SGBox>
        </SGTile>
      )}
    </Fragment>
  );
};
