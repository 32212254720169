import { ReactComponent as LinkImage } from "assets/images/link-image-black.svg";
import { FunctionComponent, SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import { SGLink } from "sg-link";
import { SGBox } from "sg-space";
import { AccordeonQuestions } from "website/components/atoms/AccordeonQuestions/AccordeonQuestions";
import { useOpenLink } from "website/components/hooks/useOpenLink";

export interface FaqTunnelProps {
  questionsKey: string[];
  questionDefautKey?: string;
  titre: string;
}

const FaqTunnel: FunctionComponent<FaqTunnelProps> = (
  props: FaqTunnelProps
) => {
  const { questionsKey, questionDefautKey, titre } = props;
  const intl = useIntl();
  const openLink = useOpenLink();

  return (
    <AccordeonQuestions
      questionsKey={questionsKey}
      questionDefautKey={questionDefautKey}
      titre={titre}
      transformations={{
        p: (word: string) => (
          <SGBox padding={{ top: "sm" }}>
            <>{word}</>
          </SGBox>
        ),
        sga: (word: string) => {
          const link = window.env.REACT_APP_BDDF_URL;

          return (
            <SGLink
              key="lien-sga"
              icon={<LinkImage />}
              iconside="right"
              href={link}
              onClick={(event: SyntheticEvent) => openLink(link, event)}
            >
              {word}
            </SGLink>
          );
        },
        pers: (word: string) => {
          const link =
            window.env.REACT_APP_BDDF_URL +
            intl.formatMessage({ id: "link.rgpd" });

          return (
            <SGLink
              key="lien-pers"
              icon={<LinkImage />}
              iconside="right"
              href={link}
              onClick={(event: SyntheticEvent) => openLink(link, event)}
            >
              {word}
            </SGLink>
          );
        },
      }}
    />
  );
};

export { FaqTunnel };
