import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { CheckList } from "../components/organisms/MonPlan/CheckList";

interface CheckListPageParams {
    checkListType: string;
}

const CheckListPage: FunctionComponent = () => {
    const { checkListType } = useParams<CheckListPageParams>();

    return <CheckList type={checkListType} />;
};

export { CheckListPage };
