import React, { PropsWithChildren, useState } from "react";

export type PauseVideoContextType = {
   pauseVideo?: boolean;
   setPauseVideo: (pauseVideo: boolean) => void;
   pauseVideoCarrousel?: boolean;
   setPauseVideoCarrousel: (pauseVideoCarrousel: boolean) => void;
};

export const PauseVideoContext = React.createContext<PauseVideoContextType>({
   pauseVideo: false, setPauseVideo: (() => null), pauseVideoCarrousel: false, setPauseVideoCarrousel: (() => null)
});

export const PauseVideoProvider = ({children}: PropsWithChildren<Record<never, never>>) => {
   const [pauseVideo, setPauseVideo] = useState<boolean>();
   const [pauseVideoCarrousel, setPauseVideoCarrousel] = useState<boolean>();

   return (
      <PauseVideoContext.Provider value={{pauseVideo, setPauseVideo, pauseVideoCarrousel, setPauseVideoCarrousel}}>
         {children}
      </PauseVideoContext.Provider>
   );
}
