import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { SGCard, SGCardMeta } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGText, SGTitle } from "sg-typo";
import { estAgeDepartAnticipeCarriereLongueSapiendo, estAgeDepartAnticipeRaisonSanteSapiendo, estAgeDepartAnticipeSapiendo } from "store/parcoursRIS/utils";
import { RetirementAge, RetirementTypes } from "store/parcoursRisProjection/types";
import { formatDate, getDureeEntreDeuxDates } from "website/utils/date/DateUtils";

type CarteEstimationDepartAnticipeProps = {
    dates: RetirementAge[];
};

export const CarteEstimationDepartAnticipe: FunctionComponent<CarteEstimationDepartAnticipeProps> = (
    props: React.PropsWithChildren<CarteEstimationDepartAnticipeProps>
) => {
    const { dates } = props;

    const intl = useIntl();
    /**
     * L'âge de départ anticipé le plus favorable.
     */
    const ageDepartAnticipe = useMemo(() => {
        const agesDepartAnticipeList: RetirementAge[] = dates.filter(estAgeDepartAnticipeSapiendo);

        return agesDepartAnticipeList.length > 0 ? agesDepartAnticipeList[0] : undefined;
    }, [dates]);
    /**
     * Calcule la différence de durée entre l'âge légal et le départ anticipé le plus favorable ({annee, mois}).
     */
    const periode = useMemo(() => {
        const ageLegal: RetirementAge | undefined = dates.find((date) => date.types_depart.includes(RetirementTypes.AGE_LEGAL));
        if (ageLegal && ageDepartAnticipe) {
            return getDureeEntreDeuxDates(ageLegal.date, ageDepartAnticipe.date, "-");
        }

        return undefined;
    }, [dates, ageDepartAnticipe]);

    const carriereLongue = useMemo(() => ageDepartAnticipe && estAgeDepartAnticipeCarriereLongueSapiendo(ageDepartAnticipe), [ageDepartAnticipe]);
    const raisonSante = useMemo(() => ageDepartAnticipe && estAgeDepartAnticipeRaisonSanteSapiendo(ageDepartAnticipe), [ageDepartAnticipe]);

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

    return (
        <>
            {ageDepartAnticipe && (
                <SGLayout component="div">
                    <SGBox span={8} textalign="center">
                        <SGCard borderleft="7px solid #9F2D62 !important">
                            <SGCardMeta
                                title={
                                    <SGTitle level={2} visuallevel={4}>
                                        {intl.formatMessage({
                                            id: `parcoursRIS.resultat.carteDepartAnticipe.titre.${
                                                // Raison santé en premier, si les deux sont présents
                                                raisonSante ? "raisonSante" : "carriereLongue"
                                            }`,
                                        })}
                                        {raisonSante && carriereLongue && (
                                            <>
                                                {" = "}
                                                {intl.formatMessage({
                                                    id: "parcoursRIS.resultat.carteDepartAnticipe.titre.carriereLongue",
                                                })}
                                            </>
                                        )}
                                    </SGTitle>
                                }
                                description={
                                    <SGSpace size="sm" direction="vertical" disableautomargin>
                                        <SGText key="text-1">
                                            <SGText weight="600" size="l">
                                                {ageDepartAnticipe.age.years} ans
                                                {ageDepartAnticipe.age.months > 0 && ` et ${ageDepartAnticipe.age.months} mois`}
                                            </SGText>
                                            <SGText size="xs" color="lighter">
                                                {` en ${formatDate(ageDepartAnticipe.date, "-").toLocaleDateString("fr-fr", { year: "numeric", month: "long" })}`}
                                            </SGText>
                                        </SGText>
                                        {periode && (
                                            <SGTag key="tag" tagcolor="45">
                                                {periode.annee > 0 && `${periode.annee} ans`} {periode.annee > 0 && periode.mois > 0 && " et "}
                                                {periode.mois > 0 && `${periode.mois} mois`} plus tôt que l’âge légal
                                            </SGTag>
                                        )}

                                        {raisonSante && (
                                            <SGBox key="box" padding={{ top: isPhone ? "sm" : "xs" }}>
                                                <SGDivider />
                                            </SGBox>
                                        )}
                                        { // On doit séparer si on veut un espace entre chaque élément du SGSpace
                                        raisonSante && (
                                            <SGText key="text-2">{intl.formatMessage({ id: "parcoursRIS.resultat.carteDepartAnticipe.texte" })}</SGText>
                                        )}
                                    </SGSpace>
                                }
                            />
                        </SGCard>
                    </SGBox>
                </SGLayout>
            )}
        </>
    );
};
