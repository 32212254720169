import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxEconomySimulationParams } from "store/simulateurEconomiesImpots/actions";
import { SimulateurEconomiesImpotsState } from "store/simulateurEconomiesImpots/types";
import { State } from "store/store";

const useSimulateurEconomiesImpotsState = () => {
   const dispatch = useDispatch();
   const simulateurEconomiesImpotsState: SimulateurEconomiesImpotsState = useSelector<State, SimulateurEconomiesImpotsState>(
      (state) => state.simulateurEconomiesImpots
   );

   useEffect(() => {
      if (!simulateurEconomiesImpotsState.hasFetched) {
         dispatch(fetchTaxEconomySimulationParams());
      }
   }, [simulateurEconomiesImpotsState.hasFetched]);

   return simulateurEconomiesImpotsState;
};

export { useSimulateurEconomiesImpotsState };
