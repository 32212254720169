import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGText } from "sg-typo";
import "./Revenus.scss";

interface RevenusProps {
  montantTotal?: number;
  montantPension?: number;
  montantRevenusComplementaires?: number;
  montantRevenusAFinancer?: number;
  assetsLength?: number;
}

const Revenus: FunctionComponent<RevenusProps> = (props: RevenusProps) => {
  const {
    montantPension,
    montantRevenusComplementaires,
    montantTotal,
    montantRevenusAFinancer,
    assetsLength,
  } = props;

  const isPhone = useMediaQuery({ minwidth: 0, maxwidth: "sm" });

  const intl = useIntl();

  const [data, setData] = useState<
    { name: string; y: number | undefined; color: string }[]
  >([]);
  const [options, setOptions] = useState(() => getInitOptions());

  useEffect(() => {
    if (montantTotal) {
      const newData: { name: string; y: number | undefined; color: string }[] =
        [];
      if (montantPension) {
        newData.push({
          name: "Revenu Total Retraite",
          color: "#9F2D62",
          y: montantPension / montantTotal,
        });
      }
      if (montantRevenusComplementaires) {
        newData.push({
          name: "Compléments revenus 1",
          color: "#208382",
          y: montantRevenusComplementaires / montantTotal,
        });
      }
      if (montantRevenusAFinancer) {
        newData.push({
          name: "Revenus à financer",
          color: "#090F70",
          y: montantRevenusAFinancer / montantTotal,
        });
      }
      setData(newData);
      setOptions((opt) => ({
        ...opt,
        title: { ...opt.title, text: getGraphTitle() },
        series: [{ ...opt.series[0], data: newData }],
      }));
    }
  }, [
    montantTotal,
    montantPension,
    montantRevenusComplementaires,
    montantRevenusAFinancer,
  ]);

  useEffect(() => {
    setOptions((opt) => ({
      ...opt,
      chart: {
        ...opt.chart,
        height: isPhone ? 260 : 420,
        spacingLeft: isPhone ? 0 : 40,
      },
      title: {
        ...opt.title,
        y: isPhone ? 30 : -15,
        style: {
          ...opt.title.style,
          fontSize: isPhone ? "30px" : "47px",
        }
      }
    }));
  }, [isPhone]);

  function getStringValue(value: number | undefined) {
    if (value !== undefined && value >= 0) {
      return value?.toLocaleString("fr-FR");
    }

    return "--";
  }

  function getGraphTitle() {
    return `<label>${getStringValue(
      montantTotal
    )} €</label><br><label style='font-size: ${
      isPhone ? "16px" : "18px"
    };font-family:Source Sans Pro Bold;color:#545454'>${intl.formatMessage({
      id: `monPlan.revenus.parmois`,
    })}</label>`;
  }

  function getInitOptions() {
    return {
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        backgroundColor: "transparent",
        height: isPhone ? 260 : 420,
        spacingRight: 0,
        spacingLeft: isPhone ? 0 : 40,
        spacingTop: 0,
        spacingBottom: 0,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          useHTML: true,
          dataLabels: {
            enabled: false,
          },
          startAngle: 250,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
            },
          },
        },
      },
      title: {
        text: getGraphTitle(),
        align: "center",
        verticalAlign: "middle",
        y: isPhone ? 30 : -15,
        style: {
          fontWeight: "bold",
          fontSize: isPhone ? "30px" : "47px",
          color: "#000000",
          fontFamily: "'Montserrat'",
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: "Revenus",
          innerSize: "88%",
          colorByPoint: true,
          data,
        },
      ],
    };
  }

  const chartRender = () => (
    <div className="revenus__container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );

  return (
    <>
      <SGMediaQuery minwidth={0} maxwidth="xs">
        <SGCard className="revenus__mob">
          {chartRender()}
          <div className="revenus__mob__subtitle-container">
            <SGGridRow>
              <SGGridCol textalign="center">
                <SGText>
                  {intl.formatMessage(
                    { id: "monPlan.revenus.subtitle" },
                    {
                      pension: getStringValue(montantPension),
                      patrimoine:
                        assetsLength !== 0
                          ? getStringValue(montantRevenusComplementaires)
                          : "--",
                      epargne: getStringValue(montantRevenusAFinancer),
                      purple: (word) => (
                        <span style={{ color: "#9F2D62" }}>{word}</span>
                      ),
                      green: (word) => (
                        <span style={{ color: "#208382" }}>{word}</span>
                      ),
                      blue: (word) => (
                        <span style={{ color: "#090F70" }}>{word}</span>
                      ),
                      light: (word) => (
                        <span style={{ fontWeight: 400 }}>{word}</span>
                      ),
                    }
                  )}
                </SGText>
              </SGGridCol>
            </SGGridRow>
          </div>
        </SGCard>
      </SGMediaQuery>

      <SGMediaQuery minwidth="sm">
        <>
          {chartRender()}
          <div className="revenus__subtitle-container">
            <SGGridRow align="middle" gutter={[10]}>
              <SGGridCol span={12} textalign="center">
                <SGText
                  weight="600"
                  size="l"
                  style={{ color: "#9F2D62", lineHeight: "24px" }}
                >
                  {intl.formatMessage(
                    { id: "monPlan.revenus.pension" },
                    {
                      montant: getStringValue(montantPension),
                      light: (word) => (
                        <span style={{ fontWeight: 400 }}>{word}</span>
                      ),
                    }
                  )}
                </SGText>
              </SGGridCol>
              <SGGridCol span={12} textalign="center">
                <SGText
                  weight="700"
                  size="l"
                  style={{ color: "#545454", lineHeight: "17px" }}
                >
                  +
                </SGText>
              </SGGridCol>
              <SGGridCol span={12} textalign="center">
                <SGText
                  weight="600"
                  size="l"
                  style={{ color: "#208382", lineHeight: "24px" }}
                >
                  {intl.formatMessage(
                    { id: "monPlan.revenus.patrimoine" },
                    {
                      montant:
                        assetsLength !== 0
                          ? getStringValue(montantRevenusComplementaires)
                          : "--",
                      light: (word) => (
                        <span style={{ fontWeight: 400 }}>{word}</span>
                      ),
                    }
                  )}
                </SGText>
              </SGGridCol>
              <SGGridCol span={12} textalign="center">
                <SGText
                  weight="700"
                  size="l"
                  style={{ color: "#545454", lineHeight: "17px" }}
                >
                  +
                </SGText>
              </SGGridCol>
              <SGGridCol span={12} textalign="center">
                <SGText
                  weight="600"
                  size="l"
                  style={{ color: "#090F70", lineHeight: "24px" }}
                >
                  {intl.formatMessage(
                    { id: "monPlan.revenus.épargne" },
                    {
                      montant: getStringValue(montantRevenusAFinancer),
                      light: (word) => (
                        <span style={{ fontWeight: 400 }}>{word}</span>
                      ),
                    }
                  )}
                </SGText>
              </SGGridCol>
            </SGGridRow>
          </div>
        </>
      </SGMediaQuery>
    </>
  );
};

export { Revenus };
