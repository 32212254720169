import { FunctionComponent, useMemo } from "react";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGProgress } from "sg-progress";
import { SGPriceCommercial, SGText } from "sg-typo";
import { anneeN } from "website/utils/date/DateUtils";

interface BarreProgressionPlafondProps {
  plafond: number;
  versement: number;
  isPlafondErreur?: boolean;
}

const BarreProgressionPlafond: FunctionComponent<
  BarreProgressionPlafondProps
> = (props: BarreProgressionPlafondProps) => {
  const { plafond, versement, isPlafondErreur } = props;

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const pourcentage: number = useMemo(
    () => (isPlafondErreur ? 0 : (versement * 100) / plafond),
    [versement, plafond, isPlafondErreur]
  );

  const montantRestant: number = useMemo(() => {
    const restant = plafond - versement;

    return restant < 0 ? 0 : restant;
  }, [versement, plafond]);

  return (
    <SGProgress
      arialabel={`Barre de progression représentant le montant de déductibilité utilisé ${versement} Euros par rapport au montant de déductibilité total ${plafond} Euros.`}
      disableautomargin
      context="money"
      showInfo={false}
      size="lg"
      percent={pourcentage}
      status="success"
      valueMin="0"
      valueMax={`${plafond}`}
      header={
        <SGGridRow gutter={[0, 0]}>
          <SGGridCol flex="0 0 0%" align="end">
            <SGText size={isPhone ? "l" : "m"} whitespace="nowrap">
              Votre montant de déductibilité{" "}
              <SGMediaQuery minwidth={0} maxwidth="xs">
                <SGText size="xs" whitespace="pre-line">
                  {"\n"}( jusqu'à {anneeN} )
                </SGText>
              </SGMediaQuery>
              <SGMediaQuery minwidth="sm">
                <SGText size="xs" whitespace="nowrap">
                  ( jusqu'à {anneeN} )
                </SGText>
              </SGMediaQuery>
            </SGText>
          </SGGridCol>
          {!isPlafondErreur && (
            <SGGridCol flex="1 0 0%" textalign="right" align="center">
              <SGPriceCommercial
                size="l"
                value={`${plafond.toLocaleString("fr-FR")}`}
              />
            </SGGridCol>
          )}
        </SGGridRow>
      }
      footer={
        !isPlafondErreur && (
          <SGGridRow>
            <SGGridCol span={6}>
              <SGText size={isPhone ? "m" : "s"} weight="600">
                Utilisé&nbsp;: {versement.toLocaleString("fr-FR")}&nbsp;€
              </SGText>
            </SGGridCol>
            <SGGridCol span={6} textalign="right">
              <SGText size={isPhone ? "m" : "s"} weight="600">
                Restant&nbsp;: {montantRestant.toLocaleString("fr-FR")}
                &nbsp;€
              </SGText>
            </SGGridCol>
          </SGGridRow>
        )
      }
    />
  );
};

export { BarreProgressionPlafond };
