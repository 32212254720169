import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { SGBox } from "sg-space";
import { Media } from "website/components/hooks/articles";
import {
  CategorieVideo,
  Video,
  useVideos,
} from "website/components/hooks/useVideos";
import {
  PlayerRef,
  VideoPlayer,
} from "website/components/molecules/VideoPlayer/VideoPlayer";
import { PauseVideoContext } from "website/components/providers/PauseVideoProvider";

import { SGAvenirStrokedVideo } from "sg-icon-pack-base";
import { MediaTemplate } from "website/components/organisms/MediaTemplate/MediaTemplate";
import "./VideoPage.scss";

interface VideoePageProps {
  media: Media;
}

export const VideoPage: FunctionComponent<VideoePageProps> = (
  props: VideoePageProps
) => {
  const { media } = props;
  const intl = useIntl();
  const [video, setVideo] = useState<Video>();
  const videos = useVideos(CategorieVideo.ARTICLES);

  const [player, setPlayer] = useState<PlayerRef>();
  const { pauseVideo, setPauseVideoCarrousel, setPauseVideo } =
    useContext(PauseVideoContext);

  useEffect(() => {
    setVideo(videos.find((v) => media.path === v.path));
  }, [media]);

  useEffect(() => {
    // Pour mettre en pause la video si on lance une vidéo dans le carrousel
    if (pauseVideo === true) {
      player?.pause();
      setPauseVideo(false);
    }
  }, [pauseVideo]);

  return (
    <>
      {video && (
        <MediaTemplate
          media={media}
          titre={intl.formatMessage({ id: `video.${video.id}.title` })}
          icone={<SGAvenirStrokedVideo />}
        >
          <SGBox className="video-page__container">
            <VideoPlayer
              cover={video.cover}
              urlVideo={video.video}
              getPlayer={setPlayer}
              onClickPlayer={() => setPauseVideoCarrousel(true)}
              type={video.type}
            />
          </SGBox>
        </MediaTemplate>
      )}
    </>
  );
};
