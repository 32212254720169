import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { State } from "store/store";
import {
  MON_PLAN,
  SIMULATEUR_ECONOMIES_IMPOTS,
} from "website/utils/privateRoutes";
import { Thematique } from "../molecules/Article/ArticleModel";
import { Media, TypeMedia } from "./articles";
import { useFeatures } from "./useFeatures";

export enum CategorieVideo {
  RIS,
  EPARGNER,
  MONPLAN,
  ARTICLES,
}

export interface Video extends Media {
  cover: string;
  video: string;
  onClick?: () => void;
  disableOnClick?: boolean;
  afficherAide?: boolean;
  linkLabel?: string;
  afficherCategorie: CategorieVideo[];
  ALaUne?: boolean; // permet d'indiquer si on doit afficher la video dans le carrousel 'A la une' (uniquement pour l'onglet Accueil)
}

type onClickRdvFunction = (
  disponibiliteSGAgenda: boolean,
) => void;

const useVideos = (
  categorie?: CategorieVideo,
  handleOnClickRdv?: onClickRdvFunction
) => {
  const history = useHistory();
  const { parcoursSEIActif, disponibiliteSGAgenda } = useFeatures();

  const estSupervisionActive: boolean = useSelector<State, boolean>((state) => state.supervision.estActive);

  const compare = (video1: Video, video2: Video) =>
    video2.date.getTime() - video1.date.getTime();

  /**
   * L'ordre défini ici est celui utilisé sur toutes les pages hors articles (notamment pour le parcours épargner).
   * Les articles se basent sur la date de vidéo.
   */
  const allVideos: Video[] = [
    {
      afficherCategorie: [CategorieVideo.ARTICLES],
      id: "combien-toucher-retraite",
      type: TypeMedia.VIDEO,
      image: "image-video-combien-toucher-retraite-carrousel.png",
      cover: "image-video-combien-toucher-retraite.jpg",
      path: "video-combien-toucher-retraite",
      video:
        "https://www.assurances.societegenerale.com/fileadmin/Videos/2023/Marketing/5_des_experts_combien_toucherez-vous_a_la_retraite.mp4",
      time: 5,
      onClick: handleOnClickRdv
        ? () =>
            handleOnClickRdv(disponibiliteSGAgenda ?? false)
        : undefined,
      disableOnClick: estSupervisionActive,
      afficherAide: true,
      date: new Date(2023, 10),
      linkLabel: "contacter",
      ALaUne: true,
      theme: Thematique.epargner,
    },
    {
      afficherCategorie: [CategorieVideo.RIS, CategorieVideo.ARTICLES],
      id: "ris",
      type: TypeMedia.VIDEO,
      image: "imageVideo-ris.jpg",
      cover: "imageVideo-ris.jpg",
      path: "video-ris",
      video:
        "https://www.assurances.societegenerale.com/fileadmin/Videos/2023/Marketing/RELEVE_INDIVIDUEL_DE_SITUATION__RIS_.mp4",
      time: 3,
      onClick: handleOnClickRdv
        ? () =>
            handleOnClickRdv(disponibiliteSGAgenda ?? false)
        : undefined,
      disableOnClick: estSupervisionActive,
      afficherAide: true,
      date: new Date(2023, 9),
      linkLabel: "contacter",
      theme: Thematique.epargner,
    },
    {
      afficherCategorie: [CategorieVideo.MONPLAN],
      id: "conseiller",
      type: TypeMedia.VIDEO,
      image: "imageVideo.jpg",
      cover: "imageVideo.jpg",
      path: "conseiller",
      time: 3,
      video:
        "https://www.assurances.societegenerale.com/fileadmin/Videos/V9_-_Comment_pr%C3%A9parer_sa_retraite_VF.mp4",
      onClick: handleOnClickRdv
        ? () =>
            handleOnClickRdv(disponibiliteSGAgenda ?? false)
        : undefined,
      disableOnClick: estSupervisionActive,
      date: new Date(2022, 3),
      linkLabel: "contacter",
      theme: Thematique.epargner,
    },
    {
      afficherCategorie: [CategorieVideo.EPARGNER, CategorieVideo.ARTICLES],
      id: "conseiller",
      type: TypeMedia.VIDEO,
      image: "image-video-conseiller.png",
      cover: "image-video-conseiller.png",
      video:
        "https://www.assurances.societegenerale.com/fileadmin/Videos/V9_-_Comment_pr%C3%A9parer_sa_retraite_VF.mp4",
      time: 3,
      path: "video-conseiller",
      onClick: () => history.push(MON_PLAN),
      date: new Date(2022, 3),
      linkLabel: "monPlan",
      theme: Thematique.epargner,
    },
    {
      afficherCategorie: [CategorieVideo.EPARGNER, CategorieVideo.ARTICLES],
      id: "per",
      type: TypeMedia.VIDEO,
      image: "image-video-per.png",
      cover: "image-video-per.png",
      video:
        "https://www.assurances.societegenerale.com/fileadmin/user_upload/video/V52_-_Le_PER_pour_avoir_plus_d_argent_11-10_VF.mp4",
      time: 7,
      path: "video-per",
      onClick: parcoursSEIActif
        ? () => history.push(SIMULATEUR_ECONOMIES_IMPOTS)
        : undefined,
      date: new Date(2022, 11),
      linkLabel: "lienSEI",
      theme: Thematique.epargner,
    },
    {
      afficherCategorie: [CategorieVideo.EPARGNER, CategorieVideo.ARTICLES],
      id: "perasv",
      type: TypeMedia.VIDEO,
      image: "image-video-perasv.png",
      cover: "image-video-perasv.png",
      video:
        "https://www.assurances.societegenerale.com/fileadmin/Videos/2023/Marketing/PER_ou_Assurance_vie__quel_epargne_pour_sa_retraite.mp4",
      time: 8,
      path: "video-perasv",
      onClick: handleOnClickRdv
        ? () =>
            handleOnClickRdv(disponibiliteSGAgenda ?? false)
        : undefined,
      disableOnClick: estSupervisionActive,
      date: new Date(2023, 6),
      linkLabel: "contactConseiller",
      theme: Thematique.epargner,
    },
  ];

  const [videos, setVideos] = useState<Video[]>(allVideos);

  useEffect(() => {
    const list = allVideos;
    if (categorie === CategorieVideo.ARTICLES) {
      list.sort(compare);
    }
    setVideos(
      categorie !== undefined ? list.filter((video) => video.afficherCategorie.includes(categorie)) : list
    );
  }, [parcoursSEIActif, disponibiliteSGAgenda]);

  return videos;
};

export { useVideos };
