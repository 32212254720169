import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGTable } from "sg-table";
import { SGBlock, SGText } from "sg-typo";
import { CelluleTableau } from "./ArticleTableauFemmeExploitantAgricole";

interface RowData {
    header: string;
    [__key: number]: CellData;
}

interface CellData {
    text: string;
    colSpan: number;
}

interface CellProps {
    colSpan?: number;
    rowSpan?: number;
}

interface AntdColumn {
    align?: "left" | "right" | "center";
    // Fait le lien avec la colonne à afficher. Supporte les sous-cellules via des tableaux de string
    dataIndex: string | string[];
    title?: string;
    rowScope?: "row" | "rowgroup";
    render?: (contenuCellule: any, ligne: any, indexLigne: number) => ReactNode;
    onCell?: (_: any, indexLigne: number) => CellProps;
}

interface ArticleTableauDesktopProps {
    titresColonnes: string[];
    donnees: CelluleTableau[][];
}

export const ArticleTableauDesktop: FunctionComponent<ArticleTableauDesktopProps> = (props: ArticleTableauDesktopProps) => {
    const { titresColonnes, donnees } = props;
    const [colonnesTable, setColonneTable] = useState<AntdColumn[]>();
    const [cellulesTable, setCellulesTable] = useState<RowData[]>();

    function initEntete(): AntdColumn[] {
        const entetes: AntdColumn[] = titresColonnes.map((titre: string, index: number) => ({
            align: "center",
            dataIndex: `${index + 1}`,
            title: titre,
            render: creerCellule,
        }));

        return [
            // Cellule d'entête
            {
                dataIndex: "header",
                rowScope: "row",
                render: creerCelluleEntete,
            },
            // Cellules de données
            ...entetes,
        ];
    }

    function initLigne(cellules: CelluleTableau[]): RowData {
        const ligne: RowData = {
            header: cellules[0].text,
        };
        let currentIndex = 1;

        cellules.slice(1).forEach((cellule: CelluleTableau) => {
            const index = cellule.cols ? cellule.cols[0] : currentIndex;
            ligne[index] = {
                text: cellule.text,
                colSpan: cellule.cols ? cellule.cols.length : 1,
            };
            currentIndex += ligne[index].colSpan;
        });

        return ligne;
    }

    useEffect(() => {
        setColonneTable(initEntete());
        setCellulesTable(donnees.map(initLigne));
    }, []);

    function creerCelluleEntete(cellule: CellData, row: RowData, indexLigne: number): ReactNode {
        return <SGText strong>{row.header}</SGText>;
    }

    function creerCellule(cellule: CellData, row: RowData, indexLigne: number): ReactNode {
        // Pas de cellule à la colonne, on la masque
        if (cellule === undefined) {
            return {
                props: {
                    colSpan: 0,
                    rowSpan: 0,
                }
            };
        }

        return {
            children: <SGText>
                <FormattedMessage id={cellule.text} defaultMessage={cellule.text} values={{
                    ul: (word: string) => <SGBlock component="ul" disableautomargin>{word}</SGBlock>,
                    li: (word: string) => <li>{word}</li>
                }} />
            </SGText>,
            props: {
                // Pour prendre plusieurs cellules de large
                colSpan: cellule.colSpan,
                style: {
                    textAlign: cellule.colSpan > 1 ? "center" : "left",
                    verticalAlign: cellule.colSpan > 1 ? "middle" : "top",
                }
            },
        };
    }

    return (
        <SGTable
            columns={colonnesTable}
            dataSource={cellulesTable}
            darkheader
            disableautomargin
            pagination={{
                hideOnSinglePage: true
            }}
        />
    );
}
