import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import {ErrorState, ErrorType} from "store/error/types";
import { resetErrorsAction } from "store/error/actions";

const useErrorAsset = (acceptAllTypes = false) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<Array<string>>([]);
  const [ignoreResetError, setIgnoreResetError] = useState<boolean>(false);
  const [errorsParameters, setErrorsParameters] = useState<Record<string, string> | undefined>({});

  const errorState: ErrorState = useSelector<State, ErrorState>((stateErr) => stateErr.error);
  const state: State = useSelector<State, State>((stateVal) => stateVal);

  useEffect(() => {
    if ((acceptAllTypes || errorState.type === ErrorType.BAD_INPUT_ERROR || errorState.type === ErrorType.TIME_OUT_ON_PDF_DOWNLOAD_ERROR) && errorState.hasError) {
       if (errorState.type === ErrorType.BAD_INPUT_ERROR) {
          setErrors(errorState.errorCodes ? errorState.errorCodes : []);
          setErrorsParameters(errorState.errorCodesParameters ? errorState.errorCodesParameters : {});
       } else {
          setErrors([errorState.message]);
       }
       setIgnoreResetError(true);
       dispatch(resetErrorsAction()); // pour repartir d'un state propre à la prochaine soumission de formulaire
    } else if (ignoreResetError) {
       setIgnoreResetError(false); // pour ne pas perdre le message d'erreur suite au reset qu'on a nous même déclenché
    } else {
       setErrors([]);
       setErrorsParameters({});
    }
 }, [errorState, state]);

  return {
    errorsParameters,
    errors
  };
};

export { useErrorAsset };
