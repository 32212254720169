import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callDashboard } from "store/dashboard/actions";
import { DashboardState, hasFetchedDashboard } from "store/dashboard/types";
import { State } from "store/store";
import { useAccount } from "../useAccount";

const useDashboardState = () => {
    const dispatch = useDispatch();
    const account = useAccount();
    const hasFetchedAccount = useSelector<State, boolean>((state) => state.account.hasFetched);
    const dashboardState: DashboardState = useSelector<State, DashboardState>((state) => state.dashboard);

    useEffect(() => {
        if (hasFetchedAccount && (!hasFetchedDashboard(dashboardState)
            // Patch si on vient de /login
            || (account.tunnelCompleted && !dashboardState.dashboard.creationDate)
        )) {
            dispatch(callDashboard());
        }
    }, [hasFetchedAccount]);

    return dashboardState;
};

export { useDashboardState };
