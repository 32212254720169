import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { resetErrors } from "store/error/errorsSlice";
import { ErrorState, ErrorType } from "store/error/types";
import { isMobileEnvironment } from "store/import/utils";
import { logout } from "store/login/actions";
import { LOGIN, LoginState } from "store/login/types";
import { State } from "store/store";
import { DASHBOARD, PAGES_BLOQUANTES } from "website/utils/privateRoutes";
import {
  FORBIDDEN,
  MAINTENANCE,
  publicRoutes,
} from "website/utils/publicRoutes";
import { redirectToRelativePath } from "website/utils/routes/routing";

export const useAppErrors = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const loginState: LoginState = useSelector<State, LoginState>(
    (state) => state.login
  );
  const errorState: ErrorState = useSelector<State, ErrorState>(
    (state) => state.error
  );
  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  const checkFatalError = () => {
    // Si erreur fatale, on redirige vers la page de maintenance
    if (errorState.type === ErrorType.FATAL_ERROR) {
      redirectToRelativePath(MAINTENANCE);
      dispatch(resetErrors());
    } else if (errorState.type === ErrorType.UNAVAILABLE_ERROR) {
      // Si le site est indisponible, on déconnecte aussi l'utilisateur
      dispatch(logout(`/#${MAINTENANCE}`));
    }
  };

  const checkNonFatalError = () => {
    // Si erreur non fatale sur un tunnel bloquant, on redirige vers la page de maintenance
    if (
      errorState.type === ErrorType.NON_FATAL_ERROR &&
      PAGES_BLOQUANTES.includes(pathname)
    ) {
      redirectToRelativePath(MAINTENANCE);
      dispatch(resetErrors());
    }

    // Si erreur non fatale sur une page autre que dashboard, on redirige vers la page dashboard
    if (
      errorState.type === ErrorType.NON_FATAL_ERROR &&
      pathname !== MAINTENANCE &&
      pathname !== DASHBOARD &&
      !PAGES_BLOQUANTES.includes(pathname) &&
      !estSupervisionActive
    ) {
      redirectToRelativePath(DASHBOARD);
      dispatch(resetErrors());
    }
  };

  const checkForbidenError = () => {
    // Si erreur 403, on redirige vers la page dédiée
    if (errorState.type === ErrorType.FORBIDDEN_ERROR) {
      redirectToRelativePath(FORBIDDEN);
      dispatch(resetErrors());
    }
  };

  const checkAuthorizationError = () => {
    // Si erreur d'authentification on redirige vers la page de login correspondant au profil si on n'est pas sur une page publique
    if (
      errorState.type === ErrorType.AUTHORIZATION_ERROR &&
      window.env.REACT_APP_USE_SSO === "true" &&
      !publicRoutes.map((publicRoute) => publicRoute.path).includes(pathname)
    ) {
      window.location.href = `${window.env.REACT_APP_API_URL}${
        !isMobileEnvironment()
          ? window.env.REACT_APP_SSO_URL
          : window.env.REACT_APP_SSO_URL_MOBILE
      }`;
      dispatch(resetErrors());
    }

    if (
      errorState.type === ErrorType.AUTHORIZATION_ERROR &&
      window.env.REACT_APP_USE_SSO !== "true" &&
      !publicRoutes.map((publicRoute) => publicRoute.path).includes(pathname)
    ) {
      redirectToRelativePath(LOGIN);
      dispatch(resetErrors());
    }
  };

  useEffect(() => {
    if (!loginState.forceLogOut) {
      checkFatalError();
      checkNonFatalError();
      checkForbidenError();
      checkAuthorizationError();
    }
  }, [errorState]);
};
