import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { insertElement, removeElement, updateElement } from "../utils";
import { AssetState, AssetWithValuation } from "./types";
import { filterActifsProjetables } from "./utils";

const initialState: AssetState = {
  assets: new Array<AssetWithValuation>(),
  assetsImported: new Array<AssetWithValuation>(),
  hasFetched: false,
  hasFetchedAssetsImported: false,
  showSGImportAssetModal: false,
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    assetCreated: (
      currentState,
      action: PayloadAction<AssetWithValuation>
    ) => ({
      ...currentState,
      assets: insertElement(currentState.assets, action.payload),
    }),
    assetUpdated: (
      currentState,
      action: PayloadAction<AssetWithValuation>
    ) => ({
      ...currentState,
      assets: updateElement(currentState.assets, action.payload),
    }),
    assetDeleted: (
      currentState,
      action: PayloadAction<AssetWithValuation>
    ) => ({
      ...currentState,
      assets: removeElement(currentState.assets, String(action.payload.id)),
    }),
    assetsFetched: (
      currentState,
      action: PayloadAction<AssetWithValuation[]>
    ) => ({
      ...currentState,
      assets: filterActifsProjetables(action.payload),
      hasFetched: true,
    }),
    assetsImportedFetched: (
      currentState,
      action: PayloadAction<AssetWithValuation[]>
    ) => ({
      ...currentState,
      hasFetchedAssetsImported: true,
      assetsImported: filterActifsProjetables(action.payload),
    }),
    assetForRetirementUpdated: (
      currentState,
      action: PayloadAction<AssetWithValuation[]>
    ) => ({
      ...currentState,
      assets: filterActifsProjetables(action.payload),
    }),
    setShowSGImportAssetModal: (
      currentState,
      action: PayloadAction<boolean>
    ) => ({
      ...currentState,
      showSGImportAssetModal: action.payload,
    }),
  },
});

export const {
  assetCreated,
  assetUpdated,
  assetDeleted,
  assetsFetched,
  assetsImportedFetched,
  assetForRetirementUpdated,
  setShowSGImportAssetModal,
} = assetsSlice.actions;

export default assetsSlice.reducer;
