import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { SGInputNumber } from "sg-input";
import { useMediaQuery } from "sg-media-query";
import { SGText } from "sg-typo";

type InputNumberBrutNetProps = {
  name: string;
  required?: boolean;
  label: string;
  labelValues?: any;
  extra?: JSX.Element;
  min?: number;
  max?: number;
  value?: number;
  onBlur?: (value: number) => void;
  onChange: (value: number) => void;
  brutNet?: "brutan" | "netan" | "brutmois";
};

export const InputNumberBrutNet: React.FC<InputNumberBrutNetProps> = (
  props: React.PropsWithChildren<InputNumberBrutNetProps>
) => {
  const {
    name,
    required = true,
    label,
    labelValues,
    extra,
    min = 0,
    max = 999_999,
    value,
    onBlur,
    onChange,
    brutNet,
  } = props;

  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { formState: { errors }, control } = useFormContext();

  function getError(valeur: string) {
    const error = errors[valeur];

    if (error?.type === "required") {
      return intl.formatMessage({ id: "tunnel.error.required" });
    }
    if (error?.type === "min") {
      return intl.formatMessage({ id: "parcoursRIS.projection.error.min" });
    }

    return "";
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
        min,
        max,
      }}
      render={({ field }) => (
        <SGInputNumber
          label={
            <SGText size="l">
              <FormattedMessage
                id={label}
                values={{
                  ...labelValues,
                  brutNet: brutNet ? (
                    <FormattedMessage id={`inputNumber.${brutNet}`} />
                  ) : (
                    ""
                  ),
                }}
              />
              {extra && <> {extra}</>}
            </SGText>
          }
          disableautomargin
          min={0}
          max={max}
          size={isPhone ? "xl" : "m"}
          required={false}
          value={value}
          onBlur={(valeurOnBlur: number) => {
            if (onBlur) {
              onBlur(valeurOnBlur);
            }
          }}
          onChange={(valeurOnChange: number) => {
            field.onChange(valeurOnChange);
            onChange(valeurOnChange);
          }}
          status={errors && errors[name] && "error"}
          validate={getError(name)}
        />
      )}
    />
  );
};
