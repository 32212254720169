import { FunctionComponent, useEffect } from "react";
import { Loader } from "website/components/atoms/Loader/Loader";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";

const SGAgendaConnexionPage: FunctionComponent = () => {
  const openSGAgenda = useSGAgenda();

  useEffect(() => {
    openSGAgenda();
  }, []);

  return <Loader />;
};

export { SGAgendaConnexionPage };
