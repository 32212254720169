import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGBox, SGSpace } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { NomNosSolutionsEnum, TabNosSolutionsEnum } from "./utils";
import { useMediaQuery } from "sg-media-query";

interface SectionTabsNosSolutionsProps {
  tabs: TabNosSolutionsEnum[];
  nom: NomNosSolutionsEnum;
}

const SectionTabsNosSolutions: FunctionComponent<
  SectionTabsNosSolutionsProps
> = (props: SectionTabsNosSolutionsProps) => {
  const { tabs, nom } = props;

  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  return (
    <SGTabs disableautomargin>
      {tabs.map((tab) => (
        <SGTabPane
          key={tab}
          tab={intl.formatMessage({
            id: `nosSolutions.${nom}.section.tabs.${tab}`,
          })}
          ariaId={tab}
        >
          <SGBox margin={isPhone ? { top: "sm" } : {}} padding={{ top: "md" }}>
            <SGSpace direction="vertical" size="md" disableautomargin>
              <SGTitle key="titre" level={2} visuallevel={isPhone ? 2 : 4}>
                {intl.formatMessage({
                  id: `nosSolutions.${nom}.section.tabs.${tab}.titre`,
                })}
              </SGTitle>
              <SGText key="text-1" size={isPhone ? "l" : "m"} weight="400">
                {intl.formatMessage(
                  {
                    id: `nosSolutions.${nom}.section.tabs.${tab}.texte`,
                  },
                  {
                    box: (word) => (
                      <SGBox margin={{ bottom: "sm" }}>
                        <>{word}</>
                      </SGBox>
                    ),
                    ul: (word) => (
                      <SGBlock component="ul" disableautomargin>
                        {word}
                      </SGBlock>
                    ),
                    li: (word) => <li>{word}</li>,
                    sup: (word) => <sup>{word}</sup>,
                    b: (word) => <SGText strong>{word}</SGText>,
                  }
                )}
              </SGText>
              {(tab === TabNosSolutionsEnum.SOUSCRIPTION ||
                tab === TabNosSolutionsEnum.ALIMENTATION) && (
                <SGText
                  key="text-2"
                  color="lighter"
                  weight="400"
                  size={isPhone ? "m" : "s"}
                >
                  {intl.formatMessage({
                    id: `nosSolutions.${nom}.section.tabs.${tab}.mention`,
                  })}
                </SGText>
              )}
            </SGSpace>
          </SGBox>
        </SGTabPane>
      ))}
    </SGTabs>
  );
};

export { SectionTabsNosSolutions };
