import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiGet, apiPost } from "../apiCaller";
import { onDefaultError, safe } from "../error/utils";
import { Appointment, APPOINTMENT_FETCHED, APPOINTMENT_UPDATED, CREATE_APPOINTMENT, FETCH_APPOINTMENT } from "./types";

export function* watcherSaga() {
   yield takeLatest(FETCH_APPOINTMENT, safe(onDefaultError, handleGetAppointment));
   yield takeLatest(CREATE_APPOINTMENT, safe(onDefaultError, handlePostAppointment));
}

function* handleGetAppointment() {
   const payload: AxiosResponse<Appointment> = yield call(apiGet, '/backpfr/api/appointment', process.env.REACT_APP_API_URL);
   yield put({ type: APPOINTMENT_FETCHED, payload: payload.data });
}

function* handlePostAppointment(action: PayloadAction<Appointment>) {
   yield call(apiPost, `/backpfr/api/appointment`, action.payload);
   yield put({ type: APPOINTMENT_UPDATED, payload: action.payload });
}
