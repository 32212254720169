import { AxiosError, AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { globalErrorUnavailable } from "store/error/errorsSlice";
import { apiGet } from "../apiCaller";
import { safe } from "../error/utils";
import { FEATURES_FETCHED, FETCH_FEATURES, Feature } from "./types";
import { logError } from "website/utils/logging";

export function* watcherSaga() {
  yield takeLeading(FETCH_FEATURES, safe(onBackError, handleGetFeatures));
}

function* onBackError(err: AxiosError) {
  yield put(globalErrorUnavailable());
}

function* handleGetFeatures() {
  const payload: AxiosResponse<Feature[]> = yield call(
    apiGet,
    `/backpfr/api/fonctionnalites`,
    process.env.REACT_APP_API_URL
  );

  const payloadStringified = JSON.stringify(payload);
  if (payloadStringified.startsWith('{"')) {
    yield put({ type: FEATURES_FETCHED, payload: payload.data });
  } else {
    logError("Problème de connexion à backpfr");
    yield put(globalErrorUnavailable());
  }
}
