import {FunctionComponent} from "react";
import {SGTextIntl} from "../SGTextIntl/SGTextIntl";
import {SGAlertIntl} from "../../molecules/SGAlertIntl/SGAlertIntl";
import {SGBlock} from "sg-typo";
import { useErrorAsset } from "website/components/hooks/useErrorAsset";

interface ErrorElementProps {
   cypressName: string;
   errorTextPrefix: string;
   notificationFrame?: boolean;
   acceptAllTypes?: boolean;
}

const ErrorElement: FunctionComponent<ErrorElementProps> = (props: ErrorElementProps) => {
   const {cypressName, errorTextPrefix, notificationFrame, acceptAllTypes} = props;
   const { errors, errorsParameters } = useErrorAsset(acceptAllTypes);
   const displayErrors = () => (
      <SGBlock textalign="center">
         {errors.map((error: string) => (
            <div key={`${errorTextPrefix}.${error}`}>
               <SGTextIntl intlId={`${errorTextPrefix}.${error}`} color="error" size="m" key={`${errorTextPrefix}.${error}`} transformations={errorsParameters}/>
            </div>
         ))}
      </SGBlock>
   );

   return (
      <>
         {notificationFrame && errors.length !== 0 && (
            <SGAlertIntl cypressName={`${cypressName}-error-element-frame`} type="warning" title="common.warning">
               {displayErrors()}
            </SGAlertIntl>
         )}

         {!notificationFrame &&
            <div data-cy={`${cypressName}-error-element`}>
               {displayErrors()}
            </div>
         }
      </>
   );
};

export {ErrorElement};
