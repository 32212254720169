import { FETCH_PARCOURS_RIS_UPLOAD, ParcoursRisUploadRequest, ParcoursRisUpload, RESET_PARCOURS_RIS_UPLOAD_ERROR, UPDATE_PARCOURS_RIS_UPLOAD, FETCH_PARCOURS_RIS_UPLOAD_ACCESSIBLE, RESET_PARCOURS_RIS_UPLOAD_ACCESSIBLE } from "./types";

export const postParcoursRisUpload = (data: ParcoursRisUploadRequest) => ({
   type: FETCH_PARCOURS_RIS_UPLOAD,
   payload: data,
});

export const resetParcoursRisUploadError = () => ({
   type: RESET_PARCOURS_RIS_UPLOAD_ERROR,
});

export const updateParcoursRisUpload = (data: Partial<ParcoursRisUpload>) => ({
   type: UPDATE_PARCOURS_RIS_UPLOAD,
   payload: data,
});

/**
 * Appelle le backpfr pour récupérer l'accessibilité de la feature
 */
 export const fetchParcoursRisUploadAccessible = () => ({
   type: FETCH_PARCOURS_RIS_UPLOAD_ACCESSIBLE
});

export const resetParcoursRisUploadAccessible = () => ({
   type: RESET_PARCOURS_RIS_UPLOAD_ACCESSIBLE
});
