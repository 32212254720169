import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavPlus } from "sg-icon-pack-base";
import { BoutonSupervision } from "../BoutonSupervision/BoutonSupervision";
import { useIntl } from "react-intl";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { State } from "store/store";

interface ButtonAddNewElementSupervisionProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonAddNewElementSupervision: FunctionComponent<
  ButtonAddNewElementSupervisionProps
> = (props: ButtonAddNewElementSupervisionProps) => {
  const { text, disabled, onClick } = props;
  const intl = useIntl();
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  return (
    <SGCard
      background="dark"
      clickable={possedeDroitsActionUtilisateur(supervisionState) && !disabled}
      textalign="center"
      hoverable
      bordered
    >
      <BoutonSupervision
        type="tertiary"
        disabled={disabled}
        onClick={onClick}
        icon={<SGIcon currentcolor component={<SGAvenirNavPlus />} />}
      >
        {intl.formatMessage({ id: text })}
      </BoutonSupervision>
    </SGCard>
  );
};

export { ButtonAddNewElementSupervision };
