import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useMediaQuery } from "sg-media-query";
import { hasFetchedDashboard } from "store/dashboard/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useFeatures } from "website/components/hooks/useFeatures";
import { usePromotionalOffers } from "website/components/hooks/usePromotionalOffers";
import { DepartAnticipeModal } from "website/components/molecules/DepartAnticipeModal/DepartAnticipeModal";
import { LegalNoticesModal } from "website/components/molecules/LegalNoticesModal/LegalNoticesModal";
import { QuestionnaireDashboard } from "website/components/molecules/NotificationDashboard/QuestionnaireDashboard";
import { OffreAvantApresModal } from "website/components/molecules/OffreAvantApresModal/OffreAvantApresModal";
import { OffreDepartAnticipeModal } from "website/components/molecules/OffreDepartAnticipeModal/OffreDepartAnticipeModal";
import { OffrePERModal } from "website/components/molecules/OffrePERModal/OffrePERModal";
import {
  DASHBOARD,
  PAGES_BLOQUANTES,
  SIMULATEUR_AVANT_APRES,
} from "website/utils/privateRoutes";

export enum ModalAutoEnum {
  legalNotices = 0,
  departAnticipe = 1,
  offreDepartAnticipe = 2,
  offrePER = 3,
  offreAvantApres = 4,
  notificationQuestionnaireDashboard = 5,
}

interface ModalAuto {
  id: ModalAutoEnum;
  visible: boolean; // La pop-in est affiché
  setVisible: (visible: boolean) => void;
  mustBeShown?: boolean; // la pop-in doit être affiché
  hasBeenShown: boolean; // La pop-in a été affiché
  delay?: number; // Le délai d'attente pour afficher la pop-in
  pathname?: string[]; // Les pages ou la pop-in peut s'afficher (si c'est undefined alors elle peut s'afficher sur toutes les pages)
}

const ManagementModalsAuto: FunctionComponent = () => {
  const dashboardState = useDashboardState();
  const {
    offrePER,
    offreAvantApres,
    offreDepartAnticipe,
    offresFetched,
    notificationQuestionnaireDashboard,
  } = usePromotionalOffers();
  const {
    simulateurAvantApres,
    disponibiliteParcoursRIS,
    visibiliteQuestionnaireDashboard,
  } = useFeatures();
  const { pathname } = useLocation();

  const [showLegalNotices, setShowLegalNotices] = useState<boolean>(false);
  const [showOffrePERModal, setShowOffrePERModal] = useState<boolean>(false);
  const [showOffreAvantApresModal, setShowOffreAvantApresModal] =
    useState<boolean>(false);
  const [showDepartAnticipeModal, setShowDepartAnticipeModal] =
    useState<boolean>(false);
  const [showOffreDepartAnticipeModal, setShowOffreDepartAnticipeModal] =
    useState<boolean>(false);

  const [
    showNotificationQuestionnaireDashboard,
    setShowNotificationQuestionnaireDashboard,
  ] = useState<boolean>(false);

  const [currentModal, setCurrentModal] = useState<ModalAuto>();
  const [timeoutIdCurrent, setTimeoutIdCurrent] = useState<NodeJS.Timeout>();
  const [timeoutCurrentModal, setTimeoutCurrentModal] =
    useState<ModalAutoEnum>();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  /**
   * La liste des pop-in
   */
  const getInitModals = (): ModalAuto[] => [
    {
      id: ModalAutoEnum.legalNotices,
      visible: false,
      setVisible: setShowLegalNotices,
      mustBeShown: false,
      hasBeenShown: false,
      pathname: [DASHBOARD, SIMULATEUR_AVANT_APRES],
    },
    {
      id: ModalAutoEnum.departAnticipe,
      visible: false,
      setVisible: setShowDepartAnticipeModal,
      mustBeShown: false,
      hasBeenShown: false,
      pathname: [DASHBOARD],
    },
    {
      id: ModalAutoEnum.offreDepartAnticipe,
      visible: false,
      setVisible: setShowOffreDepartAnticipeModal,
      mustBeShown: false,
      hasBeenShown: false,
      pathname: [DASHBOARD],
    },
    {
      id: ModalAutoEnum.offrePER,
      visible: false,
      setVisible: setShowOffrePERModal,
      mustBeShown: false,
      hasBeenShown: false,
      delay: 15000,
    },
    {
      id: ModalAutoEnum.offreAvantApres,
      visible: false,
      setVisible: setShowOffreAvantApresModal,
      mustBeShown: false,
      hasBeenShown: false,
      delay: 800,
    },
    {
      id: ModalAutoEnum.notificationQuestionnaireDashboard,
      visible: false,
      setVisible: setShowNotificationQuestionnaireDashboard,
      mustBeShown: false,
      hasBeenShown: false,
      delay: 15000,
      pathname: [DASHBOARD],
    },
  ];

  // Pour indiquer si la pop-in peut s'afficher sur ce pathname
  const isPathname = (idModal: ModalAutoEnum): boolean =>
    modals
      .find((modal) => modal.id === idModal)
      ?.pathname?.includes(pathname) ?? false;

  const [modals, setModals] = useState<ModalAuto[]>(getInitModals());

  function clearTimeoutCurrent() {
    if (timeoutIdCurrent) {
      clearTimeout(timeoutIdCurrent);
      setTimeoutIdCurrent(undefined);
      setTimeoutCurrentModal(undefined);
    }
  }

  function updateCurrentModal(modal: ModalAuto) {
    if (modal.visible) {
      setCurrentModal(modal);
    } else if (currentModal?.id === modal.id) {
      const newModals: ModalAuto[] = [...modals];
      newModals[modal.id] = {
        ...modal,
        mustBeShown: false,
        hasBeenShown: true,
      };
      setModals(newModals);
      setCurrentModal(undefined);
      clearTimeoutCurrent();
    }
  }

  const updateModals = (
    isLegalNotices: boolean,
    isOffreDepartAnticipeModal = false
  ) => {
    const isOffrePER =
      false && // Offre PER désactivée par défaut
      offresFetched &&
      dashboardState.dashboard.firstMonthlyIncomeGoalInputDate !== undefined &&
      offrePERActive() &&
      !offrePER.acceptOfferDate &&
      (offrePER.viewCountOffer < 2 || isSuperiorDays(offrePER.lastOfferDate));

    const isDepartAnticipeModal =
      dashboardState.vientDeFaireReformeDA &&
      dashboardState.dashboard.lastRISPathDate !== undefined &&
      disponibiliteParcoursRIS;

    const isOffreAvantApres =
      offresFetched &&
      !dashboardState.isOnboarded &&
      !dashboardState.dashboard.firstSimulatorBeforeAfterDate &&
      offreAvantApres.viewCountOffer < 3 &&
      simulateurAvantApres;

    const isNotificationDashboard =
      visibiliteQuestionnaireDashboard &&
      offresFetched &&
      isPhone &&
      notificationQuestionnaireDashboard.viewCountOffer < 3 &&
      notificationQuestionnaireDashboard.acceptOfferDate === undefined;

    setModals((oldModals) => [
      { ...oldModals[ModalAutoEnum.legalNotices], mustBeShown: isLegalNotices },
      {
        ...oldModals[ModalAutoEnum.departAnticipe],
        mustBeShown: isDepartAnticipeModal,
      },
      {
        ...oldModals[ModalAutoEnum.offreDepartAnticipe],
        mustBeShown: isOffreDepartAnticipeModal,
      },
      { ...oldModals[ModalAutoEnum.offrePER], mustBeShown: isOffrePER },
      {
        ...oldModals[ModalAutoEnum.offreAvantApres],
        mustBeShown: isOffreAvantApres,
      },
      {
        ...oldModals[ModalAutoEnum.notificationQuestionnaireDashboard],
        mustBeShown: isNotificationDashboard,
      },
    ]);
  };

  useEffect(() => {
    // Mettre à jour mustBeShown pour toutes les pop-in
    const isOffreDepartAnticipeModal =
      offresFetched &&
      offreDepartAnticipe.viewCountOffer < 3 &&
      !(dashboardState.isOnboarded || dashboardState.vientDeFaireReformeDA) &&
      disponibiliteParcoursRIS &&
      (dashboardState.dashboard?.lastRISPathDate === undefined ||
        (dashboardState.dashboard?.lastRISPathDate !== undefined &&
          new Date(dashboardState.dashboard?.lastRISPathDate) <
            new Date(window.env.REACT_APP_DEPART_ANTICIPE_DEBUT)));

    const isLegalNotices =
      dashboardState.dashboard?.legalNoticesAcceptanceDate === undefined &&
      dashboardState.isOnboarded === false;

    if (hasFetchedDashboard(dashboardState) && currentModal === undefined) {
      updateModals(isLegalNotices, isOffreDepartAnticipeModal);
    }

    // Mettre à jour les informations de l'offre du départ anticipé (si on doit afficher la pop-in départ anticipé)
    if (
      ((!isOffreDepartAnticipeModal &&
        currentModal?.id === ModalAutoEnum.offreDepartAnticipe) ||
        dashboardState.isOnboarded ||
        dashboardState.vientDeFaireReformeDA) &&
      modals[ModalAutoEnum.offreDepartAnticipe].mustBeShown
    ) {
      setModals((oldModals) => {
        const newModals: ModalAuto[] = [...oldModals];
        newModals[ModalAutoEnum.offreDepartAnticipe] = {
          ...oldModals[ModalAutoEnum.offreDepartAnticipe],
          mustBeShown: false,
          visible: false,
        };

        return newModals;
      });
      setShowOffreDepartAnticipeModal(false);
    }

    if (!isLegalNotices) {
      setModals((oldModals) => {
        const newModals: ModalAuto[] = [...oldModals];
        newModals[ModalAutoEnum.legalNotices] = {
          ...oldModals[ModalAutoEnum.legalNotices],
          mustBeShown: false,
          visible: false,
        };

        return newModals;
      });

      setShowLegalNotices(false);
    }
  }, [dashboardState, offresFetched, simulateurAvantApres, currentModal]);

  useEffect(() => {
    // Mettre à jour l'information visible pour toutes les pop-in
    setModals((oldModals) => [
      { ...oldModals[ModalAutoEnum.legalNotices], visible: showLegalNotices },
      {
        ...oldModals[ModalAutoEnum.departAnticipe],
        visible: showDepartAnticipeModal,
      },
      {
        ...oldModals[ModalAutoEnum.offreDepartAnticipe],
        visible: showOffreDepartAnticipeModal,
      },
      { ...oldModals[ModalAutoEnum.offrePER], visible: showOffrePERModal },
      {
        ...oldModals[ModalAutoEnum.offreAvantApres],
        visible: showOffreAvantApresModal,
      },
      {
        ...oldModals[ModalAutoEnum.notificationQuestionnaireDashboard],
        visible: showNotificationQuestionnaireDashboard,
      },
    ]);
  }, [
    showLegalNotices,
    showOffreAvantApresModal,
    showOffrePERModal,
    showDepartAnticipeModal,
    showOffreDepartAnticipeModal,
    showNotificationQuestionnaireDashboard,
  ]);

  useEffect(() => {
    const firstModalToShow = modals.find(
      (modal) => modal.mustBeShown && !modal.hasBeenShown
    );

    if (
      firstModalToShow &&
      currentModal === undefined &&
      timeoutCurrentModal !== firstModalToShow.id
    ) {
      if (firstModalToShow.delay) {
        // Pop-in avec timer
        if (!timeoutIdCurrent) {
          setTimeoutCurrentModal(firstModalToShow.id);
          setTimeoutIdCurrent(
            setTimeout(() => {
              setTimeoutIdCurrent(undefined);
              firstModalToShow.setVisible(true);
            }, firstModalToShow.delay)
          );
        } else {
          clearTimeoutCurrent();
        }
      } else {
        // Pop-in sans timer
        clearTimeoutCurrent();
        firstModalToShow.setVisible(true);
      }
    }
    modals.forEach((modal) => updateCurrentModal(modal));
  }, [modals, currentModal, timeoutCurrentModal]);

  const offrePERActive = () =>
    new Date() < new Date(window.env.REACT_APP_OFFRE_PER_FIN);

  const isSuperiorDays = (lastOfferDate?: Date) => {
    if (lastOfferDate) {
      // Différence (en ms) entre la date du jour
      // et la dernière fois que l'utilisateur a vu la popin PER
      const msDifference =
        new Date().getTime() - new Date(lastOfferDate).getTime();

      // Conversion des ms en jours
      const daysDifference = msDifference / (1000 * 60 * 60 * 24);

      return daysDifference > parseFloat(window.env.REACT_APP_OFFRE_PER_DAYS);
    }

    return true;
  };

  return !PAGES_BLOQUANTES.includes(pathname) ? (
    <>
      {showLegalNotices && isPathname(ModalAutoEnum.legalNotices) && (
        <LegalNoticesModal
          visible={showLegalNotices}
          setVisible={setShowLegalNotices}
          formStep="5"
        />
      )}
      {showOffrePERModal && (
        <OffrePERModal
          visible={showOffrePERModal}
          setVisible={setShowOffrePERModal}
        />
      )}
      {showOffreAvantApresModal && (
        <OffreAvantApresModal
          visible={showOffreAvantApresModal}
          setVisible={setShowOffreAvantApresModal}
        />
      )}
      {showDepartAnticipeModal && (
        <DepartAnticipeModal
          visible={showDepartAnticipeModal}
          setVisible={setShowDepartAnticipeModal}
        />
      )}
      {showOffreDepartAnticipeModal &&
        isPathname(ModalAutoEnum.offreDepartAnticipe) && (
          <OffreDepartAnticipeModal
            visible={showOffreDepartAnticipeModal}
            setVisible={setShowOffreDepartAnticipeModal}
          />
        )}
      {showNotificationQuestionnaireDashboard &&
        isPathname(ModalAutoEnum.notificationQuestionnaireDashboard) && (
          <QuestionnaireDashboard
            visible={showNotificationQuestionnaireDashboard}
            setVisible={setShowNotificationQuestionnaireDashboard}
          />
        )}
    </>
  ) : null;
};

export { ManagementModalsAuto };
