import { AxiosResponse } from "axios";
import { Reducer } from "react";
import {
  PARCOURS_RIS_PROJECTION_FETCHED,
  RESET_PARCOURS_RIS_PROJECTION_ERROR,
  ParcoursRisProjectionState,
  UPDATE_PARCOURS_RIS_PROJECTION,
} from "./types";
import { API_ERROR_422 } from "../parcoursRIS/types";

const initialState: ParcoursRisProjectionState = {} as ParcoursRisProjectionState;

export const ParcoursRisProjectionReducer: Reducer<
  ParcoursRisProjectionState,
  any
> = (
  currentState = initialState,
  action
): ParcoursRisProjectionState => {
  switch (action.type) {
    case PARCOURS_RIS_PROJECTION_FETCHED:
      return {
        ...currentState,
        parcoursRisProjection: action.payload,
      };
    case API_ERROR_422:
      const { response }: { response: AxiosResponse } = action.payload;

      return {
        ...currentState,
        parcoursRisProjectionError: response.data?.message,
      };
    case RESET_PARCOURS_RIS_PROJECTION_ERROR:
      return {
        ...currentState,
        parcoursRisProjectionError: "",
      };
    case UPDATE_PARCOURS_RIS_PROJECTION:
      return {
        ...currentState,
        parcoursRisProjection: action.payload,
        parcoursRisProjectionError: "",
      };
    default:
      return currentState;
  }
};
