import { FC, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGDrawer } from "sg-drawer";
import {
  SGAvenirStrokedFinancement,
  SGAvenirStyledEpargne,
  SGAvenirStyledPortefeuille,
} from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGPriceCommercial, SGText, SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardAge, User } from "store/dashboard/types";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useParcoursEpargne } from "website/components/hooks/useParcoursEpargne";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { BESOIN_EPARGNE } from "website/utils/privateRoutes";
import { OngletEffortEpargne } from "../../EffortEpargne/EffortEpargne";
import { CadreInfoSup } from "../CadreInfoSup";
import { CarteDonnee, NomCarteEnum, TypeContenuCarteEnum } from "./CarteDonnee";

enum CleDrawerBesoins {
  besoins = "besoins",
  depenses = "depenses",
  epargner = "epargner",
}

interface TuileDrawerBesoins {
  cle: CleDrawerBesoins;
  icone: JSX.Element;
  tracking: string;
  lien: string;
}

interface BesoinsProps {
  selectedRetirementAge?: DashboardAge;
  showDrawer: boolean;
  setShowDrawer: (open: boolean) => void;
}

const ID_TRACKING_DRAWER = "drawer-besoins-et-epargne";

const Besoins: FC<BesoinsProps> = (props: BesoinsProps) => {
  const { selectedRetirementAge, showDrawer, setShowDrawer } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const { hasObjectif } = useParcoursEpargne();
  const { trackDrawer, trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  const { montantEffortEpargne, montantRevenusAFinancer } = useMonPlanMontants(
    selectedRetirementAge
  );

  const tuilesDrawer: TuileDrawerBesoins[] = useMemo(
    () => [
      {
        cle: CleDrawerBesoins.besoins,
        icone: <SGAvenirStyledPortefeuille />,
        tracking: "definir-mes-besoins-de-revenus",
        lien: `#${BESOIN_EPARGNE}?tab=${OngletEffortEpargne.objectif}`,
      },
      /*
    {
      cle: CleDrawerBesoins.depenses,
      icone: <SGAvenirStyledDepensesEpargne />,
      tracking: "evaluer-mes-depenses",
      lien: "TODO",
    },
    */
      {
        cle: CleDrawerBesoins.epargner,
        icone: <SGAvenirStyledEpargne />,
        tracking: "epargner-pour-ma-retraite",
        lien: `#${BESOIN_EPARGNE}?tab=${OngletEffortEpargne.epargne}`,
      },
    ],
    []
  );

  useEffect(() => {
    if (showDrawer) {
      trackDrawer(ID_TRACKING_DRAWER);
    }
  }, [showDrawer]);

  const handleOnClickCardLink = () => {
    if (hasObjectif) {
      // Action consulter
      trackClick("clic-sur-consulter-besoins-et-epargne");
    } else {
      // Action estimer
      trackClick("clic-sur-estimer-besoins-et-epargne");
      setShowDrawer(true); // Seulement la première fois
    }
  };

  const handleOnClickTuileDrawer = (tuile: TuileDrawerBesoins) => {
    trackClick(`${ID_TRACKING_DRAWER}::clic-sur-${tuile.tracking}`);
    if (tuile.cle !== CleDrawerBesoins.depenses) {
      const choixOnglet =
        tuile.cle === CleDrawerBesoins.besoins
          ? OngletEffortEpargne.objectif
          : OngletEffortEpargne.epargne;
      // Persister le choix
      const req: User = {
        firstNeedsChoice: choixOnglet,
      };
      dispatch(updatePrefs(req));
    }
  };

  return (
    <>
      {hasObjectif ? (
        <CarteDonnee
          nom={NomCarteEnum.BESOINS}
          tagColor="11"
          isActif
          typeContenu={TypeContenuCarteEnum.MONTANT}
          montant={montantRevenusAFinancer ?? 0}
          lien={`#${BESOIN_EPARGNE}`}
          onClick={handleOnClickCardLink}
          disponibiliteFeatureFlipping
          showSkeleton={montantEffortEpargne === undefined}
        >
          <CadreInfoSup
            key="cadre-info-sup"
            icone={<SGAvenirStrokedFinancement />}
          >
            <SGText key="titre-besoins-definis" weight="600">
              <FormattedMessage id="dashboard.cadreGris.carteDonnee.besoins.titre" />
            </SGText>
            <SGPriceCommercial
              key="prix"
              value={montantEffortEpargne?.toLocaleString("fr-FR") || "--"}
              size="l"
              layout="l2"
              term="mois (net)"
            />
          </CadreInfoSup>
        </CarteDonnee>
      ) : (
        <CarteDonnee
          nom={NomCarteEnum.BESOINS}
          tagColor="11"
          typeContenu={TypeContenuCarteEnum.DESCRIPTION}
          onClick={handleOnClickCardLink}
          disponibiliteFeatureFlipping
        />
      )}

      <SGDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          trackClick(`${ID_TRACKING_DRAWER}::clic-sur-fermer`);
        }}
        placement={isPhone ? "bottom" : "right"}
        title={
          <SGTitle level={2} visuallevel={3}>
            <FormattedMessage id="dashboard.carteDonnee.besoins.drawer.titre" />
          </SGTitle>
        }
      >
        <SGText size="l" weight="400">
          <FormattedMessage id="dashboard.carteDonnee.besoins.drawer.texte" />
        </SGText>
        <SGSpace direction="vertical" size="md">
          {tuilesDrawer.map((tuile) => (
            <TuileCliquable
              key={tuile.cle}
              icone={tuile.icone}
              iconeTaille="s"
              titre={intl.formatMessage({
                id: `dashboard.carteDonnee.besoins.drawer.${tuile.cle}.titre`,
              })}
              texte={intl.formatMessage({
                id: `dashboard.carteDonnee.besoins.drawer.${tuile.cle}.texte`,
              })}
              lien={tuile.lien}
              onClick={() => handleOnClickTuileDrawer(tuile)}
            />
          ))}
        </SGSpace>
      </SGDrawer>
    </>
  );
};

export { Besoins };
