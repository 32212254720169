import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { articles, thematiques } from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CategorieVideo, useVideos } from "website/components/hooks/useVideos";
import { getIdTrackingOnglet } from "website/components/molecules/Article/ArticleModel";
import { DecouverteServicesAccompagnement } from "website/components/molecules/DecouverteServicesAccompagnement/DecouverteServicesAccompagnement";
import { ARTICLE } from "website/utils/privateRoutes";
import { CarrouselALaUne } from "./CarrouselALaUne";
import { CarteArticleHorizontal } from "./CarteArticleHorizontal";
import { CarteArticleVertical } from "./CarteArticleVertical";

interface OngletAccueilProps {
    handleOnChangeOnglet: (thematique: string) => void;
}

const OngletAccueil: FunctionComponent<OngletAccueilProps> = (props: OngletAccueilProps) => {
    const { handleOnChangeOnglet } = props;

    const intl = useIntl();
    const videos = useVideos(CategorieVideo.ARTICLES);
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { trackClick } = useTracking();

    const handleOnClickLireArticle = (path: string, trackKey: string) => {
        trackClick(trackKey, { form_field_1: path });
    };

    return (
        <SGGridRow>
            <SGGridCol span={12} md={8}>
                <SGSpace direction="vertical" disableautomargin size="xl">
                    <CarrouselALaUne
                        key="carousel"
                        articles={articles.slice(0, 3)}
                        videos={videos.filter((video) => video.ALaUne)}
                        handleOnClickLireArticle={handleOnClickLireArticle}
                        trackKeyArticle="a-la-une:clic-sur-lire-l-article"
                        trackKeyVideo="a-la-une:clic-sur-lire-la-video"
                    />
                    {thematiques.map((thematique) => (
                        <SGSpace direction="vertical" size={isPhone ? "md" : "lg"} key={thematique}>
                            <SGTitle key="title" level={2}>{intl.formatMessage({ id: `articles.thematique.titre.${thematique}` })}</SGTitle>
                            <SGGridRow key="row" align="stretch" gutter={[24, 16]}>
                                {articles
                                    .filter((article) => article.theme === thematique && article.favoriAccueil)
                                    .map((article) => (
                                        <SGGridCol span={12} md={6} key={article.id}>
                                            <SGMediaQuery minwidth="md">
                                                <CarteArticleVertical
                                                    media={article}
                                                    path={`${ARTICLE}${article.path}`}
                                                    handleOnClickLireArticle={handleOnClickLireArticle}
                                                    trackKey={`${getIdTrackingOnglet(thematique)}::clic-sur-lire-l-article`}
                                                />
                                            </SGMediaQuery>
                                            <SGMediaQuery minwidth={0} maxwidth="sm">
                                                <CarteArticleHorizontal
                                                    article={article}
                                                    handleOnClickLireArticle={handleOnClickLireArticle}
                                                    trackKey={`${getIdTrackingOnglet(thematique)}::clic-sur-lire-l-article`}
                                                />
                                            </SGMediaQuery>
                                        </SGGridCol>
                                    ))}
                            </SGGridRow>
                            <SGButtonGroup key="buttons" align={isPhone ? "center" : "right"}>
                                <SGButton
                                    type="secondary"
                                    onClick={() => {
                                        trackClick(`clic-sur-consulter-la-rubrique-${getIdTrackingOnglet(thematique)}`);
                                        handleOnChangeOnglet(thematique);
                                        window.scrollTo(0, 0);
                                    }}
                                    size={["md", "sm"]}
                                    ariaLabel={intl.formatMessage({ id: "articles.accueil.bouton.consulter.ariaLabel" })}
                                >
                                    {intl.formatMessage({ id: "articles.accueil.bouton.consulter" })}
                                </SGButton>
                            </SGButtonGroup>
                        </SGSpace>
                    ))}
                </SGSpace>
            </SGGridCol>
            <SGGridCol span={12} md={4}>
                <DecouverteServicesAccompagnement />
            </SGGridCol>
        </SGGridRow>
    );
};

export { OngletAccueil };
