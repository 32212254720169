import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiGet, apiPost } from "store/apiCaller";
import { onApiCallErrorNoError, safe } from "store/error/utils";
import { FETCH_WORK_INCOMES, SAVE_WORK_INCOMES, WorkIncome, WORK_INCOMES_FETCHED, WORK_INCOMES_SAVED } from "./types";

export function* watcherSaga() {
   yield takeLeading(FETCH_WORK_INCOMES, safe(onApiCallErrorNoError, handleFetchWorkIncomes));
   yield takeLeading(SAVE_WORK_INCOMES, safe(onApiCallErrorNoError, handleSaveWorkIncomes));
}

/**
 * Récupère les professions et revenus d'un utilisateur
 * @param action Action contenant l'id de l'utilisateur
 */
function* handleFetchWorkIncomes() {
   const payload: AxiosResponse<WorkIncome[]> = yield call(apiGet, `/backpfr/api/userWorkIncomes`);
   yield put({ type: WORK_INCOMES_FETCHED, payload: payload.data });
}

/**
 * Mets à jour les professions et revenus d'un utilisateur
 * @param action Action contenant la liste non vide de WorkIncome
 */
function* handleSaveWorkIncomes(action: PayloadAction<WorkIncome[]>) {
   yield call(apiPost, `/backpfr/api/userWorkIncomes`, action.payload);
   yield put({ type: WORK_INCOMES_SAVED, payload: action.payload });
}
