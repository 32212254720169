import React, {FunctionComponent} from "react";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGModal} from "sg-modal";
import {SGMessage} from "sg-message";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGIcon} from "sg-icon";
import {SGAvenirStatusError} from "sg-icon-pack-base";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";

interface DeleteModalProps {
   hideModal: () => void;
   onSubmit: () => void;
   cypressName: string;
   textId: string;
}

const DeleteModal: FunctionComponent<DeleteModalProps> = (props: DeleteModalProps) => {
   const {onSubmit, cypressName, hideModal, textId} = props;

   return (
      <SGModal visible onCancel={hideModal}>
         <SGMessage
            extra={<SGButtonGroup align="center">
               <SGButtonIntl type="primary" size="sm" cypressName={`${cypressName}-confirm-delete`} onClick={onSubmit} intlId="common.delete"/>
               <SGButtonIntl type="secondary" size="sm" cypressName={`${cypressName}-cancel-delete`} onClick={hideModal} intlId="common.cancel"/>
            </SGButtonGroup>}
            icon={<SGIcon component={<SGAvenirStatusError/>} size="xl"/>}
            title={<SGTitleIntl intlId="common.delete.modal.title" cypressName="delete-modal-title"/>}>
            <SGTextIntl intlId={textId}/>
         </SGMessage>
      </SGModal>
   );
};

export { DeleteModal };
