import React from "react";
import {useFormContext} from "react-hook-form";
import {useIntl} from "react-intl";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {Loan} from "../../../../../store/loans/types";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {RealEstateAsset} from "../../../../../store/assets/types";
import {isLoanType} from "../../../../../store/loans/typeguards";
import {isRealEstateAssetType} from "../../../../../store/assets/typeguards";
import {convertNumberToStringWithSeparators} from "../../../../utils/formatting/numberFormatter";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGBox, SGSpace} from "sg-space";
import {SGText} from "sg-typo";
import {AssetSelectOption} from "../../../atoms/AssetSelectOption/AssetSelectOption";
import {SGSelectOption} from "sg-select";

interface AssetAssociationFormProps {
   assetType: "LOAN" | "REAL-ESTATE";
   associatedAssets: Array<Loan | RealEstateAsset>;
   nonAssociatedAssets: Array<Loan | RealEstateAsset>;
   onRemoveAssociatedAsset: (assetIndex: number) => void;
   onAssetSelectionChange: (newAsset: string) => void;
   cypressName: string;
}

export const computeLoanName = (loan: Partial<Loan>): string => {
   if (loan.monthlyAmount) {
      return `${loan.name} (${convertNumberToStringWithSeparators(loan.monthlyAmount)} €/mois)`;
   }

   return `${loan.name}`;
};

const AssetAssociationForm = ({
                                 assetType, associatedAssets, nonAssociatedAssets, onRemoveAssociatedAsset,
                                 onAssetSelectionChange, cypressName
                              }: AssetAssociationFormProps) => {
   const formMethods = useFormContext();
   const intl = useIntl();

   return (
      <>
         <SGBox margin={{top: "lg"}}>
            {nonAssociatedAssets.length === 0 && associatedAssets.length === 0 && (
               <SGTextIntl italic intlId={`form.asset.empty.${assetType}`}/>
            )}
         </SGBox>
         {associatedAssets.map((assetOrLoan: Loan | RealEstateAsset, index: number) => (
            <SGSpace direction="vertical" key={assetOrLoan.id}>
               <SGGridRow align="center">
                  <SGGridCol xs={12} lg={8}>
                     {isLoanType(assetOrLoan) && (
                        <SGText italic data-cy={`${cypressName}-associated-loan`}>
                           {computeLoanName(assetOrLoan)}
                        </SGText>
                     )}

                     {isRealEstateAssetType(assetOrLoan) && (
                        <SGText italic data-cy={`${cypressName}-associated-real-estate`}>
                           {assetOrLoan.assetName}
                        </SGText>
                     )}
                     <input {...formMethods.register(assetType === "LOAN" ? `loans[${index}].id` : `associatedRealEstateAssets[${index}].id`)}
                            type="hidden" defaultValue={assetOrLoan.id}/>
                  </SGGridCol>

                  <SGGridCol xs={12} lg={4}>
                     <SGButtonIntl type="secondary" size="sm" cypressName={`asset-edit-remove-existing-${assetType}`} disableautomargin
                                   onClick={() => onRemoveAssociatedAsset(index)} intlId="common.delete"/>
                  </SGGridCol>
               </SGGridRow>
            </SGSpace>
         ))}
         {nonAssociatedAssets.length !== 0 &&
         <SGGridRow align="center">
            <SGGridCol span={12}>
               <SelectInput name={assetType === "LOAN" ? `associated-loans` : `associated-real-estate-assets`} onValueChange={onAssetSelectionChange}
                            required={false} cypressName={`${cypressName}-select-existing-${assetType}`}
                            label={`form.asset.associatedAssets.label.${assetType}`}
                            placeholder={intl.formatMessage({id: `form.asset.associatedAssets.choose.${assetType}`})}>
                  {nonAssociatedAssets.map((currentAsset: Loan | RealEstateAsset) => (currentAsset.id !== undefined &&
                     <SGSelectOption value={currentAsset.id} key={currentAsset.id}>
                        <AssetSelectOption assetName={isLoanType(currentAsset) ? computeLoanName(currentAsset) : currentAsset.assetName}
                                           assetValuation={isLoanType(currentAsset) ? -currentAsset.remainingPrincipal : currentAsset.assetValuation.totalValuation || 0}
                                           isLoss={isLoanType(currentAsset)}/>
                     </SGSelectOption>
                  ))}
               </SelectInput>
            </SGGridCol>
         </SGGridRow>
         }
      </>
   );
};

export {AssetAssociationForm};
