export interface WorkIncome {
   userId: number;
   originPath: number;
   profession: number;
   income: number;
}

export interface WorkIncomesState {
   workIncomes?: WorkIncome[];
}

export const hasFetchedWorkIncomes = (workIncomesState: WorkIncomesState): boolean =>
   workIncomesState.workIncomes !== undefined;

export const FETCH_WORK_INCOMES = "FETCH_WORK_INCOMES";
export const WORK_INCOMES_FETCHED = "WORK_INCOMES_FETCHED";
export const SAVE_WORK_INCOMES = "SAVE_WORK_INCOMES";
export const WORK_INCOMES_SAVED = "WORK_INCOMES_SAVED";
export const UPDATE_WORK_INCOMES_STORE = "UPDATE_WORK_INCOMES_STORE";

export enum WorkIncomePathType {
   ONBOARDING = 0,
   RIS = 1,
}
