import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import "./ServiceCard.scss";

type ServiceCardProps = {
    title: string;
    subtitle: string;
    onClickShowMore: () => void;
    selected: boolean;
    logo: string;
};

export const ServiceCard: FunctionComponent<ServiceCardProps> = (props: React.PropsWithChildren<ServiceCardProps>) => {
    const { title, subtitle, onClickShowMore, selected, logo } = props;

    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });

    return (
        <div className={`service-card ${selected ? "selected" : ""}`}>
            <SGBox margin={isPhone ? { bottom: "sm" } : { bottom: "xs" }} padding={isPhone ? { bottom: "sm" , top: "sm"} : { }} onClick={onClickShowMore}>
                <>
                <SGMediaQuery minwidth="sm">
                    <div className="divider" />
                </SGMediaQuery>
                <SGGridRow justify="space-around">
                        <SGMediaQuery minwidth={0} maxwidth="xs">
                            <SGGridCol span={12} textalign="center">
                                <img alt={`logo-service-${logo}`} src={logo}/>
                            </SGGridCol>
                        </SGMediaQuery>
                        <SGGridCol span={12}>
                            <SGBox textalign={isPhone ? "center" : "left"} disableautomargin margin={{ top: "xs", left: "sm", right: "sm" }}>
                                <SGTitle level={3} visuallevel={4}>
                                    {title}
                                </SGTitle>
                            </SGBox>
                            {subtitle !== " " && (
                                <SGBox textalign={isPhone ? "center" : "left"} disableautomargin margin={isPhone ? { top: "sm", left: "md", right: "md" } : { left: "sm", right: "sm", bottom: selected ? "sm" : undefined }}>
                                    <>
                                        <SGMediaQuery minwidth={0} maxwidth="xs">
                                            <SGBox>
                                                <SGText weight="400" size="xs">
                                                    {intl.formatMessage({ id: "mesServices.services.youAre" })}
                                                </SGText>
                                            </SGBox>
                                        </SGMediaQuery>
                                        <SGText weight="600" size="xs">
                                            {subtitle}
                                        </SGText>
                                    </>
                                </SGBox>
                            )}
                        </SGGridCol>
                </SGGridRow>
                <SGMediaQuery minwidth="sm">
                    {(!selected && (
                        <SGGridRow span={12} justify="end">
                            <SGGridCol textalign="right">
                                <SGBox margin={{ bottom: "sm" }}>
                                    <SGButton
                                        type="tertiary"
                                        icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                                        iconposition="right"
                                    >
                                        <SGText weight="lighter">{intl.formatMessage({ id: "mesServices.services.showMore" })}</SGText>
                                    </SGButton>
                                </SGBox>
                            </SGGridCol>
                        </SGGridRow>
                    )) || (
                        <div className="right-filler" />
                    )}
                </SGMediaQuery>
                </>
            </SGBox>
        </div>
    );
};
