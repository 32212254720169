export interface SimulateurEconomiesImpotsState {
   currentStep: number;
   newSimulation: boolean;
   parameters: ParametresSEI;
   savedParameters: ParametresSEI;
   taxEconomy: EconomieImpot;
   hasFetched: boolean;
   taxEconomySimulationError?: string
}

export interface ParametresSEI {
   tnsStatus?: TnsStatusEnum;
   isJointDeclaration?: boolean;
   sharesNumber?: number;
   totalAvailableCeiling?: number;
   taxIncome?: number;
   partnerTaxIncome?: number;
   payment?: number;
   simulationDate?: string;
   provision?: ProvisionEnum;
   // Données plafonds à persister
   remainingCeilingN1?: number;
   remainingCeilingN2?: number;
   remainingCeilingN3?: number;
   remainingPartnerCeilingN1?: number;
   remainingPartnerCeilingN2?: number;
   remainingPartnerCeilingN3?: number;
   isPartnerCeilingMutualized?: boolean;
   isPartnerTns?: boolean;
   donePayment?: number;
   partnerDonePayment?: number;
   usePartnerCeiling?: PlafondConjointUsageEnum;
}

export enum TnsStatusEnum {
   BIC_BNC = "BIC_BNC",
   GERANT = "GERANT",
   EXPLOITANT_AGRICOLE = "EXPLOITANT_AGRICOLE",
}

export enum ProvisionEnum {
   MADELIN = "MADELIN",
   GENERAL = "GENERAL",
}

export enum PlafondConjointUsageEnum {
   ALL = "ALL",
   PARTIAL = "PARTIAL",
   NONE = "NONE",
}
export interface EconomieImpot {
   simulationKey?: string;
   taxSaving: number;
   realSavingEffort: number;
   incomeTaxWithPayment: IncomeTaxPayment;
   incomeTaxWithoutPayment: IncomeTaxPayment;
}

export interface IncomeTaxPayment {
   // Non mappé car inutile
   // agriContribution: number;
   incomeTax: number;
   taxableIncome: number;
   taxableIncomeBracket1: number;
   taxableIncomeBracket2: number;
   taxableIncomeBracket3: number;
   taxableIncomeBracket4: number;
   taxableIncomeBracket5: number;
}

export interface TaxSimulation{
   tnsStatus?: TnsStatusEnum;
   isJointDeclaration: boolean;
   sharesNumber: number;
   totalAvailableCeiling: number;
   taxIncome: number;
   partnerTaxIncome?: number;
   payment: number;
   taxSaving: number;
   simulationDate?: string;
}

export const FETCH_TAX_ECONOMY_SIMULATION = "FETCH_TAX_ECONOMY_SIMULATION";
export const FETCH_TAX_ECONOMY_SIMULATION_PARAMS = "FETCH_TAX_ECONOMY_SIMULATION_PARAMS";
export const ERROR_ECONOMY_SIMULATION_API = "Erreur lors de la récupération de UserTaxSimulation";
