import { Fragment, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGMenu, SGMenuItem, SGSubMenu } from "sg-menu";
import { SGText } from "sg-typo";
import { MenuItem } from "website/components/hooks/useMenu";
import { MenuEvent } from "./MenuHorizontalPhone";

interface MenuInlinePhoneProps {
  menuItems: MenuItem[];
  onChangeMenu: (key: string) => void;
}

const MenuInlinePhone: FunctionComponent<MenuInlinePhoneProps> = ({
  menuItems,
  onChangeMenu,
}) => {
  const getTexte = (key: string, size: string) => (
    <SGText key="text" weight="600" size={size} whitespace="nowrap">
      <FormattedMessage id={`header.menu.${key}.titre`} />
    </SGText>
  );

  return (
    <SGMenu
      shadow={false}
      mode="inline"
      onClick={({ key }: MenuEvent) => {
        if (key) {
          onChangeMenu(key);
        }
      }}
    >
      {menuItems.map(({ key, disponibiliteParcours, sousMenus }) => (
        <Fragment key={key}>
          {sousMenus !== undefined ? (
            <SGSubMenu key={key} title={getTexte(key, "l")}>
              {sousMenus.map((sousMenu) => (
                <SGMenuItem
                  key={sousMenu.key}
                  onClick={() => {
                    onChangeMenu(sousMenu.key);
                  }}
                  disabled={sousMenu.disponibiliteParcours === false}
                >
                  {getTexte(sousMenu.key, "m")}
                </SGMenuItem>
              ))}
            </SGSubMenu>
          ) : (
            <SGMenuItem key={key} disabled={disponibiliteParcours === false}>
              {getTexte(key, "l")}
            </SGMenuItem>
          )}
        </Fragment>
      ))}
    </SGMenu>
  );
};

export { MenuInlinePhone };
