import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ElyxirError, ErrorState, ErrorType} from "./types";
import {getQueryStringParams} from "../../website/utils/formatting/queryString";
import {AxiosError} from "axios";
import {isElyxirAxiosError, isObjectAxiosError} from "./typeguards";

const initialState: ErrorState = {hasError: false, message: "", type: ErrorType.NO_ERROR};

const getError400Message = (data: ElyxirError) => {
   if (data.detail) {
      return [data.detail.split("?")[0]];
   }
   if (data.errorCode) {
      return [data.errorCode];
   }
   if (data.fieldErrors) {
      return data.fieldErrors.map((error: { message: string }) => error.message);
   }
   console.warn("unknown error", data);

   return ["UNKNOWN"];
};

const getError400Parameters = (data: { [key: string]: string }) => {
   if (data.detail) {
      return getQueryStringParams(data.detail.split("?")[1]);
   }
   if (data.errorParams) {
      const errorKeys = data.errorParams.split(",");
      const queryParams: Record<string, string> = {};
      for (let i = 0; i < errorKeys.length; i += 1) {
         queryParams[errorKeys[i]] = data[errorKeys[i]];
      }

      return queryParams;
   }

   return {};
};

export const errorsSlice = createSlice({
   name: "errors",
   initialState,
   reducers: {
      globalError400: (currentState, action: PayloadAction<AxiosError>) => ({
         hasError: true,
         message: "400",
         errorCodes: (action.payload.response && isElyxirAxiosError(action.payload)) ? getError400Message(action.payload.response.data) : ["UNKNOWN"],
         errorCodesParameters: (action.payload.response && isObjectAxiosError(action.payload)) ? getError400Parameters(action.payload.response.data) : {},
         type: ErrorType.BAD_INPUT_ERROR
      }),
      globalError401: () => ({
         hasError: true, message: "401", type: ErrorType.AUTHORIZATION_ERROR
      }),
      globalError403: () => ({
         hasError: true, message: "403", type: ErrorType.FORBIDDEN_ERROR
      }),
      globalError404: () => ({
         hasError: true, message: "404", type: ErrorType.NON_FATAL_ERROR
      }),
      globalError500: () => ({
         hasError: true, message: "500", type: ErrorType.NON_FATAL_ERROR
      }),
      globalError502: () => ({
         hasError: true, message: "502", type: ErrorType.FATAL_ERROR
      }),
      globalError504: () => ({
         hasError: true, message: "504", type: ErrorType.NON_FATAL_ERROR
      }),
      globalErrorUnavailable: () => ({
         hasError: true, message: "Site indisponible", type: ErrorType.UNAVAILABLE_ERROR
      }),
      globalErrorTimeOutOnPDFDownload: () => ({
         hasError: true, message: "pdf-time-out", type: ErrorType.TIME_OUT_ON_PDF_DOWNLOAD_ERROR
      }),
      globalError: (currentState, action: PayloadAction<string>) => ({
         hasError: true, message: action.payload, type: ErrorType.NON_FATAL_ERROR
      }),
      globalNoError500: () => ({
         hasError: true, message: "500", type: ErrorType.NO_ERROR
      }),
      resetErrors: () => initialState
   }
});

export const {
   globalError400,
   globalError401,
   globalError403,
   globalError404,
   globalError500,
   globalError502,
   globalError504,
   globalErrorUnavailable,
   globalErrorTimeOutOnPDFDownload,
   globalError,
   globalNoError500,
   resetErrors
} = errorsSlice.actions;

export default errorsSlice.reducer;
