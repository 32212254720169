import {call, put, takeEvery, takeLeading} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
   CREATE_INCOME,
   CREATE_PLANNED_SAVINGS_BUYBACK_INCOME,
   DELETE_INCOME,
   FETCH_INCOMES,
   Income,
   UPDATE_INCOME,
   UPDATE_PLANNED_SAVINGS_BUYBACK_INCOME
} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiDelete, apiGet, apiPost, apiPut} from "../apiCaller";
import {getAssetsWithoutLoans} from "../assets/actions";
import {getExpensesAction} from "../expenses/actions";
import {incomeCreated, incomeDeleted, incomesFetched, incomeUpdated} from "./incomesSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {getMembersAction} from "../members/actions";

export function* watcherSaga() {
   // On laisse takeEvery pour la création et la maj d'incomes dans le cas où l'on insère le salaire des deux membres du couple en une fois
   yield takeEvery(CREATE_INCOME, safe(onDefaultError, handleCreateIncome));
   yield takeLeading(FETCH_INCOMES, safe(onDefaultError, handleGetIncomes));
   yield takeEvery(UPDATE_INCOME, safe(onDefaultError, handleUpdateIncome));
   yield takeLeading(DELETE_INCOME, safe(onDefaultError, handleDeleteIncome));
   yield takeLeading(CREATE_PLANNED_SAVINGS_BUYBACK_INCOME, safe(onDefaultError, handleCreatePlannedSavingsBuybackIncome));
   yield takeLeading(UPDATE_PLANNED_SAVINGS_BUYBACK_INCOME, safe(onDefaultError, handleUpdatePlannedSavingsBuybackIncome));
}

function* handleGetIncomes() {
   const payload: AxiosResponse<Income[]> = yield call(apiGet, `wealth/api/incomes`);
   yield put(incomesFetched(payload.data));
}

function* handleCreateIncome(action: PayloadAction<Income>) {
   const payload: AxiosResponse<Income> = yield call(apiPost, `wealth/api/projects/incomes`, action.payload);
   yield put(incomeCreated(payload.data));
   yield put(getMembersAction());
}

function* handleCreatePlannedSavingsBuybackIncome(action: PayloadAction<Income>) {
   const payload: AxiosResponse<Income> = yield call(apiPost, `wealth/api/projects/incomes/planned-savings-buyback`, action.payload);
   yield put(incomeCreated(payload.data));
   yield put(getAssetsWithoutLoans());
}

function* handleUpdateIncome(action: PayloadAction<Income>) {
   const payload: AxiosResponse<Income> = yield call(apiPut, `wealth/api/projects/incomes`, action.payload);
   yield put(incomeUpdated(payload.data));
}

function* handleUpdatePlannedSavingsBuybackIncome(action: PayloadAction<Income>) {
   const payload: AxiosResponse<Income> = yield call(apiPut, `wealth/api/projects/incomes/planned-savings-buyback`, action.payload);
   yield put(incomeUpdated(payload.data));
}

function* handleDeleteIncome(action: PayloadAction<string>) {
   const incomeId: string = action.payload;
   yield call(apiDelete, `wealth/api/projects/${incomeId}`);
   yield put(incomeDeleted(incomeId));
   yield put(getExpensesAction());
   yield put(getAssetsWithoutLoans());
}
