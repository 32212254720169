export interface AppointmentState {
   appointment: Partial<Appointment>;
}

export const hasFetchedAppointment = (apointmentState: AppointmentState): boolean => apointmentState.appointment !== undefined;

/**
 * Objet récupéré et envoyé au backpfr
 */
export interface Appointment {
   userId: number;
   creationDate?: string;
   date: string;
   time: string;
   financialAdvisorEmail: string;
   appointmentJustCreated?: boolean;
}

// actions
export const FETCH_APPOINTMENT = "FETCH_APPOINTMENT";
export const APPOINTMENT_FETCHED = "APPOINTMENT_FETCHED";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const APPOINTMENT_UPDATED = "APPOINTMENT_UPDATED";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";

export interface SGAgendaToken {
   source: string,
   showQualificationQuestions: string,
   complements: string,
   callbackURL: string,
   callbackURLPrecedent: string,
}
